define("balance-ember/components/manage/bookmark-dropdown/current-bookmark/component", ["exports", "balance-ember/types/bookmark", "@microstates/ember"], function (_exports, _bookmark, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    zero: 0,
    characterCount: Ember.computed.or('bookmarkTitle.length', 'zero'),
    hasEmptyBookmarkTitle: Ember.computed.not('characterCount'),
    isSaved: false,
    bookmarkState: (0, _ember.state)((0, _ember.create)(_bookmark.default)),
    createdBookmark: null,

    didReceiveAttrs() {
      var _this$createdBookmark, _this$currentBookmark;

      this._super(); // We want to set `isSaved` to false if we have just created a new bookmark, but then a
      // different bookmark link is clicked without closing the dropdown. We'll know this is the case
      // if there is a `createdBookmark` and that bookmark is not the same as the currentBookmark


      if (this.createdBookmark && ((_this$createdBookmark = this.createdBookmark) === null || _this$createdBookmark === void 0 ? void 0 : _this$createdBookmark.id) !== ((_this$currentBookmark = this.currentBookmark) === null || _this$currentBookmark === void 0 ? void 0 : _this$currentBookmark.id)) {
        Ember.set(this, 'isSaved', false);
      }
    },

    actions: {
      async save(title) {
        Ember.set(this, 'createdBookmark', await this.saveBookmark(title));
        Ember.set(this, 'isSaved', true); // We don't want the bookmarkTitle to appear after the current bookmark is deleted

        Ember.set(this, 'bookmarkTitle', null); // We should always be in the 'view' state after saving a new bookmark

        this.bookmarkState.reset();
      }

    }
  });

  _exports.default = _default;
});