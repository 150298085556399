define("balance-ember/components/budgeting-link/architect/no-fy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uCEuj3ap",
    "block": "{\"symbols\":[],\"statements\":[[8,\"link-to\",[[24,0,\"whitespace-nowrap hover:no-underline focus:no-underline flex flex-col items-center text-lg px-24 py-18\"]],[[\"@route\",\"@tagName\"],[\"fiscal-years.new\",\"a\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,0],[\"na-fiscal-year\"],[[\"class\"],[\"svg-ilustration-icon mb-8\"]]]],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text-material-blue-gray700 font-semibold mb-4\"],[12],[2,\"Start using Budget\"],[13],[2,\"\\n  \"],[8,\"icon-button\",[],[[\"@style\",\"@icon\",\"@buttonText\",\"@showButtonText\"],[\"btn-primary\",\"circle-plus\",\"Create a Fiscal Year\",true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}",
    "moduleName": "balance-ember/components/budgeting-link/architect/no-fy/template.hbs"
  });

  _exports.default = _default;
});