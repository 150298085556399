define("balance-ember/utils/sum-benefits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = benefitsTotal;

  function benefitsTotal(benefitsPackage, amount) {
    if (Ember.isEmpty(benefitsPackage) || Ember.isEmpty(amount)) {
      return 0;
    }

    let flatRate = sumBenefits(benefitsPackage.flatRateBenefits);
    let percentage = sumBenefits(benefitsPackage.percentageBenefits);
    return flatRate + amount * percentage / 100.0;
  }

  function sumBenefits(benefits = []) {
    return benefits.reduce((acc, item) => {
      return acc + parseFloat(item.amount);
    }, 0);
  }
});