define("balance-ember/components/filter-form/select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TXPfCR8F",
    "block": "{\"symbols\":[\"StackItem\",\"label\",\"label\",\"@filter\",\"@categoryConfig\"],\"statements\":[[8,\"layout/stack\",[],[[\"@space\"],[\"m\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"ui-form/ui-label\",[],[[\"@style\"],[\"w-full\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"text\"]],[],[[\"@style\"],[\"none\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"global.table.filters.is\"],null]]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[11,\"div\"],[4,[38,1],[[32,0,[\"focus\"]]],null],[12],[2,\"\\n        \"],[8,\"filter-form/select/input\",[],[[\"@operator\",\"@filter\",\"@categoryConfig\"],[\"eq\",[32,4],[32,5]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"ui-form/ui-label\",[],[[\"@style\"],[\"w-full\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"text\"]],[],[[\"@style\"],[\"none\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"global.table.filters.is_not\"],null]]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[8,\"filter-form/select/input\",[],[[\"@operator\",\"@filter\",\"@categoryConfig\"],[\"not_eq\",[32,4],[32,5]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"did-insert\"]}",
    "moduleName": "balance-ember/components/filter-form/select/template.hbs"
  });

  _exports.default = _default;
});