define("balance-ember/components/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kjz4Q5t3",
    "block": "{\"symbols\":[\"@tableState\",\"@modelChanges\",\"@loadTask\",\"@trackingPrefix\",\"@categories\",\"@trackingParams\",\"@tableActions\",\"@columns\",\"&default\"],\"statements\":[[8,\"table-data-loader\",[],[[\"@tableState\",\"@modelChanges\",\"@loadDataTask\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\\n\"],[8,\"table-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,9,[[30,[36,1],null,[[\"body\",\"controls\",\"filter-bar\",\"toolbar\",\"bulk-action-bar\"],[[30,[36,0],[\"table/body\"],[[\"tableState\",\"columns\",\"tableActions\",\"loadTask\",\"trackingPrefix\",\"selectedRows\"],[[32,1],[32,8],[32,7],[32,3],[32,4],[32,0,[\"selectedRows\"]]]]],[30,[36,0],[\"table/controls\"],[[\"tableState\",\"loadTask\",\"categories\",\"trackingPrefix\",\"trackingParams\"],[[32,1],[32,3],[32,5],[32,4],[32,6]]]],[30,[36,0],[\"table/filter-bar\"],[[\"tableState\",\"categories\",\"trackingPrefix\"],[[32,1],[32,5],[32,4]]]],[30,[36,0],[\"table/toolbar\"],[[\"tableState\",\"loadTask\",\"categories\",\"trackingPrefix\"],[[32,1],[32,3],[32,5],[32,4]]]],[30,[36,0],[\"table/bulk-action-bar\"],[[\"tableState\",\"selectedRows\"],[[32,1],[32,0,[\"selectedRows\"]]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/table/template.hbs"
  });

  _exports.default = _default;
});