define("balance-ember/components/allocation-form/segments/edit-segments-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mq2s8dBf",
    "block": "{\"symbols\":[\"InlineItem\",\"@expenseCount\"],\"statements\":[[10,\"div\"],[14,0,\"pr-20 pt-12 flex justify-end\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"showExpenseEffectWarning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"ml-32 mr-12\"],[12],[2,\"\\n      \"],[8,\"allocation-form/segments/edit-segments-button/warning-pane\",[],[[\"@expenseCount\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"flex justify-end\"],[12],[2,\"\\n          \"],[8,\"layout/inline\",[],[[\"@space\",\"@align\"],[\"s\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[8,\"button\",[],[[\"@size\",\"@onclick\",\"@style\"],[\"sm\",[30,[36,1],[[32,0],[30,[36,0],[[32,0,[\"showExpenseEffectWarning\"]]],null],false],null],\"btn-subtle-link\"]],[[\"default\"],[{\"statements\":[[2,\"\\n                Cancel\\n              \"]],\"parameters\":[]}]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\\n            \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[11,\"button\"],[24,0,\"btn-secondary btn-sm mr-12\"],[24,4,\"button\"],[4,[38,1],[[32,0],[32,0,[\"changeSegmentMode\"]],\"edit\"],null],[12],[2,\"\\n                Continue\\n              \"],[13],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n          \"]],\"parameters\":[1]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"btn-secondary btn-sm mr-12\"],[24,4,\"button\"],[4,[38,1],[[32,0],[30,[36,0],[[32,0,[\"showExpenseEffectWarning\"]]],null],true],null],[12],[2,\"\\n      Edit Segments\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\",\"if\"]}",
    "moduleName": "balance-ember/components/allocation-form/segments/edit-segments-button/template.hbs"
  });

  _exports.default = _default;
});