define("balance-ember/models/fiscal-year", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.fiscalYearComputedProperties = _exports.schema = void 0;
  const schema = {
    budgets: (0, _model.hasMany)('budget', {
      async: false
    }),
    segmentTypes: (0, _model.hasMany)('segmentType', {
      async: false
    }),
    segmentsSource: (0, _model.attr)('number'),
    benefitsPackages: (0, _model.hasMany)('benefitsPackage', {
      async: false
    }),
    jobTypes: (0, _model.hasMany)('jobType', {
      async: false
    }),
    employees: (0, _model.hasMany)('employee', {
      async: false
    }),
    name: (0, _model.attr)('string'),
    startDate: (0, _model.attr)('date'),
    endDate: (0, _model.attr)('date'),
    state: (0, _model.attr)('string', {
      defaultValue: 'visible'
    }),
    allocatedAmount: (0, _model.attr)('number'),
    plannedAmount: (0, _model.attr)('number'),
    remainingAmount: (0, _model.attr)('number'),
    personnelPlannedAmount: (0, _model.attr)('number'),
    supplementalPlannedAmount: (0, _model.attr)('number'),
    nonPersonnelPlannedAmount: (0, _model.attr)('number'),
    budgetsInProgress: (0, _model.attr)('number'),
    budgetsCompleted: (0, _model.attr)('number'),
    budgetsSubmitted: (0, _model.attr)('number'),
    budgetsApproved: (0, _model.attr)('number')
  };
  _exports.schema = schema;
  const fiscalYearComputedProperties = {
    _allBudgetCounts: Ember.computed.collect('budgetsApproved', 'budgetsSubmitted', 'budgetsCompleted', 'budgetsInProgress'),
    totalBudgetCount: Ember.computed.sum('_allBudgetCounts'),
    isCurrent: Ember.computed.equal('state', 'current'),
    isHidden: Ember.computed.equal('state', 'hidden'),
    inSetup: Ember.computed.equal('state', 'in_setup'),
    possiblyNavigableTo: Ember.computed('state', function () {
      return ['current', 'hidden', 'visible'].includes(this.state);
    })
  };
  _exports.fiscalYearComputedProperties = fiscalYearComputedProperties;

  var _default = _model.default.extend(schema, fiscalYearComputedProperties);

  _exports.default = _default;
});