define("balance-ember/utils/is-offscreen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isOffscreenHorizontally = isOffscreenHorizontally;
  _exports.isOffscreenVertically = isOffscreenVertically;
  _exports.isOffscreen = isOffscreen;
  _exports.hasEnoughSpaceAbove = hasEnoughSpaceAbove;

  function isOffscreenHorizontally(el) {
    let rect = el.getBoundingClientRect();
    return rect.left < 0 || rect.right > (window.innerWidth || document.documentElement.clientWidth);
  }

  function isOffscreenVertically(el) {
    let rect = el.getBoundingClientRect();
    return rect.top < 0 || rect.bottom > (window.innerHeight || document.documentElement.clientHeight);
  }

  function isOffscreen(el) {
    return isOffscreenVertically(el) || isOffscreenHorizontally(el);
  }

  function hasEnoughSpaceAbove(el) {
    let buttonHeight = 35; // space in px between the top of the menu's trigger and the top of the menu

    let rect = el.getBoundingClientRect();
    let spaceAbove = rect.top - buttonHeight;
    let spaceBelow = (window.innerHeight || document.documentElement.clientHeight) - rect.top;
    return spaceAbove > spaceBelow && spaceAbove > rect.height;
  }
});