define("balance-ember/routes/manage/overview", ["exports", "balance-ember/classes/bookmark-query-param-builder", "balance-ember/utils/manage/budget-lea-transition"], function (_exports, _bookmarkQueryParamBuilder, _budgetLeaTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    eventTracking: Ember.inject.service(),
    availableBudgetLeas: Ember.inject.service(),
    preferences: Ember.inject.service(),

    async beforeModel(transition) {
      new _budgetLeaTransition.default({
        route: this,
        transition,
        availableBudgetLeas: this.availableBudgetLeas
      }).syncBudgetLea();
      await this.loadBookmarks().then(bookmarks => {
        this.bookmarks = bookmarks;

        if (this.preferences.manageOverviewDefaultBookmark) {
          this.applyPreferredBookmark(transition.to.queryParams.query, transition);
        }
      });
    },

    model() {
      return Ember.RSVP.hash({
        currentBudgetLea: this.availableBudgetLeas.current,
        bookmarks: this.bookmarks
      });
    },

    getBookmarkWithId(id) {
      return this.bookmarks.find(bookmark => parseInt(bookmark.id) === id);
    },

    applyPreferredBookmark(query, transition) {
      var _preferredBookmark$pa, _preferredBookmark$pa2, _preferredBookmark$pa3;

      const preferredBookmark = this.getBookmarkWithId(this.preferences.manageOverviewDefaultBookmark) || null;

      if (!preferredBookmark) {
        return;
      }

      if (!((_preferredBookmark$pa = preferredBookmark.params) !== null && _preferredBookmark$pa !== void 0 && _preferredBookmark$pa.filter)) {
        return;
      }

      let bookmarkBudgetLeaIds = preferredBookmark.budgetLeas.mapBy('id').concat((_preferredBookmark$pa2 = preferredBookmark.params.filter.budget_lea_id) === null || _preferredBookmark$pa2 === void 0 ? void 0 : _preferredBookmark$pa2.eq).compact();

      if (!bookmarkBudgetLeaIds.includes(this.availableBudgetLeas.current.id)) {
        return;
      }

      const bookmarkBudgetLeaId = ((_preferredBookmark$pa3 = preferredBookmark.params.filter.budget_lea_id) === null || _preferredBookmark$pa3 === void 0 ? void 0 : _preferredBookmark$pa3.eq) ?? this.availableBudgetLeas.current.id;
      const queryParamBuilder = new _bookmarkQueryParamBuilder.default(preferredBookmark, bookmarkBudgetLeaId);
      const newQuery = queryParamBuilder.build();

      if (query !== newQuery) {
        transition.abort();
        this.replaceWith('manage.overview', {
          queryParams: {
            query: newQuery
          }
        });
      }
    },

    activate() {
      this.eventTracking.trackEvent('View Manage Overview', {
        budgetLea: this.availableBudgetLeas.current.name
      });
      document.title = 'Manage Overview - Allovue Balance';
    },

    loadBookmarks() {
      return this.store.query('bookmark', {
        filter: {
          resource: {
            eq: 'account_balance'
          }
        },
        include: 'user,budget_leas'
      }).then(bookmarks => this.removeSummarizeBookmarks(bookmarks)).then(bookmarks => this.removeAmountsBookmarks(bookmarks));
    },

    removeSummarizeBookmarks(bookmarks) {
      return bookmarks.filter(bookmark => {
        var _bookmark$params;

        return !((_bookmark$params = bookmark.params) !== null && _bookmark$params !== void 0 && _bookmark$params.summarize);
      });
    },

    removeAmountsBookmarks(bookmarks) {
      let amountsKeys = ['budgeted', 'encumbered', 'spent', 'available', 'available_percentage', 'encumbered_percentage'];
      return bookmarks.filter(bookmark => {
        if (bookmark.params && bookmark.params.filter) {
          let keys = Object.keys(bookmark.params.filter);
          let hasAmountKey = keys.find(key => amountsKeys.includes(key));
          return !hasAmountKey;
        }

        return true;
      });
    }

  });

  _exports.default = _default;
});