define("balance-ember/models/position-expense", ["exports", "balance-ember/models/expense", "@ember-data/model", "balance-ember/utils/position-expense-totals"], function (_exports, _expense, _model, _positionExpenseTotals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.computedProperties = _exports.schema = void 0;
  const schema = {
    position: (0, _model.belongsTo)('position', {
      async: false
    }),
    accountString: (0, _model.attr)('string', {
      readOnly: true
    }),
    allocation: (0, _model.belongsTo)('allocation', {
      async: false
    }),
    percentage: (0, _model.attr)('number', {
      defaultValue: null
    }),
    amount: (0, _model.attr)('number', {
      defaultValue: 0,
      readOnly: true
    }),
    purpose: (0, _model.attr)('string')
  };
  _exports.schema = schema;
  const computedProperties = {
    jobTypeSegments: Ember.computed.readOnly('position.jobType.segments'),
    jobTypeSegmentTypes: Ember.computed.mapBy('jobTypeSegments', 'segmentType'),
    allocationCanHavePositionExpenses: Ember.computed.readOnly('allocation.canHavePositionExpenses'),
    allocationTypeSalary: Ember.computed('position', 'jobType', function () {
      return new _positionExpenseTotals.default({
        expense: this
      }).salary;
    }),
    _salary: Ember.computed.alias('allocationTypeSalary'),
    salaryAmount: Ember.computed('_salary', 'position.fte', function () {
      return new _positionExpenseTotals.default({
        expense: this
      }).salaryAmount;
    }),
    benefitsAmount: Ember.computed('salaryAmount', 'position.{fte,jobType.benefitsPackage}', function () {
      return new _positionExpenseTotals.default({
        expense: this
      }).benefitsAmount;
    }),
    positionFte: Ember.computed('position', function () {
      return new _positionExpenseTotals.default({
        expense: this
      }).FTE;
    }),
    portionOfFTEFunded: Ember.computed('fundingPercentage', 'position.fte', function () {
      return new _positionExpenseTotals.default({
        expense: this
      }).fteFunded;
    }),
    fundingPercentage: Ember.computed('percentage', function () {
      return new _positionExpenseTotals.default({
        expense: this
      }).fundingPercentage;
    }),
    adjustedSalary: Ember.computed('fundingPercentage', 'salaryAmount', function () {
      return new _positionExpenseTotals.default({
        expense: this
      }).adjustedSalary;
    }),
    adjustedBenefits: Ember.computed('fundingPercentage', 'benefitsAmount', function () {
      return new _positionExpenseTotals.default({
        expense: this
      }).adjustedBenefits;
    }),
    total: Ember.computed('salaryAmount', 'benefitsAmount', function () {
      return new _positionExpenseTotals.default({
        expense: this
      }).positionTotal;
    }),
    adjustedTotal: Ember.computed('adjustedSalary', 'adjustedBenefits', function () {
      return new _positionExpenseTotals.default({
        expense: this
      }).adjustedTotal;
    }),
    percentageDecimal: Ember.computed('percentage', function () {
      return this.percentage / 100;
    }),
    // This is a small hack. We're not showing the salarySegmentType as a select
    // because we assume that even for new positionExpenses (i.e. they don't
    // have a jobType yet) that the jobType WILL define a salarySegment.
    // Paul Ostazeski on Wed 25 Jan 2017 04:02:19 PM EST
    salarySegmentType: Ember.computed.filterBy('allSegmentTypes', 'salarySegment'),
    parentSegmentTypes: Ember.computed.union('allocationSegmentTypes', 'salarySegmentType')
  };
  _exports.computedProperties = computedProperties;

  var _default = _expense.default.extend(schema, computedProperties);

  _exports.default = _default;
});