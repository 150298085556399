define("balance-ember/components/manage/summarize/data-loader/data-loading-state", ["exports", "balance-ember/components/manage/summarize/summarize-path", "@microstates/ember", "balance-ember/utils/have-filters-changed"], function (_exports, _summarizePath, _ember, _haveFiltersChanged) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LOADING_STATES = void 0;
  const LOADING_STATES = {
    loading: 'showedLoadingState'
  };
  _exports.LOADING_STATES = LOADING_STATES;

  class DataLoadingState {
    constructor({
      summarizeTableState,
      forkedSummarizeTableState,
      loadingCycleState
    }) {
      this.summarizeTableState = summarizeTableState;
      this.forkedSummarizeTableState = forkedSummarizeTableState;
      this.loadingCycleState = loadingCycleState;
    }

    get forkedTableState() {
      return this.forkedSummarizeTableState.table;
    }

    get tableState() {
      return this.summarizeTableState.table;
    }

    get forkedSummarizeState() {
      return this.forkedSummarizeTableState.summarize;
    }

    get summarizeState() {
      return this.summarizeTableState.summarize;
    }

    get shouldLoadTopLevelData() {
      return !this.alreadyLoaded && this.hasCategory && (!this.summarizeStateHasRows || this.hasCategoryChanged || this.haveTableParametersChanged);
    }

    get shouldShowLoadingState() {
      return this.summarizeStateHasRows && !this.areAllChildrenLoaded && this.loadingCycleState !== LOADING_STATES.loading;
    }

    get shouldLoadChildren() {
      return this.summarizeStateHasRows && (!this.areAllChildrenLoaded || this.hasSummarizeTableStateChanged);
    }

    get hasCategoryChanged() {
      return Boolean(this.forkedSummarizeTableStateNotSet || this.areCategoriesNotEqual);
    }

    get areCategoriesNotEqual() {
      return this.topSummaryCategory !== this.forkedSummaryCategory;
    }

    get hasCategory() {
      return Boolean(this.topSummaryCategory);
    }

    get topSummaryCategory() {
      let value = this.summarizeState && this.summarizeState.category.entries.value;
      return value && value.state;
    }

    get forkedSummaryCategory() {
      let value = this.forkedSummarizeState && this.forkedSummarizeState.category.entries.value;
      return value && value.state;
    }

    get haveTableParametersChanged() {
      return Boolean(this.forkedSummarizeTableStateNotSet || this.haveFiltersChanged || this.haveSortsChanged);
    }

    get forkedSummarizeTableStateNotSet() {
      return !this.forkedSummarizeTableState;
    }

    get haveFiltersChanged() {
      return (0, _haveFiltersChanged.default)({
        newTableState: this.tableState,
        oldTableState: this.forkedTableState
      });
    }

    get haveSortsChanged() {
      let oldSorts = (0, _ember.valueOf)(this.forkedTableState.sorts);
      let newSorts = (0, _ember.valueOf)(this.tableState.sorts);

      if ((oldSorts === null || oldSorts === void 0 ? void 0 : oldSorts.length) !== (newSorts === null || newSorts === void 0 ? void 0 : newSorts.length)) {
        return true;
      }

      return newSorts.find((sort, index) => {
        let oldSort = oldSorts[index];
        return sort.column !== (oldSort === null || oldSort === void 0 ? void 0 : oldSort.column) || sort.dir !== (oldSort === null || oldSort === void 0 ? void 0 : oldSort.dir);
      });
    }

    get hasSummarizeTableStateChanged() {
      return (0, _ember.valueOf)(this.forkedSummarizeTableState) !== (0, _ember.valueOf)(this.summarizeTableState);
    }

    get alreadyLoaded() {
      return this.summarizeStateHasRows && this.forkedSummarizeTableStateNotSet;
    }

    get summarizeStateHasRows() {
      return Boolean(this.summarizeState.rows.length);
    }

    get areAllChildrenLoaded() {
      if (this._areAllChildrenLoaded) {
        return this._areAllChildrenLoaded;
      }

      let nonLoadedChild = this.paths.find(path => !path.lastObject.summary.rows.length);
      return this._areAllChildrenLoaded = !nonLoadedChild;
    }

    get paths() {
      return this._paths = this._paths || new _summarizePath.AllSummarizeNodePaths(this.summarizeState).find();
    }

  }

  _exports.default = DataLoadingState;
});