define("balance-ember/components/ui-modal/modal-body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iKkCQ1Lu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"isWarning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui-modal/modal-body/warning\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/ui-modal/modal-body/template.hbs"
  });

  _exports.default = _default;
});