define("balance-ember/components/slideout-budgeting-segments-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Q8CZHyK",
    "block": "{\"symbols\":[\"fieldset\",\"segmentTypeMap\",\"label\",\"@form\",\"@fieldsetNote\"],\"statements\":[[8,[32,4,[\"fieldset\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"legend\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"name\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Segments\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ml-32 pl-24\"],[12],[8,[32,1,[\"notes\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[1,[32,5]]],\"parameters\":[]}]]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"segmentTypesMap\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,4,[\"label\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"text\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[1,[32,2,[\"segmentType\",\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,2,[\"blockedFromEdit\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[12],[2,\"\\n          Cannot be edited because personnel expenses are already planned on this allocation.\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"flex-1\"],[15,\"data-segmenttype-id\",[32,2,[\"segmentType\",\"id\"]]],[12],[2,\"\\n          \"],[8,\"segment-finder\",[],[[\"@budgetCategory\",\"@segmentType\",\"@update\",\"@selectedSegment\"],[[32,0,[\"budgetCategory\"]],[32,2,[\"segmentType\"]],[30,[36,0],[[32,0],\"onSegmentUpdate\"],null],[32,2,[\"selectedSegment\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[3]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/slideout-budgeting-segments-select/template.hbs"
  });

  _exports.default = _default;
});