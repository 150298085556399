define("balance-ember/components/budget-approval-modal-actions/component", ["exports", "balance-ember/utils/urls"], function (_exports, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Exposes common actions that all the approval events will need
   *
   * @returns {action} cancel
   * @returns {action} sendEvent
   */
  var _default = Ember.Component.extend({
    routeHistory: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * Depending on which route we are going back to we may need a dynamic segment. If we pass a
     * dynamic segment to the transition when the route doesn't use it the router blows up. We
     * need to check which route we're going to and decide whether or not to pass a dynamic segment
     * Aug 8, 2017 - BB
     */
    goBack() {
      let routeHistory = this.routeHistory;
      let previousRoute = routeHistory.previousRoute;
      let isExpensesRoute = previousRoute.includes('expenses');
      let allocationId = routeHistory.previousQueryParams.id;

      if (isExpensesRoute && Ember.isPresent(allocationId)) {
        return routeHistory.goBackAndReplace(allocationId);
      }

      routeHistory.goBackAndReplace();
    },

    actions: {
      cancel() {
        this.goBack();
      },

      sendEvent(budgetId, eventType) {
        let url = `${(0, _urls.URLForAPIv3)()}/budget_states/${budgetId}`;
        return this.ajax.patch(url, {
          data: {
            state: eventType,
            extra_fields: {
              budgets: 'editable,authorized_approval_events,user_role'
            },
            include: 'approvals.approver'
          }
        }).then(payload => {
          // Push the budget data into the store so we see the update because ember-ajax does not
          // do this for us
          this.store.pushPayload(payload);
          this.goBack();
        });
      }

    }
  });

  _exports.default = _default;
});