define("balance-ember/components/amount-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8daZ52BO",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"column\",\"highlightDeficit\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"negative-amount\",[],[[\"@value\"],[[32,0,[\"value\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,0,[\"value\"]]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,1],[\"material-gray500 \",[32,0,[\"percentageClass\"]]],null]],[12],[1,[32,0,[\"percentage\"]]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"value\"]]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[30,[36,1],[\"text-material-gray500 \",[32,0,[\"percentageClass\"]]],null]],[12],[2,\"\\n    \"],[1,[32,0,[\"percentage\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\",\"concat\",\"if\"]}",
    "moduleName": "balance-ember/components/amount-cell/template.hbs"
  });

  _exports.default = _default;
});