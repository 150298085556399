define("balance-ember/components/table/filter-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2rnYe6wd",
    "block": "{\"symbols\":[\"FB\",\"@categories\",\"@trackingPrefix\",\"&attrs\",\"&default\"],\"statements\":[[8,\"add-filter-button\",[],[[\"@categories\",\"@filter\",\"@applyFilter\",\"@trackingPrefix\"],[[32,2],[32,0,[\"filter\"]],[30,[36,0],[[32,0],\"applyFilter\"],null],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"trigger\"]],[],[[\"@disabled\"],[[30,[36,1],[[32,0,[\"filters\",\"length\"]],0],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"icon-button\",[[17,4]],[[\"@style\",\"@testId\",\"@buttonText\",\"@showButtonText\",\"@icon\",\"@active\"],[\"btn-default\",\"filter-button\",\"Filter\",true,\"filter\",[30,[36,2],[[32,1,[\"isOpen\"]],[30,[36,1],[[32,0,[\"filters\",\"length\"]],0],null]],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"step\"]],[],[[\"@step\"],[1]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"category-list\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"step\"]],[],[[\"@step\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"filter-form\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[18,5,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"gt\",\"or\"]}",
    "moduleName": "balance-ember/components/table/filter-button/template.hbs"
  });

  _exports.default = _default;
});