define("balance-ember/components/positions-table/nested-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t90HULEn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"rounded shadow-md w-full bg-material-gray100 border-t border-white \"],[12],[2,\"\\n  \"],[10,\"table\"],[14,0,\"th-rounded last-tr-rounded border-spacing-none w-full\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,1],null,[[\"Head\",\"Header\",\"Body\",\"Footer\"],[[30,[36,0],[\"positions-table/nested-table/head\"],null],[30,[36,0],[\"positions-table/nested-table/header\"],null],[30,[36,0],[\"positions-table/nested-table/body\"],null],[30,[36,0],[\"positions-table/nested-table/footer\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/positions-table/nested-table/template.hbs"
  });

  _exports.default = _default;
});