define("balance-ember/components/user/profile-tabs/manage-permissions/edit/budget-lea/component", ["exports", "balance-ember/components/tagless-component/component", "@microstates/ember", "ember-inflector", "balance-ember/types/filter"], function (_exports, _component, _ember, _emberInflector, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    selectedCategory: null,
    segmentTypes: Ember.computed.readOnly('data.budgetLea.segmentTypes.[]'),
    sortedSegmentTypes: Ember.computed('segmentTypes', function () {
      return this.segmentTypes.sortBy('displayOrder');
    }),
    segmentAccess: Object.freeze([{
      label: 'contains',
      value: 'include'
    }, {
      label: 'does not contain',
      value: 'exclude'
    }]),
    categories: Ember.computed('segmentTypes', 'segments', 'sortedSegmentTypes', function () {
      return this.sortedSegmentTypes.map(segmentType => {
        let value = segmentType.name;
        let title = value.titleize();
        return {
          value,
          title,
          type: 'text',
          format: filter => {
            let condition = Ember.String.capitalize((0, _emberInflector.pluralize)(filter.firstOperator.value.state));
            let segmentCode = filter.firstOperator.firstFilterValue;
            let segmentName = this.segments.findBy('code', segmentCode).name;
            return `${condition} ${(0, _emberInflector.singularize)(title)} ${segmentCode} - ${segmentName}`;
          }
        };
      });
    }),
    segments: Ember.computed('data.customPermissions.[]', function () {
      // flatMap the segments
      return this.data.customPermissions.reduce((acc, curr) => {
        // Decorate each segment with the segment type
        let segments = curr.segments.map(({
          name,
          code
        }) => {
          return Object.assign({
            name,
            code
          }, {
            segmentType: curr.segmentType,
            segmentAccess: curr.segmentAccess
          });
        });
        return acc.concat(segments);
      }, []);
    }),
    filterList: Ember.computed('data.customPermissions.[]', 'segments', function () {
      let filters = this.segments.map(segment => {
        return {
          category: segment.segmentType,
          type: 'segment',
          operators: [{
            value: segment.segmentAccess,
            filterValues: [segment.code]
          }]
        };
      });
      return (0, _ember.create)([_filter.default], filters);
    }),
    actions: {
      changeSegment(filter, chosenSegment, segmentAccess) {
        if (chosenSegment) {
          let {
            code,
            name
          } = chosenSegment;
          filter.firstOperator.updateFilterValue(0, code); // Save the chosen code and title in our list of segments so we can display the name,
          // because the filter object only has a value

          this.segments.pushObject({
            segmentAccess,
            code,
            name
          });
        } else {
          // If the user deselects a segment it will be null
          filter.firstOperator.filterValues.clear();
        }
      },

      updateFilters(filters) {
        // We need to turn the list of filters into permission objects. This means we need to
        // group them by unique segmentTypes and segmentAccess that aren't blank
        filters = (0, _ember.valueOf)(filters);
        let permissions = filters.map(curr => {
          let item = {};
          let [operators] = curr.operators;
          item.budgetLea = this.data.budgetLea;
          item.segmentType = curr.category;
          item.segmentAccess = operators.value;
          let [code] = operators.filterValues;
          let name = this.segments.findBy('code', code).name;
          item.segments = [{
            code,
            name
          }];
          return item;
        }).reduce((acc, curr) => {
          // combine any segmentType segmentAccess entries that have multiple segments
          let index = acc.findIndex(item => item.segmentAccess === curr.segmentAccess && item.segmentType === curr.segmentType);

          if (index >= 0) {
            let permission = acc[index];
            curr.segments = permission.segments.concat(curr.segments);
            acc[index] = curr;
          } else {
            acc.push(curr);
          }

          return acc;
        }, []);
        let newData = Object.assign({}, this.data, {
          customPermissions: permissions
        });
        this.updateData(this.data.id, newData);
      },

      toggleBudgetAccess(hasAccess) {
        if (hasAccess) {
          this.data.user.addBudgetLea(this.data.budgetLea);
        } else {
          let writeableLea = this.data.user.budgetLeas.find(lea => lea.model === this.data.budgetLea);
          this.data.user.disassociateBudgetLea(writeableLea);
        }

        let newData = Object.assign({}, this.data, {
          hasAccess
        });
        this.updateData(this.data.id, newData);
      },

      updateCategory(category) {
        this.set('category', category);
      }

    }
  });

  _exports.default = _default;
});