define("balance-ember/routes/users/confirm-account", ["exports", "balance-ember/utils/urls", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _urls, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UsersConfirmAccountRoute = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    invalidToken: false,

    beforeModel(transition) {
      const {
        confirmation_token: confirmationToken,
        email
      } = transition.to.queryParams;
      this.ajax.post((0, _urls.URLForCheckConfirm)(), {
        async: false,
        data: {
          confirmation_token: confirmationToken,
          email
        },
        success: result => {
          if (result.is_token_invalid) {
            this.notifications.error('Invalid Token.  Please check your inbox for a more recent confirmation email.');
            return this.transitionTo('login');
          }

          if (result.is_confirmed) {
            return this.transitionTo('login', {
              queryParams: {
                confirmed: true
              }
            });
          }
        }
      });
      return this._super(transition);
    },

    activate() {
      return document.title = 'Confirm Account - Allovue Balance';
    }

  });
  var _default = UsersConfirmAccountRoute;
  _exports.default = _default;
});