define("balance-ember/utils/filters/autocomplete-substring-formatter", ["exports", "balance-ember/utils/filters/formatter"], function (_exports, _formatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AutocompleteSubstringFormatter extends _formatter.default {
    isEqGroup(value, operator) {
      return operator === 'not_eq' || value.eq;
    }

    isMatchGroup(value, operator) {
      return operator === 'not_match' || value.match;
    }

    eqGroupValue(value) {
      return typeof value === 'object' ? value.eq.firstObject : value;
    }

    matchGroupValue(value) {
      return typeof value === 'object' ? value.match.firstObject : value;
    }

    get operatorDescriptor() {
      let operatorValue = this.operatorValue === 'eq_group' ? Object.keys(this.firstValue)[0] : this.operatorValue;
      return this.operatorDescriptors[operatorValue];
    }

    get operatorDescriptors() {
      return {
        eq: 'is',
        not_eq: 'is not',
        match: 'contains',
        not_match: 'doesn\'t contain'
      };
    }

    formatValue(value, operator = this.operatorValue) {
      if (this.isEqGroup(value, operator)) {
        return this.formatEqGroupValue(value);
      }

      return this.isMatchGroup(value, operator) ? `“${this.matchGroupValue(value)}”` : '';
    }

    formatEqGroupValue(value) {
      if (!this.categoryConfig.getRecordFromId) {
        return this.eqGroupValue(value);
      }

      let record = this.categoryConfig.getRecordFromId(this.eqGroupValue(value));
      return this.formatRecord(record);
    }

    formatRecord(record) {
      return record[this.categoryConfig.labelPath];
    }

  }

  _exports.default = AutocompleteSubstringFormatter;
});