define("balance-ember/components/positions-table/columns-provider/component", ["exports", "@glimmer/component", "balance-ember/utils/budget/change-log/record-description", "balance-ember/helpers/position-name"], function (_exports, _component, _recordDescription, _positionName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * This component provides columns for a positions table
   * @param {string} fundedWidgetRoutePath The route to use when funding a position from the funded widget dropdown
   */
  let PositionsTableColumnsProviderComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class PositionsTableColumnsProviderComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "eventTracking", _descriptor3, this);

      _initializerDefineProperty(this, "positionToDelete", _descriptor4, this);
    }

    get columns() {
      return [{
        label: 'Position ID',
        valuePath: 'code',
        sortName: 'code',
        cellClassNames: 'code text-sm',
        testSelector: 'position-code',
        cellComponent: 'light-table/cell-components/chevron-cell'
      }, {
        label: 'Job Title',
        valuePath: 'jobTypeTitle',
        sortName: 'job_type_title',
        cellComponent: 'positions-table/columns-provider/job-type-cell',
        cellClassNames: 'text-sm',
        testSelector: 'job-type'
      }, {
        label: 'Employee',
        valuePath: 'employeeName',
        sortName: 'employee_name',
        cellComponent: 'positions-table/columns-provider/employee-cell',
        cellClassNames: 'text-sm',
        testSelector: 'employee'
      }, {
        label: 'Budget',
        valuePath: 'budget.name',
        sortName: 'budget_name',
        cellComponent: 'light-table/cell-components/link-cell',
        route: ['budgeting.budget.expenses', 'budget.id'],
        cellClassNames: 'text-sm',
        testSelector: 'budget-name'
      }, {
        label: 'Position Type',
        valuePath: 'staffTypeName',
        sortName: 'staff_type_name',
        cellClassNames: 'text-sm',
        testSelector: 'job-position-type'
      }, {
        label: 'FTE',
        valuePath: 'fte',
        sortName: 'fte',
        align: 'center',
        width: '90px',
        format: '0[.]000',
        cellComponent: 'number-cell',
        cellClassNames: 'tabular-amount text-sm',
        testSelector: 'position-fte'
      }, {
        label: 'Budgeted Cost',
        valuePath: 'budgetedCost',
        align: 'center',
        cellClassNames: 'tabular-amount text-sm',
        sortName: 'budgeted_cost',
        cellComponent: 'number-cell',
        testSelector: 'budgeted-cost'
      }, {
        label: 'Funded',
        valuePath: 'percentFundedDecimal',
        cellComponent: 'number-cell',
        format: '0,0%',
        cellClassNames: 'tabular-amount text-sm',
        sortName: 'percent_funded',
        testSelector: 'funded',
        align: 'center',
        width: '80px'
      }, {
        label: 'NOTES',
        valuePath: 'notes',
        cellComponent: 'light-table/cell-components/table-actions/notes',
        sortableBy: [{
          value: 'notes',
          label: 'Notes'
        }],
        testSelector: 'position-notes',
        align: 'center',
        width: '60px',
        notes: {
          classNames: 'justify-center',

          value(position) {
            return position.notes;
          }

        }
      }, {
        sortable: false,
        hideable: false,
        cellComponent: 'light-table/cell-components/actions-dropdown-cell',
        valuePath: 'id',
        align: 'right',
        width: '60px',
        buttonText: this.getButtonText,
        actions: this.getTableActions
      }];
    }

    getButtonText(position) {
      var _position$jobType, _position$employee;

      return (0, _positionName.formatPositionName)(position === null || position === void 0 ? void 0 : (_position$jobType = position.jobType) === null || _position$jobType === void 0 ? void 0 : _position$jobType.title, position === null || position === void 0 ? void 0 : position.fte, position === null || position === void 0 ? void 0 : (_position$employee = position.employee) === null || _position$employee === void 0 ? void 0 : _position$employee.name);
    }

    getTableActions(position) {
      let isEditable = position.isEditable;
      let label = isEditable ? 'global.buttons.edit_name' : 'global.buttons.view_name';
      let {
        eventTracking
      } = this;
      return [{
        label: this.intl.t(label, {
          name: this.intl.t('budget.position_expense_form.data.position')
        }),
        disabled: !isEditable,
        testId: 'edit',

        onclick(position, row) {
          Ember.set(row, 'mode', isEditable ? 'edit' : 'view');
          Ember.set(row, 'expanded', true);

          if (isEditable) {
            eventTracking.trackEvent('Position Edit Initiated', {
              budget: position.budget.name,
              fiscalYear: position.budget.fiscalYear.name
            });
          }
        }

      }, {
        component: 'table/actions-popover/delete',
        disabled: !isEditable,
        disabledMessage: 'budget.position.delete_disabled',
        onclick: this.delete
      }, {
        component: 'table/actions-popover/change-log',
        recordDescription: this.getRecordDescription
      }];
    }

    delete(position) {
      this.positionToDelete = position;
    }

    getRecordDescription(position) {
      return [new _recordDescription.default({
        name: 'Position ID',
        value: position.code
      })];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "eventTracking", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "positionToDelete", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getButtonText", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "getButtonText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getTableActions", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "getTableActions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getRecordDescription", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "getRecordDescription"), _class.prototype)), _class));
  _exports.default = PositionsTableColumnsProviderComponent;
});