define("balance-ember/components/multi-step-dropdown/dropdown-trigger/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    uniqueClass: Ember.computed('uniqueId', function () {
      return `dropdown-trigger-${this.uniqueId}`;
    }),
    shouldShow: true,
    actions: {
      click() {
        if (!this.disabled) {
          this.onclick();
        }
      }

    }
  });

  _exports.default = _default;
});