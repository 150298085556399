define("balance-ember/templates/district-admin/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gAD12HIx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"table\"],[14,0,\"bg-white shadow-md rounded w-full\"],[12],[2,\"\\n  \"],[10,\"thead\"],[14,0,\"bg-material-blue50\"],[12],[2,\"\\n    \"],[10,\"th\"],[14,0,\"pl-16 py-16 text-left\"],[12],[2,\"APP\"],[13],[2,\"\\n    \"],[10,\"th\"],[14,0,\"pr-16 py-16 text-right\"],[12],[2,\"CONNECTED\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"tbody\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[14,0,\"pl-16 py-8\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/assets/images/logo/ab-logo.svg\"],[15,\"alt\",[30,[36,0],[\"budget.vendor.data.amazon_business\"],null]],[14,0,\"h-20\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"td\"],[14,0,\"pr-16 py-8 text-right\"],[12],[2,\"\\n        \"],[8,\"district-admin/integrations/amazon-business-button\",[],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "balance-ember/templates/district-admin/integrations.hbs"
  });

  _exports.default = _default;
});