define("balance-ember/models/user-preference", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  const schema = {
    accountsShowChart: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    transactionsShowChart: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    accountsHiddenColumns: (0, _model.attr)('array', {
      defaultValue: () => []
    }),
    transactionsHiddenColumns: (0, _model.attr)('array', {
      defaultValue: () => []
    }),
    autocompleteSubstringFilterHint: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    acquireWithAmazonHint: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    manageOverviewDefaultBookmark: (0, _model.attr)('number', {
      defaultValue: null
    }),
    user: (0, _model.belongsTo)('user', {
      async: false
    })
  };
  _exports.schema = schema;

  var _default = _model.default.extend(schema);

  _exports.default = _default;
});