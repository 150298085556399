define("balance-ember/components/layout/inline/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class LayoutInlineComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "alignToFlex", {
        top: 'start',
        bottom: 'end',
        baseline: 'baseline',
        center: 'center',
        stretch: 'stretch'
      });

      _defineProperty(this, "alignXToFlex", {
        left: 'start',
        right: 'end',
        center: 'center',
        between: 'between'
      });
    }

    get align() {
      if (this.args.align) {
        (false && !(Object.keys(this.alignToFlex).includes(this.args.align)) && Ember.assert(`${this.args.align} is not a valid align option`, Object.keys(this.alignToFlex).includes(this.args.align)));
      }

      return this.alignToFlex[this.args.align || 'top'];
    }

    get alignX() {
      if (this.args.alignX) {
        (false && !(Object.keys(this.alignXToFlex).includes(this.args.alignX)) && Ember.assert(`${this.args.alignX} is not a valid align option`, Object.keys(this.alignXToFlex).includes(this.args.alignX)));
      }

      return this.alignXToFlex[this.args.alignX || 'left'];
    }

  }

  _exports.default = LayoutInlineComponent;
});