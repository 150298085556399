define("balance-ember/components/district-admin/tag-table/destroy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xqQAt8L3",
    "block": "{\"symbols\":[\"@closeModal\",\"@tag\"],\"statements\":[[8,\"modal/destroy\",[],[[\"@closeModal\",\"@destroy\",\"@model\"],[[32,1],[30,[36,0],[[32,0,[\"destroyTagTask\"]]],null],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"p\"],[14,0,\"text-15 text-material-gray700 leading-tight\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"district_admin.tags.delete_confirmation\"],[[\"tagName\"],[[32,2,[\"name\"]]]]]],[2,\"\\n    \"],[1,[30,[36,1],[\"district_admin.tags.delete_explanation\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"perform\",\"t\"]}",
    "moduleName": "balance-ember/components/district-admin/tag-table/destroy/template.hbs"
  });

  _exports.default = _default;
});