define("balance-ember/components/budget/non-personnel-expenses-table/edit-funding/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zLCHul3W",
    "block": "{\"symbols\":[\"StackItem\",\"@expense\",\"@errors\"],\"statements\":[[6,[37,1],[[32,0,[\"loadTask\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"layout/stack\",[],[[\"@space\",\"@align\"],[\"s\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"loading-spinner\",[],[[\"@size\"],[\"xxl\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"global.loading\"],[[\"modelName\"],[[30,[36,0],[\"budget.non_personnel_expense_form.data.segments\"],null]]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"budget/expense-table/edit-segments\",[],[[\"@expense\",\"@errors\"],[[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "balance-ember/components/budget/non-personnel-expenses-table/edit-funding/template.hbs"
  });

  _exports.default = _default;
});