define("balance-ember/controllers/budgeting/fiscal-year", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    currentRouteName: Ember.computed.readOnly('router.currentRouteName'),
    pageTitle: Ember.computed('currentRouteName', function () {
      let route = this.currentRouteName;

      if (route.includes('reports')) {
        return 'Reports';
      }

      if (route.includes('budgets')) {
        return 'Budgets';
      }

      if (route.includes('allocations')) {
        return 'Allocations';
      }

      if (route.includes('purchase-orders')) {
        return 'Purchase Orders';
      }

      return 'Positions';
    })
  });

  _exports.default = _default;
});