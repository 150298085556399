define("balance-ember/components/employee-form-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tXt/rITq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"loadDataTask\",\"lastSuccessful\",\"isFinished\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,0],null,[[\"employeeStatuses\"],[[32,0,[\"employeeStatuses\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"if\"]}",
    "moduleName": "balance-ember/components/employee-form-data/template.hbs"
  });

  _exports.default = _default;
});