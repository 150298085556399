define("balance-ember/routes/budgeting", ["exports", "balance-ember/mixins/non-architect-rerouter"], function (_exports, _nonArchitectRerouter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_nonArchitectRerouter.default, {
    session: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    hasBudgetingAccess: Ember.computed.readOnly('session.hasBudgetingAccess'),

    beforeModel() {
      if (!this.hasBudgetingAccess) {
        this.redirectToNotFound();
      }

      this._super(...arguments); // Must come after redirectToNotFound to avoid getting 404 page when we expect login page

    },

    model({
      fiscal_year_id: fiscalYearId
    }) {
      return this.store.findRecord('fiscal-year', fiscalYearId).then(fiscalYear => {
        return this.availableFiscalYears.setCurrent(fiscalYear);
      });
    },

    activate() {
      document.title = 'Budgeting - Allovue Balance';
    },

    afterModel(model) {
      if (model) {
        return model.sideload('segment_types');
      }
    }

  });

  _exports.default = _default;
});