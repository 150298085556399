define("balance-ember/components/autocomplete/multiple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3BhIyfnp",
    "block": "{\"symbols\":[\"suggestion\",\"@search\",\"@searchField\",\"@selected\",\"@options\",\"@onChange\",\"@noMatchesMessage\",\"@placeholder\",\"@searchPlaceholder\",\"@dropdownClass\",\"@triggerClass\",\"&default\"],\"statements\":[[8,\"power-select-multiple\",[[24,0,\"w-full\"]],[[\"@searchEnabled\",\"@search\",\"@searchField\",\"@selected\",\"@options\",\"@onChange\",\"@onOpen\",\"@noMatchesMessage\",\"@placeholder\",\"@searchPlaceholder\",\"@renderInPlace\",\"@dropdownClass\",\"@triggerClass\"],[true,[30,[36,0],[[32,2],[32,0,[\"search\"]]],null],[32,3],[32,4],[32,5],[32,6],[32,0,[\"onOpen\"]],[32,7],[32,8],[32,9],true,[32,10],[31,[\"css-text-input-variant-1 pl-0 mb-0 w-full h-auto \",[32,11]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,12,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/autocomplete/multiple/template.hbs"
  });

  _exports.default = _default;
});