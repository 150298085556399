define("balance-ember/components/budget/change-log-slideout/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/urls", "balance-ember/utils/morph-keys", "balance-ember/utils/budget/change-log/change"], function (_exports, _component, _emberConcurrency, _urls, _morphKeys, _change) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RecentChangesButtonSlideoutComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember.computed('args.changes', 'chosenChange', 'loadTask.lastSuccessful.value'), _dec4 = (0, _emberConcurrency.task)(function* () {
    let authString = this.ajax.getAuthString();
    let query = {
      id: this.args.record.id,
      type: this.modelName,
      include: this.args.include
    };
    let url = `${(0, _urls.URLForResourceVersions)()}${authString}`;
    return yield this.ajax.request(url, {
      data: query
    }).then(response => {
      let changes = (0, _morphKeys.camelizeKeys)(response.data);

      if (!this.args.currentChange) {
        this.chosenChange = changes.firstObject;
      }

      this.formatChanges(changes);
      return changes;
    });
  }), (_class = class RecentChangesButtonSlideoutComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "chosenChange", _descriptor2, this);

      _initializerDefineProperty(this, "loadTask", _descriptor3, this);

      this.loadTask.perform();
    }

    get currentChange() {
      return this.chosenChange ?? this.args.currentChange;
    }

    get allVersions() {
      return [this.currentChange, ...this.currentChange.children];
    }

    get changes() {
      var _this$loadTask$lastSu;

      // If we have some preloaded changes from a parent component we'll show
      // those to get something on the screen. Then when the task finishes
      // we'll show every change
      let changes = ((_this$loadTask$lastSu = this.loadTask.lastSuccessful) === null || _this$loadTask$lastSu === void 0 ? void 0 : _this$loadTask$lastSu.value) ?? this.args.changes ?? [];
      this.highlightChanges(changes);
      return changes;
    }

    highlightChanges(changes) {
      changes.forEach(change => Ember.set(change, 'color', _change.default.color(change, this.currentChange.versionId)));
    }

    get recordDescription() {
      return this.currentChange.description ? this.currentChange.description : this.args.recordDescription;
    }

    get modelName() {
      return this.args.type || this.args.resourceName || this.args.record.constructor.modelName;
    }

    formatChanges(changes) {
      changes.forEach(change => _change.default.formatChange(change, this.currentChange.versionId));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chosenChange", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changes", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changes"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loadTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RecentChangesButtonSlideoutComponent;
});