define("balance-ember/components/budget/allocations/buttons/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C/F5CpK9",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"icon-button\",[],[[\"@style\",\"@icon\",\"@testId\",\"@buttonText\",\"@displayText\",\"@onclick\"],[\"btn-primary\",\"circle-plus\",\"allocation-add-button\",\"Add Allocation\",\"Allocation\",[30,[36,0],[[32,0],\"showForm\",[30,[36,1],[[32,0,[\"showForm\"]]],null]],null]]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"showForm\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"allocation-form-data\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"allocation-form\",[],[[\"@allocation\",\"@budgets\",\"@segmentTypes\",\"@mode\",\"@cancel\",\"@formTitle\",\"@disabledButtonText\",\"@actionButtonText\"],[[32,1,[\"allocation\"]],[32,1,[\"budgets\"]],[32,1,[\"segmentTypes\"]],\"create\",[30,[36,0],[[32,0],\"showForm\",false],null],\"Add Allocation\",\"Creating\",\"Create Allocation\"]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"set\",\"not\",\"if\"]}",
    "moduleName": "balance-ember/components/budget/allocations/buttons/create/template.hbs"
  });

  _exports.default = _default;
});