define("balance-ember/components/filter-list/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/types/filter", "@microstates/ember"], function (_exports, _component, _filter, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    categories: null,
    filters: null,
    forkedFilters: Ember.computed('filters', function () {
      // Copy the filters so we can perform transitions triggering a new state on the parent if
      // it's being watched by a Store
      return (0, _ember.create)([_filter.default], (0, _ember.valueOf)(this.filters));
    }),
    validFilters: Ember.computed('forkedFilters', function () {
      return this.forkedFilters.filter(filter => filter.isValid);
    }),
    filterList: Ember.computed('filters.length', 'forkedFilters', 'validFilters.length', function () {
      if (this.validFilters.length === this.filters.length) {
        return this.validFilters.push();
      }

      return this.forkedFilters;
    }),

    applyFilters() {},

    getFilterIndex(filter) {
      let filters = (0, _ember.valueOf)(this.forkedFilters);
      let oldFilter = (0, _ember.valueOf)(filter);
      return filters.indexOf(oldFilter);
    },

    replaceFilter(index, filter) {
      let filters = [...this.forkedFilters].replace(index, 1, [filter]);
      filters = filters.map(filterState => (0, _ember.valueOf)(filterState));
      return (0, _ember.create)([_filter.default], filters);
    },

    removeFilter(filter) {
      let filters = this.forkedFilters.remove(filter); // Replace the passed filters with a new `[Filter]` state

      this.applyFilters(filters);
    },

    actions: {
      removeFilter(filter) {
        this.removeFilter(filter);
      },

      updateFilter(oldFilter, newFilter) {
        if (newFilter.isValid) {
          let filterIndex = this.getFilterIndex(oldFilter);
          let filters = this.replaceFilter(filterIndex, newFilter);
          this.applyFilters(filters);
        } else {
          this.removeFilter(oldFilter);
        }
      },

      applyFilter(filter) {
        if (filter.isValid) {
          let filters = this.forkedFilters.push((0, _ember.valueOf)(filter));
          this.applyFilters(filters);
        }
      }

    }
  });

  _exports.default = _default;
});