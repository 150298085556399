define("balance-ember/components/positions-table/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fRUMLO9K",
    "block": "{\"symbols\":[\"InlineItem\",\"@budgetStrategies\",\"@allocations\"],\"statements\":[[8,\"layout/inline\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"positions-table/form/nav\",[],[[\"@stateMachine\",\"@isNewPosition\"],[[32,0,[\"stateMachine\"]],[32,0,[\"position\",\"model\",\"isNew\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1],[],[[\"@grow\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"p-16 bg-material-indigo100\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,0,[\"stateMachine\",\"status\"]],\"funding\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"positions-table/form/funding\",[],[[\"@stateMachine\",\"@allocations\"],[[32,0,[\"stateMachine\"]],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"stateMachine\",\"status\",\"allocation\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"positions-table/form/expense\",[],[[\"@stateMachine\",\"@budgetStrategies\"],[[32,0,[\"stateMachine\"]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,0,[\"stateMachine\",\"status\"]],\"review\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"positions-table/form/review\",[],[[\"@stateMachine\"],[[32,0,[\"stateMachine\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "moduleName": "balance-ember/components/positions-table/form/template.hbs"
  });

  _exports.default = _default;
});