define("balance-ember/utils/validators/fiscal-year", ["exports", "balance-ember/utils/validator"], function (_exports, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class FiscalYearValidator extends _validator.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "constraints", {
        name: {
          presence: {
            allowEmpty: false
          }
        },
        startDate: {
          presence: {
            allowEmpty: false
          }
        },
        endDate: {
          presence: {
            allowEmpty: false
          }
        },
        dateDiff: {
          numericality: {
            greaterThan: 0,
            message: 'global.validation.rules.datetime.sensible'
          }
        }
      });

      _defineProperty(this, "constraintPropertyLookups", {
        name: 'budget.fiscal_year.data.name',
        startDate: 'budget.fiscal_year.data.start_date',
        endDate: 'budget.fiscal_year.data.end_date',
        dateDiff: 'budget.fiscal_year.data.date_diff'
      });
    }

  }

  _exports.default = FiscalYearValidator;
});