define("balance-ember/components/budget/non-personnel-expenses-table/expanded-row/component", ["exports", "@glimmer/component", "balance-ember/utils/writeable-model", "ember-concurrency", "balance-ember/utils/validators/non-personnel-expense", "balance-ember/config/environment"], function (_exports, _component, _writeableModel, _emberConcurrency, _nonPersonnelExpense, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class3, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetNonPersonnelExpensesTableExpandedRowComponent = (_dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    yield this.store.findRecord('nonPersonnelExpense', this.args.row.content.id, {
      reload: true,
      include: 'items'
    });
    this.createWriteableModels();
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    this.activeTab.errors = this.validator.validate();

    if (this.activeTab.errors) {
      return;
    }

    yield this.expense.save({
      include: 'vendor',
      extra_fields: {
        non_personnel_expenses: 'amount'
      }
    });
    this.showSuccessMessageTask.perform();
    this.resetActiveTab();
    this.updateTabs();

    if (this.args.afterSave) {
      this.args.afterSave();
    }
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    this.showSaveSuccess = true;
    yield (0, _emberConcurrency.timeout)(_environment.default.environment === 'test' ? 1 : 5000);
    this.showSaveSuccess = false;
  }), _dec11 = Ember._action, (_class3 = class BudgetNonPersonnelExpensesTableExpandedRowComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "network", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor6, this);

      _initializerDefineProperty(this, "showSaveSuccess", _descriptor7, this);

      _initializerDefineProperty(this, "loadTask", _descriptor8, this);

      _defineProperty(this, "tabs", [new Tab({
        title: 'budget.non_personnel_expense_form.details',
        component: 'budget/non-personnel-expenses-table/edit-details',
        value: 'details',
        testId: 'details-tab',
        paths: ['name', 'items']
      }), new Tab({
        title: 'budget.non_personnel_expense_form.data.notes',
        component: 'budget/non-personnel-expenses-table/edit-notes',
        value: 'notes',
        testId: 'notes-tab',
        paths: ['notes']
      }), new Tab({
        title: 'budget.non_personnel_expense_form.funding',
        component: 'budget/non-personnel-expenses-table/edit-funding',
        value: 'funding',
        testId: 'funding-tab',
        paths: ['segments'],

        validatorOptions() {
          return {
            numSegments: this.expense.expenseSegmentTypes.length
          };
        }

      }), new Tab({
        title: 'budget.non_personnel_expense_form.data.strategies.strategies',
        component: 'budget/non-personnel-expenses-table/edit-strategies',
        value: 'strategies',
        testId: 'strategies-tab',
        paths: ['expenseStrategies']
      }), new Tab({
        title: 'budget.non_personnel_expense_form.data.vendor',
        component: 'budget/non-personnel-expenses-table/edit-vendor',
        value: 'vendor',
        testId: 'vendor-tab',
        paths: ['vendor']
      })]);

      _defineProperty(this, "loadTasks", {});

      _initializerDefineProperty(this, "saveTask", _descriptor9, this);

      _initializerDefineProperty(this, "showSuccessMessageTask", _descriptor10, this);

      this.loadTask.perform();
    }

    get activeTab() {
      // row is a proxy, so have to use get
      // eslint-disable-next-line ember/no-get
      return this.tabs.findBy('value', Ember.get(this, 'args.row.tab')) ?? this.tabs[0];
    }
    /**
     * A place to keep tab loading tasks so we only do them once
     */


    /**
     * We want each tab to have its own copy of the expense so that
     * invalid data doesn't prevent a user from saving the currently
     * visible tab
     */
    createWriteableModels() {
      this.tabs.forEach(tab => {
        tab.expense = this.createWriteableModel();
      });
    }

    createWriteableModel() {
      return new _writeableModel.default({
        model: this.args.row.content,
        store: this.store,
        network: this.network,
        allSegmentTypes: this.availableFiscalYears.current.segmentTypes
      });
    }
    /**
     * After a successful save we want to reset state so no dangling relationships
     * attempt to persist, such as deleted records
     */


    resetActiveTab() {
      this.activeTab.expense = this.createWriteableModel();
    }

    updateTabs() {
      let paths = this.activeTab.paths;
      this.tabs.forEach(tab => {
        if (tab !== this.activeTab) {
          paths.forEach(path => {
            tab.expense[path] = this.activeTab.expense[path];
          });
        }
      });
    }

    get expense() {
      return this.activeTab.expense;
    }

    get validator() {
      var _this$activeTab$valid, _this$activeTab;

      return new _nonPersonnelExpense.default({
        model: this.expense,
        intl: this.intl,
        options: (_this$activeTab$valid = (_this$activeTab = this.activeTab).validatorOptions) === null || _this$activeTab$valid === void 0 ? void 0 : _this$activeTab$valid.call(_this$activeTab)
      });
    }

    reset() {
      this.activeTab.expense = this.createWriteableModel();
    }

  }, (_descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "network", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "availableFiscalYears", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "showSaveSuccess", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "loadTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "saveTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class3.prototype, "showSuccessMessageTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "reset", [_dec11], Object.getOwnPropertyDescriptor(_class3.prototype, "reset"), _class3.prototype)), _class3));
  _exports.default = BudgetNonPersonnelExpensesTableExpandedRowComponent;
  let Tab = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = class Tab {
    constructor({
      title,
      component,
      testId,
      value,
      validatorOptions,
      paths
    } = {}) {
      _initializerDefineProperty(this, "errors", _descriptor, this);

      _initializerDefineProperty(this, "expense", _descriptor2, this);

      this.title = title;
      this.component = component;
      this.testId = testId;
      this.validatorOptions = validatorOptions;
      this.value = value;
      this.paths = paths;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "expense", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});