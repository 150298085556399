define("balance-ember/components/budget/purchase-orders/existing-view/amount/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AiaJVyCw",
    "block": "{\"symbols\":[\"StackItem\",\"@amount\",\"&default\"],\"statements\":[[8,\"layout/stack\",[],[[\"@space\",\"@align\"],[\"xs\",\"right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-2xl\"],[12],[2,\"\\n      \"],[8,\"negative-amount\",[],[[\"@value\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[[32,2]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-material-gray600 text-sm\"],[12],[2,\"\\n      \"],[18,3,null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\"]}",
    "moduleName": "balance-ember/components/budget/purchase-orders/existing-view/amount/template.hbs"
  });

  _exports.default = _default;
});