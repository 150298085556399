define("balance-ember/helpers/linkify", ["exports", "linkifyjs/html"], function (_exports, _html) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkify = linkify;
  _exports.default = void 0;

  function linkify(text) {
    return Ember.String.htmlSafe((0, _html.default)(text[0], {
      defaultProtocol: 'https'
    }));
  }

  var _default = Ember.Helper.helper(linkify);

  _exports.default = _default;
});