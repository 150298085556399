define("balance-ember/helpers/hundredths-place", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hundredthsPlace = hundredthsPlace;
  _exports.default = void 0;

  // Accepts params, hash. Worded here as value and options. I removed options from the args list because JSHint complains as we're not using it in the function.
  function hundredthsPlace(value) {
    return Number(value).toFixed(2);
  }

  var _default = Ember.Helper.helper(hundredthsPlace);

  _exports.default = _default;
});