define("balance-ember/models/bookmark-reminder", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  const schema = {
    bookmark: (0, _model.belongsTo)('bookmark', {
      async: false
    }),
    user: (0, _model.belongsTo)('user', {
      async: false
    }),
    reminderFrequency: (0, _model.attr)('string'),
    reminderValue: (0, _model.attr)('string'),
    subject: (0, _model.attr)('string'),
    message: (0, _model.attr)('string')
  };
  _exports.schema = schema;

  var _default = _model.default.extend(schema);

  _exports.default = _default;
});