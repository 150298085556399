define("balance-ember/components/selected-box/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    style: 'blue',
    boxClasses: Ember.computed('style', function () {
      let style = 'border border-material-light-blue400 rounded-sm pl-12 pr-8 \
      text-material-light-blue700';

      if (this.style.includes('blue')) {
        style = `${style} bg-material-light-blue100`;
      }

      if (this.style.includes('white')) {
        style = `${style} bg-white`;
      }

      let yPadding = this.style.includes('thin') ? 4 : 8;
      style = `${style} py-${yPadding}`;
      return style;
    })
  });

  _exports.default = _default;
});