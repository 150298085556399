define("balance-ember/helpers/app-version", ["exports", "balance-ember/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appVersion = appVersion;
  _exports.default = void 0;
  const {
    APP: {
      version
    }
  } = _environment.default;

  function appVersion() {
    return version.replace(/\+.*/, '');
  }

  var _default = Ember.Helper.helper(appVersion);

  _exports.default = _default;
});