define("balance-ember/helpers/type", ["exports", "@microstates/ember/helpers/type"], function (_exports, _type) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _type.default;
    }
  });
  Object.defineProperty(_exports, "type", {
    enumerable: true,
    get: function () {
      return _type.type;
    }
  });
});