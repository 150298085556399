define("balance-ember/components/table/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HqtxpYJw",
    "block": "{\"symbols\":[\"@column\",\"@sortIcons\",\"@extra\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"shouldShowDropdown\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"table/header/label\",[],[[\"@column\",\"@sortIcons\"],[[32,1],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"popover\",[],[[\"@placement\"],[\"bottom\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"rounded overflow-hidden\"],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,4]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[18,4,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[8,\"table/header/dropdown\",[],[[\"@column\",\"@extra\"],[[32,1],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"cursor-default\"],[12],[2,\"\\n    \"],[8,\"table/header/label\",[],[[\"@column\",\"@sortIcons\"],[[32,1],[32,2]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/table/header/template.hbs"
  });

  _exports.default = _default;
});