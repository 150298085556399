define("balance-ember/utils/budget/change-log/record-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RecordDescription {
    constructor({
      name,
      value
    } = {}) {
      this.name = name;
      this.value = value;
    }

  }

  _exports.default = RecordDescription;
});