define("balance-ember/utils/filters/date-formatter", ["exports", "balance-ember/utils/filters/integer-formatter"], function (_exports, _integerFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DateFormatter extends _integerFormatter.default {
    format() {
      if (this.operatorValue === 'relative') {
        return `${this.categoryTitle} ${this.formatValue(this.firstValue).humanize()}`;
      }

      return super.format();
    }

    get operatorDescriptors() {
      return {
        eq: 'equals',
        lte: 'before',
        gte: 'after',
        between: 'between'
      };
    }

  }

  _exports.default = DateFormatter;
});