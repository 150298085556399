define("balance-ember/components/light-table/cell-components/table-actions/edit/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LightTableCellComponentsTableActionsEditComponent extends _component.default {
    get buttonText() {
      return this.args.column.buttonText(this.args.row.content);
    }

    get isEditDisabled() {
      if (this.args.row.content.isDeleted) return true;

      if (this.args.column.isEditDisabled) {
        return this.args.column.isEditDisabled(this.args.row.content);
      }

      return false;
    }

    get editTooltipText() {
      if (this.args.column.editTooltipText && !this.args.row.content.isDeleted) {
        return this.args.column.editTooltipText(this.args.row.content);
      }

      return '';
    }

  }

  _exports.default = LightTableCellComponentsTableActionsEditComponent;
});