define("balance-ember/components/ui-form/ui-fieldset/fieldset-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FxCEO06C",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"span\"],[14,0,\"text-sm text-material-gray700 font-normal w-128 text-right\\n  pr-8 pt-6 inline-block\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/ui-form/ui-fieldset/fieldset-name/template.hbs"
  });

  _exports.default = _default;
});