define("balance-ember/components/manage/overview/monthly-actuals-chart/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/urls", "balance-ember/utils/morph-keys", "moment", "lodash.isequal"], function (_exports, _component, _emberConcurrency, _urls, _morphKeys, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ManageOverviewMonthlyActualsCharts = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember.computed('allMonthsAbbreviated.length', 'availableBudgetLeas.current.{id,startDate}', 'axis.defaultLabelFormatter', 'chartTitle', 'points', 'x'), _dec7 = (0, _emberConcurrency.task)(function* (budgetLea, bookmarkFilters = {}) {
    let results = yield this.ajax.request(`${(0, _urls.URLForTransactions)()}/summaries/months`, {
      data: {
        filter: {
          user_filters: { ...bookmarkFilters,
            budget_lea_id: {
              eq: budgetLea.id
            }
          }
        },
        value: 'month'
      }
    });
    return [budgetLea, this.normalizeJSON(results)];
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    let results = yield (0, _emberConcurrency.all)(this.matchingLeas.map(lea => this.loadMonthlyActuals.perform(lea, this.currentBookmarkFilters)));
    this._previousBookmarkFilters = this.currentBookmarkFilters;
    results = results.map(result => this.formatMonthlyActuals(result)).sort((a, b) => a.fiscalYear > b.fiscalYear ? 1 : -1);
    return results;
  }).drop(), _dec9 = Ember._action, (_class = class ManageOverviewMonthlyActualsCharts extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "availableBudgetLeas", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "seriesData", _descriptor5, this);

      _defineProperty(this, "_previousBookmarkFilters", null);

      _defineProperty(this, "chartTitle", this.intl.t('manage.overview.monthly_spending_chart.chart_title'));

      _defineProperty(this, "allMonths", [...Array(12).keys()].map(i => (0, _moment.default)(i + 1, 'MM').format('MMMM')));

      _defineProperty(this, "allMonthsAbbreviated", [...Array(12).keys()].map(i => (0, _moment.default)(i + 1, 'MM').format('MMM')));

      _initializerDefineProperty(this, "loadMonthlyActuals", _descriptor6, this);

      _initializerDefineProperty(this, "loadAllMonthlyActuals", _descriptor7, this);
    }

    get currentLeaType() {
      return this.store.peekRecord('BudgetLea', this.currentBudgetLeaId).budgetLeaType;
    }

    get currentBudgetLeaId() {
      return this.args.serializedTableState.filter.budget_lea_id.eq;
    }

    get currentBookmarkFilters() {
      return this.args.serializedTableState.filter;
    }

    get isLoading() {
      return !this.loadAllMonthlyActuals.performCount || this.loadAllMonthlyActuals.isRunning;
    }

    get matchingLeas() {
      return this.availableBudgetLeas.all.filterBy('budgetLeaType', this.currentLeaType);
    }

    get options() {
      let that = this; // context for formatter functions that have their own 'this' scope;

      let indexOfFiscalYearStartMonth = (0, _moment.default)(this.availableBudgetLeas.current.startDate).utcOffset(0).format('M') - 1 || 0; // Moment.js numbers its months starting at 1 (Jan), while this.allMonthsAbbreviated is an array of strings with 'Jan' at index 0.
      // Here we subtract 1 from Moment's month number to ensure correct association with this.month's labels

      let monthLabels = this.allMonthsAbbreviated.slice(indexOfFiscalYearStartMonth, this.allMonthsAbbreviated.length).concat(this.allMonthsAbbreviated.slice(0, indexOfFiscalYearStartMonth));
      let fontStack = '"Inter UI", Arial, sans-serif';
      return {
        colors: ['#F48FB1',
        /* Material Pink/200 */
        '#AED581',
        /* Material Light Green/300 */
        '#CE93D8',
        /* Material Purple/200 */
        '#90CAF9',
        /* Material Blue/200 */
        '#9C27B0'
        /* Material Purple/500 */
        ],
        chart: {
          backgroundColor: '#FFFFFF',
          spacing: [12, 30, 30, 30],
          type: 'column'
        },
        legend: {
          align: 'right',
          itemDistance: 20,
          itemStyle: {
            color: '#37474F',

            /* Material Blue Gray/800 */
            fontFamily: fontStack,
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '19px'
          },
          squareSymbol: true,
          symbolHeight: 8,
          symbolPadding: 5,
          symbolRadius: 0,
          // Make square labels
          useHTML: true,
          verticalAlign: 'top'
        },
        title: {
          align: 'left',
          style: {
            color: '#BA68C8',

            /* Material Purple/300 */
            fontFamily: fontStack,
            fontSize: '14px',
            fontWeight: 'bold'
          },
          text: this.chartTitle,
          x: -12,
          y: 18
        },
        tooltip: {
          backgroundColor: '#FFFFFF',
          borderColor: '#9C27B0',

          /* Material Purple/500 */
          borderRadius: 6,
          shared: true,
          outside: true,
          style: {
            fontFamily: fontStack
          },
          useHTML: true,

          formatter() {
            let points = this.points.reduce(function (s, point) {
              return s + '<tr>' + '<td><span class="monthly-actuals__hc-tooltip-marker" style="background-color:' + point.series.color + ';"></span>' + point.series.name + '</td><td>$' + point.y.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }) + '</td></tr>';
            }, '<span class="monthly-actuals__hc-tooltip-title">' + that.intl.t('manage.overview.monthly_spending_chart.tooltip_title', {
              monthName: that.allMonths[that.allMonthsAbbreviated.indexOf(this.x)]
            }) + '</span><table class="monthly-actuals__hc-tooltip-points">');
            return points + '</table>';
          }

        },
        xAxis: {
          categories: monthLabels,
          showEmpty: true,
          labels: {
            style: {
              color: '#40555B',
              fontFamily: fontStack,
              fontSize: '12px'
            }
          }
        },
        yAxis: {
          gridLineColor: '#DCE2EA',
          gridLineDashStyle: 'Dash',
          labels: {
            formatter() {
              return `$${this.axis.defaultLabelFormatter.call(this)}`;
            },

            style: {
              color: '#9EAAB2',
              fontFamily: fontStack,
              fontSize: '11px'
            }
          },
          title: {
            text: ''
          }
        }
      };
    }

    formatMonthlyActuals([{
      startDate,
      endDate,
      description,
      fiscalYear
    }, months]) {
      let leaStart = (0, _moment.default)(startDate).utcOffset(0).format('YYYY-MM-DD');
      let leaEnd = (0, _moment.default)(endDate).utcOffset(0).format('YYYY-MM-DD');
      let dataInRange = months.filter(({
        dateRange
      }) => this.filterByDateRange([leaStart, leaEnd], dateRange));
      let indexOfFiscalYearStartMonth = this.allMonthsAbbreviated.indexOf((0, _moment.default)(startDate).utcOffset(0).format('MMM'));
      let fyCalendar = this.allMonthsAbbreviated.slice(indexOfFiscalYearStartMonth, this.allMonthsAbbreviated.length).concat(this.allMonthsAbbreviated.slice(0, indexOfFiscalYearStartMonth));
      let dataMappedToFyCalendar = fyCalendar.map(monthAbbr => {
        let found = dataInRange.find(({
          month
        }) => _moment.default.utc(month, 'MMMM').isSame(_moment.default.utc(monthAbbr, 'MMM')));
        return found ? [found.month, parseFloat(found.actual)] : [_moment.default.utc(monthAbbr, 'MMM').format('MMMM'), null];
      });
      return {
        name: description,
        data: dataMappedToFyCalendar,
        fiscalYear
      };
    }

    filterByDateRange([leaStart, leaEnd], [monthStart, monthEnd]) {
      return (0, _moment.default)(monthStart).isSameOrAfter(leaStart) && (0, _moment.default)(monthEnd).isSameOrBefore(leaEnd); // We don't compare to monthEnd because we could lose valuable data that started before the end of the lea year
      // If monthStart was 1/01/2020, monthEnd was 1/31/2020, and leaEnd was 1/29/2020 (as is common in our demo data)
      // then we would accidentally filter out the entire month for just a few days of extra data.
      // Ultimately we need to choose here if we opt to be inclusive, or exclusive. We're opting here to be inclusive
      // so as to avoid potential missing data bugs down the road.
    }

    conditionallyLoadAllMonthlyActuals() {
      if (this.hasBookmarkChanged()) return;
      this.loadAllMonthlyActuals.perform();
    }

    hasBookmarkChanged() {
      if (!this._previousBookmarkFilters) return false;
      let previous = { ...this._previousBookmarkFilters
      };
      let current = { ...this.currentBookmarkFilters
      };
      delete previous.budget_lea_id;
      delete current.budget_lea_id;
      return (0, _lodash.default)(previous, current);
    }

    normalizeJSON(json) {
      return json.data.map(record => {
        var _record$attributes;

        if (((_record$attributes = record.attributes) === null || _record$attributes === void 0 ? void 0 : _record$attributes.actual) === null) {
          record.attributes.actual = '0.00';
        }

        return (0, _morphKeys.camelizeKeys)(record.attributes);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "availableBudgetLeas", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "seriesData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "options", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "options"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loadMonthlyActuals", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loadAllMonthlyActuals", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "conditionallyLoadAllMonthlyActuals", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "conditionallyLoadAllMonthlyActuals"), _class.prototype)), _class));
  _exports.default = ManageOverviewMonthlyActualsCharts;
});