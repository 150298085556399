define("balance-ember/types/number-filter", ["exports", "balance-ember/types/basic-filter"], function (_exports, _basicFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NumberFilterSerializer = _exports.default = void 0;

  class NumberFilter extends _basicFilter.default {
    get isRangeMatch() {
      return this.firstOperator && this.firstOperator.value.state === 'between';
    }

    get serializer() {
      return new NumberFilterSerializer(this);
    }

    get totalFilterValues() {
      return new NumberFilterCounter(this).count();
    }

  }

  _exports.default = NumberFilter;

  class NumberFilterSerializer extends _basicFilter.FilterSerializer {
    serializeOperator(operator) {
      if (operator.value.state === 'between') {
        return this.serializeRangeOperator(operator);
      }

      return super.serializeOperator(operator);
    }

    serializeRangeOperator(operator) {
      return [{
        category: this.categoryValue,
        match: operator.value.state,
        values: [...operator.filterValues].map(filterValue => this.serializeFilterValue(filterValue.state))
      }];
    }

  }

  _exports.NumberFilterSerializer = NumberFilterSerializer;

  class NumberFilterCounter extends _basicFilter.FilterCounter {
    countValuesForOperator(operator) {
      if (operator.value.state === 'between') {
        return 1;
      }

      return super.countValuesForOperator(operator);
    }

  }
});