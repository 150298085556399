define("balance-ember/components/budget-notes/edit-inactive/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tgJynKDK",
    "block": "{\"symbols\":[],\"statements\":[[8,\"text-view-with-links\",[],[[\"@placeholder\",\"@text\"],[[32,0,[\"notesPlaceholderText\"]],[32,0,[\"budget\",\"notes\"]]]],null],[2,\"\\n\"],[10,\"section\"],[14,0,\"flex justify-end edit-notes-inactive notes-actions\"],[12],[2,\"\\n  \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-default\",\"sm\",\"edit-note\",[30,[36,0],[[32,0],[32,0,[\"editNotes\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Edit\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/components/budget-notes/edit-inactive/template.hbs"
  });

  _exports.default = _default;
});