define("balance-ember/components/filter-form/multi/filter-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FilterInputBuilder = _exports.FilterUpdater = _exports.FilterInput = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * We are intentionally using a mutable data structure here for reasons of performance. Safari can't
   * handle the input rerendering on each new character added to the field.
   */
  class FilterInput {
    constructor({
      operator = '',
      value = '',
      completed = false
    } = {}) {
      _defineProperty(this, "operator", '');

      _defineProperty(this, "value", '');

      _defineProperty(this, "completed", false);

      this.operator = operator;
      this.value = value;
      this.completed = completed;
    }

    get isCompleted() {
      return this.completed;
    }

    get isValid() {
      return Boolean(this.operator && this.value);
    }

  }

  _exports.FilterInput = FilterInput;

  class FilterUpdater {
    constructor(filter, filterInputs) {
      _defineProperty(this, "filterInputs", null);

      _defineProperty(this, "filter", null);

      this.filter = filter;
      this.filterInputs = filterInputs;
    }

    update() {
      this.filter.operators.set(this.operatorsArray);
    }

    get operatorsArray() {
      return this.getOperatorsArray();
    }

    getOperatorsArray() {
      let groupedOperators = this.groupedOperators;
      return this.groupedOperatorKeys.map(operatorValue => {
        return {
          value: operatorValue,
          filterValues: groupedOperators[operatorValue]
        };
      });
    }

    get groupedOperatorKeys() {
      return Object.keys(this.groupedOperators);
    }

    get groupedOperators() {
      return this.filterInputs.reduce((acc, curr) => {
        let operator = curr.operator;

        if (curr.isValid) {
          acc[operator] = this.getFilterValuesArray(curr, acc[operator]);
        }

        return acc;
      }, {});
    }

    getFilterValuesArray(filterInput, filterValues = []) {
      return filterValues.concat(filterInput.value);
    }

  }

  _exports.FilterUpdater = FilterUpdater;

  class FilterInputBuilder {
    constructor(filter) {
      _defineProperty(this, "filter", null);

      this.filter = filter;
    }

    build() {
      return [...this.filter.operators].reduce((acc, operator) => {
        return acc.concat(this.buildFilterInputsFromOperator(operator));
      }, []);
    }

    buildFilterInputsFromOperator(operator) {
      return [...operator.filterValues].map(filterValue => {
        return new FilterInput({
          completed: true,
          operator: operator.value.state,
          value: filterValue.state
        });
      });
    }

  }

  _exports.FilterInputBuilder = FilterInputBuilder;
});