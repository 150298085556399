define("balance-ember/components/budget/reports/deleted-positions/component", ["exports", "balance-ember/mixins/budgeting-reports", "balance-ember/utils/budget/change-log/record-description"], function (_exports, _budgetingReports, _recordDescription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_budgetingReports.default, {
    reportName: 'deleted-positions',
    defaultSortColumn: 'deleted_at',
    defaultSortDir: 'desc',
    columns: Ember.computed(function () {
      return [{
        label: 'Budget',
        valuePath: 'budgetName',
        sortName: 'budget_name',
        testSelector: 'budget_name',
        cellComponent: 'light-table/cell-components/link-cell',
        route: ['budgeting.budget.expenses', 'budgetId']
      }, {
        label: 'Job Title',
        valuePath: 'jobTitle',
        sortName: 'job_title',
        testSelector: 'job_title',
        cellClassNames: 'text-sm'
      }, {
        label: 'Employee',
        sortName: 'employee_name',
        testSelector: 'employee_name',
        cellClassNames: 'text-sm',
        cellComponent: 'tooltip-cell',

        tooltipText(position) {
          if (position.employeeCode) {
            return `Code: ${position.employeeCode}`;
          }
        },

        cellText(position) {
          return position.employeeName;
        }

      }, {
        label: 'FTE',
        valuePath: 'fte',
        sortName: 'fte',
        testSelector: 'fte',
        cellClassNames: 'tabular-amount text-sm',
        width: '64px'
      }, {
        label: 'Deleted On',
        valuePath: 'deletedAt',
        sortName: 'deleted_at',
        testSelector: 'deleted_at',
        cellType: 'allovue-cell',
        width: '115px',
        cellComponent: 'date-cell',
        timeZoneMode: 'exact',
        align: 'right',
        format: 'MMM Do, YYYY'
      }, {
        cellComponent: 'light-table/cell-components/table-actions',
        tableActionComponents: ['notes', 'recent-changes'],
        recentChanges: {
          recordDescription(position) {
            return [new _recordDescription.default({
              name: 'Position',
              value: position.code
            })];
          }

        },

        buttonText(position) {
          return position.code;
        },

        resourceName: 'Position',
        sortable: false,
        hideable: false,
        align: 'right',
        width: '70px'
      }];
    }),
    categories: Ember.computed(function () {
      return [{
        title: 'Budget',
        value: 'budget_name',
        type: 'text'
      }, {
        title: 'Job Title',
        value: 'job_title',
        type: 'text'
      }, {
        title: 'Employee',
        value: 'employee_name',
        type: 'text'
      }, {
        title: 'Employee Code',
        value: 'employee_code',
        type: 'text'
      }, {
        title: 'FTE',
        value: 'fte',
        type: 'decimal'
      }, {
        title: 'Notes',
        value: 'notes',
        type: 'text'
      }, {
        title: 'Deleted At',
        value: 'deleted_at',
        type: 'date'
      }];
    })
  });

  _exports.default = _default;
});