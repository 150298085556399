define("balance-ember/routes/budgeting/fiscal-year/purchase-orders/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    model({
      purchase_order_id: id
    }) {
      return this.store.query('purchaseOrder', {
        filter: {
          'id': {
            'eq': id
          }
        },
        include: 'allocation.budget,vendor,notes',
        extra_fields: {
          'purchase_orders': 'amount,spent,remaining'
        }
      }).then(purchaseOrders => purchaseOrders.firstObject);
    }

  }

  _exports.default = _default;
});