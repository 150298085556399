define("balance-ember/adapters/nested-under-segment-types", ["exports", "balance-ember/adapters/budgeting", "ember-inflector"], function (_exports, _budgeting, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _budgeting.default.extend({
    buildURL(modelName, id, snapshot, requestType, query) {
      if (requestType !== 'query') {
        let retval = this._super(modelName, id, snapshot, requestType, query);

        return retval;
      }

      let url = [this.host, this.namespace, (0, _emberInflector.pluralize)(Ember.String.underscore('segment-type')), query.segment_type_id, Ember.String.underscore((0, _emberInflector.pluralize)(modelName))].join('/');
      delete query.segment_type_id;
      return url;
    }

  });

  _exports.default = _default;
});