define("balance-ember/components/district-admin/integrations/amazon-business-button/connect/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "85OcpZwF",
    "block": "{\"symbols\":[],\"statements\":[[8,\"button\",[],[[\"@style\",\"@testId\",\"@onclick\"],[\"btn-primary\",\"connect-to-amazon-business\",[32,0,[\"amazonBusiness\",\"connect\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  Connect\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/district-admin/integrations/amazon-business-button/connect/template.hbs"
  });

  _exports.default = _default;
});