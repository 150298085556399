define("balance-ember/components/filter-form/supplement/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UZmAr506",
    "block": "{\"symbols\":[\"@filter\",\"@categoryConfig\"],\"statements\":[[8,\"filter-form/autocomplete-substring\",[],[[\"@filter\",\"@categoryConfig\",\"@noMatchesMessage\",\"@searchTask\"],[[32,1],[32,2],\"No Matches Found\",[32,0,[\"searchSupplementsTask\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/filter-form/supplement/template.hbs"
  });

  _exports.default = _default;
});