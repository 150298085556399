define("balance-ember/components/budget/purchase-orders/form/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/writeable-model", "moment", "balance-ember/utils/validators/purchase-order"], function (_exports, _component, _emberConcurrency, _writeableModel, _moment, _purchaseOrder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const viewPOReportPage = 'view-report';
  const viewPOPage = 'view';

  let _class = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    var _this$validator$valid;

    this.errors = (_this$validator$valid = this.validator.validate()) === null || _this$validator$valid === void 0 ? void 0 : _this$validator$valid.messages;
    if (!Ember.isEmpty(this.errors)) return false;
    return yield this.purchaseOrder.save().then(newPurchaseOrder => {
      this.handleSaveSuccess(newPurchaseOrder);
      return true;
    }).catch(errors => {
      this.errors = errors.mapBy('detail').concat('Please contact your District\'s Amazon Business account administrator or your Amazon Business Customer Advisor for further support.');
      return false;
    });
  }), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class2 = class _class2 extends _component.default {
    get validator() {
      return new _purchaseOrder.default({
        model: this.purchaseOrder,
        intl: this.intl
      });
    }

    constructor() {
      var _purchaseOrder$notes;

      super(...arguments); // We can't initialize the writeable model inside a getter
      // until we upgrade to a version of ember data that includes this fix:
      // https://github.com/emberjs/data/pull/7103

      _initializerDefineProperty(this, "network", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "formattedStartDate", _descriptor5, this);

      _initializerDefineProperty(this, "formattedEndDate", _descriptor6, this);

      _initializerDefineProperty(this, "errors", _descriptor7, this);

      _initializerDefineProperty(this, "save", _descriptor8, this);

      let purchaseOrder = new _writeableModel.default({
        model: this.args.model,
        store: this.store,
        network: this.network
      });

      if (!(purchaseOrder !== null && purchaseOrder !== void 0 && (_purchaseOrder$notes = purchaseOrder.notes) !== null && _purchaseOrder$notes !== void 0 && _purchaseOrder$notes.length)) {
        purchaseOrder.addNote(this.store.createRecord('note', {
          message: ''
        }));
      }

      this.purchaseOrder = purchaseOrder; // explicity set 'active' so the toggle in the UI has a real value

      this.purchaseOrder.active = purchaseOrder.active ?? false; // if editing a PO, fill the date fields with formatted values

      if (!purchaseOrder.isNew) {
        this.formattedStartDate = _moment.default.utc(this.purchaseOrder.startDate).format('L');
        this.formattedEndDate = _moment.default.utc(this.purchaseOrder.endDate).format('L');
      }
    }

    get amount() {
      return this.args.amount ?? this.purchaseOrder.amount;
    }

    handleSaveSuccess(newPurchaseOrder) {
      this.router.transitionTo('budgeting.fiscal-year.purchase-orders.view', newPurchaseOrder.id);
    }

    updateStartDate(unmaskedValue, maskedValue) {
      this.purchaseOrder.startDate = unmaskedValue;
      this.formattedStartDate = maskedValue;
    }

    updateEndDate(unmaskedValue, maskedValue) {
      this.purchaseOrder.endDate = unmaskedValue;
      this.formattedEndDate = maskedValue;
    }

    cancel() {
      let page = this.args.model.isNew ? viewPOReportPage : viewPOPage;
      this.router.transitionTo(`budgeting.fiscal-year.purchase-orders.${page}`);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "network", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "formattedStartDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "formattedEndDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "errors", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "save", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "updateStartDate", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "updateStartDate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateEndDate", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "updateEndDate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancel", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "cancel"), _class2.prototype)), _class2));

  _exports.default = _class;
});