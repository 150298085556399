define("balance-ember/components/benefits-package-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dm0YVS+e",
    "block": "{\"symbols\":[\"T\",\"Controls\",\"Body\",\"Controls\",\"@tableState\",\"@benefitsPackageChanges\",\"@categories\"],\"statements\":[[8,\"table\",[],[[\"@tableState\",\"@columns\",\"@modelChanges\",\"@categories\",\"@trackingPrefix\",\"@tableActions\",\"@loadTask\"],[[32,5],[32,0,[\"columns\"]],[32,6],[32,7],\"Benefits Packages\",[30,[36,1],null,[[\"edit\",\"delete\"],[[30,[36,0],[[32,0],\"edit\"],null],[30,[36,0],[[32,0],\"delete\"],null]]]],[32,0,[\"loadPackages\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,4,[\"filter-button\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,4,[\"pagination\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[4]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"filter-bar\"]],[],[[],[]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[\"@rowTestSelector\",\"@noDataIcon\"],[\"benefits-package-row\",\"na-benefits\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"loader\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Loading Benefits Packages...\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,3,[\"noData\"]],[],[[\"@noDataText\",\"@linkToPath\",\"@buttonText\"],[\"No benefits packages yet.\",\"budgeting.benefits-packages.new\",\"Create Benefits Package\"]],null],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"pagination\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\"]}",
    "moduleName": "balance-ember/components/benefits-package-table/template.hbs"
  });

  _exports.default = _default;
});