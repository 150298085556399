define("balance-ember/components/budget/budgets/table/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/classes/table-serializer"], function (_exports, _component, _emberConcurrency, _tableSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetBudgetsTableComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('budget', { ...this.serializedTableState,
      filter: { ...this.serializedTableState.filter,
        fiscal_year_id: this.availableFiscalYears.current.id
      },
      extra_fields: {
        budgets: 'user_role'
      },
      stats: {
        total: 'count'
      }
    });
  }), _dec16 = Ember.computed('modelChanges.changes.[]'), (_class = class BudgetBudgetsTableComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor5, this);

      _initializerDefineProperty(this, "router", _descriptor6, this);

      _initializerDefineProperty(this, "budgetToDelete", _descriptor7, this);

      _initializerDefineProperty(this, "budgetToEdit", _descriptor8, this);

      _initializerDefineProperty(this, "loadBudgets", _descriptor9, this);
    }

    get columns() {
      return [{
        label: this.intl.t('budget.budget.data.name'),
        valuePath: 'name',
        testSelector: 'budget-name',
        cellComponent: 'light-table/cell-components/link-cell',
        route: ['budgeting.budget', 'id'],
        sortableBy: [{
          value: 'name',
          label: 'Name'
        }],
        trackingEvent: 'View Budget From Index',
        trackingParams: {
          budget: 'name',
          fiscalYear: this.availableFiscalYears.current.name
        }
      }, {
        label: this.intl.t('budget.budget.data.category'),
        valuePath: 'category',
        testSelector: 'budget-category',
        cellComponent: 'budget/budgets/table/category-cell',
        sortableBy: [{
          value: 'category',
          label: this.intl.t('budget.budget.data.category')
        }],
        width: '140px'
      }, {
        label: this.intl.t('budget.budget.data.status'),
        valuePath: 'approvalState',
        testSelector: 'budget-state',
        cellComponent: 'budget/budgets/table/state-cell',
        sortableBy: [{
          value: 'approval_state',
          label: 'State'
        }],
        width: '100px'
      }, ...(this.session.isBudgetArchitect ? [] : [this.roleColumn]), {
        label: this.intl.t('budget.budget.data.allocated_amount'),
        valuePath: 'allocatedAmount',
        testSelector: 'budget-allocated-amount',
        cellComponent: 'number-cell',
        sortableBy: [{
          value: 'allocated_amount',
          label: this.intl.t('budget.budget.data.allocated_amount')
        }],
        width: '140px',
        align: 'right'
      }, {
        label: this.intl.t('budget.budget.data.planned_amount'),
        valuePath: 'plannedAmount',
        testSelector: 'budget-planned-amount',
        cellComponent: 'number-cell',
        sortableBy: [{
          value: 'planned_amount',
          label: this.intl.t('budget.budget.data.planned_amount')
        }],
        width: '140px',
        align: 'right'
      }, {
        label: this.intl.t('budget.budget.data.remaining_amount'),
        valuePath: 'remainingAmount',
        testSelector: 'budget-remaining-amount',
        cellComponent: 'number-cell',
        sortableBy: [{
          value: 'remaining_amount',
          label: this.intl.t('budget.budget.data.remaining_amount')
        }],
        width: '140px',
        align: 'right'
      }, {
        sortable: false,
        hideable: false,
        cellComponent: 'light-table/cell-components/actions-dropdown-cell',
        valuePath: 'name',
        align: 'right',
        width: '100px',
        buttonText: this.getButtonText,
        actions: this.getTableActions
      }];
    }

    get roleColumn() {
      return {
        label: this.intl.t('budget.budget.data.your_role'),
        valuePath: 'userRole',
        testSelector: 'budget-role',
        sortableBy: [{
          value: 'user_role',
          label: 'Role'
        }],
        cellComponent: 'budget/budgets/table/role-cell',
        width: '160px'
      };
    }

    get actionsPopoverColumn() {
      return {
        sortable: false,
        hideable: false,
        cellComponent: 'light-table/cell-components/actions-dropdown-cell',
        valuePath: 'name',
        align: 'right',
        width: '100px',
        buttonText: this.getButtonText,
        actions: this.getTableActions
      };
    }

    getButtonText(budget) {
      return this.intl.t('budget.budget.action_button_text', {
        name: budget.name
      });
    }

    getTableActions() {
      let viewBudgetData = this.viewBudgetData;
      return [{
        label: this.intl.t('budget.budget.view_strategies'),
        testId: 'view-strategies',

        onclick(budget) {
          viewBudgetData('strategies', budget);
        }

      }, {
        label: this.intl.t('budget.budget.view_non_personnel_expenses'),
        testId: 'view-non-personnel-expenses',

        onclick(budget) {
          viewBudgetData('expenses', budget);
        }

      }, {
        label: this.intl.t('budget.budget.view_positions'),
        testId: 'view-positions',

        onclick(budget) {
          viewBudgetData('positions', budget);
        }

      }, {
        label: this.intl.t('budget.budget.view_supplemental_pay'),
        testId: 'view-supplemental-pay',

        onclick(budget) {
          viewBudgetData('supplemental-pay-expenses', budget);
        }

      }, {
        label: this.intl.t('budget.budget.view_access_and_resources'),
        testId: 'view-access-and-resources',

        onclick(budget) {
          viewBudgetData('details', budget);
        }

      }, ...(this.session.isBudgetArchitect ? [this.editButton] : []), ...(this.session.isBudgetArchitect ? [this.deleteButton] : [])];
    }

    get editButton() {
      return {
        label: this.intl.t('global.buttons.edit_name', {
          name: this.intl.t('budget.allocation.data.budget')
        }),
        testId: 'edit-budget',
        onclick: this.edit
      };
    }

    get deleteButton() {
      return {
        component: 'table/actions-popover/delete',
        onclick: this.delete
      };
    }

    edit(budget) {
      this.budgetToEdit = budget;
    }

    delete(budget) {
      this.budgetToDelete = budget;
    }

    afterDestroy(budget) {
      this.budgetToDelete = null;
      this.modelChanges.addChange({
        id: budget.id,
        modelType: 'budget',
        changeType: 'destroy'
      });
    }

    viewBudgetData(route, budget) {
      this.router.transitionTo(`budgeting.budget.${route}`, budget.id);
    }

    get serializedTableState() {
      return new _tableSerializer.default(this.args.tableState).apiQuery;
    }

    get budgetChanges() {
      return this.modelChanges.changesForModelType('budget');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "budgetToDelete", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "budgetToEdit", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getButtonText", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "getButtonText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getTableActions", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "getTableActions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterDestroy", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "afterDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "viewBudgetData", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "viewBudgetData"), _class.prototype), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "loadBudgets", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "budgetChanges", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "budgetChanges"), _class.prototype)), _class));
  _exports.default = BudgetBudgetsTableComponent;
});