define("balance-ember/mixins/non-architect-rerouter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    redirectToNotFound() {
      this._super(...arguments);

      if (this.session.isAuthenticated && !this.session.isBudgetArchitect) {
        this.transitionTo('/not-found');
      }
    }

  });

  _exports.default = _default;
});