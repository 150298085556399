define("balance-ember/adapters/bookmark", ["exports", "balance-ember/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForCreateRecord() {
      let url = this._super(...arguments);

      return this.includeUserInCreateResponse(url);
    },

    includeUserInCreateResponse(url) {
      return `${url}?include=user`;
    }

  });

  _exports.default = _default;
});