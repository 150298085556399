define("balance-ember/adapters/report", ["exports", "balance-ember/adapters/budgeting", "balance-ember/mixins/nested-under-fiscal-year-adapter"], function (_exports, _budgeting, _nestedUnderFiscalYearAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _budgeting.default.extend(_nestedUnderFiscalYearAdapter.default, {
    /**
     * We want the reportType to be in the url not a query param, but we don't want a separate model
     * in the UI for every report type, since it's read-only we can use straight JSON
     */
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this.fiscalYearURL('reports');
      url = `${url}/${query.reportName}`;
      delete query.reportName;
      return url;
    }

  });

  _exports.default = _default;
});