define("balance-ember/controllers/budgeting/budget-priorities/destroy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modelChanges: Ember.inject.service(),
    actions: {
      cancelBudgetPriority() {
        this.model.rollbackAttributes();
        this.transitionToRoute('budgeting.budget-priorities');
      },

      deleteBudgetPriority() {
        let model = this.model;
        return model.destroyRecord().then(() => {
          this.modelChanges.addChange({
            id: model.id,
            modelType: 'budget-priority',
            changeType: 'delete'
          });
          this.transitionToRoute('budgeting.budget-priorities');
        });
      }

    }
  });

  _exports.default = _default;
});