define("balance-ember/components/hint/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MWEPqHqW",
    "block": "{\"symbols\":[\"&default\",\"@preferenceName\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"preferences\"]],[32,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"bg-material-gray100 text-sm p-6\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"global.hints.title\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"flex justify-end mr-20\"],[12],[2,\"\\n      \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-primary-link\",\"sm\",\"hint-dismiss\",[32,0,[\"dismiss\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"global.hints.dismiss\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"get\",\"if\"]}",
    "moduleName": "balance-ember/components/hint/template.hbs"
  });

  _exports.default = _default;
});