define("balance-ember/utils/validators/budget-strategy", ["exports", "balance-ember/utils/validator"], function (_exports, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class _default extends _validator.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "constraints", {
        budgetPriority: {
          presence: {
            allowEmpty: false
          }
        },
        name: {
          presence: {
            allowEmpty: false
          }
        },
        description: {
          presence: {
            allowEmpty: false
          }
        },
        budget: {
          presence: {
            allowEmpty: false
          }
        }
      });

      _defineProperty(this, "constraintPropertyLookups", {
        budgetPriority: 'budget.strategies.data.budget_priority',
        name: 'budget.strategies.data.name',
        description: 'budget.strategies.data.description',
        budget: 'budget.strategies.data.budget'
      });
    }

  }

  _exports.default = _default;
});