define("balance-ember/components/ui-breadcrumbs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param {string} variant The breadcrumb style ('arrow', 'simple')
   * @param {array} breadcrumbs List of breadcrumbs. Each breadcrumb should be in format
   * of { routeName, displayName }
   * @param {string} fontFamily
   * @param {string} fontWeight
   * @param {string} lastBreadcrumbFontWeight
   * @param {string} textSize
   */
  var _default = Ember.Component.extend({
    variant: 'simple',
    lastBreadcrumbIndex: Ember.computed('breadcrumbs.length', function () {
      return this.breadcrumbs.length - 1;
    }),
    breadcrumbClassNames: Ember.computed('variant', function () {
      switch (this.variant) {
        case 'arrow':
          return 'css-breadcrumb-arrow z-20 h-32';

        case 'simple':
          return 'css-breadcrumb-simple';
      }

      return '';
    }),
    fontFamily: 'headline',
    breadcrumbFontFamily: Ember.computed('fontFamily', function () {
      return `font-${this.fontFamily}`;
    }),
    fontWeight: 'medium',
    breadcrumbFontWeight: Ember.computed('fontWeight', function () {
      return `font-${this.fontWeight}`;
    }),
    lastBreadcrumbFontWeight: null,
    lastBreadcrumbFontWeightClass: Ember.computed('breadcrumbFontWeight', 'lastBreadcrumbFontWeight', function () {
      // We want to default to the overall font weight
      return this.lastBreadcrumbFontWeight ? `font-${this.lastBreadcrumbFontWeight}` : this.breadcrumbFontWeight;
    }),
    textSize: 'base',
    breadcrumbTextSize: Ember.computed('textSize', function () {
      return `text-${this.textSize}`;
    })
  });

  _exports.default = _default;
});