define("balance-ember/models/employee", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  const schema = {
    positions: (0, _model.hasMany)('position', {
      async: false
    }),
    employeeStatus: (0, _model.belongsTo)('employeeStatus', {
      async: false
    }),
    fiscalYear: (0, _model.belongsTo)('fiscalYear', {
      async: false
    }),
    actualSalary: (0, _model.attr)('number'),
    budgetedSalary: (0, _model.attr)('number'),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    code: (0, _model.attr)('string'),
    fte: (0, _model.attr)('number'),
    deletable: (0, _model.attr)('boolean'),
    name: (0, _model.attr)('string')
  };
  _exports.schema = schema;

  var _default = _model.default.extend(schema);

  _exports.default = _default;
});