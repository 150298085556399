define("balance-ember/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    availableBudgetLeas: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    isBudgetArchitect: Ember.computed.readOnly('session.isBudgetArchitect'),
    hasManageAccess: Ember.computed.readOnly('session.hasManageAccess'),

    beforeModel() {
      if (this.hasManageAccess) {
        return this.goToManageV3Overview();
      }

      if (this.isBudgetArchitect) {
        return this.goToBudgetOverview();
      }

      this.goToBudgetIndex();
    },

    goToManageV3Overview() {
      return this.transitionTo('manage.overview');
    },

    goToBudgetOverview() {
      return this.transitionTo('budgeting.fiscal-year.budgets', this.availableFiscalYears.current);
    },

    goToBudgetIndex() {
      return this.transitionTo('budgeting.fiscal-year.budgets.index', this.availableFiscalYears.current);
    }

  });

  _exports.default = _default;
});