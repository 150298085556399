define("balance-ember/mixins/operations", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    deletedModels: null,
    newModels: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'deletedModels', []);
      Ember.set(this, 'newModels', []);
    },

    /**
     * mergeNestedChanges - This needs to called before `batchSave`. This will prepare the nested
     * children that need to be saved at the same time as the parent to be sent to the API correctly
     *
     * @param  {object} changes Object with the changed properties. Each property should be a direct
     * replacement of the properties before. New records should be POJOs
     */
    mergeNestedChanges(changes) {
      // Attempt to merge changes for each nested attribute we want to do a batch api call on
      this.acceptsNestedAttributesFor.forEach(nestedAttribute => {
        let oldModels = this[nestedAttribute];
        let newModels = changes[nestedAttribute];
        oldModels.forEach(model => {
          // If the model from the old data isn't in the list of newModels then we know that it's one
          // the user wants to delete
          let shouldDelete = model && newModels.indexOf(model) === -1 && this.deletedModels.indexOf(model) !== 1;

          if (shouldDelete) {
            model.deleteRecord();
            this.deletedModels.push(model);
          }
        });
        newModels.forEach(model => {
          // If the model isn't in the old models it must be new so we need to create a record for it
          // and add it to the parent
          let isNew = oldModels.indexOf(model) === -1;
          let isAlreadyEmberData = Object.prototype.hasOwnProperty.call(model, 'store');
          let newRecord = model;

          if (isNew) {
            // This can accept either DS.Model types or bare Objects. If it's a bare object,
            // we want to delete the id attribute and make a new DS.Model
            if (!isAlreadyEmberData) {
              delete model.id;
              newRecord = this.store.createRecord((0, _emberInflector.singularize)(nestedAttribute), model);
            }

            oldModels.addObject(newRecord);
            this.newModels.push(newRecord);
          }
        });
      });
    },

    /**
     * batchSave - Wrapper around `save` that will prepare models that use `acceptsNestedAttributesFor`
     * to be pushed to the API. It will also handle cleaning up the mess afterwards
     *
     * @return {promise}
     */
    batchSave() {
      return this.save().then(model => {
        // The API will do the actual deletion of this, so we should just remove them so we don't
        // have orphan models
        this.removeDeletedModels(); // Because we are not actually calling `save` on the child models we are creating. We will
        // get a duplicate record created when the api returns with the new relationships.

        this.removeNewModels();
        return model;
      }).catch(() => {
        // If the API call fails we want to remove any Ember Data records we created so we don't
        // leave them orphaned
        this.removeNewModels(); // Reset deleted and new models

        Ember.set(this, 'deletedModels', []);
        Ember.set(this, 'newModels', []);
      });
    },

    removeDeletedModels() {
      this.deletedModels.forEach(model => model.unloadRecord());
    },

    removeNewModels() {
      this.newModels.forEach(model => model.unloadRecord());
    }

  });

  _exports.default = _default;
});