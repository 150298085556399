define("balance-ember/components/tag/form/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9q40l+Jz",
    "block": "{\"symbols\":[\"InlineItem\",\"@cancel\",\"@apply\"],\"statements\":[[10,\"div\"],[14,0,\"flex justify-end items-center\"],[12],[2,\"\\n  \"],[8,\"layout/inline\",[],[[\"@align\",\"@space\"],[\"center\",\"s\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"button\",[],[[\"@style\",\"@onclick\",\"@testId\"],[\"btn-subtle-link\",[32,2],\"cancel-button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"global.buttons.cancel\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"button\",[],[[\"@style\",\"@onclick\",\"@testId\",\"@type\"],[\"btn-primary\",[32,3],\"apply-tags\",\"submit\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"global.buttons.apply\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "balance-ember/components/tag/form/footer/template.hbs"
  });

  _exports.default = _default;
});