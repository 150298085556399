define("balance-ember/components/budget/supplemental-pay-types-table/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency", "balance-ember/classes/table-serializer", "balance-ember/utils/budget/change-log/record-description"], function (_exports, _component, _emberConcurrency, _tableSerializer, _recordDescription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TRACKING_PREFIX = void 0;
  const TRACKING_PREFIX = 'SupplementalPayType';
  _exports.TRACKING_PREFIX = TRACKING_PREFIX;

  var _default = _component.default.extend({
    availableFiscalYears: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    modelChanges: Ember.inject.service(),
    trackingPrefix: TRACKING_PREFIX,
    tableState: null,
    payTypeChanges: Ember.computed('modelChanges.changes.[]', function () {
      return this.modelChanges.changesForModelType('supplementalPayType');
    }),
    columns: Ember.computed(function () {
      let width = '140px';
      let align = 'left';
      let columns = [{
        label: 'Pay Type Name',
        valuePath: 'name',
        sortName: 'name',
        testSelector: 'name',
        cellClassNames: 'border-l print:border-l-0 border-material-gray200 print:avoid-page-break print:header-budgeted',
        classNames: 'border-l border-material-gray200',
        cellComponent: 'overflowing-table-cell',
        align,
        hideable: false,
        ascending: false
      }, {
        label: 'Code',
        valuePath: 'code',
        sortName: 'code',
        testSelector: 'code',
        cellClassNames: 'print:header-code',
        align,
        width,
        hideable: false,
        ascending: false
      }, {
        label: 'Amount',
        valuePath: 'amount',
        sortName: 'amount',
        testSelector: 'amount',
        cellComponent: 'amount-cell',
        cellClassNames: 'print:header-amount pr-64',
        classNames: 'pr-64',
        align: 'right',
        width: '200px',
        hideable: false,
        ascending: false
      }, {
        label: 'Segment Code',
        valuePath: 'segment.code',
        sortName: 'segment',
        sortable: true,
        testSelector: 'segment',
        cellClassNames: 'print:header-segment',
        align,
        hideable: true,
        ascending: false
      }, {
        label: 'Benefits Package',
        valuePath: 'benefitsPackage.name',
        sortName: 'benefits_package',
        sortable: true,
        testSelector: 'benefits-package-name',
        cellClassNames: 'print:header-benefits-package',
        align,
        hideable: true,
        ascending: false
      }, {
        cellComponent: 'light-table/cell-components/table-actions',
        tableActionComponents: ['edit', 'delete', 'recent-changes'],
        sortable: false,
        hideable: false,
        align: 'right',
        width: '80px',
        recentChanges: {
          recordDescription(supplementalPayType) {
            return [new _recordDescription.default({
              name: 'Supplemental Pay Type',
              value: supplementalPayType.name
            })];
          }

        },

        buttonText(supplementalPayType) {
          return supplementalPayType.name;
        },

        editTooltipText(supplementalPayType) {
          return `Edit ${supplementalPayType.name}`;
        },

        isDeleteDisabled(supplementalPayType) {
          return !supplementalPayType.deletable;
        },

        deleteTooltipText(supplementalPayType) {
          if (!supplementalPayType.deletable) {
            return `${supplementalPayType.name} is in use and can't be deleted`;
          }

          return `Delete ${supplementalPayType.name}`;
        }

      }];
      return columns.map(column => {
        return Object.assign({}, column, {
          cellClassNames: `text-15 ${column.cellClassNames}`
        });
      });
    }),
    fetchPayTypes: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('supplementalPayType', { ...this.serializedTableState,
        filter: {
          fiscal_year_id: this.availableFiscalYears.current.id,
          ...this.serializedTableState.filter
        },
        stats: {
          total: 'count'
        },
        include: 'segment,benefits_package'
      });
    }).restartable(),
    serializedTableState: Ember.computed('tableState', function () {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }),
    actions: {
      edit(payType) {
        this.router.transitionTo('budgeting.supplemental-pay-types.edit', payType.id);
      },

      delete(payType) {
        this.router.transitionTo('budgeting.supplemental-pay-types.destroy', payType.id);
      }

    }
  });

  _exports.default = _default;
});