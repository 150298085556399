define("balance-ember/components/budget/file-upload/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WFAkuDwe",
    "block": "{\"symbols\":[\"queue\",\"@name\",\"@for\",\"&default\"],\"statements\":[[8,\"file-upload-button\",[],[[\"@name\",\"@for\",\"@multiple\",\"@accept\",\"@onfileadd\"],[[32,2],[32,3],true,\".docx,.xlsx,.pdf\",[30,[36,0],[[32,0,[\"uploadFileTask\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,4,[[32,1],[32,0,[\"uploadFileTask\"]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"perform\"]}",
    "moduleName": "balance-ember/components/budget/file-upload/input/template.hbs"
  });

  _exports.default = _default;
});