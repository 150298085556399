define("balance-ember/components/export-individual-budget/component", ["exports", "ember-window-mock", "balance-ember/utils/urls"], function (_exports, _emberWindowMock, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    downloadButtonID: Ember.computed(function () {
      return Ember.generateGuid();
    }),
    ajax: Ember.inject.service(),
    timeZone: Ember.inject.service(),
    actions: {
      export(documentType, format) {
        let authString = this.ajax.getAuthString();
        let budgetId = this.budget.id;
        let timeZone = this.timeZone.toParam;
        let reportPath = documentType === 'staff_roster' ? '' : 'reports/';
        _emberWindowMock.default.location.href = `${(0, _urls.URLForBudgetNamespace)()}/budgets/${budgetId}/${reportPath}${documentType}.${format}${authString}&${timeZone}`;
      }

    }
  });

  _exports.default = _default;
});