define("balance-ember/components/budget/csv-upload-dropdown/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency", "ember-window-mock", "ember-inflector", "balance-ember/classes/bulk-upload-error", "balance-ember/classes/report-exporter"], function (_exports, _component, _emberConcurrency, _emberWindowMock, _emberInflector, _bulkUploadError, _reportExporter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    liveChat: Ember.inject.service(),
    fileQueue: Ember.inject.service(),
    session: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    uploadBulk: (0, _emberConcurrency.task)(function* (file) {
      try {
        yield file.upload(this.url, {
          headers: this.ajax.headers
        }).then(this.afterUpload.bind(this));
      } catch (e) {
        this.errorNotify(e, file);
        this.fileQueue.find('bulk').remove(file);
      }
    }).maxConcurrency(3).enqueue(),
    fiscalYearFilter: Ember.computed('availableFiscalYears.current.id', function () {
      return {
        fiscal_year_id: {
          eq: this.availableFiscalYears.current.id
        }
      };
    }),
    exportFilters: Ember.computed('availableFiscalYears.current.id', 'noFiscalYearFilter', 'filters', 'fiscalYearFilter', function () {
      if (this.noFiscalYearFilter) {
        return { ...this.filters
        };
      }

      return { ...this.filters,
        ...this.fiscalYearFilter
      };
    }),

    afterUpload(data) {
      this.successCountNotify(data);
    },

    afterUploadSuccess() {},

    successCountNotify(data) {
      let {
        'total-rows': totalRows,
        ...recordEvents
      } = data.body.meta.details;
      let buildingActions = [];
      Object.keys(recordEvents).forEach(function (key) {
        let recordEvent = recordEvents[key];

        if (Ember.isPresent(recordEvent.count)) {
          buildingActions.push(`
          <div class='rounded bg-material-light-blue500 mb-8'>
              <b class='px-10 rounded-l inline-block bg-material-light-blue400'>${recordEvent.count}</b>
              <span class='px-10'>${key}</span>
          </div>
        `);
        }
      });
      this.notifications.info(`<div>
        <span>${totalRows} ${this.pluralize(totalRows, this.resourceName)} Successfully Uploaded</span>
        <div>${buildingActions.join('')}</div>
      </div>
      `, {
        autoClear: false,
        htmlContent: true
      });
      this.afterUploadSuccess(data);
    },

    errorNotify(e, file) {
      let text = this.errorNotificationText(file, this.showingErrorNumber(e), this.errorCodeMessage(e));
      this.notifications.error(text, {
        autoClear: false,
        htmlContent: true,
        onClick: () => this.liveChat.open()
      });
    },

    errorNotificationText(file, showingErrorNumber, errorCodeMessage) {
      return `
      <div>
        <div class='bg-material-red100 pb-16 pt-12 px-16 rounded text-grey-darkest'>
            <span class='pb-10 pr-10 inline-block leading-tight text-material-gray700'>There was an error uploading ${file.name}</span>
            <span class='text-material-gray700'>${showingErrorNumber}</span>
            ${errorCodeMessage}
        </div>
        <div class='notification-help-text text-sm pt-10'>
          Click this notification to get help via chat.
        </div>
      </div>
    `;
    },

    errorCodeMessage(e) {
      let errorCodeMessage = e;

      if (e.body.errors) {
        errorCodeMessage = e.body.errors.map(error => {
          let errorObj = new _bulkUploadError.default(error);

          if (error.line_number) {
            return this.errorRow(errorObj.pill, errorObj.text);
          }

          return this.errorRow(errorObj.pill, errorObj.text, false);
        }).slice(0, 3).join('');
      }

      return errorCodeMessage;
    },

    showingErrorNumber(e) {
      let errors = e.body.errors || [];

      if (errors.length === 3) {
        return '<div class=\'pb-8 pl-4\'>Showing all 3 errors</div>';
      }

      if (errors.length > 3) {
        return `<div class='pb-8 pl-4'>Showing the first 3 of ${e.body.errors.length} errors</div>`;
      }

      return '';
    },

    errorRow(pill, text, multiRow = 'mb-8') {
      return `
      <div>
          <b class='p-3 px-8 rounded ${multiRow} mr-2 inline-block bg-material-red400'>
              ${pill}
          </b>
          <span class='text-material-gray700'>
              ${text}
          </span>
      </div>`;
    },

    pluralize(count, str) {
      if (count > 1) {
        return (0, _emberInflector.pluralize)(str);
      }

      return str;
    },

    exporter() {
      return new _reportExporter.default({
        availableFiscalYears: this.availableFiscalYears,
        session: this.session,
        ajax: this.ajax,
        tableState: this.tableState,
        categories: this.categories,
        resource: this.resource,
        reportName: this.reportName,
        extraFields: this.extraFields,
        exportType: 'template',
        filters: this.exportFilters,
        now: this.now,
        include: this.include
      });
    },

    actions: {
      uploadBulk(close, file) {
        close();
        this.uploadBulk.perform(file);
      },

      downloadCSVTemplate(close) {
        close();
        _emberWindowMock.default.location.href = `${this.url}${this.ajax.getAuthString()}`;
      },

      export(format, close) {
        this.exporter().export(format);
        close();
      }

    }
  });

  _exports.default = _default;
});