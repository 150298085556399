define("balance-ember/components/list-group-title/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'h6',
    classNames: ['list-group-title', 'text-sm', 'font-normal', 'ml-8', 'mb-16', 'text-material-gray600']
  });

  _exports.default = _default;
});