define("balance-ember/components/positions-table/form/funding/funding-table/component", ["exports", "@glimmer/component", "balance-ember/utils/position-expense-totals"], function (_exports, _component, _positionExpenseTotals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _dec12, _dec13, _dec14, _class3, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PositionsTableExpandedRowEditFundingTableComponent = (_dec12 = Ember.inject.service, _dec13 = Ember.inject.service, _dec14 = Ember._action, (_class3 = class PositionsTableExpandedRowEditFundingTableComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _defineProperty(this, "allocationRows", []);

      this.allocationRows = this._allocationRows();
    }

    _allocationRows() {
      return this.args.allocations.map(allocation => {
        return new AllocationRow({
          position: this.args.position,
          allocation,
          store: this.store,
          session: this.session
        });
      });
    }

    get error() {
      return this.errors.findBy('attribute', 'positionExpenses');
    }

    get errors() {
      var _this$args$errors;

      return ((_this$args$errors = this.args.errors) === null || _this$args$errors === void 0 ? void 0 : _this$args$errors.all) ?? [];
    }

    updateAllocationRows() {
      this.allocationRows = this._allocationRows();
    }

  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "store", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "session", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "updateAllocationRows", [_dec14], Object.getOwnPropertyDescriptor(_class3.prototype, "updateAllocationRows"), _class3.prototype)), _class3));
  _exports.default = PositionsTableExpandedRowEditFundingTableComponent;
  let AllocationRow = (_dec = Ember.computed.readOnly('session.isBudgetArchitect'), _dec2 = Ember.computed('adjustedTotal', 'allocation.remainingAmount', 'expense.percentage', 'originalAmount', 'position.{employee,fte,jobType}'), _dec3 = Ember.computed('expense.percentage', 'position.{employee,jobType,fte}'), _dec4 = Ember.computed('expense.amount', 'position.{employee,jobType,fte}'), _dec5 = Ember.computed('position.fte', 'expense.percentage'), _dec6 = Ember.computed('allocation.id', 'position.positionExpenses.[]'), _dec7 = Ember.computed('allocation.locked', 'isBudgetArchitect', 'session'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class AllocationRow {
    constructor({
      position,
      allocation,
      store,
      session
    }) {
      _defineProperty(this, "originalAmount", 0);

      _initializerDefineProperty(this, "isBudgetArchitect", _descriptor, this);

      Ember.set(this, 'position', position);
      Ember.set(this, 'allocation', allocation);
      this.store = store;
      Ember.set(this, 'session', session);

      if (this.expense) {
        Ember.set(this, 'originalAmount', this.expense.amount);
        Ember.set(this, 'expense.amount', this.adjustedTotal);
      }
    }

    get allocationRemaining() {
      var _this$allocation;

      return (((_this$allocation = this.allocation) === null || _this$allocation === void 0 ? void 0 : _this$allocation.remainingAmount) ?? 0) + this.originalAmount - this.adjustedTotal;
    }

    get adjustedTotal() {
      return new _positionExpenseTotals.default({
        expense: this.expense
      }).adjustedTotal;
    }

    get adjustedPercentage() {
      return new _positionExpenseTotals.default({
        expense: this.expense
      }).adjustedPercentage;
    }

    get fteFunded() {
      return new _positionExpenseTotals.default({
        expense: this.expense
      }).fteFunded;
    }

    get expense() {
      return this.position.positionExpenses.findBy('allocation.id', this.allocation.id);
    }

    get disableButton() {
      return !this.isBudgetArchitect && this.allocation.locked;
    }

    addExpense() {
      let percentage = this.position.remainingPercentage >= 0 ? this.position.remainingPercentage : 0;
      let amount = this.position.budgetedCost * percentage / 100;
      this.position.addPositionExpense(this.store.createRecord('positionExpense', {
        percentage,
        amount,
        allocation: this.allocation
      })); // The `position` is a WriteableModel which ember-data does not understand, so we
      // need to add it after the expense is created

      this.expense.position = this.position;
    }

    removeExpense() {
      this.position.deletePositionExpense(this.expense);
    }

    updateAmount(amount) {
      Ember.set(this, 'expense.amount', amount);
      Ember.set(this, 'expense.percentage', this.adjustedPercentage);
    }

    updatePercentage(percentage) {
      Ember.set(this, 'expense.percentage', percentage);
      Ember.set(this, 'expense.amount', this.adjustedTotal);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isBudgetArchitect", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "allocationRemaining", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "allocationRemaining"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "adjustedTotal", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "adjustedTotal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "adjustedPercentage", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "adjustedPercentage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fteFunded", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "fteFunded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "expense", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "expense"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableButton", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "disableButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addExpense", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "addExpense"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeExpense", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "removeExpense"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAmount", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "updateAmount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePercentage", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "updatePercentage"), _class.prototype)), _class));
});