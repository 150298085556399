define("balance-ember/components/manage/transactions-table/summarize/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CwfGNQNF",
    "block": "{\"symbols\":[\"Body\",\"@summarizeTableState\",\"@summarizeCategories\",\"@trackingPrefix\",\"@onClearSummarize\",\"@onSummarize\"],\"statements\":[[8,\"manage/print-header/summarize\",[],[[\"@modelName\",\"@tableState\",\"@summarizeTableState\",\"@categories\"],[\"transactions\",[32,0,[\"tableState\"]],[32,2],[32,3]]],null],[2,\"\\n\\n\"],[8,\"manage/summarize/data-loader\",[],[[\"@summarizeTableState\",\"@loadTask\",\"@categories\",\"@loadChildrenTask\"],[[32,2],[32,0,[\"loadTask\"]],[32,3],[32,0,[\"loadChildrenTask\"]]]],null],[2,\"\\n\\n\"],[8,\"table/body\",[],[[\"@tableState\",\"@columns\",\"@loadTask\",\"@trackingPrefix\",\"@height\",\"@estimatedRowHeight\",\"@occlusion\",\"@extra\",\"@rowTestSelector\",\"@rowBorderWidth\",\"@headComponent\",\"@headerColor\",\"@noDataIcon\"],[[32,0,[\"tableState\"]],[32,0,[\"summarizeColumns\"]],[32,0,[\"loadTask\"]],[32,4],[30,[36,0],[\"calc(100vh - \",[32,0,[\"navHeight\"]],\")\"],null],72,true,[30,[36,1],null,[[\"loadChildrenTask\",\"loadTask\",\"grandTotals\",\"summarizeState\",\"summarizeCategories\",\"onSummarize\",\"onClearSummarize\",\"headerDropdownHoverColor\",\"headerDropdownActiveColor\"],[[32,0,[\"loadChildrenTask\"]],[32,0,[\"loadTask\"]],[32,0,[\"grandTotals\"]],[32,0,[\"summarizeState\"]],[32,3],[32,6],[32,5],\"material-indigo100\",\"material-indigo-a200\"]]],\"transaction-row\",0,\"manage/transactions-table/summarize/header\",\"white\",\"na-fiscal-year\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"loader\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Loading Transactions...\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"hash\"]}",
    "moduleName": "balance-ember/components/manage/transactions-table/summarize/template.hbs"
  });

  _exports.default = _default;
});