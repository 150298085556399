define("balance-ember/initializers/ember-cli-rails-addon-csrf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    $
  } = Ember;
  var _default = {
    name: 'ember-cli-rails-addon-csrf',

    initialize() {
      $.ajaxPrefilter((options, originalOptions, xhr) => {
        const token = $('meta[name="csrf-token"]').attr('content');
        xhr.setRequestHeader('X-CSRF-Token', token);
      });
    }

  };
  _exports.default = _default;
});