define("balance-ember/components/manage/account-balance-cell/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ManageAccountBalanceCell extends _component.default {
    get percentage() {
      let valuePath = this.args.column.valuePath;
      let percentagePath = `${valuePath}Percentage`;
      let percentage = this.args.row.content[percentagePath];

      if (Ember.isPresent(percentage)) {
        percentage = `${Number(percentage).toFixed(2)}%`;
      }

      return percentage ? percentage : '';
    }

  }

  var _default = ManageAccountBalanceCell;
  _exports.default = _default;
});