define("balance-ember/components/table/body/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    features: Ember.inject.service(),

    /**
     * @param
     */
    style: '',

    /**
     * @param
     */
    fixedHeader: false,

    /**
     * @param
     */
    occlusion: false,

    /**
     * @param
     */
    trackingPrefix: '',

    /**
     * @param
     */
    onRowClick() {},

    extraHash: Ember.computed('extra', 'id', 'selectedRows', 'tableState', 'trackingPrefix', function () {
      let extra = this.extra || {};
      return Object.assign({}, extra, {
        tableState: this.tableState,
        trackingPrefix: this.trackingPrefix,
        selectedRows: this.selectedRows
      });
    }),
    headComponent: 'light-table/default-head-columns',
    rowBorderColor: 'material-gray200',
    rowBorderWidth: 1,
    headerColor: 'material-blue50',
    firstColumnPadding: 'pl-16 pr-4',
    middleColumnPadding: 'pl-2 pr-4',
    lastColumnPadding: 'pl-2 pr-16',
    tableColumns: Ember.computed('columns.[]', function () {
      return this.columns.map((column, index) => {
        let newColumn = this.addColumnClassNames(column, index);
        newColumn = this.addColumnType(newColumn);
        newColumn = this.addColumnCellType(newColumn);
        newColumn = this.addColumnHeaderComponent(newColumn);
        return newColumn;
      });
    }),

    /**
     * Add css properties to the passed in columns to get correct look and feel
     */
    addColumnClassNames(column, index) {
      let cellClasses = this.cellClasses(index); // Put our class names first so they can be easily overridden
      // Header cell classes

      let classNames = `group ${cellClasses} ${column.classNames}`; // Row cell classes

      let verticalPadding = this.verticalPadding(column);
      let cellClassNames = `${cellClasses} ${verticalPadding} ${column.cellClassNames}`;
      return Object.assign({}, column, {
        cellClassNames,
        classNames
      });
    },

    cellClasses(index) {
      if (index === this.firstVisibleIndex) {
        return this.firstColumnPadding;
      }

      if (index === this.columns.length - 1) {
        return this.lastColumnPadding;
      }

      return this.middleColumnPadding;
    },

    firstVisibleIndex: Ember.computed('columns.@each.hidden', function () {
      let firstVisible = this.columns.find(column => !column.hidden);
      return this.columns.indexOf(firstVisible);
    }),

    verticalPadding(column) {
      let classes = column.cellClassNames;
      return classes && classes.includes('py-') ? '' : 'py-12';
    },

    addColumnType(column) {
      // If a column type has not been assigned we'll add allovue-column so column headers
      // can easily have test selectors
      if (!column.type) {
        return Object.assign(column, {
          type: 'allovue-column'
        });
      }

      return column;
    },

    addColumnCellType(column) {
      // If a cell type has not been assigned we'll add allovue-cell so all cells
      // can easily have test selectors
      if (!column.cellType) {
        return Object.assign(column, {
          cellType: 'allovue-cell'
        });
      }

      return column;
    },

    addColumnHeaderComponent(column) {
      if (!column.component) {
        return Object.assign(column, {
          component: 'table/header'
        });
      }

      return column;
    },

    rowClasses: Ember.computed('rowBorderColor', 'rowBorderWidth', 'style', function () {
      let classes = `row-border-b-${this.rowBorderWidth} row-border-${this.rowBorderColor} row-page-break-inside-avoid`;

      if (this.style.includes('hover-row')) {
        classes = `${classes} hover-row:bg-material-light-blue100 cursor-pointer`;
      }

      return classes;
    }),
    headerClasses: Ember.computed('fixedHeader', 'headerColor', function () {
      // If the header is fixed the `lt-head` class gets put up on lt-head. Otherwise it lives as
      // part of the lt-body
      if (this.fixedHeader) {
        return `head-text-sm head-uppercase head-text-navy head-bg-${this.headerColor}`;
      }

      return '';
    }),
    bodyClasses: Ember.computed('customBodyClasses', 'fixedHeader', 'headerColor', 'occlusion', function () {
      let classes = 'text-material-gray700 bg-white';

      if (this.occlusion) {
        classes = `${classes} vertical-collection-align-middle`;
      } else {
        classes = `${classes} body-overflow-y-visible`;
      } // If the header is fixed the `lt-head` class gets put up on lt-head. Otherwise it lives as
      // part of the lt-body


      if (!this.fixedHeader) {
        classes = `${classes}
        head-text-sm
        head-uppercase
        head-text-navy
        head-bg-${this.headerColor}
        head-sticky
        head-top-0
        head-pin-modal-header
        head-z-10
      `;
      }

      return `${classes} ${this.customBodyClasses}`;
    })
  });

  _exports.default = _default;
});