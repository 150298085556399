define("balance-ember/models/benefit", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    segment: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(validations, {
    amount: (0, _model.attr)(),
    benefits_package: (0, _model.belongsTo)('benefits_package', {
      async: false
    }),
    segment: (0, _model.belongsTo)('segment', {
      async: false
    }),
    segments: Ember.computed('segment', function () {
      return [this.segment];
    }),
    // There doesn't seem to be a *cleaner* way to get the type
    // of a polymorphic model. If there is, great, changes welcome.
    // This might also be a problem when we upgrade ember versions.
    // PO/BW 11/4/2016
    benefitType: Ember.computed.readOnly('constructor.modelName'),
    isPercentageBenefit: Ember.computed.equal('benefitType', 'percentage-benefit')
  });

  _exports.default = _default;
});