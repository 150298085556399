define("balance-ember/components/budget/strategies-table/strategy-cell/component", ["exports", "balance-ember/utils/is-text-overflowing"], function (_exports, _isTextOverflowing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isExpanded: false,
    needsExpandLink: Ember.computed('element', function () {
      let descBox = this.element.querySelector('.text-to-truncate');
      let answer = (0, _isTextOverflowing.default)(descBox);
      return answer;
    }),
    expandLinkText: Ember.computed('isExpanded', function () {
      return this.isExpanded ? 'Hide Description' : 'Full Description';
    }),
    actions: {
      toggleExpansion() {
        this.toggleProperty('isExpanded');
      }

    }
  });

  _exports.default = _default;
});