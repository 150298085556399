define("balance-ember/components/allocation-segments-data/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * loadTask - An ember-concurrency task to fetch all the necessary relationships on an allocation
     * We want this to happen each time we try and look at a new allocation
     *
     */
    loadingTask: (0, _emberConcurrency.task)(function* () {
      let allocation = this.allocation;
      yield allocation.sideload('segments');
      Ember.set(this, 'segments', allocation.sortedSegments);
    }),

    didReceiveAttrs() {
      this._super();

      this.loadingTask.perform();
    }

  });

  _exports.default = _default;
});