define("balance-ember/components/highcharts-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "81OSJvsM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,1],[[32,0,[\"height\"]],[30,[36,0],[\"h-\",[32,0,[\"height\"]]],null]],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"rounded h-full\"],[15,1,[32,0,[\"destinationId\"]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\"]}",
    "moduleName": "balance-ember/components/highcharts-chart/template.hbs"
  });

  _exports.default = _default;
});