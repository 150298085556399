define("balance-ember/models/segment-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.computedProperties = _exports.schema = void 0;
  const schema = {
    segments: (0, _model.hasMany)('segment', {
      async: false
    }),
    fiscalYear: (0, _model.belongsTo)('fiscalYear', {
      async: false
    }),
    name: (0, _model.attr)('string'),
    displayOrder: (0, _model.attr)('number'),
    benefitSegment: (0, _model.attr)('boolean'),
    salarySegment: (0, _model.attr)('boolean'),
    expenseSegment: (0, _model.attr)('boolean'),
    subtotalSegment: (0, _model.attr)('boolean'),

    toJSON() {
      let {
        name,
        displayOrder,
        benefitSegment,
        salarySegment,
        expenseSegment,
        subtotalSegment,
        fiscalYear,
        segments
      } = this;
      segments = segments.map(segment => segment.toJSON());
      return {
        name,
        displayOrder,
        benefitSegment,
        salarySegment,
        expenseSegment,
        subtotalSegment,
        segments,
        fiscalYear
      };
    }

  };
  _exports.schema = schema;
  const computedProperties = {
    title: Ember.computed('name', function () {
      return this.name.humanize().titleize();
    })
  };
  _exports.computedProperties = computedProperties;

  var _default = _model.default.extend(schema, computedProperties);

  _exports.default = _default;
});