define("balance-ember/components/district-admin/tag-form/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "01jgPwUN",
    "block": "{\"symbols\":[\"l\"],\"statements\":[[11,\"form\"],[4,[38,2],[\"submit\",[30,[36,1],[[30,[36,0],[[32,0,[\"saveTagTask\"]]],null]],null]],null],[12],[2,\"\\n  \"],[8,\"ui-form/ui-label\",[],[[\"@style\"],[\"\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"text\"]],[],[[\"@style\"],[\"sm\"]],[[\"default\"],[{\"statements\":[[2,\"Name\"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"messages\"]],[],[[\"@errors\"],[[32,0,[\"errors\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[11,\"input\"],[16,2,[32,0,[\"tag\",\"name\"]]],[24,0,\"css-input-field\"],[4,[38,2],[\"input\",[30,[36,4],[\"target.value\",[30,[36,3],[[32,0],\"tag.name\"],null]],null]],null],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mt-8\"],[12],[2,\"\\n    \"],[8,\"button\",[],[[\"@style\",\"@type\",\"@onclick\",\"@testId\"],[\"btn-primary\",\"submit\",[30,[36,1],[[30,[36,0],[[32,0,[\"saveTagTask\"]]],null]],null],\"create-tag-button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Create\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"perform\",\"prevent-default\",\"on\",\"set\",\"pick\"]}",
    "moduleName": "balance-ember/components/district-admin/tag-form/create/template.hbs"
  });

  _exports.default = _default;
});