define("balance-ember/components/budget/reports/position-fill-rates-by-budgets/component", ["exports", "balance-ember/mixins/budgeting-reports"], function (_exports, _budgetingReports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_budgetingReports.default, {
    availableFiscalYears: Ember.inject.service(),
    reportName: 'position-fill-rates-by-budgets',
    defaultSortColumn: 'budget_name',
    columns: Ember.computed(function () {
      let availableFiscalYears = this.availableFiscalYears;
      return [{
        label: 'Job Title',
        valuePath: 'jobTitle',
        sortName: 'job_title',
        testSelector: 'job_title',
        cellClassNames: 'align-top',
        cellComponent: 'overflowing-table-cell',
        width: '250px'
      }, {
        label: 'Budget',
        valuePath: 'budgetName',
        sortName: 'budget_name',
        testSelector: 'budget_name',
        cellClassNames: 'align-top',
        width: '250px',
        cellComponent: 'light-table/cell-components/link-cell',
        route: ['budgeting.budget.expenses', 'budgetId'],
        trackingEvent: 'View Budget From Report',
        trackingParams: {
          budget: 'name',
          fiscalYear: availableFiscalYears.current.name,
          reportName: 'Position Fill Rate by Budget'
        }
      }, {
        label: 'Filled (FTE)',
        valuePath: 'filled',
        sortName: 'filled',
        testSelector: 'filled',
        cellClassNames: 'tabular-amount text-sm'
      }, {
        label: 'Vacant (FTE)',
        valuePath: 'vacant',
        sortName: 'vacant',
        testSelector: 'vacant',
        cellClassNames: 'tabular-amount text-sm'
      }, {
        label: 'Total (FTE)',
        valuePath: 'total',
        sortName: 'total',
        testSelector: 'total',
        cellClassNames: 'tabular-amount text-sm'
      }, {
        label: 'Total ($)',
        valuePath: 'cost',
        sortName: 'cost',
        testSelector: 'cost',
        cellComponent: 'number-cell',
        cellClassNames: 'tabular-amount text-sm'
      }];
    }),
    categories: Ember.computed(function () {
      return [{
        title: 'Job Title',
        value: 'job_title',
        type: 'text'
      }, {
        title: 'Budget',
        value: 'budget_name',
        type: 'text'
      }, {
        title: 'Filled (FTE)',
        value: 'filled',
        type: 'decimal'
      }, {
        title: 'Vacant (FTE)',
        value: 'vacant',
        type: 'decimal'
      }, {
        title: 'Total (FTE)',
        value: 'total',
        type: 'decimal'
      }, {
        title: 'Total ($)',
        value: 'cost',
        type: 'currency'
      }];
    })
  });

  _exports.default = _default;
});