define("balance-ember/components/budget/allocations/table/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/classes/table-serializer", "balance-ember/utils/urls", "ember-concurrency"], function (_exports, _component, _tableSerializer, _urls, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    budgets: null,
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    modelChanges: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    allocationChanges: Ember.computed('modelChanges.changes.[]', function () {
      return this.modelChanges.changesForModelType('allocation');
    }),
    loadTask: (0, _emberConcurrency.task)(function* () {
      return yield Ember.RSVP.all([this.loadAllocations.perform(), this.loadGrandTotals.perform()]).then(([allocations]) => allocations);
    }).restartable(),
    loadAllocations: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('allocationSummary', { ...this.serializedTableState,
        stats: {
          total: 'count'
        }
      });
    }),
    loadGrandTotals: (0, _emberConcurrency.task)(function* () {
      return yield this.ajax.request((0, _urls.URLForAllocationSummariesGrandTotals)(), {
        data: {
          filter: this.grandTotalsFilter(),
          stats: {
            vacant_fte: 'sum',
            total_fte: 'sum',
            allocated_amount: 'sum',
            planned_amount: 'sum',
            remaining_amount: 'sum'
          }
        }
      }).then(results => this.pullOutGrandTotalStats(results.meta.stats));
    }),

    grandTotalsFilter() {
      return new _tableSerializer.default(this.tableState).apiQuery.filter;
    },

    pullOutGrandTotalStats(stats = {}) {
      return Object.keys(stats).reduce((acc, key) => {
        acc[key] = stats[key].sum;
        return acc;
      }, {});
    },

    serializedTableState: Ember.computed('tableState', function () {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }),
    columns: Ember.computed(function () {
      let cols = [{
        label: 'Budget',
        valuePath: 'budgetName',
        sortName: 'budget_name',
        cellComponent: 'light-table/cell-components/link-cell',
        route: ['budgeting.budget.expenses', 'budgetId'],
        cellClassNames: 'font-bold',
        testSelector: 'budget-name'
      }, {
        label: 'Allocation',
        valuePath: 'allocationName',
        sortName: 'allocation_name',
        cellComponent: 'light-table/cell-components/link-cell',
        route: ['budgeting.budget.expenses.view', 'budgetId', 'id'],
        cellClassNames: 'font-bold',
        testSelector: 'allocation-name'
      }, {
        label: 'Salary',
        valuePath: 'salaryType',
        sortName: 'salary_type',
        cellComponent: 'overflowing-table-cell',
        testSelector: 'salary-type',
        width: '80px'
      }, {
        label: 'Locked Segments',
        valuePath: 'lockedSegmentCount',
        sortName: 'locked_segment_count',
        cellComponent: 'budget/allocations/table/segment-count-cell',
        align: 'center',
        testSelector: 'locked-segments',
        cellClassNames: 'text-center'
      }, {
        label: 'Vacant',
        valuePath: 'vacantFte',
        sortName: 'vacant_fte',
        testSelector: 'vacant-fte',
        width: '95px',
        align: 'right'
      }, {
        label: 'Total FTE',
        valuePath: 'totalFte',
        sortName: 'total_fte',
        testSelector: 'total-fte',
        width: '95px',
        align: 'right'
      }, {
        label: 'Allocated',
        valuePath: 'allocatedAmount',
        sortName: 'allocated_amount',
        testSelector: 'allocated-amount',
        cellComponent: 'number-cell',
        shouldAbbreviate: true,
        width: '120px',
        align: 'right'
      }, {
        label: 'Planned',
        valuePath: 'plannedAmount',
        sortName: 'planned_amount',
        testSelector: 'planned-amount',
        cellComponent: 'number-cell',
        shouldAbbreviate: true,
        width: '120px',
        align: 'right'
      }, {
        label: 'Remaining',
        valuePath: 'remainingAmount',
        sortName: 'remaining_amount',
        testSelector: 'remaining-amount',
        cellComponent: 'number-cell',
        shouldAbbreviate: true,
        width: '120px',
        align: 'right'
      }];
      return cols;
    })
  });

  _exports.default = _default;
});