define("balance-ember/utils/filters/integer-formatter", ["exports", "balance-ember/utils/filters/formatter"], function (_exports, _formatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IntegerFormatter extends _formatter.default {
    format() {
      if (this.operatorValue === 'between') {
        return `${this.categoryTitle} ${this.operatorDescriptor} ${this.formatValue(this.firstValue)} and ${this.formatValue(this.secondValue)}`;
      }

      return super.format();
    }

    get operatorDescriptors() {
      return {
        eq: 'equals',
        lte: 'less than',
        gte: 'greater than',
        between: 'between'
      };
    }

    get secondValue() {
      return this.filter.firstOperator.secondFilterValue;
    }

  }

  _exports.default = IntegerFormatter;
});