define("balance-ember/utils/autocomplete-announcement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAutocompleteResultsMessage = getAutocompleteResultsMessage;
  _exports.default = autocompleteAnnouncement;

  function getAutocompleteResultsMessage(numResults) {
    return `${numResults} results are available. Keyboard users use up and down arrows to review and enter to select. Touch device users explore by touch or with swipe gestures`;
  }
  /**
   * autocompleteAnnouncement - Use this to give screenreaders a message when results change
   *
   * @param  {array} results       The list of results
   * @param  {service} a11yAnnouncer The a11y-announcer service
   * @return {array}               The results
   */


  function autocompleteAnnouncement(results, a11yAnnouncer) {
    let numResults = results.length;

    if (numResults > 0) {
      a11yAnnouncer.sendMessage(getAutocompleteResultsMessage(numResults));
    } else {
      a11yAnnouncer.sendMessage('No search results');
    }

    return results;
  }
});