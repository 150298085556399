define("balance-ember/classes/report-exporter", ["exports", "moment", "balance-ember/utils/query-params", "ember-window-mock", "balance-ember/utils/filters/format-display-text", "balance-ember/utils/urls", "balance-ember/classes/table-serializer"], function (_exports, _moment, _queryParams, _emberWindowMock, _formatDisplayText, _urls, _tableSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ReportExporter {
    constructor({
      availableFiscalYears,
      session,
      ajax,
      tableState,
      categories,
      resource,
      reportName,
      extraFields,
      filters,
      exportType,
      now,
      include
    } = {}) {
      this.availableFiscalYears = availableFiscalYears;
      this.session = session;
      this.ajax = ajax;
      this.tableState = tableState;
      this.categories = categories;
      this.resource = resource;
      this.reportName = reportName;
      this.extraFields = extraFields;
      this.filters = filters;
      this.exportType = exportType;
      this.now = now;
      this.include = include;
    }

    get displayFilters() {
      return (0, _formatDisplayText.formatFilters)(this.tableState.filters, this.categories);
    }

    get fiscalYear() {
      return this.availableFiscalYears.current;
    }

    get currentTime() {
      return this.now ?? new Date();
    }

    get serializedTableState() {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }

    get user() {
      return this.session.currentUser;
    }

    export(format) {
      var _this$fiscalYear, _this$fiscalYear2;

      let params = { ...this.serializedTableState,
        filter: { ...this.serializedTableState.filter,
          ...this.filters
        },
        paginate: false,
        resource: this.resource,
        report_format: format,
        filename: `${this.reportName} for ${(_this$fiscalYear = this.fiscalYear) === null || _this$fiscalYear === void 0 ? void 0 : _this$fiscalYear.name}`,
        extra_fields: this.extraFields,
        export_type: this.exportType,
        include: this.include,
        header_rows: [(_this$fiscalYear2 = this.fiscalYear) === null || _this$fiscalYear2 === void 0 ? void 0 : _this$fiscalYear2.name, this.reportName, `Generated By: ${this.user.firstName} ${this.user.lastName}`, `Generated At: ${(0, _moment.default)(this.currentTime).format('MMM D, YYYY H:mmA')}`, ...(this.displayFilters.length ? ['Filters:'] : []), ...this.displayFilters]
      };
      delete params.page;
      let authString = this.ajax.getAuthString();
      _emberWindowMock.default.location.href = `${(0, _urls.URLForExports)()}${authString}&${_queryParams.default.stringify(params)}`;
    }

  }

  _exports.default = ReportExporter;
});