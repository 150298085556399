define("balance-ember/components/budget/file-upload/file-name-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/hSr9C1q",
    "block": "{\"symbols\":[\"@extra\",\"@row\",\"@value\"],\"statements\":[[11,\"a\"],[16,6,[32,0,[\"url\"]]],[4,[38,1],[\"Download a Shared Document\",[30,[36,0],null,[[\"fileName\",\"budget\",\"fiscalYear\"],[[32,2,[\"name\"]],[32,1,[\"budget\",\"name\"]],[32,1,[\"fiscalYear\",\"name\"]]]]]],null],[12],[2,\"\\n  \"],[8,\"overflowing-text\",[[16,\"height\",40]],[[\"@text\"],[[32,3]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"track-event\"]}",
    "moduleName": "balance-ember/components/budget/file-upload/file-name-cell/template.hbs"
  });

  _exports.default = _default;
});