define("balance-ember/components/light-table/cell-components/pretty-value/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    prettyValue: Ember.computed('column.valuePath', 'row.content', function () {
      let pv = this.row.content[this.column.valuePath];
      return Ember.String.decamelize(pv).humanize().titleize();
    })
  });

  _exports.default = _default;
});