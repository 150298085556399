define("balance-ember/components/radio-highlight/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    shouldHighlight: Ember.computed('value', 'option', function () {
      return this.value === this.option;
    })
  }).reopenClass({
    positionalParams: ['value']
  });

  _exports.default = _default;
});