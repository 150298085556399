define("balance-ember/components/manage/bookmark-dropdown/row/reminder/monthly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iecvhnQv",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-form/ui-label\",[],[[\"@style\",\"@for\"],[\"hidden\",\"month-days-dropdown\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  Select Day\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"one-way-select\",[[24,1,\"month-days-dropdown\"]],[[\"@value\",\"@classNames\",\"@options\",\"@update\"],[[32,0,[\"selectedDay\"]],\"simple-select mb-0\",[32,0,[\"dayOptions\"]],[32,0,[\"updateDay\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/manage/bookmark-dropdown/row/reminder/monthly/template.hbs"
  });

  _exports.default = _default;
});