define("balance-ember/components/budget/budgets/buttons/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dPeNhn8W",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"icon-button\",[],[[\"@style\",\"@icon\",\"@testId\",\"@buttonText\",\"@displayText\",\"@onclick\"],[\"btn-primary\",\"circle-plus\",\"create-budget-button\",\"Create Budget\",\"Budget\",[30,[36,0],[[32,0],\"showForm\",true],null]]],null],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"showForm\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"budget/budgets/form-data\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"budget/budgets/form\",[],[[\"@allBudgetUsers\",\"@style\",\"@budget\",\"@amazonVendor\",\"@loadTask\",\"@cancel\",\"@actionButtonText\",\"@disabledButtonText\",\"@formTitle\"],[[32,1,[\"budgetUsers\"]],\"slideout wide padded\",[32,1,[\"budget\"]],[32,1,[\"amazonVendor\"]],[32,1,[\"loadTask\"]],[30,[36,0],[[32,0],\"showForm\",false],null],\"Create Budget\",\"Creating\",\"Create Budget\"]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"set\",\"if\"]}",
    "moduleName": "balance-ember/components/budget/budgets/buttons/create/template.hbs"
  });

  _exports.default = _default;
});