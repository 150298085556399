define("balance-ember/components/budget/district-wide-files/upload-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DNPpU0Xa",
    "block": "{\"symbols\":[\"queue\",\"uploadFileTask\",\"@for\",\"@uploadFile\"],\"statements\":[[8,\"budget/district-wide-files/input\",[],[[\"@for\",\"@uploadFile\"],[[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"budget/file-upload/button\",[],[[\"@uploadFileTask\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/budget/district-wide-files/upload-button/template.hbs"
  });

  _exports.default = _default;
});