define("balance-ember/components/icon-button/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class IconButtonComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "svgSizes", {
        xsm: 'h-12 w-12',
        sm: 'h-14 w-14',
        md: 'h-16 w-16',
        lg: 'h-16 w-16',
        xlg: 'h-20 w-20'
      });

      (false && !(this.args.buttonText) && Ember.assert("You must pass unique 'buttonText' to edit-button for a11y", this.args.buttonText));
    }

    get showButtonText() {
      return this.args.showButtonText ?? false;
    }

    get shouldShowTooltip() {
      return !(this.showButtonText || this.args.displayText);
    }

    get tooltipTitle() {
      return this.args.tooltipText ?? this.args.buttonText;
    }

    get iconClasses() {
      return ['pointer-events-none', this.svgSizeClasses].join(' ');
    }

    get size() {
      return this.args.size ?? 'md';
    }

    get svgSizeClasses() {
      if (this.args.svgSize) {
        return this.args.svgSize;
      }

      return this.svgSizes[this.size];
    }

  }

  _exports.default = IconButtonComponent;
});