define("balance-ember/routes/budgeting/benefits-packages/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      benefits_package_id: id
    }) {
      return this.store.findRecord('benefits-package', id);
    }

  });

  _exports.default = _default;
});