define("balance-ember/components/job-types-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WD/AA8xR",
    "block": "{\"symbols\":[\"T\",\"Controls\",\"Body\",\"Controls\",\"@tableState\",\"@categories\",\"@jobTypeChanges\"],\"statements\":[[8,\"table\",[],[[\"@tableState\",\"@columns\",\"@tableActions\",\"@loadTask\",\"@categories\",\"@trackingPrefix\",\"@modelChanges\"],[[32,5],[32,0,[\"columns\"]],[30,[36,0],null,[[\"edit\",\"delete\"],[[32,0,[\"edit\"]],[32,0,[\"delete\"]]]]],[32,0,[\"fetchJobTypes\"]],[32,6],\"Job Types\",[32,7]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,4,[\"filter-button\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,4,[\"pagination\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[4]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"filter-bar\"]],[],[[],[]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[\"@rowTestSelector\",\"@noDataIcon\"],[\"job-type-row\",\"na-job-types\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"loader\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Loading Job Types...\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,3,[\"noData\"]],[],[[\"@noDataText\",\"@linkToPath\",\"@buttonText\"],[\"No job types yet.\",\"budgeting.job-types.new\",\"Add Job Type\"]],null],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"pagination\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "balance-ember/components/job-types-table/template.hbs"
  });

  _exports.default = _default;
});