define("balance-ember/utils/writeable-models/position-expense", ["exports", "balance-ember/utils/position-expense-totals", "balance-ember/utils/writeable-models/expense"], function (_exports, _positionExpenseTotals, _expense) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PositionExpenseWriteableModel = (_dec = Ember.computed('percentage', 'position'), _dec2 = Ember.computed('percentage', 'position'), _dec3 = Ember.computed('percentage', 'position'), _dec4 = Ember.computed('percentage', 'position'), _dec5 = Ember.computed('position'), _dec6 = Ember.computed('percentage', 'position'), _dec7 = Ember.computed('position'), _dec8 = Ember.computed('position', 'percentage'), (_class = class PositionExpenseWriteableModel extends _expense.default {
    constructor(args) {
      if (!args.paths) {
        args.paths = {
          position: {
            positionExpenses: true,
            employee: true,
            jobType: {
              benefitsPackage: {
                flatRateBenefits: true,
                percentageBenefits: true
              }
            }
          },
          segments: {
            segmentType: true
          },
          allocation: {
            budget: true,
            segments: {
              segmentType: true
            }
          },
          expenseStrategies: {
            budgetStrategy: {
              budgetPriority: true
            }
          }
        };
      }

      super(args);
    }

    get allocationRemaining() {
      return new _positionExpenseTotals.default({
        expense: this
      }).allocationRemaining;
    }

    get adjustedTotal() {
      return new _positionExpenseTotals.default({
        expense: this
      }).adjustedTotal;
    }

    get positionTotal() {
      return new _positionExpenseTotals.default({
        expense: this
      }).positionTotal;
    }

    get adjustedBenefits() {
      return new _positionExpenseTotals.default({
        expense: this
      }).adjustedBenefits;
    }

    get benefitsAmount() {
      return new _positionExpenseTotals.default({
        expense: this
      }).benefitsAmount;
    }

    get adjustedSalary() {
      return new _positionExpenseTotals.default({
        expense: this
      }).adjustedSalary;
    }

    get salaryAmount() {
      return new _positionExpenseTotals.default({
        expense: this
      }).salaryAmount;
    }

    get fteFunded() {
      return new _positionExpenseTotals.default({
        expense: this
      }).fteFunded;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "allocationRemaining", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "allocationRemaining"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "adjustedTotal", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "adjustedTotal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "positionTotal", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "positionTotal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "adjustedBenefits", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "adjustedBenefits"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "benefitsAmount", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "benefitsAmount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "adjustedSalary", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "adjustedSalary"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "salaryAmount", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "salaryAmount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fteFunded", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "fteFunded"), _class.prototype)), _class));
  _exports.default = PositionExpenseWriteableModel;
});