define("balance-ember/components/light-table/cell-components/tag-cell/trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QIBlrz3R",
    "block": "{\"symbols\":[\"@row\",\"@tags\"],\"statements\":[[8,\"button\",[],[[\"@size\",\"@style\",\"@testId\"],[\"xsm\",\"btn-primary-light\",[31,[\"tag-form-trigger-\",[32,1,[\"id\"]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,2,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[12],[1,[30,[36,0],[\"global.buttons.edit\"],null]],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[1,[30,[36,0],[\"global.tags.edit_tag_for\"],[[\"name\"],[[32,0,[\"buttonName\"]]]]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[12],[2,\" +\"],[1,[30,[36,0],[\"global.buttons.add\"],null]],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[1,[30,[36,0],[\"global.tags.add_tag_for\"],[[\"name\"],[[32,0,[\"buttonName\"]]]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "balance-ember/components/light-table/cell-components/tag-cell/trigger/template.hbs"
  });

  _exports.default = _default;
});