define("balance-ember/components/button/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ButtonComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember.computed('args.{active,route}', 'argsWithQueryParams', 'isLink', 'router.currentURL'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class ButtonComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "outlineHidden", _descriptor2, this);

      _defineProperty(this, "textSizeOptions", {
        xlg: 'text-xl',
        lg: 'text-base',
        md: 'text-base',
        sm: 'text-sm',
        xsm: 'text-13'
      });

      _defineProperty(this, "paddingSizeOptions", {
        xlg: 'px-32 py-18',
        lg: 'px-20 py-12',
        md: 'px-16 py-8',
        sm: 'px-10 py-7',
        xsm: 'px-8 py-6'
      });
    }

    get isLink() {
      var _this$args$route;

      return ((_this$args$route = this.args.route) === null || _this$args$route === void 0 ? void 0 : _this$args$route.length) ?? this.args.href ?? this.args.isLink;
    }

    get commonClasses() {
      return ['btn', 'group', 'outline-material-light-blue300'].join(' ');
    }

    get textSizeClasses() {
      return this.textSizeOptions[this.size];
    }

    get paddingClasses() {
      var _this$style;

      if ((_this$style = this.style) !== null && _this$style !== void 0 && _this$style.includes('-link') || this.args.size === 'none') {
        return '';
      }

      return this.paddingSizeOptions[this.size];
    }

    get style() {
      return this.args.style ?? 'btn-default';
    }

    get size() {
      return this.args.size ?? 'md';
    }

    get onclick() {
      return this.args.onclick ?? function () {};
    }

    get type() {
      return this.args.type ?? 'button';
    }

    get href() {
      if (!this.args.href && !this.args.route) {
        return undefined;
      }

      return this.args.href ?? this.router.urlFor(...this.argsWithQueryParams);
    }

    get isActive() {
      return this.isLink && this.args.route ? this.router.isActive(...this.argsWithQueryParams) : this.args.active;
    }

    get argsWithQueryParams() {
      let route = [...this.args.route];
      let last = route.lastObject;

      if (last && last.isQueryParams) {
        let queryParams = { ...last.values
        }; // Replace the last argument with a proper queryParams object

        route = route.slice(0, route.length - 1);
        route = route.concat({
          queryParams
        });
      }

      return route;
    }

    transition(evt) {
      if (this.args.onclick) {
        evt.preventDefault();
        return this.args.onclick();
      }

      if (this.args.href || !this.args.route) {
        return true;
      } // Default link behavior


      evt.preventDefault();

      if (this.args.replace) {
        return this.router.replaceWith(...this.argsWithQueryParams);
      }

      return this.router.transitionTo(...this.argsWithQueryParams);
    }

    mousedown() {
      this.outlineHidden = 'true';
    }

    blur() {
      this.outlineHidden = 'false';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "outlineHidden", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isActive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transition", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "transition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mousedown", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "mousedown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "blur", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "blur"), _class.prototype)), _class));
  _exports.default = ButtonComponent;
});