define("balance-ember/components/budget/allocations/buttons/upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Azy7x3Wq",
    "block": "{\"symbols\":[\"@tableState\",\"@categories\"],\"statements\":[[8,\"budget/csv-upload-dropdown\",[[24,\",\",\"\"]],[[\"@url\",\"@resourceName\",\"@afterUploadSuccess\",\"@resource\",\"@reportName\",\"@tableState\",\"@categories\",\"@extraFields\",\"@include\"],[[32,0,[\"allocationBulkUrl\"]],\"Allocation\",[30,[36,0],[[32,0],\"afterUploadSuccess\"],null],\"allocation\",\"Allocation Template\",[32,1],[32,2],[30,[36,2],null,[[\"allocations\"],[[30,[36,1],[\"budget_name\"],null]]]],\"segments\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"array\",\"hash\"]}",
    "moduleName": "balance-ember/components/budget/allocations/buttons/upload/template.hbs"
  });

  _exports.default = _default;
});