define("balance-ember/helpers/state", ["exports", "@microstates/ember/helpers/state"], function (_exports, _state) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _state.default;
    }
  });
  Object.defineProperty(_exports, "state", {
    enumerable: true,
    get: function () {
      return _state.state;
    }
  });
});