define("balance-ember/components/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T/ceYrZq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"form\"],[4,[38,2],[\"submit\",[30,[36,1],[[30,[36,0],[[32,0,[\"submitTask\"]]],null]],null]],null],[12],[2,\"\\n  \"],[18,1,[[30,[36,4],null,[[\"SubmitButton\",\"LabelGroup\"],[[30,[36,3],[\"button\"],[[\"type\",\"disabled\",\"testId\"],[\"submit\",[32,0,[\"submitTask\",\"isRunning\"]],\"save-button\"]]],[30,[36,3],[\"form/label-group\"],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"perform\",\"prevent-default\",\"on\",\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/form/template.hbs"
  });

  _exports.default = _default;
});