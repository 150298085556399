define("balance-ember/controllers/budgeting/benefits-packages", ["exports", "balance-ember/mixins/table-controller"], function (_exports, _tableController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_tableController.default, {
    router: Ember.inject.service(),
    modelChanges: Ember.inject.service(),
    categories: Ember.computed(function () {
      return [{
        title: 'Name',
        value: 'name',
        type: 'text'
      }];
    }),

    init() {
      this._super(...arguments);

      this.tableState.defaultSort.set({
        column: 'name',
        dir: 'asc'
      });
    },

    benefitsPackageChanges: Ember.computed('modelChanges.changes.[]', function () {
      return this.modelChanges.changesForModelType('benefitsPackage');
    }),
    actions: {
      edit(id) {
        this.router.transitionTo('budgeting.benefits-packages.edit', id);
      },

      delete(id) {
        this.router.transitionTo('budgeting.benefits-packages.destroy', id);
      }

    }
  });

  _exports.default = _default;
});