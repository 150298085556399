define("balance-ember/components/amount-cell/component", ["exports", "balance-ember/utils/money-math"], function (_exports, _moneyMath) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param {string} value The amount
   * @param {boolean} column.highlightDeficit If negative amounts should be highlighted with a
   * different color
   * @param {boolean} column.shouldDisplayPercentages Whether or not to show percentages
  
   */
  var _default = Ember.Component.extend({
    classNames: ['text-right'],
    classNameBindings: ['isZero:text-material-gray400'],
    attributeBindings: ['testSelector:data-test-amount-cell'],
    testSelector: Ember.computed.readOnly('column.columnValue'),
    shouldDisplayPercentages: Ember.computed.readOnly('column.shouldDisplayPercentages'),

    /**
     * percentageClass - Do this here because it saves about 100ms
     */
    percentageClass: Ember.computed('shouldDisplayPercentages', function () {
      return this.shouldDisplayPercentages ? 'block' : 'hidden';
    }),

    /**
     * isZero - We want to make zero values not stand out
     */
    isZero: Ember.computed('value', function () {
      return (0, _moneyMath.isZero)(this.value);
    }),
    amountRatios: Ember.computed.readOnly('row.content.amountRatios'),
    percentage: Ember.computed('column.columnValue', 'amountRatios', function () {
      let amountType = this.column.columnValue;
      if (!amountType || !this.amountRatios) return '%';
      let ratio = this.amountRatios[amountType];
      let amount = Ember.isPresent(ratio) ? ratio * 100 : 0;
      return `${amount.toFixed(2)}%`;
    })
  });

  _exports.default = _default;
});