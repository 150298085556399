define("balance-ember/components/light-table/cell-components/table-actions/recent-changes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xGIyOsRB",
    "block": "{\"symbols\":[\"@class\",\"@row\",\"@column\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"flex items-center leading-0 px-4 \",[32,1]]]],[12],[2,\"\\n  \"],[8,\"recent-changes-button\",[],[[\"@record\",\"@buttonText\",\"@recordDescription\",\"@resourceName\",\"@include\",\"@type\"],[[32,2,[\"content\"]],[32,0,[\"buttonText\"]],[32,0,[\"recordDescription\"]],[32,3,[\"resourceName\"]],[32,3,[\"recentChanges\",\"include\"]],[32,3,[\"recentChanges\",\"type\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/light-table/cell-components/table-actions/recent-changes/template.hbs"
  });

  _exports.default = _default;
});