define("balance-ember/components/pill/after/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CP9QmDd6",
    "block": "{\"symbols\":[\"@color\",\"@value\",\"@valueClass\"],\"statements\":[[8,\"pill/label\",[],[[\"@name\",\"@color\",\"@value\",\"@valueClass\",\"@pillWidth\"],[\"After\",[30,[36,0],[[32,1],\"bg-material-green400\"],null],[32,2],[32,3],\"w-64\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\"]}",
    "moduleName": "balance-ember/components/pill/after/template.hbs"
  });

  _exports.default = _default;
});