define("balance-ember/components/user/profile-tabs/manage-permissions/delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DoqmFs4M",
    "block": "{\"symbols\":[\"p\",\"@user\",\"@budgetLea\"],\"statements\":[[8,\"user/profile-tabs/manage-permissions/form-provider\",[],[[\"@user\",\"@afterSave\",\"@afterUndo\"],[[32,2],[30,[36,0],[[32,0],\"afterSave\"],null],[30,[36,0],[[32,0],\"cancel\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"modal/destroy\",[],[[\"@closeModal\",\"@destroy\",\"@model\"],[[32,1,[\"actions\",\"undo\"]],[30,[36,0],[[32,0],\"delete\",[32,3],[32,1,[\"data\"]],[32,1,[\"tasks\",\"saveUser\"]],[32,1,[\"user\"]]],null],[32,1,[\"user\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      Are you sure you want to remove\\n      \"],[10,\"span\"],[14,0,\"font-bold\"],[12],[2,\" \"],[1,[32,2,[\"name\"]]],[2,\"'s\"],[13],[2,\" access to\\n      \"],[10,\"span\"],[14,0,\"font-bold whitespace-nowrap\"],[12],[1,[32,3,[\"name\"]]],[13],[2,\"?\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/components/user/profile-tabs/manage-permissions/delete/template.hbs"
  });

  _exports.default = _default;
});