define("balance-ember/components/budget/non-personnel-expenses-table/component", ["exports", "@glimmer/component", "@microstates/ember", "balance-ember/utils/budget/change-log/record-description", "balance-ember/classes/table-serializer", "ember-concurrency", "balance-ember/types/table"], function (_exports, _component, _ember, _recordDescription, _tableSerializer, _emberConcurrency, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetNonPersonnelExpensesTableComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember.computed('modelChanges.changes.[]'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = (0, _emberConcurrency.task)(function* () {
    let expenses = yield this.store.query('nonPersonnelExpense', { ...this.serializedTableState,
      extra_fields: {
        'non_personnel_expenses': 'amount,account_string,has_items',
        'allocations': 'editable'
      },
      include: 'notes,vendor,purchase_order,allocation.budget',
      filter: this.args.filter,
      stats: {
        total: 'count'
      }
    });
    return expenses;
  }), _dec12 = Ember._action, _dec13 = Ember._action, (_class = class BudgetNonPersonnelExpensesTableComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor2, this);

      _initializerDefineProperty(this, "features", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "expenseItemsTableState", _descriptor5, this);

      _initializerDefineProperty(this, "loadTask", _descriptor6, this);
    }

    get nonPersonnelExpenseChanges() {
      return this.modelChanges.changesForModelType('nonPersonnelExpense');
    }

    get showPurchaseOrder() {
      let rowsWithPurchaseOrders = (0, _ember.valueOf)(this.expenseItemsTableState.rows).filter(row => row.purchaseOrder);
      return rowsWithPurchaseOrders.length > 0 && this.args.showAvailablePurchaseOrders;
    }

    get columns() {
      return [{
        label: 'NAME',
        valuePath: 'name',
        sortableBy: [{
          value: 'name',
          label: 'Name'
        }, {
          value: 'vendor_name',
          label: 'Vendor'
        }],
        testSelector: 'name',
        subValue: 'vendor.name',
        cellComponent: 'light-table/cell-components/chevron-cell',
        cellClassNames: 'font-bold text-navy'
      }, {
        label: 'ACCOUNT STRING',
        valuePath: 'accountString',
        sortName: 'account_string',
        testSelector: 'accountstring',
        cellClassNames: 'text-material-blue-gray800 break-all'
      }, ...(this.showPurchaseOrder ? [{
        label: 'PO',
        valuePath: 'purchaseOrder.number',
        testSelector: 'purchase-order',
        sortName: 'purchase_order_number',
        cellComponent: 'light-table/cell-components/link-cell',
        route: ['budgeting.fiscal-year.purchase-orders.view', 'purchaseOrder.id'],
        width: '130px'
      }] : []), {
        label: 'EXPENSE TOTAL',
        valuePath: 'amount',
        cellComponent: 'number-cell',
        sortName: 'amount',
        align: 'right',
        testSelector: 'amount',
        width: '130px',
        classNames: 'pr-16',
        cellClassNames: 'text-material-gray600 pr-16'
      }, {
        label: 'NOTES',
        valuePath: 'notes',
        cellComponent: 'light-table/cell-components/table-actions/notes',
        sortable: false,
        hideable: false,
        testSelector: 'notes',
        align: 'center',
        width: '50px',
        notes: {
          classNames: 'justify-center',

          onclick(row) {
            row.set('tab', 'notes');
            row.set('expanded', true);
          },

          value(expense) {
            if (Ember.isEmpty(expense.notes)) return '';
            return expense.notes.firstObject.message;
          }

        }
      }, {
        sortable: false,
        hideable: false,
        cellComponent: 'light-table/cell-components/actions-dropdown-cell',
        valuePath: 'name',
        align: 'right',
        width: '100px',
        buttonText: this.getButtonText,
        actions: this.getTableActions
      }];
    }

    getButtonText(expense) {
      return this.intl.t('budget.non_personnel_expense_table.action_button_text', {
        name: expense.name
      });
    }

    getTableActions(expense) {
      var _expense$allocation;

      let isEditable = ((_expense$allocation = expense.allocation) === null || _expense$allocation === void 0 ? void 0 : _expense$allocation.editable) && !expense.purchaseOrder;
      let label = isEditable ? 'global.buttons.edit_name' : 'global.buttons.view_name';
      let openToTab = this.openToTab;
      return [{
        label: this.intl.t(label, {
          name: this.intl.t('budget.non_personnel_expense_form.details')
        }),
        testId: 'edit-details',

        onclick(expense, row) {
          openToTab(row, 'details');
        }

      }, {
        label: this.intl.t(label, {
          name: this.intl.t('budget.non_personnel_expense_form.data.notes')
        }),
        testId: 'edit-notes',

        onclick(expense, row) {
          openToTab(row, 'notes');
        }

      }, {
        label: this.intl.t(label, {
          name: this.intl.t('budget.non_personnel_expense_form.funding')
        }),
        testId: 'edit-funding',

        onclick(expense, row) {
          openToTab(row, 'funding');
        }

      }, {
        label: this.intl.t(label, {
          name: this.intl.t('budget.non_personnel_expense_form.data.strategies.strategies')
        }),
        testId: 'edit-strategies',

        onclick(expense, row) {
          openToTab(row, 'strategies');
        }

      }, {
        label: this.intl.t(label, {
          name: this.intl.t('budget.non_personnel_expense_form.data.vendor')
        }),
        testId: 'edit-vendor',

        onclick(expense, row) {
          openToTab(row, 'vendor');
        }

      }, {
        component: 'table/actions-popover/delete',
        disabled: !isEditable,
        disabledMessage: 'budget.non_personnel_expense_form.delete_disabled',
        onclick: this.delete
      }, {
        component: 'table/actions-popover/change-log',
        recordDescription: this.getRecordDescription
      }];
    }

    openToTab(row, tab) {
      Ember.set(row, 'tab', tab);
      Ember.set(row, 'expanded', true);
    }

    delete(expense) {
      this.args.tableActions.delete(expense);
    }

    getRecordDescription(expense) {
      var _expense$allocation2, _expense$allocation2$;

      return [new _recordDescription.default({
        name: 'Non-Personnel Expense',
        value: expense.name
      }), new _recordDescription.default({
        name: 'Budget',
        value: (_expense$allocation2 = expense.allocation) === null || _expense$allocation2 === void 0 ? void 0 : (_expense$allocation2$ = _expense$allocation2.budget) === null || _expense$allocation2$ === void 0 ? void 0 : _expense$allocation2$.name
      })];
    }

    get serializedTableState() {
      return new _tableSerializer.default(this.expenseItemsTableState).apiQuery;
    }

    onAllocationChange() {
      this.expenseItemsTableState.rows.clear();
    }

    collapseRow(row) {
      Ember.set(row, 'tab', null);
      Ember.set(row, 'expanded', false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "expenseItemsTableState", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _ember.Store)((0, _ember.create)(_table.default, {
        pagination: {
          itemsPerPage: 25
        },
        sorts: [{
          column: 'name',
          dir: 'asc'
        }]
      }), nextState => this.expenseItemsTableState = nextState);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "nonPersonnelExpenseChanges", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "nonPersonnelExpenseChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getButtonText", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "getButtonText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getTableActions", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "getTableActions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getRecordDescription", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "getRecordDescription"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loadTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onAllocationChange", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onAllocationChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "collapseRow", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "collapseRow"), _class.prototype)), _class));
  _exports.default = BudgetNonPersonnelExpensesTableComponent;
});