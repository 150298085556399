define("balance-ember/components/budget/segment-form/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/refresh-route", "balance-ember/utils/writeable-model", "balance-ember/utils/validators/segment"], function (_exports, _component, _emberConcurrency, _refreshRoute, _writeableModel, _segment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SegmentFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* (afterSave) {
    var _this$validator$valid;

    this.errors = (_this$validator$valid = this.validator.validate()) === null || _this$validator$valid === void 0 ? void 0 : _this$validator$valid.messages;
    if (!Ember.isEmpty(this.errors)) return false;
    yield this.segment.save().then(segment => {
      this.modelChanges.addChange({
        id: segment.id,
        modelType: 'segment',
        changeType: this.isCreating ? 'create' : 'update'
      });

      if (afterSave) {
        afterSave();
      } else {
        this.goToSegmentsIndex();
      }
    });
  }).drop(), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class SegmentFormComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor3, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor4, this);

      _initializerDefineProperty(this, "network", _descriptor5, this);

      _initializerDefineProperty(this, "intl", _descriptor6, this);

      _initializerDefineProperty(this, "isCreating", _descriptor7, this);

      _initializerDefineProperty(this, "segment", _descriptor8, this);

      _initializerDefineProperty(this, "errors", _descriptor9, this);

      _initializerDefineProperty(this, "saveSegment", _descriptor10, this);

      this.createWriteableModel();

      if (this.segment.isNew) {
        this.isCreating = true;
      }
    }

    get fiscalYear() {
      return this.availableFiscalYears.current;
    }

    get segmentType() {
      return this.args.segment.segmentType ?? this.args.segmentTypes.firstObject;
    }

    get validator() {
      return new _segment.default({
        model: this.segment,
        intl: this.intl
      });
    }

    createWriteableModel() {
      this.segment = new _writeableModel.default({
        model: this.args.segment,
        store: this.store,
        network: this.network,
        paths: {
          segmentType: true
        }
      });
      this.segment.fiscalYear = this.fiscalYear;
      this.segment.segmentType = this.segmentType;
    }

    goToSegmentsIndex() {
      this.router.transitionTo('budgeting.segments');
    }

    cancel() {
      this.goToSegmentsIndex();
    }

    chooseSegmentType(segmentType) {
      if (!segmentType) {
        this.segment.disassociateSegmentType();
      } else {
        this.segment.segmentType = segmentType;
      }
    }

    chooseCategory(budgetCategory) {
      this.segment.budgetCategory = budgetCategory.value;
    }

    addAnother() {
      this.createWriteableModel();
      (0, _refreshRoute.default)('budgeting.segments.new', this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "network", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isCreating", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "segment", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "saveSegment", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseSegmentType", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "chooseSegmentType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseCategory", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "chooseCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addAnother", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "addAnother"), _class.prototype)), _class));
  _exports.default = SegmentFormComponent;
});