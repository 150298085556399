define("balance-ember/components/negative-amount/component", ["exports", "@glimmer/component", "balance-ember/utils/money-math"], function (_exports, _component, _moneyMath) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NegativeAmount extends _component.default {
    get isNegative() {
      return (0, _moneyMath.isNegative)(this.args.value);
    }

    get negativeClass() {
      if (!this.isNegative) return '';
      return this.args.deficitColor ?? 'text-deficit-color';
    }

  }

  _exports.default = NegativeAmount;
});