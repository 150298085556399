define("balance-ember/classes/table-normalizer", ["exports", "balance-ember/utils/get-category-config", "moment"], function (_exports, _getCategoryConfig, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FilterNormalizer = _exports.SortNormalizer = _exports.PaginationNormalizer = _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TableNormalizer {
    constructor({
      data = {},
      categories = [],
      sortTypes = {}
    } = {}) {
      this.data = data;
      this.categories = categories;
      this.sortTypes = sortTypes; // {suffix: Set}
    }

    normalize() {
      return { ...new PaginationNormalizer(this.data.page).normalize(),
        ...new SortNormalizer(this.data.sort, this.sortTypes).normalize(),
        ...new FilterNormalizer(this.data.filter, this.categories).normalize()
      };
    }

  }

  _exports.default = TableNormalizer;

  class PaginationNormalizer {
    constructor(paginationData) {
      this.paginationData = paginationData;
    }

    normalize() {
      if (this.paginationData) {
        return {
          pagination: {
            page: this.paginationData.number,
            itemsPerPage: this.paginationData.size
          }
        };
      }

      return {};
    }

  }

  _exports.PaginationNormalizer = PaginationNormalizer;

  class SortNormalizer {
    constructor(sort, types) {
      this.sort = sort;
      this.types = types;
    }

    normalize() {
      if (!this.sort) return {};
      let {
        column,
        dir,
        type
      } = this;
      return {
        sorts: [{
          column,
          dir,
          type
        }]
      };
    }

    get withoutDashes() {
      return this.sort.replace('-', '');
    }

    get withoutSuffix() {
      let parts = this.withoutDashes.split('_');
      return parts.slice(0, -1).join('_');
    }

    get suffix() {
      let parts = this.withoutDashes.split('_');
      return parts[parts.length - 1];
    }

    get column() {
      if (this.type) {
        let sortAsArray = this.withoutDashes.split('_');

        if (sortAsArray.lastObject === this.type) {
          sortAsArray.pop();
        }

        return sortAsArray.join('_');
      }

      return this.withoutDashes;
    }

    get dir() {
      let isDescending = this.sort[0] === '-';
      return isDescending ? 'desc' : 'asc';
    }

    get type() {
      if (!this.types[this.suffix] || !this.types[this.suffix].has(this.withoutSuffix)) return '';
      return this.suffix;
    }

  }

  _exports.SortNormalizer = SortNormalizer;

  class FilterNormalizer {
    constructor(filterData, categories) {
      this.filterData = filterData;
      this.categories = categories;
    }

    normalize() {
      if (this.filterData) {
        return {
          filters: this.getNormalizedFilters()
        };
      }

      return {};
    }

    getNormalizedFilters() {
      return Object.keys(this.filterData).map(category => {
        return this.getNormalizedFilter(category);
      });
    }

    getNormalizedFilter(category) {
      let config = (0, _getCategoryConfig.default)(this.categories, category);
      let operators = this.filterData[category];
      return { ...new FilterCategoryNormalizer(config).normalize(),
        operators: new FilterOperatorNormalizer(config, operators).normalize()
      };
    }

  }

  _exports.FilterNormalizer = FilterNormalizer;

  class FilterCategoryNormalizer {
    constructor(categoryConfig) {
      this.categoryConfig = categoryConfig;
    }

    normalize() {
      return {
        category: this.getCategoryValue(),
        type: this.getCategoryType(),
        removable: this.getRemovableStatus()
      };
    }

    getCategoryValue() {
      return this.categoryConfig ? this.categoryConfig.value : '';
    }

    getCategoryType() {
      return this.categoryConfig ? this.categoryConfig.type : 'text';
    }

    getRemovableStatus() {
      return this.categoryConfig ? this.categoryConfig.removable : true;
    }

  }

  class FilterOperatorNormalizer {
    constructor(categoryConfig, operators) {
      _defineProperty(this, "filterValueNormalizers", {
        'autocomplete-substring': AutocompleteSubstringFilterValueNormalizer,
        segment: AutocompleteSubstringFilterValueNormalizer,
        supplement: AutocompleteSubstringFilterValueNormalizer,
        date: DateFilterValueNormalizer
      });

      this.categoryConfig = categoryConfig;
      this.operators = operators;
    }

    normalize() {
      return Object.keys(this.operators).map(operator => {
        return {
          value: operator,
          filterValues: new this.filterValueNormalizer(operator, this.operators[operator]).normalize()
        };
      });
    }

    get filterValueNormalizer() {
      let type = this.categoryConfig ? this.categoryConfig.type : 'text';
      let normalizer = this.filterValueNormalizers[type];
      return normalizer ? normalizer : FilterValueNormalizer;
    }

  }

  class FilterValueNormalizer {
    constructor(operator, values) {
      this.operator = operator;
      this.values = values;
    }

    normalize() {
      return this.makeValuesAnArray(this.values);
    }

    makeValuesAnArray(values) {
      return Ember.isArray(values) ? values : [values];
    }

  }

  class AutocompleteSubstringFilterValueNormalizer extends FilterValueNormalizer {
    normalize() {
      if (this.operator === 'eq_group') {
        return this.getNormalizedEqGroup();
      }

      return super.normalize();
    }

    getNormalizedEqGroup() {
      let group = this.values;
      return [Object.keys(group).reduce((acc, key) => {
        acc[key] = this.makeValuesAnArray(group[key]);
        return acc;
      }, {})];
    }

  }

  class DateFilterValueNormalizer extends FilterValueNormalizer {
    normalize() {
      if (this.operator === 'relative') {
        return super.normalize();
      }

      return this.normalizeAbsoluteDate();
    }

    normalizeAbsoluteDate() {
      let values = super.normalize();
      return values.map(value => (0, _moment.default)(value, 'YYYY-MM-DD').format('MM/DD/YYYY'));
    }

  }
});