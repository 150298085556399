define("balance-ember/mixins/table-controller", ["exports", "balance-ember/types/table", "@microstates/ember", "balance-ember/classes/table-serializer", "balance-ember/classes/table-normalizer", "balance-ember/utils/query-params"], function (_exports, _table, _ember, _tableSerializer, _tableNormalizer, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let tableState = (0, _ember.create)(_table.default);

  var _default = Ember.Mixin.create({
    queryParams: ['query'],
    tableState: (0, _ember.state)(tableState),

    /**
     * Override this
     */
    categories: null,

    /**
     * Override this
     */
    sortTypes: Object.freeze({}),
    // We want to read from the state, but because the router internals call `set` on
    // this property because it's a query param, we have to keep it open for `set`ing as well
    query: Ember.computed('tableState', {
      get() {
        return new _tableSerializer.TableQueryParams(this.tableState).stringify();
      },

      set(key, value) {
        this.updateTableStateFromQueryParams(value);
        return value;
      }

    }),

    updateTableStateFromQueryParams(queryParamValue) {
      let parsedValue = _queryParams.default.parse(queryParamValue);

      let normalized = new _tableNormalizer.default({
        data: parsedValue,
        categories: this.categories,
        sortTypes: this.sortTypes
      }).normalize();
      normalized = this.updateTableStatePagination(normalized);
      this.updateTableStateFilters(normalized);
      this.tableState.set(normalized);
    },

    updateTableStatePagination(normalized = {}) {
      normalized.pagination = normalized.pagination || {};
      let normalizedPagination = normalized.pagination;

      if (!normalizedPagination.itemsPerPage) {
        normalizedPagination.itemsPerPage = this.tableState.pagination.itemsPerPage.state;
      }

      return normalized;
    },

    updateTableStateFilters(normalized) {
      let normalizedFilters = normalized.filters;

      if (!normalizedFilters) {
        return this.clearOptionalFilters(normalized);
      }

      return normalized;
    },

    clearOptionalFilters(normalized) {
      let requiredFilters = [...this.tableState.requiredFilters].map(filter => (0, _ember.valueOf)(filter));
      normalized.filters = requiredFilters;
      return normalized;
    }

  });

  _exports.default = _default;
});