define("balance-ember/types/filter", ["exports", "@microstates/ember", "balance-ember/types/basic-filter", "balance-ember/types/number-filter", "balance-ember/types/date-filter", "balance-ember/types/autocomplete-substring-filter"], function (_exports, _ember, _basicFilter, _numberFilter, _dateFilter, _autocompleteSubstringFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TYPES_TO_CATEGORY_CLASS = {
    date: _dateFilter.default,
    segment: _autocompleteSubstringFilter.default,
    integer: _numberFilter.default,
    currency: _numberFilter.default,
    decimal: _numberFilter.default,
    percent: _numberFilter.default,
    'autocomplete-substring': _autocompleteSubstringFilter.default,
    supplement: _autocompleteSubstringFilter.default
  };
  /**
   * This class has to be a child of BasicFilter because BasicFilter can't import its subclasses
   * otherwise it will cause a circular dependency and blow up.
   */

  class Filter extends _basicFilter.default {
    initialize(initialData = {}) {
      return this.changeType(initialData.type);
    }
    /**
     * changeType - Gives this class ability to act as a Factory so that it can change itself into
     * different subclasses without having to explicitally reference the class definition.
     *
     * @param  {string} type The type of filter it is
     * @return {Microstate}  The Microstate filter class that matches the type
     */


    changeType(type) {
      let categoryType = TYPES_TO_CATEGORY_CLASS[type];
      categoryType = categoryType ? categoryType : _basicFilter.default;
      return (0, _ember.create)(categoryType, (0, _ember.valueOf)(this));
    }

  }

  _exports.default = Filter;
});