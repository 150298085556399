define("balance-ember/components/pagination-controls/pagination-links/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EFL/GvMj",
    "block": "{\"symbols\":[\"@goToPage\"],\"statements\":[[6,[37,1],[[32,0,[\"shouldShow\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"ease-all-1s items-center \",[32,0,[\"displayState\"]]]]],[12],[2,\"\\n    \"],[8,\"pagination-links\",[],[[\"@class\",\"@prevPageIcon\",\"@nextPageIcon\",\"@showFirstAndLast\",\"@showEllipses\",\"@width\",\"@currentPage\",\"@lastPage\",\"@goToPage\"],[[30,[36,0],[\"css-pagination-links-2 current-link-bg-\",[32,0,[\"currentLinkBackgroundColor\"]]],null],\"Prev\",\"Next\",false,true,[32,0,[\"width\"]],[32,0,[\"currentPageProxy\"]],[32,0,[\"totalPages\"]],[32,1]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\"]}",
    "moduleName": "balance-ember/components/pagination-controls/pagination-links/template.hbs"
  });

  _exports.default = _default;
});