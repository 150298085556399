define("balance-ember/components/filter-form/date/component", ["exports", "balance-ember/components/filter-form/basic/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    defaultOperator: 'relative',

    get today() {
      return (0, _moment.default)(new Date());
    },

    lastMonthStart: Ember.computed('today', function () {
      return (0, _moment.default)(this.today).subtract(1, 'month').startOf('month');
    }),
    weekStart: Ember.computed('today', function () {
      return (0, _moment.default)(this.today).startOf('week');
    }),
    lastWeekStart: Ember.computed('today', function () {
      return (0, _moment.default)(this.today).subtract(1, 'week').startOf('week');
    }),
    lastWeekEnd: Ember.computed('today', function () {
      return (0, _moment.default)(this.today).subtract(1, 'week').endOf('week');
    }),
    thirtyDaysAgo: Ember.computed('today', function () {
      return (0, _moment.default)(this.today).subtract(30, 'days');
    }),
    sevenDaysAgo: Ember.computed('today', function () {
      return (0, _moment.default)(this.today).subtract(7, 'days');
    }),
    yesterday: Ember.computed('today', function () {
      return (0, _moment.default)(this.today).subtract(1, 'days');
    }),
    actions: {
      updateEndDate(unmasked, masked) {
        let value = masked ? masked : unmasked;
        this.filter.firstOperator.updateFilterValue(1, value);
      },

      updateStartDate(unmasked, masked) {
        let value = masked ? masked : unmasked;
        this.filter.firstOperator.updateFilterValue(0, value);
      },

      updateRelativeDate(title, value) {
        this.filter.firstOperator.updateFilterValue(0, value);
      }

    }
  });

  _exports.default = _default;
});