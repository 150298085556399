define("balance-ember/services/available-fiscal-years", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    current: null,

    fetch() {
      return this.store.query('fiscalYear', {
        include: 'segment_types'
      }).then(fiscalYears => {
        if (!this.current) {
          let defaultFiscalYear = fiscalYears.findBy('state', 'current');
          let newestFiscalYear = fiscalYears.firstObject;
          Ember.set(this, 'current', defaultFiscalYear || newestFiscalYear);
        }

        return this.current;
      });
    },

    setCurrent(fiscalYear) {
      return Ember.set(this, 'current', fiscalYear);
    },

    isEmpty: Ember.computed.empty('current')
  });

  _exports.default = _default;
});