define("balance-ember/components/modal/destroy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4+vawIhd",
    "block": "{\"symbols\":[\"modal\",\"@text\",\"&default\",\"@closeModal\",\"@destroy\",\"@model\",\"@errors\"],\"statements\":[[8,\"ui-modal\",[],[[\"@style\",\"@closeModal\"],[\"centered\",[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[\"@style\"],[\"warning\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"text-15 text-material-gray700 leading-tight\"],[12],[2,\"\\n        \"],[1,[32,2]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"button\",[],[[\"@style\",\"@onclick\",\"@testId\"],[\"btn-danger\",[32,5],\"confirm-delete\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,1],[\"global.buttons.delete\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"ml-8\"],[12],[2,\"\\n      \"],[8,\"button\",[],[[\"@style\",\"@testId\",\"@onclick\"],[\"btn-subtle\",\"cancel-button\",[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"global.buttons.cancel\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"budgeting-modal-errors\",[],[[\"@model\",\"@errors\"],[[32,6],[32,7]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"t\"]}",
    "moduleName": "balance-ember/components/modal/destroy/template.hbs"
  });

  _exports.default = _default;
});