define("balance-ember/components/manage/accounts-column-chart/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/bar-pattern", "@microstates/ember", "balance-ember/components/manage/accounts-column-chart/tooltip-formatter"], function (_exports, _component, _barPattern, _ember, _tooltipFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let screens = {
    'md': '768px'
  };
  let colors = {
    'material-gray700': '#40555B',
    'material-indigo-a200': '#536DFE',
    'material-cyan300': '#4DD0E1',
    'danger': '#FF8383'
  };
  const MAX_BAR_WIDTH = 30;
  const BAR_BORDER_WITH = 3;

  var _default = _component.default.extend({
    availableBudgetLeas: Ember.inject.service(),
    media: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),

    /**
     * @param
     */
    title: '',
    options: Ember.computed('availableBudgetLeas.current.id', 'axis.defaultLabelFormatter', 'chartWidth', 'title', function () {
      return {
        title: {
          text: this.title ? this.title : 'Account Balances',
          align: 'left',
          y: 50,
          // Attempt to align the legend and the title
          style: {
            fontSize: '16px',
            color: colors['material-gray700'],
            fontFamily: 'Inter UI, Arial, sans-serif',
            fontWeight: 'bold'
          }
        },
        chart: {
          type: 'column',
          borderRadius: 4,
          spacingLeft: 18,
          spacingRight: 18,
          width: this.chartWidth,
          className: 'mx-auto'
        },
        xAxis: {
          // Don't show x-axis labels, because each one represents an account and we couldn't fit
          // something that identifies an account
          visible: false
        },
        yAxis: {
          title: {
            text: ''
          },
          // If we have negative available we'll represent that as 'you have none available'
          floor: 0,
          // Make each y-axis label start with a dollar sign
          labels: {
            formatter() {
              return `$${this.axis.defaultLabelFormatter.call(this)}`;
            }

          }
        },
        legend: {
          align: 'right',
          itemStyle: {
            fontWeight: 'normal'
          },
          symbolPadding: 2,
          symbolRadius: 0,
          // Make square labels
          verticalAlign: 'top'
        },
        plotOptions: {
          column: {
            cursor: 'pointer',
            // Turn on stacking mode
            stacking: 'normal',
            // Set grouping to false, which will allow the columns to overlap
            grouping: false,
            // Evenly space the columns away from each other
            groupPadding: 0,
            // Set a max-width for the columns
            maxPointWidth: MAX_BAR_WIDTH,
            events: {
              click: ({
                point
              }) => {
                this.router.transitionTo('manage.accounts.details', this.availableBudgetLeas.current.id, point.accountCode);
              }
            }
          }
        },
        tooltip: {
          formatter: _tooltipFormatter.default,
          backgroundColor: 'white',
          useHTML: true,
          padding: 0,
          borderRadius: 4
        }
      };
    }),
    chartWidth: Ember.computed('media.{matches.[],isDesktop,isXl}', 'numRows', 'tableState', function () {
      if (this.numRows && this.numRows < 10 && (this.media.isDesktop || this.media.isXl)) {
        return Number(screens.md.replace('px', ''));
      }

      return null;
    }),
    numRows: Ember.computed.readOnly('tableState.rows.length'),
    accountBalances: Ember.computed('tableState.rows', function () {
      return (0, _ember.valueOf)(this.tableState.rows);
    }),
    series: Ember.computed('budgetLimitSeries', 'normalSeries', 'overdrawnSeries', 'tableState', function () {
      return this.normalSeries.concat(this.budgetLimitSeries).concat(this.overdrawnSeries);
    }),
    normalSeries: Ember.computed('accountBalances', 'defaultSeriesSettings', function () {
      return ['budgeted', 'encumbered', 'spent'].map(amountType => {
        let seriesDefaults = this.defaultSeriesSettings[amountType];
        return Object.assign({}, {
          data: this.accountBalances.map(accountBalance => {
            let amount = accountBalance[amountType];
            let color = this.barColor(amountType, accountBalance);
            return {
              y: amount < 0 ? null : amount,
              color,
              borderColor: color,
              borderWidth: this.barBorderWidth(amountType, accountBalance),
              accountCode: accountBalance.code,
              accountId: accountBalance.id,
              amounts: this.accountBalanceAmounts(accountBalance),
              className: `test-${amountType}-bar-${amount}`
            };
          })
        }, seriesDefaults);
      });
    }),
    defaultSeriesSettings: Ember.computed(function () {
      return {
        spent: {
          name: 'Spent',
          color: colors['material-indigo-a200']
        },
        encumbered: {
          name: 'Encumbered',
          color: (0, _barPattern.default)(colors['material-indigo-a200'], colors['material-cyan300'])
        },
        budgeted: {
          name: 'Current Budget',
          // We want the remaing amount to look like what's left in the 'Budget'
          color: colors['material-cyan300'],
          stacking: null
        }
      };
    }),
    budgetLimitSeries: Ember.computed('accountBalances', function () {
      // We want to add an extra series specifically for accounts that have spent more than their
      // budgeted amount. We only want this series to be visible on the overdrawn accounts
      return {
        showInLegend: false,
        // We're using columnrange, because we want a single floating bar where the budgeted amount
        // should be.
        type: 'columnrange',
        color: colors['material-gray700'],
        borderColor: colors['material-gray700'],
        borderWidth: BAR_BORDER_WITH,
        groupPadding: 0,
        // Make the bar expand the entire width
        lineWidth: 0,
        maxPointWidth: MAX_BAR_WIDTH,
        grouping: false,
        data: this.accountBalances.map(accountBalance => {
          let {
            available,
            budgeted
          } = accountBalance;
          let high = available < 0 && budgeted > 0 ? budgeted : null;
          return {
            high,
            // Make the low an arbitrary number less than the high just so that the bar shows up.
            // We only care about the high part of the 'range'
            low: high ? high - 0.01 : null,
            accountCode: accountBalance.code,
            amounts: this.accountBalanceAmounts(accountBalance),
            className: `test-budget-limit-bar-${high}`
          };
        })
      };
    }),
    overdrawnSeries: Ember.computed(function () {
      // We want a separate legend item to appear for the overdrawn accounts, but it shouldn't do
      // anything. It is only an indicator of what the 'danger' color means
      return {
        name: 'Overdrawn',
        color: colors.danger,
        events: {
          legendItemClick() {
            return false;
          }

        }
      };
    }),

    barColor(amountType, accountBalance) {
      let defaultColor = this.defaultSeriesSettings[amountType].color;
      let isOverdrawn = accountBalance.available < 0;
      return isOverdrawn ? colors.danger : defaultColor;
    },

    barBorderWidth(amountType, accountBalance) {
      // We want the border to match the "over budget" bar so that the edges meet
      let isOverdrawn = accountBalance.available < 0;
      return isOverdrawn ? BAR_BORDER_WITH : 0;
    },

    accountBalanceAmounts({
      budgeted,
      spent,
      encumbered,
      available
    }) {
      return {
        budgeted,
        spent,
        encumbered,
        available
      };
    },

    actions: {
      updateChartWidth(chart) {
        // We need to explicitally set chart width with this function because updating the options
        // from a Number to null isn't respected and the old number stays in place. Turning the
        // animation option to false here because some errors get thrown in the console otherwise
        // about Highcharts. Error: <rect> attribute height: Expected length "2.2343223..."
        chart.setSize(this.chartWidth, null, false);
      }

    }
  });

  _exports.default = _default;
});