define("balance-ember/components/filter-form/select/input/component", ["exports", "@glimmer/component", "@microstates/ember"], function (_exports, _component, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FilterFormSelectInputComponent = (_dec = Ember.computed.readOnly('filterOptions.options'), _dec2 = Ember.computed.readOnly('filterOptions.labelPath'), _dec3 = Ember.computed.readOnly('filterOptions.valuePath'), _dec4 = Ember.computed.readOnly('args.categoryConfig.filterOptions'), _dec5 = Ember._action, (_class = class FilterFormSelectInputComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "options", _descriptor, this);

      _initializerDefineProperty(this, "labelPath", _descriptor2, this);

      _initializerDefineProperty(this, "valuePath", _descriptor3, this);

      _initializerDefineProperty(this, "filterOptions", _descriptor4, this);
    }

    get selected() {
      return this.filterValues.map(value => {
        return this.options.findBy(this.valuePath, value);
      });
    }

    get filterValues() {
      var _this$operator;

      return (0, _ember.valueOf)((_this$operator = this.operator) === null || _this$operator === void 0 ? void 0 : _this$operator.filterValues) || [];
    }

    get operator() {
      return [...this.operators].findBy('value.state', this.args.operator);
    }

    get operators() {
      return this.args.filter.operators;
    }

    updateValues(selectedOptions) {
      if (!selectedOptions || !selectedOptions.length) {
        return this.removeOperator();
      }

      let filterValues = selectedOptions.mapBy(this.valuePath);

      if (!this.operator) {
        return this.createOperator(filterValues);
      }

      this.operator.filterValues.set(filterValues);
    }

    removeOperator() {
      return this.args.filter.operators.remove(this.operator);
    }

    createOperator(filterValues) {
      return this.args.filter.operators.push({
        value: this.args.operator,
        filterValues
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "options", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "labelPath", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "valuePath", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filterOptions", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateValues", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updateValues"), _class.prototype)), _class));
  _exports.default = FilterFormSelectInputComponent;
});