define("balance-ember/components/light-table/cell-components/table-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wt39f4h6",
    "block": "{\"symbols\":[\"InlineItem\",\"tableActionComponent\",\"@row\",\"@column\",\"@tableActions\",\"@value\"],\"statements\":[[10,\"div\"],[14,0,\"flex justify-end items-center\"],[12],[2,\"\\n\"],[6,[37,4],[[32,3,[\"editing\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"light-table/cell-components/table-actions/save\",[],[[\"@row\"],[[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"layout/inline\",[],[[\"@space\",\"@align\"],[\"xs\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"tableActionComponents\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,1],[[30,[36,0],[\"light-table/cell-components/table-actions/\",[32,2]],null]],[[\"value\",\"tableActions\",\"column\",\"row\"],[[32,6],[32,5],[32,4],[32,3]]]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"component\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "balance-ember/components/light-table/cell-components/table-actions/template.hbs"
  });

  _exports.default = _default;
});