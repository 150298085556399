define("balance-ember/components/positions-table/form/nav/funding-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V4K5FO2D",
    "block": "{\"symbols\":[\"@stateMachine\"],\"statements\":[[2,\" \"],[8,\"button\",[[16,0,[31,[\"\\n  pl-12\\n  pr-8\\n  py-8\\n  rounded-sm\\n  font-bold\\n  text-sm\\n  w-full\\n  flex\\n  items-center\\n  justify-between\\n  \",[30,[36,1],[[30,[36,2],[[30,[36,0],[[32,1,[\"status\"]],\"funding\"],null],[32,1,[\"errors\",\"funding\"]]],null],\"bg-material-red100\",[30,[36,1],[[30,[36,0],[[32,1,[\"status\"]],\"funding\"],null],\"bg-material-light-blue100\"],null]],null],\"\\n  \"]]]],[[\"@testId\",\"@active\",\"@onclick\",\"@style\",\"@size\"],[\"funding-plan-link\",[30,[36,0],[[32,1,[\"status\"]],\"funding\"],null],[30,[36,3],[[32,1,[\"editFunding\"]]],null],\"none\",\"sm\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[15,0,[30,[36,1],[[32,1,[\"errors\",\"funding\"]],\"btn-danger-link\",\"btn-primary-link\"],null]],[12],[2,\"\\n    1. \"],[1,[30,[36,4],[\"budget.position.funding_plan\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"errors\",\"funding\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"notification-error\",\"text-material-red500\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"and\",\"fn\",\"t\"]}",
    "moduleName": "balance-ember/components/positions-table/form/nav/funding-link/template.hbs"
  });

  _exports.default = _default;
});