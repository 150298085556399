define("balance-ember/components/filter-form/access/component", ["exports", "balance-ember/components/filter-form/basic/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    defaultOperator: 'eq',

    didRender() {
      this._super(...arguments);

      let filter = this.filter;

      if (!filter.firstOperator) {
        filter = filter.updateOperatorValue(0, this.defaultOperator);
      } // Access filters are just booleans so we need to default a value as well as a match


      let firstValue = filter.firstOperator.firstFilterValue;

      if (!firstValue) {
        filter.firstOperator.updateFilterValue(0, true);
      }
    },

    actions: {
      updateMatchType(matchType) {
        let value = matchType === 'eq';
        this.filter.firstOperator.updateFilterValue(0, value);
      }

    }
  });

  _exports.default = _default;
});