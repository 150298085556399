define("balance-ember/utils/writeable-model/factory", ["exports", "ember-inflector", "balance-ember/utils/writeable-models/basic", "balance-ember/utils/writeable-models/allocation", "balance-ember/utils/writeable-models/budget", "balance-ember/utils/writeable-models/expense", "balance-ember/utils/writeable-models/fiscal-year", "balance-ember/utils/writeable-models/position-expense", "balance-ember/utils/writeable-models/budget-priority", "balance-ember/utils/writeable-models/budget-strategy", "balance-ember/utils/writeable-models/employee", "balance-ember/utils/writeable-models/job-type", "balance-ember/utils/writeable-models/non-personnel-expense", "balance-ember/utils/writeable-models/position", "balance-ember/utils/writeable-models/purchase-order", "balance-ember/utils/writeable-models/supplemental-pay-expense", "balance-ember/utils/writeable-models/supplemental-pay-type", "balance-ember/utils/writeable-models/user", "balance-ember/utils/writeable-models/vendor"], function (_exports, _emberInflector, _basic, _allocation, _budget, _expense, _fiscalYear, _positionExpense, _budgetPriority, _budgetStrategy, _employee, _jobType, _nonPersonnelExpense, _position, _purchaseOrder, _supplementalPayExpense, _supplementalPayType, _user, _vendor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * @class WriteableModelFactory
   *
   * @description
   *
   * The jobs of the Factory are to copy the attributes and relationships of models onto
   * writeable models as well as pick the correct subclass while copying.
   * As part of the copying process it will decorate the writeable model with methods and
   * attributes that will be useful for updating the writeable model
   */
  class WriteableModelFactory {
    constructor({
      model = {},
      store = {},
      network = {},
      paths,
      existingWriteableModels = {},
      ...rest
    }) {
      _defineProperty(this, "subclasses", {
        'allocation': _allocation.default,
        'budget': _budget.default,
        'position-expense': _positionExpense.default,
        'budget-priority': _budgetPriority.default,
        'budget-strategy': _budgetStrategy.default,
        'employee': _employee.default,
        'fiscal-year': _fiscalYear.default,
        'job-type': _jobType.default,
        'expense': _expense.default,
        'non-personnel-expense': _nonPersonnelExpense.default,
        'position': _position.default,
        'purchase-order': _purchaseOrder.default,
        'supplemental-pay-expense': _supplementalPayExpense.default,
        'supplemental-pay-type': _supplementalPayType.default,
        'user': _user.default,
        'vendor': _vendor.default
      });

      this.model = model;
      this.store = store;
      this.network = network;
      this.paths = paths;
      this.existingWriteableModels = existingWriteableModels;
      this.otherArgs = rest;
      this.addToCache();
      return this.copy();
    }

    get subclass() {
      return this.subclasses[this.modelName] ?? _basic.default;
    }

    get modelName() {
      return this.model.constructor.modelName;
    }

    get instance() {
      return this._instance = this._instance ?? new this.subclass({
        model: this.model,
        store: this.store,
        network: this.network,
        paths: this.paths,
        factory: this,
        ...this.otherArgs
      });
    }
    /**
     * @method
     * @private
     * @description Keep a cache of all writeable models that have been made.
     * We do this because models can have inverse relationships that point back
     * to other models that we have already copied. We need to make sure that we
     * use existing writeable models for inverses instead of starting the copying
     * process over again. Otherwise there will be an infinite loop
     */


    addToCache() {
      let existingType = this.existingWriteableModels[this.modelName];

      if (!existingType) {
        this.existingWriteableModels[this.modelName] = {};
      } // Using `guidFor` to get a uniqueIdentifier for the child. We do this because
      // brand new records, that don't have ids yet, can still be pointing to each other
      // as inverses and we need to make sure we don't continue copying those either.


      this.existingWriteableModels[this.modelName][Ember.guidFor(this.model)] = this.instance;
    }
    /**
     * @method
     * @private
     * @description Removes an existing writable model from the cache.
     * As used in Deletes and Disassociates can run into trouble when models are
     * readded when previously deleted or disassociated.
     */


    removeFromCache() {
      delete this.existingWriteableModels[this.modelName][Ember.guidFor(this.model)];
    }

    copy() {
      this.setupAttributeProperties();
      this.setupRelationshipProperties();
      this.copyAttributes();
      this.copyRelationships();
      return this.instance;
    }
    /**
     * Create a getter and setter for each copied attribute so
     * we can `notifyPropertyChange` when `=` is called
     */


    setupAttributeProperties() {
      this.model.eachAttribute(name => {
        let privateName = `_${name}`;
        Object.defineProperty(this.instance, name, {
          get() {
            return this[privateName];
          },

          set(value) {
            this[privateName] = value;
            Ember.notifyPropertyChange(this, name);
          }

        });
      });
    }

    setupRelationshipProperties() {
      this.instance.relationshipDefinitions.forEach(definition => {
        new RelationshipPropertyCreator({
          definition,
          writeableModel: this.instance
        }).create();
      });
    }

    copyAttributes() {
      this.model.eachAttribute(name => {
        this.instance[name] = this.model[name];
      });
    }

    copyRelationships() {
      let relationshipsToCopy = this.instance.relationshipsToCopy;
      this.instance.relationshipDefinitions.forEach(definition => {
        if (relationshipsToCopy.includes(definition.name)) {
          new RelationshipCopier({
            writeableModel: this.instance,
            definition
          }).copy();
        }
      });
    }

  }

  _exports.default = WriteableModelFactory;

  class RelationshipPropertyCreator {
    constructor({
      writeableModel,
      definition
    }) {
      this.definition = definition;
      this.writeableModel = writeableModel;
    }

    get name() {
      return this.definition.name;
    }

    get privateName() {
      return `_${this.name}`;
    }

    get kind() {
      return this.definition.kind;
    }

    get capitalizedKind() {
      return Ember.String.capitalize(this.kind);
    }

    create() {
      this[`add${this.capitalizedKind}Property`]();

      if (this.kind === 'hasMany') {
        this.createAddFunction();
      }

      this.createDeleteFunction();
      this.createDisassociateFunction();
    }
    /**
     * Add getter and setter for each belongsTo relationship. When the child is
     * read via the getter it will return undefined if the child has been deleted
     * or disassociated
     *
     * When `=` is called the model will be copied and the rendering engine will be notified
     */


    addBelongsToProperty() {
      let creator = this;
      Object.defineProperty(this.writeableModel, this.name, {
        get() {
          let child = this[creator.privateName];

          if (child && (creator.isChildDestroyed(child) || creator.isChildDisassociated(child))) {
            return undefined;
          }

          return child;
        },

        set(child) {
          child = copyChild({
            child,
            name: creator.name,
            writeableModel: creator.writeableModel
          });
          this[creator.privateName] = child;
          creator.writeableModel.disassociatedChildren.removeObject(child);
          Ember.notifyPropertyChange(this, creator.name);
        }

      });
    }
    /**
     * Add getter and setter for each hasMany relationship. When the child is
     * read via the getter it will filter out each child that has been deleted
     * or disassociated
     *
     * When `=` is called each model will be copied and the rendering engine will be notified
     */


    addHasManyProperty() {
      let creator = this;
      Object.defineProperty(this.writeableModel, this.name, {
        get() {
          let children = this[creator.privateName] || [];
          return children.filter(child => {
            return !creator.isChildDestroyed(child) && !creator.isChildDisassociated(child);
          }).uniq();
        },

        set(children = []) {
          children = copyChildren({
            children,
            name: creator.name,
            writeableModel: creator.writeableModel
          });
          this[creator.privateName] = children;
          Ember.notifyPropertyChange(this, creator.name);
        }

      });
    }

    isChildDestroyed(child) {
      return child.isDeleted;
    }

    isChildDisassociated(child) {
      return this.writeableModel.disassociatedChildren.includes(child);
    }

    createAddFunction() {
      let creator = this;

      let addFunction = function (child) {
        let existing = this[creator.privateName] || [];
        this[creator.name] = [...existing, child];
        creator.writeableModel.disassociatedChildren.removeObject(this[creator.privateName].lastObject);
      };

      let formattedName = Ember.String.capitalize((0, _emberInflector.singularize)(this.name));
      this.writeableModel[`add${formattedName}`] = addFunction;
    }

    createDeleteFunction() {
      let creator = this;

      let deleteFunction = function (child) {
        if (creator.kind === 'belongsTo') {
          child = creator.writeableModel[creator.name];
        }

        if (!child.id) {
          this.removeUnpersisted(creator.definition, child);
        } else {
          child.delete();
        }

        Ember.notifyPropertyChange(this, creator.name);
      };

      let formattedName = Ember.String.capitalize((0, _emberInflector.singularize)(this.name));
      this.writeableModel[`delete${formattedName}`] = deleteFunction;
    }

    createDisassociateFunction() {
      let creator = this;

      let disassociateFunction = function (child) {
        if (creator.kind === 'belongsTo') {
          child = creator.writeableModel[creator.name];
        }

        if (!child.id) {
          this.removeUnpersisted(creator.definition, child);
        } else {
          creator.writeableModel.disassociatedChildren.push(child);
        }

        Ember.notifyPropertyChange(this, creator.name);
      };

      let formattedName = Ember.String.capitalize((0, _emberInflector.singularize)(this.name));
      this.writeableModel[`disassociate${formattedName}`] = disassociateFunction;
    }

  }

  class RelationshipCopier {
    constructor({
      writeableModel,
      definition
    }) {
      this.writeableModel = writeableModel;
      this.definition = definition;
    }

    copy() {
      if (this.kind === 'hasMany') {
        this.copyHasMany();
      } else {
        this.copyBelongsTo();
      }
    }

    get kind() {
      return this.definition.kind;
    }

    get name() {
      return this.definition.name;
    }

    get model() {
      return this.writeableModel.model;
    }

    copyBelongsTo() {
      let child = this.model.belongsTo(this.name).value();

      if (Ember.isPresent(child)) {
        this.writeableModel[this.name] = copyChild({
          name: this.name,
          child,
          writeableModel: this.writeableModel
        });
      }
    }

    copyHasMany() {
      let children = this.model.hasMany(this.name).value();

      if (Ember.isPresent(children)) {
        this.writeableModel[this.name] = copyChildren({
          name: this.name,
          children,
          writeableModel: this.writeableModel
        });
      }
    }

  }

  function copyChild({
    name,
    child,
    writeableModel = {}
  } = {}) {
    if (!child) {
      return undefined;
    }

    if (child instanceof _basic.default) {
      return child;
    }

    let existingInverse = new InverseFinder({
      child,
      writeableModel
    }).find();

    if (existingInverse) {
      return existingInverse;
    }

    return new WriteableModelFactory({
      model: child,
      paths: writeableModel.paths[name],
      existingWriteableModels: writeableModel.factory.existingWriteableModels
    });
  }

  function copyChildren({
    name,
    children,
    writeableModel
  } = {}) {
    return children.map(child => copyChild({
      name,
      child,
      writeableModel
    }));
  }
  /**
   * @class InverseFinder
   * @description We need to make sure inverse relationships reference existing
   * writeable models instead of trying to start copying them over again. Otherwise,
   * it becomes an infinite loop.
   */


  class InverseFinder {
    constructor({
      child,
      writeableModel
    }) {
      this.child = child;
      this.writeableModel = writeableModel;
    }

    find() {
      return this.existingWriteableModels[this.childModelName] && this.existingWriteableModels[this.childModelName][Ember.guidFor(this.child)];
    }

    get childModelName() {
      return this.child.constructor.modelName;
    }

    get existingWriteableModels() {
      return this.writeableModel.factory.existingWriteableModels;
    }

  }
});