define("balance-ember/styles/tailwind.config", [], function () {
  "use strict";

  const plugin = require("tailwindcss/plugin");

  const tabFocusVariant = plugin(function ({
    addVariant,
    e: escape
  }) {
    addVariant('tab-focus', ({
      modifySelectors,
      separator
    }) => {
      modifySelectors(({
        className
      }) => {
        return `[data-outline-hidden="false"].${escape(`tab-focus${separator}${className}`)}:focus`;
      });
    });
  });
  const appliedVariant = plugin(function ({
    addVariant,
    e: escape
  }) {
    addVariant('applied', ({
      modifySelectors,
      separator
    }) => {
      modifySelectors(({
        className
      }) => {
        return `[data-is-active].${escape(`applied${separator}${className}`)}`;
      });
    });
  });
  const hoverNotAppliedVariant = plugin(function ({
    addVariant,
    e: escape
  }) {
    addVariant('not-applied-hover', ({
      modifySelectors,
      separator
    }) => {
      modifySelectors(({
        className
      }) => {
        return `:not([data-is-active]).${escape(`not-applied-hover${separator}${className}`)}:hover`;
      });
    });
  });
  const tabFocusNotAppliedVariant = plugin(function ({
    addVariant,
    e: escape
  }) {
    addVariant('not-applied-tab-focus', ({
      modifySelectors,
      separator
    }) => {
      modifySelectors(({
        className
      }) => {
        return `[data-outline-hidden="false"]:not([data-is-active]).${escape(`not-applied-tab-focus${separator}${className}`)}:focus`;
      });
    });
  });
  /*
  Tailwind - The Utility-First CSS Framework
  
  A project by Adam Wathan (@adamwathan), Jonathan Reinink (@reinink),
  David Hemphill (@davidhemphill) and Steve Schoger (@steveschoger).
  
  Welcome to the Tailwind config file. This is where you can customize
  Tailwind specifically for your project. Don't be intimidated by the
  length of this file. It's really just a big JavaScript object and
  we've done our very best to explain each section.
  
  View the full documentation at https://tailwindcss.com.
  
  See https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js for the defaults
  */

  module.exports = {
    theme: {
      colors: {
        'allovue-blue': '#23ADCE',
        'allovue-gray': '#4A4A4A',
        'transparent': 'transparent',
        'white': 'white',
        'black': 'black',
        'budgeted': '#C2C2C2',
        // There are several places in the app where we match colors with the word in the returned data set.
        // We have both encumbered and encumbrance because some data sets say one and some say the other.
        'encumbered': '#22ADCE',
        'encumbrance': '#22ADCE',
        'spent': '#D2409B',
        'available': '#01CD9F',
        'actual': '#D2409B',
        'deficit-color': '#FF8383',
        'danger': '#FF8383',
        'success': '#42BDA2',
        'navy': '#3F4E73',
        'budgeting-bg-color': '#F1F8FC',
        'budgeting-border': '#B2D2FE',
        'blue100': '#DEF4FF',
        'blue350': '#2CB5FF',
        'blue500': '#21A9F2',
        'blue900': '#004C75',
        'material-blue50': '#E3F2FD',
        'material-blue100': '#BBDEFB',
        'material-blue300': '#64B5F6',
        'material-blue500': '#2196F3',
        'material-blue600': '#1E88E5',
        'material-blue700': '#1976D2',
        'material-light-blue50': '#F4FCFF',
        'material-light-blue100': '#E1F5FE',
        'material-light-blue200': '#B3E5FC',
        'material-light-blue300': '#81D4FA',
        'material-light-blue400': '#4FC3F7',
        'material-light-blue500': '#03A9F4',
        'material-light-blue600': '#039BE5',
        'material-light-blue700': '#0288D1',
        'material-light-blue900': '#01579B',
        'material-cyan50': '#E0F7FA',
        'material-cyan100': '#B2EBF2',
        'material-cyan200': '#00BCD4',
        'material-cyan300': '#4DD0E1',
        'material-cyan500': '#00BCD4',
        'material-cyan600': '#00ACC1',
        'material-cyan700': '#0097A7',
        'material-purple50': '#F3E5F5',
        'material-purple100': '#E1BEE7',
        'material-purple300': '#BA68C8',
        'material-purple400': '#AB47BC',
        'material-purple500': '#9C27B0',
        'material-purple600': '#8E24AA',
        'material-indigo50': '#E8EAF6',
        'material-indigo100': '#E8EAF6',
        'material-indigo200': '#C5CAE9',
        'material-indigo-a200': '#536DFE',
        'material-indigo300': '#9FA8DA',
        'material-indigo400': '#7986CB',
        'material-indigo-a400': '#304FFE',
        'material-indigo500': '#3F51B5',
        'material-indigo600': '#3949AB',
        'material-indigo700': '#303F9F',
        'material-indigo800': '#283593',
        'material-pink50': '#FCE4EC',
        'material-pink400': '#EC407A',
        'material-red50': '#FCE4EC',
        'material-red100': '#FFEBEE',
        'material-red200': '#FFCDD2',
        'material-red300': '#EF9A9A',
        'material-red400': '#E57373',
        'material-red500': '#EF5350',
        'material-red600': '#E53935',
        'material-red700': '#D32F2F',
        'material-red800': '#C62828',
        'material-gray50': '#F9FAFD',
        'material-gray100': '#F2F5F9',
        'material-gray200': '#DCE2EA',
        'material-gray300': '#CFD7E2',
        'material-gray400': '#BEC7D3',
        'material-gray500': '#9EAAB2',
        'material-gray600': '#819299',
        'material-gray650': '#5A7077',
        'material-gray700': '#40555B',
        'material-gray800': '#29373D',
        'material-blue-gray50': '#ECEFF1',
        'material-blue-gray100': '#CFD8DC',
        'material-blue-gray200': '#B0BEC5',
        'material-blue-gray300': '#90A4AE',
        'material-blue-gray400': '#78909C',
        'material-blue-gray500': '#607D8B',
        'material-blue-gray600': '#546E7A',
        'material-blue-gray700': '#455A64',
        'material-blue-gray800': '#37474F',
        'material-blue-gray900': '#263238',
        'gray50': '#FAFAFA',
        'gray400': '#BDBDBD',
        'gray600': '#868686',
        'outer-space': 'rgba(40,47,51,0.75)',
        'navgreen': '#BAFAF2',
        'navgreen-dark': '#3BC1B0',
        'material-light-green300': '#AED581',
        'material-green50': '#F2FBF3',
        'material-green100': '#EEFFEF',
        'material-green200': '#CEF6CF',
        'material-green300': '#A5D6A7',
        'material-green350': '#8BC34A',
        'material-green400': '#81C784',
        'material-green500': '#4CAF50',
        'material-green600': '#43A047',
        'material-green700': '#388E3C',
        'material-green800': '#2E7D32',
        'material-teal50': '#e0f2f1',
        'material-teal100': '#B2DFDB',
        'material-teal400': '#4db6ac',
        'material-teal-a400': '#1DE9B6',
        'material-teal500': '#009688',
        'material-teal600': '#00897B',
        'material-teal700': '#00796B',
        'material-teal-a700': '#00BFA5',
        'material-teal-800': '#00695C',
        'material-teal900': '#006064',
        'material-yellow50': '#FFFDE7',
        'material-yellow100': '#FFF9C4',
        'material-yellow200': '#FFF9C4',
        'material-yellow300': '#FFF176',
        'material-yellow700': '#fbc02d',
        'material-orange100': '#FFF3E0',
        'material-orange200': '#FFE0B2',
        'material-orange900': '#BF360C',
        'material-amber-100': '#FFECB3',
        'material-amber-600': '#FFB300',
        'material-amber900': '#FF6F00'
      },
      boxShadow: theme => ({
        DEFAULT: '0 2px 4px 0 rgba(0,0,0,0.10)',
        'form-input': 'inset 1px 0 3px 0 rgba(0, 0, 0, 0.1)',
        'md': '0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)',
        'lg': '0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)',
        'inner': 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
        'chart': '0 1px 2px #C2C2C2',
        'container': '0 2px 6px 0 #BEC7D3',
        'page-container': '0 6px 6px 0 hsla(214, 15%, 88%, 1)',
        'nav': 'inset 0px -1px 0px #CFD8DC',
        'no-overlay-modal': '0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 24px 0 rgba(0, 0, 0, 0.16)',
        'popover': '0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 24px 0 rgba(0, 0, 0, 0.16)',
        'large-search-dropdown': '0px 3px 4px rgba(0, 0, 0, 0.14),\
         0px 3px 3px rgba(0, 0, 0, 0.12), \
         0px 1px 8px rgba(0, 0, 0, 0.2);',
        'button': '0 1px 1.5px 0 rgba(0,0,0,0.07), \
        0 1px 2px 0 rgba(0,0,0,0.08), \
        0 2px 5px 0 rgba(50,50,93,0.10), \
        0 0 0 0.5px rgba(50,50,93,0.17)',
        'button-active': '0 0 2px 0 rgba(0,0,0,0.06), \
        0 0 1.5px 0 rgba(0,0,0,0.05), \
        0 1px 5px 0 rgba(50,50,93,0.08), \
        0 0 0 0.5px rgba(50,50,93,0.15)',
        'button-primary-active': '0 0 2px 0 rgba(0,0,0,0.06), \
         0 0 1.5px 0 rgba(0,0,0,0.05), \
         0 1px 5px 0 rgba(50,50,93,0.08), \
         0 0 0 0.5px rgba(50,50,93,0.15), \
         inset 0 2px 4px 0 #0277BD',
        'button-default-active': `0 0 2px 0 rgba(0,0,0,0.06), \
        0 0 1.5px 0 rgba(0,0,0,0.05), \
        0 1px 5px 0 rgba(50,50,93,0.08), \
        0 0 0 0.5px rgba(50,50,93,0.15), \
        inset 0 2px 2px 0 ${theme('colors.material-gray500')}`,
        'button-default-active-dark': 'inset 0 2px 3px 0 #014C75',
        'button-success-active': `0 0 2px 0 rgba(0,0,0,0.06), \
       0 0 1.5px 0 rgba(0,0,0,0.05), \
       0 1px 5px 0 rgba(50,92,51,0.08), \
       0 0 0 0.5px rgba(50,92,51,0.15), \
       inset 0 2px 3px 0 ${theme('colors.material-teal800')}`,
        'button-success-light-active': `0 0 2px 0 rgba(0,0,0,0.06), \
        0 0 1.5px 0 rgba(0,0,0,0.05), \
        0 1px 5px 0 rgba(50,92,51,0.08), \
        0 0 0 0.5px rgba(50,92,51,0.15), \
        inset 0 2px 3px 0 ${theme('colors.material-green400')}`,
        'button-danger-active': '0 0 1.5px 0 rgba(0,0,0,0.07), \
        0 0 2px 0 rgba(0,0,0,0.08), \
        0 1px 5px 0 rgba(93,50,50,0.10), \
        0 0 0 0.5px rgba(93,50,50,0.17), \
        inset 0 2px 3px 0 #AD1457',
        'indigo-a200': '0px 0px 0px rgba(50, 50, 93, 0.15), \
        0px 1px 5px rgba(50, 50, 93, 0.08), \
        0px 0px 1.5px rgba(0, 0, 0, 0.05), \
        0px 0px 2px rgba(0, 0, 0, 0.06), \
        inset 0px 2px 4px rgba(0, 0, 0, 0.35)',
        'cyan600': '0px 0px 0px rgba(50, 50, 93, 0.15), \
        0px 1px 5px rgba(50, 50, 93, 0.08), \
        0px 0px 1.5px rgba(0, 0, 0, 0.05), \
        0px 0px 2px rgba(0, 0, 0, 0.06), \
        inset 0px 2px 4px rgba(0, 0, 0, 0.35)',
        'none': 'none'
      }),
      extend: {
        screens: {
          'sm': '576px',
          'lg': '992px',
          'xl': '1200px',
          'print': {
            'raw': 'print'
          }
        },
        fontFamily: {
          'sans': ['Inter UI', 'Arial', 'sans-serif'],
          'serif': ['Constantia', 'Lucida Bright', 'Lucidabright', 'Lucida Serif', 'Lucida', 'DejaVu Serif', 'Bitstream Vera Serif', 'Liberation Serif', 'Georgia', 'serif'],
          'headline': ['Lato', 'Arial', 'sans-serif']
        },
        fontSize: {
          xs: '0.75rem',
          '13': '.8125rem',
          sm: '0.875rem',
          '15': '.9375rem',
          base: '1rem',
          lg: '1.125rem',
          xl: '1.25rem',
          '2xl': '1.5rem',
          '3xl': '1.875rem',
          '4xl': '2.25rem',
          '5xl': '3rem',
          '6xl': '4rem'
        },
        lineHeight: {
          '0': 0,
          'x-loose': 3,
          '16': '16px',
          '18': '18px'
        },
        borderWidth: {
          '1': '1px',
          '3': '3px'
        },
        borderRadius: {
          'pill': '1rem'
        },
        spacing: {
          '2': '0.125rem',
          '3': '3px',
          '4': '0.25rem',
          '5': '0.313rem',
          '6': '0.375rem',
          '7': '0.4375rem',
          '8': '0.5rem',
          '10': '0.625rem',
          '12': '0.75rem',
          '14': '0.875rem',
          '15': '0.9375rem',
          '16': '1rem',
          '18': '1.125rem',
          '20': '1.25rem',
          '22': '22px',
          '24': '1.5rem',
          '25': '1.5625rem',
          '27': '1.6875rem',
          //And odd size I know but it's for bumping over descriptive text in the upload dropdown as of 10/5/18 - Jake
          '28': '1.75rem',
          '32': '2rem',
          '36': '2.25rem',
          '40': '2.5rem',
          '48': '3rem',
          '56': '3.5rem',
          '60': '3.75rem',
          '64': '4rem',
          '80': '5rem',
          '96': '6rem',
          '128': '8rem',
          '144': '144px',
          '160': '160px',
          '192': '12rem',
          '256': '16rem',
          '320': '20rem',
          '384': '24rem',
          '448': '28rem',
          '500': '500px'
        },
        height: {
          '1/2': '50%',
          '2x': '200%'
        },
        minWidth: {
          '128': '8rem',
          '160': '160px',
          '185': '185px',
          '290': '290px',
          '352': '22rem',
          '600': '600px',
          '768': '768px',
          '2/3': '66.66667%'
        },
        minHeight: {
          '32': '2rem',
          '128': '8rem'
        },
        maxWidth: {
          'auto': 'auto',
          'px': '1px',
          '4': '0.25rem',
          '8': '0.5rem',
          '12': '0.75rem',
          '16': '1rem',
          '20': '1.25rem',
          '24': '1.5rem',
          '32': '2rem',
          '36': '2.25rem',
          '40': '2.5rem',
          '48': '3rem',
          '64': '4rem',
          '80': '5rem',
          '96': '6rem',
          '128': '8rem',
          '192': '12rem',
          '256': '16rem',
          '320': '20rem',
          '352': '22rem',
          '380': '380px',
          '480': '480px',
          '600': '600px',
          '1280': '1280px',
          '1/2': '50%',
          '1/3': '33.33333%',
          '2/3': '66.66667%',
          '1/4': '25%',
          '3/4': '75%',
          '1/5': '20%',
          '2/5': '40%',
          '3/5': '60%',
          '4/5': '80%',
          '1/6': '16.66667%',
          '5/6': '83.33333%',
          'screen': '100vw'
        },
        maxHeight: {
          'auto': 'auto',
          'px': '1px',
          '4': '0.25rem',
          '6': '0.375rem',
          '8': '0.5rem',
          '12': '0.75rem',
          '16': '1rem',
          '20': '1.25rem',
          '24': '1.5rem',
          '32': '2rem',
          '36': '2.25rem',
          '40': '2.5rem',
          '48': '3rem',
          '64': '4rem',
          '96': '6rem',
          '128': '8rem',
          '192': '12rem',
          '256': '16rem',
          '320': '20rem',
          '448': '28rem'
        },
        rotate: {
          '270': '270deg'
        },
        zIndex: {
          '10': 10,
          // sticky modal header, modal-errors, middle arrow-breadrumb, sticky table headers
          '20': 20,
          // bs-tooltip, arrow-breadcrumb. tooltip z-index defined in _variables.scss
          '50': 50,
          // slideout-modal
          '1000': 1000,
          //ember-basic-dropdown defined in its default scss
          '1010': 1010,
          //tooltips in the nav specificaly. Located in _app-nav-and-footer.scss
          '9999': 9999 // popover component

        },
        fill: theme => ({
          'none': 'none',
          'white': 'white',
          'remaining': '#8BC34A',
          'overdrawn': '#EF5350',
          'gray': '#819299',
          'material-blue500': theme('colors.material-blue500'),
          'material-yellow300': theme('colors.material-yellow300')
        }),
        inset: {
          '-6': '-6px',
          '-2': '-2px',
          '-1': '-1px',
          '1': '1px',
          '2': '2px',
          '3': '3px',
          '6': '6px'
        },
        width: {
          '180': '180px'
        },
        margin: {
          '80': '80px'
        }
      }
    },
    variants: {
      appearance: ['responsive'],
      backgroundAttachment: ['responsive'],
      backgroundColor: ['responsive', 'hover', 'group-hover', 'active', 'focus', 'tab-focus', 'disabled', 'applied', 'not-applied-hover', 'not-applied-tab-focus', 'first'],
      backgroundPosition: ['responsive'],
      backgroundRepeat: ['responsive'],
      backgroundSize: ['responsive'],
      borderColor: ['responsive', 'hover', 'focus', 'active', 'disabled'],
      borderRadius: ['responsive', 'applied'],
      borderStyle: ['responsive'],
      borderWidth: ['responsive'],
      cursor: ['responsive', 'disabled'],
      display: ['responsive'],
      flexDirection: ['responsive'],
      flexWrap: ['responsive'],
      alignItems: ['responsive'],
      alignSelf: ['responsive'],
      justifyContent: ['responsive'],
      alignContent: ['responsive'],
      flex: ['responsive'],
      flexGrow: ['responsive'],
      flexShrink: ['responsive'],
      float: ['responsive'],
      fontFamily: ['responsive'],
      fontWeight: ['responsive', 'hover', 'applied'],
      height: ['responsive'],
      lineHeight: ['responsive'],
      listStylePosition: ['responsive'],
      listStyleType: ['responsive'],
      margin: ['responsive'],
      maxHeight: ['responsive'],
      maxWidth: ['responsive'],
      minHeight: ['responsive'],
      minWidth: ['responsive'],
      negativeMargin: ['responsive'],
      opacity: ['responsive', 'hover', 'group-hover'],
      outline: ['focus'],
      overflow: ['responsive'],
      padding: ['responsive'],
      pointerEvents: ['responsive', 'disabled'],
      position: ['responsive'],
      inset: ['responsive'],
      resize: ['responsive'],
      boxShadow: ['responsive', 'active', 'focus', 'tab-focus', 'applied'],
      fill: ['group-hover'],
      stroke: ['group-hover'],
      textAlign: ['responsive', 'last'],
      textColor: ['responsive', 'hover', 'group-hover', 'focus', 'active', 'disabled', 'tab-focus', 'applied', 'not-applied-hover', 'not-applied-tab-focus,'],
      fontSize: ['responsive'],
      fontStyle: ['responsive', 'hover', 'focus'],
      fontSmoothing: ['responsive'],
      textDecoration: ['hover', 'focus'],
      textTransform: ['responsive', 'hover', 'focus'],
      letterSpacing: ['responsive'],
      userSelect: ['responsive'],
      verticalAlign: ['responsive'],
      visibility: ['responsive'],
      whitespace: ['responsive'],
      wordBreak: ['responsive'],
      width: ['responsive'],
      zIndex: ['responsive']
    },
    plugins: [tabFocusVariant, appliedVariant, hoverNotAppliedVariant, tabFocusNotAppliedVariant],
    purge: {
      enabled: process.env.EMBER_ENV === 'production',
      content: ['./app/index.html', './app/templates/**/*.hbs', './app/controllers/**/*.js', './app/components/**/*.hbs', './app/components/**/*.js', './public/**/*.svg']
    }
  };
});