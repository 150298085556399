define("balance-ember/components/manage-link/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8tZ6xP+v",
    "block": "{\"symbols\":[\"@route\",\"&default\"],\"statements\":[[2,\" \"],[8,\"button\",[[24,0,\"\\n    text-lg\\n    hover:text-material-teal500\\n    focus:text-material-teal500\\n    applied:text-material-teal500\\n    applied:font-bold\\n  \"]],[[\"@route\",\"@style\"],[[32,1],\"btn-secondary-link\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/manage-link/item/template.hbs"
  });

  _exports.default = _default;
});