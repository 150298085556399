define("balance-ember/components/manage/bookmark-dropdown/row/reminder/specific-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TryKwU2c",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-form/ui-label\",[],[[\"@style\",\"@for\"],[\"hidden\",\"specific-date-input\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  Select Date\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"one-way-date-mask\",[[24,1,\"specific-date-input\"],[24,0,\"css-input-field\"]],[[\"@value\",\"@options\",\"@update\"],[[32,0,[\"selectedDate\"]],[30,[36,0],null,[[\"inputFormat\",\"outputFormat\"],[\"mm/dd/yyyy\",\"yyyy-mm-dd\"]]],[32,0,[\"updateDate\"]]]],null]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "balance-ember/components/manage/bookmark-dropdown/row/reminder/specific-date/template.hbs"
  });

  _exports.default = _default;
});