define("balance-ember/routes/district-admin/budget-user-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.store.loadRecords('user', {
        filter: {
          budget_architect: {
            eq: false
          }
        },
        fields: {
          users: 'name'
        },
        sort: 'name',
        paginate: false
      }).then(users => {
        return users.toArray();
      });
    }

  });

  _exports.default = _default;
});