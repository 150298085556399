define("balance-ember/controllers/budgeting/fiscal-year/budgets", ["exports", "balance-ember/mixins/table-controller"], function (_exports, _tableController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_tableController.default, {
    session: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableState.defaultSort.set({
        column: 'name',
        dir: 'asc'
      });
    },

    categories: Ember.computed('hasAccessCategory', 'roleCategory', 'session.isBudgetArchitect', function () {
      return [{
        title: 'Name',
        value: 'name',
        type: 'text'
      }, {
        title: 'Category',
        value: 'category',
        type: 'select',
        filterOptions: {
          options: [{
            value: 'central_office',
            label: 'Central Office'
          }, {
            value: 'school',
            label: 'School'
          }],
          valuePath: 'value',
          labelPath: 'label'
        }
      }, {
        title: 'Status',
        value: 'approval_state',
        type: 'select',
        filterOptions: {
          options: [{
            value: 'submitted',
            label: 'Submitted'
          }, {
            value: 'in_progress',
            label: 'In Progress'
          }, {
            value: 'approved',
            label: 'Approved'
          }, {
            value: 'completed',
            label: 'Completed'
          }],
          valuePath: 'value',
          labelPath: 'label'
        }
      }, ...(this.session.isBudgetArchitect ? [] : [this.roleCategory]), {
        title: 'Allocated',
        value: 'allocated_amount',
        type: 'currency'
      }, {
        title: 'Planned',
        value: 'planned_amount',
        type: 'currency'
      }, {
        title: 'Remaining',
        value: 'remaining_amount',
        type: 'currency'
      }, ...(this.session.isBudgetArchitect ? [this.hasAccessCategory] : [])];
    }),
    roleCategory: Ember.computed(function () {
      return {
        title: 'Role',
        value: 'user_role',
        type: 'select',
        filterOptions: {
          options: [{
            value: 'approver',
            label: 'Approver'
          }, {
            value: 'builder',
            label: 'Builder'
          }, {
            value: 'purchasing_approver',
            label: 'Purchasing Approver'
          }, {
            value: 'viewer',
            label: 'Viewer'
          }],
          valuePath: 'value',
          labelPath: 'label'
        }
      };
    }),
    hasAccessCategory: Ember.computed('budgetUsers', function () {
      return {
        title: 'Has Access',
        value: 'has_access',
        type: 'select',
        filterOptions: {
          options: this.budgetUsers,
          valuePath: 'id',
          labelPath: 'name'
        }
      };
    })
  });

  _exports.default = _default;
});