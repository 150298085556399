define("balance-ember/components/budget/expense-table/save-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FHNTn1kC",
    "block": "{\"symbols\":[\"InlineItem\",\"@showSaveSuccess\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"\\n    text-material-green600\\n    text-sm\\n    transition-opacity\\n    duration-300\\n    \",[30,[36,0],[[32,2],\"opacity-100\",\"opacity-0\"],null],\"\\n    \"]]],[12],[2,\"\\n  \"],[8,\"layout/inline\",[],[[\"@space\",\"@align\",\"@alignX\"],[\"xxs\",\"center\",\"right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"budget-completed\",\"h-16 w-16\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,1],[\"global.notifications.changes_saved\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"t\"]}",
    "moduleName": "balance-ember/components/budget/expense-table/save-message/template.hbs"
  });

  _exports.default = _default;
});