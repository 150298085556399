define("balance-ember/components/positions-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uZDZyExc",
    "block": "{\"symbols\":[\"columns\",\"onDeleteClick\",\"reportDownloader\",\"@categories\",\"@summary\",\"@tableState\"],\"statements\":[[10,\"div\"],[14,0,\"align-middle py-10 flex\"],[12],[2,\"\\n  \"],[8,\"budget/csv-upload-dropdown\",[],[[\"@url\",\"@resourceName\",\"@afterUploadSuccess\"],[[32,0,[\"positionBulkUrl\"]],\"Position\",[30,[36,0],[[32,0],\"afterUploadSuccess\"],null]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ml-4\"],[12],[2,\"\\n    \"],[8,\"report-downloader\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"export-button\",[],[[\"@budget\",\"@reportType\",\"@exportAction\",\"@buttonText\"],[[32,0,[\"budget\"]],\"staff_roster\",[30,[36,1],[[32,3,[\"downloadReport\"]],\"fiscal_year\"],null],\"Export Positions\"]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mt-20\"],[12],[2,\"\\n  \"],[8,\"positions-table/columns-provider\",[],[[\"@fundedWidgetRoutePath\"],[[32,0,[\"fundedWidgetRoutePath\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"positions-table\",[],[[\"@fiscalYear\",\"@categories\",\"@columns\",\"@loadTask\",\"@onEditClick\",\"@onDeleteClick\",\"@onRowClick\",\"@positionChanges\",\"@summary\",\"@trackingPrefix\",\"@tableState\"],[[32,0,[\"fiscalYear\"]],[32,4],[32,1],[32,0,[\"loadTask\"]],[30,[36,0],[[32,0],\"onEditClick\"],null],[32,2],[32,0,[\"onRowClick\"]],[32,0,[\"positionChanges\"]],[32,5],\"Positions\",[32,6]]],null],[2,\"\\n  \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"fn\"]}",
    "moduleName": "balance-ember/components/positions-page/template.hbs"
  });

  _exports.default = _default;
});