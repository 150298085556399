define("balance-ember/components/positions-table/form/employee/autocomplete/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PositionsTableExpandedRowEditEmployeeAutocompleteComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = (0, _emberConcurrency.task)(function* (query) {
    yield (0, _emberConcurrency.timeout)(300);
    return yield this.store.query('employee', {
      page: {
        size: 6,
        number: 1
      },
      filter: { ...(this.shouldFilterByBudgetId ? {
          budget_id: this.budgetId
        } : {}),
        fiscal_year_id: this.availableFiscalYears.current.id,
        query: {
          fuzzy: query
        }
      }
    });
  }).restartable(), (_class = class PositionsTableExpandedRowEditEmployeeAutocompleteComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor, this);

      _initializerDefineProperty(this, "features", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "fetchEmployees", _descriptor5, this);
    }

    chooseEmployee(employee) {
      if (this.args.position.employee && !employee) {
        return this.args.position.disassociateEmployee();
      }

      return this.args.position.employee = employee;
    }

    get shouldFilterByBudgetId() {
      // features is a proxy, so have to use get
      // eslint-disable-next-line ember/no-get
      return Ember.get(this.features, 'filteredEmployeeAutocomplete') && !this.session.budgetArchitect;
    }

    get budgetId() {
      var _this$args$position$b;

      return (_this$args$position$b = this.args.position.budget) === null || _this$args$position$b === void 0 ? void 0 : _this$args$position$b.id;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "chooseEmployee", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "chooseEmployee"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fetchEmployees", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PositionsTableExpandedRowEditEmployeeAutocompleteComponent;
});