define("balance-ember/components/report-export-single/component", ["exports", "ember-window-mock", "balance-ember/utils/urls"], function (_exports, _emberWindowMock, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ml-auto'],
    ajax: Ember.inject.service(),
    timeZone: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    session: Ember.inject.service(),
    isBudgetArchitect: Ember.computed.readOnly('session.isBudgetArchitect'),
    actions: {
      fetchExportReport(reportName, format) {
        let fiscalYearId = this.availableFiscalYears.current.id;
        let authString = this.ajax.getAuthString();
        let timeZone = this.timeZone.toParam;
        _emberWindowMock.default.location.href = `${(0, _urls.URLForBudgetNamespace)()}/fiscal_years/${fiscalYearId}/reports/${reportName}/export/${format}${authString}&${timeZone}`;
      }

    }
  });

  _exports.default = _default;
});