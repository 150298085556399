define("balance-ember/controllers/users/reset-password", ["exports", "balance-ember/utils/urls", "ember-cp-validations", "ember-concurrency"], function (_exports, _urls, _emberCpValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 4,
      max: 120
    })],
    password_confirmation: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Passwords do not match'
    })
  }, {
    disabled: Ember.computed.not('model.shouldValidate')
  });
  const UsersResetPasswordController = Ember.Controller.extend(validations, {
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    queryParams: ['reset_token'],
    password: '',
    password_confirmation: '',
    setPassword: (0, _emberConcurrency.task)(function* () {
      this.set('shouldValidate', true);

      if (this.validations.isValid) {
        return yield this.ajax.request((0, _urls.URLForPasswordUpdate)(), {
          contentType: 'application/json',
          method: 'PUT',
          data: {
            user: {
              password: this.password,
              password_confirmation: this.password_confirmation,
              reset_password_token: this.reset_token
            }
          }
        }).then(() => {
          this.notifications.success('Your password has been successfully reset.');
          return this.transitionToRoute('login');
        }).catch(({
          payload
        }) => {
          this.set('errors', payload.error);
        });
      }
    })
  });
  var _default = UsersResetPasswordController;
  _exports.default = _default;
});