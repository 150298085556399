define("balance-ember/components/number-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5UQBUa4U",
    "block": "{\"symbols\":[\"@value\",\"@column\"],\"statements\":[[8,\"negative-amount\",[],[[\"@value\",\"@deficitColor\"],[[32,1],\"text-material-red500\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,0],[[32,1]],[[\"format\",\"currency\",\"abbreviate\"],[[32,0,[\"numberFormat\"]],\"dollars\",[32,2,[\"shouldAbbreviate\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\"]}",
    "moduleName": "balance-ember/components/number-cell/template.hbs"
  });

  _exports.default = _default;
});