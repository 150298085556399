define("balance-ember/utils/manage/segment-filter-id-extractor", ["exports", "balance-ember/classes/table-normalizer", "balance-ember/utils/manage/filter-id-extractor"], function (_exports, _tableNormalizer, _filterIdExtractor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SegmentFilterIdExtractor {
    constructor({
      categories,
      query
    }) {
      this.categories = categories;
      this.query = query;
    }

    extract() {
      let normalized = new _tableNormalizer.default({
        data: this.query,
        categories: this.categories
      }).normalize();
      let filters = normalized.filters ? normalized.filters : [];
      filters = this.getFilters(filters);
      return new _filterIdExtractor.default(filters).extract();
    }

    getFilters(filters) {
      return filters.filter(curr => this.segmentCategories.includes(curr.category));
    }

    get segmentCategories() {
      let segmentCategories = this.categories.findBy('value', 'segments').subcategories;
      return segmentCategories.mapBy('value');
    }

  }

  _exports.default = SegmentFilterIdExtractor;
});