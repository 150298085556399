define("balance-ember/components/banner/inline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m/aqAzQ1",
    "block": "{\"symbols\":[\"InlineItem\",\"@type\",\"@icon\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"\\n  text-sm\\n  py-4\\n  px-12\\n  rounded\\n  self-start\\n  \",[30,[36,1],[[30,[36,0],[[32,2],\"info\"],null],\"bg-material-blue50\"],null],\"\\n  \",[30,[36,1],[[30,[36,0],[[32,2],\"warning\"],null],\"bg-material-yellow700\"],null],\"\\n  \"]]],[12],[2,\"\\n  \"],[8,\"layout/inline\",[],[[\"@space\",\"@align\"],[\"s\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[\"\\n        \",[30,[36,1],[[30,[36,0],[[32,2],\"info\"],null],\"text-material-blue600\"],null],\"\\n        \",[30,[36,1],[[30,[36,0],[[32,2],\"warning\"],null],\"text-material-yellow50\"],null],\"\\n      \"]]],[12],[2,\"\\n        \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[[30,[36,2],[[32,3],[32,2]],null],\"h-16 w-16\"]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,4,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"or\"]}",
    "moduleName": "balance-ember/components/banner/inline/template.hbs"
  });

  _exports.default = _default;
});