define("balance-ember/components/budget/file-upload/file-name-cell/component", ["exports", "@glimmer/component", "balance-ember/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BudgetBudgetFilesTableFileNameCellComponent extends _component.default {
    get url() {
      return `${_environment.default.APIRoot}${this.args.row.content.url}`;
    }

  }

  _exports.default = BudgetBudgetFilesTableFileNameCellComponent;
});