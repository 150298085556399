define("balance-ember/components/light-table/cells/default-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O8NqT+gB",
    "block": "{\"symbols\":[\"@value\",\"@cellId\",\"@rawValue\",\"@row\",\"@column\",\"@table\",\"@extra\",\"@tableActions\"],\"statements\":[[6,[37,1],[[32,5,[\"cellComponent\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,5,[\"cellComponent\"]]],[[\"tableActions\",\"extra\",\"table\",\"column\",\"row\",\"value\",\"rawValue\",\"cellId\"],[[32,8],[32,7],[32,6],[32,5],[32,4],[32,1],[32,3],[32,2]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"if\"]}",
    "moduleName": "balance-ember/components/light-table/cells/default-cell/template.hbs"
  });

  _exports.default = _default;
});