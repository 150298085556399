define("balance-ember/components/budget/file-upload/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rOIVwlZn",
    "block": "{\"symbols\":[\"b\",\"@uploadFileTask\"],\"statements\":[[8,\"icon-button\",[],[[\"@style\",\"@isLink\",\"@size\",\"@icon\",\"@buttonText\",\"@showButtonText\"],[\"btn-primary\",true,\"sm\",\"upload\",\"Upload Documents\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"defaultLayout\"]],[],[[],[]],null],[2,\"\\n\"],[6,[37,0],[[32,2,[\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"ml-6\"],[12],[8,\"loading-spinner\",[],[[],[]],null],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/budget/file-upload/button/template.hbs"
  });

  _exports.default = _default;
});