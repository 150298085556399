define("balance-ember/routes/budgeting/job-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      job_type_id: id
    }) {
      return this.store.loadRecord('jobType', id, {
        include: 'benefits_package.flat_rate_benefits,benefits_package.percentage_benefits,salary_segment'
      });
    }

  });

  _exports.default = _default;
});