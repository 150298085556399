define("balance-ember/components/autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yTcpAVXw",
    "block": "{\"symbols\":[\"suggestion\",\"@selected\",\"@noMatchesMessage\",\"@groupComponent\",\"@search\",\"@searchEnabled\",\"@searchField\",\"@options\",\"@triggerId\",\"@triggerClass\",\"@defaultHighlighted\",\"@onkeydown\",\"@onInput\",\"@dropdownClass\",\"@onChange\",\"@placeholder\",\"@disabled\",\"&attrs\",\"&default\"],\"statements\":[[8,\"power-select-typeahead\",[[17,18],[4,[38,1],[\"click\",[32,0,[\"onClick\"]]],null]],[[\"@selected\",\"@renderInPlace\",\"@noMatchesMessage\",\"@groupComponent\",\"@search\",\"@searchEnabled\",\"@searchField\",\"@options\",\"@triggerId\",\"@triggerClass\",\"@defaultHighlighted\",\"@onkeydown\",\"@onInput\",\"@dropdownClass\",\"@onChange\",\"@registerAPI\",\"@placeholder\",\"@disabled\"],[[32,2],true,[32,3],[32,4],[30,[36,0],[[32,5],[32,0,[\"search\"]]],null],[32,6],[32,7],[32,8],[32,9],[32,10],[32,11],[32,12],[32,13],[32,14],[32,15],[32,0,[\"registerDropdownAPI\"]],[32,16],[32,17]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,19,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "moduleName": "balance-ember/components/autocomplete/template.hbs"
  });

  _exports.default = _default;
});