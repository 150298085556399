define("balance-ember/components/budget/button/save-another/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wggfy7gS",
    "block": "{\"symbols\":[\"@task\",\"@onclick\"],\"statements\":[[8,\"button\",[],[[\"@style\",\"@size\",\"@disabled\",\"@testId\",\"@onclick\"],[\"btn-primary-light\",\"sm\",[32,1,[\"isRunning\"]],\"save-and-add-another\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,0],[[32,1,[\"isRunning\"]],\"Creating\",\"Create and add another\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/budget/button/save-another/template.hbs"
  });

  _exports.default = _default;
});