define("balance-ember/templates/budgeting/fiscal-year/reports/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "REXwRGgf",
    "block": "{\"symbols\":[\"export\"],\"statements\":[[8,\"report-export\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],[\"budget/reports/\",[32,0,[\"model\",\"reportType\"]]],null]],[[\"summary\",\"export\"],[[32,0,[\"model\"]],[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"component\"]}",
    "moduleName": "balance-ember/templates/budgeting/fiscal-year/reports/view.hbs"
  });

  _exports.default = _default;
});