define("balance-ember/routes/budgeting/supplemental-pay-types", ["exports", "balance-ember/mixins/non-architect-rerouter"], function (_exports, _nonArchitectRerouter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_nonArchitectRerouter.default, {
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    positionBudgeting: Ember.computed.readOnly('features.positionBudgeting'),
    queryParams: {
      // We want the model to reload the data for pagination
      page: {
        refreshModel: true
      }
    },

    beforeModel() {
      if (!this.positionBudgeting) this.transitionTo('/not-found');
      this.redirectToNotFound();
    }

  });

  _exports.default = _default;
});