define("balance-ember/routes/budgeting/budget-priorities", ["exports", "balance-ember/mixins/non-architect-rerouter"], function (_exports, _nonArchitectRerouter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_nonArchitectRerouter.default, {});

  _exports.default = _default;
});