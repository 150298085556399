define("balance-ember/components/light-table/columns/grand-total-column/component", ["exports", "ember-light-table/components/columns/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This has all the regular features of a light-table column component, however it expects a
   * `total` that it will display instead of the column's label
   *
   * @param {number} total
   */
  var _default = _base.default.extend({});

  _exports.default = _default;
});