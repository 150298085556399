define("balance-ember/templates/budgeting/job-types/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rck5Lzd5",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"job-type-form-data\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"job-type-form\",[],[[\"@jobType\",\"@formTitle\",\"@actionButtonText\",\"@disabledButtonText\",\"@segmentTypes\",\"@benefitsPackages\",\"@staffTypes\",\"@budgetCategories\"],[[32,0,[\"model\"]],\"Create Job Type\",\"Create Job Type\",\"Creating\",[32,1,[\"segmentTypes\"]],[32,1,[\"benefitsPackages\"]],[32,1,[\"staffTypes\"]],[32,1,[\"budgetCategories\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/budgeting/job-types/new.hbs"
  });

  _exports.default = _default;
});