define("balance-ember/utils/writeable-models/basic", ["exports", "balance-ember/utils/writeable-model/network-adapter", "lodash.isequal"], function (_exports, _networkAdapter, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BasicWriteableModel {
    constructor({
      model = {},
      store = {},
      network = {},
      paths = {},
      factory
    }) {
      _defineProperty(this, "networkAdapterClass", _networkAdapter.default);

      _defineProperty(this, "tempIdGenerator", Ember.guidFor);

      _defineProperty(this, "isDeleted", false);

      _defineProperty(this, "disassociatedChildren", []);

      this.model = model;
      this.store = store;
      this.network = network;
      this.paths = paths;
      this.factory = factory;
    }

    get isNew() {
      return this.model.isNew;
    }

    get id() {
      return this.model.id;
    }

    get tempId() {
      return this.tempIdGenerator(this);
    }

    get modelName() {
      return this.model.constructor.modelName;
    }

    get relationshipDefinitions() {
      return this._relationshipDefinitions = this._relationshipDefinitions || [...this.model.constructor.relationships.values()].map(([definition]) => definition);
    }

    get relationshipsToCopy() {
      // Get all strings and first relationships
      return typeof this.paths === 'object' ? Object.keys(this.paths) : [];
    }

    get dirtyType() {
      return new ChangeTracker({
        writeableModel: this
      }).dirtyType;
    }

    save(options) {
      return this.networkAdapter.save(options);
    }

    get networkAdapter() {
      return this._networkAdapter = this._networkAdapter || new this.networkAdapterClass({
        writeableModel: this,
        network: this.network,
        store: this.store
      });
    }

    delete() {
      this.isDeleted = true;
      this.factory.removeFromCache();
    }
    /**
     * Used by delete<RelationshipName> and diassociate<RelationshipName> methods
     * to cleanup unpersisted records from memory
     * @private
     * @param {RelationshipDefinition} definition
     * @param {EmberDataModel} child
     */


    removeUnpersisted(definition, child) {
      let {
        name,
        kind
      } = definition;
      let privateName = `_${name}`;

      if (kind === 'hasMany') {
        let existingRecords = this[privateName] || [];
        existingRecords.removeObject(child);
      } else {
        this[privateName] = undefined;
      }

      child.model.unloadRecord(); // Cleanup memory
    }

  }

  _exports.default = BasicWriteableModel;

  class ChangeTracker {
    constructor({
      writeableModel,
      alreadyChecked = []
    } = {}) {
      this.writeableModel = writeableModel;
      this.alreadyChecked = alreadyChecked;
    }

    get dirtyType() {
      if (this.writeableModel.isNew) {
        return 'create';
      }

      if (this.writeableModel.isDeleted) {
        return 'destroy';
      }

      if (this.haveAttributesChanged) {
        return 'update';
      }

      let found = this.writeableModel.relationshipDefinitions.find(definition => {
        if (!this.writeableModel.relationshipsToCopy.includes(definition.name)) {
          return false;
        }

        if (this.alreadyChecked.includes(this.writeableModel)) {
          return false;
        }

        if (definition.kind === 'belongsTo') {
          return this.didBelongsToChange(definition.name);
        }

        return this.didHasManyChange(definition.name);
      });
      return found ? 'update' : '';
    }

    get haveAttributesChanged() {
      return [...this.writeableModel.model.constructor.attributes.values()].mapBy('name').any(name => {
        return !(0, _lodash.default)(this.writeableModel[name], this.writeableModel.model[name]);
      });
    }

    didBelongsToChange(name) {
      let original = this.writeableModel.model[name];
      let current = this.writeableModel[name];
      this.alreadyChecked.push(current);

      if (Ember.isNone(original) && Ember.isNone(current)) {
        return false;
      }

      if (original && !current) {
        return true;
      }

      if (!original && current) {
        return true;
      }

      if (original.id !== current.id) {
        return true;
      }

      return Boolean(new ChangeTracker({
        writeableModel: current,
        alreadyChecked: this.alreadyChecked
      }).dirtyType);
    }

    didHasManyChange(name) {
      let original = this.writeableModel.model[name];
      let current = this.writeableModel[name];

      if (Ember.isNone(original) && Ember.isNone(current)) {
        return false;
      }

      if (original && !current) {
        return true;
      }

      if (!original && current) {
        return true;
      }

      if (original.length !== current.length) {
        return true;
      }

      if (original.length) {
        let oldIds = original.map(x => x.id);
        let newIds = current.map(x => x.id);

        if (!(0, _lodash.default)(oldIds, newIds)) {
          return true;
        }
      }

      return Boolean(current.find(child => {
        return Boolean(new ChangeTracker({
          writeableModel: child,
          alreadyChecked: this.alreadyChecked
        }).dirtyType);
      }));
    }

  }
});