define("balance-ember/templates/budgeting/vendors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bOFTr+iS",
    "block": "{\"symbols\":[\"InlineItem\"],\"statements\":[[8,\"container\",[],[[\"@style\"],[\"horizontal centered page padding-x\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"budget/page-header\",[],[[\"@title\"],[[30,[36,0],[\"budget.vendor.index.page_title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/inline\",[],[[\"@space\"],[\"xs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"icon-button\",[],[[\"@route\",\"@style\",\"@testId\",\"@icon\",\"@buttonText\",\"@showButtonText\"],[[30,[36,1],[\"budgeting.vendors.new\"],null],\"btn-primary\",\"add-vendor\",\"circle-plus\",\"Add Vendor\",true]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"budget/csv-upload-dropdown\",[[24,\",\",\"\"]],[[\"@url\",\"@resourceName\",\"@afterUploadSuccess\",\"@resource\",\"@reportName\",\"@tableState\",\"@categories\"],[[32,0,[\"vendorsBulkUrl\"]],\"Vendor\",[30,[36,2],[[32,0],\"afterUploadSuccess\"],null],\"vendor\",\"Vendor Template\",[32,0,[\"tableState\"]],[32,0,[\"categories\"]]]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mt-16\"],[12],[2,\"\\n    \"],[8,\"budget/vendors\",[],[[],[]],null],[2,\"\\n\\n    \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"array\",\"action\",\"-outlet\",\"component\"]}",
    "moduleName": "balance-ember/templates/budgeting/vendors.hbs"
  });

  _exports.default = _default;
});