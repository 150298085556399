define("balance-ember/components/budget/non-personnel-expenses-table/edit-strategies/core-functions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SeyKtBtX",
    "block": "{\"symbols\":[\"InlineItem\",\"core\",\"@expense\"],\"statements\":[[8,\"layout/inline\",[],[[\"@alignX\"],[\"between\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1],[],[[\"@grow\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-sm text-material-blue-gray400\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"budget.non_personnel_expense_form.core_functions\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"budget/expense-table/edit-strategies/core-functions\",[],[[\"@amount\",\"@expense\"],[[32,3,[\"amount\"]],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"w-80\"],[12],[2,\"\\n        \"],[8,[32,2,[\"Percentage\"]],[],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"w-128\"],[12],[2,\"\\n        \"],[8,[32,2,[\"Amount\"]],[],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\\n   \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"w-40\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "balance-ember/components/budget/non-personnel-expenses-table/edit-strategies/core-functions/template.hbs"
  });

  _exports.default = _default;
});