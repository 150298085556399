define("balance-ember/components/account-details-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kncSujtD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[8,\"highcharts-chart\",[],[[\"@options\",\"@series\"],[[32,0,[\"options\"]],[32,0,[\"formattedData\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/account-details-chart/template.hbs"
  });

  _exports.default = _default;
});