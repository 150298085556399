define("balance-ember/components/budgeting-link/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1inREKuV",
    "block": "{\"symbols\":[\"@route\",\"@testId\",\"&default\"],\"statements\":[[8,\"button\",[[24,0,\"text-lg\"]],[[\"@style\",\"@route\",\"@testId\"],[\"btn-secondary-link\",[32,1],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/budgeting-link/item/template.hbs"
  });

  _exports.default = _default;
});