define("balance-ember/components/positions-table/form-data/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PositionsTableFormDataComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* () {
    yield Ember.RSVP.hash({
      position: this.loadPositions.perform(),
      allocations: this.loadAllocations.perform(),
      budgetStrategies: this.loadBudgetStrategies.perform()
    });
  }), _dec6 = (0, _emberConcurrency.task)(function* () {
    if (!this.args.position) {
      return this.position = this.store.createRecord('position', {
        budget: this.args.budget
      });
    }

    let positions = yield this.store.query('position', {
      filter: {
        id: this.args.position.id
      },
      extra_fields: {
        allocations: 'editable'
      },
      include: ['position_expenses.segments.segment_type', 'position_expenses.allocation', 'position_expenses.expense_strategies.budget_strategy', 'employee.employee_status', 'job_type.staff_type', 'job_type.benefits_package.flat_rate_benefits', 'job_type.benefits_package.percentage_benefits'].join(',')
    });
    return this.position = positions.firstObject;
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    let allocations = yield this.store.query('allocation', {
      filter: {
        can_have_position_expenses: true,
        budget_id: this.args.budget.id
      },
      extra_fields: {
        allocations: 'editable'
      },
      include: 'segments.segment_type',
      paginate: false
    });
    return this.allocations = allocations.sortBy('name');
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    let budgetStrategies = yield this.store.query('budgetStrategy', {
      filter: {
        budget_id: {
          eq: this.args.budget.id
        }
      },
      include: 'budget_priority'
    });
    this.budgetStrategies = budgetStrategies.sortBy('name');
  }), (_class = class PositionsTableFormDataComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "position", _descriptor2, this);

      _initializerDefineProperty(this, "allocations", _descriptor3, this);

      _initializerDefineProperty(this, "budgetStrategies", _descriptor4, this);

      _initializerDefineProperty(this, "loadTask", _descriptor5, this);

      _initializerDefineProperty(this, "loadPositions", _descriptor6, this);

      _initializerDefineProperty(this, "loadAllocations", _descriptor7, this);

      _initializerDefineProperty(this, "loadBudgetStrategies", _descriptor8, this);

      this.loadTask.perform();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "position", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "allocations", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "budgetStrategies", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loadTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loadPositions", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loadAllocations", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "loadBudgetStrategies", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PositionsTableFormDataComponent;
});