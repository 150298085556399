define("balance-ember/components/export-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uppMCXMc",
    "block": "{\"symbols\":[\"b\",\"popover\",\"StackItem\",\"@size\",\"@buttonText\",\"@budget\"],\"statements\":[[8,\"icon-button\",[],[[\"@style\",\"@size\",\"@testId\",\"@active\",\"@icon\",\"@showButtonText\",\"@buttonText\"],[\"btn-default\",[32,4],[32,0,[\"testSelector\"]],[32,0,[\"isShowing\"]],\"download\",true,[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"defaultLayout\"]],[],[[],[]],null],[2,\"\\n\\n  \"],[8,\"popover\",[],[[\"@onChange\"],[[30,[36,0],[[32,0],\"isShowing\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"p-10 w-192\"],[12],[2,\"\\n      \"],[8,\"layout/stack\",[],[[\"@space\"],[\"xxs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"csv-download-button\",[[4,[38,3],[[32,5],[30,[36,2],null,[[\"budget\",\"fiscalYear\",\"format\"],[[32,6,[\"name\"]],[32,6,[\"fiscalYear\",\"name\"]],\"csv\"]]]],null]],[[\"@onclick\"],[[30,[36,1],[[32,0,[\"export\"]],\"csv\",[32,2,[\"hide\"]]],null]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"excel-download-button\",[[4,[38,3],[[32,5],[30,[36,2],null,[[\"budget\",\"fiscalYear\",\"format\"],[[32,6,[\"name\"]],[32,6,[\"fiscalYear\",\"name\"]],\"xlsx\"]]]],null]],[[\"@onclick\"],[[30,[36,1],[[32,0,[\"export\"]],\"xlsx\",[32,2,[\"hide\"]]],null]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[3]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"set\",\"fn\",\"hash\",\"track-event\"]}",
    "moduleName": "balance-ember/components/export-button/template.hbs"
  });

  _exports.default = _default;
});