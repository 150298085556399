define("balance-ember/services/route-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service keeps track of routes the user visits. You need to add the `route-history` mixin
   * to any routes you want to keep the history of
   */
  var _default = Ember.Service.extend({
    router: Ember.inject.service(),
    previousRoute: '',
    previousQueryParams: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'previousQueryParams', {});
    },

    /**
     * Go to the previous route.
     *
     * @param {modelIds} Pass in as many model identifiers as your route needs. No more, no less.
     * This is required because there is not a good way to keep track of the transition history
     * on every route change. The most we can get is the route name
     */
    goBack(...modelIds) {
      this.router.transitionTo(this.previousRoute, ...modelIds);
    },

    /**
     * Go to the previous route and replace the current url with the new one.
     *
     * @param {modelIds} Pass in as many model identifiers as your route needs. No more, no less.
     * This is required because there is not a good way to keep track of the transition history
     * on every route change. The most we can get is the route name
     */
    goBackAndReplace(...modelIds) {
      this.router.replaceWith(this.previousRoute, ...modelIds);
    },

    addToHistory(routeName, queryParams) {
      Ember.set(this, 'previousRoute', routeName);
      Ember.set(this, 'previousQueryParams', queryParams);
    }

  });

  _exports.default = _default;
});