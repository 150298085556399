define("balance-ember/components/benefits-package-table/benefit-amount-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ISv7BFzO",
    "block": "{\"symbols\":[\"benefit\",\"@value\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"isPercentageBenefit\"]],[32,1,[\"percentageDecimal\"]],[32,1,[\"amount\"]]],null]],[[\"format\"],[[30,[36,0],[[32,1,[\"isPercentageBenefit\"]],\"0.00%\",\"$0,0.00\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"numeral-formatter\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/benefits-package-table/benefit-amount-cell/template.hbs"
  });

  _exports.default = _default;
});