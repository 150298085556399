define("balance-ember/utils/validator", ["exports", "validate.js"], function (_exports, _validate2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * @class Validator
   * @param model A new or existing EmberData model or WritableModel
   * @param intl The intl service
   * @param options An optional options hash that'll be passed to validate.js
   *
   * @description
   * # What is Validator?
   *
   * Validator is a wrapper class to abstract away the underlying library,
   * currently validate.js, that may be expanded upon for standard Balance
   * use cases.
   *
   * @example
   * ```js
   * let model = this.store.peekRecord('foo', 1);
   * new Validator({ model, intl: this.intl });
   * ```
   *
   * ## Adding rule translations
   * In the i18n lang, add rules within global.validation.rules matching
   * the validatejs rule name like so:
   *  global: {
   *    validation: {
   *      rules: {
   *        presence: {
   *          enabled: '{attribute} can\'t be blank',
   *        },
   *        length: {
   *          is: '{attribute} should be length: {expectedValue}',
   *        ...
   *  allowed variable replacements are:
   *    attribute, expectedValue
   *
   * You will need to map message to i18n paths in the `app/initializers/validate.js` file
   *
   * ## Adding Constraints
   *
   * Constraints follow the constraints API from validateJS
   * https://validatejs.org/#constraints
   *
   * Each constraint requires an associated key/value in the
   * constraintPropertyLookups hash for i18n support.
   *
   * @example
   * ```js
   * class extends Validator {
   *   constraints = {
   *     foo: {
   *       bar: presence: { allowEmpty: false }
   *     },
   *   }
   *   constraintPropertyLookups = {
   *     'foo.bar': 'app.model_name.foobar',
   *   }
   * }
   * ```
   *
   * ## Validating
   *
   * @example
   * ```js
   * fooValidator.validate();
   * ```
   */
  class Validator {
    constructor({
      model = {},
      intl = {},
      options = {}
    }) {
      _defineProperty(this, "constraints", {});

      _defineProperty(this, "constraintPropertyLookups", {});

      _defineProperty(this, "defaultOptions", {
        format: 'detailed',
        fullMessages: false // Don't prepend attribute name

      });

      this.model = model;
      this.intl = intl;
      this.options = options;
      if (!this.intl.t) throw 'You must pass Validator the intl service';
    }

    validate() {
      var _validate;

      let errors = (_validate = (0, _validate2.default)(this.model, this.constraints, { ...this.defaultOptions,
        ...this.options
      })) === null || _validate === void 0 ? void 0 : _validate.map(error => this.processError(error)).map(error => this.translateErrorMessage(error));
      if (!errors) return;
      return {
        all: errors,
        messages: errors.map(({
          message
        }) => message)
      };
    }

    processError({
      attribute,
      error,
      value,
      options
    }) {
      let message = error;
      let expectedValue;

      if (typeof message === 'object') {
        let {
          attribute: deepAttribute,
          message: deepMessage,
          expectedValue: deepExpectedValue
        } = this.processError(error);
        attribute += `.${deepAttribute}`;
        message = deepMessage;
        expectedValue = deepExpectedValue;
      }

      expectedValue = expectedValue ?? this.getExpectedValue(message, options);
      return {
        attribute,
        value,
        expectedValue,
        customMessage: options.message,
        message
      };
    }

    getExpectedValue(message, options) {
      if (message.includes('equality')) {
        return this.intl.t(this.constraintPropertyLookups[options]);
      } // use the end of the i18n string as the options key
      // e.g. 'global.validation.rules.numericality.only_integer' => `onlyInteger`


      let subConstraint = message.split('.').pop();
      return options[Ember.String.camelize(subConstraint)] ?? '';
    }

    translateErrorMessage(error) {
      let translatedAttribute = this.intl.t(this.constraintPropertyLookups[error.attribute]);
      let translationData = {
        attribute: translatedAttribute,
        expectedValue: error.expectedValue
      };
      let validCustomMessageTypes = ['string', 'function'];

      if (error.customMessage && validCustomMessageTypes.includes(typeof error.customMessage)) {
        // If in the constraint a custom message in the form of a string or function was supplied, we will use that instead
        error.message = this.intl.t(error.customMessage, translationData);
      } else {
        error.message = this.intl.t(error.message, translationData);
      }

      return error;
    }

  }

  _exports.default = Validator;
});