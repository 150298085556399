define("balance-ember/components/non-personnel-expense-form/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency", "balance-ember/utils/validators/non-personnel-expense"], function (_exports, _component, _emberConcurrency, _nonPersonnelExpense) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Use this component to show a form that users can use to edit/add a non-personnel-expense
   *
   * @param {object} expense The expense to modify
   * @param {object} allocation The allocation to assign the expense if it's a new expense
   * @param {string} formTitle The text to display as the title of the form
   * @param {string} actionButtonText The text to display on the primary button
   * @param {string} disabledButtonText What to display when the button is disabled
   */
  var _default = _component.default.extend({
    router: Ember.inject.service(),
    modelChanges: Ember.inject.service(),
    features: Ember.inject.service(),
    store: Ember.inject.service(),
    media: Ember.inject.service(),
    intl: Ember.inject.service(),
    expense: null,
    allocation: null,
    isVendorInvalid: false,
    isXlSize: Ember.computed.readOnly('media.isXl'),
    modalSize: Ember.computed('media.{isXl,isMobile}', function () {
      if (this.media.isMobile) {
        return 'slideout full-width';
      }

      if (this.media.isXl) {
        return 'slideout xw'; // Why not call it xw and not extra-wide?
        // Because the UI-Modal component's style code would
        // see 'wide' and also include the regular wide class.
      }

      return 'slideout wide';
    }),

    init() {
      this._super(...arguments);

      this.setupAsyncLoads();
    },

    async setupAsyncLoads() {
      if (!this.expense.isNew) {
        let items = await this.fetchItems.perform();
        this.expense.items = items.toArray();
      }

      let strategies = await this.fetchStrategies.perform();
      this.expense.expenseStrategies = strategies.toArray();
    },

    /**
     * We want to be able to reuse the form for different actions (edit, new) so we need
     * this copy to be dynamic
     */
    formTitle: '',
    actionButtonText: '',
    disabledButtonText: '',

    /**
     * We want the parent context to be in charge of handling what happens when we close the form
     */
    onClose() {},

    fetchItems: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('nonPersonnelExpenses/item', {
        filter: {
          non_personnel_expense_id: {
            eq: this.expense.id
          }
        }
      });
    }).drop(),
    fetchStrategies: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('expenseStrategy', {
        include: 'budget_strategy',
        filter: {
          expense_id: {
            eq: this.expense.id
          }
        }
      });
    }).drop(),
    save: (0, _emberConcurrency.task)(function* () {
      var _this$validate;

      let errors = (_this$validate = this.validate()) === null || _this$validate === void 0 ? void 0 : _this$validate.messages;
      Ember.set(this, 'errors', errors);
      if (this.errors) return;
      this.deleteEmptyNotes();
      yield this.expense.save();
      this.notifyModelChanges();
      this.transitionToExpenses();
    }).drop(),

    validate() {
      if (this.isVendorInvalid) return {
        messages: [this.intl.t('budget.non_personnel_expense_form.vendor.validation_invalid')]
      };
      return new _nonPersonnelExpense.default({
        model: this.expense,
        intl: this.intl,
        options: {
          numSegments: this.expense.expenseSegmentTypes.length
        }
      }).validate();
    },

    deleteEmptyNotes() {
      if (Ember.isPresent(this.expense.notes)) {
        this.expense.notes.forEach(note => {
          if (Ember.isBlank(note.message)) this.expense.deleteNote(note);
        });
      }
    },

    notifyModelChanges() {
      this.modelChanges.addChange({
        id: this.expense.id,
        modelType: 'nonPersonnelExpense'
      });
    },

    transitionToExpenses() {
      this.router.transitionTo('budgeting.budget.expenses');
    },

    actions: {
      cancel() {
        this.transitionToExpenses();
      },

      updateVendor(vendor, {
        isValid = true
      } = {}) {
        Ember.set(this, 'isVendorInvalid', !isValid);
        if (!vendor && this.expense.vendor) this.expense.disassociateVendor();else this.expense.vendor = vendor;
      },

      deleteItem(item) {
        this.expense.deleteItem(item);
      },

      deleteStrategy(strategy) {
        this.expense.deleteExpenseStrategy(strategy);
      }

    }
  });

  _exports.default = _default;
});