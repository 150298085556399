define("balance-ember/components/manage/spending-by-segment/cell-utilized/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/0sXpQc8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex justify-between mb-4\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"utilized\"]]],[[\"format\",\"currency\",\"abbreviate\"],[\"$0,0.00\",\"dollars\",true]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"span\"],[15,0,[30,[36,2],[[30,[36,1],[[32,0,[\"isDeficit\"]],\"text-material-red500\",\"text-material-indigo400\"],null],\" font-bold\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,0,[\"isInfinite\"]],\"N/A\",[30,[36,2],[[32,0,[\"percentUtilized\"]],\"%\"],null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"percentage-bar\",[],[[\"@backgroundColor\",\"@overflowColor\",\"@bars\"],[\"bg-material-indigo200\",\"bg-material-red500\",[30,[36,4],[[30,[36,3],null,[[\"percentage\",\"color\"],[[32,0,[\"percentUtilized\"]],\"bg-material-indigo400\"]]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\",\"if\",\"concat\",\"hash\",\"array\"]}",
    "moduleName": "balance-ember/components/manage/spending-by-segment/cell-utilized/template.hbs"
  });

  _exports.default = _default;
});