define("balance-ember/components/positions-table/nested-table/footer/cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DzlC2hhw",
    "block": "{\"symbols\":[\"@align\",\"@last\",\"@first\",\"@bold\",\"&attrs\",\"&default\"],\"statements\":[[11,\"td\"],[16,0,[31,[\"\\n  py-4\\n  \",[30,[36,1],[[32,4],\"font-bold\",\"font-normal\"],null],\"\\n  \",[30,[36,1],[[32,3],\"pl-14\"],null],\"\\n  \",[30,[36,1],[[32,2],\"pr-14\"],null],\"\\n  \",[30,[36,1],[[30,[36,0],[[32,1],\"left\"],null],\"text-left\"],null],\"\\n  \",[30,[36,1],[[30,[36,0],[[32,1],\"right\"],null],\"text-right\"],null],\"\\n  \"]]],[17,5],[12],[2,\"\\n  \"],[18,6,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "moduleName": "balance-ember/components/positions-table/nested-table/footer/cell/template.hbs"
  });

  _exports.default = _default;
});