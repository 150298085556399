define("balance-ember/components/ui-modal/errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hRNt9sYD",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[6,[37,2],[[32,0,[\"errors\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"p-16 pl-32 sticky pin-modal-header z-10 w-full bg-material-red100\"],[12],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"text-material-gray700\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"errors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[14,0,\"leading-none not-first:mt-8 flex items-center\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"text-material-red400 mr-4 inline-flex items-center\"],[12],[2,\"\\n            \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"error-exclamation\",\"svg-icon fill-current\"]],null],[2,\"\\n          \"],[13],[2,\"\\n          \"],[1,[32,1]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "balance-ember/components/ui-modal/errors/template.hbs"
  });

  _exports.default = _default;
});