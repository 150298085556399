define("balance-ember/components/report-summary-stat/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['summary-stat report-summary-stat']
  });

  _exports.default = _default;
});