define("balance-ember/models/fiscal-year-doc", ["exports", "balance-ember/models/doc", "@ember-data/model"], function (_exports, _doc, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _doc.default.extend({
    fiscalYear: (0, _model.belongsTo)('fiscalYear', {
      async: false
    })
  });

  _exports.default = _default;
});