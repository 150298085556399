define("balance-ember/components/export-individual-budget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rCCZ2tKg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n  \"],[8,\"export-button\",[],[[\"@budget\",\"@size\",\"@reportType\",\"@exportAction\",\"@buttonText\"],[[32,0,[\"budget\"]],\"sm\",\"account-budgets\",[30,[36,0],[[32,0],\"export\"],null],\"Account Budgets\"]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ml-12\"],[12],[2,\"\\n    \"],[8,\"export-button\",[],[[\"@budget\",\"@size\",\"@reportType\",\"@exportAction\",\"@buttonText\"],[[32,0,[\"budget\"]],\"sm\",\"planned-expenses\",[30,[36,0],[[32,0],\"export\"],null],\"Planned Expense\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ml-12\"],[12],[2,\"\\n    \"],[8,\"export-button\",[],[[\"@budget\",\"@size\",\"@reportType\",\"@exportAction\",\"@buttonText\"],[[32,0,[\"budget\"]],\"sm\",\"staff_roster\",[30,[36,0],[[32,0],\"export\"],null],\"Export Positions\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/components/export-individual-budget/template.hbs"
  });

  _exports.default = _default;
});