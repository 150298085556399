define("balance-ember/routes/budgeting/budget/expenses/view/allocations/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      id
    }) {
      return this.store.peekRecord('budget', id);
    }

  });

  _exports.default = _default;
});