define("balance-ember/components/auth-provider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TmLM1zc/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],null,[[\"isBudgetArchitect\",\"isAdmin\",\"hasBudgetingAccess\",\"hasManageAccess\",\"isPurchasingApprover\",\"canManageDistrictBookmarks\",\"isAuthenticated\",\"isUsingLDAP\"],[[32,0,[\"session\",\"isBudgetArchitect\"]],[32,0,[\"session\",\"isAdmin\"]],[32,0,[\"session\",\"hasBudgetingAccess\"]],[32,0,[\"session\",\"hasManageAccess\"]],[32,0,[\"session\",\"isPurchasingApprover\"]],[32,0,[\"session\",\"canManageDistrictBookmarks\"]],[32,0,[\"session\",\"isAuthenticated\"]],[32,0,[\"features\",\"ldap\"]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "balance-ember/components/auth-provider/template.hbs"
  });

  _exports.default = _default;
});