define("balance-ember/components/filter-form/number/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pgMcOL3j",
    "block": "{\"symbols\":[\"@value\",\"@update\",\"@filter\",\"&attrs\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,3,[\"type\",\"state\"]],\"currency\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"one-way-currency-mask\",[[24,0,\"css-input-field\"],[17,4]],[[\"@value\",\"@update\",\"@options\"],[[32,1],[32,2],[30,[36,2],null,[[\"prefix\"],[\"$ \"]]]]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,3,[\"type\",\"state\"]],\"percent\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"one-way-percentage-mask\",[[24,0,\"css-input-field\"],[17,4]],[[\"@value\",\"@update\"],[[32,1],[32,2]]],null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"one-way-number-mask\",[[24,0,\"css-input-field\"],[17,4]],[[\"@value\",\"@update\",\"@decimal\"],[[32,1],[32,2],[30,[36,0],[[32,3,[\"type\",\"state\"]],\"decimal\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"hash\"]}",
    "moduleName": "balance-ember/components/filter-form/number/input/template.hbs"
  });

  _exports.default = _default;
});