define("balance-ember/routes/budgeting/fiscal-year/budgets/allocation/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let id = this.paramsFor('budgeting.fiscal-year.budgets.allocation').allocation_id;
      return this.store.findRecord('allocation', id, {
        include: 'segments.segment_type,position_expenses',
        reload: true
      });
    }

  });

  _exports.default = _default;
});