define("balance-ember/components/budget/reports/displaced-employees/component", ["exports", "balance-ember/mixins/budgeting-reports", "ember-concurrency"], function (_exports, _budgetingReports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_budgetingReports.default, {
    reportName: 'displaced-employees',
    defaultSortColumn: 'code',

    init() {
      this.fetchEmployeeStatuses.perform();
      Ember.set(this, 'employeeStatuses', []);

      this._super(...arguments);
    },

    columns: Ember.computed(function () {
      return [{
        label: 'Employee ID',
        valuePath: 'code',
        sortName: 'code',
        testSelector: 'code',
        cellClassNames: 'align-top',
        cellComponent: 'overflowing-table-cell',
        width: '250px'
      }, {
        label: 'First Name',
        valuePath: 'firstName',
        sortName: 'first_name',
        testSelector: 'first_name',
        cellClassNames: 'tabular-amount text-sm',
        cellType: 'allovue-cell',
        type: 'allovue-column'
      }, {
        label: 'Last Name',
        valuePath: 'lastName',
        sortName: 'last_name',
        testSelector: 'last_name',
        cellClassNames: 'tabular-amount text-sm',
        cellType: 'allovue-cell',
        type: 'allovue-column'
      }, {
        label: 'Status',
        valuePath: 'employeeStatus',
        sortName: 'employee_status',
        testSelector: 'employee_status',
        cellClassNames: 'tabular-amount text-sm',
        cellType: 'allovue-cell',
        type: 'allovue-column'
      }];
    }),
    fetchEmployeeStatuses: (0, _emberConcurrency.task)(function* () {
      let employeeStatuses = yield this.store.query('employeeStatus', {
        sort: 'status_name'
      });
      return Ember.set(this, 'employeeStatuses', employeeStatuses.toArray());
    }),
    categories: Ember.computed('employeeStatuses', function () {
      return [{
        title: 'Employee ID',
        value: 'code',
        type: 'text'
      }, {
        title: 'First Name',
        value: 'first_name',
        type: 'text'
      }, {
        title: 'Last Name',
        value: 'last_name',
        type: 'text'
      }, {
        title: 'Status',
        value: 'employee_status',
        type: 'select',
        filterOptions: {
          options: this.employeeStatuses,
          valuePath: 'statusName',
          labelPath: 'statusName'
        }
      }];
    })
  });

  _exports.default = _default;
});