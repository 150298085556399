define("balance-ember/components/budgeting-modal-errors/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    model: null,
    errors: null,
    // If the caller passes in their own errors, use that, otherwise use the model errors
    modelErrors: Ember.computed.or('errors', 'model.errors')
  });

  _exports.default = _default;
});