define("balance-ember/components/ui-modal/loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OwFhcQtl",
    "block": "{\"symbols\":[\"@loadingText\"],\"statements\":[[8,\"delayed-block\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"loading-container\",[[24,0,\"min-h-full-450\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"loading-text\",[],[[],[]],[[\"default\"],[{\"statements\":[[1,[32,1]]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"loading-spinner\",[],[[\"@size\"],[\"xxxl\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/ui-modal/loader/template.hbs"
  });

  _exports.default = _default;
});