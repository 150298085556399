define("balance-ember/components/slideout-budgeting-segments-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Use this component to display the necessary segement <select> fields for an expense. This
   * component will figure out which fields have not been defined in the allocation and will
   * show them.
   *
   * @param {array} segmentTypes All the segment types that exist
   * @param {object} segmentable The model that needs the segments. (This should have a `segments`
   * property so you shouldn't need to pass `segments` explicitly.
   * @param {array} segments The segments already set on the expense
   */
  var _default = Ember.Component.extend({
    salaryTypeEditable: true,
    sideposting: false,
    segmentTypesMap: Ember.computed('salaryTypeEditable', 'segmentTypes.[]', 'segmentable.segments', 'segments.[]', function () {
      let segmentTypes = this.segmentTypes;
      let segments = this.segments || this.segmentable.segments;
      return segmentTypes.map(segmentType => {
        let selectedSegment = segments && segments.findBy('segmentType.id', segmentType.id);
        let blockedFromEdit = segmentType.salarySegment && !this.salaryTypeEditable;
        return {
          segmentType,
          selectedSegment,
          blockedFromEdit
        };
      });
    }),

    focusNextSegmentTypeInput(element) {
      let idx = [...this._segmentTypeElements()].indexOf(element);
      Ember.run.next(() => {
        if (this._segmentTypeElements()[idx + 1]) {
          this._segmentTypeElements()[idx + 1].querySelector('input').focus();
        }
      });
    },

    _segmentTypeElements() {
      return this.element.querySelectorAll('[data-segmenttype-id]');
    },

    _segmentTypeElementById(segmentTypeId) {
      return this.element.querySelector(`[data-segmenttype-id="${segmentTypeId}"]`);
    },

    actions: {
      onSegmentUpdate(segment, segmentTypeId) {
        if (segment) {
          this.send('chooseSegment', segment, this._segmentTypeElementById(segmentTypeId));
        } else {
          this.send('unChooseSegment', segmentTypeId);
        }
      },

      chooseSegment(segment, segmentTypeElement) {
        // We want to make sure that segments is treated as immutable so that if the segments are
        // being shared on via reference, the changes made won't also be made on
        // the underlying model
        let segments = this.segmentable.segments.toArray();

        if (this.sideposting) {
          Ember.set(segment, 'disassociate', false);
        } else {
          let existingSegmentsOfSameType = segments.filterBy('segmentType.id', segment.segmentType.id);
          segments.removeObjects(existingSegmentsOfSameType);
        }

        if (this.segmentable.addSegment) {
          this.segmentable.addSegment(segment);
        } else {
          segments.pushObject(segment);
          Ember.set(this, 'segmentable.segments', segments);
        }

        this.focusNextSegmentTypeInput(segmentTypeElement);
      },

      unChooseSegment(segmentTypeId) {
        // We want to make sure that segments is treated as immutable so that if the segments are
        // being shared via reference, the changes made won't also be made on
        // the underlying model
        let segments = this.segmentable.segments.toArray();
        let existingSegmentsOfSameType = segments.filterBy('segmentType.id', segmentTypeId);

        if (this.sideposting) {
          existingSegmentsOfSameType.forEach(segment => {
            if (this.segmentable.disassociateSegment) {
              this.segmentable.disassociateSegment(segment);
            } else {
              Ember.set(segment, 'disassociate', true);
            }
          });
        } else {
          segments.removeObjects(existingSegmentsOfSameType);
          Ember.set(this, 'segmentable.segments', segments);
        }
      }

    }
  });

  _exports.default = _default;
});