define("balance-ember/controllers/budgeting/vendors", ["exports", "balance-ember/utils/urls", "balance-ember/types/table", "@microstates/ember"], function (_exports, _urls, _table, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetVendorsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('availableFiscalYears.current.id'), _dec5 = Ember.computed('availableFiscalYears.current.id'), _dec6 = Ember.computed('modelChanges.changes.[]'), _dec7 = Ember._action, (_class = class BudgetVendorsController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor2, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor3, this);

      this.tableState = (0, _ember.Store)((0, _ember.create)(_table.default), nextState => this.tableState = nextState);
      this.categories = [];
      this.tableState.defaultSort.set({
        column: 'name',
        dir: 'asc'
      });
    }

    get vendorsBulkUrl() {
      let fiscalYearId = this.availableFiscalYears.current.id;
      return `${(0, _urls.URLForAPIv3)()}/vendors/${fiscalYearId}/bulk_upload`;
    }

    get fiscalYearFilter() {
      return {
        fiscal_year_id: {
          eq: this.availableFiscalYears.current.id
        }
      };
    }

    get vendorChanges() {
      return this.modelChanges.changesForModelType('vendor');
    }

    afterUploadSuccess() {
      this.modelChanges.addChange({
        modelType: 'vendor',
        changeType: 'create'
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "vendorsBulkUrl", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "vendorsBulkUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fiscalYearFilter", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "fiscalYearFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "vendorChanges", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "vendorChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterUploadSuccess", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "afterUploadSuccess"), _class.prototype)), _class));
  _exports.default = BudgetVendorsController;
});