define("balance-ember/templates/budgeting/employees/destroy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oH3+X6UR",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal/destroy\",[],[[\"@closeModal\",\"@destroy\",\"@model\",\"@text\"],[[30,[36,0],[[32,0],\"cancelEmployee\"],null],[30,[36,0],[[32,0],\"deleteEmployee\"],null],[32,0,[\"model\"]],[32,0,[\"warningMessage\"]]]],null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/templates/budgeting/employees/destroy.hbs"
  });

  _exports.default = _default;
});