define("balance-ember/routes/district-admin/users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      user_id: userId
    }) {
      return this.store.findRecord('user', userId, {
        include: 'budget_leas,manage_permissions.budget_lea,manage_permissions.segments'
      });
    }

  });

  _exports.default = _default;
});