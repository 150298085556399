define("balance-ember/components/delete-user-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    features: Ember.inject.service(),
    modelChanges: Ember.inject.service(),
    actions: {
      deleteUser() {
        // features is a proxy, so have to use get
        // eslint-disable-next-line ember/no-get
        if (!Ember.get(this, 'features.ldap')) {
          this.user.set('softDeletedAt', new Date());
          this.user.save().then(user => {
            user.unloadRecord();
            this.tableState.rows.clear();
            this.closeModal();
          });
        }
      }

    }
  });

  _exports.default = _default;
});