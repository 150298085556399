define("balance-ember/utils/writeable-models/non-personnel-expense", ["exports", "balance-ember/utils/writeable-models/expense"], function (_exports, _expense) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NonPersonnelExpenseWriteableModel = (_dec = Ember._tracked, _dec2 = Ember.computed('items.@each.{quantity,unitPrice}'), _dec3 = Ember.computed('allocation.remainingAmount', 'amountChange', 'itemsTotal'), (_class = class NonPersonnelExpenseWriteableModel extends _expense.default {
    constructor(args) {
      if (!args.paths) {
        args.paths = {
          purchaseOrder: true,
          vendor: true,
          notes: true,
          items: true,
          segments: {
            segmentType: true
          },
          allocation: {
            budget: true,
            segments: {
              segmentType: true
            }
          },
          expenseStrategies: {
            budgetStrategy: {
              budgetPriority: true
            }
          }
        };
      }

      super(args);

      _initializerDefineProperty(this, "vendorSelection", _descriptor, this);

      _defineProperty(this, "canHaveSalarySegment", true);
    }

    get isEditable() {
      return Boolean(this.isAllocationEditable && !this.purchaseOrder);
    }

    get amountChange() {
      return this.originalItemsTotal - this.itemsTotal;
    }

    get itemsTotal() {
      return this.items.reduce((acc, item) => {
        return acc += (item.quantity || 0) * (item.unitPrice || 0);
      }, 0);
    }
    /**
     * `amount` is a readOnly property from the server with the
     * persisted value of itemsTotal
     */


    get originalItemsTotal() {
      return this.amount;
    }

    get allocationRemaining() {
      var _this$allocation;

      return (((_this$allocation = this.allocation) === null || _this$allocation === void 0 ? void 0 : _this$allocation.remainingAmount) ?? 0) + this.amountChange;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "vendorSelection", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "itemsTotal", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "itemsTotal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allocationRemaining", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "allocationRemaining"), _class.prototype)), _class));
  _exports.default = NonPersonnelExpenseWriteableModel;
});