define("balance-ember/controllers/budgeting/employees/destroy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modelChanges: Ember.inject.service(),
    warningMessage: Ember.computed('model', function () {
      return 'Deleting these employees will permanently remove them.';
    }),
    actions: {
      cancelEmployee() {
        // Do this in case there were any server errors on DELETE, so the model isn't in an
        // `isDeleted` state
        this.model.rollbackAttributes();
        this.transitionToRoute('budgeting.employees');
      },

      deleteEmployee() {
        let model = this.model;
        return model.destroyRecord().then(() => {
          this.modelChanges.addChange({
            id: model.id,
            modelType: 'employee',
            changeType: 'delete'
          });
          this.transitionToRoute('budgeting.employees');
        });
      }

    }
  });

  _exports.default = _default;
});