define("balance-ember/utils/is-present", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * A computed macro to check if a property on your object is present
   *
   * @param {string} dependentKey The property to watch on your object
   */
  function _default(dependentKey) {
    return Ember.computed(dependentKey, function () {
      return Ember.isPresent(this[dependentKey]);
    });
  }
});