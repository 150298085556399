define("balance-ember/components/form/label-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MkF10uAh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"Label\",\"id\"],[[30,[36,0],[\"form/label-group/label\"],[[\"uniqueId\"],[[32,0,[\"uniqueId\"]]]]],[32,0,[\"uniqueId\"]]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/form/label-group/template.hbs"
  });

  _exports.default = _default;
});