define("balance-ember/components/budgeting-link/component", ["exports", "moment", "balance-ember/utils/basic-dropdown-calculate-nav-position"], function (_exports, _moment, _basicDropdownCalculateNavPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    calculatePosition: _basicDropdownCalculateNavPosition.calculatePosition,
    availableFiscalYears: Ember.inject.service(),
    session: Ember.inject.service(),
    tagName: 'li',
    classNames: ['budgeting-dropdown'],
    classNameBindings: ['isBudgetArchitect:architect'],
    arrowIconInset: 0,
    fiscalYear: Ember.computed.readOnly('availableFiscalYears.current'),
    truncatedFiscalYear: Ember.computed('availableFiscalYears.isEmpty', 'fiscalYear.{endDate,startDate}', function () {
      if (this.availableFiscalYears.isEmpty) {
        return null;
      }

      let startYear = (0, _moment.default)(this.fiscalYear.startDate).format('YY');
      let endYear = (0, _moment.default)(this.fiscalYear.endDate).format('YY');
      return `FY${startYear}–${endYear}`;
    }),
    budgetNavRole: Ember.computed('availableFiscalYears.isEmpty', 'session.isBudgetArchitect', function () {
      let role = this.session.isBudgetArchitect ? 'architect' : 'non-architect';

      if (this.availableFiscalYears.isEmpty) {
        role = `${role}/no-fy`;
      }

      return role;
    }),
    isPurchasingApprover: Ember.computed.reads('session.currentUser.isPurchasingApprover')
  });

  _exports.default = _default;
});