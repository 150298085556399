define("balance-ember/components/benefits-package-benefits/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+52E//AP",
    "block": "{\"symbols\":[\"benefit\"],\"statements\":[[10,\"dl\"],[14,0,\"flex-cell-expand combo-list\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"package\",\"benefits\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flex-cell-expand combo-list-item\"],[12],[2,\"\\n      \"],[10,\"dt\"],[14,0,\"flex-row-cell-xlg details\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"code-spacer\"],[12],[2,\"\\n          \"],[10,\"span\"],[14,0,\"code\"],[12],[1,[32,1,[\"segment\",\"code\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n          \"],[1,[32,1,[\"segment\",\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"],[2,\"      \"],[1,[30,[36,0],[[32,1,[\"benefitType\"]]],[[\"benefit\"],[[32,1]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/benefits-package-benefits/template.hbs"
  });

  _exports.default = _default;
});