define("balance-ember/components/manage/accounts-column-chart/tooltip-formatter", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = tooltipFormatter;

  function formatAmount(amount) {
    return (0, _numeral.default)(amount).format('$0,0.00');
  }

  function tooltipAmountRow({
    backgroundClasses,
    textClasses = '',
    amount,
    label
  } = {}) {
    return `
    <div class='flex justify-between test-chart-tooltip-${label.replace(' ', '')}'>
      <div class='flex items-center'>
        <div class='w-16 h-16 ${backgroundClasses}'></div>
        <div class='ml-8'>${label}</div>
      </div>
      <div class='${textClasses}'>${formatAmount(amount)}</div>
    </div>
  `;
  }

  function tooltipFormatter() {
    let {
      accountCode,
      amounts: {
        budgeted,
        available,
        spent,
        encumbered
      }
    } = this.point;
    let isOverdrawn = available < 0;
    let budgetedRow = tooltipAmountRow({
      amount: budgeted,
      backgroundClasses: 'bg-material-cyan300',
      label: 'Current Budget'
    });
    let spentRow = tooltipAmountRow({
      amount: spent,
      backgroundClasses: 'bg-material-indigo-a200',
      label: 'Spent'
    });
    let encumberedRow = tooltipAmountRow({
      amount: encumbered,
      backgroundClasses: 'encumbered-legend bg-cover',
      label: 'Encumbered'
    });
    let availableRow = tooltipAmountRow({
      amount: available,
      backgroundClasses: isOverdrawn ? 'bg-danger' : '',
      label: 'Available',
      textClasses: isOverdrawn ? 'text-danger' : ''
    });
    return `\
    <div class='font-sans text-sm text-material-gray700 pt-16 pb-12'> \
      <div class='px-16'> \
        <div class='text-center mb-12 test-chart-tooltip-title'> \
          ${accountCode}
        </div> \
        <div class='mb-12'> \
          ${budgetedRow} \
        </div> \
        <div class='mb-12'> \
          ${spentRow} \
        </div> \
        ${encumberedRow} \
      </div> \

      <div class='mt-16 pt-12 px-16 border-t border-material-gray200'> \
        ${availableRow} \
      </div> \
    </div> \
  `;
  }
});