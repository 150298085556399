define("balance-ember/components/non-personnel-expense-form-data/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency", "balance-ember/utils/writeable-model"], function (_exports, _component, _emberConcurrency, _writeableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component is to be used to render the non-personnel-expense-form. This component can be considered
   * a `data-loading` component or an `asynchronous` component. As discussed in this article
   * https://emberigniter.com/should-components-load-data/ it makes sense for TaglessComponents to load
   * data when they are requiring data that isn't connected to the URL. The route's model
   * hook, if connected to a specific model through dynamic segments, will only return the specified
   * model. This is especially true if an id/model is passed in via a `link-to`. This page requires
   * more than just the `non-personnel-expense` model, so we need to decide where the logic of pulling the
   * additional `segmentTypes`, etc. Should live. We could do this on a controller via
   * `setupController`, however the ember-community is developing a concept of `routable-components`
   * that will most likely make controllers obsolete and so moving away from controllers seems
   * to be a good idea. Therefore, we'll put this logic into a component and use ember-concurrency
   * so that if the component is destroyed the promises will be cancelled
   *
   * @param {object} allocation The allocation to assign the positionExpense to if it's a new positionExpense
   * @param {object} expense The expense the user wants to edit
   * @param {string} formTitle The text to display as the title of the form
   * @param {string} actionButtonText The text to display on the primary button
   * @param {string} disabledButtonText What to display when the button is disabled
   * @param {function} onClose What to do when we are done with the form
   */
  var _default = _component.default.extend({
    store: Ember.inject.service(),
    network: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    currentFiscalYear: Ember.computed.readOnly('availableFiscalYears.current'),
    expense: null,
    allocation: null,

    /**
     * We want to be able to reuse the form for different actions (edit, new) so we need
     * this copy to be dynamic
     */
    formTitle: '',
    actionButtonText: '',
    disabledButtonText: '',
    loadingData: null,
    loadDataTask: (0, _emberConcurrency.task)(function* () {
      let expense = this.expense; // If we are creating a new nonPersonnelExpense then it should be
      // empty and we will create one here

      if (Ember.isEmpty(expense)) {
        var _this$allocation;

        expense = this.store.createRecord('non-personnel-expense', {
          allocation: this.allocation,
          items: [this.store.createRecord('nonPersonnelExpenses/item')],
          notes: [this.store.createRecord('note')]
        });
        this.initExpense(expense); // If the allocation has no expenses then it's possible the segments for the allocation
        // won't be loaded

        yield (_this$allocation = this.allocation) === null || _this$allocation === void 0 ? void 0 : _this$allocation.segments;
      } else {
        this.initExpense(expense);

        if (Ember.isEmpty(this.expense.notes)) {
          this.writableExpense.addNote(this.store.createRecord('note'));
        }
      }
    }),

    initExpense(model) {
      Ember.set(this, 'writableExpense', new _writeableModel.default({
        model,
        store: this.store,
        network: this.network,
        allSegmentTypes: this.currentFiscalYear.segmentTypes
      }));
    },

    init() {
      this._super(...arguments); // We want the flags that a TaskInstance offers, so we can know when the task is finished.
      // We don't want to load the form till we have all the data


      Ember.set(this, 'loadingData', this.loadDataTask.perform());
    },

    /**
     * We want the parent context to be in charge of handling what happens when we close the form
     */
    onClose() {}

  });

  _exports.default = _default;
});