define("balance-ember/routes/users/reset-password", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UsersResetPasswordRoute = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    queryParams: {
      reset_token: {
        refreshModel: false
      }
    },

    activate() {
      return document.title = 'Reset Password - Allovue Balance';
    }

  });
  var _default = UsersResetPasswordRoute;
  _exports.default = _default;
});