define("balance-ember/components/budget/non-personnel-expenses-table/edit-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4EgT6c0t",
    "block": "{\"symbols\":[\"group\",\"@expense\"],\"statements\":[[8,\"form/label-group\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Label\"]],[[24,0,\"sr-only\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"budget.non_personnel_expense_form.data.notes\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,2,[\"isEditable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"one-way-textarea\",[[24,0,\"css-textarea-field\"]],[[\"@id\",\"@value\",\"@update\"],[[32,1,[\"id\"]],[32,2,[\"notes\",\"firstObject\",\"message\"]],[32,0,[\"update\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"w-full\"],[12],[1,[32,2,[\"notes\",\"firstObject\",\"message\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "balance-ember/components/budget/non-personnel-expenses-table/edit-notes/template.hbs"
  });

  _exports.default = _default;
});