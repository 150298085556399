define("balance-ember/components/budget/non-personnel-expenses-table/edit-vendor/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetNonPersonnelExpensesTableEditVendorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    return yield Ember.RSVP.all([this.loadAmazonBusinessVendorTask.perform(), this.fetchInitialVendors.perform()]);
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    let results = yield this.store.query('vendor', {
      filter: {
        fiscal_year_id: {
          eq: this.currentFiscalYearId
        },
        amazon_business: {
          eq: true
        }
      },
      page: {
        number: 1,
        size: 1
      }
    });
    return results ? results.firstObject : null;
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('vendor', {
      filter: {
        fiscal_year_id: {
          eq: this.currentFiscalYearId
        },
        amazon_business: {
          eq: false
        }
      },
      page: {
        number: 1,
        size: 6
      },
      sort: 'name'
    });
  }), _dec9 = Ember._action, (_class = class BudgetNonPersonnelExpensesTableEditVendorComponent extends _component.default {
    constructor() {
      super(...arguments); // We only want to load once

      _initializerDefineProperty(this, "network", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "errors", _descriptor5, this);

      _initializerDefineProperty(this, "loadTask", _descriptor6, this);

      _initializerDefineProperty(this, "loadAmazonBusinessVendorTask", _descriptor7, this);

      _initializerDefineProperty(this, "fetchInitialVendors", _descriptor8, this);

      if (!this.args.loadTasks.vendor) {
        this.loadTask.perform();
        this.args.loadTasks.vendor = {
          loadAmazonBusinessVendorTask: this.loadAmazonBusinessVendorTask,
          fetchInitialVendors: this.fetchInitialVendors
        };
      }

      if (this.args.expense.vendor && this.args.expense.vendor.amazonBusiness) {
        this.args.expense.vendorSelection = 'amazon-business';
      } else if (this.args.expense.vendor) {
        this.args.expense.vendorSelection = 'other-vendor';
      }
    }

    get amazonBusinessVendor() {
      var _this$args$loadTasks$;

      return (_this$args$loadTasks$ = this.args.loadTasks.vendor.loadAmazonBusinessVendorTask.lastSuccessful) === null || _this$args$loadTasks$ === void 0 ? void 0 : _this$args$loadTasks$.value;
    }

    get currentFiscalYearId() {
      return this.availableFiscalYears.current.id;
    }

    updateSelection() {
      if (this.args.expense.vendor && this.args.expense.vendor.amazonBusiness) {
        this.args.expense.vendorSelection = 'amazon-business';
      } else if (this.args.expense.vendor) {
        this.args.expense.vendorSelection = 'other-vendor';
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "network", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loadTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loadAmazonBusinessVendorTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "fetchInitialVendors", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateSelection", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelection"), _class.prototype)), _class));
  _exports.default = BudgetNonPersonnelExpensesTableEditVendorComponent;
});