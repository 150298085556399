define("balance-ember/components/planned-spending-by-segment/personnel-percentage-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F0bRmJsn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex justify-between items-baseline\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text-material-pink400 font-bold\"],[12],[2,\"\\n    \"],[1,[32,0,[\"personnelPercentage\"]]],[2,\"%\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"span\"],[14,0,\"ml-10 text-sm\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,0,[\"personnelAmount\"]]],[[\"abbreviate\"],[true]]]],[2,\"\\n    \"],[13],[2,\"\\n    of\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,0,[\"totalAmount\"]]],[[\"abbreviate\"],[true]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"percentage-bar\",[],[[\"@style\",\"@bars\"],[\"distribution\",[30,[36,2],[[30,[36,1],null,[[\"percentage\",\"color\",\"border\"],[[32,0,[\"personnelPercentage\"]],\"bg-material-pink50\",\"border-material-pink400\"]]],[30,[36,1],null,[[\"percentage\",\"color\",\"border\"],[[32,0,[\"nonPersonnelPercentage\"]],\"bg-material-purple100\",\"border-material-purple600\"]]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\",\"hash\",\"array\"]}",
    "moduleName": "balance-ember/components/planned-spending-by-segment/personnel-percentage-cell/template.hbs"
  });

  _exports.default = _default;
});