define("balance-ember/utils/reduce-computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = reduce;

  /**
   * A computed macro for reducing an array into a single value.
   *
   * @param {string} dependentKey The property to watch on your object
   * @param {function} cb The callback to give to reduce
   * @param {any} initialValue The value to start the reduce function with. Will be the first item
   * in the array if not provided.
   */
  function reduce(dependentKey, cb, initialValue) {
    return Ember.computed(dependentKey, function () {
      return this[dependentKey].reduce(cb, initialValue);
    });
  }
});