define("balance-ember/components/manage/summarize/header/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tableState: Ember.computed.readOnly('extra.tableState'),
    summarizeState: Ember.computed.readOnly('extra.summarizeState'),
    onSummarize: Ember.computed.readOnly('extra.onSummarize'),
    onClearSummarize: Ember.computed.readOnly('extra.onClearSummarize'),
    summarizeCategories: Ember.computed.readOnly('extra.summarizeCategories'),
    loadTask: Ember.computed.readOnly('extra.loadTask')
  });

  _exports.default = _default;
});