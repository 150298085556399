define("balance-ember/components/manage/omni-search-box/group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XOf1sksp",
    "block": "{\"symbols\":[\"@group\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"flex border-material-blue-gray100 not-last:border-b\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-160 bg-material-blue-gray50 font-normal text-sm pl-16 pt-8\"],[12],[2,\"\\n    \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[[32,0,[\"groupIconName\"]],[31,[\"svg-icon relative inline \",[32,1,[\"iconClass\"]]]]]],null],[2,\"\\n    \"],[10,\"span\"],[14,0,\"ml-4\"],[12],[1,[32,1,[\"groupName\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[\"flex-1 \",[32,1,[\"themeClass\"]],\" m-6\"]]],[12],[18,2,null],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/manage/omni-search-box/group/template.hbs"
  });

  _exports.default = _default;
});