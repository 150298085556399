define("balance-ember/controllers/users/unlock", ["exports", "balance-ember/utils/urls", "ember-concurrency"], function (_exports, _urls, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UsersUnlockController = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    queryParams: ['unlock_token'],
    userData: Ember.computed('unlock_token', function () {
      return {
        unlock_token: this.unlock_token
      };
    }),
    unlockAccount: (0, _emberConcurrency.task)(function* () {
      return yield this.ajax.request((0, _urls.URLForAccountUnlock)(), {
        data: this.userData
      }).then(() => {
        this.set('errors', []);
        this.notifications.success('Your account has been unlocked.');
        return this.transitionToRoute('login');
      }).catch(({
        payload
      }) => {
        this.set('errors', payload.errors);
      });
    })
  });
  var _default = UsersUnlockController;
  _exports.default = _default;
});