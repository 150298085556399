define("balance-ember/components/destroy-expense-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KxMZ629L",
    "block": "{\"symbols\":[\"@expense\",\"@onClose\"],\"statements\":[[8,\"modal/destroy\",[],[[\"@closeModal\",\"@destroy\",\"@model\"],[[32,2],[30,[36,0],[[32,0],\"deleteExpense\"],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"purpose\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"mt-8 text-material-gray700 text-15\"],[12],[2,\"Are you sure you want to delete the expense for:\"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"text-material-gray700 text-15 font-bold\"],[12],[1,[32,1,[\"purpose\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"mt-8 text-material-gray700 text-15\"],[12],[2,\"Are you sure you'd like to delete this expense?\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "moduleName": "balance-ember/components/destroy-expense-modal/template.hbs"
  });

  _exports.default = _default;
});