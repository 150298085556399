define("balance-ember/components/budget/expense-table/edit-strategies/strategy-finder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MG9pK32e",
    "block": "{\"symbols\":[\"group\",\"budgetStrategy\",\"@index\",\"@expenseStrategy\",\"@expense\"],\"statements\":[[8,\"form/label-group\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"label\"]],[[24,0,\"sr-only\"]],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"budget.non_personnel_expense_form.strategies.strategy\"],null]],[2,\" \"],[1,[32,3]]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"power-select-single\",[],[[\"@triggerId\",\"@options\",\"@searchField\",\"@selected\",\"@onchange\",\"@disabled\"],[[32,1,[\"id\"]],[32,0,[\"availableStrategies\"]],\"fullText\",[32,4,[\"budgetStrategy\",\"name\"]],[32,0,[\"updateBudgetStrategy\"]],[30,[36,1],[[32,5,[\"isEditable\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-italic font-fine text-material-gray600 overflow-hidden h-20 overflowing-text text-overflow-ellipsis\"],[12],[2,\"\\n        \"],[1,[32,2,[\"budgetPriority\",\"name\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"font-bold\"],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"not\"]}",
    "moduleName": "balance-ember/components/budget/expense-table/edit-strategies/strategy-finder/template.hbs"
  });

  _exports.default = _default;
});