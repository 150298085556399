define("balance-ember/components/app-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o63vaL/L",
    "block": "{\"symbols\":[\"session\"],\"statements\":[[8,\"auth-provider\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"isAuthenticated\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"support-block\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"footer_utility-links\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[8,\"link-to\",[],[[\"@route\"],[\"terms-of-use\"]],[[\"default\"],[{\"statements\":[[2,\"Terms of Service\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[8,\"link-to\",[],[[\"@route\"],[\"privacy-policy\"]],[[\"default\"],[{\"statements\":[[2,\"Privacy Policy\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[10,\"a\"],[14,6,\"mailto:support@allovue.com\"],[12],[2,\"support@allovue.com\"],[13],[13],[2,\"\\n    \"],[10,\"li\"],[12],[10,\"span\"],[12],[1,[34,1]],[13],[13],[2,\"\\n    \"],[10,\"li\"],[12],[10,\"span\"],[14,0,\"copyright\"],[12],[2,\"© \"],[1,[32,0,[\"currentYear\"]]],[2,\" Allovue\"],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\",\"app-version\"]}",
    "moduleName": "balance-ember/components/app-footer/template.hbs"
  });

  _exports.default = _default;
});