define("balance-ember/components/manage/segment-cell/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    segmentType: Ember.computed.readOnly('column.segmentType'),
    segmentsHash: Ember.computed.readOnly('row.content.segmentsHash'),
    segmentName: Ember.computed.readOnly('accountSegment.name'),
    segmentCode: Ember.computed.readOnly('accountSegment.code'),
    accountSegment: Ember.computed('segmentsHash', 'segmentType', function () {
      return this.segmentsHash[this.segmentType];
    })
  });

  _exports.default = _default;
});