define("balance-ember/components/budget/allocations/table/segment-count-cell/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    fiscalYearId: Ember.computed.readOnly('availableFiscalYears.current.id'),
    loadSegments: (0, _emberConcurrency.task)(function* () {
      let allocation = yield this.store.loadRecord('allocation', this.row.content.id, {
        include: 'segments.segment_type'
      });
      return allocation.get('segments');
    })
  });

  _exports.default = _default;
});