define("balance-ember/components/budget-totals/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param {object} model
   * @param {boolean} shouldShowBorders Whether or not to show borders between amounts
   * @param {boolean} useTotalTextInLabels Whether or not to show 'Total' in front of each label
   */
  var _default = Ember.Component.extend({
    classNames: ['budget-totals'],
    classNameBindings: ['isFiscalYear:fiscal-year-totals'],
    isFiscalYear: Ember.computed.equal('model.constructor.modelName', 'fiscal-year'),
    shouldShowBorders: false,
    borderWidthClass: Ember.computed('shouldShowBorders', function () {
      return this.shouldShowBorders ? 'border-r' : 'border-r-0';
    }),
    totalAllocated: Ember.computed.readOnly('model.allocatedAmount'),
    totalPlanned: Ember.computed.readOnly('model.plannedAmount'),
    totalNonPersonnelPlannedAmount: Ember.computed.readOnly('model.nonPersonnelPlannedAmount'),
    totalSupplementalPlannedAmount: Ember.computed.readOnly('model.supplementalPlannedAmount'),
    totalRemaining: Ember.computed.readOnly('model.remainingAmount'),
    totalLabel: Ember.computed('useTotalTextInLabels', function () {
      return this.useTotalTextInLabels ? 'Total' : '';
    })
  });

  _exports.default = _default;
});