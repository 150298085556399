define("balance-ember/helpers/dashes-if-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dashesIfEmpty = dashesIfEmpty;
  _exports.default = void 0;

  // Accepts params, hash. Worded here as value and options. I removed options from the args list because JSHint complains as we're not using it in the function.
  function dashesIfEmpty(value) {
    if (value) {
      return value;
    }

    return '--';
  }

  var _default = Ember.Helper.helper(dashesIfEmpty);

  _exports.default = _default;
});