define("balance-ember/components/job-types-table/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/classes/table-serializer"], function (_exports, _component, _emberConcurrency, _tableSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JobTypesTableComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('jobType', { ...this.serializedTableState,
      filter: {
        fiscal_year_id: {
          eq: this.availableFiscalYears.current.id
        },
        ...this.serializedTableState.filter
      },
      include: 'staff_type,benefits_package',
      stats: {
        total: 'count'
      }
    });
  }), _dec6 = Ember._action, _dec7 = Ember._action, (_class = class JobTypesTableComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor3, this);

      _initializerDefineProperty(this, "features", _descriptor4, this);

      _initializerDefineProperty(this, "fetchJobTypes", _descriptor5, this);

      _defineProperty(this, "columns", [{
        label: 'JOB TYPE',
        valuePath: 'title',
        testSelector: 'title',
        sortName: 'title',
        cellComponent: 'overflowing-table-cell'
      }, {
        label: 'CODE',
        valuePath: 'code',
        testSelector: 'code',
        sortName: 'code',
        width: '100px'
      }, {
        label: 'POSITION TYPE',
        valuePath: 'staffType.name',
        testSelector: 'position-type',
        sortName: 'staff_type'
      }, {
        label: 'Allowable',
        valuePath: 'budgetCategory',
        testSelector: 'budget-category',
        sortName: 'budget_category',
        width: '100px',
        cellComponent: 'light-table/cell-components/pretty-value'
      }, {
        label: 'AVG. BASE SALARY',
        valuePath: 'averageBaseSalary',
        cellComponent: 'number-cell',
        testSelector: 'average-base-salary',
        sortName: 'average_base_salary',
        classNames: 'truncate'
      }, {
        label: 'BENEFITS PACKAGE',
        valuePath: 'benefitsPackage.name',
        testSelector: 'benefits-package-name',
        sortName: 'benefits_package',
        cellComponent: 'overflowing-table-cell'
      }, {
        label: 'AVG. BENEFITS',
        valuePath: 'averageBenefitsAmount',
        cellComponent: 'number-cell',
        sortName: 'average_benefits_amount',
        testSelector: 'average-benefits-amount'
      }, {
        label: 'AVG. TOTAL',
        valuePath: 'averageTotal',
        cellComponent: 'number-cell',
        sortName: 'average_total',
        testSelector: 'average-total'
      }, {
        cellComponent: 'light-table/cell-components/table-actions',
        tableActionComponents: ['edit', 'delete'],
        sortable: false,
        hideable: false,
        align: 'right',
        width: '80px',

        buttonText(jobType) {
          return jobType.title;
        },

        isDeleteDisabled(jobType) {
          return !jobType.deletable;
        },

        deleteTooltipText(jobType) {
          if (!jobType.deletable) {
            return `${jobType.title} is in use and cannot be deleted at this time`;
          }

          return `Delete ${jobType.title}`;
        }

      }]);
    }

    get serializedTableState() {
      return new _tableSerializer.default(this.args.tableState).apiQuery;
    }

    edit(jobType) {
      this.router.transitionTo('budgeting.job-types.edit', jobType.id);
    }

    delete(jobType) {
      this.router.transitionTo('budgeting.job-types.destroy', jobType.id);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fetchJobTypes", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class));
  _exports.default = JobTypesTableComponent;
});