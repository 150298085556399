define("balance-ember/templates/manage/charts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rk+IAWrY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"bg-white\"],[12],[2,\"\\n  \"],[8,\"manage/chart\",[],[[\"@tableState\",\"@categories\"],[[32,0,[\"tableState\"]],[32,0,[\"categories\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/manage/charts.hbs"
  });

  _exports.default = _default;
});