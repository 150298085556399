define("balance-ember/components/ui-modal/modal-body/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['styleClasses'],
    isWarning: Ember.computed('style', function () {
      return this.style.includes('warning');
    }),
    style: 'centered',
    styleClasses: Ember.computed('isWarning', 'style', function () {
      let styles = '';

      if (this.style.includes('centered')) {
        styles = 'p-20';
      }

      if (this.style.includes('padded')) {
        styles = `${styles} pt-32`;
      }

      if (this.isWarning) {
        styles = 'pt-32 px-32 pb-24 text-center';
      }

      if (this.style.includes('slideout')) {
        styles = `${styles} slideout-modal-body-height`;
      }

      return styles;
    })
  });

  _exports.default = _default;
});