define("balance-ember/routes/budgeting/fiscal-year/allocations", ["exports", "balance-ember/mixins/route-history"], function (_exports, _routeHistory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeHistory.default, {
    availableFiscalYears: Ember.inject.service(),
    currentFiscalYear: Ember.computed.readOnly('availableFiscalYears.current'),
    queryParams: {
      // We want the model to reload the data for pagination
      page: {
        refreshModel: true
      }
    },

    beforeModel() {
      this.controllerFor('budgeting.fiscal-year.allocations').tableState.filters.push({
        category: 'fiscal_year_id',
        removable: false,
        operators: [{
          value: 'eq',
          filterValues: [this.currentFiscalYear.id]
        }]
      });
    },

    model() {
      return Ember.RSVP.hash({
        budgets: this.store.loadRecords('budget')
      });
    },

    setupController(controller, models) {
      controller.set('budgets', models.budgets);

      this._super(controller, models);
    }

  });

  _exports.default = _default;
});