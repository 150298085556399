define("balance-ember/components/user/profile-tabs/account-info/form-provider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yeZe2miN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],null,[[\"user\",\"errors\",\"actions\",\"tasks\"],[[32,0,[\"forkedUser\"]],[32,0,[\"errors\"]],[30,[36,0],null,[[\"undo\"],[[30,[36,1],[[32,0],\"undo\"],null]]]],[30,[36,0],null,[[\"saveUser\"],[[32,0,[\"saveUser\"]]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"action\"]}",
    "moduleName": "balance-ember/components/user/profile-tabs/account-info/form-provider/template.hbs"
  });

  _exports.default = _default;
});