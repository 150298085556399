define("balance-ember/components/manage/bookmark-dropdown/row/reminder/component", ["exports", "@glimmer/component", "balance-ember/models/bookmark-reminder", "validate.js", "moment"], function (_exports, _component, _bookmarkReminder, _validate, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class3, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BookmarkDropdownRowReminderComponent = (_dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class3 = class BookmarkDropdownRowReminderComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "selectedFrequency", _descriptor4, this);

      _initializerDefineProperty(this, "forkedBookmarkReminder", _descriptor5, this);

      _defineProperty(this, "frequencyOptions", [{
        value: 'weekly',
        component: 'weekly',
        name: 'Weekly'
      }, {
        value: 'monthly',
        component: 'monthly',
        name: 'Monthly'
      }, {
        value: 'specific_date',
        component: 'specific-date',
        name: 'Specific Date'
      }]);

      this.updateFrequency(this.initialFrequency);
    }

    get initialFrequency() {
      return this.frequencyOptions.find(option => option.value === this.initialFrequencyValue);
    }

    get initialFrequencyValue() {
      return this.hasReminder ? this.args.bookmark.reminder.reminderFrequency : 'weekly';
    }

    get hasReminder() {
      return this.args.bookmark.reminder;
    }

    get frequencyComponentName() {
      return `manage/bookmark-dropdown/row/reminder/${this.selectedFrequency.component}`;
    }

    save() {
      let reminder = this.args.bookmark.reminder;

      if (!reminder) {
        reminder = this.store.createRecord('bookmarkReminder', this.forkedBookmarkReminder.values);
      } else {
        Ember.setProperties(reminder, this.forkedBookmarkReminder.values);
      }

      Ember.set(reminder, 'bookmark', this.args.bookmark);
      Ember.set(this.args.bookmark, 'reminder', reminder);
      this.args.bookmark.save().then(() => {
        this.args.bookmarkState.reset();
      });
    }

    remove() {
      let reminder = this.args.bookmark.reminder;
      reminder.deleteRecord();
      this.args.bookmark.save().then(() => {
        reminder.unloadRecord();
        this.args.bookmarkState.reset();
      });
    }

    updateFrequency(frequency) {
      this.selectedFrequency = frequency;
      this.forkedBookmarkReminder.reminderFrequency = frequency.value;
    }

    transitionFrequency(frequency) {
      this.updateFrequency(frequency);
      this.forkedBookmarkReminder.reminderValue = null;
    }

  }, (_descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "selectedFrequency", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "forkedBookmarkReminder", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new ForkedBookmarkReminder(this.args.bookmark.reminder);
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "save", [_dec6], Object.getOwnPropertyDescriptor(_class3.prototype, "save"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "remove", [_dec7], Object.getOwnPropertyDescriptor(_class3.prototype, "remove"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "transitionFrequency", [_dec8], Object.getOwnPropertyDescriptor(_class3.prototype, "transitionFrequency"), _class3.prototype)), _class3));
  _exports.default = BookmarkDropdownRowReminderComponent;
  let ForkedBookmarkReminder = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = class ForkedBookmarkReminder {
    constructor(reminder = {}) {
      _initializerDefineProperty(this, "reminderFrequency", _descriptor, this);

      _initializerDefineProperty(this, "reminderValue", _descriptor2, this);

      _defineProperty(this, "constraints", {
        reminderValue: {
          presence: true
        },
        reminderValueParsed: {
          date: {
            earliest: _moment.default.utc()
          } // Now

        }
      });

      this.copySchema(reminder);
    }

    get reminderValueParsed() {
      return _moment.default.utc(this.reminderValue, 'YYYY-MM-DD', true);
    }

    copySchema(reminder = {}) {
      reminder = reminder || {}; // in case null is passed

      Object.keys(_bookmarkReminder.schema).forEach(key => {
        this[key] = reminder[key];
      });
    }

    get isValid() {
      if (this.reminderFrequency !== 'specific_date') {
        return true;
      }

      return !(0, _validate.default)(this, this.constraints);
    }

    get values() {
      return Object.keys(_bookmarkReminder.schema).reduce((acc, key) => {
        acc[key] = this[key];
        return acc;
      }, {});
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "reminderFrequency", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "reminderValue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  })), _class));
});