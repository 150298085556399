define("balance-ember/components/budget/supplemental-pay-type-form/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/refresh-route", "balance-ember/utils/writeable-model", "balance-ember/utils/validators/supplemental-pay-type"], function (_exports, _component, _emberConcurrency, _refreshRoute, _writeableModel, _supplementalPayType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Use this to render a supplementalPay type form
   *
   * @param {object} supplementalPayType
   * @param {object} benefitsPackage
   * @param {object} segmentTypes
   * @param {string} formTitle The text to display as the title of the form
   * @param {string} actionButtonText The text to display on the primary button
   * @param {string} disabledButtonText What to display when the button is disabled
   */
  let SupplementalPayTypeForm = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember.computed.sort('args.benefitsPackages', 'benefitsPackagesSorting'), _dec11 = Ember.computed.readOnly('availableFiscalYears.current'), _dec12 = (0, _emberConcurrency.task)(function* (afterSave) {
    var _this$validator$valid;

    if (this.args.saveSupplementPayType) {
      return yield this.args.saveSupplementPayType.perform();
    }

    this.errors = (_this$validator$valid = this.validator.validate()) === null || _this$validator$valid === void 0 ? void 0 : _this$validator$valid.messages;
    if (!Ember.isEmpty(this.errors)) return false;
    yield this.supplementalPayType.save().then(supplementalPayType => {
      this.modelChanges.addChange({
        id: supplementalPayType.id,
        modelType: 'supplementalPayType',
        changeType: this.isCreating ? 'create' : 'update'
      });

      if (afterSave) {
        afterSave();
      } else {
        this.goToSupplementalPayTypesIndex();
      }
    }).catch(err => {
      if (err.length) {
        let messages = err.mapBy('detail');
        this.errors = this.errors ? this.errors.concat(messages) : messages;
      }
    });
  }).drop(), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class SupplementalPayTypeForm extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor3, this);

      _initializerDefineProperty(this, "network", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor6, this);

      _initializerDefineProperty(this, "supplementalPayType", _descriptor7, this);

      _initializerDefineProperty(this, "isCreating", _descriptor8, this);

      _initializerDefineProperty(this, "errors", _descriptor9, this);

      _defineProperty(this, "benefitsPackagesSorting", ['name']);

      _initializerDefineProperty(this, "sortedBenefitsPackages", _descriptor10, this);

      _initializerDefineProperty(this, "fiscalYear", _descriptor11, this);

      _initializerDefineProperty(this, "saveSupplementPayType", _descriptor12, this);

      this.createWriteableModel(); // If we're have 'save and add another' button we don't want it to disappear inbetween when
      // the model is succesfully saved and we clear out all the fields and start over. That's why
      // we're not doing this in a computed property

      if (this.supplementalPayType.isNew) {
        this.isCreating = true;
      }
    }

    createWriteableModel() {
      this.supplementalPayType = new _writeableModel.default({
        model: this.args.supplementalPayType,
        store: this.store,
        network: this.network
      });
      this.supplementalPayType.fiscalYear = this.fiscalYear;
    }

    goToSupplementalPayTypesIndex() {
      this.router.transitionTo('budgeting.supplemental-pay-types');
    }

    get salarySegmentType() {
      return (this.args.segmentTypes || []).findBy('salarySegment', true);
    }

    get validator() {
      return new _supplementalPayType.default({
        model: this.supplementalPayType,
        intl: this.intl
      });
    }

    cancel() {
      this.goToSupplementalPayTypesIndex();
    }

    chooseBenefitsPackage(benefitsPackage) {
      if (!benefitsPackage) {
        this.supplementalPayType.disassociateBenefitsPackage();
      } else {
        this.supplementalPayType.benefitsPackage = benefitsPackage;
      }
    }

    chooseSegment(segment) {
      if (!segment) {
        this.supplementalPayType.disassociateSegment();
      } else {
        this.supplementalPayType.segment = segment;
      }
    }

    addAnother() {
      this.createWriteableModel();
      (0, _refreshRoute.default)('budgeting.supplemental-pay-types.new', this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "network", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "supplementalPayType", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isCreating", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "sortedBenefitsPackages", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "fiscalYear", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "saveSupplementPayType", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseBenefitsPackage", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "chooseBenefitsPackage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseSegment", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "chooseSegment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addAnother", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "addAnother"), _class.prototype)), _class));
  _exports.default = SupplementalPayTypeForm;
});