define("balance-ember/routes/budgeting/budget-priorities/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BudgetingBudgetPrioritiesEditRoute extends Ember.Route {
    model({
      budget_priority_id: id
    }) {
      return this.store.loadRecord('budget-priority', id);
    }

  }

  _exports.default = BudgetingBudgetPrioritiesEditRoute;
});