define("balance-ember/components/budget/strategies-table/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/types/table", "@microstates/ember"], function (_exports, _component, _emberConcurrency, _table, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetStrategiesTableComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('budgetStrategy', {
      extra_fields: {
        'budget_strategies': 'rollup_totals'
      },
      filter: {
        budget_priority_id: {
          eq: this.args.budgetPriority.id
        },
        budget_id: {
          eq: this.args.budget.id
        }
      },
      sort: 'name'
    });
  }), _dec6 = Ember.computed('modelChanges.changes'), (_class = class BudgetStrategiesTableComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "tableState", _descriptor4, this);

      _initializerDefineProperty(this, "strategiesLoadTask", _descriptor5, this);
    }

    get columns() {
      return [{
        label: 'Strategy',
        valuePath: 'name',
        testSelector: 'name',
        cellComponent: 'budget/strategies-table/strategy-cell',
        sortable: false,
        hideable: false
      }, {
        label: 'FTE',
        valuePath: 'rollupTotals.fte',
        testSelector: 'fte',
        width: '180px',
        align: 'right',
        sortable: false,
        hideable: false
      }, {
        label: 'NON-PSNL. EXPS.',
        valuePath: 'rollupTotals.non_personnel_expense_total',
        testSelector: 'npe',
        cellComponent: 'number-cell',
        width: '180px',
        align: 'right',
        sortable: false,
        hideable: false
      }, {
        label: 'SUPPLEMENTAL',
        valuePath: 'rollupTotals.supplemental_pay_total',
        testSelector: 'npe',
        cellComponent: 'number-cell',
        width: '180px',
        align: 'right',
        sortable: false,
        hideable: false
      }, {
        label: 'TOTAL',
        valuePath: 'rollupTotals.total',
        testSelector: 'total',
        cellComponent: 'number-cell',
        width: '180px',
        align: 'right',
        sortable: false,
        hideable: false
      }, {
        cellComponent: 'light-table/cell-components/table-actions',
        tableActionComponents: ['edit', 'delete'],
        sortable: false,
        hideable: false,
        align: 'right',
        width: '80px',

        buttonText(budgetStrategy) {
          return budgetStrategy.name;
        },

        editTooltipText() {
          return 'Edit Strategy';
        },

        deleteTooltipText() {
          return 'Delete Priority';
        }

      }];
    }

    get strategyChanges() {
      return this.modelChanges.changesForModelType('budgetStrategy');
    }

    edit(budgetStrategy) {
      this.router.transitionTo('budgeting.budget.strategies.edit', budgetStrategy.id);
    }

    delete(budgetStrategy) {
      this.router.transitionTo('budgeting.budget.strategies.destroy', budgetStrategy.id);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tableState", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _ember.Store)((0, _ember.create)(_table.default), nextState => this.tableState = nextState);
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "strategiesLoadTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "strategyChanges", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "strategyChanges"), _class.prototype)), _class));
  _exports.default = BudgetStrategiesTableComponent;
});