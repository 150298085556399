define("balance-ember/helpers/read-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.readPath = readPath;
  _exports.default = void 0;

  // Allows reading a dynamic property from an object, e.g.:
  // {{read-path obj dynamicPropName}}
  // If `dynamicPropName` equals "title", outputs the value of `obj.title`.
  // In Ember 2.0 there is a native helper called `get` that does this, see: http://emberjs.com/blog/2015/06/12/ember-1-13-0-released.html#toc_get-helper
  function readPath([object, path]) {
    return Ember.get(object, path);
  }

  var _default = Ember.Helper.helper(readPath);

  _exports.default = _default;
});