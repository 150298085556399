define("balance-ember/components/light-table/cell-components/table-actions/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LightTableCellComponentsTableActionsComponent extends _component.default {
    get tableActionComponents() {
      if (typeof this.args.column.tableActionComponents === 'function') {
        return this.args.column.tableActionComponents(this.args.row.content);
      }

      return this.args.column.tableActionComponents;
    }

  }

  _exports.default = LightTableCellComponentsTableActionsComponent;
});