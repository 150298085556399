define("balance-ember/templates/manage/accounts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6l51J40r",
    "block": "{\"symbols\":[\"preferences\"],\"statements\":[[8,\"preferences-provider\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"ui-hooks\",[],[[\"@did-insert\"],[[30,[36,0],[[32,0],[32,0,[\"shouldShowChart\",\"set\"]],[32,1,[\"accountsShowChart\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"bg-white\"],[12],[2,\"\\n  \"],[8,\"manage/accounts-table\",[],[[\"@summarizeTableState\",\"@categories\",\"@shouldShowChart\"],[[32,0,[\"summarizeTableState\"]],[32,0,[\"categories\"]],[32,0,[\"shouldShowChart\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/templates/manage/accounts/index.hbs"
  });

  _exports.default = _default;
});