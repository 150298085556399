define("balance-ember/router", ["exports", "balance-ember/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType
  });
  Router.map(function () {
    this.route('login'); // rescue users who visit signin or sign-in instead of login
    // there may be a better way of doing this but it doesn't look
    // like Ember is currently capable of it

    this.route('signin', {
      path: '/sign-in'
    });
    this.route('signin'); // All child routes of `authenticated` will require the user to login. Each top level child
    // needs `resetNamespace` so that the route names and folders don't have to be nested under
    // `authenticated`

    this.route('authenticated', {
      path: '/'
    }, function () {
      this.route('index', {
        path: '/',
        resetNamespace: true
      });
      this.route('manage', {
        resetNamespace: true
      }, function () {
        this.route('overview');
        this.route('accounts', function () {
          this.route('details', {
            path: '/:budget_lea_id/:account_code'
          });
        });
        this.route('transactions');
        this.route('charts');
      });
      this.route('manage-v3', {
        resetNamespace: true
      }, function () {
        this.route('overview');
        this.route('accounts', function () {
          this.route('details', {
            path: '/:budget_lea_id/:account_code'
          });
        });
        this.route('transactions');
        this.route('charts');
      });
      this.route('resource-versions', {
        resetNamespace: true
      });
      this.route('district-admin', {
        resetNamespace: true
      }, function () {
        this.route('users', function () {
          this.route('edit', {
            path: ':user_id/edit'
          });
        });
        this.route('tags');
        this.route('integrations');
      });
      this.route('fiscal-years', {
        path: '/budgeting/fiscal-years',
        resetNamespace: true
      }, function () {
        this.route('new');
      });
      this.route('budgeting', {
        path: '/budgeting/:fiscal_year_id',
        resetNamespace: true
      }, function () {
        this.route('overview', {
          path: '/'
        }, function () {
          this.route('dashboard', {
            path: '/overview'
          });
        });
        this.route('budget', {
          path: '/budget/:budget_id'
        }, function () {
          this.route('approval', {
            path: '/approval/:event_type'
          });
          this.route('strategies', function () {
            this.route('new', {
              path: '/new/:budget_priority_id'
            });
            this.route('edit', {
              path: '/:strategy_id/edit'
            });
            this.route('destroy', {
              path: '/:strategy_id/destroy'
            });
          }), this.route('expenses', function () {
            this.route('view', {
              path: '/:id'
            }, function () {
              this.route('new-non-personnel-expense');
              this.route('edit-non-personnel-expense', {
                path: '/edit-non-personnel-expense/:non_personnel_expense_id'
              });
              this.route('allocations', function () {
                this.route('new', {
                  path: '/new/:id'
                });
                this.route('destroy');
              });
              this.route('allocation', {
                path: '/allocation/:allocation_id'
              }, function () {
                this.route('edit');
              });
            });
          });
          this.route('positions');
          this.route('supplemental-pay-expenses', function () {
            this.route('new');
            this.route('edit', {
              path: '/:pay_expense_id/edit'
            });
            this.route('destroy', {
              path: '/:pay_expense_id/destroy'
            });
          });
          this.route('details', function () {
            this.route('edit');
          });
        });
        this.route('fiscal-year', function () {
          this.route('allocations');
          this.route('budgets', function () {
            this.route('allocations', function () {
              this.route('new', {
                path: '/new/:budget_id'
              });
              this.route('destroy');
            });
            this.route('allocation', {
              path: '/allocation/:allocation_id'
            }, function () {
              this.route('edit');
            });
            this.route('new');
          });
          this.route('positions');
          this.route('reports', function () {
            this.route('view', {
              path: '/:report_name'
            });
          });
          this.route('purchase-orders', function () {
            this.route('view', {
              path: '/:purchase_order_id'
            }, function () {
              this.route('edit');
            });
            this.route('view-report', {
              path: '/:allocation_id/:vendor_id'
            }, function () {
              this.route('new');
            });
          });
        });
        this.route('budget-priorities', function () {
          this.route('new');
          this.route('edit', {
            path: '/:budget_priority_id/edit'
          });
          this.route('destroy', {
            path: '/:budget_priority_id/destroy'
          });
        });
        this.route('benefits-packages', function () {
          this.route('new');
          this.route('edit', {
            path: '/:benefits_package_id/edit'
          });
          this.route('destroy', {
            path: '/:benefits_package_id/destroy'
          });
        });
        this.route('job-types', function () {
          this.route('new');
          this.route('edit', {
            path: '/:job_type_id/edit'
          });
          this.route('destroy', {
            path: '/:job_type_id/destroy'
          });
        });
        this.route('supplemental-pay-types', function () {
          this.route('new');
          this.route('edit', {
            path: '/:pay_type_id/edit'
          });
          this.route('destroy', {
            path: '/:pay_type_id/destroy'
          });
        });
        this.route('employees', function () {
          this.route('new');
          this.route('edit', {
            path: '/:employee_id/edit'
          });
          this.route('destroy', {
            path: '/:employee_id/destroy'
          });
        });
        this.route('district-wide-files');
        this.route('vendors', function () {
          this.route('new');
          this.route('amazon-business');
          this.route('edit', {
            path: '/:vendor_id/edit'
          });
          this.route('destroy', {
            path: '/:vendor_id/destroy'
          });
        });
        this.route('segments', function () {
          this.route('new');
          this.route('edit', {
            path: '/:id/edit'
          });
        });
      });
      this.route('users/profile', {
        path: '/profile/:tab',
        resetNamespace: true
      });
      this.route('bookmark-redirect', {
        path: '/bookmark-redirect/:id',
        resetNamespace: true
      });
      this.route('freestyle', {
        resetNamespace: true
      });
    }); // If you're adding a standalone route that is accessible without being authenticated
    // Make sure to add the route's classname to layout.scss
    // in the publicly accessible page styling section

    this.route('users/forgot-password', {
      path: '/forgot'
    });
    this.route('users/reset-password', {
      path: '/reset'
    });
    this.route('users/confirm-account', {
      path: '/confirm'
    });
    this.route('users/resend-confirmation', {
      path: '/resend-confirmation'
    });
    this.route('users/resend-unlock', {
      path: '/resend-unlock'
    });
    this.route('users/unlock', {
      path: '/unlock'
    });
    this.route('terms-of-use');
    this.route('privacy-policy'); // wildcard path that results in 404 page

    this.route('not-found', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});