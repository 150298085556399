define("balance-ember/utils/validatejs/validators/array", ["exports", "validate.js"], function (_exports, _validate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validatorsArray;

  function validatorsArray(values, valueConstraints, _key, _attributes, globalOptions) {
    let errors = values.reduce((acc, value) => {
      return acc.concat((0, _validate.default)(value, valueConstraints, globalOptions));
    }, []).compact();
    return errors.length ? errors : null;
  }
});