define("balance-ember/components/dropdown-position/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AeCY/Qqo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],[[32,0],\"calculatePosition\"],null],[32,0,[\"caretPosition\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/components/dropdown-position/template.hbs"
  });

  _exports.default = _default;
});