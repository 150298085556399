define("balance-ember/components/radio-highlight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CvMTlIc2",
    "block": "{\"symbols\":[\"@option\",\"@value\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"py-12 px-8\\n  \",[30,[36,0],[[32,0,[\"shouldHighlight\"]],[30,[36,0],[[32,0,[\"errors\",\"length\"]],\"bg-material-red100\",\"bg-material-light-blue100\"],null]],null]]]],[12],[2,\"\\n  \"],[18,3,[[30,[36,1],null,[[\"value\",\"option\",\"isChecked\"],[[32,2],[32,1],[32,0,[\"shouldHighlight\"]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"hash\"]}",
    "moduleName": "balance-ember/components/radio-highlight/template.hbs"
  });

  _exports.default = _default;
});