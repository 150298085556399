define("balance-ember/components/delayed-block/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Use this component to wrap some content that you want to delay showing for a given number
   * of milliseconds
   *
   * @param {number} delay The number of milliseconds to wait before displaying the content
   */
  var _default = Ember.Component.extend({
    classNames: ['delayed-block'],
    classNameBindings: ['isShowing'],
    delay: 100,
    isShowing: false,

    didInsertElement() {
      this._super(...arguments);

      this.showBlock.perform();
    },

    showBlock: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(this.delay);
      Ember.set(this, 'isLoaderShowing', true);
    })
  });

  _exports.default = _default;
});