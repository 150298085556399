define("balance-ember/components/admin-breadcrumbs-provider/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    breadcrumbs: Ember.computed('user.name', function () {
      return [{
        displayName: 'All Users',
        routeName: 'district-admin.users'
      }, {
        displayName: this.user.name,
        routeName: 'district-admin.users.edit'
      }];
    })
  });

  _exports.default = _default;
});