define("balance-ember/services/notifications", ["exports", "ember-cli-notifications/services/notifications"], function (_exports, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _notifications.default.extend({
    a11yAnnouncer: Ember.inject.service(),

    // Extend the imported service
    addNotification(notification) {
      this._super(...arguments); // Screen readers should read any notifications that are sent


      this.a11yAnnouncer.sendMessage(notification.message);
    }

  });

  _exports.default = _default;
});