define("balance-ember/components/user/profile-tabs/manage-permissions/table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    availableBudgetLeas: Ember.inject.service(),
    session: Ember.inject.service(),
    budgetsSort: null,
    sortedBudgetLeas: Ember.computed.sort('availableBudgetLeas.all', 'budgetsSort'),
    tableData: Ember.computed('sortedBudgetLeas', 'user.{managePermissions.[],budgetLeas.[]}', function () {
      return this.sortedBudgetLeas.map(budgetLea => {
        let hasAccess = this.user.budgetLeas.includes(budgetLea);
        let customPermissions = this.user.managePermissions.filterBy('budgetLea.id', budgetLea.id);
        return {
          id: budgetLea.id,
          budgetLea,
          user: this.user,
          hasAccess,
          customPermissions
        };
      });
    }),
    columns: Ember.computed('delete', 'edit', 'session.currentUser.admin', function () {
      let edit = this.edit;
      let deleteAction = this.delete;
      return [{
        label: 'BUDGET',
        cellType: 'allovue-cell',
        testSelector: 'manage-permission-budget-name',
        cellClassNames: 'font-bold align-top',
        valuePath: 'budgetLea.name',
        sortable: false
      }, {
        label: 'PERMISSION',
        cellComponent: 'user/profile-tabs/manage-permissions/table/permissions-cell',
        sortable: false
      }, ...(this.session.currentUser.admin ? [{
        label: 'ACTIONS',
        valuePath: 'budgetLea.name',
        classNames: 'w-128',
        cellClassNames: 'w-128',
        cellComponent: 'light-table/cell-components/actions-dropdown-cell',
        dropdownComponent: 'user/profile-tabs/manage-permissions/table/actions-cell',
        align: 'right',

        buttonText(data) {
          return data.budgetLea.name;
        },

        sortable: false,
        edit,
        delete: deleteAction
      }] : [])];
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'budgetsSort', ['fiscalYear:desc']);
    }

  });

  _exports.default = _default;
});