define("balance-ember/components/layout/stack/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q6EbI7cR",
    "block": "{\"symbols\":[\"@align\",\"@space\",\"&default\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[18,3,[[30,[36,0],[\"layout/stack/item\"],[[\"space\",\"align\"],[[32,2],[32,1]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "moduleName": "balance-ember/components/layout/stack/template.hbs"
  });

  _exports.default = _default;
});