define("balance-ember/components/budget-notes/saving/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L3Etw7XO",
    "block": "{\"symbols\":[],\"statements\":[[8,\"text-view-with-links\",[],[[\"@placeholder\",\"@text\"],[[32,0,[\"notesPlaceholderText\"]],[32,0,[\"budget\",\"notes\"]]]],null],[2,\"\\n\"],[10,\"section\"],[14,0,\"edit-notes-saving notes-actions\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"end-lg\"],[12],[2,\"\\n    Saved!\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/budget-notes/saving/template.hbs"
  });

  _exports.default = _default;
});