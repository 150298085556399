define("balance-ember/components/budget/priority-form/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/refresh-route", "balance-ember/utils/writeable-model", "balance-ember/utils/validators/budget-priority"], function (_exports, _component, _emberConcurrency, _refreshRoute, _writeableModel, _budgetPriority) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Use this to render a budget priority form
   * @param {object} budgetPriority
   * @param {string} formTitle The text to display as the title of the form
   */
  let BudgetPriorityFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed.readOnly('availableFiscalYears.current'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* (afterSave) {
    var _this$validator$valid;

    this.errors = (_this$validator$valid = this.validator.validate()) === null || _this$validator$valid === void 0 ? void 0 : _this$validator$valid.messages;
    if (!Ember.isEmpty(this.errors)) return false;
    yield this.budgetPriority.save().then(budgetPriority => {
      this.modelChanges.addChange({
        id: budgetPriority.id,
        modelType: 'budgetPriority',
        changeType: this.isCreating ? 'create' : 'update'
      });

      if (afterSave) {
        afterSave();
      } else {
        this.goToBudgetPrioritiesIndex();
      }
    });
  }).drop(), _dec12 = Ember._action, _dec13 = Ember._action, (_class = class BudgetPriorityFormComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "network", _descriptor4, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor5, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor6, this);

      _initializerDefineProperty(this, "fiscalYear", _descriptor7, this);

      _initializerDefineProperty(this, "isCreating", _descriptor8, this);

      _initializerDefineProperty(this, "budgetPriority", _descriptor9, this);

      _initializerDefineProperty(this, "errors", _descriptor10, this);

      _initializerDefineProperty(this, "saveBudgetPriority", _descriptor11, this);

      this.createWriteableModel();

      if (this.args.budgetPriority.isNew) {
        this.isCreating = true;
      }
    }

    createWriteableModel() {
      this.budgetPriority = new _writeableModel.default({
        model: this.args.budgetPriority,
        store: this.store,
        network: this.network
      });
      this.budgetPriority.fiscalYear = this.fiscalYear;
    }

    goToBudgetPrioritiesIndex() {
      this.router.transitionTo('budgeting.budget-priorities');
    }

    get validator() {
      return new _budgetPriority.default({
        model: this.budgetPriority,
        intl: this.intl
      });
    }

    cancel() {
      this.goToBudgetPrioritiesIndex();
    }

    addAnother() {
      this.createWriteableModel();
      (0, _refreshRoute.default)('budgeting.budget-priorities.new', this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "network", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fiscalYear", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isCreating", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "budgetPriority", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "saveBudgetPriority", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addAnother", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "addAnother"), _class.prototype)), _class));
  _exports.default = BudgetPriorityFormComponent;
});