define("balance-ember/controllers/budgeting/employees", ["exports", "balance-ember/utils/urls", "balance-ember/mixins/table-controller"], function (_exports, _urls, _tableController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EMPLOYEE_PAGE_SIZE = void 0;
  const EMPLOYEE_PAGE_SIZE = 50;
  _exports.EMPLOYEE_PAGE_SIZE = EMPLOYEE_PAGE_SIZE;

  var _default = Ember.Controller.extend(_tableController.default, {
    router: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    modelChanges: Ember.inject.service(),

    /**
     * Set in router
     */
    statusOptions: null,

    init() {
      this._super(...arguments);

      this.tableState.pagination.itemsPerPage.set(EMPLOYEE_PAGE_SIZE);
      this.tableState.defaultSort.set({
        column: 'last_name',
        dir: 'asc'
      });
    },

    employeesBulkUrl: Ember.computed('availableFiscalYears.current.id', function () {
      let fiscalYearId = this.availableFiscalYears.current.id;
      return `${(0, _urls.URLForBudgetNamespace)()}/fiscal_years/${fiscalYearId}/employees/bulk_upload`;
    }),
    employeeChanges: Ember.computed('modelChanges.changes.[]', function () {
      return this.modelChanges.changesForModelType('employee');
    }),
    categories: Ember.computed('statusOptions.[]', function () {
      return [{
        title: 'Employee Name',
        value: 'name',
        type: 'text'
      }, {
        title: 'Code',
        value: 'code',
        type: 'text'
      }, {
        title: 'FTE',
        value: 'fte',
        type: 'decimal'
      }, {
        title: 'Status',
        value: 'employee_status_id',
        type: 'select',
        filterOptions: {
          options: this.statusOptions,
          valuePath: 'id',
          labelPath: 'statusName'
        }
      }, {
        title: 'Actual Salary',
        value: 'actual_salary',
        type: 'currency'
      }, {
        title: 'Budgeted Salary',
        value: 'budgeted_salary',
        type: 'currency'
      }];
    }),
    actions: {
      afterUploadSuccess() {
        this.modelChanges.addChange({
          modelType: 'employee',
          changeType: 'create'
        });
      },

      edit(id) {
        this.router.transitionTo('budgeting.employees.edit', id);
      },

      delete(id) {
        this.router.transitionTo('budgeting.employees.destroy', id);
      }

    }
  });

  _exports.default = _default;
});