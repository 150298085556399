define("balance-ember/templates/fiscal-years/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jBRotfBC",
    "block": "{\"symbols\":[],\"statements\":[[8,\"fiscal-year-form\",[],[[\"@formTitle\",\"@disabledButtonText\",\"@actionButtonText\"],[\"Create A New Budget Fiscal Year\",\"Creating\",\"Create Fiscal Year\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/fiscal-years/new.hbs"
  });

  _exports.default = _default;
});