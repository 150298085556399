define("balance-ember/components/plus-icon-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cy6B1hti",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n  \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"circle-plus\",\"svg-icon stroke-current\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/plus-icon-cell/template.hbs"
  });

  _exports.default = _default;
});