define("balance-ember/components/fiscal-year-form/chart-of-accounts/upload-new/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency", "balance-ember/utils/urls", "balance-ember/classes/bulk-upload-error", "balance-ember/utils/morph-keys"], function (_exports, _component, _emberConcurrency, _urls, _bulkUploadError, _morphKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    liveChat: Ember.inject.service(),

    init() {
      this._super(...arguments);

      Ember.set(this, 'segmentTypes', []);
      this.uploadErrors = [];
    },

    segmentBulkUploadURL: `${(0, _urls.URLForBudgetNamespace)()}/fiscal_years/draft/segments/bulk_upload`,
    uploadChartOfAccounts: (0, _emberConcurrency.task)(function* (file) {
      Ember.set(this, 'uploadErrors', []);
      Ember.set(this, 'segmentTypes', []);

      try {
        yield file.upload(this.segmentBulkUploadURL, {
          headers: this.ajax.headers
        }).then(response => {
          let normalizedResponse = this.normalizeResponse(response.body);
          let fiscalYear = this.updateFiscalYear(normalizedResponse);
          Ember.set(this, 'segmentTypes', fiscalYear.segmentTypes);
          Ember.set(this, 'lastUploadedFile', file);
        });
      } catch (e) {
        this.handleUploadErrors(e);
      }
    }).drop(),

    normalizeResponse(response) {
      let dasherizedResponse = (0, _morphKeys.dasherizeKeys)(response);
      let normalizedResponse = {
        data: {
          id: response.id,
          type: 'fiscal-years',
          attributes: dasherizedResponse,
          relationships: {
            'segment-types': {
              data: dasherizedResponse['segment-types'].map(segmentType => {
                return {
                  id: segmentType.id,
                  type: 'segment-types'
                };
              })
            }
          }
        },
        included: dasherizedResponse['segment-types'].map(segmentType => {
          return {
            id: segmentType.id,
            type: 'segment-types',
            attributes: segmentType
          };
        })
      };
      return normalizedResponse;
    },

    disableUpload: Ember.computed.or('prerequisitesAreInvalid', 'uploadChartOfAccounts.isRunning'),
    prerequisitesAreInvalid: Ember.computed.equal('prerequisitesValid', false),
    showSalarySegmentValidation: Ember.computed('salarySegment', 'validate', function () {
      return this.validate && !this.salarySegment;
    }),
    showUploadValidation: Ember.computed('segmentTypes', 'segmentTypesValid', 'validate', function () {
      return this.validate && !this.segmentTypesValid;
    }),
    segmentTypesValid: Ember.computed('segmentTypes.length', function () {
      return this.segmentTypes.length !== 0;
    }),

    handleUploadErrors(e) {
      if (e.body.errors) {
        Ember.set(this, 'uploadErrors', _bulkUploadError.default.build(e.body.errors));
      } else {
        Ember.set(this, 'uploadErrors', _bulkUploadError.default.buildException(e));
      }
    },

    errors: Ember.computed('showSalarySegmentValidation', 'showUploadValidation', function () {
      let errors = [];

      if (this.showUploadValidation) {
        errors.push('Upload a chart of accounts');
      }

      if (this.showSalarySegmentValidation) {
        errors.push('Salary Segment must be selected');
      }

      return errors;
    }),
    actions: {
      uploadChartOfAccounts(file) {
        this.uploadChartOfAccounts.perform(file);
      },

      updatePersonnelSegment(personnelSegmentType) {
        if (personnelSegmentType && personnelSegmentType.id) {
          this.updateSalarySegment(personnelSegmentType);
          Ember.set(personnelSegmentType, 'salarySegment', true);
          personnelSegmentType.save();
        }
      },

      downloadTemplate() {
        window.location.href = `${this.segmentBulkUploadURL}${this.ajax.getAuthString()}`;
      },

      showChat() {
        this.liveChat.open();
      }

    }
  });

  _exports.default = _default;
});