define("balance-ember/models/non-personnel-expenses/item", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.computedProperties = _exports.schema = void 0;
  const schema = {
    unitPrice: (0, _model.attr)('number', {
      defaultValue: 0.0
    }),
    quantity: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    amount: (0, _model.attr)('number', {
      defaultValue: 0.0,
      readOnly: true
    }),
    description: (0, _model.attr)('string'),
    nonPersonnelExpenseId: (0, _model.attr)('number'),
    nonPersonnelExpense: (0, _model.belongsTo)('nonPersonnelExpense', {
      async: false
    })
  };
  _exports.schema = schema;
  const computedProperties = {
    non_personnel_expense_id: Ember.computed.alias('nonPersonnelExpenseId')
  };
  _exports.computedProperties = computedProperties;

  var _default = _model.default.extend(schema, computedProperties);

  _exports.default = _default;
});