define("balance-ember/components/login-flow-layout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/97u/uZ/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"user-form-container\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"notLoginPage\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/assets/images/logo/logo_stacked.png\"],[14,0,\"vertical-stacked-logo\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"img\"],[14,\"src\",\"/assets/images/logo/logo_stacked.png\"],[14,0,\"vertical-stacked-logo\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[6,[37,0],[[32,0,[\"title\"]]],null,[[\"default\"],[{\"statements\":[[10,\"h2\"],[14,0,\"mb-16 text-center text-2xl font-bold\"],[12],[1,[32,0,[\"title\"]]],[13]],\"parameters\":[]}]]],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"showCancelLink\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,0,\"text-center text-blue500 block\"]],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Cancel\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/login-flow-layout/template.hbs"
  });

  _exports.default = _default;
});