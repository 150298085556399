define("balance-ember/components/budget-approval-modal-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qRWM4Vlt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],[[32,0],\"cancel\"],null],[30,[36,0],[[32,0],\"sendEvent\"],null]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/components/budget-approval-modal-actions/template.hbs"
  });

  _exports.default = _default;
});