define("balance-ember/modifiers/dropdown-hover-out", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)((element, [dropdown]) => {
    let onMouseLeave = () => {
      if (dropdown.isOpen) {
        dropdown.actions.close();
        document.activeElement.blur();
      }
    };

    element.addEventListener('mouseleave', onMouseLeave);
    return () => {
      element.removeEventListener('mouseleave', onMouseLeave);
    };
  });

  _exports.default = _default;
});