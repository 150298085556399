define("balance-ember/components/budgeting-link/non-architect/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E+F8FksA",
    "block": "{\"symbols\":[\"StackItem\",\"@fiscalYearId\",\"@isPurchasingApprover\"],\"statements\":[[8,\"layout/stack\",[],[[\"@space\"],[\"m\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"budgeting-link/item\",[],[[\"@route\",\"@testId\"],[[30,[36,0],[\"fiscal-years\"],null],\"nav-fiscal-years\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Fiscal Years\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"budgeting-link/item\",[],[[\"@route\",\"@testId\"],[[30,[36,0],[\"budgeting.fiscal-year.budgets\",[32,2]],null],\"nav-budgets\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Budgets\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"budgeting-link/item\",[],[[\"@route\",\"@testId\"],[[30,[36,0],[\"budgeting.fiscal-year.purchase-orders\",[32,2]],null],\"nav-purchase-orders\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Purchase Orders\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"array\",\"if\"]}",
    "moduleName": "balance-ember/components/budgeting-link/non-architect/template.hbs"
  });

  _exports.default = _default;
});