define("balance-ember/utils/refresh-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = refreshRoute;

  /**
   * refreshRoute - Use this to refresh a route when you are not in a route handler
   *
   * @param  {string} routeName The name of the route
   * @param  {object} context   The `this` variable of the object you are in
   */
  function refreshRoute(routeName, context) {
    Ember.getOwner(context).lookup(`route:${routeName}`).refresh();
  }
});