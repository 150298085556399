define("balance-ember/components/district-admin/integrations/amazon-business-button/disconnect/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t5iGLPhs",
    "block": "{\"symbols\":[\"InlineItem\",\"InlineItem\"],\"statements\":[[8,\"layout/inline\",[],[[\"@space\",\"@align\",\"@alignX\"],[\"s\",\"center\",\"right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-material-green600\"],[12],[2,\"\\n      \"],[8,\"layout/inline\",[],[[\"@space\",\"@align\"],[\"xs\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"budget-completed\",\"h-16 w-16\"]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"div\"],[12],[2,\"Connected\"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"button\",[],[[\"@style\",\"@testId\",\"@onclick\"],[\"btn-danger\",\"disconnect-button\",[30,[36,0],[[32,0],\"showModal\",true],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Disconnect\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"showModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"modal/destroy\",[],[[\"@closeModal\",\"@destroy\",\"@text\"],[[30,[36,0],[[32,0],\"showModal\",false],null],[30,[36,1],[[32,0,[\"amazonBusiness\",\"disconnect\"]],[30,[36,0],[[32,0],\"showModal\",false],null]],null],[30,[36,2],[\"district_admin.integrations.amazon_disconnect_warning\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"set\",\"fn\",\"t\",\"if\"]}",
    "moduleName": "balance-ember/components/district-admin/integrations/amazon-business-button/disconnect/template.hbs"
  });

  _exports.default = _default;
});