define("balance-ember/components/manage/summarize/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xAF6e7Fv",
    "block": "{\"symbols\":[\"@columns\",\"@extra\",\"@table\",\"@sortIcons\",\"@firstColumnPadding\",\"@rowLabel\",\"&default\"],\"statements\":[[8,\"light-table/default-head-columns\",[],[[\"@columns\",\"@extra\",\"@table\",\"@sortIcons\"],[[32,1],[32,2],[32,3],[32,4]]],null],[2,\"\\n\\n\"],[10,\"tr\"],[14,0,\"bg-material-indigo100 text-material-blue-gray800\"],[12],[2,\"\\n  \"],[10,\"th\"],[15,0,[30,[36,0],[\"text-left py-16 \",[32,5]],null]],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"flex items-center justify-between\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-material-blue-gray400 mr-16 relative h-16\"],[12],[2,\"\\n          \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"chevron-circle\",[30,[36,1],[[32,0,[\"loadTask\",\"isRunning\"]],\"rotate-270\"],null]]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"loadTask\",\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"border-l-2 border-material-blue-gray100 absolute left-6\"],[12],[2,\"\\n               \\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"leading-none\"],[12],[2,\"\\n          \"],[1,[32,6]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[8,\"manage/summarize/button\",[],[[\"@categories\",\"@tableState\",\"@summarizeState\",\"@onSummarize\",\"@onClear\"],[[32,0,[\"summarizeCategories\"]],[32,0,[\"tableState\"]],[32,0,[\"summarizeState\"]],[32,0,[\"onSummarize\"]],[32,0,[\"onClearSummarize\"]]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[18,7,[[30,[36,4],null,[[\"cell\"],[[30,[36,3],[\"manage/summarize/header/cell\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\",\"unless\",\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/manage/summarize/header/template.hbs"
  });

  _exports.default = _default;
});