define("balance-ember/components/light-table/cell-components/table-actions/delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wO+64DjV",
    "block": "{\"symbols\":[\"@class\",\"@row\",\"@tableActions\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"flex items-center \",[32,1]]]],[12],[2,\"\\n  \"],[8,\"delete-button\",[],[[\"@click\",\"@buttonText\",\"@tooltipText\",\"@testSelectorValue\",\"@disabled\"],[[30,[36,1],[[30,[36,0],[[32,3,[\"delete\"]],[32,2,[\"content\"]]],null]],null],[32,0,[\"buttonText\"]],[32,0,[\"deleteTooltipText\"]],[32,2,[\"content\",\"id\"]],[32,0,[\"isDeleteDisabled\"]]]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"isDeleteDisabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tooltip\",[],[[\"@text\"],[[32,0,[\"deleteTooltipText\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"stop-propagation\",\"if\"]}",
    "moduleName": "balance-ember/components/light-table/cell-components/table-actions/delete/template.hbs"
  });

  _exports.default = _default;
});