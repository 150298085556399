define("balance-ember/components/filter-radio/field/component", ["exports", "balance-ember/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);

      this.focusFirstInput(this.element);
    },

    focusFirstInput(element) {
      let inputs = element.querySelectorAll('input'); // There is a bug in Inputmask 5.0.1 where events like 'focus' still linger even after an
      // input has been destroyed. Inputmask isn't expecting this and doens't defend against
      // the inputmask object not existing and errors are thrown. Only been a problem in tests
      // where elements are viewed and destroyed so quickly that the events aren't cleaned up
      // quickly enough
      // Issue is somewhat similar to https://github.com/RobinHerbots/Inputmask/issues/1485

      if (inputs.length && _environment.default.environment !== 'test') {
        inputs[0].focus();
      }
    }

  });

  _exports.default = _default;
});