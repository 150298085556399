define("balance-ember/components/layout/inline/item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class LayoutInlineItemComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "spaceToPx", {
        'xxs': 4,
        'xs': 8,
        's': 12,
        'm': 20,
        'l': 32,
        'xl': 48,
        'xxl': 96
      });
    }

    get space() {
      if (this.args.space) {
        (false && !(Object.keys(this.spaceToPx).includes(this.args.space)) && Ember.assert(`${this.args.space} is not a valid space option`, Object.keys(this.spaceToPx).includes(this.args.space)));
      }

      return this.spaceToPx[this.args.space] || '0';
    }

  }

  _exports.default = LayoutInlineItemComponent;
});