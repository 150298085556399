define("balance-ember/components/manage/account-balance-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A4HgdIib",
    "block": "{\"symbols\":[\"isNegative\",\"negativeClass\",\"shouldHighlightNegative\",\"@value\",\"@column\"],\"statements\":[[10,\"div\"],[14,0,\"print:flex print:flex-row-reverse\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"print:w-320 print:pr-32 print:text-left\"],[12],[2,\"\\n    \"],[8,\"negative-amount\",[],[[\"@value\"],[[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,5,[\"highlightNegative\"]],[32,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[30,[36,0],[[32,3],[32,2],\"text-material-gray700\"],null]],[12],[2,\"\\n          \"],[1,[30,[36,1],[[32,4]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[[32,3],[32,2],\"text-material-gray500\"],null],\" print:h-auto h-6\"]]],[12],[2,\"\\n          \"],[1,[32,0,[\"percentage\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"    \"]],\"parameters\":[1,2]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"text-material-gray500 hidden print:block print:w-128 print:pr-8 print:text-right print:block\"],[12],[2,\"\\n\\t\\t\"],[10,\"strong\"],[12],[1,[32,5,[\"label\"]]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"numeral-formatter\",\"and\",\"let\"]}",
    "moduleName": "balance-ember/components/manage/account-balance-cell/template.hbs"
  });

  _exports.default = _default;
});