define("balance-ember/services/downloads", ["exports", "ember-concurrency", "ember-window-mock", "balance-ember/config/environment", "balance-ember/utils/urls"], function (_exports, _emberConcurrency, _emberWindowMock, _environment, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    currentlyProcessing: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'currentlyProcessing', []);
    },

    getCurrentTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('manage-export');
    }).drop(),

    getInProgressFiles() {
      this.getCurrentTask.perform().then(() => {
        this.inProgress.forEach(file => this.addInProgessFile(file.id));
      });
    },

    errorMessage: '<div>Your file failed to process. <br> <span class="text-sm">Try downloading again and contact us if it continues to fail.</span></div>',
    refreshFilesTask: (0, _emberConcurrency.task)(function* () {
      while (this.currentlyProcessing.length) {
        yield this.store.findAll('manage-export', {
          reload: true
        });
        let recentlyCompleted = this.completed.filter(file => this.currentlyProcessing.includes(file.id));
        recentlyCompleted.forEach(file => {
          this.downloadFile(file.id, 'Your file is ready and is downloading now!');
          this.currentlyProcessing.removeObject(file.id);
        });
        let recentlyFailed = this.failed.filter(file => this.currentlyProcessing.includes(file.id));
        recentlyFailed.forEach(failedFile => {
          this.notifications.error(this.errorMessage, {
            htmlContent: true
          });
          this.currentlyProcessing.removeObject(failedFile.id);
        }); // In the test environment we can't make this infinite loop otherwise our tests will never
        // finish and just timeout. There is unfortunately not a better method to handle this at
        // this time :( https://embermap.com/topics/ember-concurrency/testing

        if (_environment.default.environment === 'test') {
          return;
        }

        yield (0, _emberConcurrency.timeout)(5000);
      }
    }).drop(),
    // Download File Organization
    // --------------------------
    filesSortDirection: Ember.computed(function () {
      return ['createdAt:desc'];
    }),
    // Omit hidden downloads from view
    // Note: getCurrentTask.lastSuccessful is an ember-concurrency method and doesn't mean the last successful download
    allFiles: Ember.computed.filterBy('getCurrentTask.lastSuccessful.value', 'hidden', false),
    inProgress: Ember.computed.filterBy('allFiles', 'inProgress', true),
    inProgressSorted: Ember.computed.sort('inProgress', 'filesSortDirection'),
    failed: Ember.computed.filterBy('allFiles', 'possiblyFailed', true),
    failedSorted: Ember.computed.sort('failed', 'filesSortDirection'),
    completed: Ember.computed.filterBy('allFiles', 'completed', true),
    completedSorted: Ember.computed.sort('completed', 'filesSortDirection'),

    // Actions
    // --------------------------
    addInProgessFile(reportId) {
      this.currentlyProcessing.push(reportId);
      this.refreshFilesTask.perform();
    },

    downloadFile(exportedFileId, notificationText) {
      if (notificationText) {
        this.notifications.success(notificationText);
      } // TODO: If two or more downloads complete within moments of each other, they
      // will clobber window.locations changes that come before them, so only the last
      // file gets downloaded.
      // Possible helpful search phrases are 'download multiple files window.location'
      // There's also this method that uses iframes: https://stackoverflow.com/questions/18453595/how-can-i-use-window-location-href-to-download-multiple-files


      let authString = this.ajax.getAuthString(); // Get the file's data directly from the server so all browsers treat it as a download and don't
      // try and preview it in a tab

      _emberWindowMock.default.location.href = `${(0, _urls.URLForAPIv3)()}/manage_exports/${exportedFileId}${authString}`;
    }

  });

  _exports.default = _default;
});