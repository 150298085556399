define("balance-ember/components/budget/purchase-orders/table/component", ["exports", "balance-ember/utils/urls", "@microstates/ember", "ember-concurrency", "balance-ember/types/table", "balance-ember/utils/morph-keys", "balance-ember/utils/query-params", "balance-ember/classes/table-serializer"], function (_exports, _urls, _ember, _emberConcurrency, _table, _morphKeys, _queryParams, _tableSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    network: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    reportName: 'purchase-orders',
    defaultSortColumn: 'budget_name',
    tableState: (0, _ember.state)((0, _ember.create)(_table.default, {
      pagination: {
        itemsPerPage: 50
      }
    })),
    columns: Ember.computed(function () {
      return [{
        label: this.intl.t('budget.po.headers.budget_name'),
        valuePath: 'budgetName',
        sortName: 'budget_name',
        sortable: true,
        testSelector: 'po-budget_name',
        cellComponent: 'overflowing-table-cell'
      }, {
        label: this.intl.t('budget.po.headers.allocation'),
        valuePath: 'allocationName',
        sortName: 'allocation_name',
        sortable: true,
        testSelector: 'po-allocation'
      }, {
        label: this.intl.t('budget.po.headers.vendor'),
        valuePath: 'vendorName',
        sortName: 'vendor_name',
        sortable: true,
        testSelector: 'po-vendor'
      }, {
        label: this.intl.t('budget.po.headers.po_number'),
        valuePath: 'poNumber',
        sortName: 'po_number',
        sortable: true,
        cellComponent: 'overflowing-table-cell',
        testSelector: 'po-number'
      }, {
        label: this.intl.t('budget.po.headers.status'),
        valuePath: 'active',
        sortName: 'active',
        sortable: true,
        cellComponent: 'budget/purchase-orders/table/status-cell',
        testSelector: 'po-status',
        width: '80px'
      }, {
        label: this.intl.t('budget.po.headers.action'),
        valuePath: 'poId',
        cellComponent: 'budget/purchase-orders/table/action-cell',
        testSelector: 'po-action',
        sortable: false,
        width: '120px'
      }, {
        label: this.intl.t('budget.po.headers.amount'),
        valuePath: 'amount',
        sortName: 'amount',
        sortable: true,
        testSelector: 'po-amount',
        cellComponent: 'number-cell',
        width: '150px'
      }];
    }),
    categories: Ember.computed(function () {
      return [{
        title: this.intl.t('budget.po.headers.budget_name'),
        value: 'budget_name',
        type: 'text'
      }, {
        title: this.intl.t('budget.po.headers.allocation'),
        value: 'allocation_name',
        type: 'text'
      }, {
        title: this.intl.t('budget.po.headers.amount'),
        value: 'amount',
        type: 'decimal'
      }, {
        title: this.intl.t('budget.po.headers.po_number'),
        value: 'po_number',
        type: 'text'
      }];
    }),
    serializedTableState: Ember.computed('tableState', function () {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }),
    loadReport: (0, _emberConcurrency.task)(function* () {
      let params = { ...this.serializedTableState,
        filter: {
          viewable: true,
          fiscal_year_id: this.availableFiscalYears.current.id,
          ...this.serializedTableState.filter
        },
        stats: {
          total: 'count'
        }
      };
      let json = yield this.network.fetch(`${(0, _urls.URLForAPIv3)()}/purchase_orders_reports?${_queryParams.default.stringify(params)}`);
      let meta = json.meta;
      let data = json.data.map(row => (0, _morphKeys.camelizeKeys)(row.attributes));
      data.meta = meta;
      return data;
    }).drop()
  });

  _exports.default = _default;
});