define("balance-ember/components/dropdown-position/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Use this component when you need a dropdown to automatically detect whether to be above or
   * below the trigger when `renderInPlace=true`
   *
   * @return {function} calculatePosition Pass this action to the `calculatePosition` argument of
   * ember-basic-dropdown
   * @return {string} caretPosition Use this as a css class to determine the caret position
   */
  var _default = _component.default.extend({
    caretPosition: 'caret-top-center',
    marginInPixels: 20,
    actions: {
      /**
       * So unfortunately we need to calculate the position of this ourselves. We had a problem
       * where the dropdown content wasn't visible at the bottom of the screen because it would
       * go below by default. Because we have `renderInPlace=true` the `verticalPosition=auto`
       * does not work (http://ember-basic-dropdown.com/docs/position). However, if we do without
       * renderInPlace=true it's not accessible to keyboard/screen reader users :(
       * @see http://ember-basic-dropdown.com/docs/custom-position
       */
      calculatePosition(trigger, content) {
        let {
          bottom,
          width,
          height
        } = trigger.getBoundingClientRect();
        let {
          height: contentHeight,
          width: contentWidth
        } = content.getBoundingClientRect();
        let windowHeight = window.innerHeight;
        let shouldGoUp = windowHeight - contentHeight < bottom;
        let topPosition = shouldGoUp ? -(contentHeight + this.marginInPixels) : height + this.marginInPixels;
        let style = {
          // Center it horizontally all the time
          left: (width - contentWidth) / 2,
          top: topPosition
        };

        if (shouldGoUp) {
          Ember.set(this, 'caretPosition', 'caret-bottom-center');
        } else {
          Ember.set(this, 'caretPosition', 'caret-top-center');
        }

        return {
          style
        };
      }

    }
  });

  _exports.default = _default;
});