define("balance-ember/components/manage/bookmark-dropdown/group/component", ["exports", "@microstates/ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: '',
    bookmarksVisible: (0, _ember.state)((0, _ember.create)(Boolean, true)),
    noBookmarksText: Ember.computed('title', function () {
      if (this.title === 'My Bookmarks') {
        return 'No Personal Bookmarks Yet';
      }

      return 'No District Bookmarks Yet';
    }),
    toggleText: Ember.computed('bookmarksVisible.state', function () {
      return this.bookmarksVisible.state ? 'Hide' : 'Show';
    })
  });

  _exports.default = _default;
});