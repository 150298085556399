define("balance-ember/components/budget/budgets/destroy-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "al7BLMN/",
    "block": "{\"symbols\":[\"@budget\"],\"statements\":[[8,\"modal/destroy\",[],[[\"@closeModal\",\"@destroy\",\"@model\",\"@errors\",\"@text\"],[[32,0,[\"cancelBudget\"]],[32,0,[\"deleteBudget\"]],[32,1],[32,0,[\"errors\"]],[30,[36,0],[\"global.modals.are_you_sure\"],[[\"modelName\"],[[32,1,[\"name\"]]]]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "balance-ember/components/budget/budgets/destroy-modal/template.hbs"
  });

  _exports.default = _default;
});