define("balance-ember/components/positions-table/form/nav/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PositionsTableExpandedRowEditNavComponent = (_dec = Ember.computed('args.stateMachine.status'), _dec2 = Ember.computed('args.stateMachine.expenses', 'position.positionExpenses.@each.percentage'), (_class = class PositionsTableExpandedRowEditNavComponent extends _component.default {
    get step() {
      if (this.status === 'funding') {
        return 1;
      }

      if (this.status === 'review') {
        return 3;
      }

      return 2;
    }

    get title() {
      if (this.status === 'funding') {
        return 'budget.position.funding_plan';
      }

      if (this.status === 'review') {
        return 'budget.position.review_and_notes';
      }

      return 'budget.position.allocation_details';
    }

    get status() {
      return this.args.stateMachine.status;
    }

    get position() {
      return this.args.stateMachine.position;
    }

    get expenses() {
      return this.args.stateMachine.expenses;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "status", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "status"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "expenses", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "expenses"), _class.prototype)), _class));
  _exports.default = PositionsTableExpandedRowEditNavComponent;
});