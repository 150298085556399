define("balance-ember/components/resource-versions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mQ2t8uXb",
    "block": "{\"symbols\":[],\"statements\":[[8,\"table-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"table\",[],[[\"@data\",\"@columns\"],[[32,0,[\"versions\"]],[32,0,[\"columns\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/resource-versions/template.hbs"
  });

  _exports.default = _default;
});