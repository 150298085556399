define("balance-ember/routes/budgeting/budget/strategies", ["exports", "balance-ember/mixins/route-history"], function (_exports, _routeHistory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeHistory.default, {
    availableFiscalYears: Ember.inject.service(),
    currentFiscalYear: Ember.computed.readOnly('availableFiscalYears.current'),

    model() {
      return this.store.loadRecords('budgetPriority', {
        extra_fields: {
          'budget_priorities': 'rollup_totals'
        },
        filter: {
          budget_id: {
            eq: this.modelFor('budgeting.budget').id
          },
          fiscal_year_id: {
            eq: this.currentFiscalYear.id
          }
        },
        paginate: false
      });
    },

    setupController(controller, model) {
      model.set('budget', this.modelFor('budgeting.budget'));

      this._super(controller, model);
    }

  });

  _exports.default = _default;
});