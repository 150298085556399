define("balance-ember/templates/manage/accounts/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XYVO1G4U",
    "block": "{\"symbols\":[],\"statements\":[[8,\"manage/account-details\",[],[[\"@accountCode\",\"@budgetLeaId\"],[[32,0,[\"model\",\"account_code\"]],[32,0,[\"model\",\"budget_lea_id\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/manage/accounts/details.hbs"
  });

  _exports.default = _default;
});