define("balance-ember/components/positions-table/columns-provider/employee-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QQmAf3PA",
    "block": "{\"symbols\":[\"@row\",\"@column\"],\"statements\":[[8,\"overflowing-text\",[],[[\"@text\",\"@height\"],[[30,[36,1],[[32,1,[\"content\",\"employee\"]],[30,[36,0],[[32,1,[\"content\",\"employee\"]]],null],\"Vacant\"],null],[32,2,[\"height\"]]]],null]],\"hasEval\":false,\"upvars\":[\"coded-name\",\"if\"]}",
    "moduleName": "balance-ember/components/positions-table/columns-provider/employee-cell/template.hbs"
  });

  _exports.default = _default;
});