define("balance-ember/components/light-table/rows/allovue-row/component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    init() {
      this._super(...arguments);

      let attributeBindings = this.attributeBindings;
      let classNameBindings = [...this.classNameBindings];
      let testSelector = this.testSelector;
      Ember.set(this, 'attributeBindings', attributeBindings.concat([`testSelectorValue:data-test-${testSelector}`, 'testId:data-test-id']));
      this.removeDefaultExpandClassNameBindings(classNameBindings);
      Ember.set(this, 'classNameBindings', classNameBindings.concat(['customCanExpand:is-expandable', 'customIsExpanded:is-expanded']));
    },

    removeDefaultExpandClassNameBindings(classNameBindings) {
      let canExpandIndex = Number(classNameBindings.indexOf('canExpand:is-expandable'));
      let isExpandedIndex = Number(classNameBindings.indexOf('isExpanded'));
      classNameBindings.splice(canExpandIndex, 1);
      classNameBindings.splice(isExpandedIndex, 1);
    },

    testSelectorValue: Ember.computed.readOnly('row.content.id'),
    testId: Ember.computed('row.content.id', 'testSelector', function () {
      return `${this.testSelector}-${this.row.content.id}`;
    }),
    customCanExpand: Ember.computed('canExpand', 'row.content.canExpand', function () {
      if (this.row.content.canExpand === false) return false;
      return this.canExpand || this.row.content.canExpand;
    }),
    customIsExpanded: Ember.computed('isExpanded', 'row.content.canExpand', function () {
      if (this.row.content.canExpand === false) return false;
      return this.isExpanded;
    })
  });

  _exports.default = _default;
});