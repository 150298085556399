define("balance-ember/services/filter-box-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * Whether or not the filter box is visible
     */
    isOpen: false,

    toggleIsOpen() {
      this.toggleProperty('isOpen');
    }

  });

  _exports.default = _default;
});