define("balance-ember/components/positions-table/form/position-details/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PositionsTableExpandedRowEditPositionDetailsComponent extends _component.default {
    get error() {
      return this.errors.findBy('attribute', 'fte');
    }

    get errors() {
      var _this$args$errors;

      return ((_this$args$errors = this.args.errors) === null || _this$args$errors === void 0 ? void 0 : _this$args$errors.all) ?? [];
    }

  }

  _exports.default = PositionsTableExpandedRowEditPositionDetailsComponent;
});