define("balance-ember/routes/budgeting/budget/destroy-expense", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    queryParams: {
      benefitType: {
        replace: true
      }
    },

    model(params) {
      return this.store.findRecord(params.benefitType, params.expense_id);
    }

  });

  _exports.default = _default;
});