define("balance-ember/helpers/format-employee-name-with-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatEmployeeName = formatEmployeeName;
  _exports.formatEmployeeNameWithId = formatEmployeeNameWithId;
  _exports.default = void 0;

  function formatEmployeeName(name, code) {
    return Ember.isPresent(code) ? `${name} - ${code}` : name;
  }

  function formatEmployeeNameWithId([employee]
  /*, hash*/
  ) {
    if (Ember.isBlank(employee)) {
      return '';
    }

    return formatEmployeeName(employee.name, employee.code);
  }

  var _default = Ember.Helper.helper(formatEmployeeNameWithId);

  _exports.default = _default;
});