define("balance-ember/utils/key-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ESC = _exports.ENTER = void 0;
  const ENTER = 13;
  _exports.ENTER = ENTER;
  const ESC = 27;
  _exports.ESC = ESC;
});