define("balance-ember/components/manage/supplement-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OhOCWD+x",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"print:flex print:flex-row-reverse\"],[12],[2,\"\\n    \"],[8,\"overflowing-text\",[[24,0,\"print:h-auto print:w-320 print:pr-32\"]],[[\"@height\",\"@text\"],[48,[30,[36,0],[[32,0,[\"supplement\"]],\"--\"],null]]],null],[2,\"\\n\"],[2,\"    \"],[10,\"div\"],[14,0,\"text-material-gray500 print:w-128 print:pr-8 print:text-right print:block\"],[12],[2,\"\\n      \"],[10,\"strong\"],[14,0,\"hidden uppercase print:block\"],[12],[1,[30,[36,1],[[32,0,[\"supplementType\"]]],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\",\"humanize\"]}",
    "moduleName": "balance-ember/components/manage/supplement-cell/template.hbs"
  });

  _exports.default = _default;
});