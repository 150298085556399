define("balance-ember/transforms/array", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ArrayTransform = _transform.default.extend({
    deserialize(value) {
      if (Ember.isArray(value)) {
        return value;
      }

      throw `Transform expected an Array, not a ${typeof value}.`;
    },

    serialize(deserialized) {
      return deserialized;
    }

  });

  var _default = ArrayTransform;
  _exports.default = _default;
});