define("balance-ember/components/table-data-loader/component", ["exports", "balance-ember/components/tagless-component/component", "@microstates/ember", "balance-ember/types/table", "ember-concurrency"], function (_exports, _component, _ember, _table, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * TableDataLoader - This component has the responsability of loading the rows of your table data.
   * It will make sure that the new data from the server is added to the table state and that it
   * doesn't create an infinite loop.
   *
   * @param {Microstate<Table>} state - The table state object
   * @param {Task} loadDataTask - The ember-concurrency task necessary to load your table's data
   * @param {[ModelChanges]} modelChanges - List of model changes for the rows that are being shown.
   * If an model change is detected the table will be reloaded to prevent staleness.
   */
  var _default = _component.default.extend({
    areStatesEqual() {
      let stateValue = (0, _ember.valueOf)(this.tableState);
      let forkedValue = (0, _ember.valueOf)(this.forkedTableState);
      return forkedValue && stateValue === forkedValue;
    },

    loadTableData: (0, _emberConcurrency.task)(function* () {
      // This component depends on the table state, but the table state also depends on the
      // data we load in the component. We don't want to fetch the data twice because we are
      // in a new table state from adding the 'rows' attribute. To combat this we'll save the
      // newState we are in after adding data that was returned from the server. Then we can
      // check that the new state passed in is not the same in `didReceiveAttrs` before
      // peforming the load task again
      let forked = (0, _ember.create)(_table.default, (0, _ember.valueOf)(this.tableState));
      let data = yield this.loadDataTask.linked().perform();
      forked = this.addPaginationData(data.meta, forked);
      forked = forked.rows.set(data.toArray());
      Ember.set(this, 'forkedTableState', forked);
      this.tableState.set((0, _ember.valueOf)(forked));
    }).drop(),

    addPaginationData(meta = {}, forkedState) {
      var _meta$pagination, _meta$stats, _meta$stats$total;

      // Different sources for pagination data depending on which backend endpoint is being used
      let totalItems = (_meta$pagination = meta.pagination) === null || _meta$pagination === void 0 ? void 0 : _meta$pagination.totalCount;
      totalItems = Ember.isPresent(totalItems) ? totalItems : (_meta$stats = meta.stats) === null || _meta$stats === void 0 ? void 0 : (_meta$stats$total = _meta$stats.total) === null || _meta$stats$total === void 0 ? void 0 : _meta$stats$total.count;

      if (Ember.isPresent(totalItems)) {
        return forkedState.pagination.totalItems.set(totalItems);
      }

      return forkedState;
    },

    didReceiveAttrs() {
      this._super();

      if (!this.areStatesEqual() && this.loadDataTask) {
        this.loadTableData.perform();
      }
    },

    didRender() {
      this._super(...arguments);

      let oldChanges = this.oldChanges;
      let newChanges = this.modelChanges; // If a record was added/removed/updated we want to change the table state so we get
      // fresh rows from the server so that everything is in the proper order

      if (oldChanges && oldChanges !== newChanges) {
        this.tableState.rows.clear();
      }

      Ember.set(this, 'oldChanges', newChanges);
    }

  });

  _exports.default = _default;
});