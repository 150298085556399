define("balance-ember/controllers/users/resend-unlock", ["exports", "balance-ember/utils/urls", "ember-concurrency"], function (_exports, _urls, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UsersResendUnlockController = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    queryParams: {
      identification: {
        refreshModel: true
      }
    },
    resendUnlock: (0, _emberConcurrency.task)(function* () {
      return yield this.ajax.request((0, _urls.URLForResendUnlock)(), {
        data: {
          user: {
            email: this.identification
          }
        },
        method: 'POST'
      }).then(() => {
        this.set('errors', []);
        this.notifications.success(`Unlock instructions sent to: ${this.identification}`);
        return this.transitionToRoute('login');
      }).catch(({
        payload
      }) => {
        this.set('errors', payload.errors);
      });
    })
  });
  var _default = UsersResendUnlockController;
  _exports.default = _default;
});