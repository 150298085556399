define("balance-ember/components/budget/vendors/banner/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.loadRecords('vendor', {
      filter: {
        fiscal_year_id: {
          eq: this.currentFiscalYearId
        },
        amazon_business: {
          eq: true
        }
      }
    });
  }), _dec7 = Ember.computed('modelChanges.changes'), _dec8 = Ember._action, (_class2 = class _class2 extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor3, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor4, this);

      _initializerDefineProperty(this, "amazonBusiness", _descriptor5, this);

      _initializerDefineProperty(this, "loadAmazonBusinessVendorTask", _descriptor6, this);

      this.loadAmazonBusinessVendorTask.perform();
    }

    get currentFiscalYearId() {
      return this.availableFiscalYears.current.id;
    }

    get showBanner() {
      if (this.amazonBusinessDestroyed) return true;
      if (this.amazonBusinessAdded) return false;
      return this.amazonBusiness.isConnected && this.amazonBusinessVendorTaskSettled && this.amazonDoesNotExist;
    }

    get amazonBusinessVendorTaskSettled() {
      let {
        performCount,
        isIdle
      } = this.loadAmazonBusinessVendorTask;
      return performCount && isIdle;
    }

    get amazonDoesNotExist() {
      return Ember.isEmpty(this.amazonBusinessVendor);
    }

    get amazonBusinessVendor() {
      return this.loadAmazonBusinessVendorTask.lastSuccessful.value;
    }

    get amazonBusinessAdded() {
      return this.vendorAmazonBusinessLatestChangeType === 'create' || this.vendorAmazonBusinessLatestChangeType === 'update';
    }

    get amazonBusinessDestroyed() {
      return this.vendorAmazonBusinessLatestChangeType === 'destroy';
    }

    get vendorAmazonBusinessLatestChangeType() {
      let changes = this.modelChanges.changesForModelType('vendorAmazonBusiness');
      if (changes.length === 0) return null;
      return changes[changes.length - 1].changeType;
    }

    cta() {
      return this.router.transitionTo('budgeting.vendors.amazon-business');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "availableFiscalYears", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "modelChanges", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "amazonBusiness", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "loadAmazonBusinessVendorTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "vendorAmazonBusinessLatestChangeType", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "vendorAmazonBusinessLatestChangeType"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cta", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "cta"), _class2.prototype)), _class2));

  _exports.default = _class;
});