define("balance-ember/components/position-fill-rates-widget/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/types/table", "@microstates/ember", "ember-concurrency", "balance-ember/utils/morph-keys"], function (_exports, _component, _table, _ember, _emberConcurrency, _morphKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    tableState: (0, _ember.state)((0, _ember.create)(_table.default, {
      pagination: {
        itemsPerPage: 10
      },
      sorts: [{
        column: 'job_title',
        dir: 'asc'
      }]
    })),
    columns: Ember.computed(function () {
      return [{
        label: 'Job Type',
        valuePath: 'jobTitle',
        cellComponent: 'overflowing-table-cell',
        cellClassNames: 'whitespace-nowrap',
        testSelector: 'job-title'
      }, {
        label: 'Total',
        valuePath: 'total',
        cellComponent: 'number-cell',
        format: '0,0[.]00',
        align: 'right',
        width: '60px',
        testSelector: 'total-fte'
      }, {
        label: 'Filled',
        valuePath: 'filled',
        cellComponent: 'number-cell',
        format: '0,0[.]00',
        align: 'right',
        width: '60px',
        testSelector: 'filled-fte'
      }, {
        label: 'Fill Rate',
        valuePath: 'percentFilled',
        cellComponent: 'position-fill-rates-widget/percentage-filled-cell',
        width: '80px'
      }, {
        label: 'Vacant',
        valuePath: 'vacant',
        cellComponent: 'number-cell',
        format: '0,0[.]00',
        align: 'right',
        width: '60px',
        testSelector: 'vacant-fte'
      }];
    }),
    loadTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.queryRecord('report', Object.assign({}, this.tableState.apiQuery, {
        reportName: 'position-fill-rates'
      })).then(report => {
        let pagination = this.getNewPaginationObject(report.meta.pagination);
        let newState = this.getNewTableObject({
          data: report.reportData,
          pagination
        });
        this.tableState.set(newState);
        return report;
      });
    }),

    didReceiveAttrs() {
      this._super();

      this.loadTask.perform();
    },

    getNewTableObject({
      data,
      pagination
    } = {}) {
      return Object.assign({}, (0, _ember.valueOf)(this.tableState), {
        rows: (0, _morphKeys.camelizeKeys)(data),
        pagination
      });
    },

    getNewPaginationObject(paginationResponse) {
      let {
        totalCount
      } = paginationResponse;
      return Object.assign({}, (0, _ember.valueOf)(this.tableState.pagination), {
        totalItems: totalCount
      });
    },

    actions: {
      setPage(page) {
        this.tableState.pagination.page.set(page);
        this.loadTask.perform();
      }

    }
  });

  _exports.default = _default;
});