define("balance-ember/components/one-way-number/component", ["exports", "balance-ember/components/one-way-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    attributeBindings: _component.DEFAULT_ATTRIBUTES.concat('step'),
    type: 'number'
  });

  _exports.default = _default;
});