define("balance-ember/utils/validators/employee", ["exports", "balance-ember/utils/validator"], function (_exports, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class _default extends _validator.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "constraints", {
        firstName: {
          presence: {
            allowEmpty: false
          }
        },
        lastName: {
          presence: {
            allowEmpty: false
          }
        },
        fte: {
          presence: {
            allowEmpty: false
          },
          numericality: {
            greaterThan: 0,
            lessThanOrEqualTo: 1
          }
        },
        employeeStatus: {
          presence: {
            allowEmpty: false
          }
        },
        code: {
          presence: {
            allowEmpty: false
          }
        }
      });

      _defineProperty(this, "constraintPropertyLookups", {
        firstName: 'budget.employees.data.first_name',
        lastName: 'budget.employees.data.last_name',
        fte: 'budget.employees.data.fte',
        employeeStatus: 'budget.employees.data.employee_status',
        code: 'budget.employees.data.code'
      });
    }

  }

  _exports.default = _default;
});