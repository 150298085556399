define("balance-ember/utils/writeable-model/serializer", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Serializer {
    constructor({
      writeableModel,
      rootModel,
      alreadySerialized = []
    }) {
      this.writeableModel = writeableModel;
      this.rootModel = rootModel || writeableModel;
      this.alreadySerialized = alreadySerialized;
      this.alreadySerialized.push(this.writeableModel);
    }

    serialize() {
      let included = this.included;
      return {
        data: this.data,
        included: included.length ? included : undefined // stringify removes undefined

      };
    }

    get data() {
      let _data = {};

      if (Ember.isPresent(this.id)) {
        _data.id = this.id;
      }

      let relationships = this.relationships;
      return this._data = this._data || { ..._data,
        type: this.formatType(this.writeableModel.modelName),
        attributes: this.attributes,
        ...(Object.keys(relationships).length && {
          relationships
        })
      };
    }

    get included() {
      return this.writeableModel.relationshipDefinitions.reduce((acc, relationshipDefinition) => {
        let {
          name,
          kind
        } = relationshipDefinition;

        if (!this.writeableModel.relationshipsToCopy.includes(name)) {
          return acc;
        }

        let children = this.writeableModel[name];

        if (!children) {
          return acc;
        }

        if (kind === 'belongsTo') {
          children = [children];
        }

        children.compact().forEach(child => {
          acc = new Includer({
            alreadySerialized: this.alreadySerialized,
            child,
            rootModel: this.rootModel,
            topLevelJson: {
              data: this.data,
              included: acc
            }
          }).include();
        });
        return acc;
      }, []);
    }

    get id() {
      return this.writeableModel.id;
    }

    get model() {
      return this.writeableModel.model;
    }

    get dirtyType() {
      return this.writeableModel.dirtyType;
    }

    get attributes() {
      return [...this.model.constructor.attributes.values()].reduce((acc, attributeDefinition) => {
        let options = attributeDefinition.options;
        let name = attributeDefinition.name;
        let isReadOnly = options && options.readOnly;

        if (isReadOnly) {
          return acc;
        }

        acc[Ember.String.underscore(name)] = this.writeableModel[name];
        return acc;
      }, {});
    }

    get relationships() {
      return this.writeableModel.relationshipDefinitions.reduce((acc, relationshipDefinition) => {
        let {
          name,
          kind
        } = relationshipDefinition;

        if (!this.writeableModel.relationshipsToCopy.includes(name)) {
          return acc;
        }

        let children = this.writeableModel[`_${name}`];

        if (!children) {
          return acc;
        }

        if (Ember.isArray(children) && children.find(child => child === this.rootModel)) {
          return acc;
        }

        if (children === this.rootModel) {
          return acc;
        }

        acc[Ember.String.underscore(name)] = {
          data: this[`serialize${Ember.String.capitalize(kind)}`](children)
        };
        return acc;
      }, {});
    }

    formatType(modelName) {
      return Ember.String.underscore((0, _emberInflector.pluralize)(modelName));
    }

    serializeBelongsTo(child) {
      let data = {
        type: this.formatType(child.modelName)
      };

      if (child.id) {
        data.id = child.id;
      } else {
        data['temp-id'] = child.tempId;
      }

      let dirtyType = child.dirtyType;

      if (dirtyType) {
        data.method = dirtyType;
      }

      if (this.writeableModel.disassociatedChildren.includes(child)) {
        data.method = 'disassociate';
      }

      return data;
    }

    serializeHasMany(children) {
      return children.map(child => {
        return this.serializeBelongsTo(child);
      });
    }

  }

  _exports.default = Serializer;

  class Includer {
    constructor({
      child,
      topLevelJson,
      rootModel,
      alreadySerialized
    }) {
      this.child = child;
      this.topLevelJson = topLevelJson;
      this.rootModel = rootModel;
      this.alreadySerialized = alreadySerialized;
    }

    include() {
      if (this.shouldInclude) {
        let data = {};

        if (this.child.isNew) {
          data['temp-id'] = this.child.tempId;
        }

        return this.included.concat({ ...data,
          ...this.serializer.data
        }).concat(this.serializer.included);
      }

      return this.included;
    }

    get serializer() {
      return this._serializer = this._serializer ?? new Serializer({
        writeableModel: this.child,
        rootModel: this.rootModel,
        alreadySerialized: this.alreadySerialized
      });
    }

    get data() {
      return this.topLevelJson.data;
    }

    get included() {
      return this.topLevelJson.included;
    }

    get shouldInclude() {
      return !this.isPrimaryResource && !this.isAlreadyIncluded && this.didChange;
    }

    get isPrimaryResource() {
      return this.isAlreadySerialized(this.data);
    }

    get isAlreadyIncluded() {
      return this.alreadySerialized.includes(this.child);
    }

    get didChange() {
      let dirtyType = this.child.dirtyType;
      return dirtyType === 'create' || dirtyType === 'update';
    }

    isAlreadySerialized(data) {
      return data.id === this.child.id && data.type === this.formatType(this.child.modelName);
    }

    formatType(modelName) {
      return Ember.String.underscore((0, _emberInflector.pluralize)(modelName));
    }

  }
});