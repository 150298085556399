define("balance-ember/utils/writeable-models/expense", ["exports", "balance-ember/utils/writeable-models/basic", "balance-ember/utils/expense-totals"], function (_exports, _basic, _expenseTotals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @abstract
   */
  let ExpenseWriteableModel = (_dec = Ember.computed.mapBy('allocationSortedSegments', 'segmentType'), _dec2 = Ember.computed.mapBy('allocationSegmentTypes', 'id'), (_class = class ExpenseWriteableModel extends _basic.default {
    constructor(args) {
      if (!args.paths) {
        args.paths = {
          segments: {
            segmentType: true
          },
          allocation: {
            budget: true,
            segments: {
              segmentType: true
            }
          },
          expenseStrategies: {
            budgetStrategy: {
              budgetPriority: true
            }
          }
        };
      }

      super(args);

      _defineProperty(this, "canHaveSalarySegment", false);

      _initializerDefineProperty(this, "allocationSegmentTypes", _descriptor, this);

      _initializerDefineProperty(this, "parentSegmentTypeIds", _descriptor2, this);

      this.allSegmentTypes = args.allSegmentTypes;
    }
    /**
     * Certain expense types derive their salary segment from another source (e.g. benefits)
     * instead of directly on the expense. In theses cases we don't want to allow users
     * to edit the segment on the expense directly.
     */


    get isEditable() {
      return this.isAllocationEditable;
    }

    get isAllocationEditable() {
      return this.allocation ? this.allocation.editable : true;
    }

    get allocationSortedSegments() {
      return this.allocationSegments.sortBy('segmentType.displayOrder');
    }

    get allocationSegments() {
      return new _expenseTotals.default({
        expense: this,
        allSegmentTypes: this.allSegmentTypes
      }).allocationSegments;
    }

    get expenseSegmentTypes() {
      return new _expenseTotals.default({
        expense: this,
        allSegmentTypes: this.allSegmentTypes
      }).expenseSegmentTypes;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "allocationSegmentTypes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "parentSegmentTypeIds", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ExpenseWriteableModel;
});