define("balance-ember/templates/users/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AdIISB7y",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"px-192 pt-56\"],[12],[2,\"\\n  \"],[8,\"user/profile-tabs\",[],[[\"@user\",\"@tab\",\"@afterUpdateTab\"],[[32,1,[\"user\"]],[32,1,[\"tab\"]],[32,0,[\"updateTab\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/users/profile.hbs"
  });

  _exports.default = _default;
});