define("balance-ember/components/highcharts-scatter-plot/component", ["exports", "balance-ember/components/tagless-component/component", "highcharts"], function (_exports, _component, _highcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param {array} series The series data for the chart
   * @param {string} title The title text for the chart
   * @param {string} xAxisTitle The title text for the x-axis
   */
  var _default = _component.default.extend({
    series: null,
    title: null,
    xAxisTitle: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'series', []);
    },

    /**
     * defaultOptions - Highcharts configuration options
     */
    options: Ember.computed('axis.defaultLabelFormatter', 'title', 'xAxisFormat', 'xAxisTitle', 'yAxisTitle', function () {
      return {
        chart: {
          type: 'scatter',
          zoomType: 'xy',
          borderRadius: 4,
          spacingLeft: 18,
          spacingRight: 18,
          className: 'shadow-chart'
        },
        title: {
          text: this.title
        },
        xAxis: {
          title: {
            text: this.xAxisTitle
          },
          labels: {
            format: this.xAxisFormat
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true
        },
        yAxis: {
          labels: {
            formatter() {
              return `$${this.axis.defaultLabelFormatter.call(this)}`;
            }

          },
          title: {
            text: this.yAxisTitle
          }
        },
        legend: {
          align: 'center',
          layout: 'horizontal',
          verticalAlign: 'bottom',
          floating: false,
          backgroundColor: _highcharts.default.theme && _highcharts.default.theme.legendBackgroundColor || '#FFFFFF'
        },
        exporting: {
          fallbackToExportServer: false
        },
        plotOptions: {
          scatter: {
            marker: {
              radius: 6,
              lineWidth: 1,
              states: {
                hover: {
                  enabled: true,
                  lineColor: 'rgb(100,100,100)'
                }
              }
            },
            states: {
              hover: {
                marker: {
                  enabled: true
                }
              }
            }
          }
        }
      };
    }),
    actions: {
      updateSeriesOptions(chart) {
        chart.series.forEach((series, index) => {
          let newSeries = this.series[index];
          series.update({
            tooltip: newSeries.tooltip
          }, false);
        });
      }

    }
  });

  _exports.default = _default;
});