define("balance-ember/components/multi-step-dropdown/dropdown-step/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/is-offscreen"], function (_exports, _component, _isOffscreen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    stackable: true,
    shouldShow: Ember.computed('step', 'currentStep', function () {
      return Number(this.step) === this.currentStep;
    }),

    didReceiveAttrs() {
      this._super(); // We can't do the check for whether or not the dropdown is offscreen during `didRender` because
      // when we `set` the properties it will cause another `render` to take place which will
      // trigger `didRender` and create an infinite loop. Instead we'll schedule the code to be
      // run `afterRender` so that the modal is open and we can modify the attachment properties.
      // We have to modify these properties ourselves because the `constraints` API that tether.js
      // provides doesn't do the right thing and so we have to manage this ourselves.


      Ember.run.scheduleOnce('afterRender', this, this.maybeFlipPosition);
    },

    maybeFlipPosition() {
      let el = document.querySelector(`[data-dropdown-step="${this.uniqueId}"]`);

      if (!this.isDestroyed && !this.isDestroying) {
        let attachment = ['top', 'left'];
        let targetAttachment = ['bottom', 'left'];
        let offset = [0, 0];

        if (el && (0, _isOffscreen.isOffscreenHorizontally)(el)) {
          attachment[1] = 'right';
          targetAttachment[1] = 'right';
        }

        if (el && (0, _isOffscreen.isOffscreenVertically)(el) && (0, _isOffscreen.hasEnoughSpaceAbove)(el)) {
          attachment[0] = 'bottom';
          targetAttachment[0] = 'top';
          offset[0] = '15px'; // nudge the flyout up - otherwise it covers the 'attachment' DOM element a bit
        }

        Ember.set(this, 'attachment', attachment.join(' '));
        Ember.set(this, 'targetAttachment', targetAttachment.join(' '));
        Ember.set(this, 'offset', offset.join(' '));
      }
    }

  });

  _exports.default = _default;
});