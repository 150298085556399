define("balance-ember/routes/resource-versions", ["exports", "balance-ember/utils/urls"], function (_exports, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),

    model() {
      return this.ajax.request((0, _urls.URLForResourceVersions)());
    }

  });

  _exports.default = _default;
});