define("balance-ember/components/positions-table/nested-table/head/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aPoRne8D",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"thead\"],[14,0,\"text-sm uppercase\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/positions-table/nested-table/head/template.hbs"
  });

  _exports.default = _default;
});