define("balance-ember/routes/budgeting/budget/supplemental-pay-expenses/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BudgetingBudgetSupplementalPayExpensesEditRoute extends Ember.Route {
    model({
      pay_expense_id: expenseId
    }) {
      return {
        expenseId,
        budgetId: this.modelFor('budgeting.budget').id
      };
    }

  }

  _exports.default = BudgetingBudgetSupplementalPayExpensesEditRoute;
});