define("balance-ember/components/manage/summarize/data-loader/child-params", ["exports", "balance-ember/classes/table-serializer", "balance-ember/types/filter", "balance-ember/components/manage/summarize/data-loader/parent-filters-builder", "@microstates/ember"], function (_exports, _tableSerializer, _filter, _parentFiltersBuilder, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This class represents the child loading task parameters that must be sent to parent component
   * in order to load nested summaries.
   *
   * @returns {Category} params.category The category that is being summarized
   * @returns {SummarizePath} params.path The SummarizePath for the summary
   * @returns {number} params.id optional Id of the parent to summarize for segments
   * @returns {Object} params.filters optional Serialized parent filters necessary for a nested summary
   */
  class ChildParams {
    constructor({
      path
    }) {
      this.path = path;
    }

    build() {
      let params = {
        category: this.category,
        path: this.path
      };

      if (!this.isSwitchingCategories) {
        params.id = this.id;
      }

      if (Object.keys(this.filters).length) {
        params.filter = this.filters;
      }

      return params;
    }

    get id() {
      return this.node.value;
    }

    get category() {
      return this.isSwitchingCategories ? (0, _ember.valueOf)(this.summarizeState.category) : this.node.category;
    }

    get isSwitchingCategories() {
      return this.summarizeState.category.keys.length;
    }

    get summarizeState() {
      return this.node.summary;
    }

    get node() {
      return this.path.lastObject;
    }

    get filters() {
      if (this._filters) {
        return this._filters;
      }

      let filters = new _parentFiltersBuilder.default(this.path).build();
      filters = (0, _ember.create)([_filter.default], filters);
      return this._filters = new _tableSerializer.FilterSerializer(filters).serialize();
    }

  }

  _exports.default = ChildParams;
});