define("balance-ember/utils/basic-dropdown-calculate-nav-position", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculatePosition = calculatePosition;

  function calculatePosition(positionOverrides, trigger, dropdown) {
    // `trigger` is the button/link/div that activates the dropdown.
    // If the trigger is actually a div wrapped around an a, compute our dimensions off that.
    if (trigger.childNodes[0]) {
      if (trigger.childNodes[0].tagName === 'A') {
        trigger = trigger.childNodes[0];
      }
    } //How many pixels are we gonna push from the default


    let yNudge = positionOverrides.yNudge || 4;
    let xNudge = positionOverrides.xNudge || 28;
    let topPosition;
    let horizontalPosition = positionOverrides.horizontalPosition || 'center';

    if (positionOverrides.top !== undefined) {
      topPosition = positionOverrides.top;
    } else {
      let triggerHeight = trigger.offsetHeight;
      topPosition = triggerHeight / 1.5 + yNudge;
    }

    let output = {
      horizontalPosition,
      verticalPosition: 'below',
      style: {
        top: topPosition
      }
    };

    if (positionOverrides.left !== undefined) {
      output.style['left'] = positionOverrides.left;
    }

    if (positionOverrides.right !== undefined) {
      output.style['right'] = positionOverrides.right;
    }

    if (positionOverrides.left === undefined && positionOverrides.right === undefined) {
      let computedTrigger = getComputedStyle(trigger),
          triggerRightPadding = parseFloat(computedTrigger.paddingRight),
          triggerRightBorder = parseFloat(computedTrigger.borderRightWidth),
          triggerWidth = parseFloat(computedTrigger.getPropertyValue('width')),
          dropdownWidth = parseFloat(getComputedStyle(dropdown).width);
      output.style['left'] = -dropdownWidth / 2 + triggerWidth - triggerRightPadding - triggerRightBorder - xNudge;
    }

    return output;
  }
});