define("balance-ember/templates/budgeting/job-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fGna/N57",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"job-type-form-data\",[],[[\"@jobType\"],[[32,0,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"job-type-form\",[],[[\"@jobType\",\"@isLoading\",\"@formTitle\",\"@actionButtonText\",\"@disabledButtonText\",\"@segmentTypes\",\"@benefitsPackages\",\"@staffTypes\",\"@budgetCategories\"],[[32,0,[\"model\"]],[32,1,[\"loadTask\",\"isRunning\"]],[30,[36,0],[\"Update \",[32,0,[\"model\",\"title\"]]],null],\"Save Changes\",\"Saving\",[32,1,[\"segmentTypes\"]],[32,1,[\"benefitsPackages\"]],[32,1,[\"staffTypes\"]],[32,1,[\"budgetCategories\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "moduleName": "balance-ember/templates/budgeting/job-types/edit.hbs"
  });

  _exports.default = _default;
});