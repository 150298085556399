define("balance-ember/adapters/benefits-package", ["exports", "balance-ember/adapters/budgeting", "balance-ember/mixins/nested-under-fiscal-year-adapter"], function (_exports, _budgeting, _nestedUnderFiscalYearAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _budgeting.default.extend(_nestedUnderFiscalYearAdapter.default, {
    urlForQuery() {
      return this.fiscalYearURL('benefits_packages');
    },

    urlForFindAll() {
      return this.fiscalYearURL('benefits_packages');
    }

  });

  _exports.default = _default;
});