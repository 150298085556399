define("balance-ember/components/positions-table/columns-provider/job-type-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PGNOq9RJ",
    "block": "{\"symbols\":[\"@row\",\"@column\"],\"statements\":[[8,\"overflowing-text\",[],[[\"@text\",\"@height\"],[[30,[36,0],[[32,1,[\"content\",\"jobType\"]]],[[\"namePath\"],[\"title\"]]],[32,2,[\"height\"]]]],null]],\"hasEval\":false,\"upvars\":[\"coded-name\"]}",
    "moduleName": "balance-ember/components/positions-table/columns-provider/job-type-cell/template.hbs"
  });

  _exports.default = _default;
});