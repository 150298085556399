define("balance-ember/initializers/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'notification-messages-service',

    initialize() {// clobbering this entire initializer and injecting this service ourselves because there is a
      // memory leak as of Jan 22, 2018. The `content` property on the addon's notification
      // service is a direct array property on the service/array-proxy. The addon's service is
      // really extending array-proxy and not Service and our monkey patch and the initialized
      // service conflict with each other.
    }

  };
  _exports.default = _default;
});