define("balance-ember/utils/manage/budget-lea-transition", ["exports", "balance-ember/utils/query-params"], function (_exports, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BudgetLeaTransition {
    constructor({
      route,
      transition,
      availableBudgetLeas
    }) {
      this.route = route;
      this.transition = transition;
      this.availableBudgetLeas = availableBudgetLeas;
    }

    syncBudgetLea() {
      if (this.shouldOverrideCurrentBudgetLea) {
        this.setQueryBudgetLeaOnService();
        return;
      }

      if (this.shouldRedirect) {
        this.redirectWithCurrentBudgetLea();
      }
    }

    get currentBudgetLeaId() {
      return this.currentBudgetLea.id;
    }

    get currentBudgetLea() {
      return this.availableBudgetLeas.current;
    }

    get toQuery() {
      return _queryParams.default.parse(this.toTransition.queryParams.query);
    }

    get toTransition() {
      return this.transition.to;
    }

    get toQueryHasBudgetLea() {
      return this.toQueryFilter && this.toQueryBudgetLea;
    }

    get toQueryBudgetLeaId() {
      return this.toQueryBudgetLea.eq;
    }

    get toQueryBudgetLea() {
      return this.toQueryFilter.budget_lea_id;
    }

    get toQueryFilter() {
      return this.toQuery.filter;
    }

    get shouldOverrideCurrentBudgetLea() {
      return this.toQueryHasBudgetLea && this.isFirstRender;
    }

    get isFirstRender() {
      return !this.transition.from || this.transition.from.name.includes('login');
    }

    get shouldRedirect() {
      return !this.toQueryHasBudgetLea || this.currentAndQueryAreDifferent;
    }

    get currentAndQueryAreDifferent() {
      return this.currentBudgetLeaId !== this.toQueryBudgetLeaId;
    }

    setQueryBudgetLeaOnService() {
      let newCurrent = this.availableBudgetLeas.all.findBy('id', this.toQueryBudgetLeaId);
      this.availableBudgetLeas.setCurrent(newCurrent);
    }

    redirectWithCurrentBudgetLea() {
      this.transition.abort();
      let filter = this.addBudgetLeaToFilters();
      let query = this.addFilterToQuery(filter);
      query = _queryParams.default.stringify(query);
      let params = this.addQueryToQueryParams(query);
      this.route.replaceWith(this.route.routeName, {
        queryParams: params
      });
    }

    addBudgetLeaToFilters() {
      let existingFilters = this.toQueryFilter || {};
      let filter = {
        budget_lea_id: {
          eq: this.currentBudgetLeaId
        }
      };

      if (this.shouldKeepExistingParams) {
        filter = { ...existingFilters,
          ...filter
        };
      }

      return filter;
    }

    addFilterToQuery(filter) {
      let existingQuery = this.toQuery || {};
      let query = {
        filter
      };

      if (this.shouldKeepExistingParams) {
        query = { ...existingQuery,
          ...query
        };
      }

      return query;
    }

    addQueryToQueryParams(query) {
      let existingQueryParams = this.transition.to.queryParams || {};
      let params = {
        query
      };

      if (this.shouldKeepExistingParams) {
        params = { ...existingQueryParams,
          ...params
        };
      }

      return params;
    }

    get shouldKeepExistingParams() {
      return !this.toQueryHasBudgetLea || !this.currentAndQueryAreDifferent;
    }

  }

  _exports.default = BudgetLeaTransition;
});