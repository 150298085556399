define("balance-ember/components/completion-status-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COMPLETED = _exports.ACTIVE = _exports.INACTIVE = void 0;
  const INACTIVE = 'inactive';
  _exports.INACTIVE = INACTIVE;
  const ACTIVE = 'active';
  _exports.ACTIVE = ACTIVE;
  const COMPLETED = 'completed';
  /**
   * Use this to render a circle on the completion status bar
   *
   * @param {string} status The status of the icon (inactive, active, or completed)
   */

  _exports.COMPLETED = COMPLETED;

  var _default = Ember.Component.extend({
    classNames: ['completion-status-icon'],
    classNameBindings: ['status', 'labelAlign'],

    /**
     * The status of this step. inactive, active, or completed
     *
     * @public
     */
    status: INACTIVE,

    /**
     * How to align the labels (left, center, right)
     *
     * @public
     */
    align: 'center',
    isActive: Ember.computed.equal('status', 'active'),
    labelAlign: Ember.computed('align', function () {
      return `align-${this.align}`;
    })
  });

  _exports.default = _default;
});