define("balance-ember/components/popover/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5sNlMZSE",
    "block": "{\"symbols\":[\"attacher\",\"@placement\",\"@id\",\"@arrow\",\"@onChange\",\"@lazyRender\",\"&default\"],\"statements\":[[8,\"attach-popover\",[],[[\"@placement\",\"@showOn\",\"@hideOn\",\"@interactive\",\"@class\",\"@id\",\"@arrow\",\"@showDuration\",\"@onChange\",\"@lazyRender\"],[[30,[36,0],[[32,2],\"bottom\"],null],\"click\",\"click clickout escapekey\",true,\"bg-white rounded shadow-popover popover-arrow\",[32,3],[30,[36,2],[[30,[36,1],[[32,4],false],null],false,true],null],[32,0,[\"showDuration\"]],[32,5],[30,[36,2],[[30,[36,1],[[32,6],false],null],false,true],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,7,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"eq\",\"if\"]}",
    "moduleName": "balance-ember/components/popover/template.hbs"
  });

  _exports.default = _default;
});