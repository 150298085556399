define("balance-ember/components/one-way-textarea/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'textarea',
    attributeBindings: ['value', 'disabled', 'placeholder'],
    value: '',
    disabled: false,

    update() {},

    input(evt) {
      this.update(evt.target.value);
    }

  });

  _exports.default = _default;
});