define("balance-ember/components/positions-table/form/component", ["exports", "@glimmer/component", "balance-ember/utils/writeable-model", "balance-ember/utils/validators/position", "balance-ember/utils/validators/position-expense", "balance-ember/utils/expense-totals", "balance-ember/utils/validator"], function (_exports, _component, _writeableModel, _position, _positionExpense, _expenseTotals, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EditStateMachine = _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PositionsTableExpandedRowEditComponent = (_dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember._tracked, _dec14 = Ember._tracked, (_class3 = class PositionsTableExpandedRowEditComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "network", _descriptor5, this);

      _initializerDefineProperty(this, "intl", _descriptor6, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor7, this);

      _initializerDefineProperty(this, "position", _descriptor8, this);

      _initializerDefineProperty(this, "stateMachine", _descriptor9, this);

      this.createWriteableModel();
      this.stateMachine = new EditStateMachine({
        position: this.position,
        intl: this.intl,
        allSegmentTypes: this.availableFiscalYears.current.segmentTypes,
        cancel: this.args.back
      });
    }

    createWriteableModel() {
      return this.position = new _writeableModel.default({
        model: this.args.position,
        network: this.network,
        store: this.store
      });
    }

  }, (_descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "store", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "network", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "intl", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "availableFiscalYears", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "position", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "stateMachine", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3));
  _exports.default = PositionsTableExpandedRowEditComponent;
  let EditStateMachine = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class EditStateMachine {
    constructor({
      position,
      intl,
      allSegmentTypes,
      cancel
    } = {}) {
      _initializerDefineProperty(this, "status", _descriptor, this);

      _initializerDefineProperty(this, "errors", _descriptor2, this);

      _initializerDefineProperty(this, "numPositions", _descriptor3, this);

      _defineProperty(this, "statusHistory", []);

      this.position = position;
      this.intl = intl;
      this.allSegmentTypes = allSegmentTypes;
      this.cancel = cancel;
    }

    next() {
      var _this$errors, _this$errors2;

      this.updateErrors();

      if (this.status === 'funding' && (_this$errors = this.errors) !== null && _this$errors !== void 0 && _this$errors.funding) {
        return;
      }

      if (this.status.allocation && (_this$errors2 = this.errors) !== null && _this$errors2 !== void 0 && _this$errors2[this.status.allocation.id]) {
        return;
      }

      if (this.status === 'funding' && this.expenses.length) {
        this.updateStatus(this.expenses[0]);
        return;
      }

      if (this.status === 'funding') {
        this.updateStatus('review');
        return;
      }

      if (this.expenses.length && this.status === this.expenses.lastObject) {
        this.updateStatus('review');
        return;
      }

      if (this.expenses.length) {
        let statusIndex = this.expenses.indexOf(this.status);
        this.updateStatus(this.expenses[statusIndex + 1]);
        return;
      }
    }

    back() {
      this.updateErrors();

      if (this.expenses.length && this.status === this.expenses[0]) {
        this.updateStatus('funding');
        return;
      }

      if (this.status === 'review' && this.expenses.length) {
        this.updateStatus(this.expenses.lastObject);
        return;
      }

      if (this.status === 'review') {
        this.updateStatus('funding');
        return;
      }

      if (this.expenses.length) {
        let statusIndex = this.expenses.indexOf(this.status);
        this.updateStatus(this.expenses[statusIndex - 1]);
        return;
      }
    }

    editFunding() {
      this.updateErrors();
      this.updateStatus('funding');
    }

    editExpense(expense) {
      this.updateErrors();
      this.updateStatus(expense);
    }

    review() {
      this.updateErrors({
        checkAll: true
      });
      this.updateStatus('review');
    }

    updateStatus(status) {
      this.statusHistory.push(status);
      this.status = status;
    }

    updateErrors({
      checkAll = false
    } = {}) {
      let expenseErrors = this.expenses.reduce((acc, expense) => {
        if (expense.allocation.id && (this.statusHistory.includes(expense) || checkAll)) {
          acc[expense.allocation.id] = this.validatePositionExpense(expense);
        }

        return acc;
      }, {});
      this.errors = {
        funding: this.validateFunding(),
        ...expenseErrors
      };
    }

    validateFunding() {
      let positionErrors = this.positionValidator.validate();
      let stateMachineErrors = this.stateMachineValidator.validate();
      let errors = [positionErrors, stateMachineErrors].compact();

      if (!errors.length) {
        return;
      }

      return errors.reduce((acc, error) => {
        acc.all = acc.all.concat(error.all);
        acc.messages = acc.messages.concat(error.messages);
        return acc;
      }, {
        all: [],
        messages: []
      });
    }

    validatePositionExpense(expense) {
      return new _positionExpense.default({
        model: expense,
        intl: this.intl,
        options: {
          numSegments: this.expenseSegmentTypes(expense).length
        }
      }).validate();
    }

    get positionValidator() {
      return new _position.default({
        model: this.position,
        intl: this.intl
      });
    }

    get stateMachineValidator() {
      return new StateMachineValidator({
        model: this,
        intl: this.intl
      });
    }

    expenseSegmentTypes(expense) {
      return new _expenseTotals.default({
        expense,
        allSegmentTypes: this.allSegmentTypes
      }).expenseSegmentTypes;
    }

    get expenses() {
      if (!this.position) {
        return [];
      }

      return this.position.positionExpenses.filter(expense => expense.percentage > 0).sortBy('allocation.name');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "status", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'funding';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "numPositions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "next", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "back", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editFunding", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "editFunding"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editExpense", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "editExpense"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "review", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "review"), _class.prototype)), _class));
  _exports.EditStateMachine = EditStateMachine;

  class StateMachineValidator extends _validator.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "constraints", {
        numPositions: {
          presence: {
            allowEmpty: false,
            message: 'budget.position.num_positions_validations'
          },
          numericality: {
            greaterThan: 0,
            lessThanOrEqualTo: 10,
            message: 'budget.position.num_positions_validations'
          }
        }
      });

      _defineProperty(this, "constraintPropertyLookups", {
        numPositions: 'budget.position_expense_form.data.position'
      });
    }

  }
});