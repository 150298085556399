define("balance-ember/components/non-personnel-expense-form/vendor/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NonPersonnelExpenseFormVendor = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* () {
    let results = yield this.store.query('vendor', {
      filter: {
        fiscal_year_id: {
          eq: this.currentFiscalYearId
        },
        amazon_business: {
          eq: true
        }
      },
      page: {
        number: 1,
        size: 1
      }
    });
    return results ? results.firstObject : null;
  }), _dec6 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.timeout)(300);
    return yield this.store.query('vendor', {
      filter: {
        fiscal_year_id: {
          eq: this.currentFiscalYearId
        },
        amazon_business: {
          eq: false
        }
      },
      page: {
        number: 1,
        size: 6
      },
      sort: 'name'
    });
  }), _dec7 = (0, _emberConcurrency.task)(function* (query = '') {
    yield (0, _emberConcurrency.timeout)(300);
    return yield this.store.query('vendor', {
      filter: {
        fiscal_year_id: {
          eq: this.currentFiscalYearId
        },
        amazon_business: {
          eq: false
        },
        query: {
          fuzzy: query
        }
      },
      page: {
        number: 1,
        size: 10
      },
      sort: 'name'
    });
  }), _dec8 = Ember.computed.readOnly('loadAmazonBusinessVendorTask.lastSuccessful.value'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class NonPersonnelExpenseFormVendor extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor2, this);

      _initializerDefineProperty(this, "preferences", _descriptor3, this);

      _initializerDefineProperty(this, "radioSelection", _descriptor4, this);

      _initializerDefineProperty(this, "loadAmazonBusinessVendorTask", _descriptor5, this);

      _initializerDefineProperty(this, "fetchVendorsTask", _descriptor6, this);

      _initializerDefineProperty(this, "searchVendorsTask", _descriptor7, this);

      _initializerDefineProperty(this, "amazonBusinessVendor", _descriptor8, this);

      if (this.args.expense.vendor && this.args.expense.vendor.amazonBusiness) {
        this.radioSelection = 'amazon-business';
      } else if (this.args.expense.vendor) {
        this.radioSelection = 'other-vendor';
      }

      this.loadAmazonBusinessVendorTask.perform();
    }

    get currentFiscalYearId() {
      return this.availableFiscalYears.current.id;
    }

    updateNoVendor() {
      this.radioSelection = null;
      this.args.updateVendor(null);
    }

    updateOtherVendor() {
      this.radioSelection = 'other-vendor';
      this.args.updateVendor(null, {
        isValid: false
      });
    }

    updateAmazonBusiness() {
      this.radioSelection = 'amazon-business';
      this.args.updateVendor(this.amazonBusinessVendor);
    }

    chooseOtherVendor(suggestion) {
      this.args.updateVendor(suggestion);
    }

    toggleAcquireWithAmazonHint(evt) {
      this.preferences.update('acquireWithAmazonHint', !this.preferences.acquireWithAmazonHint);
      this.preferences.persist();
      evt.preventDefault();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "preferences", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "radioSelection", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loadAmazonBusinessVendorTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fetchVendorsTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "searchVendorsTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "amazonBusinessVendor", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateNoVendor", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "updateNoVendor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateOtherVendor", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "updateOtherVendor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAmazonBusiness", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "updateAmazonBusiness"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseOtherVendor", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "chooseOtherVendor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAcquireWithAmazonHint", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAcquireWithAmazonHint"), _class.prototype)), _class));
  _exports.default = NonPersonnelExpenseFormVendor;
});