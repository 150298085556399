define("balance-ember/components/table/bulk-action-bar/tag-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VxTEtxg7",
    "block": "{\"symbols\":[\"b\",\"popover\",\"tagLoadingTask\",\"@selectedRows\"],\"statements\":[[8,\"icon-button\",[],[[\"@style\",\"@size\",\"@icon\",\"@buttonText\",\"@showButtonText\",\"@testId\"],[\"btn-subtle\",\"sm\",\"tag\",[30,[36,1],[\"global.tags.tag\"],null],true,\"bulk-tag-button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"defaultLayout\"]],[],[[],[]],null],[2,\"\\n\\n  \"],[8,\"popover\",[],[[\"@id\",\"@onChange\"],[\"bulk-tag-popover\",[30,[36,2],[[32,0],\"isShowingForm\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"    \"],[8,\"tag/loader\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"min-w-290\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"isShowingForm\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"tag/form/bulk\",[],[[\"@tags\",\"@records\",\"@cancel\",\"@afterSave\"],[[32,3,[\"lastSuccessful\",\"value\"]],[32,4],[32,2,[\"hide\"]],[30,[36,0],[[32,0,[\"afterSave\"]],[32,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"t\",\"set\",\"if\"]}",
    "moduleName": "balance-ember/components/table/bulk-action-bar/tag-button/template.hbs"
  });

  _exports.default = _default;
});