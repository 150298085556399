define("balance-ember/components/admin-breadcrumbs-provider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3+OQU2S4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[32,0,[\"breadcrumbs\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/admin-breadcrumbs-provider/template.hbs"
  });

  _exports.default = _default;
});