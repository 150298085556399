define("balance-ember/components/percentage-bar/bar/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    hasNoWrapperBorder: Ember.computed('bar.{borderClasses,borderColorClass}', function () {
      return this.bar.borderColorClass.includes('transparent');
    })
  });

  _exports.default = _default;
});