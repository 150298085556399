define("balance-ember/components/budget/file-upload/dropzone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TMBL1kg1",
    "block": "{\"symbols\":[\"@dropzone\",\"@queue\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"\\n  w-full\\n  bg-material-gray50\\n  border\\n  border-dashed\\n  border-material-blue-gray100\\n  text-center p-24\\n  \",[30,[36,1],[[32,1,[\"active\"]],\"bg-material-gray100\",\"bg-material-gray50\"],null]]]],[12],[2,\"\\n\\n\"],[6,[37,1],[[32,2,[\"files\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"loading-spinner\",[],[[\"@size\"],[\"xl\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"text-material-blue-gray200 inline-block mx-auto\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"upload\"],[[\"class\"],[\"w-48 h-48\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-material-blue-gray700 text-lg font-bold\"],[12],[2,\"\\n    Drag and drop your file here to upload\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-material-blue-gray400 text-sm\"],[12],[2,\"\\n    Upload and share .docx, .xlsx, or .pdf documents up to 10 MB each\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"if\"]}",
    "moduleName": "balance-ember/components/budget/file-upload/dropzone/template.hbs"
  });

  _exports.default = _default;
});