define("balance-ember/utils/have-filters-changed", ["exports", "lodash.isequal", "@microstates/ember"], function (_exports, _lodash, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = haveFiltersChanged;

  function haveFiltersChanged({
    newTableState,
    oldTableState
  }) {
    return !(0, _lodash.default)((0, _ember.valueOf)(newTableState.filters), (0, _ember.valueOf)(oldTableState.filters));
  }
});