define("balance-ember/components/banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iT6KONtJ",
    "block": "{\"symbols\":[\"@type\",\"@icon\",\"@text\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"\\n    py-10 px-18 mb-14 rounded flex flex-row items-center\\n    \",[30,[36,1],[[30,[36,0],[[32,1],\"warning\"],null],\"bg-material-yellow700\"],null],\"\\n  \"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mr-12 mt-2 text-material-yellow50\"],[12],[2,\"\\n    \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[[32,2],\"inline-block h-24 w-24\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-leading-tight w-mt-2\"],[12],[2,\"\\n    \"],[11,\"strong\"],[24,0,\"align-text-bottom\"],[4,[38,3],[[30,[36,2],[[32,0,[\"a11yAnnouncer\",\"sendMessage\"]],[32,3]],null]],null],[4,[38,4],[[30,[36,2],[[32,0,[\"a11yAnnouncer\",\"sendMessage\"]],[32,3]],null],[32,3]],null],[12],[2,\"\\n      \"],[1,[32,3]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"inline-block ml-20\"],[12],[2,\"\\n    \"],[18,4,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"fn\",\"did-insert\",\"did-update\"]}",
    "moduleName": "balance-ember/components/banner/template.hbs"
  });

  _exports.default = _default;
});