define("balance-ember/components/filter-list/completed-filter/component", ["exports", "balance-ember/components/tagless-component/component", "@microstates/ember", "balance-ember/utils/get-category-config", "balance-ember/utils/filters/format-display-text"], function (_exports, _component, _ember, _getCategoryConfig, _formatDisplayText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    style: 'pill',
    filter: null,
    categories: null,
    categoryValue: Ember.computed.readOnly('filter.category.state'),
    categoryToEdit: (0, _ember.state)((0, _ember.create)(String)),
    categoryConfig: Ember.computed('categories.[]', 'filter.category.state', function () {
      return (0, _getCategoryConfig.default)(this.categories, this.filter.category.state);
    }),
    displayText: Ember.computed('categoryConfig', 'categoryType', 'filter', function () {
      return (0, _formatDisplayText.default)(this.filter, this.categoryConfig);
    }),
    isBoxStyle: Ember.computed('style', function () {
      return this.style.includes('box');
    }),

    updateFilter() {},

    removeFilter() {},

    init() {
      this._super(...arguments);

      if (!this.categories) {
        Ember.set(this, 'categories', []);
      }
    }

  });

  _exports.default = _default;
});