define("balance-ember/utils/validators/user", ["exports", "balance-ember/utils/validator"], function (_exports, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class UserValidator extends _validator.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "constraints", {
        firstName: {
          presence: {
            allowEmpty: false
          }
        },
        lastName: {
          presence: {
            allowEmpty: false
          }
        },
        email: {
          presence: {
            allowEmpty: false
          },
          email: true
        }
      });

      _defineProperty(this, "constraintPropertyLookups", {
        firstName: 'district_admin.user.data.first_name',
        lastName: 'district_admin.user.data.last_name',
        email: 'district_admin.user.data.email'
      });
    }

  }

  _exports.default = UserValidator;
});