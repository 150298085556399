define("balance-ember/components/budgeting-segments/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['preSelectedSegmentCount:stacked-layout'],
    preSelectedSegmentCount: Ember.computed.lt('segments.length', 5)
  });

  _exports.default = _default;
});