define("balance-ember/components/budget-totals/total/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HF/eDbmX",
    "block": "{\"symbols\":[\"@borderWidthClass\",\"@amount\",\"@label\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"flex flex-col w-144 border-material-light-blue100 \",[32,1]]]],[12],[2,\"\\n  \"],[8,\"negative-amount\",[],[[\"@value\"],[[32,0,[\"totalRemaining\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,2]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text-15 text-material-gray600\"],[12],[1,[32,3]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\"]}",
    "moduleName": "balance-ember/components/budget-totals/total/template.hbs"
  });

  _exports.default = _default;
});