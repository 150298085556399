define("balance-ember/services/modals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Use this service for interacting with the url string
   */
  var _default = Ember.Service.extend({
    openModals: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'openModals', []);
    },

    openModal(modalId) {
      Ember.set(this, 'openModals', this.openModals.concat(modalId));
    },

    closeModal(modalId) {
      // Copy the array after removing the modal id
      let openModals = this.openModals.removeObject(modalId).toArray();
      Ember.set(this, 'openModals', openModals);
    },

    topModal: Ember.computed.reads('openModals.lastObject'),

    isTopModal(modalId) {
      let topModalID = this.topModal;
      return modalId === topModalID;
    }

  });

  _exports.default = _default;
});