define("balance-ember/components/manage/bookmark-dropdown/row/delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x76KnwG6",
    "block": "{\"symbols\":[\"@cancel\",\"@deleteBookmark\"],\"statements\":[[10,\"div\"],[14,0,\"rounded p-8 bg-material-red50 w-full\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-material-red600\"],[12],[2,\"Delete\"],[13],[2,\"\\n    \"],[10,\"span\"],[12],[1,[32,0,[\"bookmark\",\"name\"]]],[13],[2,\"?\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex justify-end\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mr-8\"],[12],[2,\"\\n      \"],[8,\"button\",[],[[\"@onclick\",\"@style\",\"@size\",\"@testId\"],[[30,[36,1],[[30,[36,0],[[32,0],[32,1]],null]],null],\"btn-default\",\"sm\",\"cancel-button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Cancel\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"button\",[[24,0,\"bg-white\"]],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-danger-outline\",\"sm\",\"save-button\",[30,[36,1],[[30,[36,0],[[32,0],[32,2],[32,0,[\"bookmark\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Yes, Delete\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"stop-propagation\"]}",
    "moduleName": "balance-ember/components/manage/bookmark-dropdown/row/delete/template.hbs"
  });

  _exports.default = _default;
});