define("balance-ember/components/budget/expense-table/edit-strategies/strategy-amount/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NytGfI2w",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,0,[\"amount\"]]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\"]}",
    "moduleName": "balance-ember/components/budget/expense-table/edit-strategies/strategy-amount/template.hbs"
  });

  _exports.default = _default;
});