define("balance-ember/components/allocation-form/common-attributes/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BUDGET_LIMIT = void 0;
  const BUDGET_LIMIT = 6;
  _exports.BUDGET_LIMIT = BUDGET_LIMIT;

  var _default = Ember.Component.extend({
    salaryTypes: Ember.computed(function () {
      return ['Estimated', 'Actual'];
    }),
    store: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    fetchBudgetsTask: (0, _emberConcurrency.task)(function* (query) {
      yield (0, _emberConcurrency.timeout)(300);
      return yield this.store.query('budget', {
        filter: {
          name: {
            match: query
          },
          fiscal_year_id: {
            eq: this.availableFiscalYears.current.id
          },
          editable: {
            eq: true
          }
        },
        extra_fields: {
          budgets: 'editable'
        },
        page: {
          size: BUDGET_LIMIT,
          number: 1
        },
        fields: {
          budgets: 'name'
        }
      });
    })
  });

  _exports.default = _default;
});