define("balance-ember/components/budget/allocation-name-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C0RvuApg",
    "block": "{\"symbols\":[\"InlineItem\",\"@allocation\"],\"statements\":[[8,\"budget-tab-title\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,2,[\"name\"]]],[2,\"\\n\"],[6,[37,0],[[32,2,[\"locked\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"text-sm leading-none\"],[12],[2,\"\\n      \"],[8,\"layout/inline\",[],[[\"@space\",\"@align\"],[\"xxs\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"span\"],[12],[2,\"\\n            \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"lock\",\"svg-icon\"]],null],[2,\"\\n          \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          Locked\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n      \"],[8,\"tooltip\",[],[[\"@text\"],[\"Expenses cannot be edited while locked\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/budget/allocation-name-header/template.hbs"
  });

  _exports.default = _default;
});