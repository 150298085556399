define("balance-ember/components/one-way-input/component", ["exports", "balance-ember/utils/key-codes"], function (_exports, _keyCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_ATTRIBUTES = void 0;

  /**
  * @param {function} cancel Action for allowing a user to cancel when
  *  they hit the esc key
  * @param {function} enter Action for allowing a user to save when
  * they hit the enter key
  * @param {function} update Action to use for updating the the property
  *   referenced by the input element
  */
  const DEFAULT_ATTRIBUTES = Object.freeze(['value', 'type', 'min', 'max', 'step', 'disabled', 'placeholder', 'maxlength']);
  _exports.DEFAULT_ATTRIBUTES = DEFAULT_ATTRIBUTES;
  const OneWayInput = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: DEFAULT_ATTRIBUTES,
    value: '',
    type: 'text',
    min: null,
    max: null,
    step: null,
    disabled: false,
    maxlength: null,

    cancel() {},

    enter() {},

    update() {},

    keyUp(evt) {
      if (evt.keyCode === _keyCodes.ESC) {
        this.cancel(evt.target.value, evt);
      }

      if (evt.keyCode === _keyCodes.ENTER) {
        this.enter(evt.target.value, evt);
      }
    },

    input(evt) {
      this.update(evt.target.value, evt);
    }

  });
  OneWayInput.reopenClass({
    positionalParams: ['value']
  });
  var _default = OneWayInput;
  _exports.default = _default;
});