define("balance-ember/components/manage/summarize/cell/supplement/component", ["exports", "balance-ember/components/tagless-component/component", "ember-inflector"], function (_exports, _component, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @param
     */
    row: null,

    /**
     * @param
     */
    category: null,
    content: Ember.computed.readOnly('row.content'),
    supplementValue: Ember.computed.readOnly('content.supplementValue'),
    supplementType: Ember.computed('category.title', function () {
      return (0, _emberInflector.singularize)(this.category.title);
    })
  });

  _exports.default = _default;
});