define("balance-ember/components/light-table/cell-components/amazon-business/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vigKdwbb",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[14,\"src\",\"/assets/images/logo/ab-logo.svg\"],[15,\"alt\",[30,[36,0],[\"budget.vendor.data.amazon_business\"],null]],[14,0,\"h-20\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "balance-ember/components/light-table/cell-components/amazon-business/template.hbs"
  });

  _exports.default = _default;
});