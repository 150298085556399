define("balance-ember/components/light-table/head-components/select-all/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GRR4JY+l",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"relative\"],[12],[2,\"\\n  \"],[8,\"ui-form/ui-label\",[],[[\"@style\",\"@for\"],[\"hidden\",\"select-all-checkbox\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"global.table.bulk_actions.select_all\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"py-12\"],[12],[2,\"\\n  \"],[8,\"checkbox\",[],[[\"@id\",\"@testId\",\"@checked\",\"@onupdate\"],[\"select-all-checkbox\",\"select-all-checkbox\",[32,0,[\"areAllVisibleRowsSelected\"]],[32,0,[\"onUpdate\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "balance-ember/components/light-table/head-components/select-all/template.hbs"
  });

  _exports.default = _default;
});