define("balance-ember/controllers/budgeting/job-types", ["exports", "balance-ember/utils/refresh-route", "balance-ember/utils/urls", "balance-ember/mixins/table-controller"], function (_exports, _refreshRoute, _urls, _tableController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.JOB_TYPE_PAGE_SIZE = void 0;
  const JOB_TYPE_PAGE_SIZE = 50;
  _exports.JOB_TYPE_PAGE_SIZE = JOB_TYPE_PAGE_SIZE;

  var _default = Ember.Controller.extend(_tableController.default, {
    router: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    modelChanges: Ember.inject.service(),
    features: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableState.pagination.itemsPerPage.set(JOB_TYPE_PAGE_SIZE);
      this.tableState.defaultSort.set({
        column: 'title',
        dir: 'asc'
      });
    },

    jobTypeBulkUrl: Ember.computed('availableFiscalYears.current.id', function () {
      let fiscalYearId = this.availableFiscalYears.current.id;
      return `${(0, _urls.URLForBudgetNamespace)()}/fiscal_years/${fiscalYearId}/job_types/bulk_upload`;
    }),
    jobTypeChanges: Ember.computed('modelChanges.changes.[]', function () {
      return this.modelChanges.changesForModelType('jobType');
    }),
    categories: Ember.computed('model.{benefitsPackages,staffTypes}', function () {
      var _this$model, _this$model2;

      return [{
        title: 'Job Type',
        value: 'title',
        type: 'text'
      }, {
        title: 'Code',
        value: 'code',
        type: 'text'
      }, {
        title: 'Position Type',
        value: 'staff_type_id',
        type: 'select',
        filterOptions: {
          options: (_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.staffTypes,
          valuePath: 'id',
          labelPath: 'name'
        }
      }, {
        title: 'Allowable',
        value: 'budget_category',
        type: 'select',
        filterOptions: {
          options: [{
            value: 'central_office',
            label: 'Central Office'
          }, {
            value: 'school',
            label: 'School'
          }, {
            value: 'any',
            label: 'Any'
          }, {
            value: 'none',
            label: 'None'
          }],
          valuePath: 'value',
          labelPath: 'label'
        }
      }, {
        title: 'Avg. Base Salary',
        value: 'average_base_salary',
        type: 'currency'
      }, {
        title: 'Benefits Package',
        value: 'benefits_package_id',
        type: 'select',
        filterOptions: {
          options: (_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : _this$model2.benefitsPackages,
          valuePath: 'id',
          labelPath: 'name'
        }
      }, {
        title: 'Avg. Benefits',
        value: 'average_benefits_amount',
        type: 'currency'
      }, {
        title: 'Avg. Total',
        value: 'average_total',
        type: 'currency'
      }];
    }),
    actions: {
      afterUploadSuccess() {
        this.modelChanges.addChange({
          modelType: 'jobType',
          changeType: 'create'
        });
        (0, _refreshRoute.default)('budgeting.job-types', this);
      },

      edit(id) {
        this.router.transitionTo('budgeting.job-types.edit', id);
      },

      delete(id) {
        this.router.transitionTo('budgeting.job-types.destroy', id);
      }

    }
  });

  _exports.default = _default;
});