define("balance-ember/models/supplement-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    summarizable: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});