define("balance-ember/components/filter-form/select/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DiEXkv6x",
    "block": "{\"symbols\":[\"suggestion\"],\"statements\":[[8,\"autocomplete/multiple\",[],[[\"@options\",\"@selected\",\"@searchField\",\"@onChange\"],[[32,0,[\"options\"]],[32,0,[\"selected\"]],[32,0,[\"labelPath\"]],[32,0,[\"updateValues\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,1],[32,0,[\"labelPath\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\"]}",
    "moduleName": "balance-ember/components/filter-form/select/input/template.hbs"
  });

  _exports.default = _default;
});