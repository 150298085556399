define("balance-ember/components/overflowing-text/component", ["exports", "@glimmer/component", "balance-ember/utils/is-text-overflowing", "balance-ember/utils/is-text-overflowing-width"], function (_exports, _component, _isTextOverflowing, _isTextOverflowingWidth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * This will add ellipsis to overflowing text and a tooltip when the user hovers over to see the
   * full text
   *
   * @param {string} text The text that may overflow
   * @param {number} height The height of the element
   */
  let OverflowingTextComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class OverflowingTextComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "shouldTruncate", _descriptor, this);
    }

    truncate(element) {
      let isOverflowing = (0, _isTextOverflowing.default)(element) || (0, _isTextOverflowingWidth.default)(element);

      if (isOverflowing) {
        this.shouldTruncate = true;
      } else {
        this.shouldTruncate = false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "shouldTruncate", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "truncate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "truncate"), _class.prototype)), _class));
  _exports.default = OverflowingTextComponent;
});