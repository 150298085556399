define("balance-ember/components/manage/account-totals/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/classes/bookmark-query-param-builder", "balance-ember/utils/percentages", "ember-concurrency", "balance-ember/utils/manage/current-bookmark-finder"], function (_exports, _component, _bookmarkQueryParamBuilder, _percentages, _emberConcurrency, _currentBookmarkFinder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    availableBudgetLeas: Ember.inject.service(),
    eventTracking: Ember.inject.service(),
    currentBudgetLea: Ember.computed.readOnly('availableBudgetLeas.current'),
    isBudgetedPositive: Ember.computed.gt('budgeted', 0),
    currentBookmark: Ember.computed('bookmarks.[]', 'serializedTableState', function () {
      return new _currentBookmarkFinder.default(this.bookmarks, this.serializedTableState).find() || null;
    }),
    budgetedAmountLabel: 'Current Budget',
    filterQuery: Ember.computed('currentBudgetLea.id', 'serializedTableState', function () {
      let pseudoBookmark = {
        params: this.serializedTableState
      };
      let queryParamBuilder = new _bookmarkQueryParamBuilder.default(pseudoBookmark, this.currentBudgetLea.id);
      return queryParamBuilder.build();
    }),
    actions: {
      viewAccounts() {
        let query = this.filterQuery;
        this.trackClick();
        this.router.transitionTo('manage.accounts', {
          queryParams: {
            query
          }
        });
      }

    },
    amendments: Ember.computed('originalBudget', 'budgeted', function () {
      return this.budgeted - this.originalBudget;
    }),
    percentages: Ember.computed('spent', 'encumbered', 'available', function () {
      let [spent, encumbered, available] = (0, _percentages.toPercentageList)([this.spent, this.encumbered, this.available]);
      return {
        spent,
        encumbered,
        available
      };
    }),
    isDeficitTotals: Ember.computed.lte('available', 0),
    showDonut: Ember.computed.and('loadTask.lastSuccessful.isFinished', 'loadTask.isIdle'),
    bookmarkApplied: Ember.computed('serializedTableState.filter', function () {
      let filterKeys = Object.keys(this.serializedTableState.filter);
      let bookmarkFilters = filterKeys.filter(value => value !== 'budget_lea_id');
      return bookmarkFilters.length > 0;
    }),
    loadTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('accountBalance', { ...this.serializedTableState,
        stats: {
          available: 'sum',
          encumbered: 'sum',
          spent: 'sum',
          budgeted: 'sum',
          original_budget: 'sum'
        }
      }).then(results => {
        this.set('spent', Number(results.meta.stats.spent.sum));
        this.set('encumbered', Number(results.meta.stats.encumbered.sum));
        this.set('available', Number(results.meta.stats.available.sum));
        this.set('budgeted', Number(results.meta.stats.budgeted.sum));
        this.set('originalBudget', Number(results.meta.stats.originalBudget.sum));
      });
    }),

    didReceiveAttrs() {
      this._super();

      this.loadTask.perform();
    },

    trackClick() {
      let bookmarkName = this.currentBookmark ? this.currentBookmark.name : this.defaultBookmarkMenuText;
      this.eventTracking.trackEvent('Click Bookmark - Donut', {
        bookmarkName
      });
    }

  });

  _exports.default = _default;
});