define("balance-ember/components/light-table/cell-components/tag-cell/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LightTableCellComponentsTagCellComponent extends _component.default {
    get visibleTags() {
      return this.sortedTags.slice(0, 2);
    }

    get hiddenTags() {
      let [,, ...rest] = this.sortedTags;
      return rest;
    }

    get sortedTags() {
      return this.args.row.content.tags.sortBy('name');
    }

  }

  _exports.default = LightTableCellComponentsTagCellComponent;
});