define("balance-ember/routes/manage/charts", ["exports", "balance-ember/utils/manage/budget-lea-transition"], function (_exports, _budgetLeaTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    availableBudgetLeas: Ember.inject.service(),

    activate() {
      document.title = 'Manage Charts - Allovue Balance';
    },

    beforeModel(transition) {
      new _budgetLeaTransition.default({
        route: this,
        transition,
        availableBudgetLeas: this.availableBudgetLeas
      }).syncBudgetLea();
    }

  });

  _exports.default = _default;
});