define("balance-ember/utils/serialize-accepts-attributes-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeAcceptsAttributesFor;

  /**
   * Use this to map over each child type and convert it to the attributes hash rails expects
   */
  function extractNestedAttributes(model) {
    // If we're trying to delete this model then all we want to include is it's id and
    // the _destroy property
    if (model.isDeleted) {
      return {
        id: model.id,
        _destroy: '1' // '1' means true for the api

      };
    } // Underscore all the attributes and relationships because rails wants that


    let attrs = {};
    model.eachAttribute(attr => {
      attrs[Ember.String.underscore(attr)] = model[attr];
    });
    model.eachRelationship(name => {
      let key = `${Ember.String.underscore(name)}_id`;
      attrs[key] = model[name].id;
    });

    if (!model.isNew) {
      attrs.id = model.id;
    }

    return attrs;
  }

  function serializeAcceptsAttributesFor(model, json) {
    let attributesToBatch = model.acceptsNestedAttributesFor;

    if (attributesToBatch) {
      attributesToBatch.forEach(attr => {
        // Only attempt serializing acceptsNestedAttributesFor if the data has been loaded
        if (model.hasMany(attr).value()) {
          let attributes = model[attr].map(extractNestedAttributes);
          json.data.attributes[`${Ember.String.underscore(attr)}_attributes`] = attributes;
        }
      });
    }

    return json;
  }
});