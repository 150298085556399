define("balance-ember/components/budget/budgets/table/role-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4TUYfpWs",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[1,[30,[36,0],[[32,1]],null]]],\"hasEval\":false,\"upvars\":[\"titleize\"]}",
    "moduleName": "balance-ember/components/budget/budgets/table/role-cell/template.hbs"
  });

  _exports.default = _default;
});