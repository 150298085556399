define("balance-ember/utils/is-text-overflowing-width", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isTextOverflowingWidth;

  /**
   * isTextOverflowing - Find out if the text is overflowing it's container
   *
   * @param  {element} element The dom element
   * @return {boolean}
   */
  function isTextOverflowingWidth(element) {
    return element.scrollWidth > element.clientWidth;
  }
});