define("balance-ember/components/excel-download-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LsgJrio3",
    "block": "{\"symbols\":[\"@buttonText\",\"@onclick\",\"&attrs\"],\"statements\":[[8,\"icon-button\",[[24,0,\"hover:text-material-green500\"],[17,3]],[[\"@style\",\"@svgSize\",\"@icon\",\"@buttonText\",\"@showButtonText\",\"@onclick\"],[\"btn-subtle-link\",\"h-24 w-24\",\"file-xlsx-narrow\",[30,[36,0],[[32,1],\"Excel (XLSX)\"],null],true,[32,2]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\"]}",
    "moduleName": "balance-ember/components/excel-download-button/template.hbs"
  });

  _exports.default = _default;
});