define("balance-ember/mixins/nested-under-fiscal-year-adapter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    availableFiscalYears: Ember.inject.service(),

    fiscalYearURL(path) {
      var _this$availableFiscal;

      let fiscalYearId = (_this$availableFiscal = this.availableFiscalYears.current) === null || _this$availableFiscal === void 0 ? void 0 : _this$availableFiscal.id;
      return `${this.host}/${this.namespace}/fiscal_years/${fiscalYearId}/${path}`;
    },

    handleResponse(status) {
      // If the user tries to go to a budget they don't have access to we want them sent to
      // not found page
      if (status === 404) {
        this.router.replaceWith('/not-found');
      } else {
        return this._super(...arguments);
      }
    }

  });

  _exports.default = _default;
});