define("balance-ember/templates/budgeting/budget-priorities/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7DQMo20F",
    "block": "{\"symbols\":[],\"statements\":[[8,\"budget/priority-form\",[],[[\"@budgetPriority\",\"@formTitle\",\"@actionButtonText\",\"@disabledButtonText\"],[[32,0,[\"model\"]],\"New Priority\",\"Create Priority\",\"Creating\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/budgeting/budget-priorities/new.hbs"
  });

  _exports.default = _default;
});