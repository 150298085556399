define("balance-ember/components/tag/form/bulk/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1i50mW5i",
    "block": "{\"symbols\":[\"@tag\"],\"statements\":[[8,\"checkbox/tristate\",[],[[\"@checked\",\"@testId\",\"@onupdate\"],[[32,1,[\"checkedState\"]],[31,[\"tag-checkbox-\",[32,1,[\"name\"]],\"-\",[32,1,[\"checkedState\"]]]],[32,1,[\"updateCount\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/tag/form/bulk/checkbox/template.hbs"
  });

  _exports.default = _default;
});