define("balance-ember/components/add-filter-button/category-list/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    categories: null,
    displayedCategories: null,
    filter: null,

    afterUpdate() {},

    displayableCategories: Ember.computed.filter('categories', function (category) {
      return category.removable || Ember.isBlank(category.removable);
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'displayedCategories', this.displayableCategories || []);
    },

    actions: {
      chooseCategory(category) {
        if (category.subcategories) {
          Ember.set(this, 'displayedCategories', category.subcategories);
        } else if (this.filter) {
          this.filter.category.set(category.value);
          this.filter.type.set(category.type);
          this.afterUpdate(category);
        }
      }

    }
  });

  _exports.default = _default;
});