define("balance-ember/components/manage/spending-by-segment/cell-name/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    value: null
  });

  _exports.default = _default;
});