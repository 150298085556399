define("balance-ember/templates/budgeting/fiscal-year/budgets/allocations/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bX99GVC6",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"allocation-form-data\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"allocation-form\",[],[[\"@allocation\",\"@budget\",\"@budgets\",\"@segmentTypes\",\"@mode\",\"@redirectRoute\",\"@formTitle\",\"@disabledButtonText\",\"@actionButtonText\"],[[32,1,[\"allocation\"]],[32,0,[\"model\"]],[32,1,[\"budgets\"]],[32,1,[\"segmentTypes\"]],\"create\",\"budgeting.fiscal-year.budgets\",\"Add Allocation\",\"Creating\",\"Create Allocation\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/budgeting/fiscal-year/budgets/allocations/new.hbs"
  });

  _exports.default = _default;
});