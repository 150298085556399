define("balance-ember/components/light-table/default-column-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "31S4sZ4N",
    "block": "{\"symbols\":[\"@sortIcons\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"\\n  flex\\n  items-center\\n  \",[30,[36,2],[[30,[36,1],[[32,0,[\"column\",\"align\"]],\"right\"],null],\"justify-end\"],null],\"\\n  \",[30,[36,2],[[30,[36,1],[[32,0,[\"column\",\"align\"]],\"center\"],null],\"justify-center\"],null],\"\\n  \"]]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"\\n    \",[30,[36,2],[[32,0,[\"column\",\"canTruncateLabel\"]],\"truncate\"],null],\"\\n    flex-initial\\n  \"]]],[12],[2,\"\\n    \"],[1,[32,0,[\"label\"]]],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,3],[[32,0,[\"shouldShowSortableIcon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flex-initial\"],[12],[2,\"\\n      \"],[10,\"i\"],[15,0,[30,[36,0],[[32,1],[32,0,[\"sortIconProperty\"]]],null]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"eq\",\"if\",\"unless\"]}",
    "moduleName": "balance-ember/components/light-table/default-column-label/template.hbs"
  });

  _exports.default = _default;
});