define("balance-ember/templates/budgeting/district-wide-files", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Sa9+wmO",
    "block": "{\"symbols\":[],\"statements\":[[8,\"container\",[],[[\"@style\"],[\"page padding-x horizontal centered\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"mt-10\"],[12],[2,\"\\n    \"],[8,\"ui-breadcrumbs\",[],[[\"@variant\",\"@breadcrumbs\"],[\"arrow\",[32,0,[\"breadcrumbs\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mt-40\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"text-material-blue-gray700 text-4xl font-bold\"],[12],[2,\"\\n      District-wide Files\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"mt-16\"],[12],[2,\"\\n      \"],[10,\"h2\"],[14,0,\"text-material-blue-gray700 text-sm font-normal\"],[12],[2,\"\\n        District-wide files uploaded are shared in all budgets.\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mt-24\"],[12],[2,\"\\n    \"],[8,\"budget/district-wide-files/table\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/budgeting/district-wide-files.hbs"
  });

  _exports.default = _default;
});