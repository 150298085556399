define("balance-ember/components/manage/summarize/button/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ManageSummarizeButtonComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class ManageSummarizeButtonComponent extends _component.default {
    get isActive() {
      return this.hasCategorySummary;
    }

    get hasCategorySummary() {
      var _this$args, _this$args$summarizeS, _this$args$summarizeS2, _this$args$summarizeS3;

      return Boolean((_this$args = this.args) === null || _this$args === void 0 ? void 0 : (_this$args$summarizeS = _this$args.summarizeState) === null || _this$args$summarizeS === void 0 ? void 0 : (_this$args$summarizeS2 = _this$args$summarizeS.category) === null || _this$args$summarizeS2 === void 0 ? void 0 : (_this$args$summarizeS3 = _this$args$summarizeS2.keys) === null || _this$args$summarizeS3 === void 0 ? void 0 : _this$args$summarizeS3.length);
    }

    get categoryTitle() {
      var _this$args2, _this$args2$summarize, _this$args2$summarize2, _this$args2$summarize3, _this$args2$summarize4;

      return (_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : (_this$args2$summarize = _this$args2.summarizeState) === null || _this$args2$summarize === void 0 ? void 0 : (_this$args2$summarize2 = _this$args2$summarize.category) === null || _this$args2$summarize2 === void 0 ? void 0 : (_this$args2$summarize3 = _this$args2$summarize2.entries) === null || _this$args2$summarize3 === void 0 ? void 0 : (_this$args2$summarize4 = _this$args2$summarize3.title) === null || _this$args2$summarize4 === void 0 ? void 0 : _this$args2$summarize4.state;
    }

    get buttonText() {
      if (this.hasCategorySummary) {
        return this.args.activeText ?? `By ${this.categoryTitle}`;
      }

      return this.args.buttonText ?? 'Summarize';
    }

    get showButtonText() {
      return this.args.showButtonText ?? true;
    }

    get buttonClasses() {
      return this.isActive ? this.appliedClasses : this.unAppliedClasses;
    }

    get appliedClasses() {
      return ['text-material-purple400', 'bg-material-purple50', 'shadow-none', 'border-material-purple400', 'hover:bg-material-purple100', 'tab-focus:bg-material-purple100', 'active:bg-material-purple100'].join(' ');
    }

    get unAppliedClasses() {
      return [this.args.shadowClass ?? 'shadow-none', 'border-transparent', this.args.textColorClass ?? 'text-material-gray700', this.args.bgColorClass ?? 'bg-white', 'hover:text-material-light-blue600', 'tab-focus:text-material-light-blue600', 'active:text-material-light-blue600'].join(' ');
    }

    summarize(category, closeDropdown) {
      closeDropdown();

      if (this.args.onSummarize) {
        this.args.onSummarize(category);
      }
    }

    clear(closeDropdown) {
      closeDropdown();

      if (this.args.onClear) {
        this.args.onClear();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "summarize", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "summarize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clear", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype)), _class));
  _exports.default = ManageSummarizeButtonComponent;
});