define("balance-ember/models/bookmark", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)(),
    resource: (0, _model.attr)(),
    params: (0, _model.attr)(),
    districtWide: (0, _model.attr)('boolean'),
    user: (0, _model.belongsTo)('user', {
      async: false
    }),
    bookmarkReminder: (0, _model.belongsTo)('bookmarkReminder', {
      async: false
    }),
    reminder: Ember.computed.alias('bookmarkReminder'),
    budgetLeas: (0, _model.hasMany)('budgetLea', {
      async: false
    })
  });

  _exports.default = _default;
});