define("balance-ember/components/table/filter-bar/clear-all/deleting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pcyu682h",
    "block": "{\"symbols\":[\"@clearAllFilters\",\"@cancelClearAll\"],\"statements\":[[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-white text-sm\"],[12],[2,\"\\n    Are you sure you want to clear all filters?\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex items-center ml-18\"],[12],[2,\"\\n    \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-danger\",\"sm\",\"confirm-clear-all-button\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Yes, Clear All\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"ml-8\"],[12],[2,\"\\n      \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-white-link\",\"sm\",\"cancel-clear-all-button\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Cancel\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/table/filter-bar/clear-all/deleting/template.hbs"
  });

  _exports.default = _default;
});