define("balance-ember/routes/budgeting/fiscal-year/purchase-orders/view-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    model({
      allocation_id: allocationId,
      vendor_id: vendorId
    }) {
      return Ember.RSVP.hash({
        'allocation': this.store.findRecord('allocation', allocationId, {
          include: 'budget'
        }),
        'nonPersonnelExpenses': this.store.query('nonPersonnelExpense', {
          filter: {
            allocation_id: {
              eq: allocationId
            },
            vendor_id: {
              eq: vendorId
            },
            purchase_order_id: 'null'
          },
          page: {
            size: 1,
            number: 1
          },
          stats: {
            amount: 'sum'
          }
        }),
        'vendor': this.store.findRecord('vendor', vendorId)
      });
    }

  }

  _exports.default = _default;
});