define("balance-ember/components/radio-button-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gqnL18Pw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[15,\"for\",[32,0,[\"controlId\"]]],[14,0,\"sr-only\"],[12],[1,[32,0,[\"controlLabel\"]]],[13],[2,\"\\n\"],[1,[30,[36,3],[[32,0,[\"value\"]]],[[\"id\",\"option\",\"name\",\"update\"],[[32,0,[\"controlId\"]],[32,0,[\"column\",\"option\"]],[30,[36,2],[\"radio-button-cell[\",[30,[36,0],[[32,0,[\"row\",\"content\"]],[32,0,[\"column\",\"namePath\"]]],null],\"-\",[32,0,[\"row\",\"content\",\"id\"]],\"]\"],null],[30,[36,1],[[32,0],[30,[36,0],[[32,0,[\"tableActions\"]],[32,0,[\"column\",\"actionName\"]]],null],[32,0,[\"row\",\"content\"]]],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"action\",\"concat\",\"one-way-radio\"]}",
    "moduleName": "balance-ember/components/radio-button-cell/template.hbs"
  });

  _exports.default = _default;
});