define("balance-ember/components/table/toolbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YbUJzPXb",
    "block": "{\"symbols\":[\"@trackingPrefix\",\"@categories\",\"@tableState\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"bg-white border-b border-material-gray200 flex items-stretch print:hidden\"],[12],[2,\"\\n  \"],[18,4,[[30,[36,1],null,[[\"section\",\"filter-button\"],[[30,[36,0],[\"table/toolbar/section\"],null],[30,[36,0],[\"table/filter-button\"],[[\"tableState\",\"categories\",\"trackingPrefix\"],[[32,3],[32,2],[32,1]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/table/toolbar/template.hbs"
  });

  _exports.default = _default;
});