define("balance-ember/components/pagination-controls/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PaginationControls = (_dec = Ember.inject.service, _dec2 = Ember.computed('args.state'), _dec3 = Ember.computed.readOnly('args.state.page.state'), _dec4 = Ember.computed.readOnly('args.state.totalPages'), _dec5 = Ember.computed.readOnly('args.state.totalItems.state'), _dec6 = Ember.computed('itemsPerPageOptions', 'totalItems'), _dec7 = Ember.computed.gt('lastPage', 1), _dec8 = Ember.computed.readOnly('loadTask.isRunning'), _dec9 = Ember._action, _dec10 = Ember._action, (_class = class PaginationControls extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "eventTracking", _descriptor, this);

      _initializerDefineProperty(this, "currentPage", _descriptor2, this);

      _initializerDefineProperty(this, "lastPage", _descriptor3, this);

      _initializerDefineProperty(this, "totalItems", _descriptor4, this);

      _initializerDefineProperty(this, "showPaginationControls", _descriptor5, this);

      _initializerDefineProperty(this, "isLoading", _descriptor6, this);

      Ember.set(this, 'state', this.args.state);
    }

    get data() {
      return this.args.data;
    }

    get loadTask() {
      return this.args.loadTask;
    }

    get trackingPrefix() {
      return this.args.trackingPrefix;
    }

    get trackingEventName() {
      return `${this.trackingPrefix} Pagination Change`;
    }

    get itemsPerPageOptions() {
      return [1, 5, 10, 15, 25, 50, 100];
    }

    get showPerPageControl() {
      return this.totalItems > this.itemsPerPageOptions[0];
    }

    get afterGoToPage() {
      return this.args.afterGoToPage ? this.args.afterGoToPage : () => {};
    }

    get afterSetPerPage() {
      return this.args.afterSetPerPage ? this.args.afterSetPerPage : () => {};
    }

    goToPage(page) {
      if (this.trackingPrefix) {
        this.eventTracking.trackEvent(this.trackingEventName, { ...this.args.trackingParams,
          page: Number(page)
        });
      }

      this.state.page.set(page);
      this.afterGoToPage(page);
    }

    setPerPage(itemsPerPage) {
      if (this.trackingPrefix) {
        this.eventTracking.trackEvent(this.trackingEventName, { ...this.args.trackingParams,
          itemsPerPage: Number(itemsPerPage)
        });
      }

      this.state.updatePerPage(itemsPerPage);
      this.afterSetPerPage(itemsPerPage);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "eventTracking", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "itemsPerPageOptions", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "itemsPerPageOptions"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentPage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "lastPage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "totalItems", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showPerPageControl", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "showPerPageControl"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showPaginationControls", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "goToPage", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "goToPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPerPage", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setPerPage"), _class.prototype)), _class));
  _exports.default = PaginationControls;
});