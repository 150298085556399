define("balance-ember/components/budget-positions-page/allocation-list/component", ["exports", "@glimmer/component", "balance-ember/types/filter", "@microstates/ember", "balance-ember/utils/budget/chosen-allocation"], function (_exports, _component, _filter, _ember, _chosenAllocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BudgetPositionsPageAllocationListComponent = (_dec = Ember._action, (_class = class BudgetPositionsPageAllocationListComponent extends _component.default {
    get chosenAllocation() {
      return new _chosenAllocation.default({
        allocations: this.args.allocations,
        tableState: this.args.tableState
      }).allocation;
    }

    get allocationFilter() {
      return new _chosenAllocation.default({
        allocations: this.args.allocations,
        tableState: this.args.tableState
      }).allocationFilter;
    }

    chooseAllocation(allocation) {
      if (allocation) {
        let filter = this.createFilter(allocation);
        return this.args.tableState.updateFilter(this.allocationFilterIndex, filter);
      }

      this.args.tableState.filters.remove(this.allocationFilter);
    }

    createFilter(allocation) {
      return (0, _ember.create)(_filter.default, {
        category: 'allocation_id',
        removable: false,
        operators: [{
          value: 'eq',
          filterValues: [allocation.id]
        }]
      });
    }

    get allocationFilterIndex() {
      if (!this.allocationFilter) {
        return 0;
      }

      return [...this.args.tableState.filters].indexOf(this.allocationFilter);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "chooseAllocation", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "chooseAllocation"), _class.prototype)), _class));
  _exports.default = BudgetPositionsPageAllocationListComponent;
});