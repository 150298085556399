define("balance-ember/utils/filters/select-formatter", ["exports", "balance-ember/utils/filters/formatter"], function (_exports, _formatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SelectFormatter extends _formatter.default {
    formatValue() {
      return this.selectedOption[this.labelPath];
    }

    get selectedOption() {
      return this.options.findBy(this.valuePath, this.firstValue);
    }

    get options() {
      return this.categoryConfig.filterOptions.options;
    }

    get labelPath() {
      return this.categoryConfig.filterOptions.labelPath;
    }

    get valuePath() {
      return this.categoryConfig.filterOptions.valuePath;
    }

    get operatorDescriptors() {
      return {
        eq: 'is',
        not_eq: 'is not'
      };
    }

  }

  _exports.default = SelectFormatter;
});