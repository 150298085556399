define("balance-ember/routes/budgeting/fiscal-year/reports/view", ["exports", "balance-ember/utils/morph-keys", "balance-ember/utils/urls"], function (_exports, _morphKeys, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetingFiscalYearReportsViewRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.readOnly('availableFiscalYears.current.id'), (_class = class BudgetingFiscalYearReportsViewRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "reportTypes", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor3, this);

      _initializerDefineProperty(this, "eventTracking", _descriptor4, this);

      _initializerDefineProperty(this, "fiscalYearId", _descriptor5, this);
    }

    activate() {
      this.eventTracking.trackEvent('View Reports', {
        fiscalYear: this.availableFiscalYears.current.name
      });
    }

    beforeModel({
      intent: {
        url,
        name
      }
    }) {
      let reportTypeName = (name ? name.split('.') : url.split('/')).pop();
      if (reportTypeName === 'view') return;
      if (this.canAccessReport(reportTypeName)) this.transitionTo('/not-found');
    }

    model({
      report_name: reportName
    }) {
      let authString = this.ajax.getAuthString();
      let url = `${(0, _urls.URLForReportsSummary)({
        fiscalYearId: this.fiscalYearId,
        reportName
      })}${authString}`;
      return this.ajax.request(url).then(response => {
        response = (0, _morphKeys.camelizeKeys)(response);
        response.reportType = reportName;
        return response;
      });
    }

    canAccessReport(reportTypeName) {
      return this.reportTypes.filtered.includes(reportTypeName) === false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "reportTypes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "eventTracking", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fiscalYearId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BudgetingFiscalYearReportsViewRoute;
});