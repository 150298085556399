define("balance-ember/components/budget-details/delete-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bM4TQZ7y",
    "block": "{\"symbols\":[\"@budget\"],\"statements\":[[8,\"button\",[],[[\"@style\",\"@size\",\"@onclick\",\"@testId\"],[\"btn-danger-link\",\"sm\",[30,[36,0],[[32,0],\"showModal\",true],null],\"delete-budget\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  Delete Budget\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"showModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"budget/budgets/destroy-modal\",[],[[\"@budget\",\"@cancel\"],[[32,1],[30,[36,0],[[32,0],\"showModal\",false],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"set\",\"if\"]}",
    "moduleName": "balance-ember/components/budget-details/delete-button/template.hbs"
  });

  _exports.default = _default;
});