define("balance-ember/components/loading-spinner/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Use this component to display a loading spinner
   *
   * @param {string} size The size the spinner should be (normal, large, xl, xxl, xxxl)
   */
  var _default = Ember.Component.extend({
    classNames: ['loader'],
    classNameBindings: ['size'],
    attributeBindings: ['testId:data-test-id'],
    size: '',
    testId: 'loading-spinner'
  });

  _exports.default = _default;
});