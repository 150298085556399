define("balance-ember/components/budget-module-no-allocations/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    isBudgetArchitect: Ember.computed.readOnly('session.isBudgetArchitect'),
    isArchitectAndEditable: Ember.computed.and('isBudgetArchitect', 'budget.isEditable')
  });

  _exports.default = _default;
});