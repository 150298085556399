define("balance-ember/routes/district-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    afterModel() {
      // We don't want non admins to access this part of the app via the url
      if (!this.session.isAdmin) {
        this.replaceWith('/not-found');
      }
    }

  });

  _exports.default = _default;
});