define("balance-ember/models/supplemental-pay-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  const schema = {
    name: (0, _model.attr)('string'),
    code: (0, _model.attr)('string'),
    amount: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    deletable: (0, _model.attr)('boolean', {
      readOnly: true
    }),
    segment: (0, _model.belongsTo)('segment', {
      async: false
    }),
    benefitsPackage: (0, _model.belongsTo)('benefitsPackage', {
      async: false
    }),
    fiscalYear: (0, _model.belongsTo)('fiscalYear', {
      async: false
    })
  };
  _exports.schema = schema;

  var _default = _model.default.extend(schema);

  _exports.default = _default;
});