define("balance-ember/components/manage/spending-by-segment/column-header-utilized/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bx0gGzaR",
    "block": "{\"symbols\":[],\"statements\":[[2,\"Utilized\\n\"],[10,\"span\"],[14,0,\"font-normal text-xs\"],[12],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],[[30,[36,0],[\"isXl\"],null],[30,[36,0],[\"isXxl\"],null]],null],\"(Encumbered+Spent)\",\"(Enc.+Spent)\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"media\",\"or\",\"if\"]}",
    "moduleName": "balance-ember/components/manage/spending-by-segment/column-header-utilized/template.hbs"
  });

  _exports.default = _default;
});