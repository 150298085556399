define("balance-ember/components/ui-breadcrumbs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p+Br60qR",
    "block": "{\"symbols\":[\"breadcrumb\",\"index\",\"@breadcrumbs\"],\"statements\":[[10,\"nav\"],[15,0,[32,0,[\"breadcrumbFontFamily\"]]],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"flex items-center\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,3],[[32,2],[32,0,[\"lastBreadcrumbIndex\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[15,0,[31,[\"flex\\n          items-center\\n          \",[32,0,[\"lastBreadcrumbFontWeightClass\"]],\"\\n          \",[32,0,[\"breadcrumbTextSize\"]],\"\\n          pl-4\\n          text-material-gray700\\n          css-breadcrumb-last \",[32,0,[\"breadcrumbClassNames\"]]]]],[12],[2,\"\\n          \"],[10,\"span\"],[12],[1,[32,1,[\"displayName\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,\"link-to\",[[16,0,[30,[36,2],[\"flex\\n          items-center \",[32,0,[\"breadcrumbFontWeight\"]],\" \",[32,0,[\"breadcrumbTextSize\"]],\" \",[30,[36,1],[[30,[36,0],[[32,2],0],null],\"css-breadcrumb-middle \"],null],[32,0,[\"breadcrumbClassNames\"]]],null]]],[[\"@route\",\"@tagName\"],[[32,1,[\"routeName\"]],\"li\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"a\"],[14,0,\"py-0 pr-6 flex items-center css-breadcrumb-name text-blue500\"],[12],[2,\"\\n            \"],[10,\"span\"],[12],[1,[32,1,[\"displayName\"]]],[13],[2,\"\\n          \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,3],[[32,0,[\"variant\"]],\"simple\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"chevron\",\"svg-icon rotate-270\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not-eq\",\"if\",\"concat\",\"eq\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/ui-breadcrumbs/template.hbs"
  });

  _exports.default = _default;
});