define("balance-ember/components/light-table/cell-components/link-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9F9U0Nyx",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[10,\"span\"],[14,0,\"inline-block leading-tight text-blue500\"],[12],[2,\"\\n  \"],[8,\"link-to\",[[4,[38,0],[[32,0,[\"trackingEvent\"]],[32,0,[\"trackingParams\"]]],null]],[[\"@params\"],[[32,0,[\"route\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"track-event\"]}",
    "moduleName": "balance-ember/components/light-table/cell-components/link-cell/template.hbs"
  });

  _exports.default = _default;
});