define("balance-ember/components/allocation-form/segments/edit-segments-button/warning-pane/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D5XFq6fh",
    "block": "{\"symbols\":[\"@expenseCount\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"bg-white\\n            border\\n            border-solid\\n            border-material-orange200\\n            bg-material-orange100\\n            rounded-sm\\n            py-12\\n            px-24\\n            text-sm\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    Editing these segments will affect all \"],[1,[32,1]],[2,\" expenses in this allocation. Do you want to continue?\\n  \"],[13],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/allocation-form/segments/edit-segments-button/warning-pane/template.hbs"
  });

  _exports.default = _default;
});