define("balance-ember/utils/english-list", ["exports", "lodash.compact", "lodash.map"], function (_exports, _lodash, _lodash2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = englishList;

  function englishList(array) {
    let normalizedArray = (0, _lodash2.default)(array, str => str.humanize().titleize());
    let lastItem = normalizedArray.pop();
    let listParts = (0, _lodash.default)([normalizedArray.join(', '), lastItem]);

    if (array.length > 2) {
      return listParts.join(', and '); // Oxford Comma
    }

    return listParts.join(' and ');
  }
});