define("balance-ember/components/manage/accounts-table/summarize/component", ["exports", "ember-concurrency", "balance-ember/classes/table-serializer", "balance-ember/utils/grand-total-stats", "balance-ember/utils/urls", "balance-ember/utils/morph-keys", "balance-ember/components/manage/summarize/remove-segment-prefix", "balance-ember/components/manage/transactions-table/summarize/component"], function (_exports, _emberConcurrency, _tableSerializer, _grandTotalStats, _urls, _morphKeys, _removeSegmentPrefix, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ManageAccountsTableSummarizeComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember.computed.readOnly('summarizeTableState.summarize'), _dec5 = Ember.computed.readOnly('summarizeTableState.table'), _dec6 = Ember.computed.readOnly('summarizeState.category.entries.value.state'), _dec7 = Ember.computed.readOnly('summarizeState.category.entries.title.state'), _dec8 = Ember.computed.readOnly('summarizeState.category.entries.type.state'), _dec9 = (0, _emberConcurrency.task)(function* (category) {
    this.eventTracking.trackEvent('Summary on Accounts', {
      category,
      nestedDepth: 1
    }); // Using plain ajax here because the results are NOT account balances. The results are aggregations
    // of account balances. Therefore we don't want them turned into ember data models. We don't want
    // them clobbering records of real account balances in the store.

    return yield this.ajax.request(this.summarizeURL, {
      data: {
        sort: this.shouldApplySort ? this.serializedTableState.sort : this.summarizeColumnSortOption[1].value,
        filter: {
          user_filters: this.serializedTableState.filter
        },
        value: (0, _removeSegmentPrefix.default)(category),
        stats: {
          budgeted: 'sum',
          encumbered: 'sum',
          spent: 'sum',
          available: 'sum'
        }
      }
    }).then(results => {
      this.grandTotals = (0, _grandTotalStats.pullOutGrandTotalStats)(results.meta.stats);
      return this.normalizeJSON(results);
    });
  }), _dec10 = (0, _emberConcurrency.task)(function* ({
    category,
    filter,
    id,
    path
  } = {}) {
    let currentPath = path.values.join('->');
    this.eventTracking.trackEvent('Summary on Accounts', {
      currentPath
    }); // Using plain ajax here because the results are NOT account balances. The results are aggregations
    // of account balances. Therefore we don't want them turned into ember data models. We don't want
    // them clobbering records of real account balances in the store.

    return yield this.ajax.request(this.summarizeURL, {
      data: {
        filter: {
          user_filters: this.serializedTableState.filter,
          summarize_filters: filter
        },
        value: (0, _removeSegmentPrefix.default)(category.value),
        id
      }
    }).then(results => this.normalizeJSON(results));
  }), (_class = class ManageAccountsTableSummarizeComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "eventTracking", _descriptor2, this);

      _initializerDefineProperty(this, "grandTotals", _descriptor3, this);

      _defineProperty(this, "navHeight", _component.NAV_HEIGHT);

      _initializerDefineProperty(this, "summarizeState", _descriptor4, this);

      _initializerDefineProperty(this, "tableState", _descriptor5, this);

      _initializerDefineProperty(this, "summarizedCategoryValue", _descriptor6, this);

      _initializerDefineProperty(this, "summarizedCategoryTitle", _descriptor7, this);

      _initializerDefineProperty(this, "summarizedCategoryType", _descriptor8, this);

      _initializerDefineProperty(this, "loadTask", _descriptor9, this);

      _initializerDefineProperty(this, "loadChildrenTask", _descriptor10, this);

      _defineProperty(this, "summarizeURL", `${(0, _urls.URLForAccountBalances)()}/summaries/segments`);
    }

    get summarizeColumnSortOption() {
      return this.summarizeColumnSortOptions[this.summarizedCategoryType];
    }

    get shouldApplySort() {
      var _, _$column;

      let sortedColumn = (_ = [...this.tableState.sorts][0]) === null || _ === void 0 ? void 0 : (_$column = _.column) === null || _$column === void 0 ? void 0 : _$column.state;
      return this.possibleSummarizeColumnSortValues.includes(sortedColumn);
    }

    get possibleSummarizeColumnSortValues() {
      return Object.keys(this.summarizeColumnSortOptions).reduce((acc, key) => {
        let options = this.summarizeColumnSortOptions[key];
        let values = options.mapBy('value');
        return acc.concat(values);
      }, []).concat('budgeted', 'encumbered', 'spent', 'available');
    }

    get summarizeColumnSortOptions() {
      return {
        segment: [{
          value: 'segment_name',
          label: 'Name'
        }, {
          value: 'segment_code',
          label: 'Code'
        }]
      };
    }

    get summarizeColumns() {
      return [this.summarizeColumn, ...this.amountColumns];
    }

    get summarizeColumn() {
      return {
        label: 'ACCOUNTS SUMMARIZE VIEW',
        testSelector: 'summarize',
        sortable: this.canSort,
        hideable: this.canSort,
        sortableBy: this.summarizeColumnSortOption,
        cellComponent: 'manage/summarize/cell',
        cellClassNames: 'py-0',
        canTruncateLabel: true
      };
    }

    get amountColumns() {
      return this.columns.filter(column => {
        return ['budgeted', 'encumbered', 'spent', 'available'].includes(column.valuePath);
      }).map(column => {
        return { ...column,
          sortable: this.canSort,
          hideable: this.canSort,
          sortName: '',
          sortableBy: [{
            value: column.valuePath,
            label: column.label.titleize()
          }],
          cellClassNames: 'py-0',
          classNames: '',
          canTruncateLabel: true
        };
      });
    }

    get canSort() {
      return !this.summarizeState.summaries.values.length;
    }

    get serializedTableState() {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }

    normalizeJSON(json) {
      return json.data.map(record => (0, _morphKeys.camelizeKeys)(record.attributes));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "eventTracking", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grandTotals", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "summarizeState", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tableState", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "summarizedCategoryValue", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "summarizedCategoryTitle", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "summarizedCategoryType", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "loadTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "loadChildrenTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ManageAccountsTableSummarizeComponent;
});