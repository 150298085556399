define("balance-ember/components/filter-list/completed-filter/pill/normal/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/classes/table-serializer"], function (_exports, _component, _tableSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    eventTracking: Ember.inject.service(),
    categoryToEdit: null,
    shouldShowForm: Ember.computed('categoryToEdit.state', 'filter.category.state', function () {
      return this.categoryToEdit && this.categoryToEdit.state === this.filter.category.state;
    }),

    trackAddFilter(finishedFilter) {
      if (this.trackingPrefix) {
        let operatorData = new _tableSerializer.FilterSerializer().serializeFilter(finishedFilter);
        this.eventTracking.trackEvent(`${this.trackingPrefix} Add Filter`, {
          category: finishedFilter.category.state,
          ...operatorData
        });
      }
    },

    actions: {
      cancel(closeDropdown) {
        closeDropdown();
        this.categoryToEdit.set('');
      },

      done(closeDropdown, finishedFilter) {
        closeDropdown();
        this.updateFilter(finishedFilter);
        this.categoryToEdit.set('');
        this.trackAddFilter(finishedFilter);
      }

    }
  });

  _exports.default = _default;
});