define("balance-ember/components/segment-finder/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param {object} update Action to perform when segment is chosen or text is typed
   * @param {object} selectedSegment The segment previously selected
   * @param {object} segmentType The type of segment to search for
   */
  var _default = Ember.Component.extend({
    classNames: ['segment-finder'],
    attributeBindings: ['testSelector:data-test-segment-finder'],
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    selectedSegment: null,
    selectedSegmentAsArray: Ember.computed('selectedSegment', function () {
      let segment = this.selectedSegment;
      return segment && segment.id ? [segment] : [];
    }),
    segmentType: null,
    segmentTypeId: Ember.computed.readOnly('segmentType.id'),
    testSelector: Ember.computed.readOnly('segmentType.name'),

    /**
     * Pass this in to get the updated data
     */
    update() {},

    fetchSegmentsTask: (0, _emberConcurrency.task)(function* (query) {
      yield (0, _emberConcurrency.timeout)(300);
      let filter = {
        fiscal_year_id: this.availableFiscalYears.current.id,
        segment_type_id: this.segmentTypeId,
        query: {
          fuzzy: query
        }
      };

      if (this.budgetCategory) {
        filter.budget_category = {
          eq: this.budgetCategory
        };
      }

      return this.store.query('segment', {
        filter,
        include: 'segment_type',
        page: {
          size: 6,
          number: 1
        }
      });
    }),
    isSegmentFinderEnabled: Ember.computed.empty('selectedSegment.id'),
    segmentSearchText: Ember.computed('isSegmentFinderEnabled', function () {
      let isEnabled = this.isSegmentFinderEnabled;
      return isEnabled ? 'Type to search' : false;
    }),
    actions: {
      chooseSegment(segmentTypeId, segment) {
        this.update(segment, segmentTypeId);
      }

    }
  });

  _exports.default = _default;
});