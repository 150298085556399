define("balance-ember/components/budget-positions-page/new-position-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qXO3die2",
    "block": "{\"symbols\":[\"data\",\"StackItem\",\"@back\",\"@budget\"],\"statements\":[[8,\"positions-table/form-data\",[],[[\"@budget\"],[[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"shadow-page-container w-full\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"loadTask\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"p-16\"],[12],[2,\"\\n        \"],[8,\"layout/stack\",[],[[\"@space\",\"@align\"],[\"s\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"loading-spinner\",[],[[\"@size\"],[\"xxl\"]],null],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n          \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"global.loading_abbr\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[2]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"positions-table/form\",[],[[\"@position\",\"@allocations\",\"@budgetStrategies\",\"@back\"],[[32,1,[\"position\"]],[32,1,[\"allocations\"]],[32,1,[\"budgetStrategies\"]],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "balance-ember/components/budget-positions-page/new-position-form/template.hbs"
  });

  _exports.default = _default;
});