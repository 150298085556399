define("balance-ember/mixins/account-filter-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    categories: Ember.computed('segmentTypeSubcategories', function () {
      return [{
        value: 'budget_lea_id',
        title: 'Budget Lea',
        type: 'integer',
        removable: false
      }, {
        value: 'segments',
        title: 'Segments',
        icon: 'segment',
        subcategories: this.segmentTypeSubcategories
      }, {
        value: 'amount',
        title: 'Amount',
        icon: 'dollar',
        subcategories: [{
          value: 'budgeted',
          title: 'Current Budget',
          type: 'currency'
        }, {
          value: 'encumbered',
          title: 'Encumbered',
          type: 'currency'
        }, {
          value: 'spent',
          title: 'Spent',
          type: 'currency'
        }, {
          value: 'available',
          title: 'Available',
          type: 'currency'
        }, {
          value: 'available_percentage',
          title: '% Available',
          type: 'percent'
        }, {
          value: 'encumbered_percentage',
          title: '% Encumbered',
          type: 'percent'
        }]
      }];
    })
  });

  _exports.default = _default;
});