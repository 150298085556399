define("balance-ember/components/user/profile-tabs/manage-permissions/form-provider/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/writeable-model", "balance-ember/utils/validators/user"], function (_exports, _component, _emberConcurrency, _writeableModel, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @param {object} user
   * @param {function} afterUndo
   */
  let ManagePermissionsFormProviderComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.sort('availableBudgetLeas.all', 'budgetLeasSort'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    this.validate();

    if (this.errors) {
      return;
    } // Delete all permissions from budgets that have had all access removed or have had all
    // custom permissions removed


    this.data.forEach(budget => {
      if (!budget.hasAccess || !budget.customAccess) {
        Ember.set(budget, 'customPermissions', []);
      }
    }); // Get all the permissions that have values and merge the nested changes. Then unnest the
    // arrays (flatmap). We'll treat all the permissions as new and delete all the old ones.
    // This way we don't have to worry about tracking changes on individual permissions

    let permissions = this.data.mapBy('customPermissions').filter(permission => permission.length).reduce((acc, curr) => acc.concat(curr), []);
    let newPermissionRecords = this.createNewPermissionRecords(permissions);
    this.markExistingPermissionsAsDeleted();
    newPermissionRecords.forEach(permission => {
      this.forkedUser.addManagePermission(permission);
    });
    yield this.forkedUser.save({
      include: ['budget_leas', 'manage_permissions.segments']
    }).then(() => {
      if (this.args.afterSave) {
        this.args.afterSave();
      }
    });
  }).drop(), _dec11 = Ember._action, _dec12 = Ember._action, (_class = class ManagePermissionsFormProviderComponent extends _component.default {
    setupData() {
      let data = this.sortedBudgetLeas.map(budgetLea => {
        let hasAccess = this.forkedUser.budgetLeas.mapBy('model').includes(budgetLea);
        let customPermissions = this.forkedUser.managePermissions.filterBy('budgetLea.id', budgetLea.id);
        let customAccess = customPermissions.length ? true : false;
        return {
          id: budgetLea.id,
          budgetLea,
          user: this.forkedUser,
          hasAccess,
          customAccess,
          customPermissions
        };
      });
      Ember.set(this, 'data', data);
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "availableBudgetLeas", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "network", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _defineProperty(this, "budgetLeasSort", ['fiscalYear:desc']);

      _initializerDefineProperty(this, "sortedBudgetLeas", _descriptor6, this);

      _initializerDefineProperty(this, "data", _descriptor7, this);

      _initializerDefineProperty(this, "forkedUser", _descriptor8, this);

      _initializerDefineProperty(this, "errors", _descriptor9, this);

      _initializerDefineProperty(this, "saveUser", _descriptor10, this);

      this.forkedUser = new _writeableModel.default({
        model: this.args.user,
        store: this.store,
        network: this.network
      });
      this.setupData();
    }

    validate() {
      var _this$validator$valid;

      this.errors = (_this$validator$valid = this.validator.validate()) === null || _this$validator$valid === void 0 ? void 0 : _this$validator$valid.messages;

      if (this.customPermissionErrors) {
        this.errors = this.errors ? this.errors.concat(this.customPermissionErrors) : [this.customPermissionErrors];
      }
    }

    get validator() {
      return new _user.default({
        model: this.forkedUser,
        intl: this.intl
      });
    }

    get customPermissionErrors() {
      return this.data.reduce((acc, curr) => {
        // Make sure each budget with custom permissions has at least one permission
        if (curr.customAccess && !curr.customPermissions.length) {
          acc = 'invalid, custom permissions must have at least one permission';
        }

        return acc;
      }, undefined);
    }

    createNewPermissionRecords(permissions) {
      return permissions.map(permission => {
        return this.store.createRecord('managePermission', {
          budgetLea: permission.budgetLea.model ?? permission.budgetLea,
          segmentType: permission.segmentType,
          codes: permission.segments.mapBy('code'),
          segmentAccess: permission.segmentAccess
        });
      });
    }

    markExistingPermissionsAsDeleted() {
      this.forkedUser.managePermissions.forEach(permission => {
        this.forkedUser.deleteManagePermission(permission);
      });
    }

    cleanupUnpersistedPermissions() {
      let unpersistedRecords = this.forkedUser.managePermissions.rejectBy('id');
      unpersistedRecords.forEach(permission => {
        permission.model.unloadRecord();
      });
    }

    undo() {
      this.cleanupUnpersistedPermissions();

      if (this.args.afterUndo) {
        this.args.afterUndo();
      }
    }

    updateData(id, newData) {
      let item = this.data.findBy('id', id);
      let index = this.data.indexOf(item);
      this.data.replace(index, 1, [newData]);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "availableBudgetLeas", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "network", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "sortedBudgetLeas", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "forkedUser", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "saveUser", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "undo", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "undo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateData", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "updateData"), _class.prototype)), _class));
  _exports.default = ManagePermissionsFormProviderComponent;
});