define("balance-ember/components/manage/accounts-table/component", ["exports", "balance-ember/components/tagless-component/component", "@microstates/ember", "balance-ember/utils/urls", "balance-ember/utils/filters/format-display-text"], function (_exports, _component, _ember, _urls, _formatDisplayText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let printStackClasses = `print:float-left
                         print:clear-left
                         print:avoid-page-break
                         print:p-0
                         print:pl-16
                         print:text-13
                         print:first:pt-8`;

  var _default = _component.default.extend({
    availableBudgetLeas: Ember.inject.service(),
    preferences: Ember.inject.service(),
    features: Ember.inject.service(),
    eventTracking: Ember.inject.service(),
    trackingPrefix: 'Accounts',

    /**
     * @param
     */
    summarizeTableState: null,

    /**
     * @param
     */
    currentBookmark: null,

    /**
     * @param
     */
    categories: null,
    isOriginalBudgetEnabled: Ember.computed.readOnly('features.originalBudget'),
    summarizeState: Ember.computed.readOnly('summarizeTableState.summarize'),
    tableState: Ember.computed.readOnly('summarizeTableState.table'),
    summarizeCategories: Ember.computed('categories.[]', function () {
      let category = this.categories.findBy('value', 'segments');
      if (!category) return [];
      return category.subcategories.filter(category => category.summarizable);
    }),
    columnState: (0, _ember.state)((0, _ember.create)([Object])),
    hiddenColumnPreferences: Ember.computed.readOnly('preferences.accountsHiddenColumns'),
    segmentTypes: Ember.computed.readOnly('selectedBudgetLea.sortedSegmentTypes.[]'),
    selectedBudgetLea: Ember.computed('availableBudgetLeas.all', 'budgetLeaFilter', 'selectedBudgetLeaId', function () {
      return this.availableBudgetLeas.all.findBy('id', this.selectedBudgetLeaId);
    }),
    selectedBudgetLeaId: Ember.computed.reads('budgetLeaFilter.firstOperator.firstFilterValue'),
    budgetLeaFilter: Ember.computed('tableState.filters', function () {
      return [...this.tableState.filters].findBy('category.state', 'budget_lea_id');
    }),
    bodyComponent: Ember.computed('summarizeState.category.keys.length', function () {
      let componentName = this.summarizeState.category.keys.length ? 'summarize' : 'index';
      return `manage/accounts-table/${componentName}`;
    }),
    columns: Ember.computed('amountColumns', 'segmentTypeColumns.[]', function () {
      let allColumns = this.segmentTypeColumns.concat(this.amountColumns);
      return allColumns.map(column => {
        return Object.assign({}, column, {
          canTruncateLabel: true
        });
      });
    }),
    isOriginalBudgetColumnHidden: Ember.computed('preferences.accountsHiddenColumns.[]', function () {
      return this.preferences.accountsHiddenColumns.includes('original budget');
    }),
    amountColumns: Ember.computed('isOriginalBudgetColumnHidden', 'isOriginalBudgetEnabled', function () {
      let width = '150px';
      let align = 'right';
      let columns = [{
        label: 'CURRENT BUDGET',
        valuePath: 'budgeted',
        sortName: 'budgeted',
        testSelector: 'budgeted',
        type: 'tooltip-column',
        tooltipText: 'The total amount that was budgeted for that particular account.',
        cellComponent: 'manage/account-balance-cell',
        cellClassNames: `manage-table-totals ${printStackClasses}`,
        classNames: 'manage-table-totals',
        width,
        align,
        hideable: false,
        ascending: false
      }, {
        label: 'ENCUMBERED',
        valuePath: 'encumbered',
        sortName: 'encumbered',
        testSelector: 'encumbered',
        type: 'tooltip-column',
        tooltipText: 'The funds that have been pre-authorized and set aside but have not been paid yet. Encumbrances count against available funds.',
        cellComponent: 'manage/account-balance-cell',
        cellClassNames: `manage-table-totals ${printStackClasses}`,
        classNames: 'manage-table-totals',
        width,
        align,
        hideable: false,
        ascending: false
      }, {
        label: 'SPENT',
        valuePath: 'spent',
        sortName: 'spent',
        testSelector: 'spent',
        type: 'tooltip-column',
        tooltipText: 'The expenses incurred to date which have been paid out, also known as “Actuals”.',
        cellComponent: 'manage/account-balance-cell',
        cellClassNames: `manage-table-totals ${printStackClasses}`,
        classNames: 'manage-table-totals',
        width,
        align,
        hideable: false,
        ascending: false
      }, {
        label: 'AVAILABLE',
        valuePath: 'available',
        sortName: 'available',
        testSelector: 'available',
        type: 'tooltip-column',
        tooltipText: 'Budgeted amount minus encumbered and spent. The amount left for the rest of the school year.',
        cellComponent: 'manage/account-balance-cell',
        cellClassNames: `manage-table-totals ${printStackClasses}`,
        classNames: 'manage-table-totals',
        width,
        align,
        highlightNegative: true,
        hideable: false,
        ascending: false
      }];

      if (this.isOriginalBudgetEnabled) {
        columns.unshift({
          label: 'ORIGINAL BUDGET',
          valuePath: 'originalBudget',
          sortName: 'original_budget',
          testSelector: 'original-budget',
          type: 'tooltip-column',
          tooltipText: 'The total amount that was initially budgeted for that particular account.',
          cellComponent: 'manage/account-balance-cell',
          cellClassNames: `manage-table-totals ${printStackClasses}`,
          classNames: 'manage-table-totals',
          width,
          align,
          name: 'original budget',
          hidden: this.isOriginalBudgetColumnHidden,
          hideable: true,
          ascending: false
        });
      }

      return columns.map(column => {
        return Object.assign({}, column, {
          cellClassNames: `text-15 ${column.cellClassNames} print:text-13`
        });
      });
    }),
    segmentTypeColumns: Ember.computed('hiddenColumnPreferences', 'segmentTypes.length', 'selectedBudgetLea', function () {
      return this.segmentTypes.map((segmentType, index) => {
        let name = segmentType.name;
        let isLastSegmentType = index === this.segmentTypes.length - 1;
        let paddingRight = isLastSegmentType ? 'pr-16 print:pb-8' : '';
        return {
          label: name.toUpperCase().replace('_', ' '),
          segmentType: name,
          testSelector: name,
          sortName: `segment_${name}`,
          name,
          sortType: 'name',
          component: 'manage/segment-header-dropdown',
          cellComponent: 'manage/segment-cell',
          cellClassNames: `text-15
                         print:text-13
                         ${paddingRight}
                         ${printStackClasses}`,
          classNames: paddingRight,
          hideable: true,
          hidden: this.hiddenColumnPreferences.includes(name)
        };
      });
    }),
    filterSummary: Ember.computed('categories', 'tableState.filters', function () {
      if (this.tableState.filters) {
        return (0, _formatDisplayText.formatFilters)(this.tableState.filters, this.categories);
      }

      return undefined;
    }),

    didReceiveAttrs() {
      this._super();

      this.columnState.set(this.columns);
    },

    downloadUrl: `${(0, _urls.URLForAPIv3)()}/account_balances/downloads`,
    actions: {
      print() {
        this.eventTracking.trackEvent('Prints Data', {
          page: 'Accounts',
          filters: this.filterSummary
        });
        window.print();
      },

      summarize(category) {
        this.tableState.rows.clear();
        this.summarizeState.set({
          category
        });
        this.eventTracking.trackEvent(`Summarize by ${this.trackingPrefix}`, {
          segment: category.title
        });
      },

      clearSummarize() {
        this.tableState.rows.clear();
        this.summarizeState.set({});
      }

    }
  });

  _exports.default = _default;
});