define("balance-ember/components/destroy-allocation-modal/component", ["exports", "balance-ember/components/tagless-component/component", "ember-inflector"], function (_exports, _component, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    allocations: null,
    budget: null,
    // We need to share this modal from different routes that need different re-directs,
    // so we're making this element dynamic.
    redirectRoute: null,

    // This function only needs to be called from the expenses route, so we're defaulting
    // to an empty function here.
    afterDelete() {},

    router: Ember.inject.service(),
    allocationReferent: Ember.computed('allocations.[]', 'budget.id', function () {
      let allocation = 'Allocation';

      if (this.allocations.length > 1) {
        allocation = (0, _emberInflector.pluralize)(allocation);
      }

      if (this.budget.id !== '0') {
        allocation = allocation + ' and Associated Expenses';
      }

      return allocation;
    }),
    warningMessage: Ember.computed('allocations.[]', 'budget.id', function () {
      if (this.allocations.length > 1) {
        return 'Deleting these allocations will permanently remove them from your budget and will delete any expenses you have planned here.';
      }

      if (this.budget.id === '0') {
        return 'Are you sure?  Deleting this allocation will permanently remove it.';
      }

      return 'Deleting this allocation will permanently remove it from your budget and will delete any expenses you have planned here.';
    }),

    // On the budgeting/budget/expenses/view (and /index) route, we've designed the code
    // to load the most recently viewed allocation. But since we are destroying
    // the most recent allocation, we need to null `currentAllocationID` (it will then load
    // the allocation at the top of the list, if any).
    clearStoredAllocation() {
      this.expensesIndexController.set('currentAllocationID', null);
    },

    expensesIndexController: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:budgeting.budget.expenses.index');
    }),
    actions: {
      cancelAllocations() {
        let redirectRoute = this.redirectRoute;

        if (this.redirectRoute === 'expenses') {
          return this.router.transitionTo('budgeting.budget.expenses.view');
        }

        this.router.transitionTo(redirectRoute);
      },

      deleteAllocations() {
        let allocations = this.allocations;
        let destroyPromises = allocations.map(allocation => allocation.destroyRecord());
        let redirectRoute = this.redirectRoute;
        return Ember.RSVP.all(destroyPromises).then(() => {
          this.clearStoredAllocation(); // We need to manually remove the allocations from the budget to prevent them from showing
          // up in the budgets index UI. Eventhough the allocations have `isDeleted: true`. Ember
          // doesn't necessarily know to clean them up from the UI. They will eventually get cleaned
          // up when the budgets index page does a background reload and that API request returns with
          // all the budgets which will have the correct relationships, but the UI will be broken for
          // a few seconds while that API call happens

          this.budget.allocations.removeObjects(allocations);

          if (this.redirectRoute === 'expenses') {
            return this.router.transitionTo('budgeting.budget.expenses', this.budget.id);
          }

          this.router.transitionTo(redirectRoute);
        });
      },

      planExpenses() {
        this.allocations.setEach('expanded', true);
        return this.router.transitionTo('budgeting.budget.expenses', this.budget.id);
      }

    }
  });

  _exports.default = _default;
});