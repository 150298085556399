define("balance-ember/components/budget/supplemental-pay-expense-form/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/writeable-model", "balance-ember/utils/validators/supplemental-pay-expense"], function (_exports, _component, _emberConcurrency, _writeableModel, _supplementalPayExpense) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PAY_TYPE_SUGGESTION_LIMIT = _exports.EMPLOYEE_SUGGESTION_LIMIT = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const EMPLOYEE_SUGGESTION_LIMIT = 6;
  _exports.EMPLOYEE_SUGGESTION_LIMIT = EMPLOYEE_SUGGESTION_LIMIT;
  const PAY_TYPE_SUGGESTION_LIMIT = 6;
  _exports.PAY_TYPE_SUGGESTION_LIMIT = PAY_TYPE_SUGGESTION_LIMIT;
  let BudgetSupplementalPayExpenseFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed.readOnly('availableFiscalYears.current'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    if (this.args.fetchSupplementalPayExpenseTask) {
      return yield this.args.fetchSupplementalPayExpenseTask.perform();
    }

    let supplementalPayExpense = yield this.store.loadRecord('supplementalPayExpense', this.args.supplementalPayExpenseId, {
      reload: true,
      include: ['employee', 'supplemental_pay_type.segment.segment_type', 'supplemental_pay_type.benefits_package.percentage_benefits', 'supplemental_pay_type.benefits_package.flat_rate_benefits', 'allocation.segments.segment_type', 'allocation.budget', 'segments.segment_type', 'expense_strategies.budget_strategy.budget_priority'].join(',')
    });
    this.createWriteableModel(supplementalPayExpense);
    return supplementalPayExpense;
  }), _dec11 = (0, _emberConcurrency.task)(function* (query) {
    yield (0, _emberConcurrency.timeout)(300);
    return this.store.query('employee', {
      filter: {
        fiscal_year_id: {
          eq: this.availableFiscalYears.current.id
        },
        query: {
          fuzzy: query
        }
      },
      page: {
        number: 1,
        size: EMPLOYEE_SUGGESTION_LIMIT
      }
    });
  }), _dec12 = (0, _emberConcurrency.task)(function* (query) {
    yield (0, _emberConcurrency.timeout)(300);
    return this.store.query('supplementalPayType', {
      filter: {
        fuzzy: {
          match: query
        },
        fiscal_year_id: this.availableFiscalYears.current.id
      },
      page: {
        number: 1,
        size: PAY_TYPE_SUGGESTION_LIMIT
      },
      include: 'benefits_package.percentage_benefits,benefits_package.flat_rate_benefits'
    });
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    var _this$validator$valid, _this$args$doneAction, _this$args;

    if (this.args.saveSupplementPayExpense) {
      return yield this.args.saveSupplementPayExpense.perform();
    }

    this.errors = (_this$validator$valid = this.validator.validate()) === null || _this$validator$valid === void 0 ? void 0 : _this$validator$valid.messages;

    if (this.errors) {
      return;
    }

    let supplementalPayExpense = yield this.supplementalPayExpense.save();
    this.modelChanges.addChange({
      id: supplementalPayExpense.id,
      modelType: 'supplementalPayExpense',
      changeType: this.isCreating ? 'create' : 'update'
    });
    (_this$args$doneAction = (_this$args = this.args).doneAction) === null || _this$args$doneAction === void 0 ? void 0 : _this$args$doneAction.call(_this$args);
    yield this.reloadBudget();
    this.goToSupplementalPayExpensesIndex();
  }), _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = class BudgetSupplementalPayExpenseFormComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "network", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor5, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor6, this);

      _initializerDefineProperty(this, "fiscalYear", _descriptor7, this);

      _initializerDefineProperty(this, "supplementalPayExpense", _descriptor8, this);

      _initializerDefineProperty(this, "errors", _descriptor9, this);

      _initializerDefineProperty(this, "fetchSupplementalPayExpenseTask", _descriptor10, this);

      _initializerDefineProperty(this, "fetchEmployeesTask", _descriptor11, this);

      _initializerDefineProperty(this, "fetchSupplementalPayType", _descriptor12, this);

      _initializerDefineProperty(this, "saveSupplementPayExpense", _descriptor13, this);
    }

    createWriteableModel(model) {
      this.supplementalPayExpense = new _writeableModel.default({
        model,
        network: this.network,
        store: this.store,
        allSegmentTypes: this.fiscalYear.segmentTypes
      });
    }

    get isLoading() {
      return this.fetchSupplementalPayExpenseTask.isRunning || Ember.isEmpty(this.supplementalPayExpense);
    }

    goToSupplementalPayExpensesIndex() {
      this.router.transitionTo('budgeting.budget.supplemental-pay-expenses');
    }

    reloadBudget() {
      this.store.peekRecord('budget', this.args.budgetId).reload();
    }

    get validator() {
      return new _supplementalPayExpense.default({
        model: this.supplementalPayExpense,
        intl: this.intl,
        options: {
          numSegments: this.supplementalPayExpense.expenseSegmentTypes.length
        }
      });
    }

    async loadSupplementalPayExpense() {
      if (Ember.isPresent(this.args.supplementalPayExpenseId)) {
        await this.fetchSupplementalPayExpenseTask.perform().catch(e => {
          if (!(0, _emberConcurrency.didCancel)(e)) throw e;
        });
      } else {
        this.createWriteableModel(this.store.createRecord('supplementalPayExpense'));

        if (this.args.allocation) {
          this.supplementalPayExpense.allocation = this.args.allocation;
        }
      }
    }

    deleteStrategy(strategy) {
      this.supplementalPayExpense.deleteExpenseStrategy(strategy);
    }

    cancel() {
      var _this$args$doneAction2, _this$args2;

      if (!this.supplementalPayExpense) this.fetchSupplementalPayExpenseTask.cancelAll();
      this.goToSupplementalPayExpensesIndex();
      (_this$args$doneAction2 = (_this$args2 = this.args).doneAction) === null || _this$args$doneAction2 === void 0 ? void 0 : _this$args$doneAction2.call(_this$args2);
    }

    updateEmployee(employee) {
      if (!employee) {
        this.supplementalPayExpense.disassociateEmployee();
      } else {
        this.supplementalPayExpense.employee = employee;
      }
    }

    updateSupplementalPayType(payType) {
      if (!payType) {
        this.supplementalPayExpense.disassociateSupplementalPayType();
      } else {
        this.supplementalPayExpense.supplementalPayType = payType;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "network", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fiscalYear", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "supplementalPayExpense", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "fetchSupplementalPayExpenseTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "fetchEmployeesTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "fetchSupplementalPayType", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "saveSupplementPayExpense", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadSupplementalPayExpense", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "loadSupplementalPayExpense"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteStrategy", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "deleteStrategy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateEmployee", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "updateEmployee"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSupplementalPayType", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "updateSupplementalPayType"), _class.prototype)), _class));
  _exports.default = BudgetSupplementalPayExpenseFormComponent;
});