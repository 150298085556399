define("balance-ember/components/positions-table/form/expense/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PositionsTableExpandedRowEditExpenseComponent extends _component.default {
    get expense() {
      return this.args.stateMachine.status;
    }

    get errors() {
      if (this.args.stateMachine.errors) {
        return this.args.stateMachine.errors[this.expense.allocation.id];
      }

      return null;
    }

  }

  _exports.default = PositionsTableExpandedRowEditExpenseComponent;
});