define("balance-ember/authenticators/magic", ["exports", "balance-ember/config/environment", "balance-ember/authenticators/devise"], function (_exports, _environment, _devise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const assign = Ember.assign || Ember.merge;
  const JSON_CONTENT_TYPE = 'application/json';

  var _default = _devise.default.extend({
    identificationAttributeName: 'email',
    serverTokenEndpoint: _environment.default.serverTokenEndpoint,

    makeRequest(data, options = {}) {
      // HACK: our token is passed through the first param in the 'email' key.
      let token = data['user']['email'];
      let url = options.url || this.serverTokenEndpoint;
      let requestOptions = {};
      let body = JSON.stringify({
        token: window.base64.decode(token)
      });
      assign(requestOptions, {
        body,
        method: 'POST',
        headers: {
          'accept': JSON_CONTENT_TYPE,
          'content-type': JSON_CONTENT_TYPE
        }
      });
      assign(requestOptions, options || {});
      return fetch(url, requestOptions);
    }

  });

  _exports.default = _default;
});