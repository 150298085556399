define("balance-ember/components/side-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W+8iBk9M",
    "block": "{\"symbols\":[\"@space\",\"&default\"],\"statements\":[[8,\"layout/stack\",[],[[\"@space\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"button\",\"DetailButton\"],[[30,[36,0],[\"side-nav/button\"],[[\"space\"],[[32,1]]]],[30,[36,0],[\"side-nav/detail-button\"],[[\"space\"],[[32,1]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/side-nav/template.hbs"
  });

  _exports.default = _default;
});