define("balance-ember/components/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ejVBSkEM",
    "block": "{\"symbols\":[\"@disabled\",\"&attrs\",\"&default\"],\"statements\":[[6,[37,4],[[32,0,[\"isLink\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[16,6,[32,0,[\"href\"]]],[16,0,[31,[[32,0,[\"commonClasses\"]],\"\\n    \",[32,0,[\"style\"]],\"\\n    \",[32,0,[\"textSizeClasses\"]],\"\\n    \",[32,0,[\"paddingClasses\"]]]]],[16,\"disabled\",[32,1]],[16,\"data-is-active\",[32,0,[\"isActive\"]]],[16,\"data-outline-hidden\",[32,0,[\"outlineHidden\"]]],[16,\"onmousedown\",[30,[36,0],[[32,0],\"mousedown\"],null]],[16,\"onblur\",[30,[36,0],[[32,0],\"blur\"],null]],[17,2],[4,[38,1],[\"click\",[32,0,[\"transition\"]]],null],[12],[2,\"\\n    \"],[18,3,[[30,[36,3],[[32,0,[\"styles\"]],[30,[36,2],null,[[\"isActive\"],[[32,0,[\"isActive\"]]]]]],null]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[31,[[32,0,[\"commonClasses\"]],\" \",[32,0,[\"style\"]],\" \",[32,0,[\"textSizeClasses\"]],\" \",[32,0,[\"paddingClasses\"]]]]],[16,\"disabled\",[32,1]],[16,\"data-outline-hidden\",[32,0,[\"outlineHidden\"]]],[16,\"data-is-active\",[32,0,[\"isActive\"]]],[16,\"onmousedown\",[30,[36,0],[[32,0],\"mousedown\"],null]],[16,\"onblur\",[30,[36,0],[[32,0],\"blur\"],null]],[17,2],[16,4,[32,0,[\"type\"]]],[4,[38,1],[\"click\",[30,[36,0],[[32,0],[32,0,[\"onclick\"]]],null]],null],[12],[2,\"\\n    \"],[18,3,[[30,[36,3],[[32,0,[\"styles\"]],[30,[36,2],null,[[\"isActive\"],[[32,0,[\"isActive\"]]]]]],null]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"on\",\"hash\",\"merge\",\"if\"]}",
    "moduleName": "balance-ember/components/button/template.hbs"
  });

  _exports.default = _default;
});