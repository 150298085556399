define("balance-ember/components/checkbox/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let CheckboxComponent = (_dec = Ember._action, (_class = class CheckboxComponent extends _component.default {
    onupdate(checked) {
      if (this.args.onupdate) {
        this.args.onupdate(checked);
      }
    }

    get checked() {
      return Ember.isBlank(this.args.checked) ? false : this.args.checked;
    }

    get backgroundColor() {
      if (this.args.disabled) {
        return 'bg-material-gray400';
      }

      return this.checked ? 'bg-material-light-blue600' : 'bg-white';
    }

    inputClick(evt) {
      evt.stopPropagation();
      this.onupdate(evt.target.checked);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "inputClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "inputClick"), _class.prototype)), _class));
  _exports.default = CheckboxComponent;
});