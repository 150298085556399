define("balance-ember/components/destroy-position-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DJcBRN4I",
    "block": "{\"symbols\":[\"@position\"],\"statements\":[[8,\"modal/destroy\",[],[[\"@closeModal\",\"@destroy\",\"@model\",\"@text\"],[[32,0,[\"cancel\"]],[32,0,[\"destroyPosition\"]],[32,1],\"Are you sure you’d like to delete this position? Associated position expenses will also be deleted.\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/destroy-position-modal/template.hbs"
  });

  _exports.default = _default;
});