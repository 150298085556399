define("balance-ember/helpers/computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bound = bound;

  // Helper to return a bound version of the passed in function
  function bound(f) {
    return Ember.computed(function () {
      // Core assumption here that it'll only get called once, for example adding/removing event listeners based on function identity - GT
      // If that's not good enough, a path forward might be gensymming an attribute for every call
      return f.bind(this);
    });
  }
});