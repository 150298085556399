define("balance-ember/components/form/label-group/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8G9tvRsE",
    "block": "{\"symbols\":[\"@uniqueId\",\"&attrs\",\"&default\"],\"statements\":[[11,\"label\"],[16,\"for\",[32,1]],[17,2],[12],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/form/label-group/label/template.hbs"
  });

  _exports.default = _default;
});