define("balance-ember/routes/budgeting/supplemental-pay-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      pay_type_id: id
    }) {
      return this.store.loadRecord('supplementalPayType', id, {
        include: 'segment,benefits_package'
      });
    }

  });

  _exports.default = _default;
});