define("balance-ember/components/budget/expense-table/edit-strategies/percentage-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sCGvaIor",
    "block": "{\"symbols\":[\"group\",\"@expenseStrategy\",\"@index\",\"@expense\"],\"statements\":[[8,\"form/label-group\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"label\"]],[[24,0,\"sr-only\"]],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,2],[\"budget.non_personnel_expense_form.strategies.percentage\"],null]],[2,\" \"],[1,[32,3]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,4,[\"isEditable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"one-way-percentage-mask\",[[24,0,\"css-input-field\"]],[[\"@value\",\"@update\"],[[32,2,[\"percentage\"]],[30,[36,1],[[32,2],\"percentage\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,2,[\"percentage\"]]],[[\"format\"],[\"0,0\"]]]],[2,\"%\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\",\"set\",\"t\",\"if\"]}",
    "moduleName": "balance-ember/components/budget/expense-table/edit-strategies/percentage-input/template.hbs"
  });

  _exports.default = _default;
});