define("balance-ember/components/filter-list/completed-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OMwwBEHR",
    "block": "{\"symbols\":[\"&default\",\"@trackingPrefix\",\"@filter\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isBoxStyle\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"selected-box\",[],[[\"@onDeselectClick\",\"@selected\"],[[32,0,[\"removeFilter\"]],[32,0,[\"displayText\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,1,[[32,0,[\"categoryConfig\"]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"filter-list/completed-filter/pill\",[],[[\"@categoryToEdit\",\"@displayText\",\"@removeFilter\",\"@updateFilter\",\"@trackingPrefix\",\"@filter\",\"@categoryConfig\"],[[32,0,[\"categoryToEdit\"]],[32,0,[\"displayText\"]],[32,0,[\"removeFilter\"]],[32,0,[\"updateFilter\"]],[32,2],[32,3],[32,0,[\"categoryConfig\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,1,[[32,0,[\"categoryConfig\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,0,[\"displayText\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/filter-list/completed-filter/template.hbs"
  });

  _exports.default = _default;
});