define("balance-ember/routes/budgeting/budget/expenses/view/edit-non-personnel-expense", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      non_personnel_expense_id: id
    }) {
      return this.store.query('nonPersonnelExpense', {
        extra_fields: {
          non_personnel_expenses: 'amount'
        },
        filter: {
          id
        },
        include: ['segments.segment_type', 'allocation', 'notes', 'vendor'].join(',')
      }).then(expenses => expenses.firstObject);
    },

    afterModel(model) {
      // We don't want users going to the modal as their first route, so we'll redirect them to the
      // expenses page. This way all the data will be loaded. We can check this by seeing if
      // the allocations segments have been loaded yet, because they won't be if we're hard
      // reloading on this page
      if (!model.allocation.hasMany('segments').value()) {
        this.replaceWith('budgeting.budget.expenses');
      }
    }

  });

  _exports.default = _default;
});