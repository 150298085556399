define("balance-ember/utils/manage/account-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AccountFilter {
    constructor({
      segmentTypeSubcategories,
      isOriginalBudgetEnabled
    }) {
      this.segmentTypeSubcategories = segmentTypeSubcategories;
      this.isOriginalBudgetEnabled = isOriginalBudgetEnabled;
    }

    get categories() {
      let categories = [{
        value: 'budget_lea_id',
        title: 'Budget Lea',
        type: 'integer',
        removable: false
      }, {
        value: 'segments',
        title: 'Segments',
        icon: 'segment',
        subcategories: this.segmentTypeSubcategories
      }, {
        value: 'amount',
        title: 'Amount',
        icon: 'dollar',
        subcategories: [{
          value: 'budgeted',
          title: 'Current Budget',
          type: 'currency'
        }, {
          value: 'encumbered',
          title: 'Encumbered',
          type: 'currency'
        }, {
          value: 'spent',
          title: 'Spent',
          type: 'currency'
        }, {
          value: 'available',
          title: 'Available',
          type: 'currency'
        }, {
          value: 'available_percentage',
          title: '% Available',
          type: 'percent'
        }, {
          value: 'encumbered_percentage',
          title: '% Encumbered',
          type: 'percent'
        }]
      }];
      let amounts = categories.find(({
        value
      }) => value === 'amount');

      if (this.isOriginalBudgetEnabled) {
        amounts.subcategories.unshift({
          value: 'original_budget',
          title: 'Original Budgeted',
          type: 'currency'
        });
      }

      return categories;
    }

  }

  _exports.default = AccountFilter;
});