define("balance-ember/components/ui-tabs/button-tab/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "StjhPqQG",
    "block": "{\"symbols\":[\"@active\",\"@onclick\",\"@testId\",\"&attrs\",\"&default\"],\"statements\":[[8,\"button\",[[16,0,[31,[\"\\n    py-10\\n    px-16\\n    -mb-px\\n    mr-10\\n    rounded\\n    rounded-b-none\\n    \",[30,[36,0],[[32,1],\"shadow-container\"],null],\"\\n    \",[30,[36,0],[[32,1],\"bg-white\"],null],\"\\n    \",[30,[36,0],[[32,1],\"relative\"],null],\"\\n  \"]]],[17,4]],[[\"@style\",\"@size\",\"@active\",\"@onclick\",\"@testId\"],[\"none\",\"none\",[32,1],[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,5,null],[2,\"\\n\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"absolute\\n        h-12\\n        mr-10\\n        bg-white\\n        css-bottom-shadow-blocker\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/ui-tabs/button-tab/template.hbs"
  });

  _exports.default = _default;
});