define("balance-ember/components/report-export/component", ["exports", "balance-ember/components/tagless-component/component", "ember-window-mock", "balance-ember/utils/urls"], function (_exports, _component, _emberWindowMock, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    ajax: Ember.inject.service(),
    timeZone: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    actions: {
      export(format, reportName) {
        let authString = this.ajax.getAuthString();
        let fiscalYearId = this.availableFiscalYears.current.id;
        let timeZone = this.timeZone.toParam;
        _emberWindowMock.default.location.href = `${(0, _urls.URLForBudgetNamespace)()}/fiscal_years/${fiscalYearId}/reports/${reportName}/export/${format}${authString}&${timeZone}`;
      }

    }
  });

  _exports.default = _default;
});