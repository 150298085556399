define("balance-ember/components/manage/accounts-table/summarize/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h8E4Tirf",
    "block": "{\"symbols\":[\"Body\",\"@summarizeTableState\",\"@summarizeCategories\",\"@trackingPrefix\",\"@onClearSummarize\",\"@onSummarize\"],\"statements\":[[8,\"manage/print-header/summarize\",[],[[\"@modelName\",\"@tableState\",\"@grandTotals\",\"@summarizeTableState\",\"@categories\"],[\"accounts\",[32,0,[\"tableState\"]],[32,0,[\"grandTotals\"]],[32,2],[32,3]]],null],[2,\"\\n\\n\"],[8,\"manage/summarize/data-loader\",[],[[\"@summarizeTableState\",\"@loadTask\",\"@loadChildrenTask\",\"@categories\"],[[32,2],[32,0,[\"loadTask\"]],[32,0,[\"loadChildrenTask\"]],[32,3]]],null],[2,\"\\n\\n\"],[8,\"table/body\",[],[[\"@tableState\",\"@columns\",\"@loadTask\",\"@trackingPrefix\",\"@height\",\"@estimatedRowHeight\",\"@occlusion\",\"@extra\",\"@rowTestSelector\",\"@rowBorderWidth\",\"@headComponent\",\"@headerColor\",\"@noDataIcon\"],[[32,0,[\"summarizeTableState\",\"table\"]],[32,0,[\"summarizeColumns\"]],[32,0,[\"loadTask\"]],[32,4],[30,[36,0],[\"calc(100vh - \",[32,0,[\"navHeight\"]],\")\"],null],72,true,[30,[36,1],null,[[\"loadTask\",\"grandTotals\",\"summarizeState\",\"summarizeCategories\",\"onSummarize\",\"onClearSummarize\",\"headerDropdownHoverColor\",\"headerDropdownActiveColor\"],[[32,0,[\"loadTask\"]],[32,0,[\"grandTotals\"]],[32,0,[\"summarizeTableState\",\"summarize\"]],[32,3],[32,6],[32,5],\"material-cyan50\",\"material-cyan600\"]]],\"account-row\",0,\"manage/accounts-table/summarize/header\",\"white\",\"na-fiscal-year\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"loader\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Loading Accounts...\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"hash\"]}",
    "moduleName": "balance-ember/components/manage/accounts-table/summarize/template.hbs"
  });

  _exports.default = _default;
});