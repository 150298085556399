define("balance-ember/routes/users/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UsersManageProfileRoute = Ember.Route.extend({
    session: Ember.inject.service(),

    activate() {
      return document.title = 'User Profile - Allovue Balance';
    },

    model({
      tab
    }) {
      return Ember.RSVP.hash({
        user: this.store.findRecord('user', this.session.currentUser.id, {
          reload: true,
          include: 'budget_leas,manage_permissions.budget_lea,manage_permissions.segments'
        }),
        tab
      });
    }

  });
  var _default = UsersManageProfileRoute;
  _exports.default = _default;
});