define("balance-ember/components/percentage-bar/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OVERFLOW = _exports.SIZES = void 0;
  const SIZES = Object.freeze({
    'sm': 'h-6',
    'md': 'h-16',
    'lg': 'h-24'
  });
  _exports.SIZES = SIZES;
  const OVERFLOW = Object.freeze({
    color: 'bg-material-red300',
    border: 'border-material-red600'
  });
  _exports.OVERFLOW = OVERFLOW;

  var _default = _component.default.extend({
    /**
     * Each bar in the array will be a bar of progress
     */
    bars: null,
    style: 'sm',
    overflowColor: OVERFLOW.color,
    overflowBorderColor: OVERFLOW.border,

    /**
     * isDistribution - If the bar should be treated not as a progress bar but to show the
     * distribution of some items
     */
    isDistribution: Ember.computed('style', function () {
      return this.style.includes('distribution');
    }),
    backgroundColor: 'bg-white',
    borderColor: 'border-transparent',
    size: Ember.computed('style', function () {
      let match = this.style.match(/sm|md|lg/);
      return match ? match[0] : 'sm';
    }),
    sizeClasses: Ember.computed('size', 'style', function () {
      return SIZES[this.size];
    }),
    borderRadiusClasses: Ember.computed('size', 'style', function () {
      if (this.size === 'sm') {
        return 'rounded-full';
      }

      return 'rounded';
    }),
    leftRadiusClass: Ember.computed('borderRadiusClasses', function () {
      return this.borderRadiusClasses.replace('rounded', 'rounded-l');
    }),
    rightRadiusClass: Ember.computed('borderRadiusClasses', function () {
      return this.borderRadiusClasses.replace('rounded', 'rounded-r');
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'bars', this.bars || []);
    },

    sanitizedBars: Ember.computed.map('bars', function (bar) {
      return Object.assign({}, bar, {
        // Make sure percentage is the right type
        percentage: Number(bar.percentage)
      });
    }),
    percentages: Ember.computed.mapBy('sanitizedBars', 'percentage'),
    totalComplete: Ember.computed.sum('percentages'),
    isOverflowing: Ember.computed.gt('totalComplete', 100),
    isComplete: Ember.computed.equal('totalComplete', 100),
    barsWithFiller: Ember.computed('backgroundColor', 'borderColor', 'isComplete', 'isOverflowing', 'overflowBorderColor', 'overflowColor', 'sanitizedBars.@each.percentage', function () {
      let total = 0; // Remove percentages that put us over 100

      let bars = this.sanitizedBars.reduce((acc, curr) => {
        total += curr.percentage;
        return total > 100 ? acc : acc.concat(curr);
      }, []); // Create a filler bar

      let newTotal = bars.mapBy('percentage').reduce((acc, curr) => acc + curr, 0);
      let remainingAmount = 100 - newTotal;
      let isOverflowBorder = this.isOverflowing && this.borderColor !== 'border-transparent';
      let fillerBar = {
        percentage: remainingAmount,
        color: this.isOverflowing ? this.overflowColor : this.backgroundColor,
        border: isOverflowBorder ? this.overflowBorderColor : this.borderColor
      }; // Add remaining amount under 100 to take up extra space

      let needsFiller = !this.isComplete;
      return needsFiller ? bars.concat(fillerBar) : bars;
    }),
    leftBorderBar: Ember.computed('barsWithFiller.[]', function () {
      return this.barsWithFiller.find(bar => bar.percentage > 0);
    }),
    rightBorderBar: Ember.computed('barsWithFiller.[]', function () {
      return [...this.barsWithFiller].reverse().find(bar => bar.percentage > 0);
    }),

    barInnerRadius(bar) {
      if (bar.percentage === 0) {
        return false;
      }

      if (this.isDistribution) {
        return false;
      }

      if (this.barShouldHaveRightBorder(bar)) {
        return false;
      }

      return true;
    },

    barBorderColorClass(bar) {
      return bar.border ? bar.border : this.borderColor;
    },

    barShouldHaveLeftBorder(bar) {
      return bar === this.leftBorderBar;
    },

    barShouldHaveRightBorder(bar) {
      return bar === this.rightBorderBar;
    },

    barBorderClasses(bar) {
      if (bar.percentage === 0) {
        return 'invisible';
      } // Everything gets top and bottom border


      let borderClasses = 'border-t border-b';

      if (this.barShouldHaveLeftBorder(bar)) {
        borderClasses = `${borderClasses} border-l`;
      }

      if (this.barShouldHaveRightBorder(bar)) {
        borderClasses = `${borderClasses} border-r`;
      }

      return borderClasses;
    },

    barBorderRadiusClasses(bar) {
      let radiusClasses = '';

      if (this.barShouldHaveLeftBorder(bar)) {
        radiusClasses = `${radiusClasses} ${this.leftRadiusClass}`;
      }

      if (this.barShouldHaveRightBorder(bar)) {
        radiusClasses = `${radiusClasses} ${this.rightRadiusClass}`;
      }

      return radiusClasses;
    },

    barsWithStyle: Ember.computed.map('barsWithFiller', function (bar) {
      return Object.assign({}, bar, {
        inlineStyle: Ember.String.htmlSafe(`width: ${bar.percentage}%;`),
        innerRadius: this.barInnerRadius(bar),
        borderColorClass: this.barBorderColorClass(bar),
        borderClasses: this.barBorderClasses(bar),
        borderRadiusClasses: this.barBorderRadiusClasses(bar)
      });
    })
  });

  _exports.default = _default;
});