define("balance-ember/components/manage/summarize/remove-segment-prefix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = removeSegmentPrefix;

  function removeSegmentPrefix(categoryValue) {
    let value = categoryValue;
    let parts = value.split('_');
    parts = parts.length > 1 && parts[0] === 'segment' ? parts.slice(1) : parts;
    return parts.join('_');
  }
});