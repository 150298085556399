define("balance-ember/templates/resource-versions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8YAVd77Y",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"my-12 ml-12\"],[12],[2,\"Change History\"],[13],[2,\"\\n\"],[8,\"resource-versions\",[],[[\"@versions\"],[[32,0,[\"model\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/resource-versions.hbs"
  });

  _exports.default = _default;
});