define("balance-ember/components/edit-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B/LmkK9i",
    "block": "{\"symbols\":[\"@disabled\",\"@buttonText\",\"@tooltipText\",\"@showButtonText\",\"@icon\",\"@displayText\",\"@click\",\"@testId\"],\"statements\":[[8,\"icon-button\",[],[[\"@style\",\"@disabled\",\"@tooltipTitle\",\"@showButtonText\",\"@icon\",\"@buttonText\",\"@displayText\",\"@onclick\",\"@testId\"],[\"btn-subtle-link\",[32,1],[30,[36,2],[[32,3],[30,[36,1],[[30,[36,0],[\"global.buttons.edit\"],null],\" \",[32,2]],null]],null],[32,4],[30,[36,2],[[32,5],\"edit\"],null],[30,[36,1],[[30,[36,0],[\"global.buttons.edit\"],null],\" \",[32,2]],null],[32,6],[32,7],[32,8]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"concat\",\"or\"]}",
    "moduleName": "balance-ember/components/edit-button/template.hbs"
  });

  _exports.default = _default;
});