define("balance-ember/components/account-amounts-donut-data/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/percentages"], function (_exports, _component, _percentages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let colors = {
    'material-red100': '#FFEBEE',
    'material-red500': '#EF5350',
    'material-green350': '#8BC34A',
    'material-blue300': '#64B5F6',
    'material-purple300': '#BA68C8'
  };
  /**
   * @param {number} spent
   * @param {number} available
   * @param {number} encumbered
   */

  var _default = _component.default.extend({
    isBudgetedPositive: Ember.computed.gt('budgeted', 0),
    amountsList: Ember.computed('spent', 'encumbered', 'available', function () {
      return [this.spent, this.encumbered, this.available];
    }),
    donutTitle: Ember.computed('available', 'isBudgetedPositive', 'amountsList.[]', function () {
      let [,, availablePercent] = (0, _percentages.toPercentageList)(this.amountsList);
      let available = this.available;

      if (!availablePercent) {
        return '';
      }

      let title;

      if (this.isBudgetedPositive) {
        title = available <= 0 ? `<tspan class='text-2xl fill-overdrawn'>${Math.abs(availablePercent)}%</tspan><br/> <tspan class='text-xs fill-gray'>Overdrawn</tspan>` : `<tspan class='text-2xl fill-remaining'>${availablePercent}%</tspan><br/> <tspan class='text-xs fill-gray'>Remaining</tspan>`;
      } else {
        title = '<tspan class=\'text-2xl fill-infinity\'>N/A</tspan><br/> <tspan class=\'text-xs fill-gray\'>Nothing</tspan><br/> <tspan class=\'text-xs fill-gray\'>Budgeted</tspan>';
      }

      return title;
    }),
    series: Ember.computed('amountsList.[]', 'isBudgetedPositive', 'isDeficitTotals', function () {
      let [spent, encumbered, available] = (0, _percentages.toPercentageList)(this.amountsList);
      let remainder = 100 - Math.abs(available);

      if (this.isDeficitTotals) {
        if (!this.isBudgetedPositive) {
          available = 0;
        }

        return [{
          name: 'Account balance',
          data: [{
            name: 'Remaining',
            y: Math.abs(available),
            color: colors['material-red500'],
            dataLabels: {
              className: 'available-donut-label'
            }
          }, {
            name: 'Foo',
            y: remainder,
            color: colors['material-red100']
          }]
        }];
      }

      return [{
        name: 'Account balance',
        data: [{
          name: 'Remaining',
          y: available,
          color: colors['material-green350'],
          dataLabels: {
            className: 'available-donut-label'
          }
        }, {
          name: 'Encumbered',
          y: encumbered,
          color: colors['material-blue300'],
          dataLabels: {
            className: 'encumbered-donut-label'
          }
        }, {
          name: 'Spent',
          y: spent,
          color: colors['material-purple300'],
          dataLabels: {
            className: 'spent-donut-label'
          }
        }]
      }];
    })
  });

  _exports.default = _default;
});