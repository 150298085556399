define("balance-ember/utils/manage/current-bookmark-finder", ["exports", "balance-ember/utils/manage/bookmark-query-builder", "lodash.isequal", "lodash.clonedeep", "balance-ember/utils/query-params"], function (_exports, _bookmarkQueryBuilder, _lodash, _lodash2, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CurrentBookmarkFinder {
    constructor(bookmarks = [], serializedTableState, serializedSummarizeState) {
      this.bookmarks = bookmarks;
      this.serializedTableState = serializedTableState;
      this.serializedSummarizeState = serializedSummarizeState;
    }

    find() {
      return this.bookmarks.find(bookmark => this.doesBookmarkMatchTableState(bookmark));
    }

    doesBookmarkMatchTableState(bookmark) {
      let normalizedBookmark = bookmark.params ? this.normalizeBookmarkParams(bookmark.params) : null;
      let normalizedTableState = this.normalizeBookmarkParams(this.bookmarkableTableState);
      return (0, _lodash.default)(normalizedBookmark, normalizedTableState);
    }

    get bookmarkableTableState() {
      return new _bookmarkQueryBuilder.default(this.serializedTableState, this.serializedSummarizeState).build();
    }

    normalizeBookmarkParams(rawParams) {
      let niceParams = (0, _lodash2.default)(rawParams);

      if (niceParams.filter) {
        // migrated v2 bookmarks have a budget_lea_id, which we don't want
        if (niceParams.filter.budget_lea_id) {
          delete niceParams.filter.budget_lea_id;
        } // traverse every part of the filter to standardize values that were mutated by tableState


        Object.keys(niceParams.filter).forEach(obj => {
          const thisFilter = Object.entries(niceParams.filter[obj])[0];
          niceParams.filter[obj][thisFilter[0]] = this.traverseFilter(thisFilter[0], thisFilter[1]);
        });
      }

      return niceParams;
    }

    traverseFilter(operator, value) {
      let result;

      switch (operator) {
        case 'eq_group':
          // contains an object that needs to be traversed on its own
          result = this.unwrapObject(value);
          break;

        case 'match':
        case 'not_match':
          // trim spaces from array's string values. if the array length is 1, recast as a string
          if (Array.isArray(value)) {
            let tidyArray = value.map(val => this.enforceTidyString(val));
            result = this.maybeUnwrapArray(tidyArray);
          } else {
            result = this.enforceTidyString(value);
          }

          break;

        case 'eq':
        case 'gte':
        case 'lte':
          // typically number-as-string. cast to string & trim spaces
          result = this.enforceTidyString(value);
          break;

        default:
          result = value;
      }

      return result;
    }

    enforceTidyString(value) {
      let tidyValue = typeof value === 'string' ? value : value.toString();

      try {
        // Values passed through here may not already be URIencoded, depending
        // on whether they came from the tableState (encoded) or the bookmark
        // (not encoded). Try to unencode, so values aren't double-encoded, and
        // so encoded trailing spaces can be removed with trim().
        tidyValue = _queryParams.default.decodePart(tidyValue);
      } catch (error) {
        // If encountering an unencoded string with a % in it, catch the
        // resulting error and continue with tidyValue unchanged
        console.warn(`'${tidyValue}' is not a URIencoded string`);
      }

      return _queryParams.default.encodePart(tidyValue.trim());
    }

    maybeUnwrapArray(value) {
      return value.length > 1 ? value : value[0];
    }

    unwrapObject(obj) {
      const objectParts = Object.entries(obj)[0];
      const niceObj = {};
      niceObj[objectParts[0]] = this.traverseFilter(objectParts[0], objectParts[1]);
      return niceObj;
    }

  }

  _exports.default = CurrentBookmarkFinder;
});