define("balance-ember/components/budget/reports/planned-purchases/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LGaAWUp6",
    "block": "{\"symbols\":[\"@export\"],\"statements\":[[10,\"div\"],[14,0,\"flex justify-between\"],[12],[2,\"\\n  \"],[8,\"budget-tab-title\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"budget.reports.types.planned_purchases\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n   \"],[8,\"budget/reports/export-button\",[],[[\"@reportName\",\"@export\"],[\"planned-purchases\",[32,1]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mt-16\"],[12],[2,\"\\n  \"],[8,\"budget/reports/planned-purchases/table\",[],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "balance-ember/components/budget/reports/planned-purchases/template.hbs"
  });

  _exports.default = _default;
});