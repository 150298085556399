define("balance-ember/components/selected-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0K1LWb6G",
    "block": "{\"symbols\":[\"@onDeselectClick\",\"@selected\",\"&default\",\"@disabled\"],\"statements\":[[10,\"div\"],[15,0,[32,0,[\"boxClasses\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex items-center justify-between\"],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n\\n\"],[2,\"    \"],[10,\"input\"],[14,0,\"hidden\"],[14,\"tabindex\",\"-1\"],[12],[13],[2,\"\\n\"],[6,[37,1],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"icon-button\",[],[[\"@onclick\",\"@style\",\"@size\",\"@icon\",\"@buttonText\"],[[30,[36,0],[[32,0],[32,1]],null],\"btn-primary-link\",\"xlg\",\"cancel-small-x\",[31,[\"Deselect \",[32,2]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"unless\"]}",
    "moduleName": "balance-ember/components/selected-box/template.hbs"
  });

  _exports.default = _default;
});