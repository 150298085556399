define("balance-ember/components/destroy-position-modal/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Destory a position with this modal.
   *
   * @param {object} position The position to destroy
   * @param {action} onClose What to do when the user presses 'cancel'
   * @param {action} onDestroy What to do when the user presses 'delete'
   */
  let DestroyPositionModalComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class DestroyPositionModalComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modelChanges", _descriptor, this);
    }

    async destroyPosition() {
      var _this$args$position$h;

      let positionExpenses = ((_this$args$position$h = this.args.position.hasMany('positionExpenses').value()) === null || _this$args$position$h === void 0 ? void 0 : _this$args$position$h.toArray()) ?? [];
      await this.args.position.destroyRecord(); // Unload positionExpenses from the store otherwise we end up with orphaned models hanging around

      positionExpenses.forEach(positionExpense => positionExpense.unloadRecord());
      this.modelChanges.addChange({
        id: this.args.position.id,
        modelType: 'position',
        changeType: 'delete'
      });
      this.args.close();
    }

    cancel() {
      this.args.close();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "destroyPosition", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "destroyPosition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class));
  _exports.default = DestroyPositionModalComponent;
});