define("balance-ember/models/segment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    allocation: (0, _model.hasMany)('allocation', {
      async: false
    }),
    segmentType: (0, _model.belongsTo)('segmentType', {
      async: false
    }),
    name: (0, _model.attr)('string'),
    code: (0, _model.attr)('string'),
    budgetCategory: (0, _model.attr)('string', {
      defaultValue: 'any'
    }),
    segmentTypeName: Ember.computed.readOnly('segmentType.name'),
    segmentTypeDisplayOrder: Ember.computed.readOnly('segmentType.displayOrder'),
    deletable: (0, _model.attr)('boolean', {
      readOnly: true
    }),
    personnelSegment: Ember.computed.or('segmentType.benefitSegment', 'segmentType.salarySegment'),

    toJSON() {
      let {
        name,
        code
      } = this;
      return {
        name,
        code
      };
    }

  });

  _exports.default = _default;
});