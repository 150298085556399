define("balance-ember/models/user", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.computedProperties = _exports.schema = void 0;
  const schema = {
    budgetLeas: (0, _model.hasMany)('budgetLea', {
      async: false
    }),
    managePermissions: (0, _model.hasMany)('managePermissions', {
      async: false
    }),
    budgetPrivileges: (0, _model.hasMany)('budgetPrivileges', {
      async: false
    }),
    preferences: (0, _model.belongsTo)('userPreference', {
      async: false
    }),
    email: (0, _model.attr)('string'),
    admin: (0, _model.attr)('boolean'),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    name: (0, _model.attr)('string', {
      readOnly: true
    }),
    uuid: (0, _model.attr)('string'),
    budgetArchitect: (0, _model.attr)('boolean'),
    hasBudgetingAccess: (0, _model.attr)('boolean', {
      readOnly: true
    }),
    hasManageAccess: (0, _model.attr)('boolean', {
      readOnly: true
    }),
    canManageDistrictBookmarks: (0, _model.attr)('boolean'),
    currentSignInAt: (0, _model.attr)(),
    isLocked: (0, _model.attr)('boolean', {
      readOnly: true
    }),
    isConfirmed: (0, _model.attr)('boolean', {
      readOnly: true
    }),
    confirmationSentAt: (0, _model.attr)(),
    softDeletedAt: (0, _model.attr)(),
    districtRoles: (0, _model.attr)('array')
  };
  _exports.schema = schema;
  const computedProperties = {
    initials: Ember.computed('firstName', 'lastName', function () {
      let firstInitial = this.firstName || '😀';
      let lastInitial = this.lastName || '';
      return `${firstInitial.charAt(0)}${lastInitial.charAt(0)}`;
    }),
    isBudgetArchitect: Ember.computed.readOnly('budgetArchitect'),
    isAdmin: Ember.computed.readOnly('admin'),
    isPurchasingApprover: Ember.computed('budgetPrivileges', function () {
      return this.budgetPrivileges.mapBy('role').includes('purchasing_approver');
    })
  };
  _exports.computedProperties = computedProperties;

  var _default = _model.default.extend(schema, computedProperties);

  _exports.default = _default;
});