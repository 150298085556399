define("balance-ember/components/layout/inline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LR9u5rCG",
    "block": "{\"symbols\":[\"@align\",\"@space\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"flex items-\",[32,0,[\"align\"]],\" justify-\",[32,0,[\"alignX\"]]]]],[12],[2,\"\\n  \"],[18,3,[[30,[36,0],[\"layout/inline/item\"],[[\"space\",\"align\"],[[32,2],[32,1]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "moduleName": "balance-ember/components/layout/inline/template.hbs"
  });

  _exports.default = _default;
});