define("balance-ember/utils/validatejs/validators/vendor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(value, options, key, attributes) {
    if (value === 'other-vendor' && !attributes.vendor) {
      return 'budget.non_personnel_expense_form.vendor.validation_invalid';
    }
  }
});