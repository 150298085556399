define("balance-ember/components/allocation-totals/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['allocation-totals'],
    totalAllocated: Ember.computed.readOnly('allocation.allocatedAmount'),
    totalPlanned: Ember.computed.readOnly('allocation.plannedAmount'),
    totalRemaining: Ember.computed.readOnly('allocation.remainingAmount'),
    totalLabel: Ember.computed('useTotalTextInLabels', function () {
      return this.useTotalTextInLabels ? 'Total' : '';
    })
  });

  _exports.default = _default;
});