define("balance-ember/templates/users/resend-unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CQM9LSVg",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[8,\"login-flow-layout\",[],[[\"@title\",\"@success\"],[\"Resend Account Unlock\",[32,0,[\"success\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"errorMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flash flash-errors\"],[12],[2,\"\\n      \"],[10,\"ul\"],[12],[2,\"\\n        \"],[10,\"li\"],[12],[1,[32,0,[\"errorMessage\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"instructions\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Enter your district email address and we'll send you a link to unlock access to your account.\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"ui-form\",[],[[\"@submitTask\"],[[32,0,[\"resendUnlock\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,3],[[32,1,[\"input\"]],[32,0,[\"identification\"]]],[[\"placeholder\",\"update\",\"class\",\"type\"],[\"District email address\",[30,[36,2],[[32,0],[30,[36,1],[[32,0,[\"identification\"]]],null]],null],\"css-text-input-variant-full\",\"email\"]]]],[2,\"\\n    \"],[8,\"button\",[[24,0,\"w-full\"]],[[\"@style\",\"@type\"],[\"btn-primary\",\"submit\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Unlock Account\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"mut\",\"action\",\"component\"]}",
    "moduleName": "balance-ember/templates/users/resend-unlock.hbs"
  });

  _exports.default = _default;
});