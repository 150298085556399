define("balance-ember/components/manage/bookmark-dropdown/row/component", ["exports", "balance-ember/types/bookmark", "@microstates/ember"], function (_exports, _bookmark, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param {object} currentBookmark
   * @param {string} currentBookmarkText
   * @param {object} bookmark
   */
  var _default = Ember.Component.extend({
    classNames: ['flex', 'justify-between', 'items-center'],
    tagName: 'li',
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    bookmarkState: (0, _ember.state)((0, _ember.create)(_bookmark.default)),

    /**
     * @param
     */
    deleteBookmark() {},

    currentBookmarkText: 'Current Page',
    currentBookmarkTextClasses: 'text-material-gray600',
    bookmarkComponentName: Ember.computed('bookmarkState.status.state', function () {
      return `manage/bookmark-dropdown/row/${this.bookmarkState.status.state}`;
    }),
    actions: {
      cancel() {
        this.bookmark.rollbackAttributes();
        this.bookmarkState.reset();
      }

    }
  });

  _exports.default = _default;
});