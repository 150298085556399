define("balance-ember/templates/budgeting/budget/expenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HRnIgKn8",
    "block": "{\"symbols\":[],\"statements\":[[8,\"budget/page-header/budgeting\",[],[[\"@budget\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\\n\"],[8,\"container\",[],[[\"@style\"],[\"horizontal centered page padding-all\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[8,\"budget/non-personnel-expenses/allocation-list\",[],[[\"@allocations\",\"@budget\"],[[32,0,[\"allocations\"]],[32,0,[\"model\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ml-40 flex-full budget-expenses\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"allocations\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"budget-module-no-allocations\",[],[[\"@svgName\",\"@budget\",\"@budget_id\",\"@noDataText\"],[\"na-allocations\",[32,0,[\"model\"]],[32,0,[\"model\",\"id\"]],\"No allocations yet.\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"unless\"]}",
    "moduleName": "balance-ember/templates/budgeting/budget/expenses.hbs"
  });

  _exports.default = _default;
});