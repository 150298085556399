define("balance-ember/components/budget/priorities-table/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency", "balance-ember/classes/table-serializer"], function (_exports, _component, _emberConcurrency, _tableSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TRACKING_PREFIX = void 0;
  const TRACKING_PREFIX = 'budgetPriority';
  _exports.TRACKING_PREFIX = TRACKING_PREFIX;

  var _default = _component.default.extend({
    availableFiscalYears: Ember.inject.service(),
    currentFiscalYear: Ember.computed.readOnly('availableFiscalYears.current'),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    modelChanges: Ember.inject.service(),
    trackingPrefix: TRACKING_PREFIX,
    tableState: null,
    budgetPriorityChanges: Ember.computed('modelChanges.changes.[]', function () {
      return this.modelChanges.changesForModelType('budgetPriority');
    }),
    columns: Ember.computed(function () {
      let align = 'left';
      let columns = [{
        label: 'Name',
        valuePath: 'name',
        testSelector: 'name',
        cellClassNames: 'border-l print:border-l-0 border-material-gray200 print:avoid-page-break print:header-budgeted',
        classNames: 'border-l border-material-gray200',
        align,
        sortable: false,
        hideable: false,
        ascending: false
      }, {
        label: 'FTE',
        valuePath: 'rollupTotals.fte',
        testSelector: 'fte',
        width: '180px',
        align: 'right',
        sortable: false,
        hideable: false
      }, {
        label: 'NON-PSNL. EXPS.',
        valuePath: 'rollupTotals.non_personnel_expense_total',
        testSelector: 'npe',
        cellComponent: 'number-cell',
        width: '180px',
        align: 'right',
        sortable: false,
        hideable: false
      }, {
        label: 'SUPPLEMENTAL',
        valuePath: 'rollupTotals.supplemental_pay_total',
        testSelector: 'supplemental',
        cellComponent: 'number-cell',
        width: '180px',
        align: 'right',
        sortable: false,
        hideable: false
      }, {
        label: 'TOTAL',
        valuePath: 'rollupTotals.total',
        testSelector: 'total',
        cellComponent: 'number-cell',
        width: '180px',
        align: 'right',
        sortable: false,
        hideable: false
      }, {
        cellComponent: 'light-table/cell-components/table-actions',
        tableActionComponents: ['edit', 'delete'],
        sortable: false,
        hideable: false,
        align: 'right',
        width: '80px',

        buttonText(budgetPriority) {
          return budgetPriority.name;
        },

        editTooltipText() {
          return 'Edit Priority';
        },

        isDeleteDisabled: budgetPriority => !budgetPriority.deletable,

        deleteTooltipText(budgetPriority) {
          if (!budgetPriority.deletable) {
            return 'This Priority is in use and cannot be deleted at this time';
          }

          return 'Delete Priority';
        }

      }];
      return columns.map(column => {
        return Object.assign({}, column, {
          cellClassNames: `text-15 ${column.cellClassNames}`
        });
      });
    }),
    fetchBudgetPriorities: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('budgetPriority', Object.assign({}, this.serializedTableState, {
        extra_fields: {
          'budget_priorities': 'rollup_totals'
        },
        filter: this.serializedFilters,
        stats: {
          total: 'count'
        }
      }));
    }).restartable(),
    serializedTableState: Ember.computed('tableState', function () {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }),
    serializedFilters: Ember.computed('currentFiscalYear.id', 'tableState', function () {
      let allFilters = new _tableSerializer.default(this.tableState).apiQuery.filter;
      return Object.assign({}, allFilters, {
        fiscal_year_id: {
          eq: this.currentFiscalYear.id
        }
      });
    }),
    actions: {
      edit(budgetPriority) {
        this.router.transitionTo('budgeting.budget-priorities.edit', budgetPriority.id);
      },

      delete(budgetPriority) {
        this.router.transitionTo('budgeting.budget-priorities.destroy', budgetPriority.id);
      }

    }
  });

  _exports.default = _default;
});