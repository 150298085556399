define("balance-ember/utils/validators/position", ["exports", "balance-ember/utils/validator"], function (_exports, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class _default extends _validator.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "constraints", {
        jobType: {
          presence: {
            allowEmpty: false
          }
        },
        fte: {
          presence: {
            allowEmpty: false
          },
          numericality: true
        },
        positionExpenses: {
          sum: {
            key: 'percentage',
            lessThanOrEqualTo: 100
          },
          array: {
            percentage: {
              numericality: {
                greaterThan: 0
              },
              presence: true
            }
          }
        }
      });

      _defineProperty(this, "constraintPropertyLookups", {
        jobType: 'budget.position.data.job_type',
        fte: 'budget.position.data.fte',
        positionExpenses: 'budget.position.data.position_expenses',
        'positionExpenses.percentage': 'budget.position_expense_form.data.percentage'
      });
    }

  }

  _exports.default = _default;
});