define("balance-ember/components/account-details-chart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    height: null,
    width: null,
    tagName: '',

    init() {
      this._super(...arguments);
    },

    formattedData: Ember.computed('historicalData.[]', 'historyChartType', function () {
      let seriesData = this.historicalData;

      if (Ember.isPresent(seriesData)) {
        let currentChartType = this.historyChartType;
        return seriesData.map(datum => {
          datum['type'] = currentChartType;
          return datum;
        });
      }

      return [];
    }),
    options: Ember.computed('axis.defaultLabelFormatter', 'height', 'historicalData.[]', 'historyChartType', 'width', function () {
      return {
        title: {
          text: null
        },
        chart: {
          height: this.height,
          width: this.width
        },
        yAxis: {
          labels: {
            formatter() {
              return `$${this.axis.defaultLabelFormatter.call(this)}`;
            }

          },
          title: {
            text: ''
          },
          type: 'linear'
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          column: {
            grouping: true
          },
          series: {
            turboThreshold: 0,
            showInLegend: true,
            marker: {
              enabled: true
            }
          }
        },
        annotationsOptions: {
          enabledButtons: false
        },
        tooltip: {
          borderRadius: '4',
          delayForDisplay: 10,
          headerFormat: "<span style='font-size: 12px'>{point.key}</span><br/>",
          shared: true,
          style: {
            fontSize: '13px'
          },
          valuePrefix: '$'
        },
        xAxis: {
          showEmpty: false,
          type: 'category',
          title: {
            text: 'Year'
          }
        }
      };
    })
  });

  _exports.default = _default;
});