define("balance-ember/components/pagination-controls/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DKnydQdm",
    "block": "{\"symbols\":[\"@state\",\"&default\"],\"statements\":[[18,2,[[30,[36,2],null,[[\"results-count\",\"per-page\",\"links\"],[[30,[36,1],[\"pagination-controls/results-count\"],[[\"totalItems\",\"isLoading\"],[[32,0,[\"totalItems\"]],[32,0,[\"isLoading\"]]]]],[30,[36,1],[\"pagination-controls/per-page\"],[[\"isLoading\",\"itemsPerPageOptions\",\"itemsPerPage\",\"shouldShow\",\"setPerPage\"],[[32,0,[\"isLoading\"]],[32,0,[\"itemsPerPageOptions\"]],[32,1,[\"itemsPerPage\",\"state\"]],[32,0,[\"showPerPageControl\"]],[30,[36,0],[[32,0],\"setPerPage\"],null]]]],[30,[36,1],[\"pagination-controls/pagination-links\"],[[\"isLoading\",\"shouldShow\",\"currentPage\",\"lastPage\",\"goToPage\"],[[32,0,[\"isLoading\"]],[32,0,[\"showPaginationControls\"]],[32,0,[\"currentPage\"]],[32,0,[\"lastPage\"]],[30,[36,0],[[32,0],\"goToPage\"],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/pagination-controls/template.hbs"
  });

  _exports.default = _default;
});