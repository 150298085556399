define("balance-ember/components/multi-step-dropdown/dropdown-trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+fmBQnmB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"shouldShow\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[16,0,[32,0,[\"uniqueClass\"]]],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"click\"],null],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "moduleName": "balance-ember/components/multi-step-dropdown/dropdown-trigger/template.hbs"
  });

  _exports.default = _default;
});