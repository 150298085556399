define("balance-ember/templates/district-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DgBc2X3n",
    "block": "{\"symbols\":[\"NavItem\"],\"statements\":[[8,\"container\",[],[[\"@style\"],[\"horizontal centered page padding-all\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"two-column\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"column-1 min-w-290\"],[12],[2,\"\\n      \"],[8,\"side-nav\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@route\"],[[30,[36,0],[\"district-admin.users\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          Users & Permissions\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@route\"],[[30,[36,0],[\"district-admin.tags\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          Tags\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@route\"],[[30,[36,0],[\"district-admin.integrations\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          Connected Apps\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"column-2 flex-full\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"array\",\"-outlet\",\"component\"]}",
    "moduleName": "balance-ember/templates/district-admin.hbs"
  });

  _exports.default = _default;
});