define("balance-ember/controllers/manage/charts", ["exports", "balance-ember/mixins/table-controller", "balance-ember/mixins/segment-controller", "balance-ember/utils/manage/account-filter"], function (_exports, _tableController, _segmentController, _accountFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_tableController.default, _segmentController.default, {
    availableBudgetLeas: Ember.inject.service(),
    selectedBudgetLea: Ember.computed.readOnly('availableBudgetLeas.current'),
    categories: Ember.computed('segmentTypeSubcategories', function () {
      let categories = new _accountFilter.default({
        segmentTypeSubcategories: this.segmentTypeSubcategories
      }).categories;
      let amount = categories.find(({
        value
      }) => value === 'amount');
      amount.subcategories = amount.subcategories.filter(({
        value
      }) => value !== 'original_budget');
      return categories;
    })
  });

  _exports.default = _default;
});