define("balance-ember/components/budget/reports/budget-allocation-totals/component", ["exports", "balance-ember/mixins/budgeting-reports"], function (_exports, _budgetingReports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_budgetingReports.default, {
    features: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    positionBudgeting: Ember.computed.readOnly('features.positionBudgeting'),
    reportName: 'budget-allocation-totals',
    defaultSortColumn: 'budget_name',
    columns: Ember.computed('fundedFTEColumn', 'personnelPlannedColumn', 'positionBudgeting', function () {
      let availableFiscalYears = this.availableFiscalYears;
      return [{
        label: 'Budget',
        valuePath: 'budgetName',
        sortName: 'budget_name',
        testSelector: 'budget_name',
        cellClassNames: 'align-top text-sm',
        width: '225px',
        cellComponent: 'light-table/cell-components/link-cell',
        route: ['budgeting.budget.expenses', 'budgetId'],
        trackingEvent: 'View Budget From Report',
        trackingParams: {
          budget: 'name',
          fiscalYear: availableFiscalYears.current.name,
          reportName: 'Budgets and Allocation Totals'
        }
      }, {
        label: 'Allocation',
        valuePath: 'allocationName',
        sortName: 'allocation_name',
        testSelector: 'allocation_name',
        cellComponent: 'light-table/cell-components/link-cell',
        route: ['budgeting.budget.expenses.view', 'budgetId', 'allocationId']
      }, {
        label: 'Allocated',
        valuePath: 'allocatedAmount',
        sortName: 'allocated_amount',
        testSelector: 'allocated_amount',
        cellComponent: 'number-cell',
        shouldAbbreviate: true
      }, ...(this.positionBudgeting ? [this.personnelPlannedColumn] : []), {
        label: 'Non-Personnel Planned',
        valuePath: 'nonPersonnelPlannedAmount',
        sortName: 'non_personnel_planned_amount',
        testSelector: 'non_personnel_planned_amount',
        cellComponent: 'number-cell',
        shouldAbbreviate: true,
        width: '110px'
      }, {
        label: 'Remaining',
        valuePath: 'remainingAmount',
        sortName: 'remaining_amount',
        testSelector: 'remaining_amount',
        cellComponent: 'number-cell',
        shouldAbbreviate: true,
        width: '100px'
      }, ...(this.positionBudgeting ? [this.fundedFTEColumn] : [])];
    }),
    personnelPlannedColumn: Ember.computed(function () {
      return {
        label: 'Personnel Planned',
        valuePath: 'personnelPlannedAmount',
        sortName: 'personnel_planned_amount',
        testSelector: 'personnel_planned_amount',
        cellComponent: 'number-cell',
        shouldAbbreviate: true,
        width: '110px'
      };
    }),
    fundedFTEColumn: Ember.computed(function () {
      return {
        label: 'Funded (FTE)',
        valuePath: 'fundedFte',
        sortName: 'funded_fte',
        testSelector: 'funded_fte',
        cellType: 'allovue-cell',
        type: 'allovue-column',
        width: '90px'
      };
    }),
    categories: Ember.computed(function () {
      return [{
        title: 'Budget',
        value: 'budget_name',
        type: 'text'
      }, {
        title: 'Allocation',
        value: 'allocation_name',
        type: 'text'
      }, {
        title: 'Funded (FTE)',
        value: 'funded_fte',
        type: 'decimal'
      }, {
        title: 'Allocated',
        value: 'allocated_amount',
        type: 'decimal'
      }, {
        title: 'Personnel Planned',
        value: 'personnel_planned_amount',
        type: 'decimal'
      }, {
        title: 'Non-Personnel Planned',
        value: 'non_personnel_planned_amount',
        type: 'decimal'
      }, {
        title: 'Remaining',
        value: 'remaining_amount',
        type: 'decimal'
      }];
    })
  });

  _exports.default = _default;
});