define("balance-ember/components/filter-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "okgsNuMx",
    "block": "{\"symbols\":[\"Form\",\"@categoryConfig\",\"&default\",\"@applyButtonType\"],\"statements\":[[8,\"ui-form\",[],[[\"@onSubmit\",\"@style\"],[[30,[36,2],[[32,0],[32,0,[\"done\"]],[32,0,[\"forkedFilter\"]]],null],\"white\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"filter-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"font-bold\"],[12],[2,\"\\n      \"],[1,[32,0,[\"categoryTitle\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,3],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,3,[[30,[36,1],null,[[\"filter\"],[[32,0,[\"forkedFilter\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,0,[\"componentName\"]]],[[\"filter\",\"form\",\"categoryConfig\"],[[32,0,[\"forkedFilter\"]],[32,1],[32,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"filter-form/footer\",[],[[\"@applyButtonType\",\"@apply\",\"@cancel\"],[[32,4],[30,[36,2],[[32,0],[32,0,[\"done\"]],[32,0,[\"forkedFilter\"]]],null],[32,0,[\"cancel\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"action\",\"if\"]}",
    "moduleName": "balance-ember/components/filter-form/template.hbs"
  });

  _exports.default = _default;
});