define("balance-ember/utils/writeable-models/position", ["exports", "balance-ember/utils/writeable-models/basic", "balance-ember/utils/position-totals"], function (_exports, _basic, _positionTotals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PositionWriteableModel = (_dec = Ember.computed('positionExpenses.@each.percentage', 'employee', 'jobType', 'fte'), _dec2 = Ember.computed('employee', 'jobType'), _dec3 = Ember.computed('jobType'), _dec4 = Ember.computed('jobType'), _dec5 = Ember.computed('positionExpenses.@each.percentage', 'fte'), _dec6 = Ember.computed('positionExpenses.@each.percentage', 'fte'), _dec7 = Ember.computed('positionExpenses.@each.percentage'), _dec8 = Ember.computed('positionExpenses.@each.percentage'), _dec9 = Ember.computed('positionExpenses.@each.percentage', 'employee', 'jobType', 'fte'), _dec10 = Ember.computed('positionExpenses.@each.allocation'), (_class = class PositionWriteableModel extends _basic.default {
    constructor(args) {
      if (!args.paths) {
        args.paths = {
          budget: true,
          jobType: {
            staffType: true,
            benefitsPackage: {
              flatRateBenefits: true,
              percentageBenefits: true
            }
          },
          employee: {
            employeeStatus: true
          },
          positionExpenses: {
            position: true,
            allocation: {
              segments: {
                segmentType: true
              }
            },
            expenseStrategies: {
              budgetStrategy: {
                budgetPriority: true
              }
            },
            segments: {
              segmentType: true
            }
          }
        };
      }

      super(args);
    }

    get cost() {
      return new _positionTotals.default({
        position: this
      }).cost;
    }

    get employeeEstimatedBenefits() {
      return new _positionTotals.default({
        position: this
      }).employeeEstimatedBenefits;
    }

    get jobTypeBenefits() {
      return new _positionTotals.default({
        position: this
      }).jobTypeBenefits;
    }

    get jobTypeCost() {
      return new _positionTotals.default({
        position: this
      }).jobTypeCost;
    }

    get remainingFTE() {
      return new _positionTotals.default({
        position: this
      }).remainingFTE;
    }

    get fteFunded() {
      return new _positionTotals.default({
        position: this
      }).fteFunded;
    }

    get fundedPercentage() {
      return new _positionTotals.default({
        position: this
      }).fundedPercentage;
    }

    get remainingPercentage() {
      return new _positionTotals.default({
        position: this
      }).remainingPercentage;
    }

    get remainingAmount() {
      return new _positionTotals.default({
        position: this
      }).remainingAmount;
    }

    get remainingAmountCalculationMethod() {
      return new _positionTotals.default({
        position: this
      }).remainingAmountCalculationMethod;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "cost", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "cost"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "employeeEstimatedBenefits", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "employeeEstimatedBenefits"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "jobTypeBenefits", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "jobTypeBenefits"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "jobTypeCost", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "jobTypeCost"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remainingFTE", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "remainingFTE"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fteFunded", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "fteFunded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fundedPercentage", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "fundedPercentage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remainingPercentage", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "remainingPercentage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remainingAmount", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "remainingAmount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remainingAmountCalculationMethod", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "remainingAmountCalculationMethod"), _class.prototype)), _class));
  _exports.default = PositionWriteableModel;
});