define("balance-ember/components/status-light-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Show a simple status light circle to indicate a boolean value
   *
   * @param {boolean} isOn default: false
   */
  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['status-light-icon'],
    classNameBindings: ['isOn'],
    attributeBindings: ['isOn:data-test-status-light-is-on'],
    isOn: false
  });

  _exports.default = _default;
});