define("balance-ember/components/filter-list/completed-filter/pill/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    filter: null,

    removeFilter() {},

    componentState: 'normal',
    backgroundColor: Ember.computed('componentState', function () {
      return this.componentState === 'deleting' ? 'material-red50' : 'material-teal50';
    }),
    actions: {
      removeFilter() {
        if (this.componentState === 'normal' && this.filter.totalFilterValues > 1) {
          Ember.set(this, 'componentState', 'deleting');
        } else {
          this.removeFilter();
        }
      },

      cancelDeleting() {
        Ember.set(this, 'componentState', 'normal');
      }

    }
  });

  _exports.default = _default;
});