define("balance-ember/templates/budgeting/supplemental-pay-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fH+CvnI6",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"budget/supplemental-pay-type-form-data\",[],[[\"@supplementalPayType\"],[[32,0,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"budget/supplemental-pay-type-form\",[],[[\"@supplementalPayType\",\"@isLoading\",\"@formTitle\",\"@actionButtonText\",\"@disabledButtonText\",\"@segmentTypes\",\"@benefitsPackages\"],[[32,0,[\"model\"]],[32,1,[\"loadTask\",\"isRunning\"]],[30,[36,0],[\"Update \",[32,0,[\"model\",\"name\"]]],null],\"Save Changes\",\"Saving\",[32,1,[\"segmentTypes\"]],[32,1,[\"benefitsPackages\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "moduleName": "balance-ember/templates/budgeting/supplemental-pay-types/edit.hbs"
  });

  _exports.default = _default;
});