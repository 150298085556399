define("balance-ember/components/budget/reports/planned-purchases/table/component", ["exports", "@glimmer/component", "balance-ember/types/table", "@microstates/ember", "ember-concurrency"], function (_exports, _component, _table, _ember, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let table = (0, _ember.create)(_table.default, {
    defaultSort: {
      column: 'budget_name',
      dir: 'asc'
    }
  });
  let BudgetReportsPlannedPurchasesTable = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = (0, _emberConcurrency.task)(function* () {
    return yield this.loadReport();
  }), (_class = class BudgetReportsPlannedPurchasesTable extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "tableState", _descriptor2, this);

      _defineProperty(this, "columns", [{
        label: 'Budget',
        valuePath: 'budgetName',
        testSelector: 'budget-name',
        sortName: 'budget_name',
        cellComponent: 'light-table/cell-components/link-cell',
        route: ['budgeting.budget.expenses', 'budgetId']
      }, {
        label: 'Allocation',
        valuePath: 'allocationName',
        testSelector: 'allocation-name',
        sortName: 'allocation_name',
        cellComponent: 'light-table/cell-components/link-cell',
        route: ['budgeting.budget.expenses.view', 'budgetId', 'allocationId']
      }, {
        label: 'Vendor',
        valuePath: 'vendorName',
        testSelector: 'vendor-name',
        sortName: 'vendor_name'
      }, {
        label: 'Amount',
        valuePath: 'amount',
        testSelector: 'amount',
        cellComponent: 'number-cell',
        sortName: 'amount',
        width: '180px',
        align: 'right'
      }]);

      _defineProperty(this, "categories", [{
        title: 'Budget',
        value: 'budget_name',
        type: 'text'
      }, {
        title: 'Allocation',
        value: 'allocation_name',
        type: 'text'
      }, {
        title: 'Vendor',
        value: 'vendor_name',
        type: 'text'
      }, {
        title: 'Amount',
        value: 'amount',
        type: 'currency'
      }]);

      _initializerDefineProperty(this, "loadReportTask", _descriptor3, this);
    }

    loadReport() {
      return this.store.queryRecord('report', { ...this.tableState.apiQuery,
        reportName: 'planned-purchases'
      }).then(data => {
        let reportData = data.reportData;
        reportData.meta = data.meta;
        return reportData;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tableState", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _ember.Store)(table, nextState => this.tableState = nextState);
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loadReportTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BudgetReportsPlannedPurchasesTable;
});