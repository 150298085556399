define("balance-ember/components/positions-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uYuECvLY",
    "block": "{\"symbols\":[],\"statements\":[[8,\"report-summary\",[],[[\"@class\"],[\"positions-plan-summary\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"summary-stat-wrapper flex\"],[12],[2,\"\\n    \"],[8,\"report-summary-stat\",[],[[\"@class\",\"@title\",\"@value\"],[\"number\",\"Total FTE\",[30,[36,0],[[32,0,[\"sumOfAllFTEs\"]]],null]]],null],[2,\"\\n    \"],[8,\"report-summary-stat\",[],[[\"@class\",\"@title\",\"@value\"],[\"number\",\"Vacant FTE\",[32,0,[\"sumOfUnstaffedFTEs\"]]]],null],[2,\"\\n    \"],[8,\"report-summary-stat\",[],[[\"@class\",\"@title\",\"@value\"],[\"number\",\"Filled FTE\",[32,0,[\"percentageofFilledFTEs\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"report-summary-bar\",[],[[\"@active\",\"@activeTitle\",\"@total\",\"@remainderTitle\",\"@typeOfThing\",\"@hideTotal\",\"@activeBgColor\",\"@activeTextColor\"],[[30,[36,0],[[32,0,[\"sumOfFundedFTEs\"]]],null],\"Funded FTE\",[32,0,[\"sumOfAllFTEs\"]],\"Unfunded FTE\",\"FTE\",true,\"bg-material-green500\",\"text-material-green500\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hundredths-place\"]}",
    "moduleName": "balance-ember/components/positions-summary/template.hbs"
  });

  _exports.default = _default;
});