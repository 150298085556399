define("balance-ember/components/budget/strategies-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NP4pYpNb",
    "block": "{\"symbols\":[\"T\",\"body\",\"@budgetPriority\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[8,\"icon-button\",[],[[\"@style\",\"@size\",\"@route\",\"@icon\",\"@testId\",\"@buttonText\",\"@showButtonText\"],[\"btn-primary-link\",\"sm\",[30,[36,0],[\"budgeting.budget.strategies.new\",[32,3,[\"id\"]]],null],\"plus\",[31,[\"add-budget-strategy-\",[32,3,[\"id\"]]]],\"Add Strategy\",true]],null],[2,\"\\n\\n  \"],[8,\"table\",[],[[\"@tableState\",\"@columns\",\"@loadTask\",\"@modelChanges\",\"@tableActions\"],[[32,0,[\"tableState\"]],[32,0,[\"columns\"]],[32,0,[\"strategiesLoadTask\"]],[32,0,[\"strategyChanges\"]],[30,[36,2],null,[[\"edit\",\"delete\"],[[30,[36,1],[[32,0],[32,0,[\"edit\"]]],null],[30,[36,1],[[32,0],[32,0,[\"delete\"]]],null]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"body\"]],[],[[\"@headerColor\",\"@headerBorder\",\"@rowTestSelector\"],[\"white\",\"material-gray200\",\"budget-strategy-row\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"loader\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Loading...\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,2,[\"noData\"]],[],[[\"@noDataText\"],[\"No strategies in this priority yet.\"]],null],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"array\",\"action\",\"hash\"]}",
    "moduleName": "balance-ember/components/budget/strategies-table/template.hbs"
  });

  _exports.default = _default;
});