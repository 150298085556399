define("balance-ember/components/budget/reports/overassigned-employees/budget-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+6a9Fc40",
    "block": "{\"symbols\":[\"position\",\"positionId\",\"@row\"],\"statements\":[[10,\"div\"],[12],[2,\" \"],[13],[2,\"\\n\"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,3,[\"content\",\"overassignedPositions\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"text-15 truncate\"],[12],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"budgeting.budget.positions\",[32,1,[\"budgetId\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"budgetName\"]]]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-each-in\",\"each\"]}",
    "moduleName": "balance-ember/components/budget/reports/overassigned-employees/budget-cell/template.hbs"
  });

  _exports.default = _default;
});