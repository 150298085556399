define("balance-ember/models/position", ["exports", "@ember-data/model", "balance-ember/utils/is-present", "balance-ember/utils/position-totals"], function (_exports, _model, _isPresent, _positionTotals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.computedProperties = _exports.schema = _exports.UNDEFINED_EMPLOYEE = void 0;
  const UNDEFINED_EMPLOYEE = 'Vacant';
  _exports.UNDEFINED_EMPLOYEE = UNDEFINED_EMPLOYEE;
  const schema = {
    jobType: (0, _model.belongsTo)('jobType', {
      async: false
    }),
    employee: (0, _model.belongsTo)('employee', {
      async: false
    }),
    budget: (0, _model.belongsTo)('budget', {
      async: false
    }),
    positionExpenses: (0, _model.hasMany)('position-expense', {
      async: false
    }),
    fte: (0, _model.attr)('number'),
    notes: (0, _model.attr)('string'),
    code: (0, _model.attr)(),
    budgetedCost: (0, _model.attr)('number', {
      readOnly: true
    }),
    percentFunded: (0, _model.attr)('number', {
      defaultValue: 0.0,
      readOnly: true
    }),
    editable: (0, _model.attr)('boolean', {
      defaultValue: false,
      readOnly: true
    })
  };
  _exports.schema = schema;
  const computedProperties = {
    undefinedEmployee: UNDEFINED_EMPLOYEE,
    employeeName: Ember.computed.or('employee.name', 'undefinedEmployee'),
    employeeLastName: Ember.computed.or('employee.lastName', 'undefinedEmployee'),
    employeeEstimatedBenefits: Ember.computed('employee', 'jobType.benefitsPackage', function () {
      return new _positionTotals.default({
        position: this
      }).employeeEstimatedBenefits;
    }),
    staffTypeName: Ember.computed.readOnly('jobType.staffType.name'),
    jobTypeTitle: Ember.computed.readOnly('jobType.title'),
    isStaffed: (0, _isPresent.default)('employee.id'),
    remainingAmountCalculationMethod: Ember.computed('positionExpenses.[]', function () {
      return new _positionTotals.default({
        position: this
      }).remainingAmountCalculationMethod;
    }),
    remainingAmount: Ember.computed('positionExpenses.[]', function () {
      return new _positionTotals.default({
        position: this
      }).remainingAmount;
    }),
    remainingPercentage: Ember.computed('positionExpenses.[]', function () {
      return new _positionTotals.default({
        position: this
      }).remainingPercentage;
    }),
    remainingFTE: Ember.computed('fte', function () {
      return new _positionTotals.default({
        position: this
      }).remainingFTE;
    }),
    fteFunded: Ember.computed('fte', function () {
      return new _positionTotals.default({
        position: this
      }).fteFunded;
    }),
    displayFTE: Ember.computed('fte', function () {
      return Math.round(this.fte * 100) / 100.0;
    }),
    hasNoFunding: Ember.computed.equal('percentFunded', 0.0),
    fullyFunded: Ember.computed.equal('percentFunded', 100.0),
    isFunded: Ember.computed.readOnly('fullyFunded'),
    isEditable: Ember.computed.readOnly('editable'),
    percentFundedDecimal: Ember.computed('percentFunded', function () {
      return this.percentFunded / 100;
    })
  };
  _exports.computedProperties = computedProperties;

  var _default = _model.default.extend(schema, computedProperties);

  _exports.default = _default;
});