define("balance-ember/components/manage/transactions-table/component", ["exports", "balance-ember/components/tagless-component/component", "@microstates/ember", "balance-ember/utils/urls", "balance-ember/utils/filters/format-display-text"], function (_exports, _component, _ember, _urls, _formatDisplayText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    availableBudgetLeas: Ember.inject.service(),
    preferences: Ember.inject.service(),
    features: Ember.inject.service(),
    intl: Ember.inject.service(),
    eventTracking: Ember.inject.service(),
    trackingPrefix: 'Transactions',
    summarizeTableState: null,

    /**
     * @param
     */
    currentBookmark: null,

    /**
     * @param
     */
    categories: null,
    summarizeState: Ember.computed.readOnly('summarizeTableState.summarize'),
    tableState: Ember.computed.readOnly('summarizeTableState.table'),
    summarizeCategories: Ember.computed('categories.[]', function () {
      let categories = [];
      let segmentCategories = this.categories.findBy('value', 'segments').subcategories;
      categories.push(...segmentCategories);
      let supplementCategories = this.categories.findBy('value', 'supplements').subcategories;
      categories.push(...supplementCategories);
      let quarterCategory = this.categories.findBy('value', 'quarter');
      categories.push(quarterCategory);
      let monthCategory = this.categories.findBy('value', 'month');
      categories.push(monthCategory);
      return categories.filter(category => category.summarizable);
    }),
    columnState: (0, _ember.state)((0, _ember.create)([Object])),
    hiddenColumnPreferences: Ember.computed.readOnly('preferences.transactionsHiddenColumns'),
    selectedBudgetLea: Ember.computed('availableBudgetLeas.all', 'budgetLeaFilter', 'selectedBudgetLeaId', function () {
      return this.availableBudgetLeas.all.findBy('id', this.selectedBudgetLeaId);
    }),
    selectedBudgetLeaId: Ember.computed.reads('budgetLeaFilter.firstOperator.firstFilterValue'),
    budgetLeaFilter: Ember.computed('tableState.filters', function () {
      return [...this.tableState.filters].findBy('category.state', 'budget_lea_id');
    }),
    segmentTypes: Ember.computed.readOnly('selectedBudgetLea.sortedSegmentTypes'),
    supplementTypes: Ember.computed.readOnly('selectedBudgetLea.supplementTypes'),
    bodyComponent: Ember.computed('summarizeState.category.keys.length', function () {
      let componentName = this.summarizeState.category.keys.length ? 'summarize' : 'index';
      return `manage/transactions-table/${componentName}`;
    }),
    columns: Ember.computed('checkboxColumn', 'dateColumn', 'segmentTypeColumns.[]', 'statusColumn', 'supplementTypeColumns', 'tagColumn', function () {
      let allColumns = [...[this.checkboxColumn], ...this.dateColumn, ...this.segmentTypeColumns, ...this.supplementTypeColumns, ...[this.tagColumn], ...this.statusColumn];
      return allColumns.map(column => {
        return Object.assign({}, column, {
          canTruncateLabel: true
        });
      });
    }),
    checkboxColumn: Ember.computed(function () {
      return {
        component: 'light-table/head-components/select-all',
        cellComponent: 'light-table/cell-components/checkbox-cell',
        hideable: false,
        sortable: false,
        width: '40px',
        checkboxText: transaction => {
          return this.intl.t('manage.transaction.name', {
            identifiers: `${transaction.transactionDate} ${transaction.account.code}`
          });
        }
      };
    }),
    dateColumn: Ember.computed(function () {
      return [{
        label: 'DATE',
        valuePath: 'transactionDate',
        sortName: 'transaction_date',
        testSelector: 'transaction_date',
        cellComponent: 'date-cell',
        timeZoneMode: 'exact',
        cellClassNames: 'print:header-date',
        hideable: false,
        format: 'MMM Do, YYYY',
        ascending: false,
        width: '140px'
      }];
    }),
    statusColumn: Ember.computed(function () {
      let columns = [{
        label: 'STATUS',
        valuePath: 'status',
        sortName: 'status',
        testSelector: 'status',
        cellComponent: 'manage/transaction-status-cell',
        cellClassNames: 'print:header-status',
        hideable: false,
        width: '140px'
      }, {
        label: 'AMOUNT',
        valuePath: 'amount',
        sortName: 'amount',
        testSelector: 'amount',
        highlightDeficit: true,
        cellComponent: 'amount-cell',
        cellClassNames: 'print:header-amount',
        hideable: false,
        ascending: false,
        width: '140px',
        align: 'right'
      }];
      return columns.map(column => {
        return Object.assign({}, column, {
          cellClassNames: `text-15 ${column.cellClassNames}`
        });
      });
    }),
    segmentTypeColumns: Ember.computed('hiddenColumnPreferences', 'segmentTypes.length', 'selectedBudgetLea', function () {
      return this.segmentTypes.map((segmentType, index) => {
        let name = segmentType.name;
        let isLastSegmentType = index === this.segmentTypes.length - 1;
        let paddingRight = isLastSegmentType ? 'pr-16' : '';
        let classNames = paddingRight + ' overflow-hidden print:invisible';
        return {
          label: name.toUpperCase().replace('_', ' '),
          segmentType: name,
          testSelector: name,
          sortName: `segment_${name}`,
          name,
          sortType: 'name',
          component: 'manage/segment-header-dropdown',
          cellComponent: 'manage/segment-cell',
          cellClassNames: `text-15
                         ${paddingRight}
                         print:float-left
                         print:clear-left
                         print:avoid-page-break
                         print:p-0
                         print:pl-16
                         print:text-13
                         print:first:pt-8`,
          classNames,
          hideable: true,
          hidden: this.hiddenColumnPreferences.includes(name)
        };
      });
    }),
    supplementTypeColumns: Ember.computed('hiddenColumnPreferences', 'selectedBudgetLea', 'supplementTypes.length', function () {
      return this.supplementTypes.map((supplementType, index) => {
        let {
          name
        } = supplementType;
        let isLastSupplementType = index === this.supplementTypes.length - 1;
        let paddingRight = isLastSupplementType ? 'pr-16 print:pb-8' : '';
        let classNames = paddingRight + ' overflow-hidden print:invisible';
        return {
          label: name.toUpperCase().replace('_', ' '),
          supplementType: name,
          testSelector: name,
          sortName: name,
          name,
          cellComponent: 'manage/supplement-cell',
          cellClassNames: `text-15
                         ${paddingRight}
                         print:float-left
                         print:clear-left
                         print:avoid-page-break
                         print:p-0
                         print:pl-16
                         print:text-13
                         print:first:pt-8`,
          classNames,
          hideable: true,
          hidden: this.hiddenColumnPreferences.includes(name)
        };
      });
    }),
    tagColumn: Ember.computed(function () {
      return {
        label: 'TAGS',
        cellComponent: 'light-table/cell-components/tag-cell',
        valuePath: 'tags',
        hideable: false,
        sortable: false,
        width: '100px',
        buttonText: transaction => {
          return this.intl.t('manage.transaction.name', {
            identifiers: `${transaction.transactionDate} ${transaction.account.code}`
          });
        }
      };
    }),
    filterSummary: Ember.computed('categories', 'tableState.filters', function () {
      if (this.tableState.filters) {
        return (0, _formatDisplayText.formatFilters)(this.tableState.filters, this.categories);
      }

      return undefined;
    }),

    didReceiveAttrs() {
      this._super();

      this.columnState.set(this.columns);
    },

    downloadUrl: `${(0, _urls.URLForAPIv3)()}/transactions/downloads`,
    actions: {
      print() {
        this.eventTracking.trackEvent('Prints Data', {
          page: 'Transactions',
          filters: this.filterSummary
        });
        window.print();
      },

      summarize(category) {
        this.summarizeState.set({
          category
        });
        this.eventTracking.trackEvent(`Summarize by ${this.trackingPrefix}`, {
          segment: category.title
        });
      },

      clearSummarize() {
        this.tableState.rows.clear();
        this.tableState.sorts.clear();
        this.summarizeState.set({});
      }

    }
  });

  _exports.default = _default;
});