define("balance-ember/components/budget/budget-quick-links/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DkyFxcjG",
    "block": "{\"symbols\":[\"@budget\"],\"statements\":[[10,\"div\"],[14,0,\"budget-links\"],[12],[2,\"\\n\"],[2,\"  \"],[8,\"link-to\",[[24,0,\"text-blue500\"]],[[\"@route\",\"@model\",\"@budget\",\"@bubbles\"],[\"budgeting.budget.expenses\",[32,1,[\"id\"]],[32,1],false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"budget.quick_links.expenses\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[\"positionBudgeting\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"text-blue500\"]],[[\"@route\",\"@model\",\"@bubbles\"],[\"budgeting.budget.positions\",[32,1,[\"id\"]],false]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"budget.quick_links.positions\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[\"positionBudgeting\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"text-blue500\"]],[[\"@route\",\"@model\",\"@bubbles\"],[\"budgeting.budget.supplemental-pay-expenses\",[32,1,[\"id\"]],false]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"budget.quick_links.supplemental_expenses\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,\"link-to\",[[24,0,\"text-blue500\"]],[[\"@route\",\"@model\",\"@bubbles\"],[\"budgeting.budget.details\",[32,1,[\"id\"]],false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"budget.quick_links.access_and_resources\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"feature-flag\",\"if\"]}",
    "moduleName": "balance-ember/components/budget/budget-quick-links/template.hbs"
  });

  _exports.default = _default;
});