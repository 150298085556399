define("balance-ember/validators/messages", ["exports", "ember-cp-validations/validators/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _messages.default.extend({
    getDescriptionFor(attribute, options) {
      return options.description || Ember.String.dasherize(attribute).titleize();
    }

  });

  _exports.default = _default;
});