define("balance-ember/utils/validators/password", ["exports", "balance-ember/utils/validator"], function (_exports, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class _default extends _validator.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "constraints", {
        currentPassword: {
          presence: {
            allowEmpty: false
          },
          length: {
            minimum: 4,
            maximum: 120
          }
        },
        newPassword: {
          presence: {
            allowEmpty: false
          },
          length: {
            minimum: 4,
            maximum: 120
          }
        },
        confirmPassword: {
          equality: 'newPassword',
          presence: {
            allowEmpty: false
          }
        }
      });

      _defineProperty(this, "constraintPropertyLookups", {
        currentPassword: 'user.current_password',
        newPassword: 'user.new_password',
        confirmPassword: 'user.confirm_password'
      });
    }

  }

  _exports.default = _default;
});