define("balance-ember/components/manage/transactions-table/index/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency", "balance-ember/classes/table-serializer", "balance-ember/utils/grand-total-stats"], function (_exports, _component, _emberConcurrency, _tableSerializer, _grandTotalStats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    modelChanges: Ember.inject.service(),

    /**
     * @param
     */
    summarizeTableState: null,

    /**
     * @param
     */
    categories: null,
    tableState: Ember.computed.readOnly('summarizeTableState.table'),
    selectedRows: Ember.computed(function () {
      return [];
    }),
    grandTotals: null,
    loadTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('transaction', { ...this.serializedTableState,
        ...(this.serializedTableState.sort ? {
          sort: this.serializedTableState.sort
        } : {
          sort: '-transaction_date'
        }),
        include: 'account.segments.segment_type,tags',
        stats: {
          total: 'count',
          amount: 'sum'
        }
      }).then(results => {
        let grandTotals = (0, _grandTotalStats.pullOutGrandTotalStats)(results.meta.stats);
        Ember.set(this, 'grandTotals', grandTotals);
        return results;
      });
    }).drop(),
    serializedTableState: Ember.computed('tableState', function () {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }),
    transactionModelChanges: Ember.computed('modelChanges.changes.[]', function () {
      return this.modelChanges.changesForModelType('transaction');
    })
  });

  _exports.default = _default;
});