define("balance-ember/components/table-provider/component", ["exports", "balance-ember/components/tagless-component/component", "ember-light-table", "@microstates/ember"], function (_exports, _component, _emberLightTable, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param {array} columns Columns array
   * @param {TableState} tableState Table State object
   * @param {task} loadTask Optional. Pass in if you expect the table to show the loading state
   * @param {function} updateSortColumn Optional. Function to update the sort column
   * @param {function} updateSortDir Optional. Function to update the sort direction
   * @param {function} updatePage Optional. Function to update the pagination page
   */
  var _default = _component.default.extend({
    data: Ember.computed('tableState.rows', function () {
      return (0, _ember.valueOf)(this.tableState.rows);
    }),
    sortDir: Ember.computed.readOnly('tableState.firstSort.dir.state'),
    sortColumn: Ember.computed.readOnly('tableState.firstSort.column.state'),
    isLoading: Ember.computed.readOnly('loadTask.isRunning'),
    isDataEmpty: Ember.computed.empty('data'),
    hasData: Ember.computed.not('isDataEmpty'),
    shouldShowTable: Ember.computed.or('isLoading', 'hasData'),
    filterApplied: Ember.computed.gt('tableState.optionalFilters.length', 0),
    hasNoFilteredResults: Ember.computed.and('isDataEmpty', 'filterApplied'),
    tableRows: Ember.computed('data.[]', 'isLoading', function () {
      // If the table is loading then we want to empty the rows so the loading spinner will show
      if (this.isLoading) {
        return [];
      }

      return this.data || [];
    }),
    table: Ember.computed('tableRows', 'columns', function () {
      return _emberLightTable.default.create({
        columns: this.columns,
        rows: this.tableRows
      });
    }),

    didReceiveAttrs() {
      this._super();

      if (this.setSortedColumn) {
        this.setSortedColumn(this.table);
      } else {
        this._setSortedColumn(this.table);
      }
    },

    /**
     * setSortedColumn - Default way to figure out which column should the sorted one. Can be
     * overridden if a table has more complicated requirements
     */
    _setSortedColumn(table) {
      if (this.sortColumn) {
        let sortColumn = table.allColumns.find(column => {
          if (column.sortName && column.sortName === this.sortColumn) {
            return column;
          }

          if (column.sortableBy) {
            return column.sortableBy.mapBy('value').includes(this.sortColumn);
          }
        });
        let isAscending = this.sortDir === 'asc' ? true : false; // Setup initial sort column

        if (sortColumn) {
          Ember.set(sortColumn, 'sorted', true);
          Ember.set(sortColumn, 'ascending', isAscending);
        }
      }
    }

  });

  _exports.default = _default;
});