define("balance-ember/templates/budgeting/fiscal-year/budgets/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tH2zmj50",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"budget/budgets/form-data\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"budget/budgets/form\",[],[[\"@allBudgetUsers\",\"@style\",\"@budget\",\"@amazonVendor\",\"@loadTask\",\"@actionButtonText\",\"@disabledButtonText\",\"@formTitle\"],[[32,1,[\"budgetUsers\"]],\"slideout wide padded\",[32,1,[\"budget\"]],[32,1,[\"amazonVendor\"]],[32,1,[\"loadTask\"]],\"Create Budget\",\"Creating\",\"Create Budget\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/budgeting/fiscal-year/budgets/new.hbs"
  });

  _exports.default = _default;
});