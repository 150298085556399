define("balance-ember/templates/budgeting/fiscal-year/budgets/allocations/destroy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XAkMYeMx",
    "block": "{\"symbols\":[],\"statements\":[[8,\"destroy-allocation-modal\",[],[[\"@allocations\",\"@budget\",\"@redirectRoute\"],[[32,0,[\"model\",\"allocations\"]],[32,0,[\"model\",\"budget\"]],[32,0,[\"model\",\"redirectRoute\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/budgeting/fiscal-year/budgets/allocations/destroy.hbs"
  });

  _exports.default = _default;
});