define("balance-ember/components/manage/summarize/cell/quarter/component", ["exports", "balance-ember/components/tagless-component/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @param
     */
    row: null,

    /**
     * @param
     */
    category: null,
    content: Ember.computed.readOnly('row.content'),
    quarter: Ember.computed.readOnly('content.quarter'),
    dateRange: Ember.computed('content.dateRange', function () {
      return this.content.dateRange.map(date => {
        return (0, _moment.default)(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
      });
    })
  });

  _exports.default = _default;
});