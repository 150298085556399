define("balance-ember/components/manage/accounts-table/summarize/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5qHlmcQJ",
    "block": "{\"symbols\":[\"Header\",\"isNegative\",\"@firstColumnPadding\",\"@columns\",\"@extra\",\"@table\",\"@sortIcons\",\"@middleColumnPadding\",\"@lastColumnPadding\"],\"statements\":[[8,\"manage/summarize/header\",[],[[\"@rowLabel\",\"@firstColumnPadding\",\"@columns\",\"@extra\",\"@table\",\"@sortIcons\"],[\"Accounts\",[32,3],[32,4],[32,5],[32,6],[32,7]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"cell\"]],[],[[\"@testId\",\"@amount\",\"@paddingClass\"],[\"summarize-header-budgeted\",[32,0,[\"grandTotals\",\"budgeted\"]],[32,8]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"cell\"]],[],[[\"@testId\",\"@amount\",\"@paddingClass\"],[\"summarize-header-encumbered\",[32,0,[\"grandTotals\",\"encumbered\"]],[32,8]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"cell\"]],[],[[\"@testId\",\"@amount\",\"@paddingClass\"],[\"summarize-header-spent\",[32,0,[\"grandTotals\",\"spent\"]],[32,8]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"cell\"]],[],[[\"@testId\",\"@paddingClass\"],[\"summarize-header-available\",[32,9]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"negative-amount\",[],[[\"@value\"],[[32,0,[\"grandTotals\",\"available\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,0,[\"grandTotals\",\"available\"]]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\"]}",
    "moduleName": "balance-ember/components/manage/accounts-table/summarize/header/template.hbs"
  });

  _exports.default = _default;
});