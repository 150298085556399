define("balance-ember/components/multi-step-dropdown/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param {action} onClose
   */
  var _default = _component.default.extend({
    modals: Ember.inject.service(),
    currentStep: 0,
    stackable: true,
    renderInPlace: false,

    init() {
      this._super(...arguments);

      Ember.set(this, 'uniqueId', Math.floor(Math.random() * 10000));
    },

    isOpen: Ember.computed.gt('currentStep', 0),
    areOtherModalsOpen: Ember.computed.gt('modals.openModals.length', 0),

    openDropdown() {
      Ember.set(this, 'currentStep', 1);

      if (this.onOpen) {
        this.onOpen();
      }
    },

    waitForModalsToClose: (0, _emberConcurrency.task)(function* () {
      let count = 0;

      while (this.areOtherModalsOpen && count < 500) {
        yield (0, _emberConcurrency.timeout)(1);
        count++;
      }
    }).drop(),
    actions: {
      onTriggerClick() {
        if (!this.stackable) {
          this.waitForModalsToClose.perform().then(() => this.openDropdown());
        } else {
          this.openDropdown();
        }
      },

      nextStep() {
        Ember.set(this, 'currentStep', this.currentStep + 1);
      },

      onClickOutside() {
        Ember.set(this, 'currentStep', 0);

        if (this.onClose) {
          this.onClose();
        }
      },

      close() {
        Ember.set(this, 'currentStep', 0);
      }

    }
  });

  _exports.default = _default;
});