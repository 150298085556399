define("balance-ember/components/ui-form/label-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pqzn7nHh",
    "block": "{\"symbols\":[\"@isChecked\",\"&default\"],\"statements\":[[10,\"span\"],[15,0,[31,[\"text-material-gray700 font-normal\\n  \",[32,0,[\"styleClasses\"]],\" \",[30,[36,0],[[32,1],\"font-semibold\"],null]]]],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/ui-form/label-text/template.hbs"
  });

  _exports.default = _default;
});