define("balance-ember/components/downloads-dropdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    downloadService: Ember.inject.service('downloads'),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.downloadService.getInProgressFiles();
    },

    downloadBoxClasses: Ember.computed('downloadService.inProgress', function () {
      let classes = 'download-box-spinner';

      if (Ember.isPresent(this.downloadService.inProgress)) {
        classes = `${classes} radius-spinner-animation`;
      }

      return classes;
    }),
    actions: {
      downloadFile(params) {
        params.closeDropdown();
        let notificationText = 'Your file is downloading now!';
        this.downloadService.downloadFile(params.fileId, notificationText);
      },

      hideFailedFile(file) {
        file.set('hidden', true);
        file.save();
      }

    }
  });

  _exports.default = _default;
});