define("balance-ember/components/loading-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['loading-container', 'flex', 'flex-col', 'items-center', 'text-center', 'mt-16']
  });

  _exports.default = _default;
});