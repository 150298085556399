define("balance-ember/components/budget/reports/deleted-positions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QQ98yLu3",
    "block": "{\"symbols\":[\"T\",\"Controls\",\"Body\",\"Controls\",\"@export\"],\"statements\":[[10,\"div\"],[14,0,\"flex justify-between\"],[12],[2,\"\\n  \"],[8,\"budget-tab-title\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"budget.reports.types.deleted_positions\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"budget/reports/export-button\",[],[[\"@reportName\",\"@export\"],[[32,0,[\"reportName\"]],[32,5]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"my-16\"],[12],[2,\"\\n  \"],[8,\"table\",[],[[\"@tableState\",\"@columns\",\"@loadTask\",\"@categories\",\"@trackingPrefix\"],[[32,0,[\"tableState\"]],[32,0,[\"columns\"]],[32,0,[\"loadReport\"]],[32,0,[\"categories\"]],\"Deleted Positions Report\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,4,[\"filter-button\"]],[],[[],[]],null],[2,\"\\n      \"],[8,[32,4,[\"pagination\"]],[],[[\"@style\"],[\"tight\"]],null],[2,\"\\n    \"]],\"parameters\":[4]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"filter-bar\"]],[],[[],[]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"body\"]],[],[[\"@id\",\"@rowTestSelector\",\"@noDataIcon\"],[\"deleted-positions\",\"budget-row\",\"na-employees\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"loader\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Loading Report....\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,3,[\"noData\"]],[],[[\"@noDataText\"],[\"No positions deleted yet\"]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"pagination\"]],[],[[\"@style\"],[\"tight\"]],null],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "balance-ember/components/budget/reports/deleted-positions/template.hbs"
  });

  _exports.default = _default;
});