define("balance-ember/components/table/actions-popover/change-log/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3xRG3XeM",
    "block": "{\"symbols\":[\"@row\",\"@tableAction\"],\"statements\":[[8,\"button\",[],[[\"@style\",\"@testId\",\"@onclick\"],[\"btn-primary-link\",\"change-log-button\",[32,0,[\"onClick\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,0],[\"global.buttons.change_log\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"shouldShowChangeLog\",\"state\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"budget/change-log-slideout\",[],[[\"@record\",\"@type\",\"@resourceName\",\"@include\",\"@recordDescription\",\"@closeSlideout\"],[[32,1,[\"content\"]],[32,2,[\"type\"]],[32,2,[\"resourceName\"]],[32,2,[\"include\"]],[32,0,[\"recordDescription\"]],[32,0,[\"shouldShowChangeLog\",\"toggle\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "balance-ember/components/table/actions-popover/change-log/template.hbs"
  });

  _exports.default = _default;
});