define("balance-ember/components/popover/component", ["exports", "@glimmer/component", "balance-ember/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PopoverComponent extends _component.default {
    get showDuration() {
      return _environment.default.environment === 'test' ? 0 : 300;
    }

  }

  _exports.default = PopoverComponent;
});