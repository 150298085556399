define("balance-ember/components/position-fill-rates-widget/percentage-filled-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HVYet6wf",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[10,\"div\"],[14,0,\"text-material-light-blue600\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,1]],[[\"format\"],[\"0,0\"]]]],[2,\"%\\n\"],[13],[2,\"\\n\\n\"],[8,\"percentage-bar\",[],[[\"@borderColor\",\"@bars\"],[\"border-material-light-blue600\",[30,[36,2],[[30,[36,1],null,[[\"percentage\",\"color\"],[[32,1],\"bg-material-light-blue600\"]]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\",\"hash\",\"array\"]}",
    "moduleName": "balance-ember/components/position-fill-rates-widget/percentage-filled-cell/template.hbs"
  });

  _exports.default = _default;
});