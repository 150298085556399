define("balance-ember/validators/server-error", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * There are additional server validations on the rails models. This validator will take those server errors and make them part of the validations so we can show server validation errors on the front end.
   * Usage: Call validator('server-error') on the desired field in the forked model
   */
  const ServerError = _base.default.extend({
    validate(value, options, model, attribute) {
      let errors = Ember.get(model, `errors.${attribute}`);
      let description = options.description || attribute.titleize();
      return errors ? errors.map(error => `${description} ${error.message || error}`).join(' ') : true;
    }

  });

  ServerError.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor() {
      return ['model.errors.[]'];
    }

  });
  var _default = ServerError;
  _exports.default = _default;
});