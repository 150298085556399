define("balance-ember/components/filter-form/autocomplete-substring/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/filters/autocomplete-substring-formatter", "@microstates/ember", "balance-ember/components/filter-form/multi/filter-input"], function (_exports, _component, _autocompleteSubstringFormatter, _ember, _filterInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AutocompleteSubstringFilterUpdater extends _filterInput.FilterUpdater {
    getOperatorsArray() {
      return [].concat(this.getPositiveOperators()).concat(this.getNegativeOperators());
    }

    getPositiveOperators() {
      if (this.positiveOperatorKeys.length) {
        return [{
          value: 'eq_group',
          filterValues: this.getPositiveFilterValues()
        }];
      }

      return [];
    }

    get positiveOperatorKeys() {
      return this.groupedOperatorKeys.filter(key => ['eq', 'match'].includes(key));
    }

    getPositiveFilterValues() {
      let operators = this.positiveOperatorKeys.reduce((operators, key) => {
        operators[key] = this.groupedOperators[key];
        return operators;
      }, {});
      return [operators];
    }

    getNegativeOperators() {
      if (this.negativeOperatorKeys.length) {
        return this.negativeOperatorKeys.map(key => {
          return {
            value: key,
            filterValues: this.groupedOperators[key]
          };
        });
      }

      return [];
    }

    get negativeOperatorKeys() {
      return this.groupedOperatorKeys.filter(key => ['not_eq', 'not_match'].includes(key));
    }

  }

  class AutocompleteSubstringFilterInputBuilder extends _filterInput.FilterInputBuilder {
    buildFilterInputsFromOperator(operator) {
      if (operator.value.state === 'eq_group') {
        return (0, _ember.valueOf)(operator.filterValues).reduce((filterInputs, filterValue) => {
          return filterInputs.concat(this.convertFilterValueToFilterInputs(filterValue));
        }, []);
      }

      return super.buildFilterInputsFromOperator(operator);
    }

    convertFilterValueToFilterInputs(filterValue) {
      return Object.keys(filterValue).reduce((filterInputs, key) => {
        let newFilterInputs = filterValue[key].map(value => new _filterInput.FilterInput({
          completed: true,
          operator: key,
          value
        }));
        return filterInputs.concat(newFilterInputs);
      }, []);
    }

  }

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    defaultOperator: 'match',
    filterUpdater: AutocompleteSubstringFilterUpdater,
    filterInputBuilder: AutocompleteSubstringFilterInputBuilder,
    formatter: _autocompleteSubstringFormatter.default,
    searchTask: null,
    noMatchesMessage: '',
    operatorDescriptors: Ember.computed.readOnly('formatterObject.operatorDescriptors'),
    formatterObject: Ember.computed('categoryConfig', 'filter', 'formatter', function () {
      return new this.formatter(this.filter, this.categoryConfig);
    }),
    suggestionValuePath: Ember.computed('categoryConfig.suggestionValuePath', function () {
      return this.categoryConfig.suggestionValuePath || 'id';
    }),

    getOperatorBoolean(operator) {
      return !operator.includes('not');
    },

    init() {
      this._super(...arguments);

      if (!this.searchTask) {
        Ember.set(this, 'searchTask', this.categoryConfig.searchTask);
      }

      if (!this.noMatchesMessage) {
        Ember.set(this, 'noMatchesMessage', this.categoryConfig.noMatchesMessage);
      }
    },

    actions: {
      chooseExactMatch(filterInput, completeFilter, value) {
        let operatorValue = this.getOperatorBoolean(filterInput.operator) ? 'eq' : 'not_eq';
        Ember.set(filterInput, 'operator', operatorValue);
        completeFilter(value[this.suggestionValuePath]);
      },

      formatCompletedValue(filterInput) {
        let isPositive = this.getOperatorBoolean(filterInput.operator);
        let value = isPositive ? {
          [filterInput.operator]: [filterInput.value]
        } : filterInput.value;
        return this.formatterObject.formatValue(value, filterInput.operator);
      },

      performSearch(value) {
        let searchArguments = this.categoryConfig.searchArguments || [];
        return this.searchTask.perform(...searchArguments, value);
      }

    }
  });

  _exports.default = _default;
});