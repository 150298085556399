define("balance-ember/utils/writeable-models/budget", ["exports", "balance-ember/utils/writeable-models/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BudgetWriteableModel extends _basic.default {
    constructor(args) {
      if (!args.paths) {
        args.paths = {
          fiscalYear: true,
          budgetPrivileges: {
            user: true
          }
        };
      }

      super(args);
    }

  }

  _exports.default = BudgetWriteableModel;
});