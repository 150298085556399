define("balance-ember/components/side-nav/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h1TabPeS",
    "block": "{\"symbols\":[\"@space\",\"@testId\",\"@route\",\"@active\",\"@onclick\",\"&attrs\",\"&default\"],\"statements\":[[8,\"layout/stack/item\",[],[[\"@space\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"button\",[[24,0,\"\\n      text-material-gray700\\n      bg-transparent\\n      not-applied-hover:bg-material-blue50\\n      not-applied-tab-focus:bg-material-blue50\\n      applied:text-white\\n      applied:bg-material-light-blue500\\n      disabled:text-material-gray400\\n      w-full\\n      justify-start\\n      px-8\\n      py-10\\n      font-bold\"],[17,6]],[[\"@style\",\"@size\",\"@testId\",\"@route\",\"@active\",\"@onclick\"],[\"none\",\"none\",[32,2],[32,3],[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,7,null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/side-nav/button/template.hbs"
  });

  _exports.default = _default;
});