define("balance-ember/components/ui-modal/modal-footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['flex', 'justify-end'],
    classNameBindings: ['styleClasses'],
    style: 'slideout bordered',
    styleClasses: Ember.computed('style', function () {
      let style = '';

      if (this.style.includes('bordered')) {
        style = `${style} border-t border-material-gray200`;
      }

      if (this.style.includes('slideout')) {
        style = `${style} py-24 px-32 sticky bottom-0 mt-12 bg-white`;
      }

      if (this.style.includes('centered')) {
        style = `${style} py-14 px-24`;
      }

      return style;
    })
  });

  _exports.default = _default;
});