define("balance-ember/components/manage/bookmark-dropdown/row/reminder/specific-date/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BookmarkDropdownRowReminderSpecificDateComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class BookmarkDropdownRowReminderSpecificDateComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "selectedDate", _descriptor, this);

      this.selectedDate = this.formatReminderValue(this.initialDate);
    }

    get initialDate() {
      return this.hasReminder ? this.args.reminder.reminderValue : undefined;
    }

    get hasReminder() {
      return this.args.reminder.reminderValue;
    }

    formatReminderValue(date) {
      // Have to keep the input flowing into the text field the way it expects
      date = (0, _moment.default)(date, 'YYYY-MM-DD');
      return date.isValid() ? date.format('MM/DD/YYYY') : '';
    }

    updateDate(unmasked, masked) {
      this.selectedDate = masked;
      this.args.reminder.reminderValue = unmasked;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedDate", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateDate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateDate"), _class.prototype)), _class));
  _exports.default = BookmarkDropdownRowReminderSpecificDateComponent;
});