define("balance-ember/components/destroy-expense-modal/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A modal that can be used to destroy an expense
   */
  var _default = _component.default.extend({
    expense: null,
    modelChanges: Ember.inject.service(),

    /**
     * What to do after the expense has been destroyed.
     */
    onDestroy() {},

    /**
     * What to do when the modal is closed
     */
    onClose() {},

    actions: {
      cancelExpense() {
        this.onClose();
      },

      deleteExpense() {
        let expense = this.expense;
        return expense.destroyRecord().then(() => {
          let expenseType = Ember.String.camelize(expense.expenseType);
          this.modelChanges.addChange({
            id: expense.id,
            modelType: expenseType,
            changeType: 'delete'
          });
          this.onDestroy();
        });
      }

    }
  });

  _exports.default = _default;
});