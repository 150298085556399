define("balance-ember/components/layout/inline/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Naz0ihtH",
    "block": "{\"symbols\":[\"@grow\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"not-first:pl-\",[32,0,[\"space\"]],\" \",[30,[36,0],[[32,1],\"flex-grow\"],null]]]],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/layout/inline/item/template.hbs"
  });

  _exports.default = _default;
});