define("balance-ember/models/transaction", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.computedProperties = _exports.schema = void 0;
  const schema = {
    transactionDate: (0, _model.attr)(),
    status: (0, _model.attr)('string'),
    amount: (0, _model.attr)('number'),
    account: (0, _model.belongsTo)('account', {
      async: false
    }),
    tags: (0, _model.hasMany)('tags', {
      async: false
    }),
    supplements: (0, _model.attr)()
  };
  _exports.schema = schema;
  const computedProperties = {
    segments: Ember.computed.readOnly('account.segments'),
    segmentsHash: Ember.computed.readOnly('account.segmentsHash')
  };
  _exports.computedProperties = computedProperties;

  var _default = _model.default.extend(schema, computedProperties);

  _exports.default = _default;
});