define("balance-ember/components/power-select-single/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JiwFRud9",
    "block": "{\"symbols\":[\"suggestion\",\"@disabled\",\"@dropdownClass\",\"@noMatchesMessage\",\"@placeholder\",\"@search\",\"@options\",\"@groupComponent\",\"@searchMessage\",\"@searchEnabled\",\"@searchField\",\"@selected\",\"@triggerClass\",\"@triggerId\",\"@onchange\",\"&default\"],\"statements\":[[6,[37,1],[[32,12]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"selected-box\",[],[[\"@style\",\"@onDeselectClick\",\"@selected\",\"@disabled\"],[[32,0,[\"boxStyles\"]],[30,[36,0],[[32,0],\"deselect\"],null],[32,12],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"leading-none\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"font-bold\"],[12],[1,[32,12]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"autocomplete\",[],[[\"@disabled\",\"@dropdownClass\",\"@noMatchesMessage\",\"@placeholder\",\"@search\",\"@options\",\"@groupComponent\",\"@searchMessage\",\"@searchEnabled\",\"@searchField\",\"@selected\",\"@triggerClass\",\"@triggerId\",\"@onChange\"],[[32,2],[32,3],[32,4],[32,5],[32,6],[32,7],[32,8],[32,9],[32,10],[32,11],[32,12],[32,13],[32,14],[32,15]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,16,[[32,1]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "moduleName": "balance-ember/components/power-select-single/template.hbs"
  });

  _exports.default = _default;
});