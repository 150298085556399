define("balance-ember/components/overflowing-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jcW6aXzG",
    "block": "{\"symbols\":[\"@text\",\"@height\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"\\n  overflow-hidden\\n  text-overflow-ellipsis\\n  print:overflow-visible\\n  print:overflow-x-hidden\\n  print:whitespace-normal h-\",[32,2],\" print:h-auto\\n  \",[30,[36,0],[[32,0,[\"shouldTruncate\"]],\"flex items-center\"],null]]]],[17,3],[4,[38,1],[[32,0,[\"truncate\"]]],null],[12],[2,\"\\n  \"],[1,[32,1]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"shouldTruncate\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"tooltip\",[],[[\"@text\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"unless\",\"did-insert\",\"if\"]}",
    "moduleName": "balance-ember/components/overflowing-text/template.hbs"
  });

  _exports.default = _default;
});