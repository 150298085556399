define("balance-ember/components/table/filter-bar/clear-all/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PS+Y9hUU",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],[[30,[36,1],[\"table/filter-bar/clear-all/\",[32,0,[\"componentState\"]]],null]],[[\"clearAllFilters\",\"cancelClearAll\"],[[30,[36,0],[[32,0],\"clearAllFilters\"],null],[30,[36,0],[[32,0],\"cancelClearAll\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"concat\",\"component\"]}",
    "moduleName": "balance-ember/components/table/filter-bar/clear-all/template.hbs"
  });

  _exports.default = _default;
});