define("balance-ember/components/filter-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vsUELtu+",
    "block": "{\"symbols\":[\"filter\",\"@categories\",\"@trackingPrefix\",\"&default\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"filterList\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,1,[\"isValid\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,4,[[30,[36,2],null,[[\"filter\",\"completed\"],[[32,1],[30,[36,1],[\"filter-list/completed-filter\"],[[\"trackingPrefix\",\"categories\",\"updateFilter\",\"removeFilter\",\"filter\"],[[32,3],[32,2],[30,[36,0],[[32,0],\"updateFilter\",[32,1]],null],[30,[36,0],[[32,0],\"removeFilter\",[32,1]],null],[32,1]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,4,[[30,[36,2],null,[[\"filter\",\"add-button\"],[[32,1],[30,[36,1],[\"add-filter-button\"],[[\"trackingPrefix\",\"filter\",\"categories\",\"applyFilter\"],[[32,3],[32,1],[32,2],[30,[36,0],[[32,0],\"applyFilter\"],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/filter-list/template.hbs"
  });

  _exports.default = _default;
});