define("balance-ember/utils/format-bytes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatBytes;
  const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const THOUSAND = 1024;

  function formatBytes(bytes = 0, decimals = 0) {
    if (bytes === 0) return '0 Bytes';
    let radixPlaces = decimals < 0 ? 0 : decimals;
    let sizeIndex = Math.floor(Math.log(bytes) / Math.log(THOUSAND));
    let num = parseFloat((bytes / Math.pow(THOUSAND, sizeIndex)).toFixed(radixPlaces));
    return `${num} ${SIZES[sizeIndex]}`;
  }
});