define("balance-ember/components/manage/spending-by-segment/dropdown/info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8/I9DXRO",
    "block": "{\"symbols\":[\"@amountColor\",\"@amountLabel\",\"@amount\"],\"statements\":[[10,\"div\"],[14,0,\"flex items-center justify-between\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[\"rounded-full h-8 w-8 bg-\",[32,1]]]],[12],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-material-gray600 ml-12\"],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"font-bold text-material-gray700\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,3]],[[\"format\",\"currency\"],[\"$0,0\",\"dollars\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\"]}",
    "moduleName": "balance-ember/components/manage/spending-by-segment/dropdown/info/template.hbs"
  });

  _exports.default = _default;
});