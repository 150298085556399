define("balance-ember/components/manage/columns-dropdown/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    eventTracking: Ember.inject.service(),
    preferences: Ember.inject.service(),
    columnState: null,
    trackingPrefix: '',
    preferenceKey: '',
    oneColumnShowing: Ember.computed.equal('numVisibleColumns', 1),
    numVisibleColumns: Ember.computed.readOnly('visibleColumns.length'),
    visibleColumns: Ember.computed('hideableColumns', function () {
      return this.hideableColumns.filter(column => !column.entries.hidden.state);
    }),
    hideableColumns: Ember.computed('columnState', function () {
      return [...this.columnState].filter(column => column.entries.hideable.state);
    }),
    actions: {
      toggle(column, isChecked) {
        let trackingText = this.trackingText(column);
        this.eventTracking.trackEvent(trackingText, {
          column: column.entries.name.state
        });
        this.savePreference(column);
        column.entries.hidden.set(!isChecked);
      }

    },

    trackingText(column) {
      let action = column.entries.hidden.state ? 'Show' : 'Hide';
      return `${this.trackingPrefix} ${action} Column`;
    },

    savePreference(column) {
      if (this.preferenceKey) {
        this.preferences.toggleHiddenColumnsPreference(this.preferenceKey, column.entries.name.state);
        this.preferences.persist();
      }
    }

  });

  _exports.default = _default;
});