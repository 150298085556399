define("balance-ember/controllers/budgeting/segments", ["exports", "balance-ember/utils/urls"], function (_exports, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    availableFiscalYears: Ember.inject.service(),
    modelChanges: Ember.inject.service(),
    segmentsBulkUrl: Ember.computed('availableFiscalYears.current.id', function () {
      let fiscalYearId = this.availableFiscalYears.current.id;
      return `${(0, _urls.URLForBudgetNamespace)()}/fiscal_years/${fiscalYearId}/segments/bulk_upload`;
    }),
    segmentChanges: Ember.computed('modelChanges.changes.[]', function () {
      return this.modelChanges.changesForModelType('segment');
    }),
    actions: {
      afterUploadSuccess() {
        this.modelChanges.addChange({
          modelType: 'segment',
          changeType: 'create'
        });
      }

    }
  });

  _exports.default = _default;
});