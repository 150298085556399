define("balance-ember/helpers/truncate-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncateText = truncateText;
  _exports.default = void 0;

  function truncateText(str, len) {
    let value = str[0];
    let limit = len.limit;

    if (value === null || void 0) {
      return '--';
    }

    if (value.length === 0) {
      return '--';
    }

    if (value.length > limit) {
      return value.substring(0, limit - 3) + '...';
    }

    return value;
  }

  var _default = Ember.Helper.helper(truncateText);

  _exports.default = _default;
});