define("balance-ember/components/budget-module-no-allocations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jj6/0R6x",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"no-data-expenses\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isArchitectAndEditable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"table/no-data\",[],[[\"@svgName\",\"@noDataText\",\"@linkToPath\",\"@modelReference\",\"@buttonText\"],[\"na-allocations\",\"No allocations yet.\",\"budgeting.budget.expenses.view.allocations.new\",[32,0,[\"budget\"]],\"Add Allocation\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"table/no-data\",[],[[\"@svgName\",\"@budget\",\"@noDataText\"],[\"na-allocations\",[32,0,[\"budget\"]],\"No allocations yet.\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/budget-module-no-allocations/template.hbs"
  });

  _exports.default = _default;
});