define("balance-ember/components/slideout-budgeting-segments/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['preSelectedSegmentCount:stacked-layout'],
    wrapperClasses: 'css-allocation-and-segments-box bg-white border border-solid border-material-light-blue500 rounded-sm py-12 px-24',
    preSelectedSegmentCount: Ember.computed.lt('segments.length', 5)
  });

  _exports.default = _default;
});