define("balance-ember/utils/writeable-models/supplemental-pay-expense", ["exports", "balance-ember/utils/writeable-models/expense", "balance-ember/utils/sum-benefits"], function (_exports, _expense, _sumBenefits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let SupplementalPayExpenseWriteableModel = (_dec = Ember.computed('quantity', 'supplementalPayType', 'supplementalPayTypeAmount'), _dec2 = Ember.computed('benefitsAmount', 'quantity', 'supplementalPayType'), _dec3 = Ember.computed('benefitsPackage', 'quantity', 'supplementalPayType', 'supplementalPayTypeAmount'), _dec4 = Ember.computed('benefitsAmountByQuantity', 'quantity', 'supplementalPayType', 'supplementalPayTypeAmountByQuantity'), (_class = class SupplementalPayExpenseWriteableModel extends _expense.default {
    constructor(args) {
      if (!args.paths) {
        args.paths = {
          segments: {
            segmentType: true
          },
          allocation: {
            budget: true,
            segments: {
              segmentType: true
            }
          },
          employee: true,
          expenseStrategies: {
            budgetStrategy: {
              budgetPriority: true
            }
          },
          supplementalPayType: {
            benefitsPackage: {
              flatRateBenefits: true,
              percentageBenefits: true
            }
          }
        };
      }

      super(args);
    }

    get supplementalPayTypeAmountByQuantity() {
      return this.supplementalPayTypeAmount * this.quantity;
    }

    get supplementalPayTypeAmount() {
      var _this$supplementalPay;

      return ((_this$supplementalPay = this.supplementalPayType) === null || _this$supplementalPay === void 0 ? void 0 : _this$supplementalPay.amount) ?? 0;
    }

    get benefitsAmountByQuantity() {
      return this.benefitsAmount * this.quantity;
    }

    get benefitsAmount() {
      return (0, _sumBenefits.default)(this.benefitsPackage, this.supplementalPayTypeAmount);
    }

    get benefitsPackage() {
      var _this$supplementalPay2;

      return (_this$supplementalPay2 = this.supplementalPayType) === null || _this$supplementalPay2 === void 0 ? void 0 : _this$supplementalPay2.benefitsPackage;
    }

    get totalAmount() {
      return this.supplementalPayTypeAmountByQuantity + this.benefitsAmountByQuantity;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "supplementalPayTypeAmountByQuantity", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "supplementalPayTypeAmountByQuantity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "benefitsAmountByQuantity", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "benefitsAmountByQuantity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "benefitsAmount", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "benefitsAmount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalAmount", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "totalAmount"), _class.prototype)), _class));
  _exports.default = SupplementalPayExpenseWriteableModel;
});