define("balance-ember/components/pagination-controls/results-count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uo9neG4M",
    "block": "{\"symbols\":[\"@totalItems\",\"@isLoading\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"ease-all-1s leading-none \",[30,[36,1],[[32,2],\"opacity-50\"],null]]]],[12],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    Loading Results\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,1],\"Result\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pluralize\",\"if\"]}",
    "moduleName": "balance-ember/components/pagination-controls/results-count/template.hbs"
  });

  _exports.default = _default;
});