define("balance-ember/components/manage/summarize/cell/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/types/summarize"], function (_exports, _component, _summarize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @param
     */
    column: null,

    /**
     * @param
     */
    row: null,

    /**
     * @param
     */
    extra: null,
    tableState: Ember.computed.readOnly('extra.tableState'),
    fullSummarizeState: Ember.computed.readOnly('extra.summarizeState'),
    content: Ember.computed.readOnly('row.content'),
    summarizePath: Ember.computed.readOnly('content.summarizePath'),
    valuePath: Ember.computed.readOnly('content.valuePath'),
    summarizeValue: Ember.computed.readOnly('content.summarizeValue'),
    childSummarizeState: Ember.computed.readOnly('content.childSummarizeState'),
    isChildSwitchingCategories: Ember.computed.readOnly('content.isChildSwitchingCategories'),
    category: Ember.computed.readOnly('content.category'),
    availableCategories: Ember.computed.readOnly('content.availableCategories'),
    canExpand: Ember.computed.readOnly('hasDescendants'),
    hasDescendants: Ember.computed.readOnly('content.descendants'),
    isExpanded: Ember.computed.readOnly('content.isExpanded'),
    isLastRow: Ember.computed.readOnly('content.isLastRow'),
    isLoading: Ember.computed.readOnly('content.isLoading'),

    /**
     * summarizeState - Digging into the full summarize state here because the summarize states in the
     * `summarizePath` are essentially readOnly. We want to be able to add or remove summaries and
     * have it update the primary state object.
     */
    summarizeState: Ember.computed('fullSummarizeState', 'valuePath', function () {
      return (0, _summarize.dig)(this.fullSummarizeState, this.valuePath);
    }),
    componentName: Ember.computed('content.rowType', function () {
      return `manage/summarize/cell/${this.content.rowType}`;
    }),
    summarizeButtonText: Ember.computed('content.rowName', function () {
      return `Summarize ${this.content.rowName}`;
    }),
    actions: {
      toggleExpansion() {
        if (!this.canExpand) {
          return;
        }

        if (this.isExpanded) {
          this.removeSummary();
        } else {
          this.addNewSummary();
        }
      },

      addSummary(category) {
        if (this.isExpanded) {
          this.removeSummary();
        }

        if (this.tableState.sorts.length) {
          this.tableState.sorts.clear();
        }

        this.addNewSummary(category);
      },

      removeSummary() {
        this.removeSummary();
      }

    },

    addNewSummary(category) {
      let summary = {
        rows: []
      };

      if (category) {
        summary.category = category;
      }

      this.summarizeState.summaries.put(this.summarizeValue, summary);
    },

    removeSummary() {
      this.summarizeState.summaries.delete(this.summarizeValue);
    }

  });

  _exports.default = _default;
});