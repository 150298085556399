define("balance-ember/components/manage/accounts-table/index/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency", "balance-ember/classes/table-serializer", "balance-ember/utils/grand-total-stats"], function (_exports, _component, _emberConcurrency, _tableSerializer, _grandTotalStats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    availableBudgetLeas: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    segment: Ember.inject.service(),

    /**
     * @param
     */
    summarizeTableState: null,

    /**
     * @param
     */
    categories: null,
    grandTotals: null,
    tableState: Ember.computed.readOnly('summarizeTableState.table'),
    loadTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.loadRecords('accountBalance', { ...this.serializedTableState,
        ...(this.serializedTableState.sort ? {
          sort: this.serializedTableState.sort
        } : {
          sort: '-budgeted'
        }),
        stats: {
          total: 'count',
          budgeted: 'sum',
          spent: 'sum',
          encumbered: 'sum',
          available: 'sum',
          original_budget: 'sum'
        },
        include: 'account.segments.segment_type,account.budget_lea'
      }).then(results => {
        let grandTotals = (0, _grandTotalStats.pullOutGrandTotalStats)(results.meta.stats);
        Ember.set(this, 'grandTotals', grandTotals);
        return results;
      });
    }).restartable(),
    serializedTableState: Ember.computed('tableState', function () {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }),
    actions: {
      viewAccountDetails(column) {
        let accountCode = column.get('account.code');
        let currentBudgetLeaId = this.availableBudgetLeas.current.id;
        this.router.transitionTo('manage.accounts.details', currentBudgetLeaId, accountCode);
      }

    }
  });

  _exports.default = _default;
});