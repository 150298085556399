define("balance-ember/adapters/user-preference", ["exports", "balance-ember/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    // Without namespace: '' the request root would be /manage/api/v1/users
    // now it's just /users/
    namespace: ''
  });

  _exports.default = _default;
});