define("balance-ember/components/slideout-add-benefit/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    benefitId: Ember.computed.or('benefit.id', 'newBenefitId'),
    newBenefitId: Ember.computed('index', function () {
      return 'new-benefit-' + this.index;
    }),
    labelTarget: Ember.computed('benefitId', 'benefit.benefitType', function () {
      return `${this.benefit.benefitType}-${this.benefitId}`;
    }),

    init() {
      this._super(...arguments);

      this.set('percentageId', `percentage-benefit-${this.benefitId}`);
      this.set('flatRateId', `flat-rate-benefit-${this.benefitId}`);
    },

    autofocus(element, [shouldAutofocus = false]) {
      if (shouldAutofocus) element.querySelector('input').focus();
    },

    actions: {
      deleteBenefit() {
        this.removeBenefit();
      },

      chooseSegment(segment) {
        this.chooseSegment(segment);
      }

    }
  });

  _exports.default = _default;
});