define("balance-ember/controllers/freestyle", ["exports", "ember-freestyle/controllers/freestyle", "ember-concurrency", "@microstates/ember", "balance-ember/types/filter"], function (_exports, _freestyle, _emberConcurrency, _ember, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let filterList = (0, _ember.create)([_filter.default], [{
    category: 'pie',
    operators: [{
      value: 'match',
      filterValues: ['foo']
    }]
  }, {
    category: 'cake',
    operators: [{
      value: 'not_match',
      filterValues: ['bar']
    }]
  }, {
    category: 'strudel',
    operators: [{
      value: 'match',
      filterValues: ['baz']
    }]
  }]);

  var _default = _freestyle.default.extend({
    emberFreestyle: Ember.inject.service(),
    notifications: Ember.inject.service(),
    filterList,
    autocompleteOptions: Object.freeze([{
      name: 'foo'
    }, {
      name: 'bar'
    }, {
      name: 'baz'
    }]),
    dateFilter: Ember.computed(function () {
      return (0, _ember.create)(_filter.default, {
        type: 'date'
      });
    }),
    selectFilter: Ember.computed('selectColumns', function () {
      return (0, _ember.create)(_filter.default, {
        type: 'select',
        filterOptions: {
          options: this.selectColumns,
          valuePath: 'value',
          labelPath: 'name'
        }
      });
    }),
    decimalFilter: Ember.computed(function () {
      return (0, _ember.create)(_filter.default, {
        type: 'decimal'
      });
    }),
    currencyFilter: Ember.computed(function () {
      return (0, _ember.create)(_filter.default, {
        type: 'currency'
      });
    }),
    percentFilter: Ember.computed(function () {
      return (0, _ember.create)(_filter.default, {
        type: 'percent'
      });
    }),
    filterCategories: Ember.computed(function () {
      return [{
        title: 'Pie',
        value: 'pie'
      }, {
        title: 'Cake',
        value: 'cake',
        icon: 'dollar'
      }, {
        title: 'Strudel',
        value: 'strudel'
      }, {
        title: 'Cobbler',
        value: 'cobbler'
      }, {
        title: 'Ice Cream',
        value: 'ice_cream',
        subcategories: [{
          title: 'Vanilla',
          value: 'vanilla'
        }, {
          title: 'Chocolate',
          value: 'chocolate'
        }]
      }];
    }),
    searchTask: (0, _emberConcurrency.task)(function* () {
      return yield [{
        name: 'pie',
        value: 'yum'
      }, {
        name: 'cake',
        value: 'no'
      }, {
        name: 'strudel',
        value: 'for-sure'
      }, {
        name: 'cobbler',
        value: 'you-bet'
      }];
    }),
    loadTableTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(2000);
      return this.tableData;
    }),
    saved: false,
    saveTask: (0, _emberConcurrency.task)(function* () {
      yield Ember.set(this, 'saved', true);
    }),
    tableData: Ember.computed(function () {
      return [{
        id: 1,
        name: 'pie',
        shouldEat: true,
        description: 'So so so so tasty',
        details: 'Pie comes in oh so many forms!',
        canExpand: true
      }, {
        id: 2,
        name: 'cake',
        shouldEat: false,
        description: 'Dry, why bother',
        details: 'Obviously the coder who wrote this list disliked pie. But it\'s okay to be wrong!',
        canExpand: true
      }, {
        id: 3,
        name: 'strudel',
        shouldEat: true,
        description: 'Great for breakfast with icing',
        details: 'Say strudel five times fast!',
        canExpand: true
      }, {
        id: 4,
        name: 'cobbler',
        shouldEat: true,
        description: 'Excellent in a dutch oven',
        canExpand: false
      }];
    }),
    overflowingTableData: Ember.computed(function () {
      return [{
        name: 'pie',
        shouldEat: true,
        description: 'fooddfadfdsjaslkja;sldkfjasd;lkfjasd;flkasdjf;dslk'
      }, {
        name: 'cake',
        shouldEat: false,
        description: 'Dry, why bother'
      }];
    }),
    actionColumns: Ember.computed(function () {
      return [{
        label: 'Dessert',
        valuePath: 'name'
      }, {
        label: 'Should Eat?',
        valuePath: 'shouldEat'
      }, {
        label: 'Actions',
        cellComponent: 'light-table/cell-components/table-actions',
        tableActionComponents: ['edit', 'delete', 'recent-changes'],
        align: 'right',

        buttonText(dessert) {
          return dessert.name;
        },

        recentChanges: {
          recordDescription() {}

        }
      }];
    }),
    actionDropdownColumns: Ember.computed(function () {
      return [{
        label: 'Dessert',
        valuePath: 'name'
      }, {
        label: 'Should Eat?',
        valuePath: 'shouldEat'
      }, {
        label: 'Actions',
        cellComponent: 'light-table/cell-components/actions-dropdown-cell',
        dropdownComponent: 'manage-permissions-table/actions-cell',
        align: 'right',

        buttonText(dessert) {
          return dessert.name;
        }

      }];
    }),
    tableColumns: Ember.computed(function () {
      return [{
        label: 'Dessert',
        valuePath: 'name'
      }, {
        label: 'Should Eat?',
        valuePath: 'shouldEat'
      }, {
        label: 'Description',
        valuePath: 'description',
        cellComponent: 'overflowing-table-cell'
      }];
    }),
    tableExpandableColumns: Ember.computed(function () {
      return [{
        label: 'Dessert',
        valuePath: 'name',
        cellComponent: 'light-table/cell-components/chevron-cell'
      }, {
        label: 'Should Eat?',
        valuePath: 'shouldEat'
      }, {
        label: 'Description',
        valuePath: 'description',
        cellComponent: 'overflowing-table-cell'
      }];
    }),
    linkTableData: Ember.computed(function () {
      return [{
        name: 'Chocolate Cake',
        shouldEat: 'Yes, please',
        linkValue: 'Cake Budget'
      }, {
        name: 'Pumpkin Pie',
        shouldEat: 'But of course',
        linkValue: 'Pie Budget'
      }];
    }),
    linkTableColumns: Ember.computed(function () {
      return [{
        label: 'Dessert',
        valuePath: 'name'
      }, {
        label: 'Should Eat?',
        valuePath: 'shouldEat'
      }, {
        label: 'Link',
        valuePath: 'linkValue',
        cellComponent: 'light-table/cell-components/link-cell',
        route: ['budgeting.budget.expenses', 'budget.id']
      }];
    }),
    numberTableData: Ember.computed(function () {
      return [{
        integerValue: 150000,
        moneyValue: 150000,
        abbreviatedValue: 150000
      }, {
        integerValue: -150000,
        moneyValue: -150000,
        abbreviatedValue: -150000
      }];
    }),
    numberTableColumns: Ember.computed(function () {
      return [{
        label: 'Money',
        valuePath: 'moneyValue',
        cellComponent: 'number-cell'
      }, {
        label: 'Integer',
        valuePath: 'integerValue',
        cellComponent: 'number-cell',
        format: '0,0'
      }, {
        label: 'Abbreviated',
        valuePath: 'abbreviatedValue',
        cellComponent: 'number-cell',
        shouldAbbreviate: true
      }];
    }),
    tooltipTableColumns: Ember.computed(function () {
      return [{
        label: 'Dessert',
        valuePath: 'name'
      }, {
        label: 'Description',
        cellComponent: 'tooltip-cell',

        tooltipText(record) {
          return record.description;
        },

        cellText() {
          return '📖';
        }

      }];
    }),
    selectColumns: Ember.computed(function () {
      return [{
        name: 'pie',
        value: 'yum'
      }, {
        name: 'cake',
        value: 'no'
      }, {
        name: 'strudel',
        value: 'for-sure'
      }, {
        name: 'cobbler',
        value: 'you-bet'
      }];
    }),
    modalErrors: Ember.computed(function () {
      return ['got broke', 'is messed up'];
    }),
    fooErrors: Ember.computed(function () {
      return ['foo is broken', 'foo is messed up'];
    }),
    barErrors: Ember.computed(function () {
      return ['bar is broken'];
    }),
    actions: {
      testNotifications() {
        this.notifications.success('Success Added This is a really long message, whats gonna happen?!');
        this.notifications.info('Info Added!');
        this.notifications.warning('Warning Added!');
        this.notifications.error('Error Added!');
      },

      testFastNotifications() {
        this.notifications.success('Success Added!', {
          autoClear: true,
          clearDuration: 1200
        });
        this.notifications.info('Info Added!', {
          autoClear: true,
          clearDuration: 1200
        });
        this.notifications.warning('Warning Added!', {
          autoClear: true,
          clearDuration: 1200
        });
        this.notifications.error('Error Added!', {
          autoClear: true,
          clearDuration: 1200
        });
      },

      testStuckNotifications() {
        this.notifications.success("Success message that doesn't auto-hide", {
          autoClear: false
        });
      },

      afterUploadSuccess() {
        this.notifications.success('Uploaded');
      },

      updateDate(unmasked, masked) {
        Ember.set(this, 'myDate', masked);
      },

      makeFilterValid(filter) {
        filter.category.set('category').match.set({
          value: 'match'
        }).values.set([{
          value: 'val'
        }]);
      },

      updateFilterCategory(filter, category) {
        filter.category.set(category);
      }

    }
  });

  _exports.default = _default;
});