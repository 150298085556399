define("balance-ember/components/user/profile-tabs/account-info/edit/roles/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let UserProfileTabsAccountInfoEditRolesComponent = (_dec = Ember.computed('args.user.districtRoles.[]', 'districtRoles'), _dec2 = Ember._action, (_class = class UserProfileTabsAccountInfoEditRolesComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "districtRoles", [{
        value: 'central_office_finance',
        label: 'Central Office Finance'
      }, {
        value: 'central_office_non_finance',
        label: 'Central Office (non-finance)'
      }, {
        value: 'principal',
        label: 'Principal'
      }, {
        value: 'school_finance',
        label: 'School Finance'
      }, {
        value: 'district_leadership',
        label: 'District Leadership'
      }]);
    }

    get userDistrictRoles() {
      let roles = this.args.user.districtRoles ?? [];
      return roles.map(role => {
        return this.districtRoles.findBy('value', role);
      });
    }

    updateDistrictRoles(roles) {
      this.args.user.districtRoles = roles.mapBy('value');
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "userDistrictRoles", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "userDistrictRoles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDistrictRoles", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateDistrictRoles"), _class.prototype)), _class));
  _exports.default = UserProfileTabsAccountInfoEditRolesComponent;
});