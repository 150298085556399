define("balance-ember/components/light-table/cells/allovue-cell/component", ["exports", "ember-light-table/components/cells/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    init() {
      this._super(...arguments);

      let attributeBindings = this.attributeBindings;
      let testSelector = this.column.testSelector;
      Ember.set(this, 'attributeBindings', attributeBindings.concat([`testSelector:data-test-${testSelector}`, 'testId:data-test-id']));
    },

    testSelector: Ember.computed.readOnly('value'),
    testId: Ember.computed('column.testSelector', 'value', function () {
      if (Ember.isBlank(this.value)) {
        return `column-${this.column.testSelector}`;
      }

      return `column-${this.column.testSelector}-${this.value}`;
    })
  });

  _exports.default = _default;
});