define("balance-ember/components/benefits-package-table/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        label: 'PACKAGE NAME',
        valuePath: 'name',
        sortName: 'name',
        testSelector: 'package-name'
      }, {
        label: 'BENEFITS',
        valuePath: 'benefits',
        sortable: false,
        hideable: false,
        testSelector: 'benefits',
        cellComponent: 'benefits-package-table/benefit-cell'
      }, {
        label: 'BENEFIT AMOUNTS',
        valuePath: 'benefits',
        sortable: false,
        hideable: false,
        testSelector: 'benefit-amounts',
        align: 'right',
        width: '145px',
        cellComponent: 'benefits-package-table/benefit-amount-cell'
      }, {
        cellComponent: 'light-table/cell-components/table-actions',
        tableActionComponents: ['edit', 'delete'],
        sortable: false,
        hideable: false,
        align: 'right',
        width: '80px',

        buttonText(benefitsPackage) {
          return benefitsPackage.name;
        },

        isDeleteDisabled(benefitsPackage) {
          return !benefitsPackage.deletable;
        },

        deleteTooltipText(benefitsPackage) {
          if (!benefitsPackage.deletable) {
            return `${benefitsPackage.name} is being used by a job type and can't be deleted`;
          }

          return `Delete ${benefitsPackage.name}`;
        }

      }];
    }),
    loadPackages: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('benefitsPackage', Object.assign({}, this.tableState.apiQuery, {
        include: 'flat_rate_benefits.segment,percentage_benefits.segment'
      }));
    }),
    actions: {
      edit(benefitsPackage) {
        this.router.transitionTo('budgeting.benefits-packages.edit', benefitsPackage.id);
      },

      delete(benefitsPackage) {
        this.router.transitionTo('budgeting.benefits-packages.destroy', benefitsPackage.id);
      }

    }
  });

  _exports.default = _default;
});