define("balance-ember/controllers/budgeting/fiscal-year/allocations", ["exports", "balance-ember/mixins/table-controller"], function (_exports, _tableController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ALLOCATIONS_PAGE_SIZE = void 0;
  const ALLOCATIONS_PAGE_SIZE = 25;
  _exports.ALLOCATIONS_PAGE_SIZE = ALLOCATIONS_PAGE_SIZE;

  var _default = Ember.Controller.extend(_tableController.default, {
    init() {
      this._super(...arguments);

      this.tableState.pagination.itemsPerPage.set(ALLOCATIONS_PAGE_SIZE);
      this.tableState.defaultSort.set({
        column: 'budget_name',
        dir: 'asc'
      });
    },

    categories: Ember.computed(function () {
      return [{
        value: 'fiscal_year_id',
        removable: false
      }, {
        value: 'budget_name',
        title: 'Budget',
        type: 'text'
      }, {
        value: 'allocation_name',
        title: 'Allocation',
        type: 'text'
      }, {
        value: 'fte',
        title: 'FTE',
        subcategories: [{
          title: 'Vacant',
          value: 'vacant_fte',
          type: 'decimal'
        }, {
          title: 'Remaining',
          value: 'remaining_fte',
          type: 'decimal'
        }]
      }, {
        value: 'amount',
        title: 'Amount',
        icon: 'dollar',
        subcategories: [{
          value: 'allocated_amount',
          title: 'Allocated',
          type: 'currency'
        }, {
          value: 'planned_amount',
          title: 'Planned',
          type: 'currency'
        }, {
          value: 'remaining_amount',
          title: 'Remaining',
          type: 'currency'
        }]
      }, {
        value: 'salary_type',
        title: 'Salary Type',
        type: 'select',
        filterOptions: {
          options: [{
            name: 'Actual',
            value: 'actual'
          }, {
            name: 'Estimated',
            value: 'estimated'
          }],
          valuePath: 'value',
          labelPath: 'name'
        }
      }];
    })
  });

  _exports.default = _default;
});