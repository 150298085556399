define("balance-ember/components/budget-progress-widget/budget-approval-total/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MJafYIj4",
    "block": "{\"symbols\":[\"@icon\",\"@label\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,0],[\"summary-stat \",\"border-material-gray300 \",\"h-full \",\"not-first:pl-16 \",\"not-last:pr-32 \",\"flex \",\"items-center \",\"flex-grow \"],null]],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"text-2xl font-bold mr-8 text-material-gray700\"],[12],[2,\"\\n        \"],[1,[32,0,[\"total\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[[32,1],\"w-20 h-20\"]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-sm text-material-gray700\"],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "moduleName": "balance-ember/components/budget-progress-widget/budget-approval-total/template.hbs"
  });

  _exports.default = _default;
});