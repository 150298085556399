define("balance-ember/routes/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    isBudgetArchitect: Ember.computed.readOnly('session.isBudgetArchitect'),
    hasBudgetingAccess: Ember.computed.readOnly('session.hasBudgetingAccess'),

    redirect() {
      let url = this._router.location.formatURL('/not-found');

      if (!this.isBudgetArchitect || !this.hasBudgetingAccess) {
        this.transitionTo('/not-found');
      } else if (window.location.pathname !== url) {
        this.intermediateTransitionTo('/not-found');
      }
    },

    activate() {
      document.title = 'Not Found - Allovue Balance';
    }

  });

  _exports.default = _default;
});