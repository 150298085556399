define("balance-ember/controllers/users/resend-confirmation", ["exports", "ember-concurrency", "balance-ember/utils/urls"], function (_exports, _emberConcurrency, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UsersResendConfirmationController = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    queryParams: {
      identification: {
        refreshModel: true
      }
    },
    resendConfirmation: (0, _emberConcurrency.task)(function* () {
      return yield this.ajax.request((0, _urls.URLForResendConfirmation)(), {
        data: {
          user: {
            email: this.identification
          }
        },
        method: 'POST'
      }).then(() => {
        this.set('errorMessage', null);
        this.notifications.success(`Confirmation instructions sent to: ${this.identification}`);
        return this.transitionToRoute('login');
      }).catch(({
        payload
      }) => {
        this.set('errorMessage', payload.errors);
      });
    })
  });
  var _default = UsersResendConfirmationController;
  _exports.default = _default;
});