define("balance-ember/components/budget/expense-table/edit-segments/segment-type/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BudgetNonPersonnelExpensesTableEditFundingSegmentTypeComponent extends _component.default {
    get segmentsMessage() {
      var _this$args$errors;

      let error = (_this$args$errors = this.args.errors) === null || _this$args$errors === void 0 ? void 0 : _this$args$errors.all.findBy('attribute', 'segments');
      return error === null || error === void 0 ? void 0 : error.message;
    }

  }

  _exports.default = BudgetNonPersonnelExpensesTableEditFundingSegmentTypeComponent;
});