define("balance-ember/routes/budgeting/budget/expenses/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect() {
      let allocations = this.modelFor('budgeting.budget.expenses');
      let sortedAllocations = allocations.sortBy('name'); // We store the most recently viewed allocation on the view controller via the view route.
      // If there's a currentAllocationID set, we use that, otherwise we default to showing the
      // allocation at the top of the sorted list.
      // See also routes/budgeting/budget/expenses/view.js
      //Also because we're using controllerFor here, we need to have a hard-coded expenses/view controller

      if (Ember.isPresent(sortedAllocations)) {
        let currentAllocationID = this.controllerFor('budgeting.budget.expenses.index').get('currentAllocationID');
        let firstAllocationID = sortedAllocations.firstObject.id;
        let allocationRedirectID = currentAllocationID || firstAllocationID;
        this.replaceWith('budgeting.budget.expenses.view', allocationRedirectID);
      }
    }

  });

  _exports.default = _default;
});