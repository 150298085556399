define("balance-ember/routes/budgeting/overview/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    redirect() {
      this.replaceWith('budgeting.overview.dashboard');
    }

  });

  _exports.default = _default;
});