define("balance-ember/components/manage/spending-by-segment/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/classes/table-serializer", "balance-ember/classes/summarize-query-params", "ember-concurrency", "balance-ember/types/table", "balance-ember/types/summarize", "@microstates/ember", "balance-ember/utils/urls", "balance-ember/utils/morph-keys"], function (_exports, _component, _tableSerializer, _summarizeQueryParams, _emberConcurrency, _table, _summarize, _ember, _urls, _morphKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const segmentPrefix = 'segment_';
  const params = {
    paginationLimit: 10
  };
  const tableState = (0, _ember.create)(_table.default, {
    pagination: {
      itemsPerPage: params.paginationLimit
    }
  });

  const columns = segmentTypeTitle => {
    return [{
      label: segmentTypeTitle,
      valuePath: 'segmentName',
      cellComponent: 'manage/spending-by-segment/cell-name',
      sortable: false,
      testSelector: 'name'
    }, {
      label: 'Budgeted',
      valuePath: 'budgeted',
      classNames: 'w-80 lg:w-96',
      cellClassNames: 'w-80 lg:w-96',
      cellComponent: 'number-cell',
      shouldAbbreviate: true,
      align: 'right',
      sortable: false,
      columnValue: 'budgeted',
      testSelector: 'budgeted-amount'
    }, {
      label: 'Utilized',
      component: 'manage/spending-by-segment/column-header-utilized',
      cellComponent: 'manage/spending-by-segment/cell-utilized',
      sortable: false,
      testSelector: 'utilized-amount'
    }, {
      label: 'Remaining',
      valuePath: 'available',
      classNames: 'w-96',
      cellClassNames: 'font-bold w-96',
      cellComponent: 'number-cell',
      shouldAbbreviate: true,
      align: 'right',
      sortable: false,
      columnValue: 'available',
      //used in the abbreviated amount cell for a test selector
      testSelector: 'remaining-amount'
    }];
  };

  var _default = _component.default.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    availableBudgetLeas: Ember.inject.service(),
    currentBudgetLea: Ember.computed.readOnly('availableBudgetLeas.current'),
    leaId: Ember.computed.readOnly('currentBudgetLea.id'),
    tableState: (0, _ember.state)(tableState),
    summarizeState: (0, _ember.state)((0, _ember.create)(_summarize.default)),
    hasSegmentTypes: Ember.computed.notEmpty('currentBudgetLea.sortedSegmentTypes'),
    segmentType: Ember.computed('summarizeState.category.entries.value.state', {
      get() {
        let name = this.summarizeState.category.entries.value.state.replace(segmentPrefix, '');
        let title = name.humanize().titleize();
        return {
          name,
          title
        };
      },

      set(key, value) {
        this.summarizeState.set({
          category: {
            value: `${segmentPrefix}${value.name}`
          }
        });
        return value;
      }

    }),
    segmentTypes: Ember.computed.readOnly('currentBudgetLea.sortedSegmentTypes'),
    columns: Ember.computed('segmentType.title', function () {
      return columns(this.segmentType.title);
    }),
    summarizeQueryString: Ember.computed('summarizeState', function () {
      return new _summarizeQueryParams.SummarizeQueryParamSerializer(this.summarizeState).stringify();
    }),
    serializedApiQuery: Ember.computed('tableState', function () {
      return new _tableSerializer.TableQueryParams(this.tableState).stringify();
    }),
    apiQueryFilter: Ember.computed('serializedTableState.filter', 'tableState', function () {
      return { ...this.serializedTableState.filter,
        ...new _tableSerializer.default(this.tableState).apiQuery.filter
      };
    }),
    page: Ember.computed('tableState', function () {
      return new _tableSerializer.default(this.tableState).apiQuery.page;
    }),
    loadAccountBalances: (0, _emberConcurrency.task)(function* () {
      // Using plain ajax here because the results are NOT account balances. The results are aggregations
      // of account balances. Therefore we don't want them turned into ember data models. We don't want
      // them clobbering records of real account balances in the store.
      let url = `${(0, _urls.URLForAccountBalances)()}/summaries/segments`;
      let accountBalances = yield this.ajax.request(url, {
        data: {
          filter: {
            user_filters: this.apiQueryFilter
          },
          value: this.segmentType.name,
          page: this.page,
          stats: {
            total: 'count'
          }
        }
      }).then(results => this.normalizeJSON(results));
      return accountBalances;
    }),

    normalizeJSON(json) {
      let meta = json.meta;
      json = json.data.map(record => (0, _morphKeys.camelizeKeys)(record.attributes));
      json.meta = meta;
      return json;
    },

    initializeFilters() {
      this.tableState.updateFilters([{
        category: 'budget_lea_id',
        operators: [{
          value: 'eq',
          filterValues: [this.leaId]
        }]
      }]);
    },

    initializeSegmentType(segmentType) {
      this.summarizeState.set({
        category: {
          value: `${segmentPrefix}${segmentType.name}`
        }
      });
    },

    actions: {
      changedSegment(newSegmentType) {
        this.tableState.rows.clear();
        this.tableState.pagination.page.set(1);
        Ember.set(this, 'segmentType', newSegmentType);
        this.loadAccountBalances.perform();
      },

      setPage(page) {
        this.tableState.rows.clear();
        this.tableState.pagination.page.set(page);
        this.loadAccountBalances.perform();
      },

      explore() {
        let query = this.serializedApiQuery;
        let summarize = this.summarizeQueryString;
        this.router.transitionTo('manage.accounts', {
          queryParams: {
            query,
            summarize
          }
        });
      }

    },

    didReceiveAttrs() {
      this._super();

      if (this.hasSegmentTypes) {
        this.initializeFilters();
        this.initializeSegmentType(this.segmentTypes.firstObject);
      }
    }

  });

  _exports.default = _default;
});