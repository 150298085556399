define("balance-ember/components/planned-spending-by-segment/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency", "balance-ember/types/table", "@microstates/ember", "ember-inflector"], function (_exports, _component, _emberConcurrency, _table, _ember, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    segmentTypes: null,
    chosenSegmentType: null,
    chosenSegmentTypeId: Ember.computed.readOnly('chosenSegmentType.id'),
    singularizedSegmentTypeName: Ember.computed('chosenSegmentType', function () {
      let segmentType = this.chosenSegmentType || {};
      return (0, _emberInflector.singularize)(segmentType.name);
    }),
    tableState: (0, _ember.state)((0, _ember.create)(_table.default, {
      pagination: {
        itemsPerPage: 10
      }
    })),
    columns: null,
    page: Ember.computed.readOnly('tableState.pagination.page.state'),
    itemsPerPage: Ember.computed.readOnly('tableState.pagination.itemsPerPage.state'),
    features: Ember.inject.service(),
    positionBudgeting: Ember.computed.readOnly('features.positionBudgeting'),

    didReceiveAttrs() {
      this._super();

      Ember.set(this, 'chosenSegmentType', this.segmentTypes.firstObject);
      this.loadTask.perform();
    },

    plannedSpendingColumns: Ember.computed('amountColumns', 'chosenSegmentType', 'personnelColumns', 'segmentColumn', 'summarizeColumn', function () {
      return [this.segmentColumn, ...this.personnelColumns];
    }),
    segmentColumn: Ember.computed('chosenSegmentType', 'columns.[]', 'singularizedSegmentTypeName', function () {
      return {
        label: this.singularizedSegmentTypeName,
        valuePath: 'segmentName',
        testSelector: 'segment-name',
        cellComponent: 'overflowing-table-cell',
        cellClassNames: 'whitespace-nowrap'
      };
    }),
    personnelColumns: Ember.computed('chosenSegmentType', 'columns.[]', 'plannedAmount', 'positionBudgeting', function () {
      let cols = [{
        label: this.positionBudgeting ? 'Personnel as % of Total' : 'Total Planned in $',
        cellComponent: this.positionBudgeting ? 'planned-spending-by-segment/personnel-percentage-cell' : 'planned-spending-by-segment/total-percentage-cell',
        extra: {
          'budgetPlannedAmount': this.plannedAmount
        }
      }];

      if (this.positionBudgeting) {
        cols.push({
          label: 'FTE',
          valuePath: 'totalFte',
          testSelector: 'total-fte',
          cellComponent: 'number-cell',
          format: '0,0[.]00',
          align: 'right',
          width: '80px'
        });
      }

      return cols;
    }),

    getNewTableObject({
      subtotalsResponse,
      pagination
    } = {}) {
      return Object.assign({}, (0, _ember.valueOf)(this.tableState), {
        rows: subtotalsResponse.toArray(),
        pagination
      });
    },

    getNewPaginationObject(paginationResponse) {
      let {
        totalCount
      } = paginationResponse;
      return Object.assign({}, (0, _ember.valueOf)(this.tableState.pagination), {
        totalItems: totalCount
      });
    },

    loadTask: (0, _emberConcurrency.task)(function* () {
      if (this.chosenSegmentTypeId) {
        return yield this.store.query('segmentSubtotal', {
          segment_type_id: this.chosenSegmentTypeId,
          page: {
            number: this.page,
            size: this.itemsPerPage
          }
        }).then(subtotals => {
          let pagination = this.getNewPaginationObject(subtotals.meta.pagination);
          let newState = this.getNewTableObject({
            subtotalsResponse: subtotals,
            pagination
          });
          this.tableState.set(newState);
          return subtotals;
        });
      }
    }),
    actions: {
      updateSegmentType({
        id
      }) {
        Ember.set(this, 'chosenSegmentType', this.segmentTypes.findBy('id', id));
        this.tableState.pagination.page.set(1);
        this.loadTask.perform();
      },

      setPage(page) {
        this.tableState.pagination.page.set(page);
        this.loadTask.perform();
      }

    }
  });

  _exports.default = _default;
});