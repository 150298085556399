define("balance-ember/components/ui-hooks/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Provides component UI hooks for route-level controllers and templates.
   *
   * See https://embermap.com/video/component-hooks-at-the-route-level
   */
  var _default = _component.default.extend({
    'did-insert'() {},

    'did-receive'() {},

    'will-destroy'() {},

    didInsert: Ember.computed.alias('did-insert'),
    didReceive: Ember.computed.alias('did-receive'),
    willDestroy: Ember.computed.alias('will-destroy'),

    didInsertElement() {
      this._super(...arguments);

      this.didInsert();
    },

    didReceiveAttrs() {
      this._super();

      this.didReceive();
    },

    willDestroyElement() {
      this._super(...arguments);

      this.willDestroy();
    }

  });

  _exports.default = _default;
});