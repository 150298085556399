define("balance-ember/components/one-way-radio/component", ["exports", "balance-ember/components/one-way-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    attributeBindings: _component.DEFAULT_ATTRIBUTES.concat('checked', 'name'),
    type: 'radio',
    option: null,
    checked: Ember.computed('value', 'option', function () {
      return this.value === this.option;
    }),

    /**
     * @override
     */
    input() {},

    click() {
      this.update(this.option);
    }

  });

  _exports.default = _default;
});