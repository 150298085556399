define("balance-ember/classes/bookmark-query-param-builder", ["exports", "balance-ember/utils/query-params"], function (_exports, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BookmarkQueryParamBuilder {
    constructor(bookmark, budgetLeaId) {
      this.bookmark = bookmark;
      this.budgetLeaId = budgetLeaId;
    }

    build() {
      let params = this.addBudgetLeaFilter();
      this.removeSummarize(params);
      return _queryParams.default.stringify(params);
    }

    addBudgetLeaFilter() {
      let params = { ...this.bookmark.params
      };
      let filterParams = params.filter;

      if (filterParams) {
        filterParams = { ...filterParams,
          budget_lea_id: {
            eq: this.budgetLeaId
          }
        };
        params.filter = filterParams;
      }

      return params;
    }

    removeSummarize(params) {
      delete params.summarize;
    }

    get summarizeQueryString() {
      if (!this.bookmark.params) {
        return undefined;
      }

      let summarize = this.bookmark.params.summarize;

      if (!summarize) {
        return undefined;
      }

      return _queryParams.default.stringify(summarize);
    }

  }

  _exports.default = BookmarkQueryParamBuilder;
});