define("balance-ember/components/manage/transaction-status-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3G5LjSju",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[10,\"div\"],[15,0,[32,0,[\"statusStyle\"]]],[12],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],[[30,[36,0],[\"manage.transaction.status.\",[32,1]],null]],null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\",\"titleize\"]}",
    "moduleName": "balance-ember/components/manage/transaction-status-cell/template.hbs"
  });

  _exports.default = _default;
});