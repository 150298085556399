define("balance-ember/app", ["exports", "balance-ember/resolver", "ember-load-initializers", "balance-ember/config/environment", "@ember-data/model", "ember-data-storefront/mixins/snapshottable", "ember-data-storefront/mixins/loadable-model", "balance-ember/mixins/operations", "@appsignal/javascript", "@appsignal/ember"], function (_exports, _resolver, _emberLoadInitializers, _environment, _model, _snapshottable, _loadableModel, _operations, _javascript, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const appsignal = new _javascript.default({
    key: _environment.default.appsignalKey
  });
  (0, _ember.installErrorHandler)(appsignal, Ember);
  let App;
  App = Ember.Application.extend({
    // NOTE: We've added customEvents per the instructions from Ember.Evented. To
    // find out more, go here: http://emberjs.com/api/classes/Ember.Application.html#property_customEvents
    // - Ted (2016-02-14)
    init() {
      this._super(...arguments);

      Ember.set(this, 'customEvents', {
        headerclick: 'header-click',
        itemmouseover: 'item-mouse-over',
        itemmouseout: 'item-mouse-out',
        itemclick: 'item-click',
        scrollend: 'scroll-end'
      });
    },

    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  _model.default.reopen(_snapshottable.default);

  _model.default.reopen(_loadableModel.default);

  _model.default.reopen(_operations.default);

  var _default = App;
  _exports.default = _default;
});