define("balance-ember/initializers/ember-freestyle", ["exports", "balance-ember/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global requirejs, require */
  function initialize() {
    const application = arguments[1] || arguments[0];
    let prefix = _environment.default.modulePrefix;
    let freestyleModuleRegExp = new RegExp(`^${prefix}/(freestyle-snippets)`);
    Object.keys(requirejs.entries).filter(function (key) {
      return freestyleModuleRegExp.test(key);
    }).forEach(function (moduleName) {
      let module = require(moduleName, null, null, true);

      let freestyleModule = module['default'];
      let moduleKey = moduleName.split('/').reverse()[0];
      let registryKey = `config:ember-freestyle-${moduleKey}`;
      application.register(registryKey, freestyleModule, {
        instantiate: false
      });
      application.inject('service:ember-freestyle', moduleKey, registryKey);
    });
  }

  var _default = {
    name: 'ember-freestyle',
    initialize: initialize
  };
  _exports.default = _default;
});