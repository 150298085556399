define("balance-ember/templates/budgeting/fiscal-year/budgets/allocation/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "esdZlxmh",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"allocation-form-data\",[],[[\"@allocation\"],[[32,0,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"allocation-form\",[],[[\"@allocation\",\"@budgets\",\"@segmentTypes\",\"@redirectRoute\",\"@formTitle\",\"@disabledButtonText\",\"@actionButtonText\"],[[32,1,[\"allocation\"]],[32,1,[\"budgets\"]],[32,1,[\"segmentTypes\"]],\"budgeting.fiscal-year.budgets\",\"Edit Allocation\",\"Saving\",\"Save Changes\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/budgeting/fiscal-year/budgets/allocation/edit.hbs"
  });

  _exports.default = _default;
});