define("balance-ember/models/benefits-package", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.benefitsPackageComputedProperties = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true),
    // We want to tell the validator to look at the validation rules for the benefits models
    benefits: (0, _emberCpValidations.validator)('has-many')
  }, {
    // Global option to prevent any validation from running until we've pressed 'save'
    disabled: Ember.computed.not('model.shouldValidate')
  });
  const benefitsPackageComputedProperties = {
    benefits: Ember.computed.union('flatRateBenefits', 'percentageBenefits')
  };
  _exports.benefitsPackageComputedProperties = benefitsPackageComputedProperties;

  var _default = _model.default.extend(validations, {
    fiscalYear: (0, _model.belongsTo)('fiscalYear', {
      async: false
    }),
    acceptsNestedAttributesFor: Ember.computed(function () {
      return ['flatRateBenefits', 'percentageBenefits'];
    }),
    jobTypes: (0, _model.hasMany)('job-type', {
      async: false
    }),
    name: (0, _model.attr)('string'),
    deletable: (0, _model.attr)('boolean', {
      readOnly: true
    }),
    flatRateBenefits: (0, _model.hasMany)('flat-rate-benefits', {
      async: false
    }),
    percentageBenefits: (0, _model.hasMany)('percentage-benefits', {
      async: false
    })
  }, benefitsPackageComputedProperties);

  _exports.default = _default;
});