define("balance-ember/components/manage/account-details/component", ["exports", "ember-concurrency", "@microstates/ember", "balance-ember/types/table", "balance-ember/classes/table-serializer", "balance-ember/utils/query-params"], function (_exports, _emberConcurrency, _ember, _table, _tableSerializer, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let colors = {
    'material-purple300': '#BA68C8'
  };
  const BUDGETED_COLOR = '#C2C2C2';
  const SPENT_COLOR = colors['material-purple300'];
  const TRANSACTIONS_PAGE_SIZE = 10;

  var _default = Ember.Component.extend({
    availableBudgetLeas: Ember.inject.service(),
    features: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    accountBalance: null,
    budgetLeaId: null,
    historyChartType: 'column',
    tableState: (0, _ember.state)((0, _ember.create)(_table.default, {
      pagination: {
        itemsPerPage: TRANSACTIONS_PAGE_SIZE
      }
    })),
    account: Ember.computed.readOnly('accountBalance.account'),
    available: Ember.computed.readOnly('accountBalance.available'),
    baseLeaFilter: Object.freeze({}),
    originalBudget: Ember.computed.readOnly('accountBalance.originalBudget'),
    budgeted: Ember.computed.readOnly('accountBalance.budgeted'),
    encumbered: Ember.computed.readOnly('accountBalance.encumbered'),
    isDeficitTotals: Ember.computed.lte('available', 0),
    showCharts: Ember.computed.and('loadAccountBalanceTask.lastSuccessful.isFinished', 'loadAccountBalanceTask.isIdle'),
    spent: Ember.computed.readOnly('accountBalance.spent'),
    supplementTypes: Ember.computed.readOnly('availableBudgetLeas.current.supplementTypes'),
    budgetedAmountLabel: 'Current Budget',
    amendments: Ember.computed('originalBudget', 'budgeted', function () {
      return this.budgeted - this.originalBudget;
    }),
    amountColumns: Ember.computed(function () {
      return [{
        align: 'right',
        cellComponent: 'manage/account-details/transaction-amount-cell',
        label: 'AMOUNT',
        sortable: true,
        sortName: 'amount',
        testSelector: 'amount-column',
        valuePath: 'amount'
      }];
    }),
    breadcrumbs: Ember.computed('account.code', function () {
      let breadcrumbs = [];
      breadcrumbs = breadcrumbs.concat([{
        displayName: 'Back to Accounts',
        routeName: 'manage.accounts.index'
      }]);

      if (this.account && this.account.code) {
        breadcrumbs = breadcrumbs.concat([{
          displayName: `Account ${this.account.code}`
        }]);
      }

      return breadcrumbs;
    }),
    dateColumns: Ember.computed(function () {
      return [{
        ascending: false,
        cellComponent: 'date-cell',
        timeZoneMode: 'exact',
        format: 'M/D/YYYY',
        label: 'DATE',
        sortable: true,
        sortName: 'transaction_date',
        testSelector: 'transaction-date-column',
        valuePath: 'transactionDate'
      }];
    }),
    historyChartData: Ember.computed('accountCode', 'historyChartType', function () {
      let accounts = this.store.peekAll('account').filterBy('code', this.accountCode).sortBy('budgetLea.fiscalYear');
      let budgetedData = [];
      let spentData = [];
      accounts.forEach(({
        accountBalance,
        budgetLea
      }) => {
        let fiscalYear = parseInt(budgetLea.fiscalYear);
        let {
          budgeted,
          spent
        } = accountBalance;
        budgetedData.push({
          amountType: 'Budgeted',
          dollars: budgeted,
          fiscalYear,
          x: fiscalYear,
          y: budgeted
        });
        spentData.push({
          amountType: 'Spent',
          dollars: spent,
          fiscalYear,
          x: fiscalYear,
          y: spent
        });
      });
      return [{
        color: BUDGETED_COLOR,
        name: 'Budgeted',
        type: this.historyChartType,
        data: budgetedData
      }, {
        color: SPENT_COLOR,
        name: 'Spent',
        type: this.historyChartType,
        data: spentData
      }];
    }),
    segments: Ember.computed('account.segments', 'loadAccountBalanceTask.isRunning', function () {
      if (!this.loadAccountBalanceTask.isRunning) {
        return this.account.segments.sortBy('segmentTypeDisplayOrder');
      }

      return [];
    }),
    supplementColumns: Ember.computed('supplementTypes.[]', function () {
      return this.supplementTypes.map(supplementType => {
        let {
          name
        } = supplementType;
        return {
          cellComponent: 'manage/supplement-cell',
          hideable: false,
          hidden: false,
          label: name.humanize(),
          name,
          sortable: true,
          sortName: name,
          supplementType: name,
          testSelector: name
        };
      });
    }),
    tableColumns: Ember.computed('amountColumns', 'dateColumns', 'supplementColumns', function () {
      return this.dateColumns.concat(this.supplementColumns).concat(this.amountColumns);
    }),
    transactionsPageFilter: Ember.computed('loadAccountBalanceTask.isRunning', 'segments', function () {
      if (!this.loadAccountBalanceTask.isRunning) {
        return this.segments.map(segment => {
          return {
            category: `segment_${segment.segmentType.name}`,
            type: 'segment',
            operators: [{
              value: 'eq_group',
              filterValues: [{
                eq: [segment.id]
              }]
            }]
          };
        });
      }

      return [];
    }),

    init() {
      this._super(...arguments);

      this.tableState.defaultSort.set({
        column: 'transaction_date',
        dir: 'desc'
      });
      this.tableState.updateFilters([{
        category: 'account_code',
        removable: false,
        operators: [{
          value: 'eq',
          filterValues: [this.accountCode]
        }]
      }, {
        category: 'budget_lea_id',
        removable: false,
        operators: [{
          value: 'eq',
          filterValues: [this.budgetLeaId]
        }]
      }]);
    },

    didReceiveAttrs() {
      this._super();

      this.loadAccountBalanceTask.perform();
    },

    loadAccountBalanceTask: (0, _emberConcurrency.task)(function* () {
      let budgetLeaIds = this.availableBudgetLeas.all.mapBy('id').join(',');
      let accountBalanceQuery = {
        filter: {
          budget_lea_id: {
            eq_group: budgetLeaIds
          },
          account_code: {
            eq: this.accountCode
          }
        },
        include: 'account.segments.segment_type,account.budget_lea'
      };
      let accountBalances = yield this.store.loadRecords('account-balance', accountBalanceQuery);
      let accountBalance = accountBalances.find(({
        account
      }) => account.budgetLea.id === this.budgetLeaId && account.code === this.accountCode);
      this.set('accountBalance', accountBalance);
    }),
    loadTransactionsTask: (0, _emberConcurrency.task)(function* () {
      let includesAndStats = {
        include: 'account.segments.segment_type',
        stats: {
          total: 'count',
          amount: 'sum'
        }
      };
      let apiQuery = new _tableSerializer.default(this.tableState).apiQuery;
      let transactionsQuery = Object.assign({}, apiQuery, includesAndStats);
      return yield this.store.loadRecords('transaction', transactionsQuery);
    }),
    actions: {
      exploreTransactions() {
        let tableState = (0, _ember.create)(_table.default, {});
        let filter = [{
          category: 'budget_lea_id',
          operators: [{
            value: 'eq',
            filterValues: [this.budgetLeaId]
          }]
        }, ...this.transactionsPageFilter];
        tableState = tableState.updateFilters(filter);
        let apiQuery = new _tableSerializer.default(tableState).apiQuery;

        let filterQueryParams = _queryParams.default.stringify(apiQuery);

        this.router.transitionTo('manage.transactions', {
          queryParams: {
            query: filterQueryParams
          }
        });
      }

    }
  });

  _exports.default = _default;
});