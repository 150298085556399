define("balance-ember/utils/budget/chosen-allocation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ChosenAllocation {
    constructor({
      tableState,
      allocations
    }) {
      this.tableState = tableState;
      this.allocations = allocations;
    }

    get allocation() {
      var _this$allocations;

      return (_this$allocations = this.allocations) === null || _this$allocations === void 0 ? void 0 : _this$allocations.findBy('id', this.allocationId);
    }

    get allocationId() {
      if (!this.allocationFilter) {
        return undefined;
      }

      let operator = [...this.allocationFilter.operators][0];
      return [...operator.filterValues][0].state;
    }

    get allocationFilter() {
      return [...this.tableState.filters].findBy('category.state', 'allocation_id');
    }

  }

  _exports.default = ChosenAllocation;
});