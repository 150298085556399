define("balance-ember/models/expense", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.expenseComputedProperties = _exports.schema = void 0;
  const schema = {
    allocation: (0, _model.belongsTo)('allocation', {
      async: false
    }),
    segments: (0, _model.hasMany)('segment', {
      async: false
    }),
    expenseStrategies: (0, _model.hasMany)('expenseStrategy', {
      async: false,
      inverse: 'expense'
    })
  };
  _exports.schema = schema;
  const expenseComputedProperties = {
    expenseType: Ember.computed.readOnly('constructor.modelName')
  };
  _exports.expenseComputedProperties = expenseComputedProperties;

  var _default = _model.default.extend(schema, expenseComputedProperties);

  _exports.default = _default;
});