define("balance-ember/templates/district-admin/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Q/oS2h2",
    "block": "{\"symbols\":[],\"statements\":[[8,\"district-admin/tag-form/create\",[],[[],[]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mt-16\"],[12],[2,\"\\n  \"],[8,\"district-admin/tag-table\",[],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/district-admin/tags.hbs"
  });

  _exports.default = _default;
});