define("balance-ember/components/allocation-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z4vs5gec",
    "block": "{\"symbols\":[\"modal\",\"form\",\"@formTitle\",\"@disabledButtonText\",\"@actionButtonText\",\"@allocation\",\"@segmentTypes\",\"@budget\"],\"statements\":[[8,\"ui-modal\",[],[[\"@closeModal\",\"@errors\"],[[32,0,[\"cancel\"]],[32,0,[\"errors\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[10,\"span\"],[12],[1,[32,3]],[13]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"ui-form\",[],[[\"@submitTask\"],[[32,0,[\"handleSave\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"renderComponent\"]]],[[\"form\",\"modal\",\"budget\",\"segmentTypes\",\"allocation\",\"resetAllocation\",\"expenseCount\",\"isRunning\",\"actionButtonText\",\"disabledButtonText\",\"saveAllocation\",\"cancelConfirmation\",\"validate\"],[[32,2],[32,1],[32,8],[32,7],[32,0,[\"allocation\"]],[32,0,[\"resetAllocation\"]],[32,6,[\"expenseCount\"]],[32,0,[\"handleSave\",\"isRunning\"]],[32,5],[32,4],[32,0,[\"handleSave\"]],[32,0,[\"cancelConfirmation\"]],[32,0,[\"validate\"]]]]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "moduleName": "balance-ember/components/allocation-form/template.hbs"
  });

  _exports.default = _default;
});