define("balance-ember/routes/budgeting/fiscal-year/purchase-orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    isPurchasingApprover: Ember.computed.or('session.currentUser.isPurchasingApprover', 'session.currentUser.isBudgetArchitect'),

    beforeModel() {
      if (!this.isPurchasingApprover) {
        this.replaceWith('/not-found');
      }
    }

  });

  _exports.default = _default;
});