define("balance-ember/components/filter-list/completed-filter/pill/deleting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MmK9bfRJ",
    "block": "{\"symbols\":[\"@filter\",\"@categoryConfig\",\"@removeFilter\",\"@cancelDeleting\"],\"statements\":[[10,\"div\"],[14,0,\"flex items-center justify-between py-8\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n    Delete \"],[1,[32,1,[\"totalFilterValues\"]]],[2,\" \"],[1,[32,2,[\"title\"]]],[2,\" Filters?\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ml-24\"],[12],[2,\"\\n    \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-danger-link\",\"sm\",\"confirm-delete-filters\",[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Delete\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"ml-8 inline-block\"],[12],[2,\"\\n      \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-secondary-link\",\"sm\",\"cancel-delete-filters\",[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Cancel\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/filter-list/completed-filter/pill/deleting/template.hbs"
  });

  _exports.default = _default;
});