define("balance-ember/components/budget/non-personnel-expenses-table/expanded-row/tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PMXMfmGh",
    "block": "{\"symbols\":[\"InlineItem\",\"InlineItem\",\"tab\",\"@changeTab\",\"@activeTab\",\"@tabs\",\"@close\"],\"statements\":[[10,\"div\"],[14,0,\"bg-material-light-blue100 w-full px-12 pt-4\"],[12],[2,\"\\n  \"],[8,\"layout/inline\",[],[[\"@align\",\"@alignX\"],[\"center\",\"between\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"layout/inline\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"budget/non-personnel-expenses-table/expanded-row/tabs/tab\",[],[[\"@tab\",\"@changeTab\",\"@activeTab\"],[[32,3],[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[1,[30,[36,0],[[32,3,[\"title\"]]],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"      \"]],\"parameters\":[2]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-subtle-link\",\"sm\",\"close-button\",[32,7]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"global.buttons.close\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/budget/non-personnel-expenses-table/expanded-row/tabs/template.hbs"
  });

  _exports.default = _default;
});