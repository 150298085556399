define("balance-ember/controllers/budgeting/supplemental-pay-types", ["exports", "balance-ember/mixins/table-controller"], function (_exports, _tableController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PAY_TYPE_PAGE_SIZE = void 0;
  const PAY_TYPE_PAGE_SIZE = 50;
  _exports.PAY_TYPE_PAGE_SIZE = PAY_TYPE_PAGE_SIZE;

  var _default = Ember.Controller.extend(_tableController.default, {
    router: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableState.pagination.itemsPerPage.set(PAY_TYPE_PAGE_SIZE);
      this.tableState.defaultSort.set({
        column: 'name',
        dir: 'asc'
      });
    },

    categories: Ember.computed(function () {
      return [{
        title: 'Pay Type',
        value: 'name',
        type: 'text'
      }, {
        title: 'Code',
        value: 'code',
        type: 'text'
      }, {
        title: 'Amount',
        value: 'amount',
        type: 'currency'
      }, // { title: 'Segment Code', value: 'segment_code', type: 'segment' }, // TODO Segment Code Filtering
      {
        title: 'Benefits Package',
        value: 'benefits_package_name',
        type: 'text'
      }];
    })
  });

  _exports.default = _default;
});