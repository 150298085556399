define("balance-ember/controllers/budgeting/benefits-packages/destroy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modelChanges: Ember.inject.service(),
    actions: {
      cancelBenefitsPackage() {
        this.transitionToRoute('budgeting.benefits-packages');
      },

      deleteBenefitsPackage() {
        let benefitsPackage = this.model;
        return benefitsPackage.destroyRecord().then(() => {
          this.modelChanges.addChange({
            id: benefitsPackage.id,
            modelType: 'benefitsPackage',
            changeType: 'delete'
          });
          this.transitionToRoute('budgeting.benefits-packages');
        });
      }

    }
  });

  _exports.default = _default;
});