define("balance-ember/components/budget/allocations/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sBed5rzB",
    "block": "{\"symbols\":[\"T\",\"Controls\",\"Body\",\"Controls\",\"@tableState\",\"@categories\"],\"statements\":[[8,\"table\",[],[[\"@tableState\",\"@columns\",\"@modelChanges\",\"@categories\",\"@trackingPrefix\",\"@loadTask\"],[[32,5],[32,0,[\"columns\"]],[32,0,[\"allocationChanges\"]],[32,6],\"Allocations\",[32,0,[\"loadTask\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,4,[\"filter-button\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,4,[\"pagination\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[4]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"filter-bar\"]],[],[[],[]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[\"@extra\",\"@id\",\"@rowTestSelector\",\"@headComponent\",\"@noDataIcon\"],[[30,[36,0],null,[[\"grandTotals\"],[[32,0,[\"loadGrandTotals\",\"lastSuccessful\",\"value\"]]]]],\"allocations-roster-table\",\"allocation-row\",\"budget/allocations/table/table-header\",\"na-positions\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"loader\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Loading Allocations...\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,3,[\"noData\"]],[],[[\"@noDataText\",\"@buttonText\"],[\"No allocations yet\",\"Add Allocation\"]],null],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"pagination\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "balance-ember/components/budget/allocations/table/template.hbs"
  });

  _exports.default = _default;
});