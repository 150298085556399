define("balance-ember/initializers/highcharts", ["exports", "highcharts", "balance-ember/config/environment"], function (_exports, _highcharts, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    let isAutomationTesting = _environment.default.environment === 'test';

    _highcharts.default.setOptions({
      lang: {
        thousandsSep: ','
      },
      credits: {
        enabled: false
      },
      chart: {
        animation: !isAutomationTesting
      },
      plotOptions: {
        series: {
          animation: !isAutomationTesting
        }
      }
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});