define("balance-ember/types/autocomplete-substring-filter-value", ["exports", "@microstates/ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AutocompleteSubstringFilterValue {
    constructor() {
      _defineProperty(this, "eq", [String]);

      _defineProperty(this, "match", [String]);
    }

    initialize(initialState) {
      if (!Ember.isNone(initialState) && typeof initialState === 'string') {
        return (0, _ember.create)(String, initialState);
      }

      return this;
    }

  }

  _exports.default = AutocompleteSubstringFilterValue;
});