define("balance-ember/components/ui-modal/component", ["exports", "ember-concurrency", "balance-ember/config/environment", "balance-ember/utils/key-codes"], function (_exports, _emberConcurrency, _environment, _keyCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modals: Ember.inject.service(),
    renderInPlace: false,
    style: 'slideout padded',
    // Have to default the target attachment same as the addon
    targetAttachment: 'middle center',
    stackable: true,

    closeModal() {},

    isSlideout: Ember.computed('style', function () {
      return this.style.includes('slideout');
    }),
    isCentered: Ember.computed('style', function () {
      return this.style.includes('centered');
    }),
    containerClassNames: Ember.computed('isCentered', 'isSlideout', 'style', 'tetherClasses', function () {
      let style = '';

      if (this.isCentered) {
        style = `${style} rounded`;
      }

      if (this.isSlideout) {
        style = `${style} slideout-modal-dialog bg-material-gray50`;
      }

      if (this.style.includes('no-overlay')) {
        style = `${style} shadow-no-overlay-modal`;
      }

      if (this.tetherClasses) {
        style = `${style} ${this.tetherClasses}`;
      }

      if (this.style.includes('rounded')) {
        style = `${style} rounded`;
      }

      return style;
    }),
    bodyStyles: Ember.computed('isCentered', 'style', function () {
      let style = this.style;

      if (this.isCentered) {
        style = 'centered';
      }

      return style;
    }),
    overlayClassNames: Ember.computed('isSlideout', 'style', function () {
      let classes = 'bg-outer-space';

      if (this.isSlideout) {
        classes = `${classes} slideout-overlay`;
      }

      return classes;
    }),
    shouldShowOverlay: Ember.computed('style', function () {
      return !this.style.includes('no-overlay');
    }),
    overlayPosition: Ember.computed('isSlideout', function () {
      return this.isSlideout ? 'sibling' : 'parent';
    }),
    wrapperClassNames: Ember.computed('isSlideout', 'style', function () {
      let classes = '';

      if (this.isSlideout) {
        classes = 'slideout-modal-wrapper';
      }

      if (this.style.includes('medium')) {
        classes = `${classes} medium-modal`;
      }

      if (this.style.includes('wide')) {
        classes = `${classes} wide-modal`;
      }

      if (this.style.includes('xw')) {
        classes = `${classes} extra-wide-modal`;
      }

      if (this.style.includes('full-width')) {
        classes = `${classes} full-width-modal`;
      }

      return classes;
    }),
    headerStyles: Ember.computed('isCentered', 'isSlideout', 'style', function () {
      if (this.isSlideout) {
        return 'blue sticky';
      }

      if (this.isCentered) {
        return 'blue rounded';
      }

      return '';
    }),
    footerStyles: Ember.computed('isSlideout', 'style', function () {
      if (this.isSlideout) {
        return 'slideout bordered';
      }

      return 'centered bordered';
    }),
    isTest: Ember.computed(function () {
      return _environment.default.environment === 'test';
    }),

    didInsertElement() {
      this._super(...arguments);

      this.modals.openModal(this.element.id);

      if (this.isSlideout && !this.isTest) {
        this.slideInModal.perform();
      } // We have to listen for when we click off of a modal that is stacked on top of another modal
      // to make sure it closes


      this.handleClick = ({
        target
      }) => {
        // if the click has already resulted in the target being removed or hidden, do nothing
        if (target.offsetWidth === 0 && target.offsetHeight === 0) {
          return;
        }

        if (this.isDestroying || this.isDestroyed) {
          return;
        } // if the click is within the dialog, do nothing


        let modalEl = document.querySelector(`[data-modal-id='${this.element.id}']`);

        if (modalEl && modalEl.contains(target)) {
          return;
        }

        if (this.stackable && !this.modals.isTopModal(this.element.id)) {
          return;
        }

        if (this.isSlideout && !this.isTest) {
          this.slideOutThenCloseModal.perform();
        } else {
          this.closeModal();
        }
      };

      this.handleKeyup = ({
        keyCode
      }) => {
        if (keyCode === _keyCodes.ESC) this.close();
      };

      const registerClick = () => document.addEventListener('click', this.handleClick);

      const registerKeyup = () => document.addEventListener('keyup', this.handleKeyup); // run.next needed or else the click handler will catch the click that spawned this modal dialog
      // Using `next` so tests are aware and wait for it properly


      Ember.run.next(registerClick);
      Ember.run.next(registerKeyup);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.modals.closeModal(this.element.id);
      document.removeEventListener('click', this.handleClick);
      document.removeEventListener('keyUp', this.handleKeyup);
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
    },

    slideInModal: (0, _emberConcurrency.task)(function* () {
      let modalContainer = document.getElementById('modal-overlays');
      yield (0, _emberConcurrency.waitForQueue)('afterRender'); // Ensure we don't slide in until everything has settled otherwise it will just appear
      // instead of sliding

      Ember.run.next(() => {
        document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
        modalContainer.classList.add('slide-in'); // Sometimes `transitionend` doesn't fire. Do I know why? No. However, it's annoying, only
        // happens in CI and makes the test suite fail. This failsafe will prevent this from happening
        // by cancelling the task if `transitionend` never fires

        setTimeout(() => {
          if (this.slideInModal.isRunning) {
            this.slideInModal.cancelAll();
          }
        }, 1000);
      }); // We don't want to attempt to close the modal until the original slide in transition has completed

      yield (0, _emberConcurrency.waitForEvent)(modalContainer, 'transitionend');
    }).drop(),
    slideOutThenCloseModal: (0, _emberConcurrency.task)(function* () {
      let modalContainer = document.getElementById('modal-overlays');

      if (modalContainer && modalContainer.classList && !this.slideInModal.isRunning) {
        Ember.run.next(() => {
          modalContainer.classList.remove('slide-in'); // Sometimes `transitionend` doesn't fire. Do I know why? No. However, it's annoying, only
          // happens in CI and makes the test suite fail. This failsafe will prevent this from happening
          // by cancelling the task if `transitionend` never fires

          setTimeout(() => {
            if (this.slideOutThenCloseModal.isRunning) {
              this.closeModal();
              this.slideOutThenCloseModal.cancelAll();
            }
          }, 1000);
        });
        yield (0, _emberConcurrency.waitForEvent)(modalContainer, 'transitionend');
      }

      this.closeModal();
    }).drop(),

    close() {
      if (!this.element || !this.modals.isTopModal(this.element.id)) {
        return;
      }

      if (this.isSlideout && !this.isTest) {
        this.slideOutThenCloseModal.perform();
      } else {
        this.closeModal();
      }
    },

    actions: {
      closeModal() {
        this.close();
      }

    }
  });

  _exports.default = _default;
});