define("balance-ember/components/budget/non-personnel-expenses-table/edit-details/name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zqi4sq1X",
    "block": "{\"symbols\":[\"group\",\"InlineItem\",\"StackItem\",\"@expense\"],\"statements\":[[8,\"form/label-group\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"layout/inline\",[],[[\"@space\",\"@align\"],[\"s\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"Label\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-sm\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"budget.non_personnel_expense_form.data.name\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2],[],[[\"@grow\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"layout/stack\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,4,[\"isEditable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[8,\"one-way-input\",[[24,0,\"css-input-field\"]],[[\"@value\",\"@update\"],[[32,4,[\"name\"]],[30,[36,0],[[32,4],\"name\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[32,4,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"error\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,0,\"text-material-red500 text-sm\"],[12],[2,\"\\n              \"],[1,[32,0,[\"error\"]]],[2,\"\\n            \"],[13],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[3]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"set\",\"t\",\"if\"]}",
    "moduleName": "balance-ember/components/budget/non-personnel-expenses-table/edit-details/name/template.hbs"
  });

  _exports.default = _default;
});