define("balance-ember/components/manage/omni-search-box/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/types/filter", "@microstates/ember", "lodash.clonedeep", "balance-ember/utils/filters/format-display-text"], function (_exports, _component, _emberConcurrency, _filter, _ember, _lodash, _formatDisplayText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ManageOmniSearchBoxComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.readOnly('args.trackingPrefix'), _dec4 = Ember.computed.readOnly('args.currentBudgetLea'), _dec5 = (0, _emberConcurrency.task)(function* (query = '') {
    yield (0, _emberConcurrency.timeout)(300);
    let promises = [this.fetchResources('manage/segment', query)];

    if (this.trackingPrefix === 'Transactions') {
      promises.push(this.fetchResources('tag', query), this.fetchResources('supplement', query));
    }

    let [segments, tags, supplements] = yield Ember.RSVP.all(promises);
    let groupedSegments = this.groupResources('Segments', segments);

    if (this.trackingPrefix === 'Accounts') {
      return [groupedSegments];
    }

    let groupedTags = this.groupResources('Tags', tags);
    let groupedSupplements = this.groupResources('Supplements', supplements);
    return [groupedSegments, groupedSupplements, groupedTags];
  }).restartable(), _dec6 = Ember._action, (_class = class ManageOmniSearchBoxComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "eventTracking", _descriptor2, this);

      _initializerDefineProperty(this, "trackingPrefix", _descriptor3, this);

      _initializerDefineProperty(this, "currentBudgetLea", _descriptor4, this);

      _initializerDefineProperty(this, "fetchResultsTask", _descriptor5, this);
    }

    get tableState() {
      return this.args.tableState;
    }

    get supplementTypes() {
      return this.currentBudgetLea.supplementTypes.map(type => type.name);
    }

    queryFilterBuilder(modelName, query) {
      let pageSize = this.trackingPrefix === 'Accounts' ? 6 : 3;
      let options = {
        filter: {
          query: {
            fuzzy: query
          }
        },
        page: {
          size: pageSize,
          number: 1
        }
      };

      if (modelName === 'tag') {
        return options;
      }

      options['filter']['budget_lea_id'] = this.currentBudgetLea.id;

      if (this.isManageSegment(modelName)) {
        options['extra_fields'] = {
          'manage/segments': 'parent_name_path'
        };
      } else {
        options['include'] = 'supplement_type';
      }

      return options;
    }

    fetchResources(modelName, query) {
      let options = this.queryFilterBuilder(modelName, query);
      return this.store.query(modelName, options);
    }

    groupResources(groupName, resources) {
      let disabled = resources.content.length === 0;
      let options = disabled ? [{
        value: 'No Results Found',
        disabled
      }] : resources.toArray();
      return {
        groupName,
        options,
        themeClass: this.args.themeClass,
        iconClass: this.args.iconClass
      };
    }

    trackAddFilter(finishedFilter) {
      if (this.trackingPrefix) {
        let filter = (0, _formatDisplayText.formatFilters)([finishedFilter], this.args.categories);
        this.eventTracking.trackEvent(`${this.trackingPrefix} Add Filter From OmniSearch`, {
          filter
        });
      }
    }

    isManageSegment(resource) {
      return resource === 'manage/segment';
    }

    isSupplement(resource) {
      return resource === 'supplement';
    }

    segmentCategoryBuilder(resource) {
      return `segment_${resource.parentNamePath[0]}`;
    }

    addResourceToFilter(filter, identifier) {
      filter.operators.find(operator => {
        if (operator.value === 'eq_group') {
          operator.filterValues.find(filterValue => {
            if ('eq' in filterValue) {
              filterValue.eq.push(identifier);
            }
          });
        } else {
          operator.filterValues.push(identifier);
        }
      });
      return filter;
    }

    findCurrentCategoryFilter(category, filters) {
      return filters.find(filter => {
        if (filter.category === category) return filter;
      });
    }

    isSupplementRelated(category) {
      return this.trackingPrefix === 'Transactions' && this.supplementTypes.includes(category);
    }

    handleFilterCategoryOperator(resource, category) {
      if (category === 'tag') {
        return [{
          value: 'eq',
          filterValues: [resource.id]
        }];
      }

      let identifier = this.isSupplementRelated(category) ? resource.value : resource.id;
      return [{
        value: 'eq_group',
        filterValues: [{
          'eq': [identifier]
        }]
      }];
    }

    updateFilter(resource, category, filters) {
      let filter = this.findCurrentCategoryFilter(category, filters);
      let identifier = this.isSupplementRelated(category) ? resource.value : resource.id;
      return this.addResourceToFilter(filter, identifier);
    }

    hasFilters(type) {
      return (0, _ember.valueOf)(this.tableState.filters).filter(filter => filter.category === type).length > 0;
    }

    getFilterType(resource) {
      let modelName = resource.constructor.modelName;
      return this.isManageSegment(modelName) ? 'segment' : this.isSupplement(modelName) ? 'supplement' : 'select';
    }

    generateFilter(resource) {
      let finishedFilter;
      let filters = (0, _lodash.default)((0, _ember.valueOf)(this.tableState.filters));
      let category = this.filterCategoryBuilder(resource);
      let type = this.getFilterType(resource);

      if (this.hasFilters(category)) {
        finishedFilter = (0, _ember.create)(_filter.default, this.updateFilter(resource, category, filters));
      } else {
        let operators = this.handleFilterCategoryOperator(resource, category);
        finishedFilter = (0, _ember.create)(_filter.default, {
          category,
          operators,
          type
        });
        filters.push((0, _ember.valueOf)(finishedFilter));
      }

      if (finishedFilter.isValid) {
        this.trackAddFilter(finishedFilter);
        this.tableState.filters.set(filters);
      }
    }

    filterCategoryBuilder(resource) {
      let modelName = resource.constructor.modelName;
      return this.isManageSegment(modelName) ? this.segmentCategoryBuilder(resource) : this.isSupplement(modelName) ? resource.supplementType.name : modelName;
    }

    selectResult(resource) {
      if (!resource) {
        return;
      }

      this.generateFilter(resource);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "eventTracking", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "trackingPrefix", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentBudgetLea", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fetchResultsTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectResult", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "selectResult"), _class.prototype)), _class));
  _exports.default = ManageOmniSearchBoxComponent;
});