define("balance-ember/components/light-table/head-components/select-all/component", ["exports", "@glimmer/component", "@microstates/ember"], function (_exports, _component, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let LightTableHeadComponentsSelectAllComponent = (_dec = Ember._action, (_class = class LightTableHeadComponentsSelectAllComponent extends _component.default {
    get areAllVisibleRowsSelected() {
      let selectedRowIds = this.selectedRowIds;
      let unselectedRow = this.visibleRowIds.find(visibleRowId => {
        let isSelected = selectedRowIds.includes(visibleRowId);
        return !isSelected;
      });
      return this.visibleRows.length && !unselectedRow;
    }

    get tableState() {
      return this.args.extra.tableState;
    }

    get selectedRows() {
      return this.args.extra.selectedRows;
    }

    get selectedRowIds() {
      return this.selectedRows.mapBy('id');
    }

    get visibleRows() {
      return this.tableState.rows;
    }
    /**
     * Have to rely on ids because each page change will pull new rows from
     * the server which will make it a different object reference
     */


    get visibleRowIds() {
      return (0, _ember.valueOf)(this.visibleRows).mapBy('id');
    }

    onUpdate(isChecked) {
      if (isChecked) {
        this.selectAllVisibleRows();
      } else {
        this.deselectVisibleRows();
      }
    }

    selectAllVisibleRows() {
      let visibleRows = (0, _ember.valueOf)(this.visibleRows) || [];
      this.selectedRows.addObjects(visibleRows);
    }

    deselectVisibleRows() {
      let visibleRowIds = this.visibleRowIds;
      let selectedVisibleRows = this.selectedRows.filter(row => {
        return visibleRowIds.includes(row.id);
      });
      this.args.extra.selectedRows.removeObjects(selectedVisibleRows);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onUpdate", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype)), _class));
  _exports.default = LightTableHeadComponentsSelectAllComponent;
});