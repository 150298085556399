define("balance-ember/components/allocation-expenses/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component displays the expenses for an allocation
   *
   * @param {object} allocation
   * @param {task} loadDataTask The task that loads the relationships for the allocation. We are
   * lazy loading the relationship data for each allocation so we want to know when we're done
   * loading the data so we can update the UI appropriately
   */
  var _default = Ember.Component.extend({
    budget: Ember.computed.readOnly('allocation.budget'),
    loadDataTask: null,
    allocation: null
  });

  _exports.default = _default;
});