define("balance-ember/components/planned-spending-by-segment/total-percentage-cell/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    segment: Ember.computed.readOnly('row.content'),
    budgetPlannedAmount: Ember.computed.readOnly('column.extra.budgetPlannedAmount'),
    totalAmount: Ember.computed.readOnly('segment.plannedAmount'),
    percentOfBudget: Ember.computed('budgetPlannedAmount', 'segment', 'totalAmount', function () {
      return Math.round(this.totalAmount / this.budgetPlannedAmount * 100);
    })
  });

  _exports.default = _default;
});