define("balance-ember/components/non-personnel-expenses/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dmz33f8F",
    "block": "{\"symbols\":[\"ab\",\"@allocation\",\"@deleteExpense\"],\"statements\":[[10,\"header\"],[14,0,\"expense-header\"],[12],[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"Non-Personnel Expenses\"],[13],[2,\"\\n\"],[6,[37,1],[[32,2,[\"isEditable\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"icon-button\",[],[[\"@route\",\"@style\",\"@size\",\"@icon\",\"@testId\",\"@showButtonText\",\"@buttonText\"],[[30,[36,0],[\"budgeting.budget.expenses.view.new-non-personnel-expense\",[32,0,[\"allocation\"]]],null],\"btn-primary-link\",\"sm\",\"plus\",\"add-non-personnel-expense\",true,\"Add New\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"p\"],[14,0,\"expense-type-total-remaining\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[32,0,[\"allocation\",\"nonPersonnelPlannedAmount\"]]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"planned\"],[12],[2,\"Planned\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[12],[2,\"\\n  \"],[8,\"budget/amazon-business\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"budget/non-personnel-expenses-table\",[],[[\"@allocation\",\"@showAvailablePurchaseOrders\",\"@afterSave\",\"@filter\",\"@tableActions\"],[[32,2],[32,1,[\"isConnected\"]],[30,[36,3],[[32,0],\"afterSave\"],null],[30,[36,4],null,[[\"allocation_id\"],[[32,2,[\"id\"]]]]],[30,[36,4],null,[[\"edit\",\"delete\"],[[30,[36,3],[[32,0],\"edit\"],null],[30,[36,3],[[32,0],[32,3]],null]]]]]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"array\",\"if\",\"numeral-formatter\",\"action\",\"hash\"]}",
    "moduleName": "balance-ember/components/non-personnel-expenses/template.hbs"
  });

  _exports.default = _default;
});