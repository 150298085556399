define("balance-ember/components/light-table/columns/tooltip-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H/kuwdkK",
    "block": "{\"symbols\":[\"@column\",\"@sortIcons\",\"@extra\",\"@tableActions\",\"@table\"],\"statements\":[[6,[37,1],[[32,1,[\"component\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,1,[\"component\"]]],[[\"column\",\"table\",\"tableActions\",\"extra\",\"sortIcons\"],[[32,1],[32,5],[32,4],[32,3],[32,2]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"light-table/default-column-label\",[],[[\"@column\",\"@sortIcons\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"tooltip\",[],[[\"@text\"],[[32,0,[\"tooltipText\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"if\"]}",
    "moduleName": "balance-ember/components/light-table/columns/tooltip-column/template.hbs"
  });

  _exports.default = _default;
});