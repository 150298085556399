define("balance-ember/components/report-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    headers: Ember.computed('model', function () {
      const example = this.model.get('firstObject');
      return example.get('fields');
    })
  });

  _exports.default = _default;
});