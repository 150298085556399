define("balance-ember/components/budget/export-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FiR8TWlo",
    "block": "{\"symbols\":[\"@resource\",\"@buttonText\"],\"statements\":[[8,\"export-button\",[],[[\"@reportType\",\"@exportAction\",\"@buttonText\"],[[32,1],[32,0,[\"export\"]],[30,[36,1],[[32,2],[30,[36,0],[\"global.buttons.export\"],null]],null]]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"or\"]}",
    "moduleName": "balance-ember/components/budget/export-button/template.hbs"
  });

  _exports.default = _default;
});