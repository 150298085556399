define("balance-ember/utils/manage/bookmark-query-builder", ["exports", "lodash.clonedeep"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BookmarkQueryBuilder {
    constructor(serializedTableState, serializedSummarizeState) {
      this.serializedTableState = serializedTableState;
      this.serializedSummarizeState = serializedSummarizeState;
    }

    build() {
      let query = (0, _lodash.default)(this.serializedTableState);
      query = this.removePagination(query);
      query = this.removeBudgetLea(query);
      query = this.maybeRemoveFilter(query);
      query = this.maybeAddSummarize(query);
      return query;
    }

    removePagination(query) {
      query = { ...query
      };
      delete query.page;
      return query;
    }

    removeBudgetLea(query) {
      query = { ...query
      };
      delete query.filter.budget_lea_id;
      return query;
    }

    maybeRemoveFilter(query) {
      query = { ...query
      };

      if (!Object.keys(query.filter).length) {
        delete query.filter;
      }

      return query;
    }

    maybeAddSummarize(query) {
      query = { ...query
      };

      if (this.serializedSummarizeState) {
        query.summarize = this.serializedSummarizeState;
      }

      return query;
    }

  }

  _exports.default = BookmarkQueryBuilder;
});