define("balance-ember/mixins/segment-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    segmentTypes: Ember.computed.readOnly('selectedBudgetLea.sortedSegmentTypes'),
    segmentTypeSubcategories: Ember.computed('segmentTypes.[]', 'store', function () {
      let getRecordFromId = id => this.store.peekRecord('manage/segment', id);

      return this.segmentTypes.map(segmentType => {
        return {
          value: `segment_${segmentType.name}`,
          title: segmentType.name.titleize(),
          summarizable: segmentType.summarizable,
          type: 'segment',
          searchType: segmentType.id,
          getRecordFromId
        };
      });
    })
  });

  _exports.default = _default;
});