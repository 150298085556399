define("balance-ember/routes/authenticated", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "balance-ember/types/filter", "@microstates/ember"], function (_exports, _authenticatedRouteMixin, _filter, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    availableBudgetLeas: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    session: Ember.inject.service(),
    url: Ember.inject.service(),

    identifyUser() {
      if (this.session.currentUser) {
        let userID = this.session.userUUID;
        let userData = this.session.data.authenticated;
        let options = {
          Intercom: {
            user_id: userID,
            user_hash: userData.intercom_hash
          }
        }; // Copied identifyUser from the segment.io JS for reference:
        // identifyUser: function(userId, traits, options, callback)

        return this.segment.identifyUser(userID, userData, options);
      }
    },

    identifyTrackingGroup() {
      // We'll use the subdomain as the name and id of the group (e.g. ips.allovue.com => 'ips')
      let subdomain = this.url.subdomain;
      this.segment.group(subdomain, {
        name: subdomain
      });
    },

    fetchBudgetLeas() {
      return this.availableBudgetLeas.fetch();
    },

    fetchFiscalYears() {
      return this.availableFiscalYears.fetch();
    },

    fetchUserPreferences() {
      let filter = (0, _ember.create)(_filter.default, {
        category: 'user_id',
        operators: [{
          value: 'eq',
          filterValues: [this.session.currentUser.id]
        }]
      }).serialized;
      return this.store.query('userPreference', {
        filter
      });
    },

    fetchUserSettings() {
      return this.store.query('user', {
        include: 'manage_permissions,budget_privileges,budget_leas',
        filter: {
          id: this.session.currentUser.id
        },
        extra_fields: {
          users: 'has_manage_access,has_budgeting_access'
        }
      });
    },

    fetchAccessTokens() {
      return this.store.findAll('accessToken');
    },

    model() {
      // We need the user to be tied to the group, so we need to identify the user first
      this.identifyUser();
      this.identifyTrackingGroup();
      return Ember.RSVP.all([this.fetchBudgetLeas(), this.fetchFiscalYears(), this.fetchUserPreferences(), this.fetchUserSettings(), this.fetchAccessTokens()]).then(() => {
        this.availableBudgetLeas.setDefaultBudgetLea();
      });
    }

  });

  _exports.default = _default;
});