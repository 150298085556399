define("balance-ember/components/district-admin/tag-table/name-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nm6xMqIy",
    "block": "{\"symbols\":[\"f\",\"@value\",\"@row\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n\"],[6,[37,5],[[32,3,[\"editing\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"ui-form\",[],[[\"@style\",\"@onSubmit\",\"@shouldAutofocus\"],[\"\",[30,[36,0],[[32,3,[\"saveTask\"]],[32,3]],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"label\"]],[],[[\"@for\",\"@style\"],[[31,[\"edit-name-input-\",[32,3,[\"id\"]]]],\"hidden\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[\"district_admin.tags.edit_name\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"ui-form/field-messages\",[],[[\"@errors\"],[[32,0,[\"errors\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[11,\"input\"],[16,2,[32,3,[\"writeableTag\",\"name\"]]],[16,1,[31,[\"edit-name-input-\",[32,3,[\"id\"]]]]],[24,0,\"css-input-field\"],[4,[38,4],[\"input\",[30,[36,3],[\"target.value\",[30,[36,2],[[32,3],\"writeableTag.name\"],null]],null]],null],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"perform\",\"t\",\"set\",\"pick\",\"on\",\"if\"]}",
    "moduleName": "balance-ember/components/district-admin/tag-table/name-cell/template.hbs"
  });

  _exports.default = _default;
});