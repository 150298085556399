define("balance-ember/components/manage/segment-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jZD+2wIU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"print:flex print:flex-row-reverse\"],[12],[2,\"\\n    \"],[8,\"overflowing-text\",[[24,0,\"print:h-auto print:w-320 print:pr-32\"]],[[\"@height\",\"@text\"],[48,[30,[36,0],[[32,0,[\"segmentName\"]],\"--\"],null]]],null],[2,\"\\n\"],[2,\"    \"],[10,\"div\"],[15,0,[31,[\"text-material-gray500 print:w-128 print:pr-8 print:text-right print:block \",[32,0,[\"segmentCodeClass\"]]]]],[12],[2,\"\\n      \"],[10,\"strong\"],[14,0,\"hidden uppercase print:block\"],[12],[1,[30,[36,2],[[30,[36,1],[[32,0,[\"segmentType\"]]],null]],null]],[13],[2,\"\\n      \"],[1,[32,0,[\"segmentCode\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\",\"humanize\",\"singularize\"]}",
    "moduleName": "balance-ember/components/manage/segment-cell/template.hbs"
  });

  _exports.default = _default;
});