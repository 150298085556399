define("balance-ember/utils/expense-totals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExpenseTotals = (_dec = Ember.computed.mapBy('allocationSortedSegments', 'segmentType'), _dec2 = Ember.computed.mapBy('allocationSegmentTypes', 'id'), (_class = class ExpenseTotals {
    constructor({
      expense,
      allSegmentTypes
    }) {
      _initializerDefineProperty(this, "allocationSegmentTypes", _descriptor, this);

      _initializerDefineProperty(this, "parentSegmentTypeIds", _descriptor2, this);

      this.expense = expense;
      this.allSegmentTypes = allSegmentTypes;
    }

    get allocationSortedSegments() {
      return this.allocationSegments.sortBy('segmentType.displayOrder');
    }

    get allocationSegments() {
      return this.allocation ? this.allocation.segments : [];
    }

    get allocation() {
      return this.expense.allocation;
    }

    get expenseSegmentTypes() {
      let parentSegmentTypeIds = this.parentSegmentTypeIds;
      let allSegmentTypes = this.allSegmentTypes || [];
      return allSegmentTypes.reject(segmentType => {
        if (!this.expense.canHaveSalarySegment && segmentType.salarySegment) {
          return true;
        }

        return parentSegmentTypeIds.includes(segmentType.id);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "allocationSegmentTypes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "parentSegmentTypeIds", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ExpenseTotals;
});