define("balance-ember/components/manage/summarize/data-loader/row-injector", ["exports", "@microstates/ember", "balance-ember/components/manage/summarize/summarize-path", "balance-ember/components/manage/summarize/data-loader/row-builder"], function (_exports, _ember, _summarizePath, _rowBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RowInjector = void 0;

  /**
   * @param {TableState} tableState
   * @param {SummarizeState} summarizeState
   * @param {Array<Category>} categories
   * @param {Task} loadChildrenTask
   */
  class RowInjector {
    constructor({
      tableState,
      summarizeState,
      categories,
      loadChildrenTask
    }) {
      this.tableState = tableState;
      this.summarizeState = summarizeState;
      this.categories = categories;
      this.loadChildrenTask = loadChildrenTask;
    }

    inject(summarizeState = this.summarizeState) {
      let decoratedRows = new _rowBuilder.default({
        rows: (0, _ember.valueOf)(summarizeState.rows),
        path: this.pathFor(summarizeState),
        loadChildrenTask: this.loadChildrenTask,
        categories: this.categories
      }).build();
      return decoratedRows.reduce((acc, row) => {
        acc = acc.concat(row);
        let summary = summarizeState.summaries.entries[row.summarizeValue];

        if (summary) {
          let childRows = this.inject(summary);
          acc = acc.concat(childRows);
        }

        return acc;
      }, []);
    }

    pathFor(summarizeState) {
      let path = this.paths.find(path => (0, _ember.valueOf)(path.lastObject.summary) === (0, _ember.valueOf)(summarizeState));
      return path || this.topLevelPath;
    }

    get paths() {
      return this._paths = this._paths || new _summarizePath.AllSummarizeNodePaths(this.summarizeState).find();
    }

    get topLevelPath() {
      return this._topLevelPath = this._topLevelPath || new _summarizePath.SummarizePathBuilder(this.summarizeState).build();
    }

  }

  _exports.RowInjector = RowInjector;
});