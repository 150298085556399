define("balance-ember/components/district-admin/integrations/amazon-business-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CIml7m57",
    "block": "{\"symbols\":[\"amazonBusiness\"],\"statements\":[[8,\"budget/amazon-business\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"isConnected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"district-admin/integrations/amazon-business-button/disconnect\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"district-admin/integrations/amazon-business-button/connect\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/district-admin/integrations/amazon-business-button/template.hbs"
  });

  _exports.default = _default;
});