define("balance-ember/components/recent-changes-button/component", ["exports", "ember-concurrency", "balance-ember/components/tagless-component/component", "balance-ember/utils/morph-keys", "balance-ember/utils/urls", "balance-ember/utils/budget/change-log/change"], function (_exports, _emberConcurrency, _component, _morphKeys, _urls, _change) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    ajax: Ember.inject.service(),
    modelName: Ember.computed.or('type', 'resourceName', 'record.constructor.modelName'),

    init() {
      this._super(...arguments);

      Ember.set(this, '_changes', []);
    },

    showSlideout: false,
    loadRecentChanges: (0, _emberConcurrency.task)(function* () {
      let authString = this.ajax.getAuthString();
      let query = {
        id: this.record.id,
        type: this.modelName,
        include: this.include,
        page: {
          number: 1,
          size: 3
        }
      };
      let url = `${(0, _urls.URLForResourceVersions)()}${authString}`;
      return yield this.ajax.request(url, {
        data: query
      }).then(response => {
        let changes = (0, _morphKeys.camelizeKeys)(response.data);
        this.formatChanges(changes);
        Ember.set(this, '_changes', changes);
        return changes;
      });
    }).drop(),

    formatChanges(changes) {
      changes.forEach(change => _change.default.formatChange(change));
    },

    changes: Ember.computed.reads('_changes'),
    actions: {
      openModal(evt) {
        evt.stopPropagation();
        this.loadRecentChanges.perform();
      },

      openSlideout(change, popover) {
        Ember.set(this, 'chosenChange', change);
        Ember.set(this, 'showSlideout', true);
        popover.hide();
      }

    }
  });

  _exports.default = _default;
});