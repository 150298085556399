define("balance-ember/components/user/profile-tabs/manage-permissions/table/actions-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vok4Gd5a",
    "block": "{\"symbols\":[\"StackItem\",\"@row\"],\"statements\":[[10,\"div\"],[14,0,\"p-8\"],[12],[2,\"\\n  \"],[8,\"layout/stack\",[],[[\"@space\"],[\"s\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"edit-button\",[],[[\"@buttonText\",\"@displayText\",\"@click\",\"@class\"],[[30,[36,0],[\"Permissions for budget \",[32,2,[\"content\",\"budgetLea\",\"name\"]]],null],\"Edit User Permissions\",[30,[36,1],[[32,0],\"edit\"],null],\"text-sm px-12 w-full text-left\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,2],[[32,2,[\"content\",\"hasAccess\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"delete-button\",[],[[\"@icon\",\"@testId\",\"@buttonText\",\"@displayText\",\"@click\",\"@class\"],[\"remove\",\"remove-permissions\",[30,[36,0],[\"Permissions for budget \",[32,2,[\"content\",\"budgetLea\",\"name\"]]],null],\"Remove These Permissions\",[30,[36,1],[[32,0],\"delete\",[32,2,[\"content\",\"budgetLea\"]]],null],\"text-sm px-12 w-full text-left\"]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"action\",\"if\"]}",
    "moduleName": "balance-ember/components/user/profile-tabs/manage-permissions/table/actions-cell/template.hbs"
  });

  _exports.default = _default;
});