define("balance-ember/components/icon-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "go91T4Wx",
    "block": "{\"symbols\":[\"@icon\",\"@buttonText\",\"@displayText\",\"&default\",\"@route\",\"@href\",\"@isLink\",\"@style\",\"@size\",\"@disabled\",\"@active\",\"@testId\",\"@onclick\",\"&attrs\"],\"statements\":[[8,\"button\",[[17,14]],[[\"@route\",\"@href\",\"@isLink\",\"@style\",\"@size\",\"@disabled\",\"@active\",\"@testId\",\"@onclick\"],[[32,5],[32,6],[32,7],[32,8],[32,9],[32,10],[32,11],[32,12],[32,13]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,2],[[27,[32,4]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,4,[[30,[36,1],null,[[\"icon\",\"iconClasses\",\"defaultLayout\"],[[30,[36,0],[\"icon-svg\"],[[\"icon\",\"class\"],[[32,1],[32,0,[\"iconClasses\"]]]]],[32,0,[\"iconClasses\"]],[30,[36,0],[\"icon-button/default-layout\"],[[\"showButtonText\",\"icon\",\"iconClasses\",\"svgSizeClasses\",\"buttonText\",\"displayText\"],[[32,0,[\"showButtonText\"]],[32,1],[32,0,[\"iconClasses\"]],[32,0,[\"svgSizeClasses\"]],[32,2],[32,3]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"icon-button/default-layout\",[],[[\"@showButtonText\",\"@icon\",\"@iconClasses\",\"@svgSizeClasses\",\"@buttonText\",\"@displayText\"],[[32,0,[\"showButtonText\"]],[32,1],[32,0,[\"iconClasses\"]],[32,0,[\"svgSizeClasses\"]],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,0,[\"shouldShowTooltip\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tooltip\",[],[[\"@text\"],[[32,0,[\"tooltipTitle\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\"]}",
    "moduleName": "balance-ember/components/icon-button/template.hbs"
  });

  _exports.default = _default;
});