define("balance-ember/components/one-way-percentage-mask/component", ["exports", "ember-inputmask/components/one-way-number-mask"], function (_exports, _oneWayNumberMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oneWayNumberMask.default.extend({
    init() {
      this._super(...arguments);

      Ember.set(this, '_options', Object.assign({}, this._options, {
        allowMinus: false,
        suffix: '%',
        digits: 3
      }));
      Ember.set(this, 'attributeBindings', this.attributeBindings.concat('placeholder'));
      Ember.set(this, 'classNames', this.classNames.concat('css-input-field'));
    },

    decimal: true,
    placeholder: '%'
  });

  _exports.default = _default;
});