define("balance-ember/components/budget/purchase-orders/table/action-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XAloTm/p",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"poExists\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"leading-tight text-blue500\"],[12],[2,\"\\n    \"],[8,\"button\",[],[[\"@style\",\"@textColor\",\"@route\"],[\"btn-default \",\"material-blue500\",[32,0,[\"viewPORoute\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,0,[\"buttonText\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"button\",[],[[\"@style\",\"@textColor\",\"@route\"],[\"btn-primary \",\"material-blue500\",[32,0,[\"newPORoute\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,0,[\"buttonText\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/budget/purchase-orders/table/action-cell/template.hbs"
  });

  _exports.default = _default;
});