define("balance-ember/utils/writeable-models/job-type", ["exports", "balance-ember/utils/writeable-models/basic", "balance-ember/utils/sum-benefits"], function (_exports, _basic, _sumBenefits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let JobTypeWriteableModel = (_dec = Ember.computed('averageBaseSalary', 'benefitsPackage', 'benefitsTotal', 'parsedAmount'), (_class = class JobTypeWriteableModel extends _basic.default {
    constructor(args) {
      if (!args.paths) {
        args.paths = {
          fiscalYear: true,
          salarySegment: true,
          staffType: true,
          benefitsPackage: {
            flatRateBenefits: true,
            percentageBenefits: true
          }
        };
      }

      super(args);
    } // totalAmount is the same as averageTotal except that averageTotal is calculated in the DB and totalAmount is computed client-side.
    // This duplicaion is needed because when editing we want to display a changing total as the user types numbers and selects benefits.
    // Paul Ostazeski on Fri 20 Jan 2017 04:31:46 PM EST


    get totalAmount() {
      return this.parsedAmount + this.benefitsTotal;
    }

    get parsedAmount() {
      return parseFloat(this.averageBaseSalary ?? 0);
    }

    get benefitsTotal() {
      return (0, _sumBenefits.default)(this.benefitsPackage, this.parsedAmount);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "totalAmount", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "totalAmount"), _class.prototype)), _class));
  _exports.default = JobTypeWriteableModel;
});