define("balance-ember/routes/signin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // rescue users who visit signin or sign-in instead of login
    redirect() {
      this.transitionTo('/login');
    }

  });

  _exports.default = _default;
});