define("balance-ember/components/manage/bookmark-dropdown/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency", "balance-ember/classes/table-serializer", "balance-ember/classes/summarize-query-params", "balance-ember/utils/manage/bookmark-query-builder", "balance-ember/utils/manage/current-bookmark-finder"], function (_exports, _component, _emberConcurrency, _tableSerializer, _summarizeQueryParams, _bookmarkQueryBuilder, _currentBookmarkFinder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    availableBudgetLeas: Ember.inject.service(),
    eventTracking: Ember.inject.service(),

    /**
     * @param
     */
    currentBudgetLea: null,
    oldBudgetLea: null,

    /**
     * @param
     */
    resource: null,

    /**
     * @param
     */
    tableState: null,

    /**
     * @param
     */
    summarizeState: null,

    /**
     * @param
     */
    updateCurrentBookmark() {},

    /**
     * @param
     */
    afterSave() {},

    /**
     * @param
     */
    afterDelete() {},

    currentBookmark: Ember.computed('bookmarks.[]', 'serializedSummarizeState', 'serializedTableState', 'summarizeState', 'tableState', function () {
      return new _currentBookmarkFinder.default(this.bookmarks, this.serializedTableState, this.serializedSummarizeState).find();
    }),

    didReceiveAttrs() {
      this._super();

      this.loadTask.perform();
    },

    bookmarksSorting: Ember.computed(function () {
      return ['name'];
    }),
    sortedBookmarks: Ember.computed.sort('bookmarks', 'bookmarksSorting'),
    districtBookmarks: Ember.computed.filterBy('sortedBookmarks', 'districtWide', true),
    userBookmarks: Ember.computed('session.currentUser.id', 'sortedBookmarks.@each.districtWide', function () {
      let currentUserId = this.session.currentUser.id; // We only want bookmarks the current user created to appear in this list

      return this.sortedBookmarks.filter(bookmark => {
        return !bookmark.districtWide && bookmark.belongsTo('user').id() === currentUserId;
      });
    }),
    shouldLoadBookmarks: Ember.computed('currentBudgetLea', 'oldBudgetLea', function () {
      return !this.oldBudgetLea || this.currentBudgetLea !== this.oldBudgetLea;
    }),
    serializedTableState: Ember.computed('tableState', function () {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }),
    serializedSummarizeState: Ember.computed('summarizeState', function () {
      if (this.summarizeState) {
        return new _summarizeQueryParams.SummarizeQueryParamSerializer(this.summarizeState).serialize();
      }

      return undefined;
    }),
    loadTask: (0, _emberConcurrency.task)(function* () {
      let bookmarks = this.bookmarks;

      if (this.shouldLoadBookmarks) {
        bookmarks = yield this.loadBookmarks();
        Ember.set(this, 'oldBudgetLea', this.currentBudgetLea);
        Ember.set(this, 'bookmarks', bookmarks.toArray());
      }

      this.updateCurrentBookmark(this.currentBookmark);
      return bookmarks;
    }).drop(),

    loadBookmarks() {
      return this.store.query('bookmark', {
        filter: {
          resource: {
            eq: this.resource
          },
          budget_lea_id: {
            eq: this.currentBudgetLea.id
          }
        },
        include: 'user,bookmark_reminder'
      });
    },

    createNewBookmark({
      title,
      budgetLea
    } = {}) {
      return this.store.createRecord('bookmark', {
        name: title,
        params: new _bookmarkQueryBuilder.default(this.serializedTableState, this.serializedSummarizeState).build(),
        resource: this.resource,
        budgetLeas: [budgetLea]
      });
    },

    actions: {
      deleteBookmark(bookmark) {
        return bookmark.destroyRecord().then(bookmark => {
          this.eventTracking.trackEvent('Delete Bookmark', {
            bookmarkName: bookmark.name
          });
          this.bookmarks.removeObject(bookmark);
          this.updateCurrentBookmark(this.currentBookmark);
          this.afterDelete();
        });
      },

      // action used to save a new boomark. This action lives here because when you save
      //  a new bookmark, you have to add to the list of existing bookmarks.
      saveBookmark(title) {
        let budgetLea = this.availableBudgetLeas.current;

        if (title) {
          return this.createNewBookmark({
            title,
            budgetLea
          }).save().then(bookmark => {
            this.eventTracking.trackEvent('Create Bookmark', {
              bookmarkName: title
            });
            this.bookmarks.pushObject(bookmark);
            this.updateCurrentBookmark(this.currentBookmark);
            this.afterSave();
            return bookmark;
          });
        }
      },

      trackClickBookmarkButton() {
        this.eventTracking.trackEvent('Clicks Bookmark Button');
      }

    }
  });

  _exports.default = _default;
});