define("balance-ember/components/budget/supplemental-pay-type-form-data/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    fiscalYear: Ember.computed.readOnly('availableFiscalYears.current'),
    segmentTypes: null,
    benefitsPackages: null,
    loadDataTask: (0, _emberConcurrency.task)(function* () {
      let store = this.store; // Make sure all our data is fetched

      yield Ember.RSVP.all([store.findAll('benefitsPackage'), store.peekAll('segmentType')]); // Set the FY scoped data

      Ember.set(this, 'benefitsPackages', this.fiscalYear.benefitsPackages);
      Ember.set(this, 'segmentTypes', this.fiscalYear.segmentTypes);
    }).drop(),

    didReceiveAttrs() {
      this._super();

      this.loadDataTask.perform();
    }

  });

  _exports.default = _default;
});