define("balance-ember/components/icon-svg/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6vz/itW8",
    "block": "{\"symbols\":[\"@class\",\"@icon\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[32,2]],[[\"class\"],[[30,[36,0],[[32,1],[32,1],\"\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"svg-jar\"]}",
    "moduleName": "balance-ember/components/icon-svg/template.hbs"
  });

  _exports.default = _default;
});