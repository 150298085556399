define("balance-ember/components/user/profile-tabs/account-info/user-provider/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/urls"], function (_exports, _component, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    actions: {
      sendResetPassword(userId) {
        let authString = this.ajax.getAuthString();
        this.ajax.patch(`${(0, _urls.URLForUser)(userId)}/send_reset_password${authString}`).then(() => {
          this.notifications.success('Your email was sent.');
        }).catch(() => {
          this.notifications.error('Your email failed to send. Please try again.');
        });
      },

      resendConfirmationEmail(userId) {
        let authString = this.ajax.getAuthString();
        this.ajax.patch(`${(0, _urls.URLForUser)(userId)}/resend_confirmation_email${authString}`).then(() => {
          this.notifications.success('Your email was sent.');
        }).catch(() => {
          this.notifications.error('Your email failed to send. Please try again.');
        });
      },

      unlockAccount(userId) {
        let authString = this.ajax.getAuthString();
        this.ajax.patch(`${(0, _urls.URLForUser)(userId)}/unlock${authString}`).then(() => {
          Ember.set(this, 'user.isLocked', false); // Reflect the backend state in the frontend

          this.notifications.success('Account Unlocked');
        }).catch(() => {
          this.notifications.error('Account failed to unlock. Please try again.');
        });
      }

    }
  });

  _exports.default = _default;
});