define("balance-ember/components/approval-state-manager/component", ["exports", "balance-ember/utils/includes-computed", "balance-ember/components/completion-status-icon/component"], function (_exports, _includesComputed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getApprovalText = getApprovalText;
  _exports.default = void 0;
  const IN_PROGRESS = 'in_progress';
  const SUBMITTED = 'submitted';
  const API_COMPLETED = 'completed';
  const SUBMIT = 'submit';
  const APPROVE = 'approve';
  const FINALIZE = 'finalize';
  const REJECT = 'reject';
  const REOPEN = 'reopen';
  const BUILDER = 'builder';
  const APPROVER = 'approver';
  const ARCHITECT = 'architect';
  /**
   * approvalText - Get the text for the approval status icon. We want this exposed here so we can
   * use it in tests as well
   *
   * @param  {number} numApprovals
   * @param  {number} numApprovers
   * @param  {boolean} isApproved
   * @param  {boolean} shouldShowDropdown
   * @return {string}
   */

  function getApprovalText(numApprovals, numApprovers, isApproved, shouldShowDropdown) {
    let approveText = isApproved ? 'Approved' : 'Approvals';
    let dropdownArrow = shouldShowDropdown ? ' ▾' : '';
    return `${numApprovals}/${numApprovers} ${approveText}${dropdownArrow}`;
  }
  /**
   * Use this component to show a progress bar and buttons to manage the approval state of a budget
   *
   * @param {object} budget
   */


  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['approval-state-manager'],

    init() {
      this._super(...arguments);

      Ember.set(this, 'approversSort', ['lastName']);
    },

    budget: null,
    approvalState: Ember.computed.readOnly('budget.approvalState'),
    approvers: Ember.computed.readOnly('budget.approvers'),
    approvals: Ember.computed.readOnly('budget.approvedBy'),
    authorizedApprovalEvents: Ember.computed.readOnly('budget.authorizedApprovalEvents'),
    userRole: Ember.computed.readOnly('budget.userRole'),

    /**
     * We want the approvers dropdown sorted by last name
     */
    approversSort: null,
    sortedApprovers: Ember.computed.sort('approvers', 'approversSort'),

    /**
     * We want to know if the current user has approved because we don't want to show the approval
     * button if the user is an approver and already approved
     */
    approvalIds: Ember.computed.mapBy('approvals', 'id'),
    currentUserId: Ember.computed.readOnly('session.userId'),
    hasCurrentUserApproved: (0, _includesComputed.default)('approvalIds', 'currentUserId', true),
    currentUserDidNotApprove: Ember.computed.not('hasCurrentUserApproved'),
    isBudgetApproved: Ember.computed.readOnly('budget.isApproved'),

    /**
     * What can the user do (will help determine which buttons to show)
     */
    isBudgetArchitect: Ember.computed.equal('userRole', ARCHITECT),
    isBuilder: Ember.computed.equal('userRole', BUILDER),
    isApprover: Ember.computed.equal('userRole', APPROVER),
    canUserApprove: (0, _includesComputed.default)('authorizedApprovalEvents', APPROVE),
    canUserSubmit: (0, _includesComputed.default)('authorizedApprovalEvents', SUBMIT),
    canUserReject: (0, _includesComputed.default)('authorizedApprovalEvents', REJECT),
    canUserFinalize: (0, _includesComputed.default)('authorizedApprovalEvents', FINALIZE),
    canUserReopen: (0, _includesComputed.default)('authorizedApprovalEvents', REOPEN),

    /**
     * We only want to show the approvers dropdown if the user is a budget architect or some approvers
     * have already been assigned
     */
    hasApprovers: Ember.computed.gt('approvers.length', 0),
    shouldShowApproversDropdown: Ember.computed.or('isBudgetArchitect', 'hasApprovers'),
    shouldNotShowApproversDropdown: Ember.computed.not('shouldShowApproversDropdown'),

    /**
     * Which buttons to show
     */
    shouldShowSkipToApproval: Ember.computed.and('isBudgetArchitect', 'budget.hasApprovers', 'canUserSubmit'),
    shouldShowSkipToFinalize: Ember.computed.and('isBudgetArchitect', 'budget.doesNotHaveApprovers', 'canUserSubmit'),
    shouldShowReturnToBuildingLink: Ember.computed.and('isBudgetArchitect', 'canUserReject'),
    shouldShowSubmitButton: Ember.computed.and('isBuilder', 'canUserSubmit'),
    shouldShowUnsubmitButton: Ember.computed.and('isBuilder', 'canUserReject'),
    shouldShowRejectButton: Ember.computed.and('isApprover', 'canUserReject'),
    shouldShowApproveButton: Ember.computed.and('isApprover', 'canUserApprove', 'currentUserDidNotApprove'),

    /**
     * Place to keep track of if each state is completed or not
     */
    isApproveCompleted: Ember.computed.equal('approveStatus', _component.COMPLETED),
    isBuildCompleted: Ember.computed.equal('buildStatus', _component.COMPLETED),
    isCompleteCompleted: Ember.computed.equal('completeStatus', _component.ACTIVE),
    isFinalizeCompleted: Ember.computed.equal('finalizeStatus', _component.COMPLETED),

    /**
     * The text for the approval status icon
     */
    approvalText: Ember.computed('approvers.length', 'approvals.length', 'isApproveCompleted', 'shouldShowApproversDropdown', function () {
      return getApprovalText(this.approvals.length, this.approvers.length, this.isApproveCompleted, this.shouldShowApproversDropdown);
    }),

    /**
     * buildStatus - Where the budget is in the building process
     *
     * If the budget is inProgress then state is active otherwise it must be completed
     */
    buildStatus: Ember.computed('approvalState', function () {
      let state = this.approvalState;
      return state === IN_PROGRESS ? _component.ACTIVE : _component.COMPLETED;
    }),

    /**
     * approveStatus - Where the budget is in the approval process
     *
     * If the budget is submitted and doesn't have all approvals then this state is active. If the
     * the budget is completed then this step must be too.
     */
    approveStatus: Ember.computed('approvalState', 'isBudgetApproved', function () {
      let state = this.approvalState;
      let isBudgetApproved = this.isBudgetApproved;

      if (state === API_COMPLETED || isBudgetApproved) {
        return _component.COMPLETED;
      }

      return state === SUBMITTED ? _component.ACTIVE : _component.INACTIVE;
    }),

    /**
     * finalizeStatus - Where the budget is in the finalize process
     *
     * If the budget is submitted and has all approvals than this state is active. If the
     * the budget is completed then this step must be too.
     */
    finalizeStatus: Ember.computed('approvalState', 'isBudgetApproved', function () {
      let state = this.approvalState;

      if (state === API_COMPLETED) {
        return _component.COMPLETED;
      }

      return this.isBudgetApproved ? _component.ACTIVE : _component.INACTIVE;
    }),

    /**
     * completeStatus - Where the budget is in the complete process
     *
     * If the budget is completed this state is active, otherwise it's inactive
     */
    completeStatus: Ember.computed('approvalState', function () {
      let state = this.approvalState;
      return state === API_COMPLETED ? _component.ACTIVE : _component.INACTIVE;
    }),
    actions: {
      /**
       * sendEvent - Direct the user to a confirmation modal which will fire off the API request to
       * change the budget approval status
       *
       * @param  {string} eventType
       */
      sendEvent(eventType) {
        this.router.transitionTo('budgeting.budget.approval', eventType);
      },

      /**
       * addApprover - We need to make sure the approval dropdown is closed when we go the
       * budget form
       *
       * @param  {type} closeDropdown Function to close the dropdown
       */
      addApprover(closeDropdown) {
        closeDropdown();
        this.router.transitionTo('budgeting.budget.details.edit', this.budget);
      }

    }
  });

  _exports.default = _default;
});