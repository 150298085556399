define("balance-ember/components/manage/bookmark-dropdown/row/promote/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GnjMtL3u",
    "block": "{\"symbols\":[\"@bookmark\",\"@cancel\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"rounded p-8 w-full border \",[32,0,[\"options\",\"boxBorderClass\"]],\" \",[32,0,[\"options\",\"boxColorClass\"]],\" \"]]],[12],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"districtWide\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      Change \"],[10,\"em\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\" from a district-wide bookmark into a private bookmark?\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      Make \"],[10,\"em\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\" a district-wide bookmark?\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex justify-end mt-12\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mr-8\"],[12],[2,\"\\n      \"],[8,\"button\",[],[[\"@onclick\",\"@style\",\"@size\",\"@testId\"],[[30,[36,2],[[30,[36,1],[[32,0],[32,2]],null]],null],\"btn-default\",\"sm\",\"cancel-button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Cancel\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[[32,0,[\"options\",\"buttonClass\"]],\"sm\",\"save-button\",[30,[36,2],[[30,[36,1],[[32,0],\"promoteBookmark\"],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,0,[\"options\",\"buttonText\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"stop-propagation\"]}",
    "moduleName": "balance-ember/components/manage/bookmark-dropdown/row/promote/template.hbs"
  });

  _exports.default = _default;
});