define("balance-ember/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    availableBudgetLeas: Ember.inject.service(),
    appBackgroundColorClass: Ember.computed('router.currentRouteName', function () {
      let route = this.router.currentRouteName;

      if (route.includes('budgeting.budget.') || route.includes('fiscal-year.budgets') || route.includes('fiscal-year.allocations') || route.includes('fiscal-year.positions') || route.includes('fiscal-year.reports') || route.includes('fiscal-year.purchase-orders') || route.includes('budgeting.overview')) {
        return 'bg-white';
      }

      if (route.includes('job-types') || route.includes('supplemental-pay-types') || route.includes('budget-priorities') || route.includes('employees') || route.includes('benefits-packages') || route.includes('vendors') || route.includes('segments')) {
        return 'bg-budgeting-bg-color';
      }

      return 'bg-gray50';
    }),

    bookmarkUrl() {
      return this.store.find('bookmark');
    },

    actions: {
      logout() {
        this.availableBudgetLeas.clear();
        return this.session.invalidate();
      }

    }
  });

  _exports.default = _default;
});