define("balance-ember/initializers/inspect-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.ActionHandler.reopen({
      segment: Ember.inject.service()
    }); // NOTE: Here we are inspecting all events that happen for {{link-to}} and
    // anything else inheriting from the LinkComponent class. - TO (2017-04-14)

    Ember.LinkComponent.reopen({
      segment: Ember.inject.service()
    });
  }

  var _default = {
    name: 'inspectAction',
    initialize
  };
  _exports.default = _default;
});