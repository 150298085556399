define("balance-ember/components/manage/bookmark-dropdown/row/view/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/classes/bookmark-query-param-builder"], function (_exports, _component, _bookmarkQueryParamBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: Ember.inject.service(),
    availableBudgetLeas: Ember.inject.service(),
    eventTracking: Ember.inject.service(),
    session: Ember.inject.service(),

    /**
     * @param
     */
    bookmark: null,
    promoteTooltipText: Ember.computed('bookmark.districtWide', function () {
      return this.bookmark.districtWide ? 'Hide from everyone except this bookmark’s creator' : 'Show to everyone in the district';
    }),
    canPromoteBookmark: Ember.computed.readOnly('session.canManageDistrictBookmarks'),
    districtEditable: Ember.computed.and('bookmark.districtWide', 'session.canManageDistrictBookmarks'),
    userEditable: Ember.computed.not('bookmark.districtWide'),
    canEditBookmark: Ember.computed.or('districtEditable', 'userEditable'),
    canDeleteBookmark: Ember.computed('bookmark', 'canEditBookmark', 'session.currentUser.id', function () {
      return this.canEditBookmark && this.session.currentUser.id === this.bookmark.belongsTo('user').id();
    }),
    isCurrentBookmark: Ember.computed('currentBookmark.id', 'bookmark.id', function () {
      return this.currentBookmark && this.currentBookmark.id === this.bookmark.id;
    }),
    bookmarkType: Ember.computed('bookmark.resource', function () {
      if (this.bookmark.resource === 'account_balance') {
        return 'Account';
      }

      return 'Transaction';
    }),
    actions: {
      bookmarkClick() {
        let currentRoute = this.router.currentRouteName;
        let currentBudgetLeaId = this.availableBudgetLeas.current.id;
        let queryParamBuilder = new _bookmarkQueryParamBuilder.default(this.bookmark, currentBudgetLeaId);
        let query = queryParamBuilder.build();
        let queryParams = {
          query
        };
        queryParams.summarize = queryParamBuilder.summarizeQueryString;
        this.trackClick();

        if (this.close) {
          this.close();
        }

        this.router.transitionTo(currentRoute, {
          queryParams
        });
      }

    },

    trackClick() {
      let bookmarkName = this.bookmark.name;
      this.eventTracking.trackEvent(`Click ${this.bookmarkType} Bookmark`, {
        bookmarkName
      });
    }

  });

  _exports.default = _default;
});