define("balance-ember/components/resource-versions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    columns: Ember.computed(function () {
      let cols = [{
        label: 'timestamp',
        valuePath: 'timestamp',
        width: '250px',
        cellClassNames: 'text-xs font-mono',
        classNames: 'text-xs font-mono'
      }, {
        label: 'resource',
        valuePath: 'resource',
        width: '150px'
      }, {
        label: 'id',
        valuePath: 'id',
        width: '50px'
      }, {
        label: 'event',
        valuePath: 'event',
        width: '100px'
      }, {
        label: 'user',
        valuePath: 'user',
        width: '150px'
      }, {
        label: 'change',
        valuePath: 'change',
        cellComponent: 'resource-versions/change-cell'
      }];
      return cols;
    })
  });

  _exports.default = _default;
});