define("balance-ember/templates/district-admin/users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Txgee20j",
    "block": "{\"symbols\":[\"breadcrumbs\"],\"statements\":[[8,\"admin-breadcrumbs-provider\",[],[[\"@user\"],[[32,0,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"ui-breadcrumbs\",[],[[\"@breadcrumbs\",\"@fontWeight\",\"@fontFamily\",\"@lastBreadcrumbFontWeight\",\"@textSize\"],[[32,1],\"normal\",\"sans\",\"bold\",\"2xl\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mt-40\"],[12],[2,\"\\n  \"],[8,\"user/profile-tabs\",[],[[\"@user\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/district-admin/users/edit.hbs"
  });

  _exports.default = _default;
});