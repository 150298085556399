define("balance-ember/components/light-table/cell-components/chevron-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HRFt87Kz",
    "block": "{\"symbols\":[\"canExpand\",\"subValue\",\"@column\",\"@row\",\"@value\"],\"statements\":[[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,5],[[30,[36,4],[[32,4,[\"content\",\"canExpand\"]],false],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"w-16\"],[12],[2,\"\\n        \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"chevron-circle\",[31,[\"mt-8 fill-current\\n            \",[30,[36,3],[[32,4,[\"expanded\"]],\"text-material-light-blue500\",\"text-material-blue-gray200\"],null],\"\\n            \",[30,[36,2],[[32,4,[\"expanded\"]],\"transform rotate-270\"],null]]]]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"leading-loose truncate \",[30,[36,3],[[32,1],\"ml-16\"],null]]]],[12],[2,\"\\n      \"],[10,\"span\"],[15,\"title\",[32,5]],[12],[2,\"\\n        \"],[1,[32,5]],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,3],[[32,3,[\"subValue\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,4,[\"content\"]],[32,3,[\"subValue\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"font-normal truncate\"],[15,\"title\",[32,2]],[12],[2,\"\\n            \"],[1,[32,2]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"let\",\"unless\",\"if\",\"eq\",\"not\"]}",
    "moduleName": "balance-ember/components/light-table/cell-components/chevron-cell/template.hbs"
  });

  _exports.default = _default;
});