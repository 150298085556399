define("balance-ember/components/notification-container/component", ["exports", "ember-cli-notifications/components/notification-container"], function (_exports, _notificationContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _notificationContainer.default.extend({
    notifications: Ember.inject.service()
  });

  _exports.default = _default;
});