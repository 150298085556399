define("balance-ember/templates/budgeting/segments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6hDe66eq",
    "block": "{\"symbols\":[\"InlineItem\"],\"statements\":[[8,\"container\",[],[[\"@style\"],[\"horizontal centered page padding-x\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"budget/page-header\",[],[[\"@title\"],[[30,[36,0],[\"budget.segments.index.page_title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/inline\",[],[[\"@space\"],[\"xs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"icon-button\",[],[[\"@route\",\"@style\",\"@testId\",\"@icon\",\"@buttonText\",\"@showButtonText\"],[[30,[36,1],[\"budgeting.segments.new\"],null],\"btn-primary\",\"add-segment\",\"circle-plus\",\"Add Segment\",true]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"budget/csv-upload-dropdown\",[],[[\"@url\",\"@resourceName\",\"@afterUploadSuccess\"],[[32,0,[\"segmentsBulkUrl\"]],\"Segments\",[30,[36,2],[[32,0],\"afterUploadSuccess\"],null]]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mt-16\"],[12],[2,\"\\n    \"],[8,\"budget/segments\",[],[[],[]],null],[2,\"\\n    \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"array\",\"action\",\"-outlet\",\"component\"]}",
    "moduleName": "balance-ember/templates/budgeting/segments.hbs"
  });

  _exports.default = _default;
});