define("balance-ember/components/pill/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pEKKf7yB",
    "block": "{\"symbols\":[\"@color\",\"@pillWidth\",\"@name\",\"@valueClass\",\"@value\"],\"statements\":[[10,\"div\"],[14,0,\"flex mb-10\"],[12],[2,\"\\n  \"],[8,\"pill\",[],[[\"@color\",\"@width\"],[[32,1],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"span\"],[15,0,[30,[36,0],[\"w-256 pl-8 \",[32,4]],null]],[12],[2,\"\\n    \"],[1,[32,5]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "moduleName": "balance-ember/components/pill/label/template.hbs"
  });

  _exports.default = _default;
});