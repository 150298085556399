define("balance-ember/components/table-provider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bax2Ks6R",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],null,[[\"table\",\"state\",\"icons\"],[[32,0,[\"table\"]],[30,[36,0],null,[[\"isLoading\",\"shouldShowTable\",\"hasNoFilteredResults\"],[[32,0,[\"isLoading\"]],[32,0,[\"shouldShowTable\"]],[32,0,[\"hasNoFilteredResults\"]]]]],[30,[36,0],null,[[\"iconSortable\",\"iconAscending\",\"iconDescending\"],[\"sort-icon unsorted\",\"sort-icon asc-sort\",\"sort-icon desc-sort\"]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "balance-ember/components/table-provider/template.hbs"
  });

  _exports.default = _default;
});