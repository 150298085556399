define("balance-ember/components/balance-users-table/component", ["exports", "ember-concurrency", "balance-ember/classes/table-serializer"], function (_exports, _emberConcurrency, _tableSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    columns: Ember.computed('features.ldap', function () {
      // Reference for parameters you can pass in:
      // http://offirgolan.github.io/ember-light-table/docs/classes/Column.html
      let cols = [{
        label: 'NAME',
        valuePath: 'name',
        sortName: 'name',
        cellType: 'allovue-cell',
        cellComponent: 'overflowing-table-cell',
        testSelector: 'balance-user'
      }, {
        label: 'EMAIL',
        valuePath: 'email',
        sortName: 'email',
        cellType: 'allovue-cell',
        cellComponent: 'overflowing-table-cell',
        testSelector: 'balance-user-email'
      }, {
        label: 'MANAGE ACCESS',
        valuePath: 'hasManageAccess',
        sortable: false,
        hideable: false,
        cellType: 'allovue-cell',
        cellComponent: 'balance-user-access-cell',
        cellClassNames: 'w-80',
        classNames: 'w-80',
        testSelector: 'manage-access'
      }, {
        label: 'BUDGET ACCESS',
        valuePath: 'hasBudgetingAccess',
        sortable: false,
        hideable: false,
        cellType: 'allovue-cell',
        cellComponent: 'balance-user-access-cell',
        cellClassNames: 'w-80 pl-8',
        classNames: 'w-80 pl-8',
        testSelector: 'budget-access'
      }, {
        label: 'LAST LOGIN',
        valuePath: 'currentSignInAt',
        sortName: 'current_sign_in_at',
        cellType: 'allovue-cell',
        cellClassNames: 'w-128',
        cellComponent: 'date-cell',
        timeZoneMode: 'exact',
        classNames: 'w-128',
        align: 'right',
        format: 'MMM Do, YYYY',
        nullState: 'Never',
        testSelector: 'last-sign-in'
      }, {
        label: 'ACTIONS',
        valuePath: 'id',
        sortable: false,
        hideable: false,
        cellType: 'allovue-cell',
        cellComponent: 'light-table/cell-components/table-actions',
        tableActionComponents: ['edit', 'delete'],
        align: 'right',
        width: '80px',
        editIcon: 'profile',

        buttonText(user) {
          return user.name;
        },

        deleteTooltipText: user => {
          // features is a proxy, so have to use get
          // eslint-disable-next-line ember/no-get
          if (Ember.get(this, 'features.ldap')) {
            return 'The LDAP administrator at your district can help you remove users';
          }

          return `Delete ${user.name}`;
        },

        editTooltipText(user) {
          return `View ${user.name}'s Profile`;
        },

        // features is a proxy, so have to use get
        // eslint-disable-next-line ember/no-get
        isDeleteDisabled: () => Ember.get(this, 'features.ldap')
      }];
      return cols;
    }),
    fetchUsers: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('user', { ...this.serializedTableState,
        stats: {
          total: 'count'
        },
        fields: {
          users: 'name,email,current_sign_in_at'
        },
        extra_fields: {
          users: 'has_manage_access,has_budgeting_access'
        }
      });
    }).drop(),
    serializedTableState: Ember.computed('tableState', function () {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }),
    actions: {
      onRowClick(row) {
        this.router.transitionTo('district-admin.users.edit', row.content.id);
      },

      editUser(user) {
        this.router.transitionTo('district-admin.users.edit', user.id);
      },

      deleteUser(user) {
        Ember.set(this, 'showDeleteModal', true);
        Ember.set(this, 'userToDelete', user);
        return false; // Stop propogation so parent rowClick doesn't take over
      }

    }
  });

  _exports.default = _default;
});