define("balance-ember/components/manage/chart-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hy3Flimo",
    "block": "{\"symbols\":[\"@disabled\",\"@shouldShowChart\"],\"statements\":[[8,\"icon-button\",[[4,[38,3],[\"click\",[32,0,[\"toggle\"]]],null]],[[\"@style\",\"@size\",\"@testId\",\"@disabled\",\"@buttonText\",\"@showButtonText\",\"@icon\"],[\"btn-secondary-link\",\"sm\",\"chart-toggle\",[32,1],[30,[36,0],[[32,2,[\"state\"]],\"Hide Chart\",\"Show Chart\"],null],[30,[36,2],[[30,[36,1],[\"isXxl\"],null]],null],\"show-visualization\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"media\",\"or\",\"on\"]}",
    "moduleName": "balance-ember/components/manage/chart-toggle/template.hbs"
  });

  _exports.default = _default;
});