define("balance-ember/components/no-filtered-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "19+IsU7w",
    "block": "{\"symbols\":[\"@tableState\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"flex flex-col justify-center items-center mt-40 mb-128\"],[12],[2,\"\\n  \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[[32,0,[\"svgName\"]],\"h-96\"]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-material-gray500 mt-24 mb-20\"],[12],[2,\"\\n    No Results\\n  \"],[13],[2,\"\\n  \"],[8,\"icon-button\",[],[[\"@style\",\"@buttonText\",\"@displayText\",\"@icon\",\"@onclick\",\"@testId\"],[\"btn-secondary-link\",\"Clear Filters\",\"Clear Filters\",\"cancel-circle\",[30,[36,0],[[32,0],[32,1,[\"clearFilters\"]]],null],\"clear-table-filters-button\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/components/no-filtered-results/template.hbs"
  });

  _exports.default = _default;
});