define("balance-ember/components/table/header/dropdown/sort-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4/ujzb8D",
    "block": "{\"symbols\":[\"@testId\",\"@dir\",\"@extra\",\"@icon\",\"@buttonText\"],\"statements\":[[8,\"table/header/dropdown/item\",[],[[\"@testId\",\"@active\",\"@onclick\",\"@extra\",\"@icon\",\"@buttonText\"],[[32,1],[30,[36,1],[[32,0,[\"isColumnSorted\"]],[30,[36,0],[[32,0,[\"firstSort\",\"dir\",\"state\"]],[32,2]],null],[30,[36,0],[[32,0,[\"firstSort\",\"type\",\"state\"]],[32,0,[\"sortType\"]]],null]],null],[30,[36,2],[[32,0],\"updateSort\"],null],[32,3],[32,4],[32,5]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"and\",\"action\"]}",
    "moduleName": "balance-ember/components/table/header/dropdown/sort-item/template.hbs"
  });

  _exports.default = _default;
});