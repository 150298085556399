define("balance-ember/components/positions-table/form/funding/num-positions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Be8lfEGV",
    "block": "{\"symbols\":[\"group\",\"InlineItem\",\"@stateMachine\"],\"statements\":[[8,\"form/label-group\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"layout/inline\",[],[[\"@space\",\"@align\"],[\"xs\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"Label\"]],[[24,0,\"text-sm\"]],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"budget.position.how_many_positions\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"w-60\"],[12],[2,\"\\n        \"],[8,\"one-way-number-mask\",[[24,0,\"css-input-field w-60\"]],[[\"@id\",\"@value\",\"@update\"],[[32,1,[\"id\"]],[32,3,[\"numPositions\"]],[32,0,[\"update\"]]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"error\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"text-xs text-material-red500 font-bold\"],[12],[2,\"\\n      \"],[1,[32,0,[\"error\",\"message\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "balance-ember/components/positions-table/form/funding/num-positions/template.hbs"
  });

  _exports.default = _default;
});