define("balance-ember/components/budget-notes/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/writeable-model"], function (_exports, _component, _writeableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    network: Ember.inject.service(),
    isEditingNotes: false,
    isSavingNotes: false,

    init() {
      this._super(...arguments);

      this.createWriteableModel();
    },

    notesStateComponentName: Ember.computed('isBudgetArchitect', 'isSavingNotes', 'isEditingNotes', function () {
      let isBudgetArchitect = this.isBudgetArchitect;
      let isEditingNotes = this.isEditingNotes;
      let isSavingNotes = this.isSavingNotes;

      if (!isBudgetArchitect) {
        return 'budget-notes/view';
      }

      if (isBudgetArchitect && !isEditingNotes) {
        return 'budget-notes/edit-inactive';
      }

      if (isBudgetArchitect && isEditingNotes && isSavingNotes) {
        return 'budget-notes/saving';
      }

      if (isBudgetArchitect && isEditingNotes) {
        return 'budget-notes/edit-active';
      }

      return '';
    }),
    notesPlaceholderText: Ember.computed('isBudgetArchitect', function () {
      return this.isBudgetArchitect ? 'Enter notes' : 'No notes to display';
    }),

    createWriteableModel() {
      Ember.set(this, 'writeableBudget', new _writeableModel.default({
        model: this.budget,
        store: this.store,
        network: this.network
      }));
    },

    actions: {
      editNotes() {
        this.set('isEditingNotes', true);
      },

      cancelEditNotes() {
        this.createWriteableModel();
        this.set('isEditingNotes', false);
      },

      saveNotes() {
        this.writeableBudget.save().then(() => {
          this.set('isSavingNotes', true);
          Ember.run.later(() => {
            this.set('isEditingNotes', false);
            this.set('isSavingNotes', false);
          }, 1000);
        });
      }

    }
  });

  _exports.default = _default;
});