define("balance-ember/components/ui-form/ui-fieldset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GLOsVquN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"fieldset\"],[14,0,\"not-first:mt-24 not-last:mb-24 not-last:border-b border-material-gray200\\n  not-last:pb-16\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"legend\",\"name\",\"notes\"],[[30,[36,0],[\"ui-form/ui-fieldset/ui-legend\"],null],[30,[36,0],[\"ui-form/ui-fieldset/fieldset-name\"],null],[30,[36,0],[\"ui-form/ui-fieldset/fieldset-notes\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/ui-form/ui-fieldset/template.hbs"
  });

  _exports.default = _default;
});