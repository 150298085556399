define("balance-ember/models/manage/segment-type", ["exports", "balance-ember/models/segment-type", "@ember-data/model"], function (_exports, _segmentType, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _segmentType.default.extend({
    segments: (0, _model.hasMany)('manage/segment', {
      async: false
    }),
    summarizable: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});