define("balance-ember/routes/fiscal-years", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.store.query('fiscalYear', {
        filter: {
          state: {
            eq: ['current', 'visible', 'in-setup', 'hidden']
          }
        },
        sort: '-end_date'
      }).then(fiscalYears => {
        this.pollForFiscalYearsBeingCopied(fiscalYears);
        return fiscalYears;
      });
    },

    pollForFiscalYearsBeingCopied(fiscalYears) {
      this.refreshTask.perform(fiscalYears);
    },

    refreshTask: (0, _emberConcurrency.task)(function* (fiscalYears) {
      let fiscalYearsInSetup = fiscalYears.filterBy('inSetup');

      if (fiscalYearsInSetup.length) {
        yield (0, _emberConcurrency.timeout)(2500);
        let reloadPromises = fiscalYearsInSetup.map(fiscalYear => fiscalYear.reload());
        let reloadedFiscalYears = yield Ember.RSVP.all(reloadPromises);
        this.refreshTask.perform(reloadedFiscalYears);
      }
    }).restartable()
  });

  _exports.default = _default;
});