define("balance-ember/templates/bookmark-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yTCuKAgv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"model\",\"redirect\",\"isError\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"my-32 text-2xl\"],[12],[2,\"There was an error loading your bookmark\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"my-32 text-2xl\"],[12],[2,\"Redirecting...\"],[13],[2,\"\\n    \"],[8,\"loading-spinner\",[],[[\"@size\"],[\"xxxl\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/templates/bookmark-redirect.hbs"
  });

  _exports.default = _default;
});