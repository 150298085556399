define("balance-ember/models/doc", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    uploadedBy: (0, _model.belongsTo)('user', {
      async: false
    }),
    url: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    extension: (0, _model.attr)('string'),
    size: (0, _model.attr)('number'),
    uploadedAt: (0, _model.attr)('date')
  });

  _exports.default = _default;
});