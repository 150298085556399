define("balance-ember/components/loading-text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'p',
    classNames: ['text-material-light-blue700', 'text-xl', 'font-medium']
  });

  _exports.default = _default;
});