define("balance-ember/components/budget/page-header/budgeting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fGk73m6X",
    "block": "{\"symbols\":[\"@budget\"],\"statements\":[[8,\"container\",[],[[\"@style\"],[\"tabbed-budget\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"container\",[],[[\"@style\"],[\"horizontal centered page\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"budget/page-header\",[],[[\"@title\",\"@subtitle\",\"@breadcrumbs\"],[[32,1,[\"name\"]],[30,[36,1],[[32,1,[\"category\"]],[30,[36,0],[[32,1,[\"category\"]]],null]],null],[30,[36,4],[[30,[36,3],null,[[\"displayName\",\"routeName\"],[[30,[36,2],[\"budget.fiscal_year.tabs.budgets\"],null],\"budgeting.fiscal-year.budgets\"]]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"flex justify-between mb-16\"],[12],[2,\"\\n        \"],[8,\"budget-totals\",[],[[\"@model\",\"@shouldShowBorders\"],[[32,1],true]],null],[2,\"\\n        \"],[8,\"approval-state-manager\",[],[[\"@budget\"],[[32,1]]],null],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"budget/budget-tabs\",[],[[\"@model\"],[[32,1]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"titleize\",\"if\",\"t\",\"hash\",\"array\"]}",
    "moduleName": "balance-ember/components/budget/page-header/budgeting/template.hbs"
  });

  _exports.default = _default;
});