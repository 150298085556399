define("balance-ember/controllers/manage/transactions", ["exports", "balance-ember/mixins/segment-controller", "balance-ember/mixins/summarize-controller", "@microstates/ember"], function (_exports, _segmentController, _summarizeController, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let mixins = [_summarizeController.default, _segmentController.default];

  var _default = Ember.Controller.extend(...mixins, {
    availableBudgetLeas: Ember.inject.service(),
    features: Ember.inject.service(),
    supplementTypes: Ember.computed.readOnly('selectedBudgetLea.supplementTypes'),
    selectedBudgetLea: Ember.computed.readOnly('availableBudgetLeas.current'),
    shouldShowChart: (0, _ember.state)((0, _ember.create)(Boolean, true)),
    sortTypes: Ember.computed('segmentTypes', function () {
      let sortsSet = new Set(this.segmentTypes.map(segment => `segment_${segment.name}`));
      return {
        code: sortsSet,
        name: sortsSet
      };
    }),
    filterCategories: Ember.computed('segmentTypeSubcategories', 'supplementTypeSubcategories', 'tags', function () {
      let categories = [{
        value: 'budget_lea_id',
        title: 'Budget Lea',
        type: 'integer',
        removable: false
      }, {
        value: 'segments',
        title: 'Segments',
        icon: 'segment',
        subcategories: this.segmentTypeSubcategories
      }, {
        value: 'supplements',
        title: 'Supplements',
        icon: 'supplement',
        subcategories: this.supplementTypeSubcategories
      }, {
        value: 'amount',
        title: 'Amount',
        icon: 'dollar',
        type: 'currency'
      }, {
        value: 'transaction_date',
        title: 'Date',
        icon: 'calendar',
        type: 'date'
      }, {
        value: 'status',
        title: 'Status',
        type: 'select',
        icon: 'transaction-status',
        filterOptions: {
          options: [{
            name: 'Actual',
            value: 'actual'
          }, {
            name: 'Encumbrance',
            value: 'encumbrance'
          }, {
            name: 'Budget',
            value: 'budget'
          }],
          valuePath: 'value',
          labelPath: 'name'
        }
      }];
      categories.push({
        value: 'tag',
        title: 'Tag',
        type: 'select',
        icon: 'tag',
        filterOptions: {
          options: this.tags,
          valuePath: 'id',
          labelPath: 'name'
        }
      });
      return categories;
    }),
    categories: Ember.computed('filterCategories', function () {
      let dates = [{
        value: 'quarter',
        title: 'Quarter',
        type: 'quarter',
        summarizable: true
      }, {
        value: 'month',
        title: 'Month',
        type: 'month',
        summarizable: true
      }];
      return this.filterCategories.concat(dates);
    }),
    supplementTypeSubcategories: Ember.computed('supplementTypes.[]', function () {
      return this.supplementTypes.map(supplementType => {
        return {
          value: supplementType.name,
          title: supplementType.name.titleize(),
          summarizable: supplementType.summarizable,
          type: 'supplement',
          labelPath: 'value',
          suggestionLabelPath: 'valueMarked',
          suggestionValuePath: 'value',
          searchArguments: [supplementType.id]
        };
      });
    })
  });

  _exports.default = _default;
});