define("balance-ember/components/manage/share-link/component", ["exports", "balance-ember/config/environment", "@glimmer/component", "ember-window-mock", "ember-concurrency", "balance-ember/utils/autocomplete-announcement", "balance-ember/utils/urls", "balance-ember/utils/filters/format-display-text"], function (_exports, _environment, _component, _emberWindowMock, _emberConcurrency, _autocompleteAnnouncement, _urls, _formatDisplayText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShareLink = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed(function () {
    return [];
  }), _dec8 = Ember.computed.empty('recipientIds'), _dec9 = Ember.computed.mapBy('recipients', 'id'), _dec10 = Ember.computed.readOnly('args.bookmark.name'), _dec11 = Ember.computed('getShortUrlTask.isRunning', 'shareUrl'), _dec12 = (0, _emberConcurrency.task)(function* (query) {
    yield (0, _emberConcurrency.timeout)(300);
    return this.store.query('user', {
      filter: {
        name: {
          match: query
        },
        has_manage_access: {
          eq: true
        },
        id: {
          not_eq: this.recipientIds
        }
      },
      extra_fields: {
        users: 'has_manage_access,has_budgeting_access'
      },
      page: {
        size: 5,
        number: 1
      }
    }).then(results => (0, _autocompleteAnnouncement.default)(results.toArray(), this.a11yAnnouncer));
  }), _dec13 = (0, _emberConcurrency.task)(function* (url) {
    let shareLink = this.store.peekAll('share-link').findBy('url', url);

    if (!shareLink) {
      shareLink = this.store.createRecord('share-link', {
        url
      });
      yield shareLink.save();
    }

    let host = _environment.default.APIRoot ? _environment.default.APIRoot : _emberWindowMock.default.location.origin;
    Ember.set(this, 'shareUrl', `${host}${shareLink.shortUrl}`);
  }).drop(), _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class ShareLink extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "a11yAnnouncer", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "eventTracking", _descriptor6, this);

      _defineProperty(this, "comment", this.args.comment);

      _defineProperty(this, "copyMessage", '<div>URL successfully copied to clipboard</div>');

      _defineProperty(this, "shareMessage", '<div>We\'re sending recipients an email with a link to this</div>');

      _defineProperty(this, "shareUrl", null);

      _initializerDefineProperty(this, "recipients", _descriptor7, this);

      _initializerDefineProperty(this, "disableForm", _descriptor8, this);

      _initializerDefineProperty(this, "recipientIds", _descriptor9, this);

      _initializerDefineProperty(this, "bookmarkTitle", _descriptor10, this);

      _initializerDefineProperty(this, "fetchUsersTask", _descriptor11, this);

      _initializerDefineProperty(this, "getShortUrlTask", _descriptor12, this);
    }

    get isCreatingLink() {
      return this.shareUrl === null || this.getShortUrlTask.isRunning === true;
    }

    get filterSummary() {
      if (this.args.tableState.filters) {
        return (0, _formatDisplayText.formatFilters)(this.args.tableState.filters, this.args.categories);
      }

      return undefined;
    }

    copied() {
      this.notifications.info(this.copyMessage, {
        htmlContent: true
      });
      this.eventTracking.trackEvent('Clicks Copy Share Link', {
        page: this.args.trackingPrefix,
        filters: this.filterSummary
      });
    }

    getShortUrl() {
      this.getShortUrlTask.perform(_emberWindowMock.default.location.href);
    }

    share(closeAction) {
      let userIds = this.recipientIds;
      let extraComment = this.comment;
      let bookmarkUrl = this.shareUrl;
      let bookmarkTitle = this.bookmarkTitle;
      let data = {
        userIds,
        extraComment,
        bookmarkUrl,
        bookmarkTitle
      }; // Get the file id url to download

      this.ajax.post((0, _urls.URLForSharing)(), {
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify(data)
      }).then(() => {
        this.notifications.info(this.shareMessage, {
          htmlContent: true
        });
        this.eventTracking.trackEvent('Clicks Share Link', {
          recipient_count: userIds.length,
          page: this.args.trackingPrefix,
          filters: this.filterSummary
        });
        closeAction();
        Ember.set(this, 'recipients', []);
        Ember.set(this, 'comment', '');
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "a11yAnnouncer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "eventTracking", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "recipients", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "disableForm", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "recipientIds", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "bookmarkTitle", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isCreatingLink", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "isCreatingLink"), _class.prototype), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "fetchUsersTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "getShortUrlTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "copied", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "copied"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getShortUrl", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "getShortUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "share", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "share"), _class.prototype)), _class));
  _exports.default = ShareLink;
});