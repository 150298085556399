define("balance-ember/models/manage-permission", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  const schema = {
    budgetLea: (0, _model.belongsTo)('budgetLea', {
      async: false
    }),
    segments: (0, _model.hasMany)('manage/segment', {
      async: false
    }),
    segmentType: (0, _model.attr)(),
    codes: (0, _model.attr)(),
    segmentAccess: (0, _model.attr)()
  };
  _exports.schema = schema;

  var _default = _model.default.extend(schema);

  _exports.default = _default;
});