define("balance-ember/components/slideout-budgeting-segments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m+OQcHXT",
    "block": "{\"symbols\":[\"segment\"],\"statements\":[[10,\"div\"],[15,0,[32,0,[\"wrapperClasses\"]]],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"headerTitle\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h3\"],[14,0,\"text-material-gray700 mb-12 leading-tight\"],[12],[2,\"\\n      \"],[1,[32,0,[\"headerTitle\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"table\"],[14,0,\"border-spacing-none\"],[12],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"tableHeader\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[14,0,\"text-sm text-material-gray600 leading-tight\"],[12],[2,\"\\n          \"],[10,\"td\"],[14,0,\"css-form-segments-cell pr-16\"],[12],[1,[32,0,[\"tableHeader\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"segments\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[14,0,\"text-sm text-material-gray700 leading-tight\"],[12],[2,\"\\n          \"],[10,\"td\"],[14,0,\"css-form-segments-cell text-material-light-blue500 font-bold pr-16\"],[12],[2,\"\\n            \"],[1,[32,1,[\"segmentType\",\"name\"]]],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"td\"],[14,0,\"css-form-segments-cell code text-material-gray600 pr-16\"],[12],[1,[32,1,[\"code\"]]],[13],[2,\"\\n          \"],[10,\"td\"],[14,0,\"css-form-segments-cell font-bold\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/slideout-budgeting-segments/template.hbs"
  });

  _exports.default = _default;
});