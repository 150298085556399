define("balance-ember/components/position-fill-rates-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dd/VLnhU",
    "block": "{\"symbols\":[\"Widget\",\"T\"],\"statements\":[[8,\"dashboard-widget\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Funded Positions Fill Rate by FTE\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-material-gray600 text-sm h-32 flex items-center\"],[12],[2,\"\\n      (Excludes Unfunded Positions)\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"dashboard-table\",[],[[\"@tableState\",\"@columns\",\"@height\",\"@loadingText\",\"@loadTask\"],[[32,0,[\"tableState\"]],[32,0,[\"columns\"]],\"588px\",\"Loading Positions\",[32,0,[\"loadTask\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"flex justify-between\"],[12],[2,\"\\n        \"],[8,[32,2,[\"pagination-buttons\"]],[],[[\"@setPage\"],[[30,[36,0],[[32,0],\"setPage\"],null]]],null],[2,\"\\n\\n        \"],[8,\"button\",[],[[\"@route\",\"@style\",\"@size\",\"@testId\"],[[30,[36,1],[\"budgeting.fiscal-year.reports.view\",\"position-fill-rates-by-budgets\"],null],\"btn-default\",\"xsm\",\"explore-fill-rates-button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          Explore Fill Rates\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"array\"]}",
    "moduleName": "balance-ember/components/position-fill-rates-widget/template.hbs"
  });

  _exports.default = _default;
});