define("balance-ember/components/ui-tabs/link-tab/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend({
    classNames: ['py-10 px-16', '-mb-px', // Move the tabs down to obscure the top border of it's content
    'mr-10', 'rounded', 'rounded-b-none', 'hover:no-underline', 'focus:no-underline'],
    classNameBindings: ['borderClass', 'backgroundClass', 'textColorClass', 'positionClass'],
    variant: 'bordered',
    borderClass: Ember.computed('variant', 'active', function () {
      if (this.active) {
        switch (this.variant) {
          case 'bordered':
            return 'border border-budgeting-border border-b-0';

          case 'shadowed':
            return 'shadow-container';
        }
      }

      return 'border-transparent';
    }),
    backgroundClass: Ember.computed('active', function () {
      return this.active ? 'bg-white' : false;
    }),
    positionClass: Ember.computed('variant', 'active', function () {
      // We want the parent to be position relative if we are shadowed and active because we have to
      // hide the bottom of the box shadow with an absolutely positioned element
      return this.active && this.variant === 'shadowed' ? 'relative' : false;
    }),
    textColorClass: Ember.computed('active', function () {
      let classString = 'hover:text-material-gray700 focus:text-material-gray700';

      if (this.active) {
        return `text-material-gray700 ${classString}`;
      }

      return `text-material-gray650 ${classString}`;
    }),
    shouldUseShadowBlocker: Ember.computed('active', 'variant', function () {
      return this.active && this.variant === 'shadowed';
    })
  });

  _exports.default = _default;
});