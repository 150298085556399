define("balance-ember/routes/budgeting/budget/positions", ["exports", "balance-ember/mixins/route-history", "balance-ember/utils/urls", "balance-ember/utils/morph-keys"], function (_exports, _routeHistory, _urls, _morphKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.POSITIONS_PAGE_SIZE = void 0;
  const POSITIONS_PAGE_SIZE = 25;
  _exports.POSITIONS_PAGE_SIZE = POSITIONS_PAGE_SIZE;

  var _default = Ember.Route.extend(_routeHistory.default, {
    ajax: Ember.inject.service(),
    features: Ember.inject.service(),
    positionBudgeting: Ember.computed.readOnly('features.positionBudgeting'),
    queryParams: {
      // We want the model to reload the data for pagination
      page: {
        refreshModel: true
      }
    },

    beforeModel() {
      if (!this.positionBudgeting) this.transitionTo('/not-found');
    },

    model() {
      let budgetId = this.paramsFor('budgeting.budget').budget_id;
      let authString = this.ajax.getAuthString();
      let url = `${(0, _urls.URLForBudgetNamespace)()}/budgets/${budgetId}/positions_summary${authString}`;
      return Ember.RSVP.hash({
        summary: this.ajax.request(url).then(data => data ? (0, _morphKeys.camelizeKeys)(data) : {}),
        staffTypes: this.store.findAll('staffType'),
        allocations: this.store.query('allocation', {
          filter: {
            budget_id: budgetId
          },
          paginate: false,
          include: 'segments.segment_type'
        })
      });
    },

    setupController(controller, model) {
      // Add the budget to the model hash in case no allocations have been defined on the budget
      model.budget = this.modelFor('budgeting.budget');
      Ember.set(controller, 'staffTypes', model.staffTypes);
      Ember.set(controller, 'allocations', model.allocations.sortBy('name'));

      this._super(controller, model);
    }

  });

  _exports.default = _default;
});