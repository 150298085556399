define("balance-ember/utils/budget/budget-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BudgetUser {
    constructor({
      user,
      privilege
    }) {
      this.user = user;
      this.privilege = privilege;
    }

    get id() {
      return this.user ? this.user.id : null;
    }

    get name() {
      return this.user ? this.user.name : '';
    }

    get role() {
      return this.privilege ? this.privilege.role : null;
    }

  }

  _exports.default = BudgetUser;
});