define("balance-ember/components/destroy-allocation-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YQXZpUkC",
    "block": "{\"symbols\":[\"@allocationBudget\"],\"statements\":[[8,\"modal/destroy\",[],[[\"@closeModal\",\"@destroy\",\"@model\"],[[30,[36,0],[[32,0],\"cancelAllocations\"],null],[30,[36,0],[[32,0],\"deleteAllocations\"],null],[32,0,[\"allocationReferent\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"p\"],[14,0,\"text-15 text-material-gray700 leading-tight\"],[12],[1,[32,0,[\"warningMessage\"]]],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"budget\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"mt-8 text-material-gray700 text-15\"],[12],[2,\"\\n      Would you like to\\n      \"],[11,\"a\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"planExpenses\"],null],[12],[2,\"\\n        plan \"],[1,[32,1,[\"name\"]]],[2,\" expenses\\n      \"],[13],[2,\" instead?\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "moduleName": "balance-ember/components/destroy-allocation-modal/template.hbs"
  });

  _exports.default = _default;
});