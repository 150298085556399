define("balance-ember/components/tooltip-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jtD7w83C",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,0],[\"flex justify-start items-center \",[32,0,[\"column\",\"cellClassNames\"]]],null]],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"tooltipText\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tooltip\",[],[[\"@text\"],[[32,0,[\"tooltipText\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[32,0,[\"cellText\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\"]}",
    "moduleName": "balance-ember/components/tooltip-cell/template.hbs"
  });

  _exports.default = _default;
});