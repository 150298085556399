define("balance-ember/components/light-table/default-head-columns/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7oaCst8W",
    "block": "{\"symbols\":[\"column\",\"@sortIcons\",\"@extra\",\"@table\",\"@columns\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[30,[36,0],[\"light-table/columns/\",[32,1,[\"type\"]]],null],[32,1]],[[\"table\",\"extra\",\"rowspan\",\"sortIcons\"],[[32,4],[32,3],1,[32,2]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"component\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/light-table/default-head-columns/template.hbs"
  });

  _exports.default = _default;
});