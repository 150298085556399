define("balance-ember/types/date-filter", ["exports", "balance-ember/types/basic-filter", "balance-ember/types/number-filter", "moment", "validate.js"], function (_exports, _basicFilter, _numberFilter, _moment, _validate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class DateFilter extends _numberFilter.default {
    get startDate() {
      return this.firstOperator ? this.firstOperator.firstFilterValue : '';
    }

    get endDate() {
      return this.firstOperator ? this.firstOperator.secondFilterValue : '';
    }

    get isRelativeMatch() {
      return this.firstOperator && this.firstOperator.value.state === 'relative';
    }

    get dateDiff() {
      return (0, _moment.default)(this.endDate, 'MM/DD/YYYY').diff((0, _moment.default)(this.startDate, 'MM/DD/YYYY'));
    }

    get validations() {
      return new DateFilterValidator(this).validate();
    }

    get isValid() {
      return !this.validations;
    }

    get serializer() {
      return new DateFilterSerializer(this);
    }

  }

  _exports.default = DateFilter;

  class DateFilterValidator extends _basicFilter.FilterValidator {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "DATE_REGEX", /^\d{1,2}\/\d{1,2}\/\d{4}$/);

      _defineProperty(this, "operatorConstraints", {
        'firstOperator.value.state': {
          presence: true
        }
      });

      _defineProperty(this, "relativeDateConstraints", Object.assign({
        'startDate': {
          presence: true
        }
      }, this.operatorConstraints));

      _defineProperty(this, "singleDateContraints", Object.assign({
        'startDate': {
          presence: true,
          format: {
            pattern: this.DATE_REGEX
          }
        }
      }, this.operatorConstraints));

      _defineProperty(this, "rangeConstraints", Object.assign({
        'startDate': {
          presence: true,
          format: {
            pattern: this.DATE_REGEX
          }
        },
        'endDate': {
          presence: true,
          format: {
            pattern: this.DATE_REGEX
          }
        }
      }, this.operatorConstraints));

      _defineProperty(this, "dateDiffConstraints", Object.assign({
        'dateDiff': {
          numericality: {
            greaterThan: 0,
            message: 'The end date must come after the start date'
          }
        }
      }, this.rangeConstraints));
    }

    validate() {
      if (this.filter.isRelativeMatch) {
        return this.validateRelative();
      }

      if (this.filter.isRangeMatch) {
        return this.validateRange();
      }

      return this.validateSingle();
    }

    validateRelative() {
      return (0, _validate.default)(this.filter, this.relativeDateConstraints);
    }

    validateRange() {
      let rangeErrors = (0, _validate.default)(this.filter, this.rangeConstraints);
      return rangeErrors ? rangeErrors : (0, _validate.default)(this.filter, this.dateDiffConstraints, {
        fullMessages: false
      });
    }

    validateSingle() {
      return (0, _validate.default)(this.filter, this.singleDateContraints);
    }

  }

  class DateFilterSerializer extends _numberFilter.NumberFilterSerializer {
    serializeFilterValue(filterValue) {
      let serializedValue = super.serializeFilterValue(filterValue); // Format the non-relative dates as formal date strings

      if (!this.filter.isRelativeMatch) {
        return this.serializeAbsoluteValue(filterValue.trim());
      }

      return serializedValue;
    }

    serializeAbsoluteValue(filterValue) {
      return (0, _moment.default)(filterValue, 'MM/DD/YYYY').format('YYYY-MM-DD');
    }

  }
});