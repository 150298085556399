define("balance-ember/components/light-table/cell-components/checkbox-cell/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let LightTableCellComponentsCheckboxCellComponent = (_dec = Ember._action, (_class = class LightTableCellComponentsCheckboxCellComponent extends _component.default {
    get content() {
      return this.args.row.content;
    }

    get id() {
      return this.content.id;
    }

    get label() {
      return this.args.column.checkboxText(this.content);
    }

    get selectedRows() {
      return this.args.extra.selectedRows;
    }

    get isChecked() {
      return Boolean(this.selectedRowRecord);
    }
    /**
     * We can't rely on the object references being the same because
     * the user may change pages of data which would cause the server to
     * pull new data creating a different object.
     */


    get selectedRowRecord() {
      return this.selectedRows.findBy('id', this.id);
    }

    onUpdate(isChecked) {
      if (isChecked) {
        this.selectedRows.addObject(this.content);
      } else {
        this.removeFromSelectedRows();
      }
    }

    removeFromSelectedRows() {
      this.selectedRows.removeObject(this.selectedRowRecord);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onUpdate", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype)), _class));
  _exports.default = LightTableCellComponentsCheckboxCellComponent;
});