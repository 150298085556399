define("balance-ember/components/filter-form/segment/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/filters/segment-formatter", "ember-concurrency", "balance-ember/utils/autocomplete-announcement"], function (_exports, _component, _segmentFormatter, _emberConcurrency, _autocompleteAnnouncement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    a11yAnnouncer: Ember.inject.service(),
    formatter: _segmentFormatter.default,
    searchType: Ember.computed.readOnly('categoryConfig.searchType'),
    searchSegmentsTask: (0, _emberConcurrency.task)(function* (value) {
      yield (0, _emberConcurrency.timeout)(300);
      return yield this.store.query('manage/segment', {
        extra_fields: {
          'manage/segments': 'code_marked,name_marked,parent_name_path'
        },
        filter: {
          hierarchy: {
            eq: {
              search_query: value,
              segment_type_id: this.searchType
            }
          }
        },
        page: {
          size: 6,
          number: 1
        }
      }).then(results => (0, _autocompleteAnnouncement.default)(results, this.a11yAnnouncer)).catch(() => {
        this.notifications.error('Unable to perform search. Please try again later.');
      });
    })
  });

  _exports.default = _default;
});