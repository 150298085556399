define("balance-ember/components/budget-users-permissions-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nbCE2q4a",
    "block": "{\"symbols\":[\"tableState\",\"T\",\"@users\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,1],[\"table\"],null],[30,[36,0],null,[[\"rows\"],[[32,3]]]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"table\",[],[[\"@tableState\",\"@columns\"],[[32,1],[32,0,[\"columns\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"body\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"type\",\"state\",\"let\"]}",
    "moduleName": "balance-ember/components/budget-users-permissions-table/template.hbs"
  });

  _exports.default = _default;
});