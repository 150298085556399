define("balance-ember/utils/filters/format-display-text", ["exports", "balance-ember/utils/filters/text-formatter", "balance-ember/utils/filters/integer-formatter", "balance-ember/utils/filters/percent-formatter", "balance-ember/utils/filters/currency-formatter", "balance-ember/utils/filters/date-formatter", "balance-ember/utils/filters/select-formatter", "balance-ember/utils/filters/segment-formatter", "balance-ember/utils/filters/autocomplete-substring-formatter", "balance-ember/utils/get-category-config"], function (_exports, _textFormatter, _integerFormatter, _percentFormatter, _currencyFormatter, _dateFormatter, _selectFormatter, _segmentFormatter, _autocompleteSubstringFormatter, _getCategoryConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatDisplayText;
  _exports.formatFilters = formatFilters;
  const FORMATTER_CLASSES = {
    segment: _segmentFormatter.default,
    text: _textFormatter.default,
    integer: _integerFormatter.default,
    decimal: _integerFormatter.default,
    currency: _currencyFormatter.default,
    percent: _percentFormatter.default,
    date: _dateFormatter.default,
    select: _selectFormatter.default,
    'autocomplete-substring': _autocompleteSubstringFormatter.default,
    supplement: _autocompleteSubstringFormatter.default
  };

  function formatDisplayText(filter, categoryConfig) {
    if (!filter || !categoryConfig || !filter.isValid) {
      return '';
    }

    if (categoryConfig.format) {
      return categoryConfig.format(filter);
    }

    if (!filter.type.state) {
      return '';
    }

    let formatterClass = FORMATTER_CLASSES[filter.type.state];
    return new formatterClass(filter, categoryConfig).format();
  }

  function formatFilters(filters, categories) {
    return [...filters].map(filter => {
      let category = (0, _getCategoryConfig.default)(categories, filter.category.state);

      if (category) {
        return formatDisplayText(filter, category);
      }
    }).compact();
  }
});