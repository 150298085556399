define("balance-ember/components/budget/report-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AHDp7paf",
    "block": "{\"symbols\":[\"NavItem\",\"reportType\"],\"statements\":[[10,\"div\"],[14,0,\"min-w-290\"],[12],[2,\"\\n  \"],[8,\"list-group-title\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    Reports\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"side-nav\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"reportTypes\",\"filtered\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"button\"]],[],[[\"@route\"],[[30,[36,0],[\"budgeting.fiscal-year.reports.view\",[32,2]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n          \"],[1,[30,[36,3],[[30,[36,2],[\"budget.reports.types.\",[30,[36,1],[[32,2]],null]],null]],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"array\",\"underscore\",\"concat\",\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/budget/report-list/template.hbs"
  });

  _exports.default = _default;
});