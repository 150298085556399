define("balance-ember/types/bookmark", ["exports", "@microstates/ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BookmarkState {
    constructor() {
      _defineProperty(this, "status", (0, _ember.create)(String, 'view'));
    }

    edit() {
      if (this.status.state === 'view') {
        return this.status.set('edit');
      }
    }

    delete() {
      if (this.status.state === 'view') {
        return this.status.set('delete');
      }
    }

    promote() {
      if (this.status.state === 'view') {
        return this.status.set('promote');
      }
    }

    remind() {
      if (this.status.state === 'view') {
        return this.status.set('reminder');
      }
    }

    reset() {
      if (this.status.state !== 'view') {
        return this.status.set('view');
      }
    }

  }

  _exports.default = BookmarkState;
});