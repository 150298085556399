define("balance-ember/models/manage-export", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    modelType: (0, _model.attr)('string'),
    format: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)(),
    completedAt: (0, _model.attr)(),
    url: (0, _model.attr)('string'),
    possiblyFailed: (0, _model.attr)('boolean'),
    hidden: (0, _model.attr)('boolean'),
    inProgress: Ember.computed('completedAt', 'possiblyFailed', function () {
      return Ember.isEmpty(this.completedAt) && !this.possiblyFailed;
    }),
    completed: Ember.computed.notEmpty('completedAt'),
    prettyReportType: Ember.computed('modelType', function () {
      if (this.modelType && this.modelType.includes('account')) {
        return 'Accounts';
      }

      return 'Transactions';
    }),
    dateFormat: 'MMMM Do, h:mma',
    prettyCreatedAt: Ember.computed('createdAt', 'dateFormat', function () {
      return (0, _moment.default)(this.createdAt).format(this.dateFormat);
    }),
    prettyCompletedAt: Ember.computed('completedAt', 'dateFormat', function () {
      return (0, _moment.default)(this.completedAt).format(this.dateFormat);
    })
  });

  _exports.default = _default;
});