define("balance-ember/routes/manage/transactions", ["exports", "balance-ember/utils/manage/segment-filter-id-extractor", "balance-ember/utils/query-params", "balance-ember/utils/manage/budget-lea-transition"], function (_exports, _segmentFilterIdExtractor, _queryParams, _budgetLeaTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TransactionsRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.readOnly('availableBudgetLeas.current.id'), (_class = class TransactionsRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "availableBudgetLeas", _descriptor, this);

      _initializerDefineProperty(this, "eventTracking", _descriptor2, this);

      _initializerDefineProperty(this, "currentBudgetLeaId", _descriptor3, this);

      _defineProperty(this, "queryParams", {
        query: {
          refreshModel: true
        }
      });
    }

    activate() {
      document.title = 'Manage Transactions - Allovue Balance';
      this.eventTracking.trackEvent('View Manage Transactions', {
        balanceBudgetLea: this.availableBudgetLeas.current.name
      });
    }

    beforeModel(transition) {
      let budgetLeaTransition = new _budgetLeaTransition.default({
        route: this,
        transition,
        availableBudgetLeas: this.availableBudgetLeas
      });
      budgetLeaTransition.syncBudgetLea();

      if (budgetLeaTransition.shouldRedirect) {
        return;
      }
    }

    model({
      query
    }) {
      query = _queryParams.default.parse(query);
      let controller = this.controllerFor('manage/transactions');
      let categories = controller.categories;
      let segmentIds = new _segmentFilterIdExtractor.default({
        categories,
        query
      }).extract();
      let promises = [this.store.findAll('tag', {
        reload: true
      })];

      if (segmentIds.length) {
        promises.push(this.store.query('manage/segment', {
          filter: {
            id: {
              eq: segmentIds
            }
          },
          include: 'segment_type'
        }));
      }

      return Ember.RSVP.all(promises);
    }

    setupController(controller, model) {
      if (super.setupController) {
        super.setupController(controller, model);
        Ember.set(controller, 'tags', model[0]);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "availableBudgetLeas", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "eventTracking", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentBudgetLeaId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TransactionsRoute;
});