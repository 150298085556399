define("balance-ember/models/supplemental-pay-expense", ["exports", "balance-ember/models/expense", "@ember-data/model", "balance-ember/utils/sum-benefits"], function (_exports, _expense, _model, _sumBenefits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.supplementalPayExpenseComputedProperties = _exports.supplementalPayExpenseSchema = void 0;
  const supplementalPayExpenseSchema = {
    supplementalPayType: (0, _model.belongsTo)('supplementalPayType', {
      async: false
    }),
    employee: (0, _model.belongsTo)('employee', {
      async: false
    }),
    notes: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    pay: (0, _model.attr)('number', {
      readOnly: true
    }),
    benefits: (0, _model.attr)('number', {
      readOnly: true
    }),
    amount: (0, _model.attr)('number', {
      defaultValue: 0,
      readOnly: true
    })
  };
  _exports.supplementalPayExpenseSchema = supplementalPayExpenseSchema;
  const supplementalPayExpenseComputedProperties = {
    salarySegmentType: Ember.computed.filterBy('allSegmentTypes', 'salarySegment'),
    parentSegmentTypes: Ember.computed.union('allocationSegmentTypes', 'salarySegmentType'),
    purpose: Ember.computed.alias('notes'),
    benefitsAmount: Ember.computed('supplementalPayType.{amount,benefitsPackage}', function () {
      return (0, _sumBenefits.default)(this.supplementalPayType.benefitsPackage, this.supplementalPayType.amount);
    }),
    benefitsAmountByQuantity: Ember.computed('benefitsAmount', 'quantity', function () {
      return this.benefitsAmount * this.quantity;
    }),
    supplementalPayTypeAmountByQuantity: Ember.computed('supplementalPayType.amount', 'quantity', function () {
      var _this$supplementalPay;

      return (((_this$supplementalPay = this.supplementalPayType) === null || _this$supplementalPay === void 0 ? void 0 : _this$supplementalPay.amount) ?? 0) * this.quantity;
    }),
    totalAmount: Ember.computed('supplementalPayTypeAmountByQuantity', 'benefitsAmountByQuantity', function () {
      return this.supplementalPayTypeAmountByQuantity + this.benefitsAmountByQuantity;
    })
  };
  _exports.supplementalPayExpenseComputedProperties = supplementalPayExpenseComputedProperties;

  var _default = _expense.default.extend(supplementalPayExpenseSchema, supplementalPayExpenseComputedProperties);

  _exports.default = _default;
});