define("balance-ember/templates/budgeting/job-types/destroy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jt90gk2a",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"modal/destroy\",[],[[\"@closeModal\",\"@destroy\",\"@model\",\"@text\"],[[30,[36,0],[[32,0],\"cancelJobType\"],null],[30,[36,0],[[32,0],\"deleteJobType\"],null],[32,1],[30,[36,1],[\"global.modals.are_you_sure\"],[[\"modelName\"],[[32,1,[\"title\"]]]]]]],null]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "moduleName": "balance-ember/templates/budgeting/job-types/destroy.hbs"
  });

  _exports.default = _default;
});