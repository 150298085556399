define("balance-ember/components/user/profile-tabs/manage-permissions/table/permissions-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jXbNybOv",
    "block": "{\"symbols\":[\"permission\",\"segment\",\"@row\"],\"statements\":[[6,[37,6],[[32,3,[\"content\",\"hasAccess\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,6],[[32,3,[\"content\",\"customPermissions\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"Custom\"],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,3,[\"content\",\"customPermissions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,1,[\"segments\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"font-bold leading-tight mt-12\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"segmentAccess\"]]],null]],null]],[2,\" \"],[1,[30,[36,3],[[30,[36,2],[[32,1,[\"segmentType\"]]],null]],null]],[2,\" \"],[1,[32,2,[\"code\"]]],[2,\" - \"],[1,[32,2,[\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"Full Access\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[12],[2,\"No Access\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"pluralize\",\"capitalize\",\"titleize\",\"singularize\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "balance-ember/components/user/profile-tabs/manage-permissions/table/permissions-cell/template.hbs"
  });

  _exports.default = _default;
});