define("balance-ember/mixins/rollback-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Use this mixin in routes that need to rollback their model when the user leaves the route
   */
  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.router.on('routerWillChange', () => {
        // If the user tries to delete the budget and the delete call fails, the model will be in an
        // `isDeleted` state and will break any other things that try and interact with it (e.g.
        // deleting allocations). So if there are errors when we leave the destroy route then we
        // will reset the model so the rest of the app can continue on it's merry way.
        const model = this.controller.model;

        if (model.errors.length) {
          model.rollbackAttributes();
        }
      });
    }

  });

  _exports.default = _default;
});