define("balance-ember/utils/validators/supplemental-pay-type", ["exports", "balance-ember/utils/validator"], function (_exports, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class _default extends _validator.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "constraints", {
        segment: {
          presence: {
            allowEmpty: false
          }
        },
        name: {
          presence: {
            allowEmpty: false
          }
        },
        amount: {
          presence: {
            allowEmpty: false
          },
          numericality: {
            greaterThanOrEqualTo: 0
          }
        },
        code: {
          presence: {
            allowEmpty: false
          }
        }
      });

      _defineProperty(this, "constraintPropertyLookups", {
        segment: 'budget.supplemental_pay_types.data.segment',
        name: 'budget.supplemental_pay_types.data.name',
        amount: 'budget.supplemental_pay_types.data.amount',
        code: 'budget.supplemental_pay_types.data.code'
      });
    }

  }

  _exports.default = _default;
});