define("balance-ember/utils/writeable-models/purchase-order", ["exports", "balance-ember/utils/writeable-models/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PurchaseOrderWriteableModel extends _basic.default {
    constructor(args) {
      if (!args.paths) {
        args.paths = {
          vendor: true,
          allocation: {
            budget: true
          },
          notes: true
        };
      }

      super(args);
    }

  }

  _exports.default = PurchaseOrderWriteableModel;
});