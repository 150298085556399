define("balance-ember/templates/budgeting/budget/approval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wmHm7XAX",
    "block": "{\"symbols\":[\"cancel\",\"sendEvent\"],\"statements\":[[8,\"budget-approval-modal-actions\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"  \"],[1,[30,[36,2],[[30,[36,1],[\"budget-\",[32,0,[\"model\",\"eventType\"]],\"-modal\"],null]],[[\"closeModal\",\"sendEvent\",\"budget\"],[[32,1],[30,[36,0],[[32,0],[32,2],[32,0,[\"model\",\"budget\",\"id\"]],[32,0,[\"model\",\"eventType\"]]],null],[32,0,[\"model\",\"budget\"]]]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"concat\",\"component\"]}",
    "moduleName": "balance-ember/templates/budgeting/budget/approval.hbs"
  });

  _exports.default = _default;
});