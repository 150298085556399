define("balance-ember/components/account-amounts-donut/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let colors = {
    'gray600': '#868686'
  };

  var _default = Ember.Component.extend({
    /**
    * @param {string} donutTitle - HTML to fill in the donut title
    * @param {boolean} isDeficitTotals
    */
    options: Ember.computed('donutTitle', 'height', 'isDeficitTotals', 'width', function () {
      return {
        title: {
          text: this.donutTitle,
          align: 'center',
          verticalAlign: 'middle'
        },
        exporting: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        chart: {
          type: 'pie',
          spacing: [0, 0, 0, 0],
          width: this.width,
          // have to specify chart width/height explicitally with donuts
          height: this.height
        },
        plotOptions: {
          pie: {
            innerSize: '60%',
            // Makes it a donut
            tooltip: {
              pointFormat: "<span style='color:{point.color}'>\u25CF</span> <b>{point.y}%</b><br/>"
            },
            size: '100%',
            states: {
              hover: {
                enabled: !this.isDeficitTotals
              }
            }
          },
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: false,
              crop: false,
              align: 'center',
              format: '{percentage:,.0f}%',
              color: colors.gray600
            }
          }
        },
        tooltip: {
          enabled: !this.isDeficitTotals
        }
      };
    })
  });

  _exports.default = _default;
});