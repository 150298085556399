define("balance-ember/services/preferences", ["exports", "balance-ember/models/user-preference"], function (_exports, _userPreference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function convertPreferencesToComputedProperties() {
    return Object.keys(_userPreference.schema).reduce((acc, key) => {
      acc[key] = Ember.computed.readOnly(`all.${key}`);
      return acc;
    }, {});
  }

  let preferenceAliases = convertPreferencesToComputedProperties();

  var _default = Ember.Service.extend(Object.assign({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    currentUser: Ember.computed.readOnly('session.currentUser'),
    all: Ember.computed('currentUser.preferences', function () {
      if (this.currentUser && this.currentUser.preferences) {
        return this.currentUser.preferences;
      }

      return this.store.createRecord('userPreference', {
        user: this.currentUser
      });
    }),

    persist() {
      return this.all.save();
    },

    toggleHiddenColumnsPreference(preferenceKey, column) {
      let preferredHiddenColumns = Ember.get(this.all, preferenceKey);

      if (preferredHiddenColumns.includes(column)) {
        preferredHiddenColumns.removeObject(column);
      } else {
        preferredHiddenColumns.addObject(column);
      }
    },

    update(preference, value) {
      Ember.set(this.all, preference, value);
    }

  }, preferenceAliases));

  _exports.default = _default;
});