define("balance-ember/components/budget/vendors/banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TLyFpyyl",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"showBanner\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"banner\",[],[[\"@type\",\"@icon\",\"@text\",\"@dataTestId\"],[\"warning\",\"warning-exclamation\",[30,[36,0],[\"budget.banners.vendor.lacks_amazon_business.text\"],null],\"vendor-amazon-business-banner\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"icon-button\",[],[[\"@testId\",\"@style\",\"@onclick\",\"@buttonText\",\"@showButtonText\",\"@icon\"],[\"banner-vendor-cta\",\"btn-warning\",[32,0,[\"cta\"]],[30,[36,0],[\"budget.banners.vendor.lacks_amazon_business.cta\"],null],true,\"add-user\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "balance-ember/components/budget/vendors/banner/template.hbs"
  });

  _exports.default = _default;
});