define("balance-ember/components/budget/amazon-business/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ERkDkYMH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],null,[[\"isConnected\"],[[32,0,[\"amazonBusiness\",\"isConnected\"]]]]]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "balance-ember/components/budget/amazon-business/template.hbs"
  });

  _exports.default = _default;
});