define("balance-ember/routes/budget-lea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    availableBudgetLeas: Ember.inject.service(),
    session: Ember.inject.service(),

    model(params) {
      // Not sure if this is where this should go. The problem is `beforeModel`
      // doesn't receive the params and putting it in `afterModel` seems like
      // a great chance to open up race conditions
      return Ember.RSVP.all([this.store.peekRecord('budget-lea', params.budgetLeaId)]);
    }

  });

  _exports.default = _default;
});