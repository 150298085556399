define("balance-ember/components/disable-on-click-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xN6kNjfH",
    "block": "{\"symbols\":[\"@buttonText\",\"@disabledText\",\"@style\",\"@size\",\"&attrs\"],\"statements\":[[8,\"button\",[[17,5]],[[\"@style\",\"@size\",\"@testId\",\"@disabled\",\"@onclick\"],[[30,[36,0],[[32,3],\"btn-primary\"],null],[30,[36,0],[[32,4],\"md\"],null],\"save-button\",[32,0,[\"saveTask\",\"isRunning\"]],[30,[36,1],[[32,0,[\"saveTask\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,0,[\"saveTask\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\",\"perform\",\"if\"]}",
    "moduleName": "balance-ember/components/disable-on-click-button/template.hbs"
  });

  _exports.default = _default;
});