define("balance-ember/components/tag/form/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cOuYpwGX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"input\",\"results\"],[[30,[36,0],[\"tag/form/search/input\"],[[\"updateQuery\"],[[32,0,[\"updateQuery\"]]]]],[32,0,[\"results\"]]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/tag/form/search/template.hbs"
  });

  _exports.default = _default;
});