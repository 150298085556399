define("balance-ember/components/table/header/dropdown/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _component.default {
    get sortableBy() {
      if (this.args.column.sortableBy) {
        return this.args.column.sortableBy;
      }

      return [{
        value: this.args.column.sortName
      }];
    }

    get hasMultipleSortables() {
      return this.sortableBy.length > 1;
    }

  }

  _exports.default = _default;
});