define("balance-ember/components/manage/recent-transactions-widget/dropdown/info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W5P2rNMn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-material-gray600 w-128\"],[12],[2,\"\\n  \"],[1,[32,0,[\"label\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ml-16 max-w-380 font-semibold text-material-gray700\"],[12],[2,\"\\n  \"],[1,[32,0,[\"value\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/manage/recent-transactions-widget/dropdown/info/template.hbs"
  });

  _exports.default = _default;
});