define("balance-ember/components/downloads-dropdown-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cxiTXvBn",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[30,[36,1],[[32,0,[\"fileFormat\"]],\"csv\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"file-csv\",\"file-icon\"]],null],[2,\"\\n\"],[6,[37,0],[[32,0,[\"showDownloadIcon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"download-csv-file\",\"file-icon download-file-icon csv-format\"]],null],[2,\"\\n    \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"csv\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"file-xlsx\",\"svg-icon file-icon xlsx-format\"]],null],[2,\"\\n\"],[6,[37,0],[[32,0,[\"showDownloadIcon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"excel\"],[13],[2,\"\\n    \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"download-xlsx-file\",\"file-icon download-file-icon xlsx-format\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"eq\"]}",
    "moduleName": "balance-ember/components/downloads-dropdown-icon/template.hbs"
  });

  _exports.default = _default;
});