define("balance-ember/utils/bar-pattern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // @see https://api.highcharts.com/class-reference/global.html#PatternOptions
  function _default(color, backgroundColor = '') {
    return {
      pattern: {
        path: {
          d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
          strokeWidth: 3,
          stroke: color,
          fill: backgroundColor
        },
        width: 10,
        height: 10
      }
    };
  }
});