define("balance-ember/components/allocation-form/edit/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    segmentMode: 'view',
    needsConfirmation: false,
    isConfirming: false,
    segmentComponent: Ember.computed('segmentMode', function () {
      return `allocation-form/segments/${this.segmentMode}`;
    }),
    actions: {
      changeSegmentMode(mode) {
        Ember.set(this, 'needsConfirmation', true);
        Ember.set(this, 'segmentMode', mode);
      },

      confirmChanges() {
        let errors = this.validate();

        if (errors) {
          return;
        }

        if (this.segmentMode === 'edit') {
          Ember.set(this, 'isConfirming', true);
        }
      }

    }
  });

  _exports.default = _default;
});