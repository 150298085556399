define("balance-ember/types/autocomplete-substring-filter", ["exports", "balance-ember/types/basic-filter", "balance-ember/types/autocomplete-substring-operator", "@microstates/ember"], function (_exports, _basicFilter, _autocompleteSubstringOperator, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AutocompleteSubstringFilter extends _basicFilter.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "operators", [_autocompleteSubstringOperator.default]);
    }

    get serializer() {
      return new AutocompleteSubstringFilterSerializer(this);
    }

    get totalFilterValues() {
      return new AutocompleteSubstringFilterCounter(this).count();
    }

  }

  _exports.default = AutocompleteSubstringFilter;

  class AutocompleteSubstringFilterSerializer extends _basicFilter.FilterSerializer {
    serializeFilterValue(filterValue) {
      if (typeof (0, _ember.valueOf)(filterValue) === 'object') {
        return this.trimValueWhiteSpace((0, _ember.valueOf)(filterValue));
      }

      return super.serializeFilterValue(filterValue);
    }
    /**
     * We have to deeply trim whitespace because this kind of filter can have
     * an eq_group type of value which has nested operators
     * @param {FilterValue} value
     */


    trimValueWhiteSpace(value) {
      return Object.keys(value).reduce((acc, curr) => {
        acc[Ember.String.underscore(curr)] = value[curr].map(filterValue => super.serializeFilterValue(filterValue));
        return acc;
      }, {});
    }

  }

  class AutocompleteSubstringFilterCounter extends _basicFilter.FilterCounter {
    countValuesForOperator(operator) {
      if (operator.value.state === 'eq_group') {
        return (0, _ember.valueOf)(operator.filterValues).reduce((total, filterValue) => {
          return Object.keys(filterValue).map(key => filterValue[key].length).reduce((acc, length) => length + acc, total);
        }, 0);
      }

      return super.countValuesForOperator(operator);
    }

  }
});