define("balance-ember/components/table/header/dropdown/sort-item/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    eventTracking: Ember.inject.service(),
    dir: '',
    sortType: '',
    trackingPrefix: Ember.computed.readOnly('extra.trackingPrefix'),
    tableState: Ember.computed.readOnly('extra.tableState'),
    firstSort: Ember.computed.readOnly('tableState.firstSort'),
    sortedColumn: Ember.computed.readOnly('firstSort.column'),
    isColumnSorted: Ember.computed('column.sortName', 'sortedColumn', 'tableState', function () {
      let sortedColumn = this.sortedColumn || {};
      return sortedColumn.state === this.column.sortName;
    }),
    actions: {
      updateSort() {
        if (this.trackingPrefix) {
          this.eventTracking.trackEvent(`${this.trackingPrefix} Sort`, {
            column: this.column.sortName,
            direction: this.dir,
            type: this.sortType
          });
        }

        this.tableState.updateSortColumn(0, this.column.sortName).updateSortDir(0, this.dir).updateSortType(0, this.sortType);
      }

    }
  });

  _exports.default = _default;
});