define("balance-ember/templates/budgeting/benefits-packages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wBfsM6MI",
    "block": "{\"symbols\":[\"InlineItem\"],\"statements\":[[8,\"container\",[],[[\"@style\"],[\"horizontal centered page padding-x\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"budget/page-header\",[],[[\"@title\"],[[30,[36,0],[\"budget.benefits.index.page_title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/inline\",[],[[\"@space\"],[\"xs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n         \"],[8,\"icon-button\",[],[[\"@route\",\"@style\",\"@testId\",\"@icon\",\"@buttonText\",\"@showButtonText\"],[[30,[36,1],[\"budgeting.benefits-packages.new\"],null],\"btn-primary\",\"create-benefits-package\",\"circle-plus\",\"Create Benefits Package\",true]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"flex flex-1 items-end\"],[12],[2,\"\\n        \"],[8,\"report-export-single\",[],[[\"@reportType\"],[\"benefits-report\"]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mt-16\"],[12],[2,\"\\n    \"],[8,\"benefits-package-table\",[],[[\"@tableState\",\"@categories\",\"@benefitsPackageChanges\"],[[32,0,[\"tableState\"]],[32,0,[\"categories\"]],[32,0,[\"benefitsPackageChanges\"]]]],null],[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"array\",\"-outlet\",\"component\"]}",
    "moduleName": "balance-ember/templates/budgeting/benefits-packages.hbs"
  });

  _exports.default = _default;
});