define("balance-ember/utils/is-text-overflowing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isTextOverflowing;

  /**
   * isTextOverflowing - Find out if the text is overflowing it's container
   *
   * @param  {element} element The dom element
   * @return {boolean}
   */
  function isTextOverflowing(element) {
    return element.scrollHeight > element.clientHeight;
  }
});