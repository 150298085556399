define("balance-ember/components/filter-form/number/component", ["exports", "balance-ember/components/filter-form/basic/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    defaultOperator: 'eq',
    defaultFilterValue: '0.00',

    setOperatorWithDefaults() {
      let firstOperator = this._super(...arguments);

      if (firstOperator.value.state === 'between') {
        firstOperator.updateFilterValue(1, this.defaultFilterValue);
      }
    },

    actions: {
      changeOperator(newOperator) {
        this.setOperatorWithDefaults(newOperator);
      }

    }
  });

  _exports.default = _default;
});