define("balance-ember/components/expense-index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4osbhJM+",
    "block": "{\"symbols\":[\"@allocation\"],\"statements\":[[8,\"non-personnel-expenses\",[],[[\"@allocation\",\"@deleteExpense\"],[[32,1],[30,[36,0],[[32,0],\"showDeleteModal\"],null]]],null],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"shouldShowDeleteModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"destroy-expense-modal\",[],[[\"@expense\",\"@onDestroy\",\"@onClose\"],[[32,0,[\"expenseToDelete\"]],[30,[36,0],[[32,0],\"closeDeleteModal\"],null],[30,[36,0],[[32,0],\"closeDeleteModal\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "moduleName": "balance-ember/components/expense-index/template.hbs"
  });

  _exports.default = _default;
});