define("balance-ember/utils/writeable-models/fiscal-year", ["exports", "balance-ember/utils/writeable-models/basic", "moment"], function (_exports, _basic, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FiscalYearWriteableModel extends _basic.default {
    get dateDiff() {
      return (0, _moment.default)(this.endDate).diff((0, _moment.default)(this.startDate));
    }

  }

  _exports.default = FiscalYearWriteableModel;
});