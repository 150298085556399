define("balance-ember/services/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * We're using the simple-auth session service as the interface into local storage to keep things
     * consistent
     */
    session: Ember.inject.service(),

    /**
     * Properties that we want to persist in the browser even when the user logs out. This will help
     * make it so the preferences the user has chosen about how they want to look at the data will
     * stay the same from session to session
     */
    ephemeralProps: Ember.computed.oneWay('_defaultProps'),
    _defaultProps: Ember.computed({
      get() {
        return {
          isAccountChartHidden: false,
          isTransactionChartHidden: false,
          isDisplayCodes: false,
          isDisplayPercentages: true,
          hiddenColumns: {}
        };
      }

    }),

    defaultForType(type) {
      return type !== 'amount_ratio' ? 'equal' : 'greater';
    },

    /**
     * Add a users filter settings to local storage so the user can add new filters quickly
     */
    cachedMatchFor(category, newMatchValue = undefined) {
      let {
        value: key,
        type
      } = category;
      key = key.replace(/\./g, '_');
      let cachedForKey = this.ephemeralProps['cachedMatchFor' + key];
      let match = newMatchValue || cachedForKey || this.defaultForType(type);
      Ember.set(this, 'ephemeralProps.cachedMatchFor' + key, match);
      this.session.set('data.ephemeralProps', this.ephemeralProps);
      return match;
    },

    /**
     * Push the current state to local storage
     */
    persist() {
      this.session.set('data.ephemeralProps', this.ephemeralProps);
    },

    /**
     * Merge the default settings with any user-stored preferences.
     */
    init() {
      this._super(...arguments);

      const session = this.session;
      const ephemeralProps = session.data.ephemeralProps;
      const defaultProps = this._defaultProps;
      Ember.set(this, 'ephemeralProps', Ember.assign(defaultProps, ephemeralProps));
    }

  });

  _exports.default = _default;
});