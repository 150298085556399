define("balance-ember/components/filter-form/component", ["exports", "balance-ember/components/tagless-component/component", "@microstates/ember", "balance-ember/types/filter"], function (_exports, _component, _ember, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lookUpFilterComponent = lookUpFilterComponent;
  _exports.default = void 0;
  const COMPONENT_LOOKUP = {
    text: 'text',
    date: 'date',
    decimal: 'number',
    currency: 'number',
    integer: 'number',
    percent: 'number',
    select: 'select',
    'autocomplete-substring': 'autocomplete-substring',
    segment: 'segment',
    supplement: 'supplement'
  };

  function lookUpFilterComponent(type) {
    return `filter-form/${COMPONENT_LOOKUP[type]}`;
  }

  var _default = _component.default.extend({
    /**
     * @param {Microstate<Filter>}
     */
    filter: null,

    /**
     * @param {object}
     */
    categoryConfig: null,

    /**
     * @param {action}
     */
    cancel() {},

    /**
     * @param {action}
     */
    done() {},

    forkedFilter: null,
    componentName: Ember.computed('filterOptions.componentName', 'forkedFilter.type.state', function () {
      let componentName = this.filterOptions && this.filterOptions.componentName;
      return componentName ? componentName : lookUpFilterComponent(this.forkedFilter.type.state);
    }),
    filterOptions: Ember.computed.readOnly('categoryConfig.filterOptions'),
    categoryTitle: Ember.computed.readOnly('categoryConfig.title'),

    didReceiveAttrs() {
      this._super();

      this.forkFilter();
    },

    forkFilter() {
      let forkedFilterData = (0, _ember.valueOf)(this.filter);
      let forkedFilter = (0, _ember.create)(_filter.default, forkedFilterData); // Update the filter property anytime it moves to a new state

      forkedFilter = (0, _ember.Store)(forkedFilter, nextState => Ember.set(this, 'forkedFilter', nextState));
      Ember.set(this, 'forkedFilter', forkedFilter);
    }

  });

  _exports.default = _default;
});