define("balance-ember/components/manage/transactions-column-chart/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/bar-pattern", "@microstates/ember", "balance-ember/components/manage/transactions-column-chart/tooltip-formatter"], function (_exports, _component, _barPattern, _ember, _tooltipFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let screens = {
    'md': '768px'
  };
  let colors = {
    'material-gray700': '#40555B',
    'material-indigo600': '#3949AB',
    'material-cyan600': '#00ACC1'
  };
  const MAX_BAR_WIDTH = 30;
  const BAR_BORDER_WITH = 3;
  /**
   * @param {Table} tableState The underlying Table for the current page of data
   */

  var _default = _component.default.extend({
    media: Ember.inject.service(),

    /**
     * @param
     */
    title: '',
    options: Ember.computed('axis.defaultLabelFormatter', 'chartWidth', 'title', function () {
      return {
        title: {
          text: this.title ? this.title : 'Transactions',
          align: 'left',
          y: 15,
          // Attempt to align the dropdown
          style: {
            fontSize: '16px',
            color: colors['material-gray700'],
            fontFamily: 'Inter UI, Arial, sans-serif',
            fontWeight: 'bold'
          }
        },
        chart: {
          type: 'column',
          borderRadius: 4,
          spacingLeft: 18,
          spacingRight: 18,
          width: this.chartWidth,
          className: 'mx-auto'
        },
        xAxis: {
          // Don't show x-axis labels, because each one represents an account and we couldn't fit
          // something that identifies an account
          visible: false
        },
        yAxis: {
          title: {
            text: ''
          },
          // Make each y-axis label start with a dollar sign
          labels: {
            formatter() {
              return `$${this.axis.defaultLabelFormatter.call(this)}`;
            }

          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          column: {
            cursor: 'pointer',
            // Turn on stacking mode
            stacking: 'normal',
            // Set grouping to false, which will allow the columns to overlap
            grouping: false,
            // Evenly space the columns away from each other
            groupPadding: 0,
            // Set a max-width for the columns
            maxPointWidth: MAX_BAR_WIDTH
          }
        },
        tooltip: {
          formatter: _tooltipFormatter.default,
          backgroundColor: 'white',
          useHTML: true,
          padding: 0,
          borderRadius: 4
        }
      };
    }),
    chartWidth: Ember.computed('media.{matches.[],isDesktop,isXl}', 'numRows', 'tableState', function () {
      if (this.numRows && this.numRows < 10 && (this.media.isDesktop || this.media.isXl)) {
        return Number(screens.md.replace('px', ''));
      }

      return null;
    }),
    numRows: Ember.computed.readOnly('tableState.rows.length'),
    transactions: Ember.computed('tableState.rows', function () {
      return (0, _ember.valueOf)(this.tableState.rows);
    }),
    series: Ember.computed.readOnly('normalSeries'),
    styles: Object.freeze({
      actual: 'material-indigo600',
      encumbrance: 'material-cyan600'
    }),
    normalSeries: Ember.computed('styles', 'transactions', function () {
      return [Object.assign({}, {
        name: 'Amount',
        data: this.transactions.map(transaction => {
          let {
            amount,
            status,
            transactionDate
          } = transaction;
          let colorClass = this.styles[status];
          let color = this.barColor(colorClass, amount);
          return {
            accountCode: transaction.account.code,
            y: amount,
            colorClass,
            supplements: transaction['supplements'],
            color,
            borderColor: color,
            borderWidth: this.barBorderWidth(amount),
            amount,
            transactionDate,
            className: `test-${status}-amount-bar-${amount}`
          };
        })
      })];
    }),

    barColor(colorClass, amount) {
      let defaultColor = colors[colorClass];
      let isNegative = amount < 0;
      return isNegative ? (0, _barPattern.default)(defaultColor) : defaultColor;
    },

    barBorderWidth(amountType, amount) {
      // We want the border to match the "over budget" bar so that the edges meet
      let isNegative = amount < 0;
      return isNegative ? BAR_BORDER_WITH : 0;
    },

    actions: {
      updateChartWidth(chart) {
        // We need to explicitally set chart width with this function because updating the options
        // from a Number to null isn't respected and the old number stays in place. Turning the
        // animation option to false here because some errors get thrown in the console otherwise
        // about Highcharts. Error: <rect> attribute height: Expected length "2.2343223..."
        chart.setSize(this.chartWidth, null, false);
      }

    }
  });

  _exports.default = _default;
});