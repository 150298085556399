define("balance-ember/utils/validators/non-personnel-expense", ["exports", "balance-ember/utils/validator"], function (_exports, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class _default extends _validator.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "constraints", {
        segments: {
          length: {
            is: this.options.numSegments,
            message: 'budget.non_personnel_expense_form.validation.segments'
          }
        },
        expenseStrategies: {
          sum: {
            key: 'percentage',
            lessThanOrEqualTo: 100
          },
          array: {
            percentage: {
              numericality: {
                greaterThan: 0
              },
              presence: true
            },
            budgetStrategy: {
              presence: true
            }
          }
        },
        name: {
          presence: {
            allowEmpty: false
          }
        },
        items: {
          length: {
            minimum: 1,
            message: 'budget.non_personnel_expense_form.validation.items'
          },
          array: {
            unitPrice: {
              presence: true,
              numericality: true
            },
            quantity: {
              presence: true,
              numericality: true
            },
            description: {
              presence: {
                allowEmpty: false
              }
            }
          }
        },
        vendorSelection: {
          vendor: true
        }
      });

      _defineProperty(this, "constraintPropertyLookups", {
        name: 'budget.non_personnel_expense_form.data.name',
        segments: 'budget.non_personnel_expense_form.data.segments',
        expenseStrategies: 'budget.non_personnel_expense_form.data.strategies.strategies',
        items: 'budget.non_personnel_expense_form.data.items_property',
        vendorSelection: 'budget.non_personnel_expense_form.data.vendor',
        'items.description': 'budget.non_personnel_expense_form.data.items.description',
        'items.unitPrice': 'budget.non_personnel_expense_form.data.items.unit_price',
        'items.quantity': 'budget.non_personnel_expense_form.data.items.quantity',
        'expenseStrategies.budgetStrategy': 'budget.non_personnel_expense_form.data.strategies.budget_strategy',
        'expenseStrategies.percentage': 'budget.non_personnel_expense_form.data.strategies.percentage'
      });
    }

  }

  _exports.default = _default;
});