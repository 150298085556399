define("balance-ember/components/manage/segment-header-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WQTe5txr",
    "block": "{\"symbols\":[\"StackItem\",\"@column\",\"@extra\",\"@sortIcons\"],\"statements\":[[8,\"table/header\",[],[[\"@column\",\"@extra\",\"@sortIcons\"],[[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"layout/stack\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"table/header/dropdown/sort-item\",[],[[\"@column\",\"@testId\",\"@dir\",\"@sortType\",\"@extra\",\"@icon\",\"@buttonText\"],[[32,2],\"sort-ascending-name-button\",\"asc\",\"name\",[32,3],\"sort-asc-letters\",\"Sort By Name: Ascending\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"table/header/dropdown/sort-item\",[],[[\"@column\",\"@testId\",\"@dir\",\"@sortType\",\"@extra\",\"@icon\",\"@buttonText\"],[[32,2],\"sort-descending-name-button\",\"desc\",\"name\",[32,3],\"sort-desc-letters\",\"Sort By Name: Descending\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"table/header/dropdown/sort-item\",[],[[\"@column\",\"@testId\",\"@dir\",\"@sortType\",\"@extra\",\"@icon\",\"@buttonText\"],[[32,2],\"sort-ascending-code-button\",\"asc\",\"code\",[32,3],\"sort-asc-numbers\",\"Sort By Code: Ascending\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"table/header/dropdown/sort-item\",[],[[\"@column\",\"@testId\",\"@dir\",\"@sortType\",\"@extra\",\"@icon\",\"@buttonText\"],[[32,2],\"sort-descending-code-button\",\"desc\",\"code\",[32,3],\"sort-desc-numbers\",\"Sort By Code: Descending\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/manage/segment-header-dropdown/template.hbs"
  });

  _exports.default = _default;
});