define("balance-ember/components/manage/spending-by-segment/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/v+1PY2/",
    "block": "{\"symbols\":[\"@row\"],\"statements\":[[10,\"div\"],[14,0,\"font-bold\\n  text-material-gray600\\n  border-b\\n  border-material-gray400\\n  px-16\\n  py-10\"],[12],[2,\"\\n  \"],[1,[32,1,[\"segmentName\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"px-16 py-10 border-b border-material-gray400\"],[12],[2,\"\\n  \"],[8,\"manage/spending-by-segment/dropdown/info\",[],[[\"@amount\",\"@amountColor\",\"@amountLabel\"],[[32,1,[\"budgeted\"]],\"material-gray200\",\"Budgeted\"]],null],[2,\"\\n\\n  \"],[8,\"manage/spending-by-segment/dropdown/info\",[],[[\"@amount\",\"@amountColor\",\"@amountLabel\"],[[32,1,[\"spent\"]],\"material-purple300\",\"Spent\"]],null],[2,\"\\n\\n  \"],[8,\"manage/spending-by-segment/dropdown/info\",[],[[\"@amount\",\"@amountColor\",\"@amountLabel\"],[[32,1,[\"encumbered\"]],\"material-blue300\",\"Encumbered\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"px-16 py-10\"],[12],[2,\"\\n  \"],[8,\"manage/spending-by-segment/dropdown/info\",[],[[\"@amount\",\"@amountColor\",\"@amountLabel\"],[[32,1,[\"available\"]],\"material-light-green300\",\"Available\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/manage/spending-by-segment/dropdown/template.hbs"
  });

  _exports.default = _default;
});