define("balance-ember/helpers/format-bytes", ["exports", "balance-ember/utils/format-bytes"], function (_exports, _formatBytes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatBytesHelper(params, hash) {
    return (0, _formatBytes.default)(params[0], hash.decimals);
  });

  _exports.default = _default;
});