define("balance-ember/components/date-cell/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DateCell extends _component.default {
    get formattedDate() {
      return this.parsedDate.format(this.args.column.format);
    }

    get parsedDate() {
      if (this.timeZoneMode === 'utc') {
        return _moment.default.utc(this.args.value);
      }

      if (this.timeZoneMode === 'exact') {
        return _moment.default.parseZone(this.args.value);
      }

      return (0, _moment.default)(this.args.value);
    }

    get timeZoneMode() {
      return this.args.column.timeZoneMode || 'user';
    }

  }

  _exports.default = DateCell;
});