define("balance-ember/components/highcharts-chart/component", ["exports", "balance-ember/components/tagless-component/component", "highcharts"], function (_exports, _component, _highcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Given a configuration object create a highcharts chart. This is a bare-bones component that
   * is only responsible for rendering a chart given the configuration object, it has no opinion
   * about the kind of chart. If you want the chart to change, you need to pass in a new
   * configuration object
   *
   * @param {object} options The configuration object and options for initial setup
   * @param {string} destinationId Optional. Element Id to render the chart if not the default
   * @param {function} updateChart Optional. Hook that can be used to customize how the chart is updated
   */
  var _default = _component.default.extend({
    defaultOptions: null,
    destinationId: 'chart-destination',
    chart: null,
    oldOptions: null,
    options: null,
    series: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'data', []);
    },

    didInsertElement() {
      this._super(...arguments); // Create the highchart once the DOM is ready


      let chart = _highcharts.default.chart(this.destinationId, this.options);

      Ember.set(this, 'chart', chart);
      this.addAllSeriesToChart();
    },

    didReceiveAttrs() {
      this._super();

      let chart = this.chart;

      if (chart) {
        if (chart.forceReplace) {
          this.replaceAllSeries();
        } else if (chart.series.length && this.areSeriesSameLength) {
          this.updateAllSeriesData();
          this.afterUpdateSeries(chart);
        } else if (this.areSeriesDifferentLengths) {
          this.replaceAllSeries();
        } else {
          this.addAllSeriesToChart();
        }

        this.updateChartOptions();
      }
    },

    areSeriesSameLength: Ember.computed('chart.series.length', 'series.length', function () {
      return this.chart.series.length === this.series.length;
    }),
    areSeriesDifferentLengths: Ember.computed.not('areSeriesSameLength'),

    updateAllSeriesData() {
      this.series.mapBy('data').forEach((seriesData, index) => {
        let series = this.chart.series[index]; // Update the series data without replacing the whole series so that the bars animate
        // from their old position to their new one if the number of data points is the same

        series.setData(seriesData);
        series.update({
          type: this.series[index].type
        }, false);
      });
    },

    afterUpdateSeries() {},

    replaceAllSeries() {
      this.chart.update({
        series: this.series
      }, true, true);
    },

    addAllSeriesToChart() {
      this.series.forEach(series => {
        this.chart.addSeries(series);
      });
    },

    updateChartOptions() {
      if (this.oldOptions !== this.options) {
        // We turn on `redraw` to true, so that the new data coming in updates the visual representation
        // of the chart.
        // We also set `oneToOne` to `true` series are added and removed in relation to the original
        // data
        // @see https://api.highcharts.com/class-reference/Highcharts.Chart#update
        this.chart.update(this.options, true, true);
      }

      Ember.set(this, 'oldOptions', this.options);
    },

    willDestroyElement() {
      this._super(...arguments);

      let chart = this.chart;

      if (chart) {
        chart.destroy();
      }
    }

  });

  _exports.default = _default;
});