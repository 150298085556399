define("balance-ember/components/balance-user-access-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lFmR3rNx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[30,[36,0],[\"text-\",[32,0,[\"iconColor\"]],\" flex\"],null]],[12],[2,\"\\n  \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[[32,0,[\"icon\"]],\"svg-icon\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"ml-4 hidden lg:inline\"],[12],[1,[32,0,[\"text\"]]],[13],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,1],[\"isTablet\"],null],[30,[36,1],[\"isMobile\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[1,[32,0,[\"text\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"media\",\"or\",\"if\"]}",
    "moduleName": "balance-ember/components/balance-user-access-cell/template.hbs"
  });

  _exports.default = _default;
});