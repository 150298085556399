define("balance-ember/components/planned-spending-by-segment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bfCe/A13",
    "block": "{\"symbols\":[\"Widget\",\"T\",\"@segmentTypes\"],\"statements\":[[8,\"dashboard-widget\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Planned Subtotals By Segment\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"one-way-select\",[[24,1,\"spending-by-segment-selector\"]],[[\"@value\",\"@update\",\"@classNames\",\"@options\",\"@optionValuePath\",\"@optionLabelPath\"],[[32,0,[\"chosenSegmentType\"]],[30,[36,0],[[32,0],\"updateSegmentType\"],null],\"css-select-dropdown max-w-320\",[32,3],\"id\",\"name\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"dashboard-table\",[],[[\"@tableState\",\"@columns\",\"@loadTask\",\"@loadingText\",\"@height\",\"@rowTestSelector\"],[[32,0,[\"tableState\"]],[32,0,[\"plannedSpendingColumns\"]],[32,0,[\"loadTask\"]],\"Loading Segments\",\"588px\",\"segment-row\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"flex justify-between items-center\"],[12],[2,\"\\n        \"],[8,[32,2,[\"pagination-buttons\"]],[],[[\"@setPage\"],[[30,[36,0],[[32,0],\"setPage\"],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/components/planned-spending-by-segment/template.hbs"
  });

  _exports.default = _default;
});