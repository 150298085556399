define("balance-ember/components/manage/summarize/header/cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GCSWqsaL",
    "block": "{\"symbols\":[\"@amount\",\"&default\",\"@paddingClass\"],\"statements\":[[10,\"th\"],[15,0,[30,[36,1],[\"text-right py-16 \",[32,3]],null]],[12],[2,\"\\n\"],[6,[37,2],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,1]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\",\"concat\",\"if\"]}",
    "moduleName": "balance-ember/components/manage/summarize/header/cell/template.hbs"
  });

  _exports.default = _default;
});