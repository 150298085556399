define("balance-ember/components/allocation-form/create/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    segmentMode: 'create',
    segmentComponent: Ember.computed('segmentMode', function () {
      return `allocation-form/segments/${this.segmentMode}`;
    }),
    needsConfirmation: Ember.computed.reads('allocation.hasPersonnelSegment'),
    confirmAllocation: (0, _emberConcurrency.task)(function* (afterSave) {
      if (this.needsConfirmation && !this.isConfirming) {
        Ember.set(this, 'isConfirming', true); // We need to pass `afterSave` to the confirmation modal so it knows what to do

        Ember.set(this, 'afterSave', afterSave);
      } else {
        yield this.saveAllocation.linked().perform(this.afterSave ?? afterSave);
      }
    }).drop(),
    actions: {
      confirmChanges() {
        Ember.set(this, 'allocation.shouldValidate', true);

        if (this.allocation.validations.isValid) {
          Ember.set(this, 'isConfirming', true);
        }
      }

    }
  });

  _exports.default = _default;
});