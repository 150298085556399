define("balance-ember/routes/budgeting/budget/pays", ["exports", "balance-ember/mixins/route-history"], function (_exports, _routeHistory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeHistory.default, {
    model() {
      return this.modelFor('budgeting.budget');
    }

  });

  _exports.default = _default;
});