define("balance-ember/components/user/profile-tabs/manage-permissions/table/actions-cell/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    actions: {
      delete(budgetLea) {
        this.popover.hide();
        this.column.delete(budgetLea);
      },

      edit() {
        this.popover.hide();
        this.column.edit();
      }

    }
  });

  _exports.default = _default;
});