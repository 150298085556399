define("balance-ember/authenticators/devise", ["exports", "balance-ember/config/environment", "ember-simple-auth/authenticators/devise"], function (_exports, _environment, _devise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devise.default.extend({
    identificationAttributeName: 'email',
    serverTokenEndpoint: _environment.default.serverTokenEndpoint
  });

  _exports.default = _default;
});