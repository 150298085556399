define("balance-ember/controllers/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NotFoundController = Ember.Controller.extend({
    session: Ember.inject.service(),
    liveChat: Ember.inject.service(),
    availableBudgetLeas: Ember.inject.service(),
    currentBudgetLea: Ember.computed.readOnly('availableBudgetLeas.current'),
    actions: {
      showSupportChat() {
        this.liveChat.open();
      }

    }
  });
  var _default = NotFoundController;
  _exports.default = _default;
});