define("balance-ember/components/pill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z4Z6yQB/",
    "block": "{\"symbols\":[\"@tooltip\",\"@color\",\"@width\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[\"px-6\\n                 h-20\\n                 \",[32,3],\"\\n                 pb-22\\n                 text-white\\n                 text-center\\n                 text-sm\\n                 font-medium\\n                 mr-2\\n                 mt-2\\n                 rounded-full\\n                 \",[32,2]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"tooltip\",[],[[\"@text\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[18,4,null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/pill/template.hbs"
  });

  _exports.default = _default;
});