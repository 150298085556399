define("balance-ember/components/filter-form/multi/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/components/filter-form/multi/filter-input"], function (_exports, _component, _filterInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    defaultOperator: '',
    filter: null,
    filterInputs: null,
    filterInputBuilder: _filterInput.FilterInputBuilder,
    filterUpdater: _filterInput.FilterUpdater,

    init() {
      this._super(...arguments);

      this.initializeFilterInputs();
    },

    initializeFilterInputs() {
      let filterInputs = new this.filterInputBuilder(this.filter).build();
      filterInputs.push(this.createBlankFilterInput());
      Ember.set(this, 'filterInputs', filterInputs);
    },

    addBlankFilterInput() {
      let filterInputs = [...this.filterInputs].concat(this.createBlankFilterInput());
      Ember.set(this, 'filterInputs', filterInputs);
    },

    createBlankFilterInput() {
      return new _filterInput.FilterInput({
        operator: this.defaultOperator
      });
    },

    updateFilterState() {
      new this.filterUpdater(this.filter, this.filterInputs).update();
    },

    copyAndReplaceFilterInput(filterInput) {
      let index = this.filterInputs.indexOf(filterInput);
      let newFilterInput = Object.assign(new _filterInput.FilterInput(), filterInput);
      this.filterInputs.replace(index, 1, [newFilterInput]);
    },

    completeFilterInput(filterInput, filterValue) {
      filterValue = this.getInputValue(filterValue);
      Ember.set(filterInput, 'value', filterValue);
      Ember.set(filterInput, 'completed', true);
    },

    getInputValue(filterValue) {
      return filterValue;
    },

    actions: {
      completeFilter(filterInput, filterValue) {
        if (filterValue) {
          this.completeFilterInput(filterInput, filterValue); // Have to completely replace the object reference or the `each` helper won't rerender
          // this one

          this.copyAndReplaceFilterInput(filterInput);
          this.addBlankFilterInput();
          this.updateFilterState();
        }
      },

      removeFilterInput(filterInput) {
        this.filterInputs.removeObject(filterInput);
        this.updateFilterState();
      },

      updateOperator(filterInput, operator) {
        Ember.set(filterInput, 'operator', operator);
        this.updateFilterState();
      },

      updateValue(filterInput, value) {
        Ember.set(filterInput, 'value', value);
        this.updateFilterState();
      }

    }
  });

  _exports.default = _default;
});