define("balance-ember/components/ui-tabs/link-tab/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4wEhN8n0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"shouldUseShadowBlocker\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"absolute\\n    h-12\\n    mr-10\\n    bg-white\\n    css-bottom-shadow-blocker\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/ui-tabs/link-tab/template.hbs"
  });

  _exports.default = _default;
});