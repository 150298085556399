define("balance-ember/components/manage/summarize/cell/segment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TJc2BgPG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,0,[\"segmentName\"]],\"--\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"text-material-blue-gray400\"],[12],[2,\"\\n  \"],[1,[32,0,[\"segmentType\"]]],[2,\" \"],[1,[32,0,[\"segmentCode\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\"]}",
    "moduleName": "balance-ember/components/manage/summarize/cell/segment/template.hbs"
  });

  _exports.default = _default;
});