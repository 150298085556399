define("balance-ember/components/relative-date-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LRmgKNF1",
    "block": "{\"symbols\":[\"b\",\"@dateType\",\"@onclick\",\"@testId\",\"@heading\",\"&default\"],\"statements\":[[8,\"button\",[[16,0,[31,[\"\\n    w-full\\n    \",[30,[36,0],[[32,0,[\"isActive\"]],[32,0,[\"appliedClasses\"]]],null],\"\\n  \"]]]],[[\"@style\",\"@size\",\"@active\",\"@onclick\",\"@testId\"],[[30,[36,0],[[32,0,[\"isActive\"]],\"none\",\"btn-default\"],null],\"sm\",[32,0,[\"isActive\"]],[30,[36,1],[[32,0],[32,3],[32,2]],null],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"p-8 \",[30,[36,0],[[32,0,[\"isFlat\"]],\"flex items-center w-full\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,0],[[32,0,[\"isFlat\"]],\"w-96 text-left\"],null]],[12],[1,[32,5]],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"\\n        ease-in-out-all-1/5s\\n        whitespace-nowrap\\n        \",[30,[36,2],[[32,1,[\"isActive\"]],\"group-hover:text-material-light-blue600\"],null],\"\\n        \",[30,[36,0],[[32,1,[\"isActive\"]],\"text-white\",\"text-material-gray600\"],null],\"\\n        \",[30,[36,0],[[32,0,[\"isFlat\"]],\"text-sm\",\"text-xs mt-4\"],null]]]],[12],[2,\"\\n      \"],[18,6,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"unless\"]}",
    "moduleName": "balance-ember/components/relative-date-button/template.hbs"
  });

  _exports.default = _default;
});