define("balance-ember/components/support-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+qHM/Ijx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"support-block\"],[12],[2,\"\\n  For help, reach out to us by emailing or \"],[11,\"a\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"showSupportChat\"],null],[12],[2,\"chat live\"],[13],[2,\".\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/components/support-block/template.hbs"
  });

  _exports.default = _default;
});