define("balance-ember/components/app-footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'footer',
    classNames: ['app-footer'],
    currentYear: Ember.computed(function () {
      return new Date().getFullYear();
    })
  });

  _exports.default = _default;
});