define("balance-ember/components/light-table/cell-components/table-actions/save/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vdwLF1sG",
    "block": "{\"symbols\":[\"@row\"],\"statements\":[[8,\"icon-button\",[],[[\"@icon\",\"@style\",\"@testId\",\"@buttonText\",\"@onclick\"],[\"cancel-small-x\",\"btn-secondary-link\",[31,[\"cancel-button-\",[32,1,[\"id\"]]]],[30,[36,0],[\"global.buttons.cancel\"],null],[32,0,[\"cancel\"]]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"ml-6\"],[12],[2,\"\\n  \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-primary\",\"xsm\",[31,[\"save-button-\",[32,1,[\"id\"]]]],[30,[36,1],[[32,1,[\"saveTask\"]],[32,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,1,[\"saveTask\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"loading-spinner\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"global.buttons.save\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"perform\",\"if\"]}",
    "moduleName": "balance-ember/components/light-table/cell-components/table-actions/save/template.hbs"
  });

  _exports.default = _default;
});