define("balance-ember/components/filter-form/suggestion-input/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/key-codes"], function (_exports, _component, _keyCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    preferences: Ember.inject.service(),

    /**
     * @param
     */
    onchange() {},

    /**
     * @param
     */
    updateValue() {},

    /**
     * @param
     */
    updateOperator() {},

    radioOperators: Object.freeze([{
      value: 'match',
      label: 'Contains',
      testSelector: 'contains-match'
    }, {
      value: 'not_match',
      label: 'Doesn\'t Contain',
      testSelector: 'not-contains-match'
    }]),

    /**
     * defaultHighlighted - This is a function that does nothing. We want it do nothing because we
     * don't want the first option highlighted by default. ember-power-select automatically chooses
     * the first highlighted option on 'ENTER', which we don't want. If the user presses ENTER
     * without highlighting a choice we want to do a plain text search or 'fuzzy search'
     */
    defaultHighlighted() {},

    didInsertElement() {
      this._super(...arguments);

      this.moveFocusToInput();
    },

    moveFocusToInput() {
      let input = document.querySelector(`#${this.id} .ember-power-select-typeahead-input`);

      if (input) {
        input.focus();
      }
    },

    preventEnterFromSubmittingForm(evt) {
      if (evt.keyCode === _keyCodes.ENTER && evt.target.value) {
        evt.preventDefault();
      }
    },

    actions: {
      /**
       * onkeydown - What to do when a user presses a key down
       *
       * @param  {object} select            The ember-power-select object
       * @param  {number} { event.keyCode } The keycode the user pressed off of the event object
       */
      onkeydown(select, evt) {
        this.preventEnterFromSubmittingForm(evt);
        let isHighlightingOption = select.highlighted; // If the user presses enter while typing we want to use the text as the search value and not
        // one of the dropdown options

        if (!isHighlightingOption && (evt.key === 'ENTER' || evt.keyCode === _keyCodes.ENTER)) {
          this.onenter(select.searchText);
        }

        return evt;
      },

      showHint() {
        this.preferences.update('autocompleteSubstringFilterHint', true);
        this.preferences.persist();
      }

    }
  });

  _exports.default = _default;
});