define("balance-ember/utils/manage/filter-id-extractor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class FilterIdExtractor {
    constructor(filters = []) {
      this.filters = filters;
    }

    extract() {
      return this.filters.reduce((acc, filter) => {
        let ids = this.findIdsInFilter(filter);
        return acc.concat(ids);
      }, []);
    }

    findIdsInFilter(filter) {
      return filter.operators.reduce((acc, operator) => {
        let ids = new FilterOperatorIdExtractor(operator).extract();
        return acc.concat(ids);
      }, []);
    }

  }

  _exports.default = FilterIdExtractor;

  class FilterOperatorIdExtractor {
    constructor(operator) {
      _defineProperty(this, "operatorIdExtractors", {
        eq_group: this.findEqGroupIds,
        not_eq: this.findNotEqIds
      });

      this.operator = operator;
    }

    extract() {
      let extractorFunction = this.operatorIdExtractors[this.operator.value];
      return extractorFunction ? extractorFunction.call(this) : [];
    }

    findEqGroupIds() {
      let eqOperator = this.operator.filterValues[0].eq;
      return eqOperator ?? [];
    }

    findNotEqIds() {
      return this.operator.filterValues;
    }

  }
});