define("balance-ember/controllers/budgeting/budget/supplemental-pay-expenses", ["exports", "balance-ember/mixins/table-controller"], function (_exports, _tableController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PAY_EXPENSE_PAGE_SIZE = void 0;
  const PAY_EXPENSE_PAGE_SIZE = 50;
  _exports.PAY_EXPENSE_PAGE_SIZE = PAY_EXPENSE_PAGE_SIZE;

  var _default = Ember.Controller.extend(_tableController.default, {
    init() {
      this._super(...arguments);

      this.tableState.pagination.itemsPerPage.set(PAY_EXPENSE_PAGE_SIZE);
      this.tableState.defaultSort.set({
        column: 'supplemental_pay_type',
        dir: 'asc'
      });
    },

    categories: Ember.computed(function () {
      return [{
        title: 'Pay Type',
        value: 'supplemental_pay_type_name',
        type: 'text'
      }, {
        title: 'Employee',
        value: 'employee_name',
        type: 'text'
      }, {
        title: 'Allocation',
        value: 'allocation_name',
        type: 'text'
      }, {
        title: 'Purpose',
        value: 'notes',
        type: 'text'
      }, {
        title: 'Amount',
        value: 'amount',
        type: 'currency'
      }, {
        title: 'Quantity',
        value: 'quantity',
        type: 'decimal'
      }];
    })
  });

  _exports.default = _default;
});