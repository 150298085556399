define("balance-ember/components/manage/manage-permissions-autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SS2RpOMC",
    "block": "{\"symbols\":[\"suggestion\",\"@noMatchesMessage\",\"@id\",\"@filter\"],\"statements\":[[10,\"div\"],[14,0,\"mt-8\"],[12],[2,\"\\n  \"],[8,\"ui-form/ui-label\",[],[[\"@style\",\"@for\"],[\"hidden\",[32,3]]],[[\"default\"],[{\"statements\":[[2,\"Enter Text\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,0,[\"selected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"selected-box\",[],[[\"@style\",\"@onDeselectClick\",\"@selected\"],[\"blue\",[30,[36,1],[[32,0],[30,[36,2],[[32,0,[\"selected\"]]],null],null],null],[32,0,[\"selected\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"leading-none\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"font-bold\"],[12],[1,[32,0,[\"selectedText\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n      \"],[8,\"power-select-typeahead\",[],[[\"@renderInPlace\",\"@noMatchesMessage\",\"@search\",\"@triggerId\",\"@dropdownClass\",\"@onChange\"],[true,[32,2],[30,[36,0],[[32,0,[\"searchSegmentsTask\"]]],null],[32,3],\"suggestion-input\",[30,[36,1],[[32,0],\"onChange\",[32,4]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"code\"]]],[2,\" \"],[1,[32,1,[\"name\"]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"perform\",\"action\",\"mut\",\"if\"]}",
    "moduleName": "balance-ember/components/manage/manage-permissions-autocomplete/template.hbs"
  });

  _exports.default = _default;
});