define("balance-ember/routes/users/unlock", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UsersUnlockRoute = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    queryParams: {
      unlock_token: {
        refreshModel: false
      }
    },

    activate() {
      return document.title = 'Unlock - Allovue Balance';
    }

  });
  var _default = UsersUnlockRoute;
  _exports.default = _default;
});