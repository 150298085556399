define("balance-ember/models/budget-doc", ["exports", "balance-ember/models/doc", "@ember-data/model"], function (_exports, _doc, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _doc.default.extend({
    budget: (0, _model.belongsTo)('budget', {
      async: false
    })
  });

  _exports.default = _default;
});