define("balance-ember/components/allocation-form/create/confirm-changes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZcuaoPIX",
    "block": "{\"symbols\":[\"@modal\"],\"statements\":[[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"p\"],[14,0,\"p-16 leading-tight\"],[12],[2,\"\\n    Creating this allocation with an object code defined may prevent you from planning positions here. Are you sure\\n    you'd like to save?\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,[32,1,[\"footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"mr-12\"],[12],[2,\"\\n    \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@disabled\",\"@onclick\"],[\"btn-primary-light\",\"sm\",\"create-with-object-code\",[32,0,[\"confirmAllocation\",\"isRunning\"]],[30,[36,0],[[32,0,[\"confirmAllocation\"]],null],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,1],[[32,0,[\"confirmAllocation\",\"isRunning\"]],\"Creating\",\"Create Allocation with Object Code Defined\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@disabled\",\"@onclick\"],[\"btn-subtle-link\",\"sm\",[32,0,[\"confirmAllocation\",\"isRunning\"]],[32,0,[\"cancelConfirmation\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Cancel\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"if\"]}",
    "moduleName": "balance-ember/components/allocation-form/create/confirm-changes/template.hbs"
  });

  _exports.default = _default;
});