define("balance-ember/serializers/user", ["exports", "balance-ember/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize() {
      let json = this._super(...arguments);

      delete json.data.attributes.name;
      delete json.data.attributes.uuid;
      delete json.data.attributes.first_name;
      delete json.data.attributes.last_name;
      delete json.data.attributes.has_manage_access;
      delete json.data.attributes.has_budgeting_access;
      return json;
    }

  });

  _exports.default = _default;
});