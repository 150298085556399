define("balance-ember/templates/budgeting/fiscal-year/purchase-orders/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JwNd7ehB",
    "block": "{\"symbols\":[],\"statements\":[[8,\"container\",[],[[\"@style\"],[\"tabbed-budget\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"container\",[],[[\"@style\"],[\"horizontal centered page\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"budget/page-header\",[],[[\"@title\",\"@subtitle\",\"@breadcrumbs\"],[[32,0,[\"model\",\"number\"]],[31,[[32,0,[\"model\",\"vendor\",\"name\"]],\" - \",[32,0,[\"model\",\"allocation\",\"budget\",\"name\"]]]],[30,[36,2],[[30,[36,1],null,[[\"displayName\",\"routeName\"],[[30,[36,0],[\"budget.fiscal_year.tabs.purchase_orders\"],null],\"budgeting.fiscal-year.purchase-orders\"]]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"mb-18 inline-block text-material-light-blue500\"]],[[\"@route\"],[\"budgeting.fiscal-year.purchase-orders\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"budget.po.view.back_link\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"container\",[],[[\"@style\"],[\"horizontal centered page padding-all\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"budget/purchase-orders/existing-view\",[],[[\"@purchaseOrder\"],[[32,0,[\"model\"]]]],null],[2,\"\\n  \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"hash\",\"array\",\"-outlet\",\"component\"]}",
    "moduleName": "balance-ember/templates/budgeting/fiscal-year/purchase-orders/view.hbs"
  });

  _exports.default = _default;
});