define("balance-ember/components/ui-form/label-text/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    style: 'tabular sm',
    styleClasses: Ember.computed('style', function () {
      let style = '';

      if (!this.style.includes('no-focus')) {
        style = `${style} focus-within-group:font-semibold`;
      }

      if (this.style.includes('sm')) {
        style = `${style} text-sm`;
      }

      if (this.style.includes('lg')) {
        style = `${style} text-lg`;
      }

      if (this.style.includes('tabular')) {
        style = `${style} pr-8 pt-6 inline-block w-128 min-w-128 text-right self-baseline`;
      }

      if (this.style.includes('radio') && !this.style.includes('left')) {
        style = `${style} ml-6 leading-none`;
      }

      if (this.style.includes('radio left')) {
        style = `${style} mr-6 leading-none`;
      }

      if (this.style.includes('full')) {
        style = `${style} w-full inline-block`;
      }

      return style;
    })
  });

  _exports.default = _default;
});