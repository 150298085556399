define("balance-ember/mixins/budgeting-reports", ["exports", "@microstates/ember", "ember-concurrency", "balance-ember/types/table", "balance-ember/routes/budgeting/fiscal-year/reports/view"], function (_exports, _ember, _emberConcurrency, _table, _view) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNames: ['report-results'],
    store: Ember.inject.service(),
    tableState: (0, _ember.state)((0, _ember.create)(_table.default, {
      pagination: {
        itemsPerPage: _view.REPORTS_PAGE_SIZE
      }
    })),
    defaultSortDir: 'asc',

    init() {
      this._super(...arguments);

      this.tableState.defaultSort.set({
        column: this.defaultSortColumn,
        dir: this.defaultSortDir
      });
    },

    loadReport: (0, _emberConcurrency.task)(function* () {
      return yield this.store.queryRecord('report', Object.assign({}, this.tableState.apiQuery, {
        reportName: this.reportName
      })).then(data => {
        let reportData = data.reportData;
        reportData.meta = data.meta;
        return reportData;
      });
    })
  });

  _exports.default = _default;
});