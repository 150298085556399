define("balance-ember/components/manage/spending-by-segment/dropdown/info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    amount: null,
    amountColor: null,
    amountLabel: null
  });

  _exports.default = _default;
});