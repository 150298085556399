define("balance-ember/components/fiscal-year-form/chart-of-accounts/reuse-existing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cmJQuwdc",
    "block": "{\"symbols\":[\"Label\",\"fy\",\"@form\"],\"statements\":[[8,[32,3,[\"label\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"text\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"Select Fiscal Year\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"w-full max-w-320\"],[12],[2,\"\\n    \"],[8,\"powered-select\",[],[[\"@options\",\"@searchField\",\"@selected\",\"@testId\",\"@onchange\"],[[32,0,[\"existingFiscalYears\"]],\"name\",[32,0,[\"selectedFiscalYear\"]],\"fy-chart-of-accounts-copy-from\",[30,[36,1],[[32,0],\"updateSegmentsSource\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"span\"],[14,0,\"font-bold\"],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,2,[\"startDate\"]],[32,2,[\"endDate\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"italic font-light\"],[12],[1,[30,[36,0],[[32,2,[\"startDate\"]],\"MM/D/YYYY\"],null]],[2,\" - \"],[1,[30,[36,0],[[32,2,[\"endDate\"]],\"MM/D/YYYY\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"action\",\"and\",\"if\"]}",
    "moduleName": "balance-ember/components/fiscal-year-form/chart-of-accounts/reuse-existing/template.hbs"
  });

  _exports.default = _default;
});