define("balance-ember/types/filter-operator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function getFilterValue(operator, index) {
    let firstValue = [...operator.filterValues][index];
    return firstValue ? firstValue.state : '';
  }

  class _default {
    constructor() {
      _defineProperty(this, "value", String);

      _defineProperty(this, "filterValues", [String]);
    }

    get isValid() {
      return this.filterValues.length > 0 && this.value.state;
    }

    get firstFilterValue() {
      return getFilterValue(this, 0);
    }

    get secondFilterValue() {
      return getFilterValue(this, 1);
    }

    updateValue(value) {
      return this.value.set(value).filterValues.clear();
    }

    updateFilterValue(index, newValue) {
      // If someone tries to add an item to an index where the indicies beneath it haven't been
      // put in the array, we need to populate the array with empty things so the indicies are
      // correct and things don't break. We do this by:
      // 1. Create a new array with the number items we need to match the index
      // 2. Fill that new array with filler values
      // 3. If there is an existing item in a given index use it, otherwise use the filler value
      let filterValues = new Array(Math.max(index + 1, [...this.filterValues].length)).fill('').map((emptyVal, index) => {
        let existingValue = [...this.filterValues][index];
        return existingValue ? existingValue.state : emptyVal;
      });
      let newValues = filterValues.replace(index, 1, [newValue]);
      return this.filterValues.set(newValues);
    }

  }

  _exports.default = _default;
});