define("balance-ember/components/budget/expense-table/edit-strategies/strategy-finder/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BudgetExpensesTableEditStrategiesStrategyFinderComponent = (_dec = Ember._action, (_class = class BudgetExpensesTableEditStrategiesStrategyFinderComponent extends _component.default {
    get availableStrategies() {
      if (!this.args.budgetStrategies) {
        return [];
      }

      let usedBudgetStrategyIds = this.usedBudgetStrategyIds;
      return this.args.budgetStrategies.reject(budgetStrategy => usedBudgetStrategyIds.includes(budgetStrategy.id));
    }

    get usedBudgetStrategyIds() {
      return this.args.expense.expenseStrategies.mapBy('budgetStrategy.id');
    }

    updateBudgetStrategy(budgetStrategy) {
      this.args.expenseStrategy.budgetStrategy = budgetStrategy;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "updateBudgetStrategy", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateBudgetStrategy"), _class.prototype)), _class));
  _exports.default = BudgetExpensesTableEditStrategiesStrategyFinderComponent;
});