define("balance-ember/utils/morph-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.camelizeKeys = camelizeKeys;
  _exports.dasherizeKeys = dasherizeKeys;
  _exports.underscoreKeys = underscoreKeys;
  _exports.traverseKeys = traverseKeys;

  /**
   * Recursivley go through an obj and camelize all the keys
   */
  function camelizeKeys(obj, options) {
    return traverseKeys(obj, Ember.String.camelize, options);
  }

  function dasherizeKeys(obj, options) {
    return traverseKeys(obj, Ember.String.dasherize, options);
  }

  function underscoreKeys(obj, options) {
    return traverseKeys(obj, Ember.String.underscore, options);
  }

  function traverseKeys(obj, func, {
    deep = true
  } = {}) {
    if (!obj) {
      return {};
    }

    let type = Ember.typeOf(obj);

    if (type === 'array') {
      return obj.map(item => traverseKeys(item, func));
    }

    if (type === 'object') {
      let newObj = {};
      Object.keys(obj).forEach(key => {
        let value = obj[key];

        if (deep && (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'array')) {
          value = traverseKeys(value, func);
        }

        newObj[func(key)] = value;
      });
      return newObj;
    }

    return obj;
  }
});