define("balance-ember/utils/percentages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toPercentageList = toPercentageList;

  /**
   * toPercentageList - Given a list of numbers, convert them into percentages assuming the sum
   * of those numbers = 100%
   *
   * @param  {array} numberList List of numbers
   * @return {array}            Numbers converted to percentages
   */
  function toPercentageList(numberList = []) {
    let sum = numberList.reduce((acc, curr) => curr + acc, 0);
    return numberList.map(val => Math.round(val / sum * 100) || 0);
  }
});