define("balance-ember/helpers/titleize-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.titleizeError = titleizeError;
  _exports.default = void 0;

  function titleizeError([string]) {
    // reach into the form and see if there's a label associated with this data attribute
    // so we can show the user the same text that appears in the form itself
    let node = document.querySelector(`[name*='${string}']`);
    let DOMName = node ? node.closest('div').querySelector('label').textContent.trim() : null; // and if there isn't (for power-select or segment selection, right now), take the
    // data attribute name and use it. It'll be close.

    let displayName = Ember.isBlank(DOMName) ? string : DOMName;
    return Ember.String.decamelize(displayName).humanize().titleize();
  }

  var _default = Ember.Helper.helper(titleizeError);

  _exports.default = _default;
});