define("balance-ember/controllers/budgeting/budget/supplemental-pay-expenses/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    allocation: null
  });

  _exports.default = _default;
});