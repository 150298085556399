define("balance-ember/models/segment-subtotal", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    segmentCode: (0, _model.attr)('string'),
    segmentName: (0, _model.attr)('string'),
    plannedAmount: (0, _model.attr)('number'),
    nonPersonnelPlannedAmount: (0, _model.attr)('number'),
    personnelPlannedAmount: (0, _model.attr)('number'),
    segmentTypeId: (0, _model.attr)('number'),
    totalFte: (0, _model.attr)('number')
  });

  _exports.default = _default;
});