define("balance-ember/components/budget-user-permissions-form/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/validators/user", "balance-ember/utils/writeable-model"], function (_exports, _component, _emberConcurrency, _user, _writeableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function compareBudgetNames(name1, name2) {
    if (name1.toLowerCase() > name2.toLowerCase()) {
      return 1;
    }

    if (name1.toLowerCase() === name2.toLowerCase()) {
      return 0;
    }

    return -1;
  }

  let BudgetUserPermissionsFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed('budgetPrivilegeList', 'budgets.[]'), _dec10 = (0, _emberConcurrency.task)(function* () {
    let [budgets, budgetUser] = yield Ember.RSVP.all([this.store.loadRecords('budget', {
      fields: {
        budgets: 'name'
      },
      filter: {
        fiscal_year_id: {
          eq: this.availableFiscalYears.current.id
        }
      },
      paginate: false
    }), this.store.loadRecords('user', {
      include: 'budget_privileges.budget',
      filter: {
        id: {
          eq: this.args.budgetUserId
        },
        'budgets.fiscal_year_id': {
          eq: this.availableFiscalYears.current.id
        }
      },
      fields: {
        budgets: 'name'
      }
    }).then(users => users.firstObject)]);
    this.privileges = budgetUser.budgetPrivileges.toArray();
    this.budgetUser = new _writeableModel.default({
      model: budgetUser,
      store: this.store,
      network: this.network
    });
    this.budgets = budgets;
  }).drop(), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class BudgetUserPermissionsFormComponent extends _component.default {
    /**
     * sortedBudgets - We should be sorting by role first than budget name. roles should be in
     * order of 'builder, approver, viewer, no access'
     */
    get sortedBudgets() {
      // We want to sort by role first and then if roles are equal sort by name
      return this.budgetPrivilegeList.sort((privilege1, privilege2) => {
        let role1 = privilege1.role;
        let role2 = privilege2.role;
        let areRolesEqual = role1 === role2;

        if (areRolesEqual) {
          return compareBudgetNames(privilege1.budget.name, privilege2.budget.name);
        } // Loop through each role in order. If the first budget has the role it should go first if the
        // second budget has the role it should go second


        let roles = ['builder', 'approver', 'viewer', 'purchasing_approver'];

        for (let index in roles) {
          let role = roles[index];

          if (role1 === role) {
            return -1;
          }

          if (role2 === role) {
            return 1;
          }
        } // If none of the roles were found then we should say they are equal


        return 0;
      });
    }
    /**
     * Make a budgetPrivilege list that includes privileges the user already has and all the ones
     * they don't.
     */


    get budgetPrivilegeList() {
      return this.budgets.map(budget => {
        let budgetUser = this.budgetUser; // We want to get the role the user has for this particular budget

        let privilege = budgetUser.budgetPrivileges.findBy('budget.id', budget.id);

        if (privilege) {
          return privilege;
        } // Keep the same 'interface' as a budgetPrivilege


        return {
          id: budget.id,
          // We need an id for the radio-button-cell to use as an id
          budget,
          budgetUser: this.originalBudgetUser,
          role: null
        };
      });
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "network", _descriptor3, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "budgetUser", _descriptor6, this);

      _initializerDefineProperty(this, "budgets", _descriptor7, this);

      _initializerDefineProperty(this, "privileges", _descriptor8, this);

      _initializerDefineProperty(this, "loadDataTask", _descriptor9, this);

      _defineProperty(this, "columns", [{
        label: 'Budget',
        valuePath: 'budget.name',
        cellClassNames: 'text-sm',
        testSelector: 'budget',
        width: '200px',
        sortable: false,
        hideable: false
      }, {
        label: 'Builder',
        cellComponent: 'radio-button-cell',
        cellClassNames: 'text-sm',
        option: 'builder',
        valuePath: 'role',
        namePath: 'budget.name',
        actionName: 'editBudgetRole',
        align: 'center',
        width: '75px',
        testSelectorName: 'builder',
        testSelectorValuePath: 'budget.name',
        sortable: false,
        hideable: false,

        controlLabel(privilege) {
          return `Make user a builder for ${privilege.budget.name}`;
        }

      }, {
        label: 'Budget Approver',
        cellComponent: 'radio-button-cell',
        cellClassNames: 'text-sm',
        option: 'approver',
        valuePath: 'role',
        namePath: 'budget.name',
        actionName: 'editBudgetRole',
        align: 'center',
        width: '155px',
        testSelectorName: 'approver',
        testSelectorValuePath: 'budget.name',
        sortable: false,
        hideable: false,

        controlLabel(privilege) {
          return `Make user an approver for ${privilege.budget.name}`;
        }

      }, {
        label: 'Viewer',
        cellComponent: 'radio-button-cell',
        cellClassNames: 'text-sm',
        option: 'viewer',
        valuePath: 'role',
        namePath: 'budget.name',
        actionName: 'editBudgetRole',
        align: 'center',
        width: '75px',
        testSelectorName: 'viewer',
        testSelectorValuePath: 'budget.name',
        sortable: false,
        hideable: false,

        controlLabel(privilege) {
          return `Make user a viewer for ${privilege.budget.name}`;
        }

      }, {
        label: 'PO Approver',
        cellComponent: 'radio-button-cell',
        cellClassNames: 'text-sm',
        option: 'purchasing_approver',
        valuePath: 'role',
        namePath: 'budget.name',
        actionName: 'editBudgetRole',
        align: 'center',
        width: '115px',
        testSelectorName: 'purchasingApprover',
        testSelectorValuePath: 'budget.name',
        sortable: false,
        hideable: false,

        controlLabel(privilege) {
          return `Make user a purchasing approver for ${privilege.budget.name}`;
        }

      }, {
        label: 'No Access',
        cellComponent: 'radio-button-cell',
        cellClassNames: 'text-sm',
        option: null,
        valuePath: 'role',
        namePath: 'budget.name',
        actionName: 'editBudgetRole',
        align: 'center',
        testSelectorName: 'no-access',
        testSelectorValuePath: 'budget.name',
        width: '100px',
        sortable: false,
        hideable: false,

        controlLabel(privilege) {
          return `Remove user's access to ${privilege.budget.name}`;
        }

      }]);

      this.loadDataTask.perform();
    }

    cancel() {
      // Be defensive against canceling before the budgetUser has been loaded
      if (this.budgetUser) {
        this.cleanupUnpersistedPermissions();
      }

      this.router.replaceWith('district-admin.budget-user-permissions');
    }

    editBudgetRole(privilege, role) {
      if (role === null) {
        this.removePrivilege(privilege);
      } else if (privilege.role) {
        this.updatePrivilege(privilege, role);
      } else {
        this.createPrivilege(privilege.budget, role);
      }
    }

    removePrivilege(privilege) {
      this.budgetUser.deleteBudgetPrivilege(privilege);
    }

    updatePrivilege(privilege, role) {
      privilege.role = role;
    }

    createPrivilege(budget, role) {
      let newPrivilege = this.store.createRecord('budgetPrivilege', {
        user: this.budgetUser.model,
        role,
        budget
      });
      this.budgetUser.addBudgetPrivilege(newPrivilege);
    }

    save() {
      var _this$validator$valid;

      this.errors = (_this$validator$valid = this.validator.validate()) === null || _this$validator$valid === void 0 ? void 0 : _this$validator$valid.messages;

      if (this.errors) {
        return;
      }

      return this.budgetUser.save().then(budgetUser => {
        this.cleanupUnpersistedPermissions(budgetUser);
        this.router.replaceWith('district-admin.budget-user-permissions');
      });
    }

    get validator() {
      return new _user.default({
        model: this.budgetUser,
        intl: this.intl
      });
    }

    cleanupUnpersistedPermissions() {
      let records = this.budgetUser.budgetPrivileges;
      let unpersistedRecords = records ? records.rejectBy('id') : [];
      unpersistedRecords.forEach(record => {
        record.model.unloadRecord();
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "network", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "budgetUser", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "budgets", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "privileges", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sortedBudgets", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "sortedBudgets"), _class.prototype), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "loadDataTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editBudgetRole", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "editBudgetRole"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = BudgetUserPermissionsFormComponent;
});