define("balance-ember/components/light-table/cell-components/table-actions/recent-changes/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LightTableCellComponentsTableActionsRecentChangesComponent extends _component.default {
    get buttonText() {
      return this.args.column.buttonText(this.args.row.content);
    }

    get recordDescription() {
      return this.args.column.recentChanges.recordDescription(this.args.row.content);
    }

  }

  _exports.default = LightTableCellComponentsTableActionsRecentChangesComponent;
});