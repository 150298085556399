define("balance-ember/controllers/manage/accounts/index", ["exports", "balance-ember/mixins/segment-controller", "balance-ember/mixins/summarize-controller", "balance-ember/utils/manage/account-filter", "@microstates/ember"], function (_exports, _segmentController, _summarizeController, _accountFilter, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let mixins = [_summarizeController.default, _segmentController.default];

  var _default = Ember.Controller.extend(...mixins, {
    availableBudgetLeas: Ember.inject.service(),
    features: Ember.inject.service(),
    isOriginalBudgetEnabled: Ember.computed.readOnly('features.originalBudget'),
    selectedBudgetLea: Ember.computed.readOnly('availableBudgetLeas.current'),
    shouldShowChart: (0, _ember.state)((0, _ember.create)(Boolean, true)),
    categories: Ember.computed('segmentTypeSubcategories', 'isOriginalBudgetEnabled', function () {
      return new _accountFilter.default({
        segmentTypeSubcategories: this.segmentTypeSubcategories,
        isOriginalBudgetEnabled: this.isOriginalBudgetEnabled
      }).categories;
    }),
    sortTypes: Ember.computed('segmentTypes', function () {
      let sortsSet = new Set(this.segmentTypes.map(segment => `segment_${segment.name}`));
      return {
        code: sortsSet,
        name: sortsSet
      };
    })
  });

  _exports.default = _default;
});