define("balance-ember/components/percentage-benefit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HBbLKorB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"dd\"],[14,0,\"allocated percentage-benefit benefit-amount tabular-amount\"],[12],[2,\"\\n  \"],[1,[32,0,[\"benefit\",\"percentage\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/percentage-benefit/template.hbs"
  });

  _exports.default = _default;
});