define("balance-ember/components/benefits-package-table/benefit-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AyW0b0zx",
    "block": "{\"symbols\":[\"benefit\",\"@value\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"truncate\"],[12],[2,\"\\n    \"],[1,[32,1,[\"segment\",\"code\"]]],[2,\" \"],[1,[32,1,[\"segment\",\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/benefits-package-table/benefit-cell/template.hbs"
  });

  _exports.default = _default;
});