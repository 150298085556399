define("balance-ember/adapters/budgeting", ["exports", "balance-ember/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * We don't want ember-data to make n-requests when we attempt to load a relationship lazily.
     * We want to send all the ids and get back all the models in one request
     * @see https://emberjs.com/api/ember-data/2.16/classes/DS.JSONAPIAdapter/properties/coalesceFindRequests?anchor=coalesceFindRequests
     */
    coalesceFindRequests: true,
    namespace: 'budgeting/api/v1'
  });

  _exports.default = _default;
});