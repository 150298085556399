define("balance-ember/classes/table-serializer", ["exports", "balance-ember/utils/query-params", "@microstates/ember"], function (_exports, _queryParams, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TableQueryParams = _exports.FilterCombiner = _exports.FilterSerializer = _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TableSerializer {
    constructor(table) {
      this.table = table;
    }

    get apiQuery() {
      let query = {
        page: {
          number: this.table.pagination.page.state,
          size: this.table.pagination.itemsPerPage.state
        },
        filter: this.serializedFilters
      };

      if (this.table.firstSort) {
        let column = [this.table.firstSort.column.state, this.table.firstSort.type.state || null].compact().join('_');
        let dir = this.table.firstSort.dir.state === 'desc' ? '-' : '';
        query.sort = `${dir}${column}`;
      }

      return query;
    }

    get serializedFilters() {
      return new FilterSerializer(this.table.filters).serialize();
    }

  }

  _exports.default = TableSerializer;

  class FilterSerializer {
    constructor(filters) {
      this.filters = filters;
    }

    serialize() {
      return [...this.filters].reduce((filters, filter) => {
        filters[filter.category.state] = this.serializeFilter(filter);
        return filters;
      }, {});
    }

    serializeFilter(filter) {
      return [...filter.operators].reduce((operators, operator) => {
        operators[operator.value.state] = this.serializeOperator(operator, filter);
        return operators;
      }, {});
    }

    serializeOperator(operator, filter) {
      let values = [...operator.filterValues].map(filterValue => filter.serializer.serializeFilterValue((0, _ember.valueOf)(filterValue)));

      if (values.length === 1) {
        values = values[0];
      }

      return values;
    }

  }

  _exports.FilterSerializer = FilterSerializer;

  class FilterCombiner {
    constructor(oldFilter = {}, newFilter = {}) {
      this.oldFilter = { ...oldFilter
      };
      this.newFilter = newFilter;
    }

    combine() {
      return Object.keys(this.newFilter).reduce((acc, key) => {
        let oldCategory = this.oldFilter[key];
        let newCategory = this.newFilter[key];
        acc[key] = this.combineCategories(oldCategory, newCategory);
        return acc;
      }, this.oldFilter);
    }

    combineCategories(oldCategory, newCategory) {
      if (!oldCategory) {
        return newCategory;
      }

      return Object.keys(newCategory).reduce((acc, key) => {
        let oldOperator = oldCategory[key];
        let newOperator = newCategory[key];
        acc[key] = new OperatorCombiner(oldOperator, newOperator).combine();
        return acc;
      }, oldCategory);
    }

  }

  _exports.FilterCombiner = FilterCombiner;

  class OperatorCombiner {
    constructor(oldOperator, newOperator) {
      _defineProperty(this, "OPERATOR_METHODS", {
        array: this.combineArrayOperator,
        object: this.combineObjectOperator
      });

      this.oldOperator = oldOperator;
      this.newOperator = newOperator;
    }

    combine(oldOperator = this.oldOperator, newOperator = this.newOperator) {
      if (!oldOperator) {
        return newOperator;
      }

      let oldOperatorType = Ember.typeOf(oldOperator);
      let operatorMethod = this.OPERATOR_METHODS[oldOperatorType] || this.combineSimpleOperator;
      return operatorMethod.call(this, oldOperator, newOperator);
    }

    combineArrayOperator(oldOperator, newOperator) {
      return oldOperator.concat(newOperator);
    }

    combineObjectOperator(oldOperator, newOperator) {
      return Object.keys(newOperator).reduce((acc, key) => {
        if (Ember.isNone(oldOperator[key])) {
          return { ...acc,
            [key]: newOperator[key]
          };
        }

        acc[key] = this.combine(oldOperator[key], newOperator[key]);
        return acc;
      }, oldOperator);
    }

    combineSimpleOperator(oldOperator, newOperator) {
      return [oldOperator].concat(newOperator);
    }

  }

  class TableQueryParams {
    constructor(tableState) {
      this.tableState = tableState;
    }

    stringify() {
      return _queryParams.default.stringify(this.serializedTableState);
    }

    get serializedTableState() {
      return new TableSerializer(this.tableState).apiQuery;
    }

  }

  _exports.TableQueryParams = TableQueryParams;
});