define("balance-ember/components/pagination-controls/pagination-links/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    shouldShow: true,
    displayState: Ember.computed('isLoading', function () {
      if (this.isLoading) {
        return 'opacity-50 flex';
      }

      return 'flex';
    }),
    totalPages: Ember.computed('lastPage', function () {
      // Default to 1 for skeleton view
      return this.lastPage ? this.lastPage : 1;
    }),
    width: 5,

    didReceiveAttrs() {
      this._super();

      Ember.set(this, 'currentPageProxy', this.currentPage);
    },

    // Need a proxy for the current page because pagination-links addon doesn't follow DDAU and
    // mutates whatever `currentPage` property is passed to it. Would like to isolate this behavior
    // here so it doesn't mutate anything farther up the hierarchy
    currentPageProxy: 1,
    currentLinkBackgroundColor: 'material-gray500'
  });

  _exports.default = _default;
});