define("balance-ember/models/account", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.computedProperties = _exports.schema = void 0;
  const schema = {
    code: (0, _model.attr)(),
    segments: (0, _model.hasMany)('manage/segment', {
      async: false
    }),
    accountBalance: (0, _model.belongsTo)('accountBalance', {
      async: false
    }),
    budgetLea: (0, _model.belongsTo)('budgetLea', {
      async: false
    })
  };
  _exports.schema = schema;
  const computedProperties = {
    segmentsHash: Ember.computed('segments.[]', function () {
      return this.segments.reduce(segmentsHashReducer, {});
    })
  };
  _exports.computedProperties = computedProperties;

  function segmentsHashReducer(accumulatorHash, segment) {
    let {
      segmentType,
      name,
      code
    } = segment;
    if (!segmentType) return accumulatorHash;
    accumulatorHash[segment.segmentType.name] = {
      name,
      code
    };
    return accumulatorHash;
  }

  var _default = _model.default.extend(schema, computedProperties);

  _exports.default = _default;
});