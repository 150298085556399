define("balance-ember/routes/budgeting/budget/expenses", ["exports", "balance-ember/mixins/route-history"], function (_exports, _routeHistory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeHistory.default, {
    model() {
      let budget = this.modelFor('budgeting.budget');
      let budgetId = this.paramsFor('budgeting.budget').budget_id;
      return this.store.loadRecords('allocation', {
        filter: {
          budget_id: {
            eq: budgetId
          }
        },
        paginate: false,
        include: 'segments.segment_type'
      }).then(allocations => {
        Ember.set(budget, 'allocations', allocations);
        return allocations;
      });
    },

    setupController(controller, model) {
      Ember.set(controller, 'allocations', model);

      this._super(controller, this.modelFor('budgeting.budget'));
    }

  });

  _exports.default = _default;
});