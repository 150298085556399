define("balance-ember/components/budget/non-personnel-expenses-table/edit-details/item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BudgetNonPersonnelExpensesTableEditDetailsItemComponent = (_dec = Ember.computed('args.item.{quantity,unitPrice}'), _dec2 = Ember.computed('args.{errors.all,item.description}'), _dec3 = Ember.computed('args.{errors.all,item.unitPrice}'), _dec4 = Ember.computed('args.{errors.all,item.quantity}'), _dec5 = Ember._action, (_class = class BudgetNonPersonnelExpensesTableEditDetailsItemComponent extends _component.default {
    get subtotal() {
      return this.args.item.quantity * this.args.item.unitPrice;
    }

    get errors() {
      return [this.descriptionError, this.unitPriceError, this.quantityError].compact();
    }

    get descriptionError() {
      if (!this.args.item.description) {
        var _this$args$errors, _this$args$errors$all;

        return (_this$args$errors = this.args.errors) === null || _this$args$errors === void 0 ? void 0 : (_this$args$errors$all = _this$args$errors.all.findBy('attribute', 'items.description')) === null || _this$args$errors$all === void 0 ? void 0 : _this$args$errors$all.message;
      }

      return undefined;
    }

    get unitPriceError() {
      if (!this.args.item.unitPrice) {
        var _this$args$errors2, _this$args$errors2$al;

        return (_this$args$errors2 = this.args.errors) === null || _this$args$errors2 === void 0 ? void 0 : (_this$args$errors2$al = _this$args$errors2.all.findBy('attribute', 'items.unitPrice')) === null || _this$args$errors2$al === void 0 ? void 0 : _this$args$errors2$al.message;
      }

      return undefined;
    }

    get quantityError() {
      if (!this.args.item.quantity) {
        var _this$args$errors3, _this$args$errors3$al;

        return (_this$args$errors3 = this.args.errors) === null || _this$args$errors3 === void 0 ? void 0 : (_this$args$errors3$al = _this$args$errors3.all.findBy('attribute', 'items.quantity')) === null || _this$args$errors3$al === void 0 ? void 0 : _this$args$errors3$al.message;
      }

      return undefined;
    }

    removeItem() {
      this.args.expense.deleteItem(this.args.item);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "subtotal", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "subtotal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "descriptionError", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "descriptionError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unitPriceError", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "unitPriceError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "quantityError", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "quantityError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeItem", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "removeItem"), _class.prototype)), _class));
  _exports.default = BudgetNonPersonnelExpensesTableEditDetailsItemComponent;
});