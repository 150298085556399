define("balance-ember/components/budget/slideout-non-personnel-expense-items/item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let SlideoutNonPersonnelExpenseItem = (_dec = Ember.computed('args.item.{unitPrice,quantity}'), (_class = class SlideoutNonPersonnelExpenseItem extends _component.default {
    get liveCalculatedAmount() {
      return parseFloat(this.args.item.unitPrice) * parseInt(this.args.item.quantity);
    }

    autofocus(element, [shouldAutofocus = false]) {
      if (shouldAutofocus) element.focus();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "liveCalculatedAmount", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "liveCalculatedAmount"), _class.prototype)), _class));
  _exports.default = SlideoutNonPersonnelExpenseItem;
});