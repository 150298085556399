define("balance-ember/components/budget/budgets/table/category-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZmabRE9h",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,1]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  Any\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"titleize\",\"if\"]}",
    "moduleName": "balance-ember/components/budget/budgets/table/category-cell/template.hbs"
  });

  _exports.default = _default;
});