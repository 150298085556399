define("balance-ember/components/tag/form/bulk/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/urls"], function (_exports, _component, _emberConcurrency, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _dec3, _dec4, _dec5, _dec6, _class3, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TagFormBulkComponent = (_dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = (0, _emberConcurrency.task)(function* () {
    let body = this.serializedData;

    if (body.data.changes.length) {
      yield this.network.fetch(this.url, {
        method: 'POST',
        body: JSON.stringify(body)
      });
      this.modelChanges.addChange({
        changeType: 'update',
        modelType: Ember.String.camelize(body.data.type)
      });
    }

    if (this.args.afterSave) {
      this.args.afterSave();
    }
  }).drop(), (_class3 = class TagFormBulkComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "network", _descriptor2, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor3, this);

      _initializerDefineProperty(this, "saveTask", _descriptor4, this);

      this.tags = this.createTags();
      this.sortedTags = this.sortTags();
    }

    createTags() {
      return this.args.tags.map(tag => {
        return new TagState({
          tag,
          records: this.args.records
        });
      });
    }

    sortTags() {
      let alphabeticalSortedTags = this.tags.sortBy('name');
      return [...alphabeticalSortedTags.filterBy('allTagged'), ...alphabeticalSortedTags.filterBy('someTagged'), ...alphabeticalSortedTags.filterBy('noneTagged')];
    }

    save() {
      this.saveTask.perform();
    }

    get url() {
      return `${(0, _urls.URLForAPIv3)()}/tags/bulk`;
    }

    get serializedData() {
      return new Serializer({
        records: this.args.records,
        tags: this.tags
      }).serialize();
    }

  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "network", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "modelChanges", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "save", [_dec5], Object.getOwnPropertyDescriptor(_class3.prototype, "save"), _class3.prototype), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "saveTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3));
  _exports.default = TagFormBulkComponent;
  let TagState = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class TagState {
    constructor({
      tag,
      records
    }) {
      _initializerDefineProperty(this, "count", _descriptor, this);

      this.tag = tag;
      this.records = records;
      this.count = this.initialCount;
    }

    get name() {
      return this.tag.name;
    }

    get id() {
      return this.tag.id;
    }

    get checkedState() {
      if (this.allTagged) {
        return true;
      }

      if (this.noneTagged) {
        return false;
      }

      return 'partial';
    }

    get allTagged() {
      return this.numRecords === this.count;
    }

    get noneTagged() {
      return this.count === 0;
    }

    get someTagged() {
      return this.count > 0 && this.count < this.numRecords;
    }

    get numRecords() {
      return this.records.length;
    }

    get recordTags() {
      return this.records.mapBy('tags');
    }

    get initialCount() {
      if (Ember.isPresent(this._initialCount)) {
        return this._initialCount;
      }

      return this._initialCount = this.recordTags.reduce((total, tags) => {
        let found = tags.findBy('id', this.id);
        return found ? total + 1 : total;
      }, 0);
    }

    updateCount(checkedState) {
      if (!checkedState) {
        return this.count = 0;
      }

      if (checkedState === 'partial') {
        return this.count = this.initialCount;
      }

      return this.count = this.numRecords;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "count", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateCount", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateCount"), _class.prototype)), _class));

  class Serializer {
    constructor({
      records,
      tags
    }) {
      this.tags = tags;
      this.records = records;
    }

    serialize() {
      return {
        data: {
          type: this.type,
          filter: this.filter,
          changes: this.changes
        }
      };
    }

    get filter() {
      return {
        id: {
          eq: this.ids
        }
      };
    }

    get ids() {
      return this.records.map(record => String(record.id));
    }

    get type() {
      return this.records.firstObject.constructor.modelName;
    }

    get changes() {
      return this.tags.reduce((acc, tag) => {
        let tagDidntChange = tag.count === tag.initialCount || tag.someTagged;

        if (tagDidntChange) {
          return acc;
        }

        return acc.concat({
          id: String(tag.id),
          method: tag.allTagged ? 'add' : 'remove'
        });
      }, []);
    }

  }
});