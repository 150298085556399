define("balance-ember/components/light-table/cell-components/checkbox-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N8JSRwKa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"relative\"],[12],[2,\"\\n  \"],[8,\"ui-form/ui-label\",[],[[\"@style\",\"@for\"],[\"hidden\",[31,[\"checkbox-cell-\",[32,0,[\"id\"]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,0,[\"label\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"checkbox\",[],[[\"@id\",\"@testId\",\"@checked\",\"@onupdate\"],[[31,[\"checkbox-cell-\",[32,0,[\"id\"]]]],[31,[\"checkbox-cell-\",[32,0,[\"id\"]]]],[32,0,[\"isChecked\"]],[32,0,[\"onUpdate\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/light-table/cell-components/checkbox-cell/template.hbs"
  });

  _exports.default = _default;
});