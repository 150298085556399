define("balance-ember/templates/budgeting/budget-priorities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gZUq6WsW",
    "block": "{\"symbols\":[],\"statements\":[[8,\"container\",[],[[\"@style\"],[\"horizontal centered page padding-x\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"budget/page-header\",[],[[\"@title\"],[[30,[36,0],[\"budget.priorities.index.page_title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"icon-button\",[],[[\"@route\",\"@style\",\"@testId\",\"@icon\",\"@buttonText\",\"@showButtonText\"],[[30,[36,1],[\"budgeting.budget-priorities.new\"],null],\"btn-primary\",\"add-budget-priority\",\"circle-plus\",\"Add Priority\",true]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mt-16\"],[12],[2,\"\\n    \"],[8,\"budget/priorities-table\",[],[[\"@tableState\",\"@categories\"],[[32,0,[\"tableState\"]],[32,0,[\"categories\"]]]],null],[2,\"\\n\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"array\",\"-outlet\",\"component\"]}",
    "moduleName": "balance-ember/templates/budgeting/budget-priorities.hbs"
  });

  _exports.default = _default;
});