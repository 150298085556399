define("balance-ember/components/budget/vendor-delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gt0wI98z",
    "block": "{\"symbols\":[\"@closeModal\",\"@vendor\"],\"statements\":[[8,\"modal/destroy\",[],[[\"@closeModal\",\"@destroy\",\"@model\",\"@text\"],[[32,1],[30,[36,0],[[32,0,[\"deleteVendorTask\"]]],null],[32,2],[30,[36,1],[\"global.modals.are_you_sure\"],[[\"modelName\"],[[32,2,[\"name\"]]]]]]],null]],\"hasEval\":false,\"upvars\":[\"perform\",\"t\"]}",
    "moduleName": "balance-ember/components/budget/vendor-delete/template.hbs"
  });

  _exports.default = _default;
});