define("balance-ember/components/recent-changes-button/summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y7wdirgN",
    "block": "{\"symbols\":[\"summary\",\"InlineItem\",\"@change\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,3,[\"summarizes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"text-sm truncate max-w-320\"],[12],[2,\"\\n      \"],[8,\"layout/inline\",[],[[\"@space\",\"@align\"],[\"xxs\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"span\"],[15,0,[31,[\"text-\",[32,1,[\"color\"]]]]],[12],[2,\"\\n            \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[[32,1,[\"icon\"]],[31,[\"svg-icon align-middle w-12 h-12 fill-current fill-\",[32,1,[\"color\"]]]]]],null],[2,\"\\n          \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mb-4 mt-6\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-md font-bold\"],[12],[2,\"\\n    \"],[1,[32,3,[\"user\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-sm ml-auto text-material-gray600\"],[12],[2,\"\\n    \"],[1,[32,3,[\"dateDisplay\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/recent-changes-button/summary/template.hbs"
  });

  _exports.default = _default;
});