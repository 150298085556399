define("balance-ember/helpers/position-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPositionName = formatPositionName;
  _exports.positionName = positionName;
  _exports.default = void 0;

  function formatPositionName(jobTitle, fte, employeeName) {
    return `${jobTitle} - ${fte} FTE - ${employeeName ?? 'Vacant'}`;
  }

  function positionName(params
  /*, hash*/
  ) {
    let [jobTitle, fte, employeeName] = params;

    if (Ember.isBlank(jobTitle) || Ember.isBlank(fte)) {
      return '';
    }

    return formatPositionName(jobTitle, fte, employeeName);
  }

  var _default = Ember.Helper.helper(positionName);

  _exports.default = _default;
});