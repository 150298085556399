define("balance-ember/components/budget/supplemental-pay-expenses-table/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency", "balance-ember/classes/table-serializer", "balance-ember/utils/budget/change-log/record-description"], function (_exports, _component, _emberConcurrency, _tableSerializer, _recordDescription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TRACKING_PREFIX = void 0;
  const TRACKING_PREFIX = 'SupplementalPayExpense';
  _exports.TRACKING_PREFIX = TRACKING_PREFIX;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    modelChanges: Ember.inject.service(),
    intl: Ember.inject.service(),
    trackingPrefix: TRACKING_PREFIX,
    tableState: null,
    // @param
    categories: null,
    // @param
    budgetId: null,
    // @param
    payChanges: Ember.computed('modelChanges.changes.[]', function () {
      return this.modelChanges.changesForModelType('supplementalPayExpense');
    }),
    budget: Ember.computed('budgetId', function () {
      return this.store.peekRecord('budget', this.budgetId);
    }),
    columns: Ember.computed('budget.name', function () {
      let width = '140px';
      let align = 'left';
      let columns = [{
        label: 'Pay Type',
        valuePath: 'supplementalPayType.name',
        sortName: 'supplemental_pay_type',
        testSelector: 'pay-type-name',
        cellClassNames: 'border-l print:border-l-0 border-material-gray200 print:avoid-page-break print:header-budgeted',
        cellComponent: 'overflowing-table-cell',
        classNames: 'border-l border-material-gray200',
        align,
        hideable: false,
        ascending: false
      }, {
        label: 'Employee',
        valuePath: 'employee.name',
        sortName: 'employee',
        testSelector: 'employee-name',
        cellComponent: 'overflowing-table-cell',
        align,
        width,
        hideable: false,
        ascending: false
      }, {
        label: 'Allocation',
        valuePath: 'allocation.name',
        sortName: 'allocation',
        testSelector: 'allocation-name',
        cellComponent: 'overflowing-table-cell',
        align,
        width,
        hideable: false,
        ascending: false
      }, {
        label: 'Purpose',
        valuePath: 'notes',
        sortName: 'notes',
        testSelector: 'purpose',
        cellClassNames: 'pr-12',
        cellComponent: 'overflowing-table-cell',
        align,
        width,
        hideable: false,
        ascending: false
      }, {
        label: 'Pay',
        valuePath: 'pay',
        sortName: 'pay',
        testSelector: 'pay',
        cellComponent: 'amount-cell',
        cellClassNames: 'print:header-amount',
        align: 'right',
        width: '140px',
        hideable: false,
        ascending: false
      }, {
        label: 'Benefits',
        valuePath: 'benefits',
        sortName: 'benefits',
        testSelector: 'benefits',
        cellComponent: 'amount-cell',
        cellClassNames: 'print:header-amount pr-32',
        classNames: 'pr-32',
        align: 'right',
        width: '140px',
        hideable: false,
        ascending: false
      }, {
        label: 'Qty',
        valuePath: 'quantity',
        sortName: 'quantity',
        testSelector: 'quantity',
        align,
        width: '80px',
        hideable: false,
        ascending: false
      }, {
        label: 'Amount',
        valuePath: 'amount',
        sortName: 'amount',
        testSelector: 'amount',
        cellComponent: 'amount-cell',
        cellClassNames: 'print:header-amount pr-32',
        classNames: 'pr-32',
        align: 'right',
        width: '140px',
        hideable: false,
        ascending: false
      }, {
        cellComponent: 'light-table/cell-components/table-actions',
        tableActionComponents: ['edit', 'delete', 'recent-changes'],
        isEditDisabled: ({
          allocation: {
            isEditable
          }
        }) => !isEditable,
        isDeleteDisabled: ({
          allocation: {
            isEditable
          }
        }) => !isEditable,
        editTooltipText: ({
          allocation: {
            isEditable
          }
        }) => {
          if (isEditable) return this.intl.t('global.buttons.edit');
          return this.intl.t('budget.supplemental_pay_expenses.tooltips.edit_locked');
        },
        deleteTooltipText: ({
          allocation: {
            isEditable
          }
        }) => {
          if (isEditable) return this.intl.t('global.buttons.delete');
          return this.intl.t('budget.supplemental_pay_expenses.tooltips.delete_locked');
        },
        sortable: false,
        hideable: false,
        align: 'right',
        width: '80px',

        buttonText(supplementalPayExpense) {
          if (!supplementalPayExpense.supplementalPayType) {
            return supplementalPayExpense.id;
          }

          let name = `Supplemental Pay ${supplementalPayExpense.supplementalPayType.name}`;

          if (supplementalPayExpense.employee) {
            name += ` for ${supplementalPayExpense.employee.name}`;
          }

          return name;
        },

        recentChanges: {
          recordDescription: ({
            purpose
          }) => {
            return [new _recordDescription.default({
              name: 'Supplemental Pay',
              value: purpose
            }), new _recordDescription.default({
              name: 'Budget',
              value: this.budget.name
            })];
          },
          include: 'segments'
        }
      }];
      return columns.map(column => {
        return Object.assign({}, column, {
          cellClassNames: `text-15 ${column.cellClassNames}`
        });
      });
    }),
    fetchPays: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('supplementalPayExpense', Object.assign({}, this.serializedTableState, {
        extra_fields: {
          allocations: 'editable'
        },
        filter: Object.assign({}, {
          budget_id: this.budgetId
        }, this.serializedTableState.filter),
        stats: {
          total: 'count'
        },
        include: ['employee', 'supplemental_pay_type.segment', 'supplemental_pay_type.benefits_package.percentage_benefits', 'supplemental_pay_type.benefits_package.flat_rate_benefits', 'allocation.segments'].join(',')
      }));
    }).restartable(),
    serializedTableState: Ember.computed('tableState', function () {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }),
    actions: {
      edit(supplementalPayExpense) {
        this.router.transitionTo('budgeting.budget.supplemental-pay-expenses.edit', supplementalPayExpense.id);
      },

      delete(supplementalPayExpense) {
        this.router.transitionTo('budgeting.budget.supplemental-pay-expenses.destroy', supplementalPayExpense.id);
      }

    }
  });

  _exports.default = _default;
});