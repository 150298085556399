define("balance-ember/routes/budgeting/supplemental-pay-types/destroy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    model(params) {
      return this.store.peekRecord('supplementalPayType', params.pay_type_id);
    },

    setupController(controller, model) {
      controller.set('isConfirming', false);
      return this._super(controller, model);
    }

  });

  _exports.default = _default;
});