define("balance-ember/routes/manage-v3/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ManageV3AccountsRoute extends Ember.Route {
    redirect(_, transition) {
      if (!transition.to.name.includes('details')) this.replaceWith('manage.accounts');
    }

  }

  _exports.default = ManageV3AccountsRoute;
});