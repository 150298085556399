define("balance-ember/components/table/bulk-action-bar/component", ["exports", "@glimmer/component", "balance-ember/utils/have-filters-changed", "balance-ember/types/table", "@microstates/ember"], function (_exports, _component, _haveFiltersChanged, _table, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let TableBulkActionBarComponent = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class TableBulkActionBarComponent extends _component.default {
    get numSelected() {
      return this.args.selectedRows.length;
    }

    get haveFiltersChanged() {
      return (0, _haveFiltersChanged.default)({
        newTableState: this.args.tableState,
        oldTableState: this.forkedTableState
      });
    }

    deselect() {
      this.args.selectedRows.removeObjects(this.args.selectedRows);
    }

    checkForFilterChange() {
      if (this.haveFiltersChanged) {
        this.deselect();
        this.forkTableState();
      }
    }

    forkTableState() {
      this.forkedTableState = (0, _ember.create)(_table.default, (0, _ember.valueOf)(this.args.tableState));
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "deselect", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "deselect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkForFilterChange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "checkForFilterChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "forkTableState", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "forkTableState"), _class.prototype)), _class));
  _exports.default = TableBulkActionBarComponent;
});