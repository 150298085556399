define("balance-ember/components/manage/recent-transactions-widget/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency", "balance-ember/classes/table-serializer", "balance-ember/types/table", "@microstates/ember"], function (_exports, _component, _emberConcurrency, _tableSerializer, _table, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const params = {
    paginationLimit: 10,
    pastDays: 7
  };
  const tableState = (0, _ember.create)(_table.default, {
    pagination: {
      itemsPerPage: params.paginationLimit
    }
  });
  const columns = [{
    cellComponent: 'date-cell',
    timeZoneMode: 'exact',
    cellClassNames: 'text-xs',
    format: 'M/D/YYYY',
    label: 'DATE',
    sortable: false,
    testSelector: 'date',
    valuePath: 'transactionDate'
  }, {
    align: 'right',
    cellClassNames: 'text-xs',
    cellComponent: 'amount-cell',
    label: 'AMOUNT',
    sortable: false,
    testSelector: 'amount',
    valuePath: 'amount'
  }];

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    availableBudgetLeas: Ember.inject.service(),
    currentBudgetLea: Ember.computed.readOnly('availableBudgetLeas.current'),
    columns,
    tableState: (0, _ember.state)(tableState),
    amountType: 'actual',
    // or 'encumbered'
    pastDays: params.pastDays,
    leaId: Ember.computed.readOnly('currentBudgetLea.id'),
    meta: null,
    widgetType: Ember.computed('amountType', function () {
      return this.amountType === 'actual' ? 'Spent' : 'Encumbered';
    }),
    widgetColor: Ember.computed('amountType', function () {
      return this.amountType === 'actual' ? 'material-purple300' : 'material-blue300';
    }),
    noTransactions: Ember.computed('loadTransactions.{isIdle,performCount}', 'tableState.rows', function () {
      return this.loadTransactions.performCount >= 1 && this.loadTransactions.isIdle && Ember.isEmpty(this.tableState.rows);
    }),
    apiQuery: Ember.computed('tableState', function () {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }),
    serializedApiQuery: Ember.computed('apiQuery', 'tableState', function () {
      return new _tableSerializer.TableQueryParams(this.tableState).stringify();
    }),
    loadTransactions: (0, _emberConcurrency.task)(function* () {
      let transactions = yield this.store.query('transaction', Object.assign({}, this.apiQuery, {
        include: 'account',
        filter: Object.assign({}, { ...this.serializedTableState.filter,
          ...this.apiQuery.filter
        }, {
          transaction_date: {
            relative: 'past-seven-days'
          }
        }),
        sort: '-transaction_date',
        stats: {
          total: 'count',
          amount: 'sum'
        }
      }));
      Ember.set(this, 'meta', transactions.meta);
      return transactions;
    }),

    initializeFilters() {
      this.tableState.updateFilters([{
        category: 'budget_lea_id',
        operators: [{
          value: 'eq',
          filterValues: [this.leaId]
        }]
      }, {
        category: 'status',
        operators: [{
          value: 'eq',
          filterValues: [this.amountType]
        }]
      }]);
    },

    actions: {
      setPage(page) {
        this.tableState.pagination.page.set(page);
      }

    },

    didReceiveAttrs() {
      this._super();

      this.initializeFilters();
    }

  });

  _exports.default = _default;
});