define("balance-ember/models/account-balance", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.computedProperties = _exports.schema = void 0;
  const schema = {
    encumbered: (0, _model.attr)('number'),
    encumberedPercentage: (0, _model.attr)('number'),
    budgeted: (0, _model.attr)('number'),
    spent: (0, _model.attr)('number'),
    spentPercentage: (0, _model.attr)('number'),
    available: (0, _model.attr)('number'),
    availablePercentage: (0, _model.attr)('number'),
    originalBudget: (0, _model.attr)('number'),
    account: (0, _model.belongsTo)('account', {
      async: false
    })
  };
  _exports.schema = schema;
  const computedProperties = {
    segments: Ember.computed.readOnly('account.segments'),
    segmentsHash: Ember.computed.readOnly('account.segmentsHash'),
    code: Ember.computed.readOnly('account.code')
  };
  _exports.computedProperties = computedProperties;

  var _default = _model.default.extend(schema, computedProperties);

  _exports.default = _default;
});