define("balance-ember/routes/budgeting/budget/supplemental-pay-expenses/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BudgetingBudgetSupplementalPayExpensesNewRoute extends Ember.Route {
    model() {
      return this.modelFor('budgeting.budget').id;
    }

  }

  _exports.default = BudgetingBudgetSupplementalPayExpensesNewRoute;
});