define("balance-ember/components/budget/expense-table/edit-strategies/core-functions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8df1qv3U",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"Amount\",\"Percentage\"],[[30,[36,0],[\"budget/expense-table/edit-strategies/core-functions/amount\"],[[\"amount\"],[[32,0,[\"remainingAmount\"]]]]],[30,[36,0],[\"budget/expense-table/edit-strategies/core-functions/percentage\"],[[\"percentage\"],[[32,0,[\"remainingPercentage\"]]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/budget/expense-table/edit-strategies/core-functions/template.hbs"
  });

  _exports.default = _default;
});