define("balance-ember/components/allocation-form/segments/view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n2TcP3S1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"py-16 pl-16\"],[12],[2,\"\\n  \"],[8,\"slideout-budgeting-segments\",[],[[\"@segments\",\"@tableHeader\"],[[32,0,[\"allocation\",\"sortedSegments\"]],\"Segments\"]],null],[2,\"\\n  \"],[8,\"allocation-form/segments/edit-segments-button\",[],[[\"@expenseCount\",\"@changeSegmentMode\"],[[32,0,[\"allocation\",\"expenseCount\"]],[32,0,[\"changeSegmentMode\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/allocation-form/segments/view/template.hbs"
  });

  _exports.default = _default;
});