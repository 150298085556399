define("balance-ember/controllers/budgeting/budget-priorities", ["exports", "balance-ember/mixins/table-controller"], function (_exports, _tableController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PRIORITIES_PAGE_SIZE = void 0;
  const PRIORITIES_PAGE_SIZE = 10;
  _exports.PRIORITIES_PAGE_SIZE = PRIORITIES_PAGE_SIZE;

  var _default = Ember.Controller.extend(_tableController.default, {
    router: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    modelChanges: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.tableState.pagination.itemsPerPage.set(PRIORITIES_PAGE_SIZE);
      this.tableState.defaultSort.set({
        column: 'name',
        dir: 'asc'
      });
    },

    budgetPriorityChanges: Ember.computed('modelChanges.changes.[]', function () {
      return this.modelChanges.changesForModelType('budgetPriority');
    }),
    categories: Ember.computed(function () {
      return [{
        title: 'Name',
        value: 'name',
        type: 'text'
      }];
    })
  });

  _exports.default = _default;
});