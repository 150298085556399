define("balance-ember/components/allocation-form/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/writeable-model", "balance-ember/utils/validators/allocation", "balance-ember/utils/refresh-route"], function (_exports, _component, _emberConcurrency, _writeableModel, _allocation, _refreshRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Use this component to display a form to create/edit allocations
   *
   * @param {object} allocation The allocation to edit if it exists already
   * @param {object} budget The budget the allocation should belong to if creating a new allocation
   * on a specific budget
   * @param {array} budgets All the budgets the user has access to
   * @param {array} segmentTypes All the segmentTypes the user has access to
   * @param {string} formTitle The text to display as the title of the form
   * @param {string} actionButtonText The text to display on the primary button
   * @param {string} disabledButtonText What to display when the button is disabled
   * @param {string} mode ENUM ['create', 'edit', 'saving'] displays modes
   */
  let AllocationForm = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* (afterSave) {
    let redirectRoute = this.args.redirectRoute;
    this.validate();

    if (this.errors) {
      return;
    }

    this.mode = 'saving';
    yield this.allocation.save({
      include: 'budget',
      extra_fields: {
        allocations: 'editable',
        budgets: ['editable', 'authorized_approval_events', 'user_role']
      }
    }).then(allocation => {
      (0, _refreshRoute.default)('budgeting.budget.expenses', this);
      this.isConfirming = false;
      this.modelChanges.addChange({
        modelType: 'allocation',
        changeType: 'create'
      }); // When adding the first allocation from an individual budget page the first
      // allocation should be selected, and we need to grab the allocation id for
      // the redirectRoute to make that happen.

      if (afterSave) {
        afterSave();
        this.mode = this.args.mode;
      } else if (this.args.cancel) {
        this.args.cancel();
      } else if (redirectRoute === 'budgeting.budget.expenses') {
        return this.router.transitionTo('budgeting.budget.expenses.view', allocation.id);
      } else {
        this.router.transitionTo(redirectRoute);
      }
    }).catch(errors => {
      if (errors.length) {
        let messages = errors.mapBy('detail');
        this.errors = this.errors ? this.errors.concat(messages) : messages;
      }

      this.mode = this.args.mode;
    });
  }).drop(), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = class AllocationForm extends _component.default {
    /**
     * If the user adds an "Object" segment to an allocation we need to show them a warning before
     * they save to let them know that they won't be able to create positions on that allocation
     */

    /**
     * We need to store if the user wants to perform an action after save (e.g. 'add another') and
     * pass the action to the button in the `confirm` modal
     */

    /**
     * Defaults to display as if allocation was already created.
     */
    get renderComponent() {
      return `allocation-form/${this.mode}`;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "network", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor5, this);

      _initializerDefineProperty(this, "isConfirming", _descriptor6, this);

      _initializerDefineProperty(this, "afterSave", _descriptor7, this);

      _initializerDefineProperty(this, "mode", _descriptor8, this);

      _initializerDefineProperty(this, "allocation", _descriptor9, this);

      _initializerDefineProperty(this, "errors", _descriptor10, this);

      _initializerDefineProperty(this, "handleSave", _descriptor11, this);

      this.allocation = this.args.allocation;
      let budget = this.args.budget || this.allocation.budget;

      if (this.args.mode) {
        this.mode = this.args.mode;
      }

      if (Ember.isPresent(budget)) {
        this.allocation.budget = budget;
      }
    }
    /**
     * handleSave - Moving the save functionality out of the actions hash, because it's needed in
     * two different actions
     *
     * @param  {function} afterSave optional function to do after save
     * @return {promise}            Save promise
     */


    validate() {
      var _this$validator$valid;

      return this.errors = (_this$validator$valid = this.validator.validate()) === null || _this$validator$valid === void 0 ? void 0 : _this$validator$valid.messages;
    }

    get validator() {
      return new _allocation.default({
        model: this.allocation,
        intl: this.intl
      });
    }

    cancel() {
      if (this.args.cancel) {
        return this.args.cancel();
      }

      this.router.transitionTo(this.args.redirectRoute);
    }

    cancelConfirmation() {
      this.isConfirming = false;
    }

    resetAllocation() {
      this.afterSave = null;
      this.allocation = new _writeableModel.default({
        model: this.store.createRecord('allocation', {
          budget: this.args.budget
        }),
        store: this.store,
        network: this.network
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "network", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isConfirming", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "afterSave", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "mode", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "allocation", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "handleSave", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "validate", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelConfirmation", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "cancelConfirmation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetAllocation", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "resetAllocation"), _class.prototype)), _class));
  _exports.default = AllocationForm;
});