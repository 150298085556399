define("balance-ember/serializers/application", ["exports", "@ember-data/serializer/json-api", "balance-ember/utils/morph-keys", "balance-ember/utils/serialize-accepts-attributes-for"], function (_exports, _jsonApi, _morphKeys, _serializeAcceptsAttributesFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    /**
     * normalize - Normalize one record payload at a time
     *
     * @param  {type} typeClass
     * @param  {object} hash    The json from the API for the record
     * @return {object}         Correctly formtted JSONAPI doc
     */
    normalize(typeClass, hash) {
      return this._super(typeClass, this.dasherizeRecord(hash));
    },

    dasherizeRecord(payload) {
      payload.attributes = (0, _morphKeys.dasherizeKeys)(payload.attributes, {
        deep: false
      });
      payload.relationships = (0, _morphKeys.dasherizeKeys)(payload.relationships, {
        deep: false
      });
      return payload;
    },

    normalizeMeta(result) {
      // We want to camelcase any meta data keys, because they won't be camelcased otherwise
      let meta = result.meta;

      if (meta) {
        result.meta = (0, _morphKeys.camelizeKeys)(meta);
      }

      return result;
    },

    normalizeQueryResponse() {
      const result = this._super(...arguments);

      return this.normalizeMeta(result);
    },

    normalizeQueryRecordResponse() {
      const result = this._super(...arguments);

      return this.normalizeMeta(result);
    },

    /**
     * serialize - Serialize the data for one record to go to the api
     *
     * @return {obj} Serialized json
     */
    serialize(snapshot) {
      let json = this._super(...arguments);

      (0, _serializeAcceptsAttributesFor.default)(snapshot.record, json);
      return this.underscoreRecord(json);
    },

    payloadKeyFromModelName() {
      return Ember.String.underscore(this._super(...arguments));
    },

    underscoreRecord(payload) {
      payload.data.attributes = (0, _morphKeys.underscoreKeys)(payload.data.attributes, {
        deep: false
      });
      payload.data.relationships = (0, _morphKeys.underscoreKeys)(payload.data.relationships, {
        deep: false
      });
      payload.data.type = Ember.String.underscore(payload.data.type);
      return payload;
    }

  });

  _exports.default = _default;
});