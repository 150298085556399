define("balance-ember/components/budget/expense-table/edit-strategies/add-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i6OgkX3N",
    "block": "{\"symbols\":[],\"statements\":[[8,\"icon-button\",[],[[\"@style\",\"@size\",\"@icon\",\"@buttonText\",\"@showButtonText\",\"@testId\",\"@onclick\"],[\"btn-primary-link\",\"sm\",\"plus\",[30,[36,0],[\"budget.non_personnel_expense_form.add_strategy\"],null],true,\"add-strategy\",[32,0,[\"addStrategy\"]]]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "balance-ember/components/budget/expense-table/edit-strategies/add-button/template.hbs"
  });

  _exports.default = _default;
});