define("balance-ember/routes/budgeting/budget/strategies/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BudgetingBudgetStrategiesNewRoute extends Ember.Route {
    model({
      budget_priority_id: priorityId
    }) {
      return Ember.RSVP.all([this.store.loadRecord('budgetPriority', priorityId), this.modelFor('budgeting.budget')]).then(([budgetPriority, budget]) => {
        return this.store.createRecord('budget-strategy', {
          budgetPriority,
          budget
        });
      });
    }

  }

  _exports.default = BudgetingBudgetStrategiesNewRoute;
});