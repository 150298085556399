define("balance-ember/components/fiscal-years-index/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/urls"], function (_exports, _component, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    canManageFiscalYears: Ember.computed.readOnly('session.isBudgetArchitect'),
    routeFollowingFYSelection: Ember.computed('session.isBudgetArchitect', function () {
      return this.session.isBudgetArchitect ? 'budgeting.overview' : 'budgeting.fiscal-year.budgets';
    }),
    actions: {
      toggleVisibility(fy, visibilityState) {
        if (fy.state !== visibilityState) {
          fy.set('state', visibilityState);
          fy.save();
        }
      },

      makeCurrent(fy) {
        let url = `${(0, _urls.URLForAPIv3)()}/fiscal_year_states/${fy.id}`;
        return this.ajax.patch(url).then(() => {
          this.availableFiscalYears.fetch();
          this.availableFiscalYears.setCurrent(fy);
        });
      }

    }
  });

  _exports.default = _default;
});