define("balance-ember/components/report-summary-bar/component", ["exports", "balance-ember/components/tagless-component/component", "numeral"], function (_exports, _component, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    remainder: Ember.computed('active', 'total', function () {
      let remainder = this.total - this.active;
      return (0, _numeral.default)(remainder).format('0[.][00]');
    }),
    percentageUsed: Ember.computed('active', 'total', function () {
      return this.active / this.total * 100;
    })
  });

  _exports.default = _default;
});