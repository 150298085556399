define("balance-ember/utils/budget/change-log/change", ["exports", "moment", "lodash.map"], function (_exports, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const highLightColor = 'bg-white';
  const stdColor = 'bg-material-gray100';
  const dateFormat = 'MMM Do, YYYY [at] h:mma';

  class Change {
    constructor(change) {
      this.change = change;
    }

    static formatChange(changeObj, currentChangeId) {
      let change = new Change(changeObj);
      return change.formatChange(currentChangeId);
    }

    formatChange(currentChangeId) {
      this.change.summarizes = this.summarizes;
      this.change.dateDisplay = this.dateDisplay;
      this.change.color = this.color(currentChangeId);
      return this.change;
    }

    static color(change, currentChangeId) {
      if (change.versionId === currentChangeId) {
        return highLightColor;
      }

      return stdColor;
    }

    color(currentChangeId) {
      return Change.color(this.change, currentChangeId);
    }

    get dateDisplay() {
      return (0, _moment.default)(this.change.timestamp).format(dateFormat);
    }

    get summarizes() {
      let children = (0, _lodash.default)(this.change.children, child => {
        return Object.assign(this.buildIcon(child.event), {
          name: child.description.name
        });
      });
      return [...this.rootSummaries(), ...children];
    }

    rootSummaries() {
      let roots;

      if (this.change.requestedRoot) {
        roots = (0, _lodash.default)(this.change.diffs, change => {
          return Object.assign(this.buildIcon(this.change.event), {
            name: change.attribute
          });
        });
      } else {
        if (this.change.diffs.length === 0) {
          roots = [];
        } else {
          roots = [Object.assign(this.buildIcon(this.change.event), {
            name: this.change.description.name
          })];
        }
      }

      return roots;
    }

    buildIcon(event) {
      let icon;
      let color;

      switch (event) {
        case 'create':
          icon = 'circle-plus-solid';
          color = 'material-green500';
          break;

        case 'update':
          icon = 'edit-circle';
          color = 'material-amber-600';
          break;

        case 'destroy':
          icon = 'remove-x';
          color = 'material-red700';
          break;

        default:
      }

      return {
        icon,
        color
      };
    }

  }

  _exports.default = Change;
});