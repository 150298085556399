define("balance-ember/components/manage/recent-transactions-widget/dropdown/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    row: null,
    // Transaction
    data: null,
    _supplements: Ember.computed.readOnly('row.supplements'),
    supplements: Ember.computed('_supplements', function () {
      let supplements = this._supplements || {};
      return Object.keys(supplements).map(key => {
        return {
          name: key,
          value: this._supplements[key]
        };
      });
    }),
    supplementSortOrder: Object.freeze(['name:asc']),
    sortedSupplements: Ember.computed.sort('supplements', 'supplementSortOrder')
  });

  _exports.default = _default;
});