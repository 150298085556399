define("balance-ember/utils/filters/segment-formatter", ["exports", "balance-ember/utils/filters/autocomplete-substring-formatter"], function (_exports, _autocompleteSubstringFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SegmentFormatter extends _autocompleteSubstringFormatter.default {
    formatRecord(record) {
      return `${record.code} - ${record.name}`;
    }

  }

  _exports.default = SegmentFormatter;
});