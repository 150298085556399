define("balance-ember/components/tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ifgD+o5o",
    "block": "{\"symbols\":[\"@text\",\"&default\",\"@wrap\"],\"statements\":[[10,\"div\"],[14,0,\"\\n  rounded\\n  bg-material-blue-gray50\\n  text-material-blue-gray800\\n  text-sm\\n  font-bold\\n  py-4\\n  px-6\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[\"leading-18 \",[30,[36,1],[[30,[36,0],[[32,3],false],null],\"truncate\"],null]]]],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "moduleName": "balance-ember/components/tag/template.hbs"
  });

  _exports.default = _default;
});