define("balance-ember/routes/budgeting/budget/strategies/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BudgetingBudgetStrategiesEditRoute extends Ember.Route {
    model({
      strategy_id: id
    }) {
      return this.store.loadRecord('budget-strategy', id, {
        include: 'budget,budget_priority'
      });
    }

  }

  _exports.default = BudgetingBudgetStrategiesEditRoute;
});