define("balance-ember/components/file-upload-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OdZONANf",
    "block": "{\"symbols\":[\"queue\",\"@name\",\"@for\",\"@accept\",\"@multiple\",\"@onfileadd\",\"@disabled\",\"&default\"],\"statements\":[[8,\"file-upload\",[],[[\"@name\",\"@for\",\"@accept\",\"@multiple\",\"@onfileadd\",\"@disabled\"],[[32,2],[32,3],[32,4],[32,5],[32,6],[32,7]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,8,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/file-upload-button/template.hbs"
  });

  _exports.default = _default;
});