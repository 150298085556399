define("balance-ember/components/manage/budget-lea-switcher/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HC6K85o6",
    "block": "{\"symbols\":[\"b\",\"popover\",\"StackItem\",\"budgetLea\"],\"statements\":[[10,\"div\"],[15,0,[32,0,[\"theme\",\"wrapperClasses\"]]],[12],[2,\"\\n  \"],[8,\"icon-button\",[],[[\"@style\",\"@size\",\"@testId\",\"@icon\",\"@buttonText\",\"@showButtonText\"],[[32,0,[\"theme\",\"iconButton\",\"style\"]],\"sm\",\"budget-lea-button\",[32,0,[\"theme\",\"iconButton\",\"icon\"]],[32,0,[\"selectedBudgetLea\",\"name\"]],[30,[36,3],[[30,[36,2],[\"isXxl\"],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,[32,1,[\"defaultLayout\"]],[],[[],[]],null],[2,\"\\n\\n    \"],[8,\"popover\",[],[[\"@placement\"],[\"bottom-start\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"rounded overflow-hidden\"],[12],[2,\"\\n          \"],[8,\"layout/stack\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"sortedBudgetLeas\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@onclick\",\"@testId\",\"@active\"],[\"btn-primary-menu\",\"none\",[30,[36,0],[[32,0],\"changeBudgetLea\",[32,4],[32,2,[\"hide\"]]],null],[31,[\"budget-lea-\",[32,4,[\"name\"]]]],[30,[36,1],[[32,0,[\"availableBudgetLeas\",\"current\"]],[32,4]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                  \"],[1,[32,4,[\"name\"]]],[2,\"\\n                \"]],\"parameters\":[]}]]],[2,\"\\n              \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[4]}]]],[2,\"          \"]],\"parameters\":[3]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"eq\",\"media\",\"or\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/manage/budget-lea-switcher/template.hbs"
  });

  _exports.default = _default;
});