define("balance-ember/modifiers/hover-trigger", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)((element, [dropdown]) => {
    let onMouseEnter = () => dropdown.actions.open();

    let onMouseLeave = e => {
      let dropdownContainer = document.querySelector('.ember-basic-dropdown-content');

      if (dropdownContainer && dropdownContainer.contains(e.toElement)) {
        return;
      }

      if (dropdown.isOpen) {
        dropdown.actions.close();
        element.blur();
      }
    };

    element.addEventListener('mouseenter', onMouseEnter);
    element.addEventListener('mouseleave', onMouseLeave);
    return () => {
      element.removeEventListener('mouseenter', onMouseEnter);
      element.removeEventListener('mouseleave', onMouseLeave);
    };
  });

  _exports.default = _default;
});