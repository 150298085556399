define("balance-ember/components/manage/budget-lea-switcher/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetLeaSwitcher = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.readOnly('args.tableState'), _dec5 = Ember.computed.readOnly('args.summarizeState'), _dec6 = Ember.computed('router.currentRouteName'), _dec7 = Ember.computed('availableBudgetLeas.allowed'), _dec8 = Ember.computed('tableState.filters'), _dec9 = Ember.computed('budgetLeaFilter.firstOperator.firstFilterValue'), _dec10 = Ember.computed('availableBudgetLeas.all', 'selectedBudgetLeaId'), _dec11 = Ember._action, (_class = class BudgetLeaSwitcher extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "availableBudgetLeas", _descriptor, this);

      _initializerDefineProperty(this, "eventTracking", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "tableState", _descriptor4, this);

      _initializerDefineProperty(this, "summarizeState", _descriptor5, this);
    }

    get theme() {
      return this.router.currentRouteName === 'manage.overview' ? {
        wrapperClasses: 'mt-8 mb-27 mr-8 inline-block',
        iconButton: {
          style: 'text-left btn-default',
          icon: 'chevron'
        },
        itemButton: {
          hoverBackgroundColor: 'blue500',
          hoverTextColor: 'white'
        }
      } : {
        wrapperClasses: '',
        iconButton: {
          style: 'btn-secondary-link',
          icon: 'calendar'
        },
        itemButton: {
          hoverBackgroundColor: 'blue100',
          hoverTextColor: 'material-gray700'
        }
      };
    }

    get sortedBudgetLeas() {
      return this.availableBudgetLeas.allowed.sortBy('fiscalYear').reverse();
    }

    get budgetLeaFilter() {
      return [...this.tableState.filters].findBy('category.state', 'budget_lea_id');
    }

    get selectedBudgetLeaId() {
      return this.budgetLeaFilter.firstOperator.firstFilterValue;
    }

    get selectedBudgetLea() {
      return this.availableBudgetLeas.all.findBy('id', this.selectedBudgetLeaId);
    }

    updateBudgetLeaFilter(budgetLeaId) {
      this.tableState.filters.map(filter => {
        if (filter.category.state === 'budget_lea_id') {
          return filter.firstOperator.updateFilterValue(0, budgetLeaId);
        }

        return filter;
      });
      this.tableState.reset();

      if (this.summarizeState) {
        this.summarizeState.set({});
      }
    }

    setCurrentBudgetLea(budgetLea) {
      this.availableBudgetLeas.setCurrent(budgetLea);
    }

    changeBudgetLea(budgetLea, closeAction) {
      this.eventTracking.trackEvent('Change Manage Budget', {
        name: budgetLea.name
      });
      this.setCurrentBudgetLea(budgetLea);
      this.updateBudgetLeaFilter(budgetLea.id);
      closeAction();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "availableBudgetLeas", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "eventTracking", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tableState", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "summarizeState", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "theme", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "theme"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortedBudgetLeas", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "sortedBudgetLeas"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "budgetLeaFilter", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "budgetLeaFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedBudgetLeaId", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "selectedBudgetLeaId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedBudgetLea", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "selectedBudgetLea"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeBudgetLea", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "changeBudgetLea"), _class.prototype)), _class));
  _exports.default = BudgetLeaSwitcher;
});