define("balance-ember/components/pagination-controls/per-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NVmv4xtj",
    "block": "{\"symbols\":[\"label\",\"pageOption\",\"@itemsPerPage\",\"@setPerPage\",\"@itemsPerPageOptions\"],\"statements\":[[6,[37,4],[[32,0,[\"shouldShow\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"ease-all-1s \",[32,0,[\"displayState\"]]]]],[12],[2,\"\\n    \"],[8,\"ui-form/ui-label\",[],[[\"@style\"],[\"side\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"text\"]],[],[[\"@style\"],[\"sm radio left\"]],[[\"default\"],[{\"statements\":[[2,\"Show:\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"select\"],[14,0,\"simple-select mb-0\"],[15,\"onchange\",[30,[36,1],[[32,0],[32,4]],[[\"value\"],[\"target.value\"]]]],[12],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"option\"],[15,2,[32,2]],[15,\"selected\",[30,[36,0],[[32,2],[32,3]],null]],[12],[2,\"\\n            \"],[1,[32,2]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"action\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "balance-ember/components/pagination-controls/per-page/template.hbs"
  });

  _exports.default = _default;
});