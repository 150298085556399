define("balance-ember/components/budget/budget-files/table/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/types/table", "@microstates/ember", "balance-ember/classes/table-serializer"], function (_exports, _component, _emberConcurrency, _table, _ember, _tableSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetBudgetFilesTableComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    return yield this.loadBudgetDocs();
  }), _dec7 = Ember.computed('modelChanges.changes'), (_class = class BudgetBudgetFilesTableComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor2, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor3, this);

      _initializerDefineProperty(this, "tableState", _descriptor4, this);

      _initializerDefineProperty(this, "budgetDocToDestroy", _descriptor5, this);

      _defineProperty(this, "columns", [{
        label: 'NAME',
        valuePath: 'name',
        testSelector: 'name',
        cellComponent: 'budget/file-upload/file-name-cell',
        sortable: false,
        hideable: false
      }, {
        label: 'UPLOADED',
        valuePath: 'uploadedAt',
        testSelector: 'uploaded-at',
        cellComponent: 'date-cell',
        timeZoneMode: 'user',
        format: 'YYYY/MM/DD h:mm A',
        sortable: false,
        hideable: false
      }, {
        label: 'TYPE',
        valuePath: 'extension',
        testSelector: 'file-type',
        sortable: false,
        hideable: false
      }, {
        label: 'SIZE',
        valuePath: 'size',
        testSelector: 'size',
        cellComponent: 'light-table/cell-components/bytes-cell',
        sortable: false,
        hideable: false
      }, {
        label: 'UPLOADED By',
        valuePath: 'uploadedBy.name',
        testSelector: 'uploaded-by',
        sortable: false,
        hideable: false
      }, {
        cellComponent: 'light-table/cell-components/table-actions',
        sortable: false,
        hideable: false,
        align: 'right',
        width: '80px',
        tableActionComponents: ['delete'],

        buttonText(file) {
          return file.name;
        },

        isDeleteDisabled(file) {
          return this.isFiscalYearDoc(file);
        },

        deleteTooltipText(file) {
          if (this.isFiscalYearDoc(file)) {
            return 'You cannot delete District-wide files from within a budget';
          }

          return `Delete ${file.name}`;
        },

        isFiscalYearDoc(file) {
          return file.constructor.modelName === 'fiscal-year-doc';
        }

      }]);

      _initializerDefineProperty(this, "loadBudgetDocsTask", _descriptor6, this);
    }

    loadBudgetDocs() {
      return this.store.query('doc', { ...this.serializedTableState,
        include: 'uploaded_by',
        filter: {
          type_ids: {
            budget_id: [this.args.budget.id],
            fiscal_year_id: [this.availableFiscalYears.current.id]
          }
        },
        stats: {
          total: 'count'
        },
        sort: '-updated_at'
      });
    }

    get serializedTableState() {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }

    get budgetDocChanges() {
      return this.modelChanges.changesForModelType('budgetDoc');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tableState", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _ember.Store)((0, _ember.create)(_table.default), nextState => this.tableState = nextState);
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "budgetDocToDestroy", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loadBudgetDocsTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "budgetDocChanges", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "budgetDocChanges"), _class.prototype)), _class));
  _exports.default = BudgetBudgetFilesTableComponent;
});