define("balance-ember/utils/writeable-model", ["exports", "balance-ember/utils/writeable-model/factory"], function (_exports, _factory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class WriteableModel
   * @param model A new or existing EmberData model
   * @param store Reference to the EmberData store service. Needed to push saved data
   * back into the store.
   * @param network Reference to the network service. Needed to save changes.
   * @param {object} paths  Define which relationship paths you care about updating
   * and tracking changes on
   *
   * @description
   * # Construction
   *
   * To build a new WriteableModel you can give it a new or existing EmberData Model.
   *
   * @example
   * ```js
   * let model = this.store.peekRecord('nonPersonnelExpense', 1);
   * new WriteableModel({ model, store: this.store, session: this.session, paths: {
   *   allocation: true,
   *   segments: {
   *     segmentTypes: true,
   *     allocation: true,
   *   },
   *   expenseStrategies: {
   *     budgetStrategy: true,
   *   }
   * }});
   * ```
   *
   * ## Copying
   *
   * During construction the WriteableModel will copy all attributes and relationships of the
   * EmberData model you provide recursively if it is in the paths object.
   *
   * Computed properties can't be copied. If your form needs those you should move them from the
   * model to the component's JS file OR you can subclass `WriteableModel` and put them on the
   * subclass. You should then register that subclass in side of `writeable-models/factory.js`
   *
   * # Relationships
   *
   * You can simply access relationships by their name. Any disassociated or deleted relationships
   * will be omitted.
   *
   * ## Mutating BelongsTo
   *
   * To change the relationship simply use `=` (e.g. `expense.vendor = differentVendor`).
   * To delete use `.deleteFoo()` (e.g. `expense.deleteVendor()`).
   * To diassociate use `.disassociateFoo()` (e.g. `expense.disassociateVendor()`).
   *
   * ## Mutating HasMany
   *
   * Only use direct setting via `=` (e.g. `person.pets = [Pet1, Pet2]`) when you are initializing
   * the relationship. You may need to do this if you are waited to load the relationship from the
   * server and then need to associate it on the frontend.
   *
   * Otherwise use the `addPet`, `deletePet`, or `disassociatePet` method for your relationship.
   * These methods update the relationship so that it will be sideposted correctly.
   *
   * # Saving
   *
   * To persist your changes to the server call `.save()` on the WriteableModel. The WriteableModel
   * will clean up any unpersisted relationships after save is successful.
   *
   * Attributes marked as `readOnly: true` will not be sent to the server.
   *
   * @example
   * ```js
   * {
   *   amount: attr('number', { defaultValue: 0.0, readOnly: true }),
   * }
   * ```
   */
  var _default = _factory.default;
  _exports.default = _default;
});