define("balance-ember/components/user/profile-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yzmgXA+b",
    "block": "{\"symbols\":[\"currentUser\",\"t\",\"@user\"],\"statements\":[[8,\"auth-provider\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,1,[\"isUsingLDAP\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"italic text-material-gray700\"],[12],[2,\"\\n      User information managed via LDAP\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"ui-tabs\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"ml-32\"],[12],[2,\"\\n      \"],[8,[32,2,[\"ButtonTab\"]],[],[[\"@active\",\"@onclick\"],[[30,[36,0],[[32,0,[\"activeComponent\"]],\"account-info\"],null],[30,[36,1],[[32,0,[\"updateTab\"]],\"account-info\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Account Info\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,1,[\"hasManageAccess\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,2,[\"ButtonTab\"]],[],[[\"@active\",\"@onclick\"],[[30,[36,0],[[32,0,[\"activeComponent\"]],\"manage-permissions\"],null],[30,[36,1],[[32,0,[\"updateTab\"]],\"manage-permissions\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Manage Permissions\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[2,\"  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[11,\"div\"],[24,0,\"bg-white shadow-container rounded\"],[4,[38,3],[[32,0,[\"navigateToTab\"]]],null],[12],[2,\"\\n  \"],[1,[30,[36,5],[[30,[36,4],[\"user/profile-tabs/\",[32,0,[\"activeComponent\"]]],null]],[[\"user\"],[[32,3]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"fn\",\"if\",\"did-insert\",\"concat\",\"component\"]}",
    "moduleName": "balance-ember/components/user/profile-tabs/template.hbs"
  });

  _exports.default = _default;
});