define("balance-ember/components/manage/accounts-column-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qpkW+h4Y",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"print:hidden\"],[12],[2,\"\\n  \"],[8,\"highcharts-chart\",[],[[\"@height\",\"@series\",\"@options\",\"@afterUpdateSeries\",\"@destinationId\"],[\"384\",[32,0,[\"series\"]],[32,0,[\"options\"]],[30,[36,0],[[32,0],\"updateChartWidth\"],null],\"accounts-column-chart-destination\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/components/manage/accounts-column-chart/template.hbs"
  });

  _exports.default = _default;
});