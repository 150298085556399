define("balance-ember/controllers/district-admin/users/index", ["exports", "balance-ember/mixins/table-controller", "balance-ember/utils/urls", "@microstates/ember", "balance-ember/types/table"], function (_exports, _tableController, _urls, _ember, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EMPLOYEE_PAGE_SIZE = void 0;

  var _dec, _dec2, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const EMPLOYEE_PAGE_SIZE = 50;
  _exports.EMPLOYEE_PAGE_SIZE = EMPLOYEE_PAGE_SIZE;

  let _class = (_dec = Ember.inject.service, _dec2 = Ember.computed('availableFiscalYears.current.id'), (_class2 = class _class2 extends Ember.Controller.extend(_tableController.default) {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor, this);

      this.tableState.defaultSort.set({
        column: 'name'
      });
    }

    get budgetPrivilegeBulkUrl() {
      var _this$availableFiscal;

      let fiscalYearId = (_this$availableFiscal = this.availableFiscalYears.current) === null || _this$availableFiscal === void 0 ? void 0 : _this$availableFiscal.id;
      return (0, _urls.URLForBulkBulkPrivileges)(fiscalYearId);
    }

    get managePermissionBulkUrl() {
      return (0, _urls.URLForBulkManagePermissions)();
    }

    get budgetPrivilegesTableState() {
      let tableState = (0, _ember.Store)((0, _ember.create)(_table.default), nextState => tableState = nextState);
      tableState.pagination.page.set(0);
      tableState.defaultSort.set({
        column: 'user_budget_name',
        dir: 'asc'
      });
      return tableState;
    }

    get managePermissionsTableState() {
      let tableState = (0, _ember.Store)((0, _ember.create)(_table.default), nextState => tableState = nextState);
      tableState.pagination.page.set(0);
      tableState.defaultSort.set({
        column: 'id',
        dir: 'asc'
      });
      return tableState;
    }

    get categories() {
      return [{
        title: 'Name',
        value: 'name',
        type: 'text'
      }, {
        title: 'Email',
        value: 'email',
        type: 'text'
      }, {
        title: 'Manage Access',
        value: 'has_manage_access',

        format(filter) {
          let filterValue = filter.firstOperator.firstFilterValue;
          let operatorDescriptor = filterValue === 'true' ? 'Access' : 'No Access';
          return `Manage: ${operatorDescriptor}`;
        },

        filterOptions: {
          componentName: 'filter-form/access'
        }
      }, {
        title: 'Budget Access',
        value: 'has_budgeting_access',

        format(filter) {
          let filterValue = filter.firstOperator.firstFilterValue;
          let operatorDescriptor = filterValue === 'true' ? 'Access' : 'No Access';
          return `Budget: ${operatorDescriptor}`;
        },

        filterOptions: {
          componentName: 'filter-form/access'
        }
      }, {
        title: 'Last Login',
        value: 'current_sign_in_at',
        type: 'date'
      }];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "availableFiscalYears", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "budgetPrivilegeBulkUrl", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "budgetPrivilegeBulkUrl"), _class2.prototype)), _class2));

  _exports.default = _class;
});