define("balance-ember/session-stores/application", ["exports", "ember-simple-auth/session-stores/adaptive", "balance-ember/config/environment"], function (_exports, _adaptive, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * We want to use local storage for session data if it's available, but we'll use
   * cookies if not.
   *
   * @override
   */
  var _default = _adaptive.default.extend({
    localStorageKey: `${_environment.default.environment}-allovue_balance-local_storage`,
    cookieName: `${_environment.default.environment}-allovue_balance-local_storage`
  });

  _exports.default = _default;
});