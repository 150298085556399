define("balance-ember/components/positions-table/expanded-row/view/position-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wUwxazjy",
    "block": "{\"symbols\":[\"StackItem\",\"@position\"],\"statements\":[[10,\"div\"],[14,0,\"w-144\"],[12],[2,\"\\n  \"],[8,\"layout/stack\",[],[[\"@space\"],[\"xs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"positions-table/card-detail\",[],[[\"@label\",\"@testId\"],[[30,[36,0],[\"budget.position.data.fte_long\"],null],\"position-fte\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[[32,2,[\"fte\"]]],[[\"format\"],[\"0[.]000\"]]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"positions-table/card-detail\",[],[[\"@label\",\"@testId\"],[[30,[36,0],[\"budget.position.data.id\"],null],\"position-code\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,2,[\"code\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[32,2,[\"code\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          --\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"numeral-formatter\",\"if\"]}",
    "moduleName": "balance-ember/components/positions-table/expanded-row/view/position-details/template.hbs"
  });

  _exports.default = _default;
});