define("balance-ember/components/add-filter-button/component", ["exports", "balance-ember/components/tagless-component/component", "@microstates/ember", "balance-ember/types/filter", "balance-ember/utils/get-category-config", "balance-ember/utils/filters/format-display-text"], function (_exports, _component, _ember, _filter, _getCategoryConfig, _formatDisplayText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    eventTracking: Ember.inject.service(),
    filter: null,
    filterClass: _filter.default,
    trackingPrefix: null,
    renderInPlace: false,

    didReceiveAttrs() {
      this._super();

      this.initializeFilter();
    },

    initializeFilter() {
      let initialValue = this.filter ? (0, _ember.valueOf)(this.filter) : {}; // Update the filter property anytime it moves to a new state

      let forkedFilter = (0, _ember.Store)((0, _ember.create)(this.filterClass, initialValue), nextState => Ember.set(this, 'forkedFilter', nextState));
      Ember.set(this, 'forkedFilter', forkedFilter);
    },

    categoryConfig: Ember.computed('categories.[]', 'forkedFilter.category.state', function () {
      return (0, _getCategoryConfig.default)(this.categories, this.forkedFilter.category.state);
    }),

    applyFilter() {},

    onclose() {},

    resetFilter() {
      this.applyFilter((0, _ember.create)(this.filterClass));
      this.initializeFilter();
    },

    trackAddFilter(finishedFilter) {
      if (this.trackingPrefix) {
        let filter = (0, _formatDisplayText.formatFilters)([finishedFilter], this.categories);
        this.eventTracking.trackEvent(`${this.trackingPrefix} Add Filter`, {
          filter
        });
      }
    },

    actions: {
      apply(closeDropdown, finishedFilter) {
        this.trackAddFilter(finishedFilter);
        closeDropdown();
        this.applyFilter(finishedFilter);
        this.onclose();
      },

      cancel(closeDropdown) {
        this.resetFilter();
        closeDropdown();
        this.onclose();
      },

      onClickOutside() {
        this.resetFilter();
        this.onclose();
      }

    }
  });

  _exports.default = _default;
});