define("balance-ember/components/fiscal-year-form/component", ["exports", "@glimmer/component", "ember-concurrency", "moment", "balance-ember/utils/refresh-route", "balance-ember/utils/writeable-model", "balance-ember/utils/validators/fiscal-year"], function (_exports, _component, _emberConcurrency, _moment, _refreshRoute, _writeableModel, _fiscalYear) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FiscalYearFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    let fiscalYear = this.fiscalYear;
    this.validateSegments = true;
    this.errors = this.validate();

    if (this.errors) {
      return;
    }

    if (fiscalYear.segmentsSource) {
      fiscalYear.state = 'in_setup';
    } else if (['draft', 'hidden'].includes(fiscalYear.state)) {
      fiscalYear.state = 'hidden';
    } else {
      fiscalYear.state = 'hidden';
    }

    yield fiscalYear.save().then(() => {
      (0, _refreshRoute.default)('fiscal-years', this);
      this.router.transitionTo('fiscal-years');
    }).catch(errors => {
      this.errors = errors.mapBy('detail');
    });
  }).drop(), _dec11 = Ember.computed('fiscalYear.{endDate,name,startDate}', 'validator'), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class FiscalYearFormComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "network", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "fiscalYear", _descriptor5, this);

      _initializerDefineProperty(this, "errors", _descriptor6, this);

      _initializerDefineProperty(this, "chartOfAccountsSource", _descriptor7, this);

      _initializerDefineProperty(this, "validateSegments", _descriptor8, this);

      _initializerDefineProperty(this, "salarySegment", _descriptor9, this);

      _initializerDefineProperty(this, "saveFiscalYear", _descriptor10, this);

      this.createWriteableModel(this.store.createRecord('fiscalYear'));
    }

    createWriteableModel(model) {
      this.fiscalYear = new _writeableModel.default({
        model,
        store: this.store,
        network: this.network
      });
    }

    get fiscalYearValid() {
      return !this.validator.validate();
    }

    validate() {
      var _this$validator$valid;

      let messages = (_this$validator$valid = this.validator.validate()) === null || _this$validator$valid === void 0 ? void 0 : _this$validator$valid.messages;

      if (this.chartOfAccountsSource === 'none-selected') {
        return (messages ?? []).concat(this.intl.t('budget.fiscal_year.validations.no_chart_of_accounts'));
      }

      if (this.chartOfAccountsSource === 'reuse-existing' && !this.fiscalYear.segmentsSource) {
        return (messages ?? []).concat(this.intl.t('budget.fiscal_year.validations.no_segments_source'));
      }

      if (this.chartOfAccountsSource === 'upload-new' && !this.salarySegment) {
        return (messages ?? []).concat(this.intl.t('budget.fiscal_year.validations.no_salary_segment'));
      }

      return messages;
    }

    get validator() {
      return new _fiscalYear.default({
        model: this.fiscalYear,
        intl: this.intl
      });
    }

    setStartDate(_, date) {
      if (!date.match(/[mdy]/i)) {
        let iso8601date = (0, _moment.default)(date, 'MM/DD/YYYY').toDate();
        Ember.set(this, 'fiscalYear.startDate', iso8601date);
      }
    }

    setEndDate(_, date) {
      if (!date.match(/[mdy]/i)) {
        let iso8601date = (0, _moment.default)(date, 'MM/DD/YYYY').toDate();
        Ember.set(this, 'fiscalYear.endDate', iso8601date);
      }
    }

    cancelFiscalYear() {
      this.router.transitionTo('/budgeting/fiscal-years');
    }

    chartOfAccountsUploadSelection(value) {
      this.chartOfAccountsSource = value;
    }

    updateFiscalYear(serverDraftFiscalYearPayload) {
      this.store.pushPayload(serverDraftFiscalYearPayload); // Pull newly added record from store

      let fiscalYearId = serverDraftFiscalYearPayload.data.id;
      let fiscalYear = this.store.peekRecord('fiscalYear', fiscalYearId); // Merge Font-end properties onto draft server record.

      let frontendFiscalYear = this.fiscalYear;
      fiscalYear.name = frontendFiscalYear.name;
      fiscalYear.startDate = frontendFiscalYear.startDate;
      fiscalYear.endDate = frontendFiscalYear.endDate; // Overwrite font-end record with server record.

      this.createWriteableModel(fiscalYear);
      return fiscalYear;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "network", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fiscalYear", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "chartOfAccountsSource", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'none-selected';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "validateSegments", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "salarySegment", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "saveFiscalYear", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fiscalYearValid", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "fiscalYearValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setStartDate", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "setStartDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setEndDate", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setEndDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelFiscalYear", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "cancelFiscalYear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chartOfAccountsUploadSelection", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "chartOfAccountsUploadSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFiscalYear", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "updateFiscalYear"), _class.prototype)), _class));
  _exports.default = FiscalYearFormComponent;
});