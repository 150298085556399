define("balance-ember/components/manage/summarize/data-loader/row-builder", ["exports", "@microstates/ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class RowBuilder {
    constructor({
      rows,
      path,
      categories = []
    } = {}) {
      _defineProperty(this, "ROW_TYPES", {
        segment: SegmentRow,
        supplement: SupplementRow,
        quarter: QuarterRow,
        month: MonthRow
      });

      this.rows = rows;
      this.path = path;
      this.categories = categories;
    }

    build() {
      return this.rows.map((row, index) => {
        return new this.rowClass({ ...row,
          // needs to be first in case passed rows are row objects. we want fresh stuff
          summarizePath: this.path,
          valuePath: this.valuePath,
          category: this.category,
          summarizeState: this.summarizeState,
          availableCategories: this.availableCategories,
          isLastRow: index === this.rows.length - 1
        });
      });
    }

    get category() {
      return this._category = this._category || (this.isSwitchingCategories ? (0, _ember.valueOf)(this.summarizeState.category) : this.node.category);
    }

    get isSwitchingCategories() {
      return Boolean(this.summarizeState.category.keys.length);
    }

    get rowClass() {
      return this._rowClass = this._rowClass || this.ROW_TYPES[this.category.type];
    }

    get valuePath() {
      return this._valuePath = this._valuePath || this.path.values;
    }

    get summarizeState() {
      return this._summarizeState = this._summarizeState || this.node.summary;
    }

    get node() {
      return this._node = this._node || this.path.lastObject;
    }

    get availableCategories() {
      return this._availableCategories = this._availableCategories || this.categories.filter(category => !this.usedCategoryValues.includes(category.value));
    }

    get usedCategoryValues() {
      return this._usedCategoryValues = this._usedCategoryValues || this.parentCategories.compact().mapBy('value');
    }

    get parentCategories() {
      return this._parentCategories = this._parentCategories || this.path.mapBy('category').concat((0, _ember.valueOf)(this.summarizeState.category)).compact();
    }

  }

  _exports.default = RowBuilder;

  class BasicRow {
    constructor({
      category,
      summarizePath,
      valuePath,
      availableCategories,
      summarizeState,
      descendants = false,
      isLastRow = false,
      ...data
    } = {}) {
      this.category = category;
      this.summarizePath = summarizePath;
      this.valuePath = valuePath;
      this.summarizeState = summarizeState;
      this.descendants = descendants;
      this.availableCategories = availableCategories;
      this.isLastRow = isLastRow;
      this.assignDynamicAttributes(data);
    }

    assignDynamicAttributes(data) {
      Object.keys(data).forEach(key => {
        this[key] = data[key];
      });
    }

    get isExpanded() {
      return Boolean(this.childSummarizeState && this.childSummarizeState.rows.length);
    }

    get summarizeValue() {
      return null;
    }

    get isLoading() {
      return Boolean(this.childSummarizeState && !this.childSummarizeState.rows.length);
    }

    get isChildSwitchingCategories() {
      return this._isChildSwitchingCategories = this._isChildSwitchingCategories || Boolean(this.childSummarizeState && this.childSummarizeState.category.keys.length);
    }
    /**
     * get childSummarizeState
     * @return {SummarizeState} The SummarizeState object for the child rows
     */


    get childSummarizeState() {
      return this.summarizeState.summaries.entries[this.summarizeValue];
    }

  }

  class SegmentRow extends BasicRow {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "rowType", 'segment');
    }

    get summarizeValue() {
      return this.segmentId;
    }

    get rowName() {
      return `${this.segmentName} - ${this.segmentCode}`;
    }

  }

  class SupplementRow extends BasicRow {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "rowType", 'supplement');
    }

    get summarizeValue() {
      return this.supplementValue;
    }

    get rowName() {
      return this.supplementValue;
    }

  }

  class QuarterRow extends BasicRow {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "rowType", 'quarter');
    }

    get summarizeValue() {
      return this.dateRange.join(',');
    }

    get rowName() {
      return `Quarter ${this.name}`;
    }

  }

  class MonthRow extends BasicRow {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "rowType", 'month');
    }

    get summarizeValue() {
      return this.dateRange.join(',');
    }

    get rowName() {
      return this.name;
    }

  }
});