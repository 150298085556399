define("balance-ember/components/manage/grand-totals-header/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    features: Ember.inject.service(),
    isOriginalBudgetEnabled: Ember.computed.readOnly('features.originalBudget'),
    originalBudgetColumnHidden: Ember.computed('table.hiddenColumns', function () {
      var _this$table, _this$table$hiddenCol, _this$table$hiddenCol2;

      return (_this$table = this.table) === null || _this$table === void 0 ? void 0 : (_this$table$hiddenCol = _this$table.hiddenColumns) === null || _this$table$hiddenCol === void 0 ? void 0 : (_this$table$hiddenCol2 = _this$table$hiddenCol.filter(column => column.name === 'original budget')) === null || _this$table$hiddenCol2 === void 0 ? void 0 : _this$table$hiddenCol2.length;
    }),
    labelColSpan: Ember.computed('columns.[]', 'numAmountColumns', function () {
      return this.columns.length - this.numAmountColumns;
    }),
    numAmountColumns: Ember.computed('grandTotals', 'originalBudgetColumnHidden', 'isOriginalBudgetEnabled', function () {
      return this.isOriginalBudgetEnabled && !this.originalBudgetColumnHidden ? Object.keys(this.grandTotals).length : Object.keys(this.grandTotals).length - 1 || 1;
    }),
    grandTotals: Ember.computed('extra.grandTotals', function () {
      return this.extra.grandTotals || {};
    }),
    oneVisibleColumn: Ember.computed.equal('numVisibleColumns', 1),
    numVisibleColumns: Ember.computed.readOnly('visibleColumns.length'),
    visibleColumns: Ember.computed('hideableColumns.@each.hidden', function () {
      return this.hideableColumns.filter(column => !column.hidden);
    }),
    hideableColumns: Ember.computed('columns.@each.hideable', function () {
      return this.columns.filter(column => column.hideable);
    })
  });

  _exports.default = _default;
});