define("balance-ember/components/budget/vendor-form-amazon-business/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/writeable-model", "balance-ember/utils/autocomplete-announcement", "balance-ember/utils/validators/vendor"], function (_exports, _component, _emberConcurrency, _writeableModel, _autocompleteAnnouncement, _vendor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('vendor', {
      filter: {
        fiscal_year_id: {
          eq: this.currentFiscalYearId
        }
      },
      page: {
        number: 1,
        size: 6
      },
      sort: 'name'
    });
  }), _dec12 = (0, _emberConcurrency.task)(function* (query = '') {
    yield (0, _emberConcurrency.timeout)(300);
    return yield this.store.query('vendor', {
      filter: {
        fiscal_year_id: {
          eq: this.currentFiscalYearId
        },
        query: {
          fuzzy: query
        }
      },
      page: {
        number: 1,
        size: 10
      },
      sort: 'name'
    }).then(results => (0, _autocompleteAnnouncement.default)(results, this.a11yAnnouncer));
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    var _this$validator$valid;

    if (!this.vendor) {
      this.errors = [this.intl.t('budget.vendor.form_amazon_business.validation_no_selection')];
      return;
    }

    this.errors = (_this$validator$valid = this.validator.validate()) === null || _this$validator$valid === void 0 ? void 0 : _this$validator$valid.messages;
    if (!Ember.isEmpty(this.errors)) return;

    try {
      yield this.vendor.save();
    } catch (errors) {
      this.handleErrors(errors);
      return;
    }

    this.handleSaveSuccess();
  }), _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class2 = class _class2 extends _component.default {
    get currentFiscalYearId() {
      return this.availableFiscalYears.current.id;
    }

    get isCreatingNewVendor() {
      return this.vendor !== null && this.vendor.isNew;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "network", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "a11yAnnouncer", _descriptor5, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor6, this);

      _initializerDefineProperty(this, "intl", _descriptor7, this);

      _initializerDefineProperty(this, "notifications", _descriptor8, this);

      _initializerDefineProperty(this, "vendor", _descriptor9, this);

      _initializerDefineProperty(this, "errors", _descriptor10, this);

      _initializerDefineProperty(this, "fetchVendorsTask", _descriptor11, this);

      _initializerDefineProperty(this, "searchVendorsTask", _descriptor12, this);

      _initializerDefineProperty(this, "save", _descriptor13, this);

      this.initialize();
    }

    async initialize() {
      let vendors = await this.fetchVendorsTask.perform();
      if (Ember.isEmpty(vendors)) this.setFlowNew();
    }

    goToVendorsIndex() {
      this.router.transitionTo('budgeting.vendors');
    }

    get validator() {
      return new _vendor.default({
        model: this.vendor,
        intl: this.intl
      });
    }

    handleErrors(errors) {
      if (errors && errors.length) {
        errors.forEach(error => {
          if (error.detail === 'Code has already been taken') {
            this.errors = [this.intl.t('budget.vendor.form.validation_code_unique')];
          } else {
            this.notifications.error(errors[0].detail);
          }
        });
      }
    }

    handleSaveSuccess() {
      this.modelChanges.addChange({
        modelType: 'vendor',
        changeType: this.vendor.isNew ? 'create' : 'update'
      });
      this.modelChanges.addChange({
        modelType: 'vendorAmazonBusiness',
        changeType: this.vendor.isNew ? 'create' : 'update'
      });
      this.goToVendorsIndex();
    }

    cancel() {
      this.goToVendorsIndex();
    }

    chooseExistingVendor(existingVendor) {
      if (!existingVendor) {
        this.vendor = null;
        return;
      }

      let model = new _writeableModel.default({
        model: existingVendor,
        store: this.store,
        network: this.network
      });
      model.amazonBusiness = true;
      this.vendor = model;
    }

    setFlowExisting() {
      this.vendor = null;
    }

    setFlowNew() {
      this.vendor = new _writeableModel.default({
        model: this.store.createRecord('vendor', {
          fiscalYear: this.store.peekRecord('fiscalYear', this.currentFiscalYearId),
          amazonBusiness: true
        }),
        store: this.store,
        network: this.network
      });
    }

    focusFirst(element) {
      element.querySelector('input[role="combobox"]').focus();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "network", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "availableFiscalYears", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "a11yAnnouncer", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "modelChanges", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "notifications", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "vendor", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "errors", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "fetchVendorsTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "searchVendorsTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "save", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "cancel", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "cancel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "chooseExistingVendor", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "chooseExistingVendor"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setFlowExisting", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "setFlowExisting"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setFlowNew", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "setFlowNew"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "focusFirst", [_dec18], Object.getOwnPropertyDescriptor(_class2.prototype, "focusFirst"), _class2.prototype)), _class2));

  _exports.default = _class;
});