define("balance-ember/components/budget/reports/overassigned-employees/component", ["exports", "balance-ember/mixins/budgeting-reports"], function (_exports, _budgetingReports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_budgetingReports.default, {
    reportName: 'overassigned-employees',
    defaultSortColumn: 'code',
    columns: Ember.computed(function () {
      return [{
        label: 'Employee ID',
        valuePath: 'code',
        sortName: 'code',
        testSelector: 'code',
        cellClassNames: 'align-top',
        cellComponent: 'overflowing-table-cell',
        width: '150px'
      }, {
        label: 'Employee & Positions',
        sortName: 'name',
        testSelector: 'name',
        cellClassNames: 'align-top',
        cellComponent: 'budget/reports/overassigned-employees/employees-positions-cell'
      }, {
        label: 'FTE',
        sortable: false,
        hideable: false,
        cellComponent: 'budget/reports/overassigned-employees/fte-cell',
        cellClassNames: 'align-top',
        width: '50px'
      }, {
        label: 'Budget',
        sortable: false,
        hideable: false,
        cellComponent: 'budget/reports/overassigned-employees/budget-cell',
        cellClassNames: 'align-top'
      }];
    }),
    categories: Ember.computed(function () {
      return [{
        title: 'Employee ID',
        value: 'code',
        type: 'text'
      }, {
        title: 'Employee',
        value: 'name',
        type: 'text'
      }];
    })
  });

  _exports.default = _default;
});