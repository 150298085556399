define("balance-ember/components/manage/summarize/data-loader/child-update", ["exports", "balance-ember/types/summarize", "@microstates/ember"], function (_exports, _summarize, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * ChildUpdate - A class for updating a child summarize state. It takes a task and performs
   * it if rows don't already exist. It also makes it possible to connect a path with the
   * rows it belongs to
   */
  class ChildUpdate {
    constructor({
      path,
      task
    } = {}) {
      this.path = path;
      this.task = task;
    }

    perform() {
      if (this.existingRows.length) {
        this.taskInstance = {
          value: this.existingRows
        };
      } else {
        this.taskInstance = this.task.perform(this.path);
      }

      return this;
    }
    /**
     * updateSummarizeState - Updates a summarize state object that's passed in. It adds the
     * rows returned from the task to the correct child summarize state
     *
     * @param  {SummarizeState} summarizeState The copied summarize state to update
     * @return {SummarizeState}                The new summarize state with the rows added
     */


    updateSummarizeState(summarizeState) {
      if (!this.newRows || !this.newRows.length) {
        return this.collapseStateWithNoRows(summarizeState);
      }

      return (0, _summarize.dig)(summarizeState, this.valuePath).rows.set(this.newRows);
    }

    collapseStateWithNoRows(summarizeState) {
      return (0, _summarize.dig)(summarizeState, this.parentValuePath).summaries.delete(this.summarizeValue);
    }

    get summarizeValue() {
      return this.valuePath.lastObject;
    }

    get parentValuePath() {
      return this.valuePath.slice(0, this.valuePath.length - 1);
    }

    get valuePath() {
      return this.path.values;
    }

    get newRows() {
      return this.taskInstance.value;
    }

    get existingRows() {
      return (0, _ember.valueOf)(this.childSummarizeState.rows);
    }

    get childSummarizeState() {
      return this.path.lastObject.summary;
    }

  }

  _exports.default = ChildUpdate;
});