define("balance-ember/serializers/sideposting", ["exports", "balance-ember/serializers/application", "balance-ember/utils/graphiti-sidepost-serializer"], function (_exports, _application, _graphitiSidepostSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot) {
      let json = this._super(...arguments);

      return new _graphitiSidepostSerializer.default(snapshot, json).serialize();
    }

  });

  _exports.default = _default;
});