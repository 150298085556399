define("balance-ember/routes/manage-v3/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ManageV3OverviewRoute extends Ember.Route {
    redirect() {
      this.replaceWith('manage.overview');
    }

  }

  _exports.default = ManageV3OverviewRoute;
});