define("balance-ember/components/light-table/cell-components/link-cell/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Adds a link to an ember-light-table cell
   * @param {object} row.content Accessible properties on each row of data in your table.
   * @param {array} column.route An array of the route params needed for the linkto helper to construct a route path.
   *  The first index should be the route name and all other indices should be the path to the value on the row object.
   *  Need to define a route property on the table column you want to be a link.
   *  Example for a budget expenses link:
   *    `['budgeting.budget.expenses', 'budget.id']`
   * @param {string} value The string to display as a link. Taken from the column's valuePath property.
   */
  class LinkCell extends _component.default {
    get route() {
      return this.args.column.route.map((routeParam, index) => {
        if (index > 0) {
          return Ember.get(this.args.row.content, routeParam);
        }

        return routeParam;
      });
    }

    get trackingEvent() {
      return this.args.column.trackingEvent;
    }

    get trackingParams() {
      let {
        trackingParams
      } = this.args.column;

      if (trackingParams) {
        return Object.keys(trackingParams).reduce((acc, key) => {
          let param = trackingParams[key];

          if (param) {
            let value = this.args.row.get(param) ? this.args.row.get(param) : trackingParams[key];
            return { ...acc,
              [key]: value
            };
          }

          return { ...acc
          };
        }, {});
      }

      return null;
    }

  }

  _exports.default = LinkCell;
});