define("balance-ember/components/budget/non-personnel-expenses-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eZ+puGgk",
    "block": "{\"symbols\":[\"T\",\"Controls\",\"Body\",\"row\",\"Controls\",\"@allocation\",\"@tableActions\",\"@afterSave\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"onAllocationChange\"]],[32,6]],null],[12],[13],[2,\"\\n\\n\"],[8,\"table\",[],[[\"@tableState\",\"@columns\",\"@loadTask\",\"@modelChanges\",\"@trackingPrefix\",\"@tableActions\"],[[32,0,[\"expenseItemsTableState\"]],[32,0,[\"columns\"]],[32,0,[\"loadTask\"]],[32,0,[\"nonPersonnelExpenseChanges\"]],\"Non-Personnel Expenses\",[32,7]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,5,[\"pagination\"]],[],[[\"@style\"],[\"tight\"]],null],[2,\"\\n  \"]],\"parameters\":[5]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[\"@canExpand\",\"@multiRowExpansion\",\"@id\",\"@rowTestSelector\",\"@noDataIcon\"],[true,true,\"non-personnel-expenses-table\",\"non-personnel-expense-row\",\"na-expenses\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"loader\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Loading Non-Personnel Expenses...\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,3,[\"noData\"]],[],[[\"@noDataText\"],[\"No expenses yet\"]],null],[2,\"\\n    \"],[8,[32,3,[\"expanded-row\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"budget/non-personnel-expenses-table/expanded-row\",[],[[\"@row\",\"@close\",\"@afterSave\"],[[32,4],[30,[36,1],[[32,0,[\"collapseRow\"]],[32,4]],null],[32,8]]],null],[2,\"\\n    \"]],\"parameters\":[4]}]]],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"pagination\"]],[],[[\"@style\"],[\"tight\"]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-update\",\"fn\"]}",
    "moduleName": "balance-ember/components/budget/non-personnel-expenses-table/template.hbs"
  });

  _exports.default = _default;
});