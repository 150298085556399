define("balance-ember/components/budget/file-upload/input/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetFileUploadInput = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = (0, _emberConcurrency.task)(function* (file) {
    yield this.uploadFunc(file).catch(err => {
      this.showErrors(err);
      this.removeFileFromQueue(file);
    });
    this.afterUpload();
  }), (_class = class BudgetFileUploadInput extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modelChanges", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "ajax", _descriptor3, this);

      _initializerDefineProperty(this, "fileQueue", _descriptor4, this);

      _initializerDefineProperty(this, "eventTracking", _descriptor5, this);

      _initializerDefineProperty(this, "uploadFileTask", _descriptor6, this);

      _defineProperty(this, "ERROR_MESSAGES", {
        413: 'File size must be less than 10MB'
      });
    }

    afterUpload() {
      if (this.allUploadsComplete) {
        this.afterAllUploads();
      }
    }

    get allUploadsComplete() {
      return !this.fileQueue.find(this.args.name).files.length;
    }

    get afterAllUploads() {
      return this.args.afterAllUploads || function () {};
    }

    get uploadFunc() {
      return this.args.uploadFile || this.uploadFile;
    }

    uploadFile(file) {
      if (this.args.budget) {
        this.eventTracking.trackEvent('Upload a Shared Document', {
          fileName: file.name,
          budget: this.args.budget.name,
          fiscalYear: this.args.budget.fiscalYear.name
        });
      }

      return file.upload(this.args.url, {
        data: this.args.uploadData
      });
    }

    showErrors(err) {
      let message = err && this.ERROR_MESSAGES[err.status] || 'Something went wrong, please try again later';
      message = `Upload Error: ${message}`;
      this.notifications.error(message);
    }

    removeFileFromQueue(file) {
      this.fileQueue.find(this.args.name).files.removeObject(file);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fileQueue", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "eventTracking", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uploadFileTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BudgetFileUploadInput;
});