define("balance-ember/components/tag/form/single/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ov9ltUEQ",
    "block": "{\"symbols\":[\"@tag\"],\"statements\":[[2,\"   \"],[8,\"checkbox\",[],[[\"@checked\",\"@testId\",\"@onupdate\"],[[32,0,[\"isChecked\"]],[31,[\"tag-checkbox-\",[32,1,[\"name\"]],\"-\",[32,0,[\"isChecked\"]]]],[32,0,[\"update\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/tag/form/single/checkbox/template.hbs"
  });

  _exports.default = _default;
});