define("balance-ember/components/manage/bookmark-dropdown/row/promote/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'w-full',
    eventTracking: Ember.inject.service(),
    options: Ember.computed('bookmark.districtWide', function () {
      let isDistrict = this.bookmark.districtWide;
      return {
        buttonText: isDistrict ? 'Yes, Make It Private' : 'Yes, Make It District-Wide',
        boxColorClass: isDistrict ? 'bg-material-orange100' : 'bg-material-gray100',
        boxBorderClass: isDistrict ? 'border-material-orange200' : 'border border-material-gray200',
        buttonClass: isDistrict ? 'btn-warning-light' : 'btn-sm btn-default-blue'
      };
    }),

    trackPromotion() {
      let prefix = this.bookmark.districtWide ? 'Promote' : 'Demote';
      this.eventTracking.trackEvent(`${prefix} Bookmark`, {
        bookmarkName: this.bookmark.name
      });
    },

    actions: {
      promoteBookmark() {
        this.toggleProperty('bookmark.districtWide');
        return this.bookmark.save().then(() => {
          this.trackPromotion();
          this.bookmarkState.reset();
        });
      }

    }
  });

  _exports.default = _default;
});