define("balance-ember/components/transaction-amount-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param {string} value The amount
   * @param {string} row.content.statusCode The type of transaction (e.g. actual, encumbered, etc.)
   */
  var _default = Ember.Component.extend({
    classNameBindings: ['statusCodeClass'],
    attributeBindings: ['testSelector:data-test-amount-cell'],
    testSelector: Ember.computed.readOnly('column.columnValue'),
    value: null,
    statusCode: Ember.computed.readOnly('row.content.statusCode'),
    statusCodeClass: Ember.computed('statusCode', function () {
      return `text-${this.statusCode}`;
    }),
    formattedStatusCode: Ember.computed('statusCode', function () {
      return Ember.String.capitalize(this.statusCode);
    })
  });

  _exports.default = _default;
});