define("balance-ember/components/budget/strategies-table/strategy-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5wL4wesX",
    "block": "{\"symbols\":[\"@row\"],\"statements\":[[10,\"div\"],[14,0,\"text-lg\"],[12],[2,\"\\n  \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[\"text-material-gray600\\n  text-to-truncate\\n  overflow-hidden\\n  overflowing-text\\n  text-overflow-ellipsis\\n  print:overflow-visible\\n  print:overflow-x-hidden\\n  print:whitespace-normal\\n  \",[30,[36,1],[[32,0,[\"isExpanded\"]],\"h-auto\",\"h-48\"],null],\"\\n  print:h-auto\"]]],[12],[2,\"\\n  \"],[1,[32,1,[\"description\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"needsExpandLink\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"javascript:void(0)\"],[24,0,\"\"],[4,[38,0],[[32,0],\"toggleExpansion\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[1,[32,0,[\"expandLinkText\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "moduleName": "balance-ember/components/budget/strategies-table/strategy-cell/template.hbs"
  });

  _exports.default = _default;
});