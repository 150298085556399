define("balance-ember/components/employee-form-data/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    employeeStatuses: null,
    loadDataTask: (0, _emberConcurrency.task)(function* () {
      let store = this.store;
      yield Ember.RSVP.all([Ember.set(this, 'employeeStatuses', store.query('employeeStatus', {
        sort: 'status_name'
      }))]);
    }),

    didReceiveAttrs() {
      this._super();

      this.loadDataTask.perform();
    }

  });

  _exports.default = _default;
});