define("balance-ember/components/grand-totals-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "argfZunJ",
    "block": "{\"symbols\":[\"columns\",\"column\",\"index\",\"@grandTotalLabelIndex\",\"@totals\",\"@foot\"],\"statements\":[[6,[37,5],[[32,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"tr\"],[14,0,\"print:hidden\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,2,[\"grandTotalPath\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"light-table/columns/grand-total-column\",[],[[\"@column\",\"@total\"],[[32,2],[30,[36,2],[[32,5],[32,2,[\"grandTotalPath\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,3],[32,4]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"td\"],[14,0,\"font-semibold text-xs text-right\"],[12],[2,\"Grand Totals:\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"td\"],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[2,3]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"get\",\"-track-array\",\"each\",\"component\"]}",
    "moduleName": "balance-ember/components/grand-totals-footer/template.hbs"
  });

  _exports.default = _default;
});