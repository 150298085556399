define("balance-ember/components/budgeting-modal-errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mIl0Vr0i",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"modelErrors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"error\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isCPValidationError\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[32,1,[\"message\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,1,[\"attribute\"]]],null]],[2,\" \"],[1,[32,1,[\"message\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"titleize-error\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/budgeting-modal-errors/template.hbs"
  });

  _exports.default = _default;
});