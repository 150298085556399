define("balance-ember/components/budget-users-permissions-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    users: null,
    columns: Ember.computed(function () {
      let cols = [{
        label: 'User',
        valuePath: 'name',
        cellClassNames: 'text-sm',
        testSelector: 'budget-user',
        sortable: false,
        hideable: false
      }, {
        valuePath: 'id',
        cellComponent: 'edit-budget-user-permissions',
        cellClassNames: 'text-sm',
        align: 'right',
        sortable: false,
        hideable: false
      }];
      return cols;
    })
  });

  _exports.default = _default;
});