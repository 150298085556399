define("balance-ember/services/url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Use this service for interacting with the url string
   */
  var _default = Ember.Service.extend({
    host: window.location.host,
    subdomain: Ember.computed('host', function () {
      let [sub] = this.host.split('.'); // If subdomain isn't defined for some reason we'll just return localhost

      return sub ? sub : 'localhost';
    })
  });

  _exports.default = _default;
});