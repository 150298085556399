define("balance-ember/components/table/controls/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QA0i6RCj",
    "block": "{\"symbols\":[\"@trackingPrefix\",\"@trackingParams\",\"@loadTask\",\"@tableState\",\"@categories\",\"&default\"],\"statements\":[[6,[37,3],[[32,0,[\"shouldShow\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[30,[36,0],[\"print:hidden flex items-center px-16 py-14 bg-\",[32,0,[\"backgroundColor\"]]],null]],[12],[2,\"\\n    \"],[18,6,[[30,[36,2],null,[[\"filter-button\",\"pagination\"],[[30,[36,1],[\"table/filter-button\"],[[\"tableState\",\"categories\",\"trackingPrefix\"],[[32,4],[32,5],[32,1]]]],[30,[36,1],[\"table/controls/pagination\"],[[\"state\",\"loadTask\",\"trackingParams\",\"trackingPrefix\"],[[32,4,[\"pagination\"]],[32,3],[32,2],[32,1]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"component\",\"hash\",\"if\"]}",
    "moduleName": "balance-ember/components/table/controls/template.hbs"
  });

  _exports.default = _default;
});