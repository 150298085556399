define("balance-ember/components/manage/bookmark-dropdown/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x21N4/9m",
    "block": "{\"symbols\":[\"@currentBookmark\",\"@bookmark\"],\"statements\":[[2,\"  \"],[1,[30,[36,1],[[32,0,[\"bookmarkComponentName\"]]],[[\"bookmark\",\"currentBookmark\",\"bookmarkState\",\"cancel\",\"deleteBookmark\",\"currentBookmarkText\",\"currentBookmarkTextClasses\"],[[32,2],[32,1],[32,0,[\"bookmarkState\"]],[30,[36,0],[[32,0],\"cancel\"],null],[32,0,[\"deleteBookmark\"]],[32,0,[\"currentBookmarkText\"]],[32,0,[\"currentBookmarkTextClasses\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\"]}",
    "moduleName": "balance-ember/components/manage/bookmark-dropdown/row/template.hbs"
  });

  _exports.default = _default;
});