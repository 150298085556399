define("balance-ember/utils/writeable-model/network-adapter", ["exports", "balance-ember/config/environment", "ember-inflector", "balance-ember/utils/writeable-model/serializer", "balance-ember/utils/query-params"], function (_exports, _environment, _emberInflector, _serializer, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class NetworkAdapter {
    constructor({
      writeableModel,
      store,
      network
    }) {
      _defineProperty(this, "APIRoot", _environment.default.APIRoot);

      _defineProperty(this, "serializerClass", _serializer.default);

      _defineProperty(this, "namespace", 'api/v3');

      this.writeableModel = writeableModel;
      this.store = store;
      this.network = network;
    }

    save(options = {}) {
      if (!this.network || !this.network.fetch) {
        throw 'You must pass WriteableModel the network service in order to save records requests. \n' + 'new WriteableModel({ model, store: this.store, network: this.network})';
      }

      let url = this.url;

      let queryString = _queryParams.default.stringify(options);

      if (queryString.length) {
        url = `${url}?${queryString}`;
      }

      return this.network.fetch(url, {
        method: this.saveMethod,
        body: JSON.stringify(this.requestBody)
      }).then(json => {
        if (json.errors && json.errors.length) throw json.errors;
        let persistedData = this.addPersistedDataToStore(json);
        this.unloadUnpersistedRecords();
        return persistedData;
      });
    }

    addPersistedDataToStore(json) {
      if (!this.store || !this.store.pushPayload) {
        throw 'You must pass WriteableModel the EmberData store in order to save records. \n' + 'new WriteableModel({ model, store: this.store, network: this.network })';
      } // pushPayload is stripping out json.data, including the ID, so set it here


      let id = json.data.id;
      this.store.pushPayload(json);
      return this.store.peekRecord(this.modelName, id);
    }

    unloadUnpersistedRecords() {
      this.writeableModel.relationshipDefinitions.forEach(definition => {
        let children = this.writeableModel[`_${definition.name}`];

        if (!children) {
          return;
        }

        if (definition.kind === 'hasMany') {
          children.forEach(child => this.unloadUnpersistedRecord(child));
        } else {
          this.unloadUnpersistedRecord(children);
        }
      });
      this.unloadUnpersistedRecord(this.writeableModel);
    }

    unloadUnpersistedRecord(child) {
      if (!child.id || child.isDeleted) {
        child.model.unloadRecord();
      }
    }

    get saveMethod() {
      return !this.isNew ? 'PATCH' : 'POST';
    }

    get url() {
      let _url = `${this.APIRoot}/${this.namespace}/${this.resourceName}`;
      return !this.isNew ? `${_url}/${this.id}` : _url;
    }

    get resourceName() {
      return Ember.String.underscore((0, _emberInflector.pluralize)(this.modelName));
    }

    get requestBody() {
      return new this.serializerClass({
        writeableModel: this.writeableModel
      }).serialize();
    }

    get isNew() {
      return this.writeableModel.isNew;
    }

    get id() {
      return this.writeableModel.id;
    }

    get modelName() {
      return this.writeableModel.modelName;
    }

  }

  _exports.default = NetworkAdapter;
});