define("balance-ember/templates/authenticated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k8rJaPWv",
    "block": "{\"symbols\":[\"session\"],\"statements\":[[8,\"auth-provider\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"  \"],[10,\"div\"],[15,0,[30,[36,0],[[32,1,[\"isAuthenticated\"]],\"opacity-25\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\",\"-outlet\",\"component\"]}",
    "moduleName": "balance-ember/templates/authenticated.hbs"
  });

  _exports.default = _default;
});