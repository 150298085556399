define("balance-ember/components/report-summary-stat/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pkcVDVbN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"value amount\"],[12],[2,\"\\n  \"],[1,[32,0,[\"value\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"label\"],[12],[1,[30,[36,0],[[32,0,[\"value\"]],[32,0,[\"title\"]]],[[\"without-count\"],[true]]]],[13]],\"hasEval\":false,\"upvars\":[\"pluralize\"]}",
    "moduleName": "balance-ember/components/report-summary-stat/template.hbs"
  });

  _exports.default = _default;
});