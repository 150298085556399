define("balance-ember/components/positions-table/columns-provider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jIZJgjGk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[32,0,[\"columns\"]],[32,0,[\"delete\"]]]],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"positionToDelete\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"destroy-position-modal\",[],[[\"@position\",\"@close\"],[[32,0,[\"positionToDelete\"]],[30,[36,0],[[32,0],\"positionToDelete\",null],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"set\",\"if\"]}",
    "moduleName": "balance-ember/components/positions-table/columns-provider/template.hbs"
  });

  _exports.default = _default;
});