define("balance-ember/adapters/application", ["exports", "@ember-data/adapter/json-api", "balance-ember/config/environment", "ember-inflector", "ember-simple-auth/mixins/data-adapter-mixin", "ember-window-mock"], function (_exports, _jsonApi, _environment, _emberInflector, _dataAdapterMixin, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    namespace: 'api/v3',
    host: _environment.default.APIRoot,
    currentRelease: null,
    session: Ember.inject.service(),
    headers: Ember.computed('session.{isAuthenticated,data.authenticated.token}', function () {
      let headers = {};

      if (this.session.isAuthenticated) {
        let {
          email,
          token
        } = this.session.data.authenticated;
        headers['Authorization'] = `Token token="${token}", email="${email}"`;
      }

      return headers;
    }),

    ajaxOptions() {
      let options = this._super(...arguments); // Have to do this until https://github.com/emberjs/data/pull/6341 becomes part of a release.
      // Somewhere between 3.9 and 3.12 the `content-type` header started getting sent to the API
      // on GET requests. Rails doesn't like this and freezes up.


      if (options.type === 'GET' || options.type === 'DELETE') {
        delete options.contentType;
      }

      return options;
    },

    pathForType(modelName) {
      let resourcePath = modelName; // We want to use the regular model's api endpoint on forked models so that when this is saved the
      // api response will be for the regular model and our API will update accordingly. This will
      // prevent the UI from showing models we are adding/editing that haven't passed API validation

      if (resourcePath.includes('forked')) {
        resourcePath = resourcePath.replace('forked/', '');
      }

      let underscored = Ember.String.underscore(resourcePath);
      return (0, _emberInflector.pluralize)(underscored);
    },

    handleResponse(_, headers) {
      // Request headers are case-insensitive
      // https://tools.ietf.org/html/rfc2616
      // https://tools.ietf.org/html/rfc7230
      // 'Each header field consists of a name followed by a colon (":") and the field value. Field names are case-insensitive.'
      const headerKey = Ember.A(Object.keys(headers)).find(key => key.toLowerCase() === 'X-Balance-Release'.toLowerCase());
      const apiRelease = headers[headerKey];

      if (this.currentRelease && this.currentRelease !== apiRelease) {
        return _emberWindowMock.default.location.reload(true);
      }

      this.set('currentRelease', apiRelease);
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});