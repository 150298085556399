define("balance-ember/components/budgeting-link/non-architect/no-fy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N/t7t3Ku",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"whitespace-nowrap flex flex-col items-center text-lg px-24 py-18\"],[12],[2,\"\\n  \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"na-fiscal-year\",\"svg-ilustration-icon mb-8\"]],null],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text-center text-material-blue-gray700 tw font-semibold mb-4\"],[12],[2,\"To start using Budget\"],[10,\"br\"],[12],[13],[2,\"contact your administrator\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/budgeting-link/non-architect/no-fy/template.hbs"
  });

  _exports.default = _default;
});