define("balance-ember/components/feedback-form/component", ["exports", "ember-cp-validations", "ember-concurrency", "balance-ember/utils/urls", "ember-window-mock"], function (_exports, _emberCpValidations, _emberConcurrency, _urls, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    userName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'We need your name!'
    }),
    userEmail: (0, _emberCpValidations.validator)('format', {
      type: 'email',
      allowNonTld: true
    }),
    userPersona: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please select your role.'
    }),
    userFeedbackText: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'We need your idea!'
    })
  }, {
    // Global option to prevent any validation from running until we've pressed 'save'
    disabled: Ember.computed.not('model.shouldValidate')
  });

  var _default = Ember.Component.extend(validations, {
    ajax: Ember.inject.service(),
    liveChat: Ember.inject.service(),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    // Form Setup
    availablePersonas: null,
    availableProducts: Ember.computed(function () {
      return ['Any', 'Budget', 'Manage'];
    }),
    // User data
    userName: null,
    userEmail: null,
    userPersona: null,
    userProduct: null,
    userFeedbackText: null,
    // Form State
    shouldValidate: false,
    disabledButtonText: 'Submitting',
    actionButtonText: 'Submit Feedback',

    init() {
      this._super(...arguments);

      this.set('userName', this.session.name);
      this.set('userEmail', this.session.email);
      this.set('availablePersonas', []);
    },

    didInsertElement() {
      this._super(...arguments);

      this.loadPersonasTask.perform();
      this.loadProductRoute();
    },

    loadPersonasTask: (0, _emberConcurrency.task)(function* () {
      return yield this.ajax.request((0, _urls.URLForFeedbackFormPersonas)(), {
        method: 'GET',
        headers: this.headers
      }).then(json => {
        let personas = json.map(function (persona) {
          return {
            name: persona.fields.Name,
            value: persona.id
          };
        }).sortBy('name');
        this.set('availablePersonas', personas);
      }).catch(() => {
        this.notifications.error('Our feedback system isn\'t working right now. Please try again later or let us know via support chat.');
        this.closeModal();
      });
    }),

    loadProductRoute() {
      let routeName = Ember.String.capitalize(this.router.currentRouteName.split('.')[0].replace('ing', '').replace('-v3', ''));
      this.set('userProduct', this.availableProducts.find(element => element === routeName));
    },

    sendFeedbackTask: (0, _emberConcurrency.task)(function* () {
      this.set('shouldValidate', true);
      let currentUrl = _emberWindowMock.default.location.href;

      if (this.validations.isValid) {
        return yield this.ajax.request((0, _urls.URLForFeedbackForm)(), {
          contentType: 'application/json',
          method: 'POST',
          data: {
            'feedback': this.userFeedbackText,
            'email': this.userEmail,
            'name': this.userName,
            'persona': this.userPersona.value,
            'product': this.userProduct,
            'from_url': currentUrl,
            'via': 'Product Feedback Form'
          }
        }).then(() => {
          this.notifications.success('Your feedback was sent successfully!');
          this.closeModal();
        }).catch(() => {
          this.notifications.error('We had a problem submitting your feedback. Please try again later or let us know via support chat.');
          this.closeModal();
        });
      }
    }),
    actions: {
      showSupportChat() {
        Ember.run.next(this, function () {
          this.liveChat.open();
          this.closeModal();
        });
      }

    }
  });

  _exports.default = _default;
});