define("balance-ember/components/manage/print-header/filter/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/get-category-config", "balance-ember/utils/filters/format-display-text"], function (_exports, _component, _getCategoryConfig, _formatDisplayText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component formats and displays a filter
   *
   * @param {object} filter
   * @param {object} categories
   */
  var _default = _component.default.extend({
    filter: null,
    categories: null,
    categoryConfig: Ember.computed('categories.[]', 'filter.category.state', function () {
      return (0, _getCategoryConfig.default)(this.categories, this.filter.category.state);
    }),
    displayText: Ember.computed('categoryConfig', 'categoryType', 'filter', function () {
      return (0, _formatDisplayText.default)(this.filter, this.categoryConfig);
    })
  });

  _exports.default = _default;
});