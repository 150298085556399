define("balance-ember/components/light-table/columns/tooltip-column/component", ["exports", "balance-ember/components/light-table/columns/allovue-column/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Use this component to show a tooltip on top of the table heading
   *
   * @param {column.testSelector}
   * @param {column.tooltipText}
   */
  var _default = _component.default.extend({
    init() {
      this._super(...arguments);

      let attributeBindings = this.attributeBindings;
      let testSelector = this.column.testSelector;
      Ember.set(this, 'attributeBindings', attributeBindings.concat([`testSelector:data-test-${testSelector}-header`]));
    },

    testSelector: Ember.computed('column.testSelectorValue', 'testSelectorValue', function () {
      let testSelectorValue = this.column.testSelectorValue;
      return testSelectorValue ? testSelectorValue : true;
    }),
    tooltipText: Ember.computed.readOnly('column.tooltipText')
  });

  _exports.default = _default;
});