define("balance-ember/components/filter-form/suggestion-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UHX2nGqk",
    "block": "{\"symbols\":[\"suggestion\",\"@filterInput\",\"@id\",\"@noMatchesMessage\",\"@search\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"flex justify-between items-end whitespace-nowrap\"],[12],[2,\"\\n  \"],[8,\"filter-form/multi/radio-options\",[],[[\"@filterInput\",\"@operators\",\"@onupdate\"],[[32,2],[32,0,[\"radioOperators\"]],[32,0,[\"updateOperator\"]]]],null],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"preferences\",\"autocompleteSubstringFilterHint\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"primary-link\",\"xsm\",\"show-hint-button\",[30,[36,0],[[32,0],\"showHint\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      How to search\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mt-8\"],[12],[2,\"\\n  \"],[8,\"ui-form/ui-label\",[],[[\"@style\",\"@for\"],[\"hidden\",[32,3]]],[[\"default\"],[{\"statements\":[[2,\"Enter Text\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"power-select-typeahead\",[],[[\"@selected\",\"@renderInPlace\",\"@noMatchesMessage\",\"@search\",\"@triggerId\",\"@defaultHighlighted\",\"@onkeydown\",\"@onInput\",\"@dropdownClass\",\"@onChange\"],[[32,2,[\"value\"]],true,[32,4],[32,5],[32,3],[32,0,[\"defaultHighlighted\"]],[30,[36,0],[[32,0],\"onkeydown\"],null],[30,[36,0],[[32,0],[32,0,[\"updateValue\"]]],null],\"suggestion-input\",[32,0,[\"onchange\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,6,[[32,1]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"hint\",[],[[\"@preferenceName\"],[\"autocompleteSubstringFilterHint\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,2],[\"global.hints.autocomplete_substring_filter_hint\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"unless\",\"t\"]}",
    "moduleName": "balance-ember/components/filter-form/suggestion-input/template.hbs"
  });

  _exports.default = _default;
});