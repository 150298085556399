define("balance-ember/components/budget/reports/planned-purchases/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r6Pi9jpw",
    "block": "{\"symbols\":[\"T\",\"Controls\",\"Body\",\"Controls\"],\"statements\":[[8,\"table\",[],[[\"@tableState\",\"@columns\",\"@categories\",\"@loadTask\",\"@trackingPrefix\"],[[32,0,[\"tableState\"]],[32,0,[\"columns\"]],[32,0,[\"categories\"]],[32,0,[\"loadReportTask\"]],\"Planned Purchases Report\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,4,[\"filter-button\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,4,[\"pagination\"]],[],[[\"@style\"],[\"tight\"]],null],[2,\"\\n  \"]],\"parameters\":[4]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"filter-bar\"]],[],[[],[]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[\"@id\",\"@rowTestSelector\",\"@noDataIcon\"],[\"planned-purchases\",\"planned-purchase-row\",\"na-budgets\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"loader\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Loading Report...\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,3,[\"noData\"]],[],[[\"@noDataText\"],[\"No planned purchases yet\"]],null],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"pagination\"]],[],[[\"@style\"],[\"tight\"]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/budget/reports/planned-purchases/table/template.hbs"
  });

  _exports.default = _default;
});