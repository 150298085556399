define("balance-ember/components/budget/budgets/form-data/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/budget/budget-user", "balance-ember/utils/writeable-model"], function (_exports, _component, _emberConcurrency, _budgetUser, _writeableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetBudgetsFormDataComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    let promises = [this.loadUsers(), this.loadAmazonBusinessVendor()];

    if (this.args.budget && this.args.budget.id) {
      promises.push(this.loadBudgetPrivileges());
    }

    let [users, amazonVendor, privileges] = yield Ember.RSVP.all(promises);

    if (privileges) {
      Ember.set(this.args.budget, 'budgetPrivileges', privileges);
    }

    this.budgetUsers = new BudgetUserBuilder({
      users,
      privileges
    }).budgetUsers;
    this.amazonVendor = amazonVendor;
  }).drop(), (_class = class BudgetBudgetsFormDataComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "network", _descriptor2, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor3, this);

      _initializerDefineProperty(this, "budget", _descriptor4, this);

      _initializerDefineProperty(this, "budgetUsers", _descriptor5, this);

      _initializerDefineProperty(this, "amazonVendor", _descriptor6, this);

      _initializerDefineProperty(this, "loadData", _descriptor7, this);

      this.loadData.perform();
      let budget = this.args.budget; // Create a new budget if one hasn't been passed in

      if (Ember.isBlank(budget)) {
        budget = this.store.createRecord('budget', {
          fiscalYear: this.availableFiscalYears.current
        });
      }

      this.budget = new _writeableModel.default({
        model: budget,
        store: this.store,
        network: this.network
      });
    }

    loadBudgetPrivileges() {
      return this.store.query('budgetPrivilege', {
        filter: {
          budget_id: {
            eq: this.args.budget.id
          }
        },
        include: 'user',
        sort: 'role,user_name',
        fields: {
          users: 'name'
        },
        paginate: false
      });
    }

    loadUsers() {
      return this.store.query('user', {
        filter: {
          budget_architect: {
            eq: false
          }
        },
        sort: 'name',
        paginate: false
      });
    }

    loadAmazonBusinessVendor() {
      return this.store.query('vendor', {
        filter: {
          amazon_business: true,
          fiscal_year_id: this.availableFiscalYears.current.id
        }
      }).then(vendors => vendors.firstObject);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "network", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "budget", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "budgetUsers", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "amazonVendor", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loadData", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BudgetBudgetsFormDataComponent;

  class BudgetUserBuilder {
    constructor({
      users,
      privileges = []
    } = {}) {
      this.users = users;
      this.privileges = privileges;
    }

    get budgetUsers() {
      return this.privilegeBudgetUsers.concat(this.noAccessUsers);
    }

    get uniquePrivilegeUserIds() {
      return this.privileges.mapBy('user.id').uniq();
    }

    get privilegeBudgetUsers() {
      return this.uniquePrivilegeUserIds.map(id => this.privileges.findBy('user.id', id)).map(privilege => new _budgetUser.default({
        user: privilege.user,
        privilege
      }));
    }

    get noAccessUsers() {
      return this.users.reject(user => this.uniquePrivilegeUserIds.includes(user.id)).map(user => new _budgetUser.default({
        user
      }));
    }

  }
});