define("balance-ember/components/manage/manage-permissions-autocomplete/component", ["exports", "ember-concurrency", "balance-ember/utils/autocomplete-announcement"], function (_exports, _emberConcurrency, _autocompleteAnnouncement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    a11yAnnouncer: Ember.inject.service(),
    store: Ember.inject.service(),
    selected: null,
    segmentType: Ember.computed('budgetLea.segmentTypes', 'category', function () {
      return this.budgetLea.segmentTypes.findBy('name', this.category);
    }),
    selectedText: Ember.computed('selected.{code,name}', function () {
      return `${this.selected.code} - ${this.selected.name}`;
    }),
    searchSegmentsTask: (0, _emberConcurrency.task)(function* (value) {
      yield (0, _emberConcurrency.timeout)(300);
      return yield this.store.query('manage/segment', {
        extra_fields: {
          'manage/segments': 'code_marked,name_marked,parent_name_path'
        },
        filter: {
          hierarchy: {
            eq: {
              search_query: value,
              segment_type_id: this.segmentType.id
            }
          }
        },
        page: {
          size: 6,
          number: 1
        }
      }).then(results => (0, _autocompleteAnnouncement.default)(results, this.a11yAnnouncer)).catch(() => {
        this.notifications.error('Unable to perform search. Please try again later.');
      });
    }),
    actions: {
      onChange(filter, segment) {
        this.set('selected', segment);
        this.changeSegment(filter, segment, this.segmentAccess);
      }

    }
  });

  _exports.default = _default;
});