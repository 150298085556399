define("balance-ember/utils/validatejs/validators/sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validatorsSum;

  function validatorsSum(values, options = {}) {
    let {
      greaterThan,
      lessThanOrEqualTo,
      key
    } = options;
    let sum = values.reduce((acc, value) => acc += parseFloat(value[key]), 0);

    if (Ember.isPresent(lessThanOrEqualTo) && sum > lessThanOrEqualTo) {
      return 'global.validation.rules.sum.less_than_or_equal_to';
    }

    if (Ember.isPresent(greaterThan) && sum <= greaterThan) {
      return 'global.validation.rules.sum.greater_than';
    }

    return null;
  }
});