define("balance-ember/components/budget-notes/view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GpuKjIG0",
    "block": "{\"symbols\":[],\"statements\":[[8,\"text-view-with-links\",[],[[\"@placeholder\",\"@text\"],[[32,0,[\"notesPlaceholderText\"]],[32,0,[\"budget\",\"notes\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/budget-notes/view/template.hbs"
  });

  _exports.default = _default;
});