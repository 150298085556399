define("balance-ember/utils/writeable-models/user", ["exports", "balance-ember/utils/writeable-models/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserWriteableModel extends _basic.default {
    constructor(args) {
      if (!args.paths) {
        args.paths = {
          budgetLeas: true,
          managePermissions: {
            segments: {
              segmentType: true
            },
            budgetLea: true
          },
          budgetPrivileges: {
            budget: true
          }
        };
      }

      super(args);
    }

  }

  _exports.default = UserWriteableModel;
});