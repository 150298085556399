define("balance-ember/components/expense-index/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    features: Ember.inject.service(),
    fiscalYear: Ember.computed.readOnly('availableFiscalYears.current'),
    budget: Ember.computed.readOnly('allocation.budget'),
    positionBudgeting: Ember.computed.readOnly('features.positionBudgeting'),

    /* Allocations may not be able to have position expenses */

    /**
     * Will be passed from child components when they want to delete an expense
     */
    expenseToDelete: null,

    /**
     * Whethere or not to show the modal that can delete an expense
     */
    shouldShowDeleteModal: false,
    expenseSegmentName: Ember.computed('fiscalYear.segmentTypes', function () {
      // TODO -make better
      let segmentTypes = this.fiscalYear.segmentTypes;

      if (segmentTypes) {
        let expenseSegment = segmentTypes.findBy('expenseSegment');

        if (expenseSegment) {
          return expenseSegment.get('name');
        }
      }

      return '';
    }),
    actions: {
      showDeleteModal(expense) {
        Ember.set(this, 'shouldShowDeleteModal', true);
        Ember.set(this, 'expenseToDelete', expense);
      },

      closeDeleteModal() {
        Ember.set(this, 'shouldShowDeleteModal', false);
      }

    }
  });

  _exports.default = _default;
});