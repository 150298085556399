define("balance-ember/components/manage/transactions-column-chart/tooltip-formatter", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = tooltipFormatter;

  function formatAmount(amount) {
    return (0, _numeral.default)(amount).format('$0,0.00');
  }

  function tooltipAmountRow({
    backgroundClasses,
    textClasses = '',
    amount,
    label
  } = {}) {
    return `
    <div class='flex justify-between test-chart-tooltip-${label}'>
      <div class='flex items-center'>
        <div class='w-16 h-16 ${backgroundClasses}'></div>
        <div class='ml-8'>${label}</div>
      </div>
      <div class='${textClasses}'>${formatAmount(amount)}</div>
    </div>
  `;
  }

  function supplementRows(supplements = {}) {
    return Object.keys(supplements).map(function (key) {
      let type = key;
      let value = supplements[key];
      return `
      <div class='flex justify-between' data-test-id='chart-tooltip-supplement-${value}'>
        <div class='flex items-center'>
          <div class='w-16 h-16'></div>
          <div class='ml-8'>${type.titleize()}</div>
        </div>
        <div>${value}</div>
      </div>
    `;
    }).join('');
  }

  function tooltipFormatter() {
    let {
      amount,
      colorClass,
      accountCode,
      supplements,
      transactionDate
    } = this.point;
    let amountRow = tooltipAmountRow({
      amount,
      backgroundClasses: `bg-${colorClass}`,
      label: 'Amount'
    });
    return `\
    <div class='font-sans text-sm text-material-gray700 pt-16 pb-12'> \
      <div class='px-16'> \
        <div class='text-center mb-12' data-test-id='chart-tooltip-title'> \
          ${accountCode}
        </div> \
        <div class='mb-12'> \
          ${transactionDate}
        </div> \
        <div class='mb-12'> \
          ${amountRow} \
        </div> \
        <div class='mb-12'> \
          ${supplementRows(supplements)}
        </div> \
      </div> \
    </div> \
  `;
  }
});