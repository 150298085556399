define("balance-ember/models/metric", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)(),
    measurementType: (0, _model.attr)(),
    budgetLea: (0, _model.belongsTo)('budgetLea', {
      async: false
    }),
    measurements: (0, _model.hasMany)('measurements', {
      async: false
    })
  });

  _exports.default = _default;
});