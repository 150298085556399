define("balance-ember/components/manage/summarize/summarize-path", ["exports", "@microstates/ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SummarizePathNode = _exports.SummarizePath = _exports.SummarizePathBuilder = _exports.AllPathsFinder = _exports.AllSummarizeNodePaths = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AllSummarizeNodePaths {
    constructor(summarizeState) {
      this.summarizeState = summarizeState;
    }
    /**
     * find
     * @return {Array<Array<SummarizePathNode>>} An array of nodes for each path through the tree
     */


    find() {
      return this.paths.map(path => new SummarizePathBuilder(this.summarizeState, path).build());
    }

    get paths() {
      return new AllPathsFinder(this.summarizeState).find();
    }

  }

  _exports.AllSummarizeNodePaths = AllSummarizeNodePaths;

  class AllPathsFinder {
    constructor(summarizeState) {
      this.summarizeState = summarizeState;
    }
    /**
     * find
     * @return {Array<Array<String>>} An array of all paths through the summarize state.
     * Each path is an array of strings, which are the summarize keys
     */


    find(summary = this.summarizeState, parents = []) {
      return summary.summaries.keys.reduce((acc, key) => {
        let newParents = [...parents, key];
        let childKeys = this.find(summary.summaries.entries[key], newParents);
        return [...acc, newParents, ...childKeys];
      }, []);
    }

  }

  _exports.AllPathsFinder = AllPathsFinder;

  class SummarizePathBuilder {
    constructor(tableSummarizeState, valuePath = []) {
      this.tableSummarizeState = tableSummarizeState;
      this.valuePath = valuePath;
    }
    /**
     * build
     * @return {SummarizePath<SummarizePathNode>} An array of node objects for each item in the value path
     */


    build() {
      let path = [new SummarizePathNode({
        summary: this.tableSummarizeState,
        valuePath: this.valuePath,
        level: 0
      })];
      path = this.valuePath.reduce((acc, summaryValue, index) => {
        let parent = acc.lastObject;
        let node = new SummarizePathNode({
          summary: parent.summary.summaries.entries[summaryValue],
          parent,
          value: summaryValue,
          valuePath: this.valuePath,
          level: index + 1
        });
        return acc.concat(node);
      }, path);
      return new SummarizePath(...path);
    }

  }

  _exports.SummarizePathBuilder = SummarizePathBuilder;

  class SummarizePath extends Array {
    get values() {
      return this.mapBy('value').compact();
    }

    toString() {
      return this.values.map(value => value.replace(/\./g, '_')).join('.');
    }

  }
  /**
   * SummarizePathNode
   *
   * @param {Array<String>} valuePath Array of keys for the path
   * @param {number} level position in the path, 0 index.
   * @param {string} value The key in the parent's summarize object
   * @param {SummarizeState} summary The SummarizeState object that has this summary's data
   * @param {SummarizePathNode} parent The parent node if not top level
   */


  _exports.SummarizePath = SummarizePath;

  class SummarizePathNode {
    constructor({
      value = null,
      summary,
      parent = null,
      level,
      valuePath = []
    } = {}) {
      _defineProperty(this, "ROW_TYPES", {
        segment: SegmentRow,
        supplement: SupplementRow,
        quarter: DateRow,
        month: DateRow
      });

      this.summary = summary;
      this.parent = parent;
      this.value = value;
      this.level = level;
      this.valuePath = valuePath;
    }

    get type() {
      return this.category.type;
    }

    get category() {
      if (this._category) {
        return this._category;
      }

      if (!this.parent) {
        return this._category = (0, _ember.valueOf)(this.summary.category);
      }

      if (this.parentSummaryHasCategory) {
        return this._category = (0, _ember.valueOf)(this.parent.summary.category);
      }

      return this._category = (0, _ember.valueOf)(this.parent.category);
    }

    get parentSummaryHasCategory() {
      return Boolean(this.parentSummary && this.parentSummary.category.keys.length);
    }

    get parentSummary() {
      return this.parent.summary;
    }

    get isLastRow() {
      if (Ember.isNone(this.lastRow.summarizeValue)) {
        return false;
      } // if the last row's value for this node is the same as its child value in the path, then we
      // know that this node represents a final row


      return this.lastRow.summarizeValue === this.valuePath[this.level];
    }

    get lastRow() {
      return new this.rowClass(this.lastRowData);
    }

    get lastRowData() {
      return (0, _ember.valueOf)(this.summary.rows).lastObject;
    }

    get isDeepest() {
      return this.level === this.valuePath.length;
    }

    get rowClass() {
      return this.ROW_TYPES[this.rowType] || BasicRow;
    }

    /**
     * get rowType - The row type may be different than the node's category.
     *
     * @return {string} The type of object that represents each row in the summary
     */
    get rowType() {
      return this.isSwitchingCategories ? this.summary.category.entries.type.state : this.type;
    }

    get isSwitchingCategories() {
      return Boolean(this.summary.category.keys.length);
    }

  }

  _exports.SummarizePathNode = SummarizePathNode;

  class BasicRow {
    constructor(data = {}) {
      _defineProperty(this, "summarizeKey", '');

      this.assignDynamicAttributes(data);
    }

    assignDynamicAttributes(data) {
      Object.keys(data).forEach(key => {
        this[key] = data[key];
      });
    }

    get summarizeValue() {
      return this[this.summarizeKey];
    }

  }

  class SegmentRow extends BasicRow {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "summarizeKey", 'segmentId');
    }

  }

  class SupplementRow extends BasicRow {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "summarizeKey", 'supplementValue');
    }

  }

  class DateRow extends BasicRow {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "summarizeKey", 'dateRange');
    }

    get summarizeValue() {
      let value = this[this.summarizeKey];
      return value && value.join(',');
    }

  }
});