define("balance-ember/components/district-admin/tag-table/name-cell/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DistrictAdminTagTableNameCellComponent extends _component.default {
    get errors() {
      // row is a proxy, so have to use get
      // eslint-disable-next-line ember/no-get
      let allErrors = Ember.get(this, 'args.row.serverErrors') || [];
      return allErrors.filter(err => {
        return err.meta.attribute === 'name';
      }).mapBy('detail');
    }

  }

  _exports.default = DistrictAdminTagTableNameCellComponent;
});