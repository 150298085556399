define("balance-ember/components/ui-link-to/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: Ember.inject.service(),
    href: Ember.computed('args.[]', 'router.currentURL', function () {
      return this.router.urlFor(...this.args);
    }),
    isActive: Ember.computed('args.[]', 'router.currentURL', function () {
      return this.router.isActive(...this.args);
    }),
    actions: {
      transition() {
        return this.router[this.replace ? 'replaceWith' : 'transitionTo'](...this.args);
      }

    }
  }).reopenClass({
    positionalParams: 'args'
  });

  _exports.default = _default;
});