define("balance-ember/components/light-table/cells/allovue-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n73TmWNU",
    "block": "{\"symbols\":[\"@column\",\"@tableActions\",\"@table\",\"@extra\",\"@row\"],\"statements\":[[8,\"light-table/cells/default-cell\",[],[[\"@column\",\"@tableActions\",\"@table\",\"@extra\",\"@row\",\"@value\",\"@rawValue\",\"@cellId\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[32,0,[\"value\"]],[32,0,[\"rawValue\"]],[32,0,[\"element\",\"id\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/light-table/cells/allovue-cell/template.hbs"
  });

  _exports.default = _default;
});