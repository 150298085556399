define("balance-ember/components/budget-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I9+mhsQC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"notes-display flex flex-col\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[32,0,[\"notesStateComponentName\"]]],[[\"budget\",\"isEditingNotes\",\"isSavingNotes\",\"notesPlaceholderText\",\"editNotes\",\"cancelEditNotes\",\"saveNotes\"],[[32,0,[\"writeableBudget\"]],[32,0,[\"isEditingNotes\"]],[32,0,[\"isSavingNotes\"]],[32,0,[\"notesPlaceholderText\"]],[30,[36,0],[[32,0],\"editNotes\"],null],[30,[36,0],[[32,0],\"cancelEditNotes\"],null],[30,[36,0],[[32,0],\"saveNotes\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\"]}",
    "moduleName": "balance-ember/components/budget-notes/template.hbs"
  });

  _exports.default = _default;
});