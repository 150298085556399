define("balance-ember/templates/budgeting/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8t1LBkic",
    "block": "{\"symbols\":[],\"statements\":[[8,\"container\",[],[[\"@style\"],[\"tabbed-budget\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"container\",[],[[\"@style\"],[\"horizontal centered page\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"budget/page-header\",[],[[\"@title\",\"@hideFiscalYearBreadcrumb\",\"@showBudgetTotals\",\"@breadcrumbs\"],[[32,0,[\"fiscalYear\",\"name\"]],true,true,[30,[36,2],[[30,[36,1],null,[[\"displayName\",\"routeName\"],[[30,[36,0],[\"budget.fiscal_year.index.page_title\"],null],\"fiscal-years\"]]]],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"container\",[],[[\"@style\"],[\"horizontal centered page padding-all\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"budget-page-tab-content\"],[12],[2,\"\\n    \"],[8,\"budget/vendors/banner\",[],[[],[]],null],[2,\"\\n\\n    \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"hash\",\"array\",\"-outlet\",\"component\"]}",
    "moduleName": "balance-ember/templates/budgeting/overview.hbs"
  });

  _exports.default = _default;
});