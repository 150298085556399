define("balance-ember/components/budget/expense-table/edit-strategies/errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U14DDZl/",
    "block": "{\"symbols\":[\"error\",\"&default\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"errors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/budget/expense-table/edit-strategies/errors/template.hbs"
  });

  _exports.default = _default;
});