define("balance-ember/components/dashboard-table/pagination-buttons/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param {number} currentPage
   * @param {number} totalPages
   * @param {number} totalRecords
   * @param {boolean} shouldShowPagination
   * @param {function} setPage
   * @param {number} min
   * @param {number} max
   */
  var _default = Ember.Component.extend({
    actions: {
      increasePage() {
        this.setPage(this.paginationState.page.state + 1);
      },

      decreasePage() {
        this.setPage(this.paginationState.page.state - 1);
      }

    }
  });

  _exports.default = _default;
});