define("balance-ember/templates/budgeting/budget/supplemental-pay-expenses/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hQCq1FtQ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"allocation\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"budget/supplemental-pay-expense-form\",[],[[\"@budgetId\",\"@formTitle\",\"@actionButtonText\",\"@disabledButtonText\",\"@allocation\",\"@doneAction\"],[[32,0,[\"model\"]],\"Create Supplemental Pay\",\"Create Supplemental Pay\",\"Creating\",[32,0,[\"allocation\"]],[30,[36,1],[[32,0],[30,[36,0],[[32,0,[\"allocation\"]]],null],null],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"budget/allocation-picker-form\",[],[[\"@formTitle\",\"@actionButtonText\",\"@budgetId\",\"@cancelRoute\",\"@chooseAllocation\"],[\"Create Supplemental Pay\",\"Fund using this Allocation\",[32,0,[\"model\"]],\"budgeting.budget.supplemental-pay-expenses\",[30,[36,1],[[32,0],[30,[36,0],[[32,0,[\"allocation\"]]],null]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\",\"if\"]}",
    "moduleName": "balance-ember/templates/budgeting/budget/supplemental-pay-expenses/new.hbs"
  });

  _exports.default = _default;
});