define("balance-ember/models/allocation", ["exports", "@ember-data/model", "balance-ember/utils/money-math"], function (_exports, _model, _moneyMath) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.computedProperties = _exports.schema = void 0;
  const schema = {
    budget: (0, _model.belongsTo)('budget', {
      async: false
    }),
    segments: (0, _model.hasMany)('segment', {
      async: false
    }),
    nonPersonnelExpenses: (0, _model.hasMany)('non-personnel-expense', {
      async: false
    }),
    positionExpenses: (0, _model.hasMany)('position-expense', {
      async: false
    }),
    supplementalPayExpenses: (0, _model.hasMany)('supplemental-pay-expense', {
      async: false
    }),
    purchaseOrders: (0, _model.hasMany)('purchaseOrder', {
      async: false
    }),
    name: (0, _model.attr)('string'),
    salaryType: (0, _model.attr)('string', {
      defaultValue: 'Estimated'
    }),
    allocatedAmount: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    plannedAmount: (0, _model.attr)('number', {
      readOnly: true
    }),
    remainingAmount: (0, _model.attr)('number', {
      readOnly: true
    }),
    personnelPlannedAmount: (0, _model.attr)('number', {
      readOnly: true
    }),
    supplementalPlannedAmount: (0, _model.attr)('number', {
      readOnly: true
    }),
    nonPersonnelPlannedAmount: (0, _model.attr)('number', {
      readOnly: true
    }),
    expanded: (0, _model.attr)('boolean', {
      defaultValue: false,
      readOnly: true
    }),
    checked: (0, _model.attr)('boolean', {
      defaultValue: false,
      readOnly: true
    }),
    canHavePositionExpenses: (0, _model.attr)('boolean', {
      readOnly: true
    }),
    locked: (0, _model.attr)('boolean'),
    editable: (0, _model.attr)('boolean', {
      readOnly: true
    })
  };
  _exports.schema = schema;
  const computedProperties = {
    sortedSegments: Ember.computed('segments.[]', function () {
      return this.segments.sortBy('segmentTypeDisplayOrder');
    }),
    nonPersonnelExpensesCount: Ember.computed('allocation', function () {
      return this.hasMany('nonPersonnelExpenses').ids().length;
    }),
    positionExpensesCount: Ember.computed('allocation', function () {
      return this.hasMany('positionExpenses').ids().length;
    }),
    supplementalExpensesCount: Ember.computed('allocation', function () {
      return this.hasMany('supplementalPayExpenses').ids().length;
    }),
    expenseCount: Ember.computed('nonPersonnelExpenses', 'nonPersonnelExpensesCount', 'positionExpenses', 'positionExpensesCount', 'supplementalExpensesCount', function () {
      return this.nonPersonnelExpensesCount + this.positionExpensesCount + this.supplementalExpensesCount;
    }),
    hasNegativeRemaining: Ember.computed('remainingAmount', function () {
      return (0, _moneyMath.isNegative)(this.remainingAmount);
    }),
    isEditable: Ember.computed.readOnly('editable')
  };
  _exports.computedProperties = computedProperties;

  var _default = _model.default.extend(schema, computedProperties);

  _exports.default = _default;
});