define("balance-ember/components/ui-modal/modal-body/warning/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VTFziQuk",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-material-red500\"],[12],[2,\"\\n  \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"error-exclamation\",\"w-48 h-48 fill-current\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"text-4xl font-bold\"],[12],[2,\"\\n  Are You Sure?\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/ui-modal/modal-body/warning/template.hbs"
  });

  _exports.default = _default;
});