define("balance-ember/types/basic-filter", ["exports", "@microstates/ember", "balance-ember/types/filter-operator"], function (_exports, _ember, _filterOperator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FilterCounter = _exports.FilterSerializer = _exports.FilterValidator = _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BasicFilter {
    constructor() {
      _defineProperty(this, "category", String);

      _defineProperty(this, "operators", [_filterOperator.default]);

      _defineProperty(this, "removable", (0, _ember.create)(Boolean, true));

      _defineProperty(this, "type", (0, _ember.create)(String, 'text'));
    }

    get isValid() {
      return new FilterValidator(this).validate();
    }

    get serialized() {
      return this.serializer.serialize();
    }

    get serializer() {
      return new FilterSerializer(this);
    }

    get firstOperator() {
      return [...this.operators][0];
    }

    get totalFilterValues() {
      return new FilterCounter(this).count();
    }

    updateOperatorValue(index, value) {
      if (this.operators.length) {
        let operator = [...this.operators][index];
        return operator.updateValue(value);
      }

      return this.operators.push({
        value
      });
    }

  }

  _exports.default = BasicFilter;

  class FilterValidator {
    constructor(filter) {
      _defineProperty(this, "filter", void 0);

      this.filter = filter;
    }

    validate() {
      let validOperators = [...this.filter.operators].filterBy('isValid');
      return Boolean(validOperators.length > 0 && this.filter.category.state);
    }

  }

  _exports.FilterValidator = FilterValidator;

  class FilterSerializer {
    constructor(filter) {
      _defineProperty(this, "filter", void 0);

      this.filter = filter;
    }

    serialize() {
      return [...this.filter.operators].reduce((data, operator) => {
        return data.concat(this.serializeOperator(operator));
      }, []);
    }

    get categoryValue() {
      return this.filter.category.state;
    }

    serializeOperator(operator) {
      let category = this.categoryValue;
      return [...operator.filterValues].map(filterValue => {
        return {
          values: [this.serializeFilterValue((0, _ember.valueOf)(filterValue))],
          match: operator.value.state,
          category
        };
      });
    }

    serializeFilterValue(filterValue) {
      return filterValue.trim();
    }

  }

  _exports.FilterSerializer = FilterSerializer;

  class FilterCounter {
    constructor(filter) {
      _defineProperty(this, "filter", void 0);

      this.filter = filter;
    }

    count() {
      return [...this.filter.operators].reduce((total, operator) => {
        return this.countValuesForOperator(operator) + total;
      }, 0);
    }

    countValuesForOperator(operator) {
      return operator.filterValues.length;
    }

  }

  _exports.FilterCounter = FilterCounter;
});