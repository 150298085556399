define("balance-ember/types/table", ["exports", "balance-ember/types/sort", "balance-ember/types/pagination", "balance-ember/types/filter", "@microstates/ember"], function (_exports, _sort, _pagination, _filter, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Table {
    constructor() {
      _defineProperty(this, "filters", [_filter.default]);

      _defineProperty(this, "pagination", _pagination.default);

      _defineProperty(this, "sorts", [_sort.default]);

      _defineProperty(this, "defaultSort", _sort.default);

      _defineProperty(this, "rows", [_ember.Any]);
    }

    initialize(initialState = {}) {
      return this.initializeSorts(initialState);
    }

    initializeSorts(initialState) {
      let noSorts = !initialState.sorts || !initialState.sorts.length;

      if (noSorts && initialState.defaultSort) {
        return this.sorts.set([initialState.defaultSort]);
      }

      return this;
    }

    get firstSort() {
      return [...this.sorts][0];
    }

    get serializedFilters() {
      return [...this.filters].mapBy('serialized').reduce((acc, curr) => {
        return acc.concat(curr);
      }, []);
    }

    get optionalFilters() {
      let filters = [...this.filters].filterBy('removable.state').map(filter => (0, _ember.valueOf)(filter));
      return (0, _ember.create)([_filter.default], filters);
    }

    get requiredFilters() {
      let filters = [...this.filters].filterBy('removable.state', false).map(filter => (0, _ember.valueOf)(filter));
      return (0, _ember.create)([_filter.default], filters);
    }

    get hasFullPage() {
      return this.rows.length === this.pagination.itemsPerPage.state;
    }

    get apiQuery() {
      let filters = this.serializedFilters;
      let query = {
        page: {
          number: this.pagination.page.state,
          size: this.pagination.itemsPerPage.state
        }
      };

      if (filters.length) {
        query.filter = filters;
      }

      if (this.firstSort) {
        query.sort = {
          type: this.firstSort.type.state || undefined,
          column: this.firstSort.column.state,
          dir: this.firstSort.dir.state
        };
      }

      return query;
    }

    resetPagination() {
      return this.pagination.page.set(1);
    }

    updateSortColumn(index, column) {
      let sorts = (0, _ember.valueOf)(this.sorts).toArray();
      sorts = sorts.replace(index, 1, [{
        column,
        dir: 'asc'
      }]);
      return this.sorts.set(sorts).resetPagination();
    }

    updateSortDir(index, dir) {
      let sorts = (0, _ember.valueOf)(this.sorts).toArray();
      let sort = sorts[index];
      sorts = sorts.replace(index, 1, [Object.assign({}, sort, {
        dir
      })]);
      return this.sorts.set(sorts).resetPagination();
    }

    updateSortType(index, type) {
      let sorts = (0, _ember.valueOf)(this.sorts).toArray();
      let sort = sorts[index];
      sorts = sorts.replace(index, 1, [Object.assign({}, sort, {
        type
      })]); // Pagination should reset when sort type changes

      return this.sorts.set(sorts).resetPagination();
    } // Use this transition for replacing the entire list of filters. This will replace all the filter ids and therefore allows you to not have to keep track of which index the user added or removed.


    updateFilters(filters) {
      return this.filters.set(filters).resetPagination();
    } // Use this transition for cases when you need to update a specific filter


    updateFilter(index, filter) {
      if (filter.isValid) {
        // copy the array with toArray
        let filters = (0, _ember.valueOf)(this.filters).toArray().replace(index, 1, [(0, _ember.valueOf)(filter)]);
        return this.updateFilters(filters);
      }

      return this;
    }

    reset() {
      return this.rows.clear().clearFilters().sorts.clear().resetPagination();
    }

    clearFilters() {
      let nonRemovableFilters = [...this.filters].filter(filter => !filter.removable.state).map(filter => (0, _ember.valueOf)(filter));
      return this.updateFilters(nonRemovableFilters);
    }

  }

  _exports.default = Table;
});