define("balance-ember/templates/budgeting/benefits-packages/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LbCRahOH",
    "block": "{\"symbols\":[],\"statements\":[[8,\"benefits-package-form\",[],[[\"@benefitsPackage\",\"@buttonText\",\"@disabledText\",\"@formTitle\"],[[32,0,[\"model\"]],\"Create Benefits Package\",\"Creating\",\"Create a Benefits Package\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/budgeting/benefits-packages/new.hbs"
  });

  _exports.default = _default;
});