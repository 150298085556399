define("balance-ember/utils/position-totals", ["exports", "balance-ember/utils/sum-benefits", "balance-ember/utils/position-expense-totals"], function (_exports, _sumBenefits, _positionExpenseTotals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PositionTotals {
    constructor({
      position
    }) {
      this.position = position;
    }

    get cost() {
      return this.positionExpenses.reduce((acc, curr) => {
        let expenseCost = new _positionExpenseTotals.default({
          expense: curr
        }).adjustedTotal;
        return acc + expenseCost;
      }, 0);
    }

    get remainingPercentage() {
      return 100 - this.fundedPercentage;
    }

    get remainingAmount() {
      let calculationMethods = {
        Actual: 'actualRemainingAmount',
        Estimated: 'estimatedRemainingAmount',
        Mixed: 'mixedRemainingAmount'
      };
      return this[calculationMethods[this.remainingAmountCalculationMethod]];
    }

    get remainingAmountCalculationMethod() {
      let salaryTypes = this.positionExpenses.map(expense => expense.allocation.salaryType).uniq();
      return salaryTypes.length === 1 ? salaryTypes[0] : 'Mixed';
    }

    get actualRemainingAmount() {
      let actualCost = this.employee ? this.employeeCost : this.jobTypeCost;
      return actualCost * this.fte - this.cost;
    }

    get estimatedRemainingAmount() {
      return this.jobTypeCost * this.fte - this.cost;
    }

    get mixedRemainingAmount() {
      return null;
    }

    get fundedPercentage() {
      return this.positionExpenses.reduce((acc, curr) => {
        return Number(curr.percentage) + Number(acc);
      }, 0);
    }

    get remainingFTE() {
      return this.fte - this.fteFunded;
    }

    get fteFunded() {
      return this.positionExpenses.reduce((acc, curr) => {
        return acc + this.fte * curr.percentage / 100;
      }, 0);
    }

    get positionExpenses() {
      return this.position.positionExpenses ?? [];
    }

    get fte() {
      return this.position.fte ?? 0;
    }

    get employeeCost() {
      var _this$employee;

      return ((_this$employee = this.employee) === null || _this$employee === void 0 ? void 0 : _this$employee.budgetedSalary) + this.employeeEstimatedBenefits;
    }

    get employeeEstimatedBenefits() {
      var _this$jobType, _this$employee2;

      return (0, _sumBenefits.default)((_this$jobType = this.jobType) === null || _this$jobType === void 0 ? void 0 : _this$jobType.benefitsPackage, (_this$employee2 = this.employee) === null || _this$employee2 === void 0 ? void 0 : _this$employee2.budgetedSalary);
    }

    get jobTypeCost() {
      return this.jobType ? this.jobType.averageBaseSalary + this.jobTypeBenefits : 0;
    }

    get jobTypeBenefits() {
      var _this$jobType2, _this$jobType3;

      return (0, _sumBenefits.default)((_this$jobType2 = this.jobType) === null || _this$jobType2 === void 0 ? void 0 : _this$jobType2.benefitsPackage, (_this$jobType3 = this.jobType) === null || _this$jobType3 === void 0 ? void 0 : _this$jobType3.averageBaseSalary);
    }

    get jobType() {
      return this.position.jobType;
    }

    get employee() {
      return this.position.employee;
    }

  }

  _exports.default = PositionTotals;
});