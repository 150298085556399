define("balance-ember/transforms/snake-cased-array", ["exports", "@ember-data/serializer/transform", "lodash.map", "lodash.transform", "lodash.snakecase", "lodash.camelcase"], function (_exports, _transform, _lodash, _lodash2, _lodash3, _lodash4) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize(serialized) {
      return (0, _lodash.default)(serialized, hash => {
        return (0, _lodash2.default)(hash, (result, value, key) => {
          result[(0, _lodash4.default)(key)] = value;
        }, {});
      });
    },

    serialize(deserialized) {
      return (0, _lodash.default)(deserialized, hash => {
        return (0, _lodash2.default)(hash, (result, value, key) => {
          result[(0, _lodash3.default)(key)] = value;
        }, {});
      });
    }

  });

  _exports.default = _default;
});