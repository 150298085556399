define("balance-ember/services/ajax", ["exports", "ember-ajax/services/ajax", "balance-ember/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    authenticatedSession: Ember.computed.readOnly('session.session.content.authenticated'),

    /**
     * Our primary api call. Then in our api requests we can simply pass in the endpoint we need.
     * Not using the `namespace` property, because we have serveral different namespaces we need to
     * use.
     */
    host: _environment.default.APIRoot,
    token: Ember.computed.readOnly('session.token'),
    email: Ember.computed.readOnly('session.email'),
    headers: Ember.computed('token', 'email', function () {
      if (this.token && this.email) {
        return {
          'Authorization': `Token token="${this.token}", email="${this.email}"`
        };
      }

      return {};
    }),

    getAuthString() {
      return `?auth_token=${this.token}&auth_email=${encodeURIComponent(this.email)}`;
    }

  });

  _exports.default = _default;
});