define("balance-ember/components/budget/allocation-picker-form/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    formTitle: '',
    // @Param
    actionButtonText: '',
    // @Param
    cancelRoute: '',

    // @Param
    chooseAllocation() {},

    // @Param
    budgetId: null,
    // @Param
    allocation: null,

    init() {
      this._super(...arguments);

      this.fetchAllocationsTask.perform();
    },

    allocationHasPersonnelSegment: Ember.computed('allocation.segments', function () {
      if (!Ember.isEmpty(this.allocation) && !Ember.isEmpty(this.allocation.segments)) {
        return this.allocation.segments.findBy('personnelSegment', true) !== undefined;
      }

      return false;
    }),
    isSubmitDisabled: Ember.computed('allocation.{editable,locked}', 'allocationHasPersonnelSegment', function () {
      return Ember.isEmpty(this.allocation) || !this.allocation.editable || this.allocationHasPersonnelSegment;
    }),
    fetchAllocationsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('allocation', {
        include: 'segments.segment_type,budget',
        extra_fields: {
          allocations: 'editable'
        },
        filter: {
          budget_id: this.budgetId
        },
        paginate: false
      });
    }),
    actions: {
      cancel() {
        this.router.transitionTo(this.cancelRoute);
      }

    }
  });

  _exports.default = _default;
});