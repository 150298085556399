define("balance-ember/components/manage/transaction-status-cell/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TransactionStatusCell extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "actualStyle", 'text-material-indigo600');

      _defineProperty(this, "budgetStyle", 'text-material-amber900');

      _defineProperty(this, "encumberedStyle", 'text-material-cyan600');
    }

    get statusStyle() {
      let statusStyle;

      switch (this.args.value) {
        case 'actual':
          statusStyle = this.actualStyle;
          break;

        case 'budget':
          statusStyle = this.budgetStyle;
          break;

        case 'encumbrance':
          statusStyle = this.encumberedStyle;
          break;
      }

      return statusStyle;
    }

  }

  _exports.default = TransactionStatusCell;
});