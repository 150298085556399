define("balance-ember/components/table/actions-popover/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gbz0C8JV",
    "block": "{\"symbols\":[\"StackItem\",\"tableAction\",\"@popover\",\"@row\"],\"statements\":[[10,\"div\"],[14,0,\"p-8\"],[12],[2,\"\\n  \"],[8,\"layout/stack\",[],[[\"@space\"],[\"s\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"tableActions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,2,[\"component\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,1],[[32,2,[\"component\"]]],[[\"row\",\"tableAction\",\"onclick\"],[[32,4],[32,2],[32,3,[\"hide\"]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[8,\"button\",[],[[\"@style\",\"@disabled\",\"@testId\",\"@onclick\"],[\"btn-primary-link\",[32,2,[\"disabled\"]],[32,2,[\"testId\"]],[30,[36,0],[[32,0,[\"onActionClick\"]],[32,2]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"label\"]]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"component\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/table/actions-popover/template.hbs"
  });

  _exports.default = _default;
});