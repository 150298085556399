define("balance-ember/templates/budgeting/employees/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qCUg015i",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"employee-form-data\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"employee-form\",[],[[\"@employee\",\"@formTitle\",\"@disabledButtonText\",\"@actionButtonText\",\"@employeeStatuses\"],[[32,0,[\"model\"]],\"Create Employee\",\"Creating\",\"Create Employee\",[32,1,[\"employeeStatuses\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/budgeting/employees/new.hbs"
  });

  _exports.default = _default;
});