define("balance-ember/components/app-nav/profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rHphVJjI",
    "block": "{\"symbols\":[\"StackItem\"],\"statements\":[[10,\"div\"],[14,1,\"user-avatar\"],[15,0,[31,[\"user-avatar \",[30,[36,0],[[32,0,[\"isShowing\"]],\"active\"],null]]]],[15,\"title\",[32,0,[\"session\",\"name\"]]],[12],[2,\"\\n  \"],[11,\"span\"],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"trackClick\"]],\"User Initials Clicked\"],null]],null],[12],[1,[32,0,[\"session\",\"initials\"]]],[13],[2,\"\\n\"],[6,[37,3],[[32,0,[\"isShowing\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tooltip\",[],[[\"@text\"],[\"Profile\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"popover\",[],[[\"@onChange\"],[[30,[36,4],[[32,0],\"isShowing\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"app-nav/menu\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"layout/stack\",[],[[\"@space\"],[\"s\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"icon-button\",[[24,0,\"text-lg\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"trackClick\"]],\"Manage Profile Selected\"],null]],null]],[[\"@style\",\"@icon\",\"@route\",\"@buttonText\",\"@showButtonText\"],[\"btn-secondary-link\",\"profile\",[30,[36,5],[\"users/profile\",\"account-info\"],null],\"Profile\",true]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"icon-button\",[[24,0,\"text-lg\"],[4,[38,2],[\"click\",[32,0,[\"logout\"]]],null]],[[\"@style\",\"@icon\",\"@buttonText\",\"@showButtonText\"],[\"btn-secondary-link\",\"log-out\",\"Log Out\",true]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\",\"unless\",\"set\",\"array\"]}",
    "moduleName": "balance-ember/components/app-nav/profile/template.hbs"
  });

  _exports.default = _default;
});