define("balance-ember/models/budget", ["exports", "@ember-data/model", "balance-ember/utils/budget/budget-user"], function (_exports, _model, _budgetUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = _exports.budgetComputedProperties = void 0;
  const budgetComputedProperties = {
    approvers: Ember.computed.filterBy('budgetUsers', 'role', 'approver'),
    builders: Ember.computed.filterBy('budgetUsers', 'role', 'builder'),
    viewers: Ember.computed.filterBy('budgetUsers', 'role', 'viewer'),
    purchasingApprovers: Ember.computed.filterBy('budgetUsers', 'role', 'purchasing_approver'),
    budgetUsers: Ember.computed.map('budgetPrivileges', function (privilege) {
      return new _budgetUser.default({
        user: privilege.user,
        privilege
      });
    }),
    approvedBy: Ember.computed.mapBy('approvals', 'approver'),
    approvedByIds: Ember.computed.mapBy('approvedBy', 'id'),
    isApproved: Ember.computed.equal('approvalState', 'approved'),
    hasApprovers: Ember.computed.gt('approvers.length', 0),
    doesNotHaveApprovers: Ember.computed.not('hasApprovers'),
    isEditable: Ember.computed.readOnly('editable')
  };
  _exports.budgetComputedProperties = budgetComputedProperties;
  const schema = {
    fiscalYear: (0, _model.belongsTo)('fiscalYear', {
      async: false
    }),
    allocations: (0, _model.hasMany)('allocation', {
      async: false
    }),
    positions: (0, _model.hasMany)('position', {
      async: false
    }),
    docs: (0, _model.hasMany)('budgetDoc', {
      async: false
    }),
    budgetPrivileges: (0, _model.hasMany)('budgetPrivileges', {
      async: false
    }),
    approvals: (0, _model.hasMany)('approval', {
      async: false
    }),
    userRole: (0, _model.attr)('string', {
      readOnly: true
    }),
    name: (0, _model.attr)('string'),
    notes: (0, _model.attr)('string'),
    approvalState: (0, _model.attr)('string', {
      readOnly: true
    }),
    authorizedApprovalEvents: (0, _model.attr)('array', {
      readOnly: true,
      defaultValue: () => []
    }),
    areAllPositionsFunded: (0, _model.attr)('boolean', {
      readOnly: true
    }),
    allocatedAmount: (0, _model.attr)('number', {
      readOnly: true,
      defaultValue: 0
    }),
    amazonGroupId: (0, _model.attr)('string'),
    category: (0, _model.attr)('string'),
    plannedAmount: (0, _model.attr)('number', {
      defaultValue: 0,
      readOnly: true
    }),
    remainingAmount: (0, _model.attr)('number', {
      defaultValue: 0,
      readOnly: true
    }),
    percentRemaining: (0, _model.attr)('number', {
      defaultValue: 0,
      readOnly: true
    }),
    editable: (0, _model.attr)('boolean', {
      readOnly: true
    })
  };
  _exports.schema = schema;

  var _default = _model.default.extend(schema, budgetComputedProperties);

  _exports.default = _default;
});