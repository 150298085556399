define("balance-ember/components/budget/allocation-segments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l2DfEY6u",
    "block": "{\"symbols\":[\"segment\"],\"statements\":[[10,\"div\"],[14,0,\"allocation-segments\"],[12],[2,\"\\n  \"],[10,\"header\"],[12],[2,\"\\n    \"],[8,\"button\",[],[[\"@style\",\"@onclick\",\"@testId\"],[\"btn-primary-link\",[30,[36,0],[[32,0],\"toggleSegments\"],null],\"toggle-allocation-segments\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,0,[\"showHideLabel\"]]],[2,\" Segments\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"segments-container box-style\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"segments\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"segment-row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"segment-type-and-code\"],[12],[2,\"\\n          \"],[10,\"span\"],[14,0,\"segment-type\"],[12],[2,\"\\n            \"],[1,[32,1,[\"segmentTypeName\"]]],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"span\"],[14,0,\"segment-code code\"],[12],[2,\"\\n            \"],[1,[32,1,[\"code\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"segment-name\"],[12],[2,\"\\n          \"],[1,[32,1,[\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/budget/allocation-segments/template.hbs"
  });

  _exports.default = _default;
});