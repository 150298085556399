define("balance-ember/components/positions-table/nested-table/body/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a2mlJCe1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"tr\"],[14,0,\"border-t border-material-blue-gray100\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/positions-table/nested-table/body/row/template.hbs"
  });

  _exports.default = _default;
});