define("balance-ember/routes/budgeting/fiscal-year/purchase-orders/view-report/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    model() {
      let {
        allocation,
        vendor
      } = this.modelFor('budgeting.fiscal-year.purchase-orders.view-report');
      return this.store.createRecord('purchaseOrder', {
        allocation,
        vendor
      });
    }

    setupController(controller) {
      var _nonPersonnelExpenses, _nonPersonnelExpenses2, _nonPersonnelExpenses3;

      super.setupController(...arguments);
      let {
        nonPersonnelExpenses
      } = this.modelFor('budgeting.fiscal-year.purchase-orders.view-report');
      controller.set('amount', nonPersonnelExpenses === null || nonPersonnelExpenses === void 0 ? void 0 : (_nonPersonnelExpenses = nonPersonnelExpenses.meta) === null || _nonPersonnelExpenses === void 0 ? void 0 : (_nonPersonnelExpenses2 = _nonPersonnelExpenses.stats) === null || _nonPersonnelExpenses2 === void 0 ? void 0 : (_nonPersonnelExpenses3 = _nonPersonnelExpenses2.amount) === null || _nonPersonnelExpenses3 === void 0 ? void 0 : _nonPersonnelExpenses3.sum);
    }

  }

  _exports.default = _default;
});