define("balance-ember/components/table/controls/pagination/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    media: Ember.inject.service(),
    style: 'full',
    currentLinkBackgroundColor: 'material-gray500',
    shouldShowFullLinks: Ember.computed('media.{matches.[],isDesktop,isXl}', 'style', function () {
      if (this.style.includes('tight')) {
        return this.media.isXl;
      }

      return this.media.isDesktop || this.media.isXl;
    }),
    linksWidth: Ember.computed('shouldShowFullLinks', function () {
      return this.shouldShowFullLinks ? 5 : 1;
    }),

    afterGoToPage() {},

    afterSetPerPage() {}

  });

  _exports.default = _default;
});