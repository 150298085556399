define("balance-ember/templates/budgeting/budget/supplemental-pay-expenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "98j9gd9F",
    "block": "{\"symbols\":[],\"statements\":[[8,\"budget/page-header/budgeting\",[],[[\"@budget\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\\n\"],[8,\"container\",[],[[\"@style\"],[\"horizontal centered page padding-all\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"model\",\"isEditable\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"button\",[],[[\"@route\",\"@style\",\"@testId\"],[[30,[36,0],[\"budgeting.budget.supplemental-pay-expenses.new\"],null],\"btn-primary\",\"supplemental-pay-add\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Add Supplemental Pay\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mt-16\"],[12],[2,\"\\n    \"],[8,\"budget/supplemental-pay-expenses-table\",[],[[\"@budgetId\",\"@tableState\",\"@categories\"],[[32,0,[\"model\",\"id\"]],[32,0,[\"tableState\"]],[32,0,[\"categories\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"array\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "balance-ember/templates/budgeting/budget/supplemental-pay-expenses.hbs"
  });

  _exports.default = _default;
});