define("balance-ember/controllers/manage/overview", ["exports", "balance-ember/mixins/table-controller", "balance-ember/mixins/segment-controller", "balance-ember/mixins/account-filter-controller", "balance-ember/classes/table-serializer"], function (_exports, _tableController, _segmentController, _accountFilterController, _tableSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const mixins = [_tableController.default, _segmentController.default, _accountFilterController.default];

  var _default = Ember.Controller.extend(...mixins, {
    currentBudgetLeaId: Ember.computed.readOnly('availableBudgetLeas.current.id'),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    availableBudgetLeas: Ember.inject.service(),
    selectedBudgetLea: Ember.computed.readOnly('availableBudgetLeas.current'),
    currentBookmarks: Ember.computed('model.bookmarks', 'serializedTableState.filter.budget_lea_id.eq', 'tableState', function () {
      return this.model.bookmarks.filter(bookmark => bookmark.budgetLeas.find(budgetLea => budgetLea.id === this.serializedTableState.filter.budget_lea_id.eq));
    }),
    actions: {
      updateBudgetLea(budgetLea) {
        this.set('model.budgetLea', budgetLea);
        this.transitionToRoute(this.router.currentRouteName, {
          'queryParams': {
            query: undefined
          }
        });
      }

    },
    serializedTableState: Ember.computed('tableState', function () {
      return new _tableSerializer.default(this.tableState).apiQuery;
    })
  });

  _exports.default = _default;
});