define("balance-ember/components/positions-table/nested-table/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nRT+BODE",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"tbody\"],[24,0,\"bg-white\"],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"Row\",\"Cell\"],[[30,[36,0],[\"positions-table/nested-table/body/row\"],null],[30,[36,0],[\"positions-table/nested-table/body/cell\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/positions-table/nested-table/body/template.hbs"
  });

  _exports.default = _default;
});