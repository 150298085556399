define("balance-ember/components/ui-modal/modal-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zJmrCAie",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"section\"],[14,0,\"mb-16 pb-16 border-material-gray200 not-last:border-b\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"mx-32 mb-8 font-bold\"],[12],[1,[32,0,[\"heading\"]]],[13],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/ui-modal/modal-section/template.hbs"
  });

  _exports.default = _default;
});