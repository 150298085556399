define("balance-ember/components/budget/purchase-orders/existing-view/amazon-purchases-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZodiRlUm",
    "block": "{\"symbols\":[\"T\",\"Controls\",\"Body\",\"row\",\"Controls\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[8,\"table\",[],[[\"@tableState\",\"@columns\",\"@loadTask\",\"@trackingPrefix\"],[[32,0,[\"tableState\"]],[32,0,[\"columns\"]],[32,0,[\"loadTask\"]],\"Amazon Purchases\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"      \"],[8,[32,5,[\"pagination\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[5]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"filter-bar\"]],[],[[],[]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"body\"]],[],[[\"@canExpand\",\"@multiRowExpansion\",\"@rowTestSelector\",\"@id\",\"@noDataIcon\"],[true,true,\"amazon-purchases-row\",\"amazon-purchases-table\",\"na-box\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"loader\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Loading Amazon Purchases....\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,3,[\"noData\"]],[],[[\"@noDataText\"],[\"No purchases yet.\"]],null],[2,\"\\n      \"],[8,[32,3,[\"expanded-row\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"budget/purchase-orders/existing-view/amazon-purchases-table/expanded-row\",[],[[\"@row\"],[[32,4]]],null],[2,\"\\n      \"]],\"parameters\":[4]}]]],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"pagination\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/budget/purchase-orders/existing-view/amazon-purchases-table/template.hbs"
  });

  _exports.default = _default;
});