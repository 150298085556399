define("balance-ember/components/manage/bookmark-dropdown/group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cWH/bnOt",
    "block": "{\"symbols\":[\"bookmark\",\"@currentBookmark\",\"@deleteBookmark\",\"@close\",\"@bookmarks\",\"@title\"],\"statements\":[[10,\"div\"],[14,0,\"heading mt-32 mx-20 pb-4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"border-b border-material-gray200 pb-8 flex justify-between items-center\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"uppercase text-13 text-material-gray600\"],[12],[1,[32,6]],[13],[2,\"\\n\"],[6,[37,2],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"button\",[],[[\"@onclick\",\"@testId\",\"@size\",\"@style\"],[[30,[36,3],[[32,0],[32,0,[\"bookmarksVisible\",\"toggle\"]]],null],\"toggle-bookmark-group\",\"xsm\",\"btn-subtle-link\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,0,[\"toggleText\"]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"bookmarksVisible\",\"state\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"bookmark-dropdown-list my-8 mx-20\"],[12],[2,\"\\n\"],[6,[37,2],[[32,5,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"manage/bookmark-dropdown/row\",[[24,0,\"flex not-first:mt-8\"]],[[\"@bookmark\",\"@currentBookmark\",\"@deleteBookmark\",\"@close\"],[[32,1],[32,2],[32,3],[32,4]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"text-13 text-material-gray600\"],[12],[2,\"\\n        \"],[1,[32,0,[\"noBookmarksText\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\",\"action\"]}",
    "moduleName": "balance-ember/components/manage/bookmark-dropdown/group/template.hbs"
  });

  _exports.default = _default;
});