define("balance-ember/components/budget/change-log-slideout/change-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dvhz54tz",
    "block": "{\"symbols\":[\"diff\",\"@event\",\"@description\",\"@diffs\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"border border-\",[32,0,[\"darkColor\"]],\" m-24\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"header font-medium p-8 \",[32,0,[\"lightColor\"]]]]],[12],[2,\"\\n      \"],[8,\"pill\",[],[[\"@color\",\"@tooltip\"],[[32,0,[\"darkColor\"]],[32,3,[\"value\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,0,[\"eventText\"]]],[2,\" \"],[1,[30,[36,0],[[32,3,[\"name\"]]],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"change-row flex border-material-gray500 not-last:border-b m-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"field-name font-semibold w-1/5 text-\",[32,0,[\"fieldColors\"]]]]],[12],[2,\"\\n          \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"attribute\"]]],null]],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"changes pl-16 w-4/5 text-black\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,2],\"update\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"               \"],[8,\"pill/before\",[],[[\"@value\",\"@color\"],[[32,1,[\"old\"]],\"bg-material-amber-600\"]],null],[2,\"\\n               \"],[8,\"pill/after\",[],[[\"@value\"],[[32,1,[\"new\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"              \"],[1,[32,1,[\"old\"]]],[1,[32,1,[\"new\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"titleize\",\"humanize\",\"eq\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/budget/change-log-slideout/change-table/template.hbs"
  });

  _exports.default = _default;
});