define("balance-ember/components/budget-positions-page/component", ["exports", "@glimmer/component", "balance-ember/utils/urls", "balance-ember/utils/refresh-route", "ember-concurrency", "balance-ember/classes/table-serializer", "balance-ember/utils/budget/chosen-allocation"], function (_exports, _component, _urls, _refreshRoute, _emberConcurrency, _tableSerializer, _chosenAllocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * This component represents the entire budget positions section of the app
   *
   * @param {object} budget The individual budget the user is currently viewing
   */
  let BudgetPositionsPage = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember.computed('modelChanges.changes.[]'), _dec7 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('position', { ...this.serializedTableState,
      filter: { ...this.serializedTableState.filter,
        budget_id: {
          eq: this.args.budget.id
        }
      },
      include: 'job_type.staff_type,employee,budget',
      extra_fields: {
        positions: 'editable,budgeted_cost,percent_funded'
      },
      fields: {
        employees: 'name,code',
        job_types: 'title,code',
        budgets: 'name'
      },
      stats: {
        total: 'count'
      }
    });
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    let positions = yield this.fetchPositions.perform();
    return positions;
  }), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class BudgetPositionsPage extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modelChanges", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "eventTracking", _descriptor4, this);

      _initializerDefineProperty(this, "showForm", _descriptor5, this);

      _initializerDefineProperty(this, "fetchPositions", _descriptor6, this);

      _initializerDefineProperty(this, "loadTask", _descriptor7, this);
    }

    get positionBulkUrl() {
      let budgetId = this.args.budget.id;
      return `${(0, _urls.URLForBudgetNamespace)()}/budgets/${budgetId}/positions/bulk_upload`;
    }

    get positionChanges() {
      return this.modelChanges.changesForModelType('position');
    }

    get budgetColumns() {
      return this.args.columns.filter(col => {
        return col.valuePath !== 'budget.name';
      });
    }

    get budgetCategories() {
      return this.args.categories.filter(category => {
        return category.value !== 'budget';
      });
    }

    get budgetNameFilter() {
      return {
        budget_name: {
          match: this.args.budget.name
        }
      };
    }

    get serializedTableState() {
      return new _tableSerializer.default(this.args.tableState).apiQuery;
    }

    get chosenAllocation() {
      return new _chosenAllocation.default({
        allocations: this.args.allocations,
        tableState: this.args.tableState
      }).allocation;
    }

    afterUploadSuccess() {
      this.modelChanges.addChange({
        modelType: 'position',
        changeType: 'create'
      });
      (0, _refreshRoute.default)('budgeting.budget.positions', this);
    }

    onEditClick(position) {
      this.router.transitionTo('budgeting.budget.positions.edit-position', position.id);
    }

    onRowClick(position) {
      if (position.expanded) {
        this.eventTracking.trackEvent('Position Details Opened', {
          budget: position.get('budget.name'),
          fiscalYear: position.get('budget.fiscalYear.name')
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "eventTracking", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showForm", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "positionChanges", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "positionChanges"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fetchPositions", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loadTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterUploadSuccess", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "afterUploadSuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEditClick", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onEditClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRowClick", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onRowClick"), _class.prototype)), _class));
  _exports.default = BudgetPositionsPage;
});