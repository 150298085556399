define("balance-ember/routes/budgeting/budget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      budget_id: budgetId
    }) {
      // We set the budgetID so we can use it in willTransition hook later.
      this.set('budgetId', budgetId);
      return this.store.loadRecords('budget', {
        include: 'budget_privileges.user,approvals.approver,fiscal_year',
        fields: {
          users: 'name'
        },
        extra_fields: {
          budgets: 'authorized_approval_events,editable,user_role'
        },
        filter: {
          id: {
            eq: budgetId
          },
          'budget_privileges.role': {
            eq: 'approver'
          }
        }
      }).then(budgets => budgets.firstObject);
    }

  });

  _exports.default = _default;
});