define("balance-ember/components/positions-table/card-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eZHODnWM",
    "block": "{\"symbols\":[\"StackItem\",\"@align\",\"@label\",\"&default\"],\"statements\":[[8,\"layout/stack\",[],[[\"@align\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"\\n      text-sm\\n      text-material-blue-gray500\\n      \",[30,[36,1],[[30,[36,0],[[32,2],\"right\"],null],\"text-right\"],null],\"\\n      \"]]],[12],[2,\"\\n      \"],[1,[32,3]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"\\n        leading-none\\n        font-bold\\n        pt-4\\n        \",[30,[36,1],[[30,[36,0],[[32,2],\"right\"],null],\"text-right\"],null],\"\\n      \"]]],[12],[2,\"\\n      \"],[18,4,null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "moduleName": "balance-ember/components/positions-table/card-detail/template.hbs"
  });

  _exports.default = _default;
});