define("balance-ember/models/manage/segment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    code: (0, _model.attr)('string'),
    segmentType: (0, _model.belongsTo)('manage/segmentType', {
      async: false
    }),
    codeMarked: (0, _model.attr)('string'),
    nameMarked: (0, _model.attr)('string'),
    parentNamePath: (0, _model.attr)('array'),
    segmentTypeName: Ember.computed.readOnly('segmentType.name'),
    segmentTypeDisplayOrder: Ember.computed.readOnly('segmentType.displayOrder'),

    toJSON() {
      let {
        name,
        code
      } = this;
      return {
        name,
        code
      };
    }

  });

  _exports.default = _default;
});