define("balance-ember/controllers/budgeting/budget/positions", ["exports", "balance-ember/mixins/table-controller", "balance-ember/mixins/positions-categories"], function (_exports, _tableController, _positionsCategories) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_SORT = {
    column: 'job_type_title',
    dir: 'asc'
  };

  var _default = Ember.Controller.extend(_tableController.default, _positionsCategories.default, {
    init() {
      this._super(...arguments);

      this.tableState.defaultSort.set(DEFAULT_SORT);
    },

    fundedWidgetRoutePath: 'budgeting.budget.positions.new-position-expense-with-position',
    actions: {
      budgetChanged() {
        let oldBudget = this.oldBudget;
        let newBudget = this.model.budget;
        let budgetChanged = oldBudget && oldBudget.id !== newBudget.id;

        if (budgetChanged) {
          // Allocations are marked as a non removable filter because we don't want them removed if
          // someone presses "clear all" on the filter bar. However, we want to clear all filters,
          // including allocations, if someone switches from budget to the next otherwise we can
          // have a filter for an allocation that isn't part of the budget
          this.tableState.updateFilters([]);
          this.tableState.reset().sorts.push(DEFAULT_SORT);
        }

        Ember.set(this, 'oldBudget', newBudget);
      }

    }
  });

  _exports.default = _default;
});