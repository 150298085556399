define("balance-ember/routes/manage-v3/transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ManageV3TransactionsRoute extends Ember.Route {
    redirect() {
      this.replaceWith('manage.transactions');
    }

  }

  _exports.default = ManageV3TransactionsRoute;
});