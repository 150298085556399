define("balance-ember/components/tooltip-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-actions', 'row-actions'],
    tooltipText: Ember.computed('column', 'row.content', function () {
      return this.column.tooltipText(this.row.content);
    }).readOnly(),
    cellText: Ember.computed('column', 'row.content', function () {
      return this.column.cellText(this.row.content);
    }).readOnly()
  });

  _exports.default = _default;
});