define("balance-ember/classes/bulk-upload-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BulkUploadError {
    constructor(error) {
      this.name = error.name;
      this.text = error.title;
      this.lineNumber = error.line_number;
    }

    get pill() {
      if (this.lineNumber) {
        return `Line ${this.lineNumber}`;
      }

      return this.name || 'Error';
    }

    static build(errors) {
      return errors.map(error => {
        return new BulkUploadError(error);
      });
    }

    static buildException(ex) {
      return {
        pill: 'Error',
        text: JSON.stringify(ex)
      };
    }

  }

  _exports.default = BulkUploadError;
});