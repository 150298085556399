define("balance-ember/templates/budgeting/segments/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2dGbV0RE",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"budget/segment-form-data\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"budget/segment-form\",[],[[\"@segment\",\"@segmentTypes\",\"@budgetCategories\"],[[32,0,[\"model\"]],[32,1,[\"segmentTypes\"]],[32,1,[\"budgetCategories\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/budgeting/segments/new.hbs"
  });

  _exports.default = _default;
});