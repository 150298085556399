define("balance-ember/components/account-totals/amount-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    percentageInvalid: Ember.computed('percentage', 'isBudgetedPositive', function () {
      // 'undefined' percentage is valid, when rendering no percentages for the account-details view
      // Invalid if Infinity, budget is $0
      return this.percentage && !this.isBudgetedPositive || Math.abs(this.percentage) === Infinity;
    }),
    percentSymbol: Ember.computed('percentage', function () {
      return Ember.isPresent(this.percentage) ? '%' : '';
    })
  });

  _exports.default = _default;
});