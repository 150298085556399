define("balance-ember/components/table/filter-bar/clear-all/normal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cQ+38sRZ",
    "block": "{\"symbols\":[\"@clearAllFilters\"],\"statements\":[[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-white-link\",\"sm\",\"clear-all-filters-button\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  Clear All Filters\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/table/filter-bar/clear-all/normal/template.hbs"
  });

  _exports.default = _default;
});