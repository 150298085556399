define("balance-ember/components/budget/change-log-slideout/change-table/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RecentChangesButtonChangeTableComponent extends _component.default {
    get lightColor() {
      switch (this.args.event) {
        case 'create':
          return 'bg-material-green50';

        case 'destroy':
          return 'bg-material-red100';

        case 'update':
          return 'bg-material-orange100';

        default:
          return 'bg-material-blue50';
      }
    }

    get darkColor() {
      switch (this.args.event) {
        case 'create':
          return 'bg-material-green400';

        case 'destroy':
          return 'bg-material-red500';

        case 'update':
          return 'bg-material-amber-600';

        default:
          return 'bg-material-blue200';
      }
    }

    get fieldColors() {
      switch (this.args.event) {
        case 'destroy':
          return 'material-red600';

        default:
          return 'material-blue300';
      }
    }

    get eventText() {
      switch (this.args.event) {
        case 'destroy':
          return 'Deleted';

        case 'update':
          return 'Updated';

        case 'create':
          return 'Created';

        default:
          return this.args.event;
      }
    }

  }

  _exports.default = RecentChangesButtonChangeTableComponent;
});