define("balance-ember/models/measurement", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    value: (0, _model.attr)(),
    metric: (0, _model.belongsTo)('metric', {
      async: false
    }),
    segment: (0, _model.belongsTo)('manage/segment', {
      async: false
    }),
    totalAccountBalances: (0, _model.attr)(),
    numberEnrolled: (0, _model.attr)('number')
  });

  _exports.default = _default;
});