define("balance-ember/components/budget/reports/overassigned-employees/fte-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SaMaSWD2",
    "block": "{\"symbols\":[\"position\",\"positionId\",\"@row\"],\"statements\":[[10,\"div\"],[12],[1,[32,3,[\"content\",\"fte\"]]],[13],[2,\"\\n\"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,3,[\"content\",\"overassignedPositions\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"text-15 text-material-gray650 truncate\"],[12],[1,[32,1,[\"positionFte\"]]],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-each-in\",\"each\"]}",
    "moduleName": "balance-ember/components/budget/reports/overassigned-employees/fte-cell/template.hbs"
  });

  _exports.default = _default;
});