define("balance-ember/components/filter-form/multi/completed/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    operatorDescriptors: null,
    filterInput: null,
    formatValue: null,

    removeFilter() {},

    init() {
      this._super(...arguments);

      if (!this.operatorDescriptors) {
        Ember.set(this, 'operatorDescriptors', {});
      }
    },

    operator: Ember.computed.readOnly('filterInput.operator'),
    value: Ember.computed.readOnly('filterInput.value'),
    operatorDescriptor: Ember.computed('operator', 'operatorDescriptors', function () {
      let descriptor = this.operatorDescriptors[this.operator];
      return descriptor ? descriptor.titleize() : '';
    }),
    displayText: Ember.computed('filterInput', 'formatValue', 'value', function () {
      return this.formatValue ? this.formatValue(this.filterInput) : this.value;
    })
  });

  _exports.default = _default;
});