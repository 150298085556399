define("balance-ember/components/balance-users-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lg8eGH/c",
    "block": "{\"symbols\":[\"T\",\"Controls\",\"Body\",\"Controls\",\"@tableState\",\"@categories\"],\"statements\":[[8,\"table\",[],[[\"@tableState\",\"@columns\",\"@loadTask\",\"@categories\",\"@tableActions\",\"@trackingPrefix\"],[[32,5],[32,0,[\"columns\"]],[32,0,[\"fetchUsers\"]],[32,6],[30,[36,2],null,[[\"edit\",\"delete\"],[[30,[36,1],[[32,0],\"editUser\"],null],[30,[36,1],[[32,0],\"deleteUser\"],null]]]],\"Balance Users\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,4,[\"filter-button\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,4,[\"pagination\"]],[],[[\"@style\"],[\"tight\"]],null],[2,\"\\n  \"]],\"parameters\":[4]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"filter-bar\"]],[],[[],[]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[\"@onRowClick\",\"@rowTestSelector\",\"@style\",\"@id\",\"@noDataIcon\"],[[30,[36,1],[[32,0],\"onRowClick\"],null],\"admin-user-row\",\"hover-row\",\"balance-users-table\",\"na-employees\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"loader\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Loading Users…\"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"pagination\"]],[],[[\"@style\"],[\"tight\"]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"showDeleteModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"delete-user-modal\",[],[[\"@closeModal\",\"@user\",\"@tableState\"],[[30,[36,1],[[32,0],[30,[36,0],[[32,0,[\"showDeleteModal\"]]],null],false],null],[32,0,[\"userToDelete\"]],[32,5]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\",\"hash\",\"if\"]}",
    "moduleName": "balance-ember/components/balance-users-table/template.hbs"
  });

  _exports.default = _default;
});