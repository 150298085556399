define("balance-ember/services/session", ["exports", "ember-simple-auth/services/session", "balance-ember/utils/morph-keys", "balance-ember/models/user"], function (_exports, _session, _morphKeys, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function convertUserAttrsToComputedProperties(attrs) {
    return Object.keys(attrs).reduce((acc, key) => {
      acc[key] = Ember.computed.readOnly(`currentUser.${key}`);
      return acc;
    }, {});
  }

  var _default = _session.default.extend(Object.assign({
    store: Ember.inject.service(),
    token: Ember.computed.readOnly('session.content.authenticated.token'),
    email: Ember.computed.readOnly('session.content.authenticated.email'),
    name: Ember.computed('currentUser.{firstName,lastName}', function () {
      return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
    }),
    currentUser: Ember.computed('data.authenticated', function () {
      const user = this.data.authenticated; // `push` requires `user` to have an id. If `user` is an empty object then `push` will fail

      if (Ember.isPresent(user) && Ember.isPresent(user.id)) {
        let storedUser = this.store.peekRecord('user', user.id);
        return storedUser ? storedUser : this.addUserToStore(user);
      }

      return undefined;
    }),

    addUserToStore(data) {
      return this.store.push(this.normalizeUserData(data));
    },

    /**
     * normalizeUserData - Convert the snakecased flat user object returned from the sign_in api into
     * a jsonAPI document.
     *
     * @param  {object} data The flat snake_cased user data
     * @return {object}      jsonAPI document for the user
     */
    normalizeUserData(data) {
      data = (0, _morphKeys.camelizeKeys)(data);
      let budgetLeaIds = data.budgetLeaIds || []; // We want the budgetLeas as a relationships array

      let budgetLeas = budgetLeaIds.map(id => {
        return {
          type: 'budgetLea',
          id
        };
      });
      return {
        data: {
          id: data.id,
          type: 'user',
          attributes: data,
          relationships: {
            budgetLeas: {
              data: budgetLeas
            }
          }
        }
      };
    },

    userId: Ember.computed.readOnly('currentUser.id'),
    userUUID: Ember.computed.readOnly('currentUser.uuid')
  }, convertUserAttrsToComputedProperties(_user.schema), convertUserAttrsToComputedProperties(_user.computedProperties)));

  _exports.default = _default;
});