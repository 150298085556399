define("balance-ember/components/tag/form/search/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d6y1bTab",
    "block": "{\"symbols\":[\"@updateQuery\"],\"statements\":[[8,\"ui-form/ui-label\",[],[[\"@style\",\"@for\"],[\"hidden\",\"tag-form-search-input\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"global.tags.search_tags\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[11,\"input\"],[24,0,\"css-input-field\"],[24,1,\"tag-form-search-input\"],[4,[38,1],[\"input\",[32,1]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"on\"]}",
    "moduleName": "balance-ember/components/tag/form/search/input/template.hbs"
  });

  _exports.default = _default;
});