define("balance-ember/components/filter-form/access/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VXBm5Uko",
    "block": "{\"symbols\":[\"R\",\"R\",\"@form\",\"@filter\",\"@groupName\"],\"statements\":[[8,\"filter-radio\",[],[[\"@form\",\"@value\",\"@option\",\"@update\",\"@groupName\",\"@testId\"],[[32,3],[32,4,[\"firstOperator\",\"firstFilterValue\"]],true,[30,[36,0],[[32,0],\"updateMatchType\",[32,0,[\"defaultOperator\"]]],null],[32,5],\"access-match\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,2,[\"label\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex items-center justify-between w-full\"],[12],[2,\"\\n      \"],[8,[32,2,[\"text\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"has access\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"flex text-material-teal400\"],[12],[2,\"\\n        \"],[8,\"icon-svg\",[],[[\"@icon\"],[\"checkmark\"]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"],[8,\"filter-radio\",[],[[\"@form\",\"@value\",\"@option\",\"@update\",\"@groupName\",\"@testId\"],[[32,3],[32,4,[\"firstOperator\",\"firstFilterValue\"]],false,[30,[36,0],[[32,0],\"updateMatchType\",\"not_eq\"],null],[32,5],\"no-access-match\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"label\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex items-center justify-between w-full\"],[12],[2,\"\\n      \"],[8,[32,1,[\"text\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"has no access\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"icon-svg\",[],[[\"@icon\"],[\"no-access\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/components/filter-form/access/template.hbs"
  });

  _exports.default = _default;
});