define("balance-ember/components/table/no-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S4hv9Kmf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"\\n  flex\\n  flex-col\\n  items-center\\n  justify-baseline\\n  bg-white\\n  pt-40\\n  h-320\\n\"],[12],[2,\"\\n  \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[[32,0,[\"svgName\"]],\"h-96\"]],null],[2,\"\\n  \"],[10,\"span\"],[14,0,\"\\n      text-material-gray500\\n      font-light\\n      mt-24\\n      mb-20\\n    \"],[12],[2,\"\\n    \"],[1,[32,0,[\"noDataText\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"modelReference\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"icon-button\",[],[[\"@route\",\"@style\",\"@testId\",\"@icon\",\"@buttonText\",\"@showButtonText\"],[[30,[36,1],[[32,0,[\"linkToPath\"]],[32,0,[\"modelReference\"]]],null],\"btn-primary\",\"add-button\",\"circle-plus\",[32,0,[\"buttonText\"]],true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"linkToPath\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"icon-button\",[],[[\"@route\",\"@style\",\"@testId\",\"@icon\",\"@buttonText\",\"@showButtonText\"],[[30,[36,1],[[32,0,[\"linkToPath\"]]],null],\"btn-secondary-link\",\"add-button\",\"circle-plus\",[32,0,[\"buttonText\"]],true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"array\"]}",
    "moduleName": "balance-ember/components/table/no-data/template.hbs"
  });

  _exports.default = _default;
});