define("balance-ember/routes/budgeting/budget/approval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    model(params) {
      return Ember.RSVP.hash({
        budget: this.modelFor('budgeting/budget'),
        eventType: params.event_type
      });
    }

  });

  _exports.default = _default;
});