define("balance-ember/components/manage/chart/per-pupil-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DAfDCoUW",
    "block": "{\"symbols\":[\"@togglePupil\",\"@active\"],\"statements\":[[8,\"button\",[],[[\"@onclick\",\"@style\",\"@active\",\"@testId\"],[[32,1],[30,[36,0],[[32,2],\"btn-success-light\",\"btn-default\"],null],[32,2],\"per-pupil-button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  Per Pupil\\n  \"],[8,\"tooltip\",[],[[\"@text\"],[\"Divides amount totals by number of pupils at each location\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/manage/chart/per-pupil-button/template.hbs"
  });

  _exports.default = _default;
});