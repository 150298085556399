define("balance-ember/components/budget/segment-form-data/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    fiscalYear: Ember.computed.readOnly('availableFiscalYears.current'),
    segmentTypes: null,
    budgetCategories: null,
    loadBudgetCategories: Ember.computed(function () {
      return [{
        name: 'Central Office',
        value: 'central_office'
      }, {
        name: 'School',
        value: 'school'
      }, {
        name: 'Any',
        value: 'any'
      }, {
        name: 'None',
        value: 'none'
      }];
    }),
    loadDataTask: (0, _emberConcurrency.task)(function* () {
      yield Ember.RSVP.all([Ember.set(this, 'segmentTypes', this.fiscalYear.segmentTypes), Ember.set(this, 'budgetCategories', this.loadBudgetCategories)]);
    }).drop(),

    didReceiveAttrs() {
      this._super();

      this.loadDataTask.perform();
    }

  });

  _exports.default = _default;
});