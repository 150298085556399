define("balance-ember/components/manage/download-dropdown/summarize-filter-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SummarizeFilterSerializer {
    constructor(summarizeState) {
      this.summarizeState = summarizeState;
    }

    serialize(summarizeState = this.summarizeState) {
      return { ...new CategorySerializer(summarizeState.category).serialize(),
        ...this.serializeSummaries(summarizeState.summaries)
      };
    }

    serializeSummaries(summaries) {
      if (!summaries.keys.length) {
        return {};
      }

      return summaries.keys.reduce((acc, key) => {
        acc.summaries[key] = this.serialize(summaries.entries[key]);
        return acc;
      }, {
        summaries: {}
      });
    }

  }

  _exports.default = SummarizeFilterSerializer;

  class CategorySerializer {
    constructor(category) {
      this.category = category;
    }

    serialize() {
      if (!this.category.keys.length) {
        return {};
      }

      return {
        value: this.value,
        type: this.type
      };
    }

    get type() {
      return this.category.entries.type.state;
    }

    get value() {
      let value = this.category.entries.value.state || '';
      let parts = value.split('_');
      parts = parts.length > 1 && parts[0] === 'segment' ? parts.slice(1) : parts;
      return parts.join('_');
    }

  }
});