define("balance-ember/components/budget/district-wide-files/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "csuIi81q",
    "block": "{\"symbols\":[\"queue\",\"uploadFileTask\",\"@for\",\"@uploadFile\",\"&default\"],\"statements\":[[8,\"budget/file-upload/input\",[],[[\"@name\",\"@for\",\"@url\",\"@uploadData\",\"@uploadFile\",\"@afterAllUploads\"],[\"district-wide-files-queue\",[32,3],[32,0,[\"url\"]],[30,[36,0],null,[[\"fiscal_year_id\"],[[32,0,[\"currentFiscalYearId\"]]]]],[32,4],[32,0,[\"afterAllUploads\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,5,[[32,1],[32,2]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "balance-ember/components/budget/district-wide-files/input/template.hbs"
  });

  _exports.default = _default;
});