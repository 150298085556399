define("balance-ember/components/budget/budgets/form/table/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    didReceiveAttrs() {
      this._super();

      if (this.budgetUsers) {
        Ember.set(this, 'users', this.budgetUsers.toArray());
      }
    },

    columns: Ember.computed(function () {
      let cols = [{
        label: 'User',
        valuePath: 'name',
        cellClassNames: 'text-sm',
        testSelector: 'user',
        width: '160px',
        sortable: false,
        hideable: false
      }, {
        label: 'Builder',
        cellComponent: 'radio-button-cell',
        cellClassNames: 'text-sm',
        option: 'builder',
        valuePath: 'role',
        namePath: 'name',
        actionName: 'editBudgetRole',
        align: 'center',
        testSelectorName: 'builder',
        testSelectorValuePath: 'name',
        width: '90px',
        sortable: false,
        hideable: false,

        controlLabel(user) {
          return `Make ${user.name} a builder`;
        }

      }, {
        label: 'Budget Approver',
        cellComponent: 'radio-button-cell',
        cellClassNames: 'text-sm',
        option: 'approver',
        valuePath: 'role',
        namePath: 'name',
        actionName: 'editBudgetRole',
        align: 'center',
        testSelectorName: 'approver',
        testSelectorValuePath: 'name',
        width: '150px',
        sortable: false,
        hideable: false,

        controlLabel(user) {
          return `Make ${user.name} an approver`;
        }

      }, {
        label: 'Viewer',
        cellComponent: 'radio-button-cell',
        cellClassNames: 'text-sm',
        option: 'viewer',
        valuePath: 'role',
        namePath: 'name',
        actionName: 'editBudgetRole',
        align: 'center',
        testSelectorName: 'viewer',
        testSelectorValuePath: 'name',
        width: '78px',
        sortable: false,
        hideable: false,

        controlLabel(user) {
          return `Make ${user.name} a viewer for`;
        }

      }, {
        label: 'PO Approver',
        cellComponent: 'radio-button-cell',
        cellClassNames: 'text-sm',
        option: 'purchasing_approver',
        valuePath: 'role',
        namePath: 'name',
        actionName: 'editBudgetRole',
        align: 'center',
        testSelectorName: 'purchasingApprover',
        testSelectorValuePath: 'name',
        width: '115px',
        sortable: false,
        hideable: false,

        controlLabel(user) {
          return `Make ${user.name} a PO approver for`;
        }

      }, {
        label: 'No Access',
        cellComponent: 'radio-button-cell',
        cellClassNames: 'text-sm',
        option: null,
        valuePath: 'role',
        namePath: 'name',
        actionName: 'editBudgetRole',
        align: 'center',
        testSelectorName: 'no-access',
        testSelectorValuePath: 'name',
        sortable: false,
        hideable: false,

        controlLabel(user) {
          return `Remove ${user.name}'s access`;
        }

      }];
      return cols;
    })
  });

  _exports.default = _default;
});