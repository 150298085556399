define("balance-ember/components/table/actions-popover/change-log/component", ["exports", "@glimmer/component", "@microstates/ember"], function (_exports, _component, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TableActionsPopoverChangeLogComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class TableActionsPopoverChangeLogComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "shouldShowChangeLog", _descriptor, this);
    }

    get recordDescription() {
      var _this$args$tableActio;

      if ((_this$args$tableActio = this.args.tableAction) !== null && _this$args$tableActio !== void 0 && _this$args$tableActio.recordDescription) {
        return this.args.tableAction.recordDescription(this.args.row.content);
      }

      return [];
    }

    onClick() {
      this.shouldShowChangeLog.set(true);

      if (this.args.onclick) {
        this.args.onclick();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "shouldShowChangeLog", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _ember.Store)((0, _ember.create)(Boolean, false), nextState => this.shouldShowChangeLog = nextState);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = TableActionsPopoverChangeLogComponent;
});