define("balance-ember/components/manage/bookmark-dropdown/row/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hgTP2bDH",
    "block": "{\"symbols\":[\"@bookmark\",\"@cancel\"],\"statements\":[[10,\"div\"],[14,0,\"bookmark-edit-wrapper rounded p-8 bg-material-yellow200 w-full\"],[12],[2,\"\\n  \"],[1,[30,[36,2],[[32,1,[\"name\"]]],[[\"class\",\"maxlength\",\"type\",\"update\",\"enter\"],[\"css-text-input-variant-1 w-full\",\"50\",\"text\",[30,[36,1],[[32,0],[30,[36,0],[[32,1,[\"name\"]]],null]],null],[32,0,[\"renameBookmark\"]]]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex justify-between\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-13\"],[12],[2,\"\\n      \"],[1,[32,1,[\"name\",\"length\"]]],[2,\" / 50 Characters Max\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"mr-8 inline\"],[12],[2,\"\\n        \"],[8,\"button\",[],[[\"@onclick\",\"@style\",\"@size\",\"@testId\"],[[30,[36,3],[[30,[36,1],[[32,0],[32,2]],null]],null],\"btn-default\",\"sm\",\"cancel-button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          Cancel\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-subtle\",\"sm\",\"save-button\",[30,[36,3],[[32,0,[\"renameBookmark\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Save\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\",\"one-way-input\",\"stop-propagation\"]}",
    "moduleName": "balance-ember/components/manage/bookmark-dropdown/row/edit/template.hbs"
  });

  _exports.default = _default;
});