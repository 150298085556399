define("balance-ember/components/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0/wR5QOQ",
    "block": "{\"symbols\":[\"@icon\",\"@id\",\"@disabled\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"\\n  ease-in-out-all-1/5s\\n  w-16\\n  h-16\\n  text-white\\n  border\\n  border-material-gray400\\n  rounded\\n  inline-flex\\n  items-center\\n  justify-center\\n  cursor-pointer\\n  relative\\n  focus-within:outline-material-light-blue300\\n  \",[32,0,[\"backgroundColor\"]]]]],[24,\"role\",\"button\"],[4,[38,1],[[32,0],[32,0,[\"onupdate\"]],[30,[36,0],[[32,0,[\"checked\"]]],null]],null],[12],[2,\"\\n\\n  \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[[30,[36,2],[[32,1],\"checkmark\"],null],[31,[\"\\n      ease-in-out-all-1/5s\\n      h-12\\n      \",[30,[36,3],[[30,[36,4],[[32,1],\"minus\"],null],\"w-10\",\"w-12\"],null],\"\\n      fill-current\\n      ease-all-1/5s\\n      pointer-events-none\\n      \",[30,[36,3],[[32,0,[\"checked\"]],\"visible\",\"invisible\"],null]]]]],null],[2,\"\\n\\n  \"],[11,\"input\"],[16,1,[32,2]],[24,0,\"absolute opacity-0\"],[16,\"disabled\",[32,3]],[16,\"checked\",[32,0,[\"checked\"]]],[24,4,\"checkbox\"],[4,[38,5],[\"click\",[32,0,[\"inputClick\"]]],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"action\",\"or\",\"if\",\"eq\",\"on\"]}",
    "moduleName": "balance-ember/components/checkbox/template.hbs"
  });

  _exports.default = _default;
});