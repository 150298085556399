define("balance-ember/routes/budgeting/vendors/edit", ["exports", "balance-ember/utils/writeable-model"], function (_exports, _writeableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetingVendorsEditRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class BudgetingVendorsEditRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "network", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);
    }

    async model({
      vendor_id: id
    }) {
      let model = await this.findVendorRecord(id);
      if (!model) return;
      return new _writeableModel.default({
        model,
        store: this.store,
        network: this.network
      });
    }

    async findVendorRecord(id) {
      try {
        return (await this.store.peekRecord('vendor', id)) || (await this.store.findRecord('vendor', id));
      } catch ({
        errors
      }) {
        this.handleError(errors[0]);
        throw 'Vendor model lookup failed';
      }
    }

    handleError(error) {
      this.transitionTo('budgeting.vendors');
      this.notifications.error(`${error.status} ${error.title}`);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "network", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BudgetingVendorsEditRoute;
});