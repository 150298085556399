define("balance-ember/routes/bookmark-redirect", ["exports", "balance-ember/classes/bookmark-query-param-builder", "ember-concurrency"], function (_exports, _bookmarkQueryParamBuilder, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    availableBudgetLeas: Ember.inject.service(),

    model({
      id
    }) {
      return {
        redirect: this.redirectTask.perform(id)
      };
    },

    redirectTask: (0, _emberConcurrency.task)(function* (bookmarkId) {
      let currentBudgetLeaId = this.availableBudgetLeas.current.id;
      let bookmark = yield this.store.loadRecord('bookmark', bookmarkId);
      let queryParamBuilder = new _bookmarkQueryParamBuilder.default(bookmark, currentBudgetLeaId);
      let query = queryParamBuilder.build();
      let queryParams = {
        query
      };
      queryParams.summarize = queryParamBuilder.summarizeQueryString;
      const route = getRoute(bookmark.resource);
      this.transitionTo(route, {
        queryParams
      });
    }).drop()
  });

  _exports.default = _default;

  function getRoute(resource) {
    switch (resource) {
      case 'account_balance':
        return 'manage.accounts.index';

      case 'transaction':
        return 'manage.transactions';
    }
  }
});