define("balance-ember/services/time-zone", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    get name() {
      return _moment.default.tz.guess();
    },

    toParam: Ember.computed('name', function () {
      return `timezone_name=${this.name}`;
    }),
    header: Ember.computed('name', function () {
      return {
        'X-Timezone-Name': this.name
      };
    })
  });

  _exports.default = _default;
});