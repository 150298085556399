define("balance-ember/models/budget-lea", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.computedProperties = _exports.schema = void 0;
  const schema = {
    active: (0, _model.attr)('boolean'),
    budgetLeaType: (0, _model.attr)('string'),
    current: (0, _model.attr)('boolean'),
    description: (0, _model.attr)('string'),
    endDate: (0, _model.attr)('date'),
    fiscalYear: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    startDate: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    metrics: (0, _model.hasMany)('metric', {
      async: false
    }),
    segmentTypes: (0, _model.hasMany)('manage/segmentType', {
      async: false
    }),
    supplementTypes: (0, _model.hasMany)('supplementType', {
      async: false
    })
  };
  _exports.schema = schema;
  const computedProperties = {
    defaultSort: Ember.computed(function () {
      return ['displayOrder:asc', 'value:asc'];
    }),
    sortedSegmentTypes: Ember.computed.sort('segmentTypes', 'defaultSort'),
    shortName: Ember.computed('name', function () {
      const regex = /(([fFyY]{2}[\d]{2}).*)/i;
      const name = this.name;
      return name !== null ? name.replace(regex, '$2') : undefined;
    })
  };
  _exports.computedProperties = computedProperties;

  var _default = _model.default.extend(schema, computedProperties);

  _exports.default = _default;
});