define("balance-ember/components/light-table/default-column-label/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    column: null,
    sortIcons: null,
    label: Ember.computed.readOnly('column.label'),
    sortIconProperty: Ember.computed('column.{sortable,sorted,ascending}', function () {
      let sorted = this.column.sorted;

      if (sorted) {
        let ascending = this.column.ascending;
        return ascending ? 'iconAscending' : 'iconDescending';
      }

      let sortable = this.column.sortable;
      return sortable ? 'iconSortable' : null;
    }),
    isNotSorted: Ember.computed.not('column.sorted'),
    shouldShowSortableIcon: Ember.computed.and('isNotSorted', 'column.sortable')
  });

  _exports.default = _default;
});