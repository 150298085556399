define("balance-ember/components/table/controls/pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TBvvMDYL",
    "block": "{\"symbols\":[\"Controls\",\"@data\",\"@state\",\"@loadTask\",\"@trackingParams\",\"@trackingPrefix\"],\"statements\":[[8,\"pagination-controls\",[],[[\"@data\",\"@afterGoToPage\",\"@afterSetPerPage\",\"@state\",\"@loadTask\",\"@trackingParams\",\"@trackingPrefix\"],[[32,2],[32,0,[\"afterGoToPage\"]],[32,0,[\"afterSetPerPage\"]],[32,3],[32,4],[32,5],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-sm flex items-center justify-end w-full\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n      \"],[8,[32,1,[\"results-count\"]],[],[[],[]],null],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"inline-block ml-16\"],[12],[2,\"\\n        \"],[8,[32,1,[\"per-page\"]],[],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"border-l border-material-gray100 ml-16 inline-block\"],[12],[2,\"\\n      \"],[8,[32,1,[\"links\"]],[],[[\"@width\",\"@currentLinkBackgroundColor\"],[[32,0,[\"linksWidth\"]],[32,0,[\"currentLinkBackgroundColor\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/table/controls/pagination/template.hbs"
  });

  _exports.default = _default;
});