define("balance-ember/models/supplement", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    supplementType: (0, _model.belongsTo)('supplementType', {
      async: false
    }),
    value: (0, _model.attr)('string'),
    valueMarked: (0, _model.attr)('string')
  });

  _exports.default = _default;
});