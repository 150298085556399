define("balance-ember/routes/district-admin/budget-user-permissions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      budget_user_id: budgetUserId
    }) {
      // We want to load all the data at once with a loading spinner in the modal, so we're just
      // passing the id along so we know which user to get
      return budgetUserId;
    }

  });

  _exports.default = _default;
});