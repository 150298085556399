define("balance-ember/routes/budgeting/budget/supplemental-pay-expenses/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    features: Ember.inject.service(),
    positionBudgeting: Ember.computed.readOnly('features.positionBudgeting'),

    beforeModel() {
      if (!this.positionBudgeting) this.transitionTo('/not-found');
    },

    model() {
      return this.modelFor('budgeting.budget');
    }

  });

  _exports.default = _default;
});