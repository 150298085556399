define("balance-ember/components/allocation-edit-delete-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I6i4WLk9",
    "block": "{\"symbols\":[\"InlineItem\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"allocation\",\"isEditable\"]],[32,0,[\"isArchitectAndEditable\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"allocation-edit-delete-group\"],[12],[2,\"\\n    \"],[8,\"layout/inline\",[],[[\"@space\"],[\"s\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"edit-button\",[],[[\"@click\",\"@testSelectorValue\",\"@buttonText\",\"@showButtonText\"],[[30,[36,0],[[32,0],\"editAllocation\",[32,0,[\"allocation\",\"id\"]]],null],[32,0,[\"allocation\",\"name\"]],\"Allocation\",true]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"delete-button\",[],[[\"@click\",\"@testSelectorValue\",\"@buttonText\",\"@displayText\"],[[30,[36,0],[[32,0],\"deleteAllocation\",[32,0,[\"allocation\",\"id\"]]],null],[32,0,[\"allocation\",\"name\"]],\"Allocation\",\"Delete\"]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"and\",\"if\"]}",
    "moduleName": "balance-ember/components/allocation-edit-delete-group/template.hbs"
  });

  _exports.default = _default;
});