define("balance-ember/components/transaction-amount-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F7OEnz3+",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[[32,0,[\"value\"]]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n\"],[10,\"div\"],[12],[2,\"(\"],[1,[32,0,[\"formattedStatusCode\"]]],[2,\")\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\"]}",
    "moduleName": "balance-ember/components/transaction-amount-cell/template.hbs"
  });

  _exports.default = _default;
});