define("balance-ember/components/positions-table/form/position-details/code-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OvBdL9Ui",
    "block": "{\"symbols\":[\"@position\"],\"statements\":[[8,\"one-way-input\",[[16,1,[32,0,[\"group\",\"id\"]]],[24,0,\"css-input-field\"]],[[\"@value\",\"@update\"],[[32,1,[\"code\"]],[30,[36,0],[[32,1],\"code\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"set\"]}",
    "moduleName": "balance-ember/components/positions-table/form/position-details/code-input/template.hbs"
  });

  _exports.default = _default;
});