define("balance-ember/components/powered-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8tbNVVGv",
    "block": "{\"symbols\":[\"option\",\"@options\",\"@searchField\",\"@selected\",\"@onchange\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"power-select-single blue-selected\"],[12],[2,\"\\n  \"],[8,\"power-select\",[],[[\"@searchEnabled\",\"@options\",\"@searchField\",\"@selected\",\"@renderInPlace\",\"@onChange\"],[false,[32,2],[32,3],[32,4],true,[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,6,[[32,1]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/powered-select/template.hbs"
  });

  _exports.default = _default;
});