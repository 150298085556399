define("balance-ember/components/manage/print-header/filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rsZ17KqI",
    "block": "{\"symbols\":[],\"statements\":[[1,[32,0,[\"displayText\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/manage/print-header/filter/template.hbs"
  });

  _exports.default = _default;
});