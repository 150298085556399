define("balance-ember/models/non-personnel-expense", ["exports", "balance-ember/models/expense", "@ember-data/model"], function (_exports, _expense, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.nonPersonnelExpenseComputedProperties = _exports.nonPersonnelExpenseSchema = void 0;
  const nonPersonnelExpenseSchema = {
    name: (0, _model.attr)('string'),
    accountString: (0, _model.attr)('string', {
      readOnly: true
    }),
    // extra_attribute
    amount: (0, _model.attr)('number', {
      defaultValue: 0.0,
      readOnly: true
    }),
    // extra_attribute
    hasItems: (0, _model.attr)('boolean', {
      defaultValue: false,
      readOnly: true
    }),
    // extra_attribute
    purchaseOrder: (0, _model.belongsTo)('purchaseOrder', {
      async: false
    }),
    vendor: (0, _model.belongsTo)('vendor', {
      async: false
    }),
    notes: (0, _model.hasMany)('note', {
      async: false
    }),
    items: (0, _model.hasMany)('nonPersonnelExpenses/item', {
      async: false
    })
  };
  _exports.nonPersonnelExpenseSchema = nonPersonnelExpenseSchema;
  const nonPersonnelExpenseComputedProperties = {
    expenseSegment: Ember.computed('combinedSegments.[]', 'isDeleted', function () {
      // Ember data will attempt to fetch `segments` from the server if it can't find it locally.
      // This will fail if the model has just been destroyed and will result in weird bugs.
      return this.isDeleted ? {} : this.combinedSegments.findBy('segmentType.expenseSegment');
    }),
    expenseSegmentCode: Ember.computed.and('expenseSegment', 'expenseSegment.code'),
    expenseSegmentName: Ember.computed.and('expenseSegment', 'expenseSegment.name'),
    associatedSegments: Ember.computed.filter('segments', segment => !segment.disassociate)
  };
  _exports.nonPersonnelExpenseComputedProperties = nonPersonnelExpenseComputedProperties;

  var _default = _expense.default.extend(nonPersonnelExpenseSchema, nonPersonnelExpenseComputedProperties);

  _exports.default = _default;
});