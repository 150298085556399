define("balance-ember/components/relative-date-button/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RelativeDateButtonComponent extends _component.default {
    get style() {
      return this.args.style ?? 'stacked';
    }

    get isFlat() {
      return this.style.includes('flat');
    }

    get isActive() {
      return this.args.value === this.args.dateType;
    }

    get appliedClasses() {
      return ['text-white', 'bg-material-light-blue700', 'button-default-active-dark'].join(' ');
    }

  }

  _exports.default = RelativeDateButtonComponent;
});