define("balance-ember/components/non-personnel-expenses/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/refresh-route"], function (_exports, _component, _refreshRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: Ember.inject.service(),
    actions: {
      afterSave() {
        (0, _refreshRoute.default)('budgeting.budget.expenses.view', this);
      },

      edit(expense) {
        this.router.transitionTo('budgeting.budget.expenses.view.edit-non-personnel-expense', expense.id);
      }

    }
  });

  _exports.default = _default;
});