define("balance-ember/components/multi-step-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G+hTAiqS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"trigger\",\"step\",\"state\",\"actions\"],[[30,[36,2],[\"multi-step-dropdown/dropdown-trigger\"],[[\"onclick\",\"uniqueId\"],[[30,[36,0],[[32,0],\"onTriggerClick\"],null],[32,0,[\"uniqueId\"]]]]],[30,[36,2],[\"multi-step-dropdown/dropdown-step\"],[[\"renderInPlace\",\"currentStep\",\"uniqueId\",\"stackable\",\"closeModal\"],[[32,0,[\"renderInPlace\"]],[32,0,[\"currentStep\"]],[32,0,[\"uniqueId\"]],[32,0,[\"stackable\"]],[30,[36,0],[[32,0],\"onClickOutside\"],null]]]],[30,[36,1],null,[[\"isOpen\"],[[32,0,[\"isOpen\"]]]]],[30,[36,1],null,[[\"nextStep\",\"close\"],[[30,[36,0],[[32,0],\"nextStep\"],null],[30,[36,0],[[32,0],\"close\"],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\",\"component\"]}",
    "moduleName": "balance-ember/components/multi-step-dropdown/template.hbs"
  });

  _exports.default = _default;
});