define("balance-ember/components/manage/supplement-cell/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param {string} column.columnValue
   * @param {array} row.content.supplements
   * @param {string} cellId
   */
  var _default = _component.default.extend({
    column: null,
    row: null,
    cellId: null,
    supplementType: Ember.computed.readOnly('column.supplementType'),
    supplements: Ember.computed.readOnly('row.supplements'),
    supplement: Ember.computed('supplements', 'supplementType', function () {
      return this.supplements[this.supplementType];
    })
  });

  _exports.default = _default;
});