define("balance-ember/components/filter-list/completed-filter/pill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uATINy5Z",
    "block": "{\"symbols\":[\"&default\",\"@updateFilter\",\"@trackingPrefix\",\"@categoryConfig\",\"@categoryToEdit\",\"@filter\",\"@displayText\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"\\n    rounded-pill\\n    \",[30,[36,1],[[30,[36,0],[[32,0,[\"componentState\"]],\"deleting\"],null],\"bg-material-red50\",\"bg-material-teal50\"],null],\"\\n    inline-block\\n    text-material-gray700\\n    text-sm\\n    px-16\\n    leading-none\"]]],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[\"filter-list/completed-filter/pill/\",[32,0,[\"componentState\"]]],null]],[[\"displayText\",\"filter\",\"categoryToEdit\",\"categoryConfig\",\"trackingPrefix\",\"updateFilter\",\"removeFilter\",\"cancelDeleting\"],[[32,7],[32,6],[32,5],[32,4],[32,3],[32,2],[30,[36,2],[[32,0],\"removeFilter\"],null],[30,[36,2],[[32,0],\"cancelDeleting\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"action\",\"concat\",\"component\"]}",
    "moduleName": "balance-ember/components/filter-list/completed-filter/pill/template.hbs"
  });

  _exports.default = _default;
});