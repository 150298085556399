define("balance-ember/components/planned-spending-by-segment/personnel-percentage-cell/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/percentages"], function (_exports, _component, _percentages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    segment: Ember.computed.readOnly('row.content'),
    personnelAmount: Ember.computed.readOnly('segment.personnelPlannedAmount'),
    nonPersonnelAmount: Ember.computed.readOnly('segment.nonPersonnelPlannedAmount'),
    totalAmount: Ember.computed.readOnly('segment.plannedAmount'),
    percentages: Ember.computed('nonPersonnelAmount', 'personnelAmount', 'segment', function () {
      return (0, _percentages.toPercentageList)([this.personnelAmount, this.nonPersonnelAmount]);
    }),
    personnelPercentage: Ember.computed.readOnly('percentages.firstObject'),
    nonPersonnelPercentage: Ember.computed.readOnly('percentages.lastObject')
  });

  _exports.default = _default;
});