define("balance-ember/utils/position-expense-totals", ["exports", "balance-ember/utils/expense-totals", "balance-ember/utils/sum-benefits"], function (_exports, _expenseTotals, _sumBenefits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PositionExpenseTotals extends _expenseTotals.default {
    get allocationRemaining() {
      var _this$allocation;

      return (((_this$allocation = this.allocation) === null || _this$allocation === void 0 ? void 0 : _this$allocation.remainingAmount) ?? 0) + this.amountChange;
    }

    get amountChange() {
      return this.originalTotal - this.adjustedTotal;
    }
    /**
     * `amount` is a readOnly property from the server with the
     * persisted value of the position's cost
     */


    get originalTotal() {
      return this.expense.amount ?? 0;
    }
    /**
     * The total cost of the position multiplied by the percentage
     * on this expense. This shows much of the position's total cost will be
     * on this expense's allocation.
     */


    get adjustedTotal() {
      return this.positionTotal * this.fundingPercentage;
    }
    /**
     * Total percentage funded based on the position total
     */


    get adjustedPercentage() {
      return this.originalTotal / this.positionTotal * 100;
    }
    /**
     * The total amount for the position in this budget (includes FTE)
     */


    get positionTotal() {
      return this.salaryAmount + this.benefitsAmount;
    }
    /**
     * That benefitsAmount must be multipled by the percentage in the expense
     * to determine how much of the benefits will be funded by this expense's allocation
     */


    get adjustedBenefits() {
      return this.benefitsAmount * this.fundingPercentage;
    }
    /**
     * The Position's FTE (Full-Time Employee), represents how much of the employee's
     * time will be dedicated to that budget. Example: A music teacher position that works at 3
     * differents schools would have a .33 FTE on 3 different budgets. We multiply the benefits
     * by the FTE to determine how much benefits goes to the budget.
     */


    get benefitsAmount() {
      return this.benefitsTotal * this.FTE;
    }

    get benefitsTotal() {
      return (0, _sumBenefits.default)(this.benefitsPackage, this.salary);
    }

    get benefitsPackage() {
      var _this$position, _this$position$jobTyp;

      return (_this$position = this.position) === null || _this$position === void 0 ? void 0 : (_this$position$jobTyp = _this$position.jobType) === null || _this$position$jobTyp === void 0 ? void 0 : _this$position$jobTyp.benefitsPackage;
    }
    /**
     * That salaryAmount must be multipled by the percentage in the expense
     * to determine how much of the salary will be funded by this expense's allocation
     */


    get adjustedSalary() {
      return this.salaryAmount * this.fundingPercentage;
    }
    /**
     * The Position's FTE (Full-Time Employee), represents how much of the employee's
     * time will be dedicated to that budget. Example: A music teacher position that works at 3
     * differents schools would have a .33 FTE on 3 different budgets. We multiply the salary
     * by the FTE to determine how much salary goes to the budget.
     */


    get salaryAmount() {
      return this.salary * this.FTE;
    }
    /**
     * Different allocations have different ways of choosing which salary to use.
     * `Actual` means we use the Employee's actual salary. `Estimated` we use the
     * averageBaseSalary of that Job Type.
     */


    get salary() {
      var _this$position2;

      return this.salaryType === 'Actual' && (_this$position2 = this.position) !== null && _this$position2 !== void 0 && _this$position2.employee ? this.employeeBudgetedSalary : this.averageBaseSalary;
    }
    /**
     * We always use the employee's budgeted salary in calculations.
     * We don't use their actual salary. The actual salary is just a reference
     * point for what the employee is currently making. Budgeting is about the
     * upcoming fiscal year, so we want to use the value we think the employee will
     * be making in the future.
     */


    get employeeBudgetedSalary() {
      var _this$position3, _this$position3$emplo;

      return ((_this$position3 = this.position) === null || _this$position3 === void 0 ? void 0 : (_this$position3$emplo = _this$position3.employee) === null || _this$position3$emplo === void 0 ? void 0 : _this$position3$emplo.budgetedSalary) ?? 0;
    }

    get averageBaseSalary() {
      var _this$position4, _this$position4$jobTy;

      return ((_this$position4 = this.position) === null || _this$position4 === void 0 ? void 0 : (_this$position4$jobTy = _this$position4.jobType) === null || _this$position4$jobTy === void 0 ? void 0 : _this$position4$jobTy.averageBaseSalary) ?? 0;
    }

    get salaryType() {
      var _this$expense$allocat;

      return ((_this$expense$allocat = this.expense.allocation) === null || _this$expense$allocat === void 0 ? void 0 : _this$expense$allocat.salaryType) ?? 'Estimated';
    }

    get fteFunded() {
      return this.FTE * this.fundingPercentage;
    }

    get FTE() {
      var _this$position5;

      return ((_this$position5 = this.position) === null || _this$position5 === void 0 ? void 0 : _this$position5.fte) ?? 0;
    }

    get fundingPercentage() {
      return this.percentage ? this.percentage / 100 : 0;
    }

    get allocation() {
      return this.expense.allocation;
    }

    get percentage() {
      return this.expense.percentage;
    }

    get position() {
      return this.expense.position;
    }

  }

  _exports.default = PositionExpenseTotals;
});