define("balance-ember/utils/includes-computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = includes;

  /**
   * A computed macro for seeing if a value is included in one of your properties
   *
   * @param {string} dependentKey The property to watch on your object. Don't need to include `.[]`
   * or `.each`
   * @param {any}     value The value to look for
   * @param {boolean} isProp Set this to true if the value you passed in is a property name on your
   * object and not a raw value
   */
  function includes(dependentKey, value, isProp) {
    return Ember.computed(`${dependentKey}.[]`, function () {
      let val = value;

      if (val === 'this') {
        val = this;
      } else if (isProp) {
        val = this[value];
      }

      return this[dependentKey].includes(val);
    });
  }
});