define("balance-ember/components/budget/page-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+iYg4TkF",
    "block": "{\"symbols\":[\"currentUser\",\"@title\",\"@subtitle\",\"@showBudgetTotals\",\"&default\"],\"statements\":[[10,\"header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex justify-between mt-8 mb-32\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"breadcrumbs\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"ui-breadcrumbs\",[],[[\"@breadcrumbs\",\"@variant\"],[[32,0,[\"breadcrumbs\"]],\"arrow\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"budget-quick-picker\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex items-center justify-between\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex flex-col\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"my-0 text-3xl font-bold\"],[12],[2,\"\\n        \"],[1,[32,2]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"h2\"],[14,0,\"my-0 font-normal text-lg\"],[12],[2,\"\\n        \"],[1,[32,3]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[8,\"auth-provider\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[30,[36,1],[[32,1,[\"isBudgetArchitect\"]],[32,0,[\"currentFiscalYear\"]],[32,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"budget-totals\",[],[[\"@model\",\"@useTotalTextInLabels\",\"@shouldShowBorders\"],[[32,0,[\"currentFiscalYear\"]],true,true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"pt-16\"],[12],[2,\"\\n  \"],[18,5,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"and\"]}",
    "moduleName": "balance-ember/components/budget/page-header/template.hbs"
  });

  _exports.default = _default;
});