define("balance-ember/routes/budgeting/budget/expenses/view/allocations/destroy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    queryParams: {
      allocationIds: {
        replace: true
      }
    },

    model(params) {
      let allocations = this.store.peekAll('allocation');
      allocations = allocations.filter(function (a) {
        return params.allocationIds.split(',').includes(a.get('id'));
      });
      let budget = allocations.firstObject.budget; // We use 'expenses' as a redirectRoute flag, as a more complicated route is built
      // in the allocation-form component and also changes depending on whether 'cancel'
      // or confirming the destroy happens.

      let redirectRoute = 'expenses';
      return {
        allocations,
        budget,
        redirectRoute
      };
    }

  });

  _exports.default = _default;
});