define("balance-ember/components/manage/print-header/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * This component shows additional information to the user on a printed page
   *
   * @param {string} modelName (e.g. 'accounts', 'transactions')
   * @param {object} totals
   * @param {object} grandTotals
   */
  let PrintHeader = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.readOnly('session.name'), _dec5 = Ember.computed.readOnly('availableBudgetLeas.current.name'), _dec6 = Ember.computed.readOnly('args.tableState.optionalFilters'), _dec7 = Ember.computed.readOnly('args.tableState.pagination.totalItems.state'), _dec8 = Ember.computed.readOnly('args.tableState.pagination.itemsPerPage.state'), _dec9 = Ember.computed.readOnly('args.tableState.pagination.page.state'), _dec10 = Ember.computed.notEmpty('args.grandTotals'), _dec11 = Ember.computed.notEmpty('args.totals'), _dec12 = Ember.computed('args.totals'), _dec13 = Ember.computed('totals'), _dec14 = Ember.computed('itemsPerPage', 'max'), _dec15 = Ember.computed('page', 'itemsPerPage'), (_class = class PrintHeader extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "availableBudgetLeas", _descriptor2, this);

      _initializerDefineProperty(this, "features", _descriptor3, this);

      _initializerDefineProperty(this, "currentUserName", _descriptor4, this);

      _initializerDefineProperty(this, "currentBudgetLeaName", _descriptor5, this);

      _initializerDefineProperty(this, "filters", _descriptor6, this);

      _initializerDefineProperty(this, "totalItems", _descriptor7, this);

      _initializerDefineProperty(this, "itemsPerPage", _descriptor8, this);

      _initializerDefineProperty(this, "page", _descriptor9, this);

      _initializerDefineProperty(this, "showGrandTotals", _descriptor10, this);

      _initializerDefineProperty(this, "showSubTotals", _descriptor11, this);
    }

    get modelName() {
      return this.args.modelName || 'items';
    }

    get totals() {
      return this.args.totals || null;
    }

    get grandTotals() {
      return this.args.grandTotals || null;
    }

    get subTotals() {
      let defaultSubTotals = {
        originalBudget: 0,
        budgeted: 0,
        spent: 0,
        available: 0,
        encumbered: 0
      };
      if (Ember.isEmpty(this.totals)) return defaultSubTotals;
      return this.totals.reduce((acc, cur) => {
        return {
          originalBudget: acc.originalBudget + cur.originalBudget,
          budgeted: acc.budgeted + cur.budgeted,
          spent: acc.spent + cur.spent,
          available: acc.available + cur.available,
          encumbered: acc.encumbered + cur.encumbered
        };
      }, defaultSubTotals);
    }

    get min() {
      return this.max - this.itemsPerPage + 1;
    }

    get max() {
      return this.page * this.itemsPerPage;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "availableBudgetLeas", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUserName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentBudgetLeaName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "totalItems", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "itemsPerPage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showGrandTotals", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "showSubTotals", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "totals", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "totals"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "subTotals", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "subTotals"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "min", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "min"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "max", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "max"), _class.prototype)), _class));
  _exports.default = PrintHeader;
});