define("balance-ember/components/login-flow-layout/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['user-login-flow'],
    showCancelLink: Ember.computed('page', 'success', function () {
      if (this.page !== 'login' && this.success !== true) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});