define("balance-ember/templates/budgeting/fiscal-year/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gzis1GP9",
    "block": "{\"symbols\":[],\"statements\":[[8,\"container\",[],[[\"@style\"],[\"tabbed-budget\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"container\",[],[[\"@style\"],[\"horizontal centered page\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"budget/page-header\",[],[[\"@title\",\"@showBudgetTotals\"],[[30,[36,0],[\"budget.fiscal_year.tabs.reports\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"budget/page-header/fiscal-year-tabs\",[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"container\",[],[[\"@style\"],[\"horizontal centered page padding-all\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex report-list\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[8,\"budget/report-list\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ml-40 flex-full\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "moduleName": "balance-ember/templates/budgeting/fiscal-year/reports.hbs"
  });

  _exports.default = _default;
});