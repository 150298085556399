define("balance-ember/components/manage/grand-totals-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sCZlRNnQ",
    "block": "{\"symbols\":[\"@columns\",\"@extra\",\"@table\",\"@sortIcons\",\"@rowBorderColor\",\"@middleColumnPadding\",\"@firstColumnPadding\",\"&default\"],\"statements\":[[8,\"light-table/default-head-columns\",[],[[\"@columns\",\"@extra\",\"@table\",\"@sortIcons\"],[[32,1],[32,2],[32,3],[32,4]]],null],[2,\"\\n\\n\"],[10,\"tr\"],[15,0,[31,[\"bg-material-gray50 border-\",[32,5],\" border-b text-material-gray700 print:hidden\"]]],[12],[2,\"\\n\\n  \"],[10,\"th\"],[15,\"colspan\",[32,0,[\"labelColSpan\"]]],[15,0,[31,[\"text-right py-12 capitalize\\n      \",[30,[36,0],[[32,0,[\"oneVisibleColumn\"]],[32,7],[32,6]],null]]]],[12],[2,\"\\n    Grand Total:\\n  \"],[13],[2,\"\\n\\n  \"],[18,8,[[30,[36,2],null,[[\"cell\"],[[30,[36,1],[\"manage/grand-totals-header/cell\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/manage/grand-totals-header/template.hbs"
  });

  _exports.default = _default;
});