define("balance-ember/components/add-filter-button/category-list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3IEbNG8J",
    "block": "{\"symbols\":[\"@category\"],\"statements\":[[10,\"div\"],[14,0,\"flex items-center leading-none\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"text-material-gray500 group-hover:text-white w-16 h-16 flex justify-center items-center\"],[12],[2,\"\\n      \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[[32,1,[\"icon\"]],\"pointer-events-none w-16 h-16\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"span\"],[14,0,\"ml-8\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"subcategories\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"\\n      flex\\n      items-center\\n      leading-none\\n      text-material-light-blue500\\n      group-hover:text-white\"],[12],[2,\"\\n    \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"chevron\",\"rotate-270 w-12 h-12 \"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/add-filter-button/category-list/item/template.hbs"
  });

  _exports.default = _default;
});