define("balance-ember/templates/budgeting/budget/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q7Xx+/Ml",
    "block": "{\"symbols\":[],\"statements\":[[8,\"budget/page-header/budgeting\",[],[[\"@budget\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\\n\"],[8,\"container\",[],[[\"@style\"],[\"horizontal centered page padding-all\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"budget-details\",[],[[\"@budget\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\\n  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "moduleName": "balance-ember/templates/budgeting/budget/details.hbs"
  });

  _exports.default = _default;
});