define("balance-ember/components/overflowing-table-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Use this component in light-table cells that you think may overflow the container. This will
   * add ellipsis to overflowing text and a tooltip when the user hovers over to see the full text
   *
   * @param {number} column.height How tall the text can before it overflows
   */
  var _default = Ember.Component.extend({
    /**
     * The `value` passed in from `ember-light-table`
     */
    value: ''
  });

  _exports.default = _default;
});