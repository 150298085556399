define("balance-ember/components/balance-user-access-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['flex', 'items-center'],
    icon: Ember.computed('value', function () {
      return this.value ? 'checkmark' : 'no-access';
    }),
    iconColor: Ember.computed('value', function () {
      return this.value ? 'material-teal400' : 'material-red400';
    }),
    text: Ember.computed('value', function () {
      return this.value ? 'Yes' : 'No';
    })
  });

  _exports.default = _default;
});