define("balance-ember/components/filter-form/segment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0bC/0Q6T",
    "block": "{\"symbols\":[\"suggestion\",\"@filter\",\"@categoryConfig\"],\"statements\":[[8,\"filter-form/autocomplete-substring\",[],[[\"@filter\",\"@categoryConfig\",\"@formatter\",\"@noMatchesMessage\",\"@searchTask\"],[[32,2],[32,3],[32,0,[\"formatter\"]],\"No Matching Segments Found\",[32,0,[\"searchSegmentsTask\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-material-grey600\"],[12],[1,[30,[36,0],[\" > \",[32,1,[\"parentNamePath\"]]],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"font-medium material-gray700\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"h-full\"],[12],[1,[30,[36,1],[[32,1,[\"codeMarked\"]]],null]],[2,\" - \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[1,[30,[36,1],[[32,1,[\"nameMarked\"]]],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"join\",\"html-safe\"]}",
    "moduleName": "balance-ember/components/filter-form/segment/template.hbs"
  });

  _exports.default = _default;
});