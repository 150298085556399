define("balance-ember/components/budget/non-personnel-expenses-table/expanded-row/tabs/tab/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TNp3BQar",
    "block": "{\"symbols\":[\"@tab\",\"@activeTab\",\"@changeTab\",\"&default\"],\"statements\":[[2,\" \"],[8,\"button\",[[24,0,\"\\n    bg-material-light-blue100\\n    applied:font-bold\\n    applied:bg-white\\n    applied:rounded-b-none\\n  \"]],[[\"@style\",\"@testId\",\"@active\",\"@onclick\"],[\"none\",[32,1,[\"testId\"]],[30,[36,0],[[32,2],[32,1]],null],[30,[36,1],[[32,3],[32,1,[\"value\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,4,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"fn\"]}",
    "moduleName": "balance-ember/components/budget/non-personnel-expenses-table/expanded-row/tabs/tab/template.hbs"
  });

  _exports.default = _default;
});