define("balance-ember/components/employees-table/component", ["exports", "@glimmer/component", "ember-concurrency", "balance-ember/utils/budget/change-log/record-description", "balance-ember/classes/table-serializer"], function (_exports, _component, _emberConcurrency, _recordDescription, _tableSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EMPLOYEE_PAGE_SIZE = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const EMPLOYEE_PAGE_SIZE = 50;
  _exports.EMPLOYEE_PAGE_SIZE = EMPLOYEE_PAGE_SIZE;
  let BudgetEmployeesTableComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('employee', { ...this.serializedTableState,
      include: 'employee_status',
      filter: {
        fiscal_year_id: this.availableFiscalYears.current.id,
        ...this.serializedTableState.filter
      },
      stats: {
        total: 'count'
      }
    });
  }), _dec5 = Ember._action, _dec6 = Ember._action, (_class = class BudgetEmployeesTableComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor3, this);

      _defineProperty(this, "columns", [{
        label: 'EMPLOYEE NAME',
        valuePath: 'name',
        sortable: true,
        sortName: 'last_name',
        testSelector: 'employee-name',
        cellComponent: 'overflowing-table-cell'
      }, {
        label: 'CODE',
        valuePath: 'code',
        sortable: true,
        sortName: 'code',
        testSelector: 'code',
        cellComponent: 'overflowing-table-cell'
      }, {
        label: 'FTE',
        valuePath: 'fte',
        width: '80px',
        sortable: true,
        sortName: 'fte',
        testSelector: 'fte'
      }, {
        label: 'STATUS',
        valuePath: 'employeeStatus.statusName',
        sortable: true,
        sortName: 'status',
        testSelector: 'employee-status'
      }, {
        label: 'ACTUAL SALARY',
        valuePath: 'actualSalary',
        cellComponent: 'number-cell',
        sortable: true,
        sortName: 'actual_salary',
        testSelector: 'actual-salary',
        width: '175px'
      }, {
        label: 'BUDGETED SALARY',
        valuePath: 'budgetedSalary',
        cellComponent: 'number-cell',
        sortable: true,
        sortName: 'budgeted_salary',
        testSelector: 'budgeted-salary',
        width: '175px'
      }, {
        cellComponent: 'light-table/cell-components/table-actions',
        tableActionComponents: ['edit', 'delete', 'recent-changes'],
        recentChanges: {
          recordDescription(employee) {
            return [new _recordDescription.default({
              name: 'Employee',
              value: employee.name
            })];
          }

        },
        sortable: false,
        hideable: false,
        align: 'right',
        width: '80px',

        buttonText(employee) {
          return employee.name;
        },

        isDeleteDisabled: employee => !employee.deletable,

        deleteTooltipText(employee) {
          if (!employee.deletable) {
            return `${employee.name} is in use and cannot be deleted at this time`;
          }

          return `Delete ${employee.name}`;
        }

      }]);

      _initializerDefineProperty(this, "loadEmployees", _descriptor4, this);
    }

    get serializedTableState() {
      return new _tableSerializer.default(this.args.tableState).apiQuery;
    }

    edit(employee) {
      this.router.transitionTo('budgeting.employees.edit', employee.id);
    }

    delete(employee) {
      this.router.transitionTo('budgeting.employees.destroy', employee.id);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loadEmployees", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class));
  _exports.default = BudgetEmployeesTableComponent;
});