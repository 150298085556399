define("balance-ember/components/manage/recent-transactions-widget/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e2NvsUpL",
    "block": "{\"symbols\":[\"supplement\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"font-semibold\\n  border-b\\n  border-material-gray400\\n  px-16\\n  py-10\\n  text-\",[32,0,[\"data\",\"widgetColor\"]]]]],[12],[2,\"\\n  \"],[1,[30,[36,2],[[32,0,[\"data\",\"widgetType\"]]],null]],[2,\" Transaction\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"px-16\\n  py-10\\n  border-b\\n  border-material-gray400\"],[12],[2,\"\\n  \"],[8,\"manage/recent-transactions-widget/dropdown/info\",[],[[\"@label\",\"@value\"],[\"Date\",[30,[36,3],[[32,0,[\"row\",\"transactionDate\"]],\"M/D/YYYY\"],null]]],null],[2,\"\\n\\n  \"],[8,\"manage/recent-transactions-widget/dropdown/info\",[],[[\"@label\",\"@value\"],[\"Amount\",[30,[36,4],[[32,0,[\"row\",\"amount\"]]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]]],null],[2,\"\\n\\n  \"],[8,\"manage/recent-transactions-widget/dropdown/info\",[],[[\"@label\",\"@value\"],[\"Account\",[32,0,[\"row\",\"account\",\"code\"]]]],null],[2,\"\\n\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"sortedSupplements\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"manage/recent-transactions-widget/dropdown/info\",[],[[\"@label\",\"@value\"],[[30,[36,1],[[30,[36,0],[[32,1,[\"name\"]]],null]],null],[32,1,[\"value\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"humanize\",\"titleize\",\"capitalize\",\"moment-format\",\"numeral-formatter\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/manage/recent-transactions-widget/dropdown/template.hbs"
  });

  _exports.default = _default;
});