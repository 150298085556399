define("balance-ember/components/budget/expense-table/edit-strategies/core-functions/percentage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mE41/nQ0",
    "block": "{\"symbols\":[\"@percentage\"],\"statements\":[[10,\"div\"],[14,0,\"text-sm text-material-blue-gray400\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,1]],[[\"format\"],[\"0,0\"]]]],[2,\"%\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\"]}",
    "moduleName": "balance-ember/components/budget/expense-table/edit-strategies/core-functions/percentage/template.hbs"
  });

  _exports.default = _default;
});