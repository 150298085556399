define("balance-ember/components/planned-subtotals-widget/component", ["exports", "balance-ember/utils/percentages", "balance-ember/components/tagless-component/component"], function (_exports, _percentages, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    totalPersonnel: Ember.computed('fiscalYear.{personnelPlannedAmount,supplementalPlannedAmount}', function () {
      return this.fiscalYear.personnelPlannedAmount + this.fiscalYear.supplementalPlannedAmount;
    }),
    totalNonPersonnel: Ember.computed.readOnly('fiscalYear.nonPersonnelPlannedAmount'),
    totalPlanned: Ember.computed.readOnly('fiscalYear.plannedAmount'),
    plannedPercentages: Ember.computed('totalPersonnel', 'totalNonPersonnel', function () {
      let personnelPlannedAmount = this.totalPersonnel;
      let nonPersonnelPlannedAmount = this.totalNonPersonnel;
      let [personnelPlannedPercentage, nonPersonnelPlannedPercentage] = (0, _percentages.toPercentageList)([personnelPlannedAmount, nonPersonnelPlannedAmount]);
      return {
        personnelPlannedPercentage,
        nonPersonnelPlannedPercentage
      };
    }),
    personnelPlannedPercentage: Ember.computed('plannedPercentages', function () {
      return this.plannedPercentages['personnelPlannedPercentage'];
    }),
    nonPersonnelPlannedPercentage: Ember.computed('plannedPercentages', function () {
      return this.plannedPercentages['nonPersonnelPlannedPercentage'];
    })
  });

  _exports.default = _default;
});