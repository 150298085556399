define("balance-ember/components/allocation-form-data/component", ["exports", "ember-concurrency", "balance-ember/utils/writeable-model"], function (_exports, _emberConcurrency, _writeableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component loads the data necessary for the allocation form to function
   *
   * @param {object} allocation The allocation to edit if we're not creating a new one
   * @return {taskInstance} loadingData An instance of the task to load the data
   * @return {array} data.budgets All the budgets in the app
   * @return {array} data.segmentTypes All the segment types
   */
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    network: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    fiscalYear: Ember.computed.readOnly('availableFiscalYears.current'),
    allocation: null,
    budgets: null,
    segmentTypes: null,
    loadingData: null,
    loadDataTask: (0, _emberConcurrency.task)(function* () {
      let store = this.store;
      let allocation = this.allocation;

      if (Ember.isEmpty(allocation)) {
        allocation = store.createRecord('allocation');
      }

      Ember.set(this, 'allocation', new _writeableModel.default({
        model: allocation,
        store: this.store,
        network: this.network
      }));
      yield Ember.set(this, 'segmentTypes', this.fiscalYear.segmentTypes);
    }),

    init() {
      this._super(...arguments); // We want the flags that a TaskInstance offers, so we can know when the task is finished.
      // We don't want to load the form till we have all the data


      Ember.set(this, 'loadingData', this.loadDataTask.perform());
    }

  });

  _exports.default = _default;
});