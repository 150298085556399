define("balance-ember/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8MadH7EF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"print:overflow-visible\"],[12],[2,\"\\n\"],[2,\"  \"],[8,\"a11y-announcer\",[],[[],[]],null],[2,\"\\n\\n\"],[2,\"  \"],[8,\"notification-container\",[],[[\"@position\"],[\"top\"]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"app-container flex flex-row print:block\"],[12],[2,\"\\n\"],[2,\"    \"],[10,\"div\"],[15,0,[31,[\"flex flex-col flex-1-0-auto min-h-screen w-full print:block\\n      \",[32,0,[\"appBackgroundColorClass\"]]]]],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"session\",\"isAuthenticated\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"app-nav\",[],[[\"@logout\",\"@currentRouteName\"],[[30,[36,2],[[32,0],\"logout\"],null],[32,0,[\"router\",\"currentRouteName\"]]]],null],[2,\"\\n        \"],[10,\"div\"],[14,0,\"main-content\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"flex flex-col flex-1-0-auto items-center justify-center\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[8,\"app-footer\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"action\",\"if\"]}",
    "moduleName": "balance-ember/templates/application.hbs"
  });

  _exports.default = _default;
});