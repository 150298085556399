define("balance-ember/components/tag/form/bulk/tag-count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OFZjWWuB",
    "block": "{\"symbols\":[\"InlineItem\",\"@tag\"],\"statements\":[[2,\" \"],[8,\"layout/inline\",[],[[\"@space\",\"@align\"],[\"xxs\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-sm font-bold text-material-blue-gray300\"],[12],[2,\"\\n      \"],[1,[32,2,[\"count\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"leading-0 text-material-blue-gray300\"],[12],[2,\"\\n      \"],[8,\"icon-svg\",[],[[\"@icon\"],[\"tag\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/tag/form/bulk/tag-count/template.hbs"
  });

  _exports.default = _default;
});