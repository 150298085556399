define("balance-ember/utils/filters/text-formatter", ["exports", "balance-ember/utils/filters/formatter"], function (_exports, _formatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TextFormatter extends _formatter.default {
    get operatorDescriptors() {
      return {
        match: 'contains',
        not_match: 'doesn\'t contain'
      };
    }

  }

  _exports.default = TextFormatter;
});