define("balance-ember/components/ui-form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const focusableQuerySuffix = ':not([readonly]):not([disabled]):not([tabindex="-1"])';
  const focusableQueries = [`input${focusableQuerySuffix}`, `textarea${focusableQuerySuffix}`, `select${focusableQuerySuffix}`];
  /**
   * @param {task} submitTask
   */

  var _default = Ember.Component.extend({
    tagName: 'form',
    classNameBindings: ['formStyleClass'],

    didInsertElement() {
      this._super(...arguments);

      if (this.shouldAutofocus) this.autofocus();
    },

    autofocus() {
      let element = this.element.querySelector(focusableQueries.join(','));
      if (!element) return;
      element.focus();
    },

    style: 'side-label',
    errors: null,
    formStyleClass: Ember.computed('style', function () {
      let classes = '';

      if (this.style.includes('side-label')) {
        classes = `${classes} inline`;
      }

      if (this.style.includes('gray')) {
        classes = `${classes} bg-material-gray50`;
      }

      if (this.style.includes('white')) {
        classes = `${classes} bg-white`;
      }

      if (this.style.includes('h-full')) {
        classes = `${classes} h-full`;
      }

      return classes;
    }),

    focusFirstInvalidField() {
      let errorEl = this.element.querySelector('[data-has-errors] input,select');

      if (errorEl) {
        errorEl.focus();
      }
    },

    performSubmit: (0, _emberConcurrency.task)(function* () {
      if (this.submitTask) {
        yield this.submitTask.perform();
      } else if (this.onSubmit) {
        yield this.onSubmit();
      }

      if (this.errors && this.errors.length) {
        this.focusFirstInvalidField();
      }
    }),

    submit(evt) {
      evt.preventDefault(); // Prevent default form submit action which reloads the page

      this.performSubmit.perform();
    }

  });

  _exports.default = _default;
});