define("balance-ember/components/tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lm/MnkCk",
    "block": "{\"symbols\":[\"@placement\",\"@text\"],\"statements\":[[8,\"attach-tooltip\",[],[[\"@animation\",\"@arrow\",\"@lazyRender\",\"@placement\",\"@hideOn\"],[[32,0,[\"animation\"]],true,true,[30,[36,0],[[32,1],\"top\"],null],\"mouseleave blur escapekey clickout\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-sm leading-tight\"],[12],[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\"]}",
    "moduleName": "balance-ember/components/tooltip/template.hbs"
  });

  _exports.default = _default;
});