define("balance-ember/types/pagination", ["exports", "@microstates/ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Pagination {
    constructor() {
      _defineProperty(this, "page", (0, _ember.create)(Number, 1));

      _defineProperty(this, "itemsPerPage", (0, _ember.create)(Number, 25));

      _defineProperty(this, "totalItems", Number);
    }

    updatePerPage(newPerPage) {
      return this.itemsPerPage.set(newPerPage).page.set(1);
    }

    get shouldShowPagination() {
      return this.totalPages > 1;
    }

    get isLastPage() {
      return this.totalPages === this.page.state;
    }

    get totalPages() {
      if (this.itemsPerPage.state === 0) {
        return 0;
      }

      return Math.ceil(this.totalItems.state / this.itemsPerPage.state);
    }

    get offset() {
      return this.itemsPerPage.state * this.page.state - this.itemsPerPage.state;
    }

    get from() {
      return this.offset + 1;
    }

    get to() {
      return this.offset + this.itemsPerPage.state;
    }

  }

  _exports.default = Pagination;
});