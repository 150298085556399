define("balance-ember/components/ui-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8pMDmPPG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"label\",\"label-text\",\"fieldset\",\"input\",\"date\",\"number\",\"percentage\",\"currency\",\"radio\",\"checkbox\",\"textarea\",\"select\"],[[30,[36,0],[\"ui-form/ui-label\"],null],[30,[36,0],[\"ui-form/label-text\"],null],[30,[36,0],[\"ui-form/ui-fieldset\"],null],[30,[36,0],[\"one-way-input\"],null],[30,[36,0],[\"one-way-date-mask\"],[[\"options\"],[[30,[36,1],null,[[\"inputFormat\",\"outputFormat\"],[\"mm/dd/yyyy\",\"yyyy-mm-dd\"]]]]]],[30,[36,0],[\"one-way-number-mask\"],null],[30,[36,0],[\"one-way-percentage-mask\"],null],[30,[36,0],[\"one-way-currency-mask\"],[[\"options\"],[[30,[36,1],null,[[\"prefix\"],[\"$ \"]]]]]],[30,[36,0],[\"one-way-radio\"],null],[30,[36,0],[\"checkbox\"],null],[30,[36,0],[\"one-way-textarea\"],null],[30,[36,0],[\"one-way-select\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/ui-form/template.hbs"
  });

  _exports.default = _default;
});