define("balance-ember/routes/privacy-policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      document.title = 'Privacy Policy - Allovue Balance';
    }

  });

  _exports.default = _default;
});