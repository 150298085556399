define("balance-ember/components/manage-link/component", ["exports", "balance-ember/utils/basic-dropdown-calculate-nav-position"], function (_exports, _basicDropdownCalculateNavPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    features: Ember.inject.service(),
    calculatePosition: _basicDropdownCalculateNavPosition.calculatePosition,
    tagName: 'li',
    attributeBindings: ['testId:data-test-id'],
    testId: 'manage-link',
    scatterIsAvailable: Ember.computed.readOnly('features.scatterVis')
  });

  _exports.default = _default;
});