define("balance-ember/components/manage/transactions-table/index/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YsRCnoTg",
    "block": "{\"symbols\":[\"Header\",\"@columns\",\"@extra\",\"@table\",\"@sortIcons\",\"@rowBorderColor\",\"@lastColumnPadding\"],\"statements\":[[8,\"manage/grand-totals-header\",[],[[\"@columns\",\"@extra\",\"@table\",\"@sortIcons\",\"@rowBorderColor\"],[[32,2],[32,3],[32,4],[32,5],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"cell\"]],[],[[\"@testId\",\"@amount\",\"@paddingClass\"],[\"grand-total-amount\",[32,0,[\"grandTotals\",\"amount\"]],[32,7]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/manage/transactions-table/index/header/template.hbs"
  });

  _exports.default = _default;
});