define("balance-ember/components/manage/columns-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BI3c2a2u",
    "block": "{\"symbols\":[\"b\",\"column\",\"@disabled\"],\"statements\":[[8,\"icon-button\",[],[[\"@style\",\"@size\",\"@testId\",\"@disabled\",\"@buttonText\",\"@showButtonText\",\"@icon\"],[\"btn-secondary-link\",\"sm\",\"columns-dropdown-button\",[32,3],\"Hide Columns\",[30,[36,6],[[30,[36,5],[\"isXxl\"],null]],null],\"hide-columns\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"defaultLayout\"]],[],[[],[]],null],[2,\"\\n\\n  \"],[8,\"popover\",[],[[\"@placement\"],[\"bottom-start\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"p-16\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-small font-bold\"],[12],[2,\"Visible Columns\"],[13],[2,\"\\n\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[32,0,[\"hideableColumns\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"mt-16\"],[12],[2,\"\\n          \"],[10,\"label\"],[12],[2,\"\\n            \"],[8,\"checkbox\",[],[[\"@testId\",\"@checked\",\"@disabled\",\"@onupdate\"],[[30,[36,0],[\"column-checkbox-\",[32,2,[\"entries\",\"name\",\"state\"]]],null],[30,[36,1],[[32,2,[\"entries\",\"hidden\",\"state\"]]],null],[30,[36,2],[[32,0,[\"oneColumnShowing\"]],[30,[36,1],[[32,2,[\"entries\",\"hidden\",\"state\"]]],null]],null],[30,[36,3],[[32,0],\"toggle\",[32,2]],null]]],null],[2,\"\\n\\n            \"],[10,\"span\"],[14,0,\"ml-18\"],[12],[2,\"\\n              \"],[1,[30,[36,4],[[32,2,[\"entries\",\"name\",\"state\"]]],null]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"not\",\"and\",\"action\",\"humanize\",\"media\",\"or\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/manage/columns-dropdown/template.hbs"
  });

  _exports.default = _default;
});