define("balance-ember/components/table/toolbar/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j7+eJ2Rn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,1],[\" \",[30,[36,0],[\"py-14\",\"px-20\",\"not-last:border-r\",\"border-material-gray200\",\"flex\",\"items-center\",\"middle:flex-grow\"],null]],null]],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"array\",\"join\"]}",
    "moduleName": "balance-ember/components/table/toolbar/section/template.hbs"
  });

  _exports.default = _default;
});