define("balance-ember/components/manage/download-dropdown/component", ["exports", "@glimmer/component", "balance-ember/utils/filters/format-display-text", "balance-ember/classes/table-serializer", "balance-ember/components/manage/download-dropdown/summarize-filter-serializer"], function (_exports, _component, _formatDisplayText, _tableSerializer, _summarizeFilterSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DownloadDropdown = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._action, (_class = class DownloadDropdown extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "eventTracking", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "downloads", _descriptor4, this);

      _initializerDefineProperty(this, "notifications", _descriptor5, this);

      _defineProperty(this, "notificationMessage", '<div>Your download is being processed. <br> <span class=\'text-sm\'>It will download automatically when ready.</span></div>');
    }

    get serializedTableStateWithSummarize() {
      let category = this.args.summarizeState.category.entries.value;
      category = category ? category.state : '';

      if (category) {
        return { ...this.serializedTableState,
          filter: { ...this.serializedTableState.filter,
            summarize: this.serializeFilter
          }
        };
      }

      return this.serializedTableState;
    }

    get serializedTableState() {
      return new _tableSerializer.default(this.args.tableState).apiQuery;
    }

    get serializeFilter() {
      return new _summarizeFilterSerializer.default(this.args.summarizeState).serialize();
    }

    get filterSummary() {
      if (this.args.tableState.filters) {
        return (0, _formatDisplayText.formatFilters)(this.args.tableState.filters, this.args.categories);
      }

      return undefined;
    }

    download(format, close) {
      let displayFilters = (0, _formatDisplayText.formatFilters)(this.args.tableState.filters, this.args.categories);
      let table = this.serializedTableStateWithSummarize;
      let data = {
        display_filters: displayFilters,
        sort: table.sort,
        filter: table.filter,
        paginate: false
      };
      this.notifications.info(this.notificationMessage, {
        htmlContent: true
      });
      let url = `${this.args.downloadUrl}.${format}`;
      let headers = {
        Accept: 'application/vnd.api+json',
        ...this.ajax.headers
      };
      close();
      this.ajax.post(url, {
        contentType: 'application/json',
        dataType: 'json',
        data,
        headers
      }).then(result => {
        this.eventTracking.trackEvent('Downloads Data', {
          page: this.args.trackingPrefix,
          format,
          url: this.args.downloadUrl,
          filters: this.filterSummary
        });
        this.downloads.addInProgessFile(result.meta.report_id);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "eventTracking", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "downloads", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "download", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "download"), _class.prototype)), _class));
  _exports.default = DownloadDropdown;
});