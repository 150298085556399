define("balance-ember/controllers/users/forgot-password", ["exports", "balance-ember/utils/urls", "ember-cp-validations", "ember-concurrency"], function (_exports, _urls, _emberCpValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    userEmail: (0, _emberCpValidations.validator)('format', {
      type: 'email',
      allowNonTld: true
    })
  }, {
    disabled: Ember.computed.not('model.shouldValidate')
  });

  var _default = Ember.Controller.extend(validations, {
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    disabled: false,
    userEmail: null,
    shouldValidate: false,
    sendRecoveryEmail: (0, _emberConcurrency.task)(function* () {
      this.set('errors', null);
      this.set('shouldValidate', true);

      if (this.validations.isValid) {
        return yield this.ajax.request((0, _urls.URLForPasswordReset)(), {
          contentType: 'application/json',
          method: 'POST',
          data: {
            user: {
              email: this.userEmail
            }
          }
        }).then(() => {
          this.notifications.success('A password recovery email has been sent. Please check your email.');
          this.router.transitionTo('login');
        }).catch(({
          payload
        }) => {
          this.set('errors', payload.error);
          return this.set('confirmMessage', payload.show_send_confirmation_link);
        });
      }
    })
  });

  _exports.default = _default;
});