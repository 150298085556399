define("balance-ember/templates/budgeting/benefits-packages/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YG6DD/7b",
    "block": "{\"symbols\":[],\"statements\":[[8,\"benefits-package-form\",[],[[\"@benefitsPackage\",\"@buttonText\",\"@disabledText\",\"@formTitle\"],[[32,0,[\"model\"]],\"Save Changes\",\"Saving\",[30,[36,0],[\"Update \",[32,0,[\"model\",\"name\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "moduleName": "balance-ember/templates/budgeting/benefits-packages/edit.hbs"
  });

  _exports.default = _default;
});