define("balance-ember/components/budget/purchase-orders/table/action-cell/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PurchaseOrdersTableActionCell = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class PurchaseOrdersTableActionCell extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);
    }

    get fiscalYearId() {
      return this.availableFiscalYears.current.id;
    }

    get poId() {
      return this.args.value;
    }

    get poExists() {
      return this.poId;
    }

    get buttonText() {
      if (this.poExists) {
        return this.intl.t('budget.po.actions.view_po');
      }

      return this.intl.t('budget.po.actions.start_po');
    }

    get newPORoute() {
      let allocationId = this.args.row.get('allocationId');
      let vendorId = this.args.row.get('vendorId');
      return ['budgeting.fiscal-year.purchase-orders.view-report', allocationId, vendorId];
    }

    get viewPORoute() {
      return ['budgeting.fiscal-year.purchase-orders.view', this.fiscalYearId, this.poId];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PurchaseOrdersTableActionCell;
});