define("balance-ember/components/table/filter-bar/clear-all/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    componentState: 'normal',
    actions: {
      clearAllFilters() {
        if (this.componentState === 'normal') {
          Ember.set(this, 'componentState', 'deleting');
        } else {
          this.tableState.clearFilters();
        }
      },

      cancelClearAll() {
        Ember.set(this, 'componentState', 'normal');
      }

    }
  });

  _exports.default = _default;
});