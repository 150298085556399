define("balance-ember/components/light-table/columns/grand-total-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G8U/ZYUM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"text-xs font-semibold\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"column\",\"highlightDeficit\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"negative-amount\",[],[[\"@value\"],[[32,0,[\"total\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[[32,0,[\"total\"]]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,0,[\"total\"]]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\",\"if\"]}",
    "moduleName": "balance-ember/components/light-table/columns/grand-total-column/template.hbs"
  });

  _exports.default = _default;
});