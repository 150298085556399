define("balance-ember/helpers/value-of", ["exports", "@microstates/ember/helpers/value-of"], function (_exports, _valueOf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _valueOf.default;
    }
  });
  Object.defineProperty(_exports, "valueOf", {
    enumerable: true,
    get: function () {
      return _valueOf.valueOf;
    }
  });
});