define("balance-ember/components/layout/form-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "budcqFrj",
    "block": "{\"symbols\":[\"@headerText\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"text-sm bg-white border border-solid border-material-light-blue300 rounded-sm\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"bg-material-light-blue50 py-8 px-12 uppercase\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[1,[32,1]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/layout/form-section/template.hbs"
  });

  _exports.default = _default;
});