define("balance-ember/components/budget-details/budget-access/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetAccess = (_dec = Ember.inject.service, _dec2 = Ember.computed.sort('budget.approvers', 'userSortKey'), _dec3 = Ember.computed.sort('budget.builders', 'userSortKey'), _dec4 = Ember.computed.sort('budget.viewers', 'userSortKey'), _dec5 = Ember.computed.sort('budget.purchasingApprovers', 'userSortKey'), _dec6 = (0, _emberConcurrency.task)(function* () {
    return yield this.loadBudgetPrivileges();
  }), (_class = class BudgetAccess extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "sortedApprovers", _descriptor2, this);

      _initializerDefineProperty(this, "sortedBuilders", _descriptor3, this);

      _initializerDefineProperty(this, "sortedViewers", _descriptor4, this);

      _initializerDefineProperty(this, "sortedPurchasingApprovers", _descriptor5, this);

      _initializerDefineProperty(this, "loadBudgetPrivilegesTask", _descriptor6, this);
    }

    get budget() {
      return this.args.budget;
    }

    get budgetEditPath() {
      return 'budgeting.budget.details.edit';
    }

    get userSortKey() {
      return ['lastName', 'firstName'];
    }

    loadBudgetPrivileges() {
      this.store.loadRecords('budgetPrivilege', {
        include: 'user',
        fields: {
          users: 'name'
        },
        filter: {
          budget_id: {
            eq: this.budget.id
          }
        },
        paginate: false
      }).then(privileges => Ember.set(this.budget, 'budgetPrivileges', privileges));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sortedApprovers", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sortedBuilders", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sortedViewers", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sortedPurchasingApprovers", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loadBudgetPrivilegesTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BudgetAccess;
});