define("balance-ember/models/approval", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    approver: (0, _model.belongsTo)('user', {
      async: false
    }),
    budget: (0, _model.belongsTo)('budget', {
      async: false
    })
  });

  _exports.default = _default;
});