define("balance-ember/components/district-admin/tag-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aWAKXC/+",
    "block": "{\"symbols\":[\"T\",\"Controls\",\"Body\",\"Controls\"],\"statements\":[[8,\"table\",[],[[\"@tableState\",\"@columns\",\"@loadTask\",\"@modelChanges\",\"@trackingPrefix\",\"@tableActions\"],[[32,0,[\"tableState\"]],[32,0,[\"columns\"]],[32,0,[\"loadTagsTask\"]],[32,0,[\"tagChanges\"]],\"Tags\",[30,[36,1],null,[[\"edit\",\"delete\"],[[32,0,[\"edit\"]],[30,[36,0],[[32,0],\"recordToDelete\"],null]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,4,[\"pagination\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[4]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[\"@id\",\"@noDataIcon\"],[\"tag-table\",\"na-bookmarks\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"loader\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Loading Tags....\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,3,[\"noData\"]],[],[[\"@noDataText\"],[\"No tags yet.\"]],null],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"pagination\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"recordToDelete\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"district-admin/tag-table/destroy\",[],[[\"@closeModal\",\"@tag\"],[[30,[36,0],[[32,0],\"recordToDelete\",null],null],[32,0,[\"recordToDelete\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"set\",\"hash\",\"if\"]}",
    "moduleName": "balance-ember/components/district-admin/tag-table/template.hbs"
  });

  _exports.default = _default;
});