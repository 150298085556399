define("balance-ember/routes/terms-of-use", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate() {
      document.title = 'Terms of Use - Allovue Balance';
    }

  });

  _exports.default = _default;
});