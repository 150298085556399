define("balance-ember/templates/budgeting/budget/positions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cEN7aMQI",
    "block": "{\"symbols\":[\"columns\",\"onDeleteClick\"],\"statements\":[[8,\"budget/page-header/budgeting\",[],[[\"@budget\"],[[32,0,[\"model\",\"budget\"]]]],null],[2,\"\\n\\n\"],[8,\"container\",[],[[\"@style\"],[\"horizontal centered page padding-all\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"ui-hooks\",[],[[\"@budget\",\"@did-receive\"],[[32,0,[\"model\",\"budget\"]],[30,[36,0],[[32,0],\"budgetChanged\"],null]]],null],[2,\"\\n\\n  \"],[8,\"positions-table/columns-provider\",[],[[\"@fundedWidgetRoutePath\"],[[32,0,[\"fundedWidgetRoutePath\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"budget-positions-page\",[],[[\"@budget\",\"@categories\",\"@allocations\",\"@columns\",\"@summary\",\"@tableState\",\"@onDeleteClick\"],[[32,0,[\"model\",\"budget\"]],[32,0,[\"categories\"]],[32,0,[\"allocations\"]],[32,1],[32,0,[\"model\",\"summary\"]],[32,0,[\"tableState\"]],[32,2]]],null],[2,\"\\n  \"]],\"parameters\":[1,2]}]]],[2,\"\\n\\n\"],[2,\"  \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"-outlet\",\"component\"]}",
    "moduleName": "balance-ember/templates/budgeting/budget/positions.hbs"
  });

  _exports.default = _default;
});