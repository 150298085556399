define("balance-ember/components/positions-table/nested-table/footer/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h/3A/ByK",
    "block": "{\"symbols\":[\"@first\",\"&default\"],\"statements\":[[10,\"tr\"],[15,0,[31,[\"\\n  bg-white\\n  \",[30,[36,0],[[32,1],\"border-t border-material-blue-gray100\"],null],\"\\n\"]]],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/positions-table/nested-table/footer/row/template.hbs"
  });

  _exports.default = _default;
});