define("balance-ember/components/budget-progress-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/J2Zw/JD",
    "block": "{\"symbols\":[\"@fiscalYear\"],\"statements\":[[8,\"dashboard-widget\",[],[[\"@height\"],[[31,[[30,[36,1],[[30,[36,0],[\"positionBudgeting\"],null],\"full\",\"auto\"],null]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"p-16\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex items-center justify-between mb-0\"],[12],[2,\"\\n      \"],[10,\"h4\"],[14,0,\"text-base font-bold\"],[12],[2,\"Budget Progress\"],[13],[2,\"\\n      \"],[8,\"button\",[],[[\"@route\",\"@style\",\"@size\"],[[30,[36,2],[\"budgeting.fiscal-year.budgets\"],null],\"btn-primary-link\",\"sm\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        View Budgets\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"horizontal-summary-stats\",[],[[\"@class\"],[\"mt-32 pl-16 h-96 justify-around items-center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"budget-progress-widget/budget-approval-total\",[],[[\"@testId\",\"@icon\",\"@label\",\"@total\"],[\"budgets-in-progress-total\",\"budget-in-progress\",\"In Progress\",[32,1,[\"budgetsInProgress\"]]]],null],[2,\"\\n\\n      \"],[8,\"budget-progress-widget/budget-approval-total\",[],[[\"@testId\",\"@icon\",\"@label\",\"@total\"],[\"budgets-submitted-total\",\"budget-needs-approval\",\"Need Approval\",[32,1,[\"budgetsSubmitted\"]]]],null],[2,\"\\n\\n      \"],[8,\"budget-progress-widget/budget-approval-total\",[],[[\"@testId\",\"@icon\",\"@label\",\"@total\"],[\"budgets-approved-total\",\"budget-needs-finalization\",\"Need Finalizing\",[32,1,[\"budgetsApproved\"]]]],null],[2,\"\\n\\n      \"],[8,\"budget-progress-widget/budget-approval-total\",[],[[\"@testId\",\"@icon\",\"@label\",\"@total\"],[\"budgets-completed-total\",\"budget-completed\",\"Complete\",[32,1,[\"budgetsCompleted\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"feature-flag\",\"if\",\"array\"]}",
    "moduleName": "balance-ember/components/budget-progress-widget/template.hbs"
  });

  _exports.default = _default;
});