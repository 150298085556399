define("balance-ember/components/budget/purchase-orders/existing-view/amazon-purchases-table/component", ["exports", "@glimmer/component", "ember-concurrency", "@microstates/ember", "balance-ember/types/table", "balance-ember/classes/table-serializer"], function (_exports, _component, _emberConcurrency, _ember, _table, _tableSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetPurchaseOrdersExistingViewAmazonPurchasesTableComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('amazonTransactionLineItem', { ...this.serializedTableState,
      filter: { ...this.serializedTableState.filter,
        purchase_order_id: this.args.purchaseOrder.id
      },
      include: 'amazon_line_item,amazon_transaction',
      stats: {
        total: 'count'
      }
    });
  }), (_class = class BudgetPurchaseOrdersExistingViewAmazonPurchasesTableComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "tableState", _descriptor3, this);

      _defineProperty(this, "columns", [{
        label: this.intl.t('budget.po.amazon_purchases_table.product_name'),
        valuePath: 'amazonLineItem.productTitle',
        testSelector: 'item',
        cellComponent: 'light-table/cell-components/chevron-cell',
        sortable: false,
        hidden: false
      }, {
        label: this.intl.t('budget.po.amazon_purchases_table.merchant'),
        valuePath: 'amazonLineItem.merchantLegalName',
        testSelector: 'merchant',
        cellComponent: 'overflowing-table-cell',
        width: '240px',
        sortable: false,
        hidden: false
      }, {
        label: this.intl.t('budget.po.amazon_purchases_table.charge_type'),
        valuePath: 'amazonTransaction.transactionType',
        testSelector: 'charge-type',
        width: '120px',
        sortable: false,
        hidden: false
      }, {
        label: this.intl.t('budget.po.amazon_purchases_table.transaction_date'),
        valuePath: 'amazonTransaction.date',
        testSelector: 'transaction-date',
        cellComponent: 'date-cell',
        format: 'MM/DD/YYYY',
        width: '150px',
        sortableBy: [{
          label: 'Transaction Date',
          value: 'transaction_date'
        }]
      }, {
        label: this.intl.t('budget.po.amazon_purchases_table.shipping_date'),
        valuePath: 'amazonLineItem.shipmentDate',
        testSelector: 'shipping-date',
        cellComponent: 'date-cell',
        format: 'MM/DD/YYYY',
        width: '130px',
        sortable: false,
        hidden: false
      }, {
        label: this.intl.t('budget.po.amazon_purchases_table.amount_total'),
        valuePath: 'amazonLineItem.amount',
        testSelector: 'amount-total',
        cellComponent: 'number-cell',
        width: '130px',
        sortable: false,
        hidden: false
      }]);

      _initializerDefineProperty(this, "loadTask", _descriptor4, this);

      this.tableState = (0, _ember.Store)((0, _ember.create)(_table.default, {
        defaultSort: {
          column: 'transaction_date',
          dir: 'desc'
        }
      }), nextState => this.tableState = nextState);
    }

    get serializedTableState() {
      return new _tableSerializer.default(this.tableState).apiQuery;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tableState", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loadTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BudgetPurchaseOrdersExistingViewAmazonPurchasesTableComponent;
});