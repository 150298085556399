define("balance-ember/components/resource-versions/change-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "69vyLDcg",
    "block": "{\"symbols\":[\"change\",\"@value\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"mb-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"font-bold\"],[12],[2,\"\\n      \"],[1,[32,1,[\"attribute\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"mr-8\"],[12],[2,\"old:\"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[1,[32,1,[\"old\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"mr-8\"],[12],[2,\"new:\"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[1,[32,1,[\"new\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/resource-versions/change-cell/template.hbs"
  });

  _exports.default = _default;
});