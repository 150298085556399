define("balance-ember/components/budget-quick-picker/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    fetchBudgetsTask: (0, _emberConcurrency.task)(function* (query) {
      yield (0, _emberConcurrency.timeout)(300);
      return this.store.query('budget', {
        filter: {
          only_name: {
            match: query
          },
          fiscal_year_id: {
            eq: this.availableFiscalYears.current.id
          }
        },
        fields: {
          budgets: 'name'
        },
        page: {
          number: 1,
          size: 6
        }
      });
    }),
    actions: {
      goToBudgetExpensesPage(budget) {
        this.router.transitionTo('budgeting.budget.expenses', budget.id);
      }

    }
  });

  _exports.default = _default;
});