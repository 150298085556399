define("balance-ember/components/overflowing-table-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S8+kDJ+K",
    "block": "{\"symbols\":[],\"statements\":[[8,\"overflowing-text\",[],[[\"@text\",\"@height\"],[[32,0,[\"value\"]],[32,0,[\"column\",\"height\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/overflowing-table-cell/template.hbs"
  });

  _exports.default = _default;
});