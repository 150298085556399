define("balance-ember/components/allocation-edit-delete-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['allocation-edit-delete-container'],
    allocation: null,
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    isBudgetArchitect: Ember.computed.readOnly('session.isBudgetArchitect'),
    isArchitectAndEditable: Ember.computed.and('isBudgetArchitect', 'budget.isEditable'),
    actions: {
      editAllocation(id) {
        this.router.transitionTo('budgeting.budget.expenses.view.allocation.edit', id);
      },

      deleteAllocation(allocationId) {
        // The allocation destroy modal takes an array of allocationIds, so we're wrapping it here.
        let allocationIds = [allocationId];
        this.router.transitionTo('budgeting.budget.expenses.view.allocations.destroy', {
          queryParams: {
            allocationIds
          }
        });
      }

    }
  });

  _exports.default = _default;
});