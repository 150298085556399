define("balance-ember/utils/grand-total-stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pullOutGrandTotalStats = pullOutGrandTotalStats;

  function pullOutGrandTotalStats(stats = {}) {
    return Object.keys(stats).reduce((acc, key) => {
      if (!stats[key].sum) {
        return acc;
      }

      acc[key] = stats[key].sum;
      return acc;
    }, {});
  }
});