define("balance-ember/templates/manage/transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zmM9uMRG",
    "block": "{\"symbols\":[\"preferences\"],\"statements\":[[8,\"preferences-provider\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"div\"],[24,0,\"bg-white\"],[4,[38,1],[[30,[36,0],[[32,0,[\"shouldShowChart\",\"set\"]],[32,1,[\"transactionsShowChart\"]]],null]],null],[12],[2,\"\\n    \"],[8,\"manage/transactions-table\",[],[[\"@summarizeTableState\",\"@categories\",\"@filterCategories\",\"@shouldShowChart\"],[[32,0,[\"summarizeTableState\"]],[32,0,[\"categories\"]],[32,0,[\"filterCategories\"]],[32,0,[\"shouldShowChart\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\"]}",
    "moduleName": "balance-ember/templates/manage/transactions.hbs"
  });

  _exports.default = _default;
});