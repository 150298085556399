define("balance-ember/utils/writeable-models/allocation", ["exports", "balance-ember/utils/writeable-models/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AllocationWriteableModel extends _basic.default {
    constructor(args) {
      if (!args.paths) {
        args.paths = {
          positionExpenses: true,
          segments: {
            segmentType: true
          },
          budget: true
        };
      }

      super(args);
    }

    get hasPersonnelSegment() {
      return this.segments.any(({
        segmentType
      }) => {
        return (segmentType === null || segmentType === void 0 ? void 0 : segmentType.benefitSegment) || (segmentType === null || segmentType === void 0 ? void 0 : segmentType.salarySegment);
      });
    }

    get sortedSegments() {
      return this.segments.sortBy('segmentType.displayOrder');
    }

    get positionExpensesCount() {
      return this.positionExpenses.length;
    }

  }

  _exports.default = AllocationWriteableModel;
});