define("balance-ember/components/manage/summarize/data-loader/component", ["exports", "balance-ember/components/tagless-component/component", "ember-concurrency", "balance-ember/types/summarize-table", "balance-ember/components/manage/summarize/summarize-path", "@microstates/ember", "balance-ember/components/manage/summarize/data-loader/row-builder", "balance-ember/components/manage/summarize/data-loader/row-injector", "balance-ember/components/manage/summarize/data-loader/child-params", "balance-ember/components/manage/summarize/data-loader/child-update", "balance-ember/components/manage/summarize/data-loader/data-loading-state"], function (_exports, _component, _emberConcurrency, _summarizeTable, _summarizePath, _ember, _rowBuilder, _rowInjector, _childParams, _childUpdate, _dataLoadingState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @param
     */
    loadTask: null,

    /**
     * @param
     */
    loadChildrenTask: null,

    /**
     * @param
     */
    summarizeTableState: null,

    /**
     * @param
     */
    categories: null,
    forkedSummarizeTableState: null,
    loadingCycleState: '',
    summarizeState: Ember.computed.readOnly('summarizeTableState.summarize'),
    tableState: Ember.computed.readOnly('summarizeTableState.table'),
    shouldLoadTopLevelData: Ember.computed.readOnly('dataLoadingState.shouldLoadTopLevelData'),
    shouldShowLoadingState: Ember.computed.readOnly('dataLoadingState.shouldShowLoadingState'),
    shouldLoadChildren: Ember.computed.readOnly('dataLoadingState.shouldLoadChildren'),
    shouldLoadRows: Ember.computed.readOnly('dataLoadingState.shouldLoadRows'),
    areAllChildrenLoaded: Ember.computed.readOnly('dataLoadingState.areAllChildrenLoaded'),
    topSummaryCategory: Ember.computed.readOnly('dataLoadingState.topSummaryCategory'),
    summarizePaths: Ember.computed('summarizeTableState.summarize', function () {
      return new _summarizePath.AllSummarizeNodePaths(this.summarizeTableState.summarize).find();
    }),
    dataLoadingState: Ember.computed('summarizeTableState', 'forkedSummarizeTableState', 'loadingCycleState', function () {
      return new _dataLoadingState.default({
        summarizeTableState: this.summarizeTableState,
        forkedSummarizeTableState: this.forkedSummarizeTableState,
        loadingCycleState: this.loadingCycleState
      });
    }),
    topLevelPath: Ember.computed('summarizeTableState.summarize', function () {
      return new _summarizePath.SummarizePathBuilder(this.summarizeTableState.summarize).build();
    }),

    /**
     * loadRows - Moves through several tasks that each update the summarizeTableState. Each
     * time the state object is updated it will start a new "render cycle". We return after each
     * of these updates so that it's not confusing about if the code execution is continuing on after
     * a render cycle has been kicked off. To prevent infinite loops each step must guard against
     * itself being fired again after it has already done it's work. Only after it's work is done
     * should the next task be allowed to continue
     */
    loadRows: (0, _emberConcurrency.task)(function* () {
      if (this.shouldLoadTopLevelData) {
        return yield this.loadTopLevelRows.perform();
      }

      if (this.shouldShowLoadingState) {
        return this.showLoadingStates();
      } // Clear this variable so that we are able to add loading states later the next time the user
      // performs an action.


      Ember.set(this, 'loadingCycleState', '');

      if (this.shouldLoadChildren) {
        return yield this.loadChildRows.perform();
      }
    }),

    /**
     * loadTopLevelRows - Loads the rows for the very top of the hierarchy. We want these to show up
     * on the screen before anything else in cases of a full update. We want this so give the
     * perception that things are happening quickly (perceived performance)
     */
    loadTopLevelRows: (0, _emberConcurrency.task)(function* () {
      let forkedSummarizeTableState = this.copySummarizeTableState();
      let rows = yield this.loadTask.perform(this.topSummaryCategory);
      rows = rows ? rows.toArray() : []; // Don't update state and start a render cycle if there are no top level rows

      if (rows.length) {
        let decoratedRows = new _rowBuilder.default({
          rows,
          path: this.topLevelPath,
          categories: this.categories
        }).build(); // Table gets decorated rows and Summarize gets "raw" data rows. We separate these because the
        // decorated rows need to know about the summarize state and path. There will be a circular
        // dependency if the summarizeState depends on the decorated rows and the decorated rows
        // depend on the summarizeState.

        forkedSummarizeTableState = forkedSummarizeTableState.table.rows.set(decoratedRows);
        forkedSummarizeTableState = forkedSummarizeTableState.summarize.rows.set(rows);
        Ember.set(this, 'forkedSummarizeTableState', forkedSummarizeTableState);
        this.updateSummarizeTableState(forkedSummarizeTableState);
      }
    }),

    /**
     * showLoadingStates - This task rebuilds all the rows with any updates summarize state information.
     * This way a loading spinner can show on rows that have children but no rows yet.
     */
    showLoadingStates() {
      let summarizeTableStateCopy = this.addNewRowsToTable(this.copySummarizeTableState());
      Ember.set(this, 'forkedSummarizeTableState', summarizeTableStateCopy); // We need to save an indicator that we are in the middle of showing loading states. The
      // summarize state shape looks the exact same when we need to show loading state and when we
      // need to load child rows. Therefore we need something that can't be derived to make sure
      // we don't get stuck in an infinite loop

      Ember.set(this, 'loadingCycleState', _dataLoadingState.LOADING_STATES.loading); // Scheduling this summarizeState update to happen after the next render so we don't get
      // "you modified 2x on single render..."

      Ember.run.scheduleOnce('afterRender', this, this.updateSummarizeTableState, summarizeTableStateCopy);
    },

    /**
     * loadChildRows - This task updates all the children summaries in parallel.
     */
    loadChildRows: (0, _emberConcurrency.task)(function* () {
      let summarizeTableStateCopy = this.copySummarizeTableState();
      let updates = this.summarizePaths.map(path => {
        return new _childUpdate.default({
          path,
          task: this.updateChild
        }).perform();
      });
      yield (0, _emberConcurrency.all)(updates.mapBy('taskInstance'));
      summarizeTableStateCopy = this.addRowsToSummarizeStates(updates, summarizeTableStateCopy);
      summarizeTableStateCopy = this.addNewRowsToTable(summarizeTableStateCopy);
      Ember.set(this, 'forkedSummarizeTableState', summarizeTableStateCopy);
      this.updateSummarizeTableState(summarizeTableStateCopy);
    }).drop(),

    // If this is already running then don't load again
    addRowsToSummarizeStates(updates, summarizeTableState) {
      return updates.reduce((acc, update) => {
        return update.updateSummarizeState(acc.summarize);
      }, summarizeTableState);
    },

    addNewRowsToTable(summarizeTableState) {
      let rows = new _rowInjector.RowInjector({
        tableState: summarizeTableState.table,
        summarizeState: summarizeTableState.summarize,
        categories: this.categories,
        loadChildrenTask: this.updateChild
      }).inject();
      return summarizeTableState.table.rows.set(rows);
    },

    /**
     * updateChild - The task to update one child summary
     *
     * @param  {SummarizePath} path
     */
    updateChild: (0, _emberConcurrency.task)(function* (path) {
      let params = new _childParams.default({
        path
      }).build();
      let newRows = yield this.loadChildrenTask.perform(params);
      newRows = newRows ? newRows.toArray() : [];
      return newRows;
    }),

    updateSummarizeTableState(summarizeTableStateCopy) {
      return this.summarizeTableState.set((0, _ember.valueOf)(summarizeTableStateCopy));
    },

    copySummarizeTableState() {
      return (0, _ember.create)(_summarizeTable.default, (0, _ember.valueOf)(this.summarizeTableState));
    },

    didReceiveAttrs() {
      this._super();

      this.loadRows.perform();
    },

    willDestroyElement() {
      this._super(...arguments);

      Ember.run.scheduleOnce('actions', this, this.clearSummarize);
    },

    clearSummarize() {
      if (this.isDestroyed) return;
      this.tableState.rows.clear();
      this.summarizeState.set({});
    }

  });

  _exports.default = _default;
});