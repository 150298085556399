define("balance-ember/components/account-totals/amount-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FOGLUA5y",
    "block": "{\"symbols\":[\"@labelWidth\",\"@color\",\"@amountLabel\",\"@amountColor\",\"@percentage\",\"@amountFontWeight\",\"@amount\"],\"statements\":[[10,\"div\"],[14,0,\"flex justify-between items-center text-material-gray700\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[30,[36,0],[\"flex items-center whitespace-nowrap \",[32,1]],null]],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[30,[36,0],[\"rounded-full h-8 w-8 bg-\",[32,2]],null]],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-material-gray600 ml-12\"],[12],[2,\"\\n      \"],[1,[32,3]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[30,[36,0],[\"font-bold w-48 ml-8 text-\",[32,4]],null]],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,0,[\"percentageInvalid\"]],[30,[36,0],[[32,5],[32,0,[\"percentSymbol\"]]],null],\"N/A\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[30,[36,0],[\"text-right text-lg w-1/2 text-\",[32,4],\" \",[32,6]],null]],[12],[2,\"\\n    \"],[1,[30,[36,2],[[32,7]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"unless\",\"numeral-formatter\"]}",
    "moduleName": "balance-ember/components/account-totals/amount-row/template.hbs"
  });

  _exports.default = _default;
});