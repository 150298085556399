define("balance-ember/utils/graphiti-sidepost-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class GraphitiSidepostSerializer {
    constructor(snapshot, json, tempIdGenerator = Ember.guidFor) {
      this.snapshot = snapshot;
      this.json = json;
      this.tempIdGenerator = tempIdGenerator;
    }

    serialize() {
      // We don't want to graphiti sidepost for models trying to use ActiveRecord `accepts_nested_attributes_for`
      if (this.record.acceptsNestedAttributesFor) {
        return this.json;
      }

      this.initializeRelationshipsHash();
      this.initializeIncluded();
      this.serializeChildren();
      return this.json;
    }

    get record() {
      return this.snapshot.record;
    }

    serializeChildren() {
      // Each definition is an array with 1 item
      this.relationshipDefinitions.forEach(([definition]) => {
        if (definition.kind === 'hasMany') {
          let childData = this.record.hasMany(definition.name).value();
          this.serializeHasMany(definition, childData);
        } else {
          let childData = this.record.belongsTo(definition.name).value();
          this.serializeBelongsTo(definition, childData);
        }
      });
    }

    serializeBelongsTo(definition, child) {
      if (this.isChildDirty(child)) {
        new BelongsToSerializer(child, this.json, this.tempIdGenerator, definition).serialize();
      }
    }

    serializeHasMany(definition, children = []) {
      children = children || []; // in case of null

      children.forEach(child => {
        if (this.isChildDirty(child)) {
          new HasManySerializer(child, this.json, this.tempIdGenerator, definition).serialize();
        }
      });
    }

    initializeRelationshipsHash() {
      if (!this.json.data.relationships) {
        this.json.data.relationships = {};
      }
    }

    initializeIncluded() {
      if (!this.json.included) {
        this.json.included = [];
      }
    }

    isChildDirty(child) {
      return Boolean(child && (child.dirtyType || child.disassociate));
    }
    /**
     * @return {Array<RelationshipDefinition>} { name, kind }
     */


    get relationshipDefinitions() {
      // relationships is a Map, values returns an Iterator
      return [...this.record.constructor.relationships.values()];
    }

  }

  _exports.default = GraphitiSidepostSerializer;

  class RelationshipSerializer {
    constructor(child, json, tempIdGenerator, definition) {
      _defineProperty(this, "childSerializers", {
        'created': CreateSerializer,
        'updated': UpdateSerializer,
        'deleted': DestroySerializer,
        'disassociate': DisassociateSerializer
      });

      this.child = child;
      this.json = json;
      this.tempIdGenerator = tempIdGenerator;
      this.definition = definition;
    }

    get relationshipsHash() {
      return this.json.data.relationships[this.relationshipName];
    }

    get relationshipName() {
      return Ember.String.underscore(this.definition.name);
    }

    get childSerializer() {
      return this.childSerializers[this.dirtyType];
    }

    get dirtyType() {
      if (this.child.disassociate) {
        return 'disassociate';
      }

      return this.child.dirtyType;
    }

    serialize() {
      this.initializeRelationship();
      return new this.childSerializer(this.child, this.json, this.tempIdGenerator).serialize();
    }

    initializeRelationship() {
      if (!this.relationshipsHash) {
        this.json.data.relationships[this.relationshipName] = {};
      }
    }

  }

  class HasManySerializer extends RelationshipSerializer {
    serialize() {
      let data = super.serialize(); // If a child is deleted, JSONAPISerializer will have removed it from the relationships array,
      // we need to put it back in so the backend can destroy it

      if (this.dirtyType === 'created' || this.dirtyType === 'deleted') {
        this.relationshipsHash.data.push(data);
      } else {
        this.relationshipsHash.data.replace(this.indexOfChild, 1, [data]);
      }
    }

    get indexOfChild() {
      return this.relationshipsHash.data.indexOf(this.childRelationshipHashEntry);
    }

    get childRelationshipHashEntry() {
      return this.relationshipsHash.data.findBy('id', this.id);
    }

    get id() {
      return this.child.id;
    }

    initializeRelationship() {
      super.initializeRelationship();

      if (!this.relationshipsHash.data) {
        this.relationshipsHash.data = [];
      }
    }

  }

  class BelongsToSerializer extends RelationshipSerializer {
    serialize() {
      let data = super.serialize();
      this.relationshipsHash.data = data;
    }

  }

  class ChildSerializer {
    constructor(child, json, tempIdGenerator) {
      this.child = child;
      this.json = json;
      this.tempIdGenerator = tempIdGenerator;
    }

    get modelName() {
      return this.child.constructor.modelName;
    }

    serialize() {
      return this.dataHash;
    }

    get id() {
      return '';
    }

    get dataHash() {
      return {};
    }

    get includedHash() {
      return {};
    }

    get attributes() {
      return this.child.serialize().data.attributes;
    }

    get relationships() {
      return this.child.serialize().data.relationships;
    }

  }

  class CreateSerializer extends ChildSerializer {
    serialize() {
      this.json.included.push(this.includedHash);
      return this.dataHash;
    }

    get id() {
      return this._id = this._id || this.tempIdGenerator(this);
    }

    get dataHash() {
      return {
        type: this.modelName,
        'temp-id': this.id,
        method: 'create'
      };
    }

    get includedHash() {
      return {
        'temp-id': this.id,
        type: this.modelName,
        attributes: this.attributes,
        relationships: this.relationships
      };
    }

  }

  class UpdateSerializer extends ChildSerializer {
    serialize() {
      this.json.included.push(this.includedHash);
      return this.dataHash;
    }

    get id() {
      return this.child.id;
    }

    get dataHash() {
      return {
        id: this.id,
        type: this.modelName,
        method: 'update'
      };
    }

    get includedHash() {
      return {
        id: this.id,
        type: this.modelName,
        attributes: this.attributes,
        relationships: this.relationships
      };
    }

  }

  class DestroySerializer extends ChildSerializer {
    serialize() {
      return this.dataHash;
    }

    get id() {
      return this.child.id;
    }

    get dataHash() {
      return {
        id: this.id,
        type: this.modelName,
        method: 'destroy'
      };
    }

  }

  class DisassociateSerializer extends ChildSerializer {
    serialize() {
      return this.dataHash;
    }

    get id() {
      return this.child.id;
    }

    get dataHash() {
      return {
        id: this.id,
        type: this.modelName,
        method: 'disassociate'
      };
    }

  }
});