define("balance-ember/components/table/filter-bar/component", ["exports", "balance-ember/components/tagless-component/component", "@microstates/ember"], function (_exports, _component, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tableState: null,
    filters: Ember.computed.readOnly('tableState.optionalFilters'),
    categoryToEdit: (0, _ember.state)((0, _ember.create)(String)),

    isCategoryAlreadyFiltered(categoryValue) {
      return [...this.filters].findBy('category.state', categoryValue);
    },

    applyFilters(filters) {
      this.tableState.updateFilters(filters);
    },

    actions: {
      chooseCategory(nextStep, closeCategoryList, {
        value: categoryValue
      }) {
        if (this.filters && this.isCategoryAlreadyFiltered(categoryValue)) {
          closeCategoryList();
          this.categoryToEdit.set(categoryValue);
        } else {
          nextStep();
        }
      },

      applyFilters(filters) {
        filters = [...(0, _ember.valueOf)(this.tableState.requiredFilters), ...(0, _ember.valueOf)(filters)];
        this.applyFilters(filters);
      }

    }
  });

  _exports.default = _default;
});