define("balance-ember/utils/get-category-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getCategoryConfig;
  _exports.flattenedCategories = flattenedCategories;

  function getCategoryConfig(categories = [], categoryValue) {
    return flattenedCategories(categories).findBy('value', categoryValue);
  }

  function flattenedCategories(categories) {
    return categories.reduce((acc, category) => {
      if (!category.subcategories) {
        return acc.concat(category);
      }

      return acc.concat(category.subcategories);
    }, []);
  }
});