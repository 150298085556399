define("balance-ember/components/manage/summarize/cell/month/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    /**
     * @param
     */
    row: null,

    /**
     * @param
     */
    category: null,
    content: Ember.computed.readOnly('row.content'),
    month: Ember.computed.readOnly('content.month'),
    year: Ember.computed.readOnly('content.year')
  });

  _exports.default = _default;
});