define("balance-ember/routes/budgeting/fiscal-year/positions", ["exports", "balance-ember/mixins/route-history", "balance-ember/utils/urls", "balance-ember/utils/morph-keys"], function (_exports, _routeHistory, _urls, _morphKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeHistory.default, {
    ajax: Ember.inject.service(),
    features: Ember.inject.service(),
    positionBudgeting: Ember.computed.readOnly('features.positionBudgeting'),
    queryParams: {
      // We want the model to reload the data for pagination
      page: {
        refreshModel: true
      }
    },

    beforeModel() {
      if (!this.positionBudgeting) {
        this.replaceWith('/not-found');
      }
    },

    model() {
      let fiscalYear = this.modelFor('budgeting');
      let authString = this.ajax.getAuthString();
      let url = `${(0, _urls.URLForBudgetNamespace)()}/fiscal_years/${fiscalYear.id}/positions_summary${authString}`;
      return Ember.RSVP.hash({
        fiscalYear,
        staffTypes: this.store.findAll('staffType'),
        summary: this.ajax.request(url).then(summary => (0, _morphKeys.camelizeKeys)(summary))
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, 'staffTypes', model.staffTypes);
    }

  });

  _exports.default = _default;
});