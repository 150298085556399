define("balance-ember/components/delete-user-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wKIPEgBa",
    "block": "{\"symbols\":[\"@closeModal\",\"@user\"],\"statements\":[[8,\"modal/destroy\",[],[[\"@closeModal\",\"@destroy\",\"@model\",\"@text\"],[[32,1],[30,[36,0],[[32,0],\"deleteUser\"],null],[32,2],\"Deleting this user will remove them from Balance and they will no longer be able to login. Are you sure you’d like to proceed?\"]],null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/components/delete-user-modal/template.hbs"
  });

  _exports.default = _default;
});