define("balance-ember/components/delayed-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dCkO/hqs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"showBlock\",\"last\",\"isFinished\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/delayed-block/template.hbs"
  });

  _exports.default = _default;
});