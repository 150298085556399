define("balance-ember/mixins/summarize-controller", ["exports", "balance-ember/mixins/table-controller", "@microstates/ember", "balance-ember/types/table", "balance-ember/types/summarize-table", "balance-ember/classes/summarize-query-params", "balance-ember/utils/have-filters-changed"], function (_exports, _tableController, _ember, _table, _summarizeTable, _summarizeQueryParams, _haveFiltersChanged) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_tableController.default, {
    summarizeTableState: (0, _ember.state)((0, _ember.create)(_summarizeTable.default)),
    summarizeState: Ember.computed.readOnly('summarizeTableState.summarize'),
    tableState: Ember.computed.readOnly('summarizeTableState.table'),
    oldTableState: null,

    init() {
      this._super(...arguments);

      let queryParams = this.queryParams || [];
      Ember.set(this, 'queryParams', [...queryParams, 'summarize']);
      this.forkTableState();
    },

    forkTableState() {
      Ember.set(this, 'oldTableState', (0, _ember.create)(_table.default, (0, _ember.valueOf)(this.tableState)));
    },

    summarize: Ember.computed('summarizeState', {
      get() {
        return new _summarizeQueryParams.SummarizeQueryParamSerializer(this.summarizeState).stringify();
      },

      set(key, value) {
        this.updateSummarizeStateFromQueryParams(value);
        this.forkTableState();
        return value;
      }

    }),

    updateSummarizeStateFromQueryParams(query) {
      let normalized = new _summarizeQueryParams.SummarizeQueryParamNormalizer(query, this.categories).normalize();
      this.preserveExistingRowData(normalized);
      this.summarizeState.set(normalized);
    },

    preserveExistingRowData(normalized) {
      let shouldSummarize = Object.keys(normalized).length;

      if (shouldSummarize && !this.haveFiltersChanged) {
        new RowPlucker((0, _ember.valueOf)(this.summarizeState), normalized).pluck();
      } else {
        this.tableState.rows.clear();
      }
    },

    haveFiltersChanged: Ember.computed('oldTableState', 'tableState', function () {
      return (0, _haveFiltersChanged.default)({
        newTableState: this.tableState,
        oldTableState: this.oldTableState
      });
    })
  });

  _exports.default = _default;

  class RowPlucker {
    constructor(source = {}, target = {}) {
      this.source = source;
      this.target = target;
    }

    pluck(source = this.source, target = this.target) {
      if (this.shouldCopyRows(source, target)) {
        target.rows = [...source.rows];
      }

      this.recurseSummaries(source, target);
    }

    shouldCopyRows(source, target) {
      return this.doesSourceHaveRows(source) && this.doesTargetNeedRows(target);
    }

    doesSourceHaveRows(source) {
      return source.rows && source.rows.length;
    }

    doesTargetNeedRows(target) {
      return target && !target.rows;
    }

    recurseSummaries(source, target) {
      if (source.summaries && target.summaries) {
        Object.keys(source.summaries).forEach(key => {
          this.pluck(source.summaries[key], target.summaries[key]);
        });
      }
    }

  }
});