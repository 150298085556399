define("balance-ember/components/positions-table/form/review/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PositionsTableExpandedRowEditReviewComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)(function* () {
    if (this.numErrors) {
      return;
    }

    let saves = [...new Array(Number(this.numPositions))].map(() => {
      return this.args.stateMachine.position.save({
        include: ['employee', 'job_type'],
        extra_fields: {
          positions: ['percent_funded', 'budgeted_cost']
        }
      });
    });
    yield Ember.RSVP.all(saves); // We only want the table to reload for adding new rows

    if (!this.args.stateMachine.position.id) {
      this.modelChanges.addChange({
        modelType: 'position',
        changeType: 'create'
      });
    }

    this.args.stateMachine.cancel();
  }).drop(), (_class = class PositionsTableExpandedRowEditReviewComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modelChanges", _descriptor, this);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor2, this);

      _initializerDefineProperty(this, "saveTask", _descriptor3, this);
    }

    get numErrors() {
      return Object.keys(this.args.stateMachine.errors ?? {}).filter(key => this.args.stateMachine.errors[key]).length;
    }

    get numPositions() {
      return this.args.stateMachine.numPositions;
    }

    get changeType() {
      return this.args.stateMachine.position.id ? 'Edit' : 'Create';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "saveTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PositionsTableExpandedRowEditReviewComponent;
});