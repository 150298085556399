define("balance-ember/classes/model-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    // create, update, delete
    changeType: '',
    id: null,
    modelType: ''
  });

  _exports.default = _default;
});