define("balance-ember/components/budget/allocation-segments/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localStorage: Ember.inject.service(),
    classNameBindings: ['displayAllocationSegments:segments-expanded:', 'useTwoColumns:two-column-layout:'],
    useTwoColumns: Ember.computed.gt('segments.length', 4),
    displayAllocationSegments: Ember.computed.alias('localStorage.ephemeralProps.displayAllocationSegments'),
    showHideLabel: Ember.computed('displayAllocationSegments', function () {
      return this.displayAllocationSegments ? 'Hide' : 'Show';
    }),
    actions: {
      toggleSegments() {
        this.toggleProperty('displayAllocationSegments');
        this.localStorage.persist();
      }

    }
  });

  _exports.default = _default;
});