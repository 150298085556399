define("balance-ember/classes/summarize-query-params", ["exports", "balance-ember/utils/query-params", "balance-ember/utils/get-category-config"], function (_exports, _queryParams, _getCategoryConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SummarizeQueryParamNormalizer = _exports.SummarizeQueryParamSerializer = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SummarizeQueryParamSerializer {
    constructor(summarizeState) {
      this.summarizeState = summarizeState;
    }

    serialize() {
      if (!this.hasCategory()) {
        return;
      }

      let category = this.extractCategoryValue();
      return {
        category,
        ...this.convertSummaries()
      };
    }

    stringify() {
      if (!this.hasCategory()) {
        return;
      }

      return _queryParams.default.stringify(this.serialize());
    }

    convertSummaries(summaries = this.summarizeState.summaries) {
      return summaries.keys.reduce((acc, key) => {
        acc[_queryParams.default.encodePart(key)] = this.buildSummaryObject(key, summaries);
        return acc;
      }, {});
    }

    buildSummaryObject(key, summaries) {
      let summary = summaries.entries[key];
      let obj = { ...this.convertSummaries(summary.summaries)
      };

      if (this.hasCategory(summary)) {
        obj.category = this.extractCategoryValue(summary.category);
      }

      return Object.keys(obj).length ? obj : '';
    }

    hasCategory(summary = this.summarizeState) {
      return Boolean(summary.category.keys.length);
    }

    extractCategoryValue(category = this.summarizeState.category) {
      return category.entries.value.state;
    }

  }

  _exports.SummarizeQueryParamSerializer = SummarizeQueryParamSerializer;

  class SummarizeQueryParamNormalizer {
    constructor(params, categories) {
      _defineProperty(this, "AVERAGE_SEGMENT_HIERARCHY_DEPTH", 3);

      this.categories = categories;
      this.data = _queryParams.default.parse(params, {
        parseArrays: false,
        depth: this.parseDepth
      });
    }

    get parseDepth() {
      // For security reasons `qs` lib will only parse a default depth of 5. We need more than
      // this sometimes for a deeply nested summarize.
      // https://www.npmjs.com/package/qs
      return this.AVERAGE_SEGMENT_HIERARCHY_DEPTH * (0, _getCategoryConfig.flattenedCategories)(this.categories).length;
    }

    normalize() {
      let category = this.getCategory();

      if (!category) {
        return {};
      }

      return {
        category,
        summaries: this.convertSummaries()
      };
    }

    convertSummaries(data = this.data) {
      return Object.keys(data).reduce((acc, key) => {
        if (key === 'category') {
          return acc;
        }

        return { ...acc,
          [key]: this.buildSummaryObject(key, data)
        };
      }, {});
    }

    getCategory(data = this.data) {
      return (0, _getCategoryConfig.default)(this.categories, data.category);
    }

    buildSummaryObject(key, data) {
      let normalized = {};
      let summary = data[key];

      if (summary.category) {
        normalized.category = this.getCategory(summary);
      }

      if (this.hasChildren(summary)) {
        normalized.summaries = this.convertSummaries(summary);
      }

      return normalized;
    }

    hasChildren(data) {
      return Object.keys(data).any(key => key !== 'category');
    }

  }

  _exports.SummarizeQueryParamNormalizer = SummarizeQueryParamNormalizer;
});