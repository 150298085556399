define("balance-ember/components/budgeting-link/architect/config-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DJZkCBOj",
    "block": "{\"symbols\":[\"@route\",\"@testId\",\"@buttonText\"],\"statements\":[[8,\"icon-button\",[[24,0,\"text-lg\"]],[[\"@style\",\"@route\",\"@testId\",\"@icon\",\"@buttonText\",\"@showButtonText\"],[\"btn-subtle-link\",[32,1],[32,2],\"admin\",[32,3],true]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/budgeting-link/architect/config-link/template.hbs"
  });

  _exports.default = _default;
});