define("balance-ember/initializers/humanize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    String.prototype.humanize = function () {
      let str;
      str = this.replace(/_id$/, '').replace(/[_-]/g, ' ').replace(/([a-z\d]*)/gi, function (match) {
        return match.toLowerCase();
      });
      return str.split('.').pop();
    };
  }

  var _default = {
    name: 'humanize',
    initialize
  };
  _exports.default = _default;
});