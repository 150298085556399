define("balance-ember/utils/query-params", ["exports", "qs"], function (_exports, _qs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    stringify(data, options = {}) {
      let commaSanitized = replaceComma(data);
      return _qs.default.stringify(commaSanitized, {
        arrayFormat: 'comma',
        encodeValuesOnly: true,
        ...options
      });
    },

    parse(params, options = {}) {
      let commaSanitized = replaceHTMLEncodedCommma(params);

      let parsed = _qs.default.parse(commaSanitized, {
        comma: true,
        ...options
      });

      return replaceUTF8EncodedComma(parsed);
    },

    encodePart(text) {
      return window.encodeURIComponent(text);
    },

    decodePart(text) {
      return window.decodeURIComponent(text);
    }

  };
  _exports.default = _default;
  const UTF8_COMMA = '0x2C';
  const HTML_COMMA = '%2C';
  /**
   * We're using commas in the query paramaters as a meaningful character
   * to separate values in arrays. This is problematic if a data value
   * has a comma in it because there will be no way after encoding to
   * distinguish what is a separate array item vs what is just a
   * comma in text. This function will replace the commas with a
   * special encoding, that isn't a url encoding, to let us know that it
   * is supposed to be a regular comma when we parse it back into an object.
   * @param {object} data
   */

  function replaceComma(data) {
    if (typeof data === 'string') {
      return data.replace(/,/g, UTF8_COMMA);
    }

    if (Ember.isArray(data)) {
      return data.map(item => replaceComma(item));
    }

    if (typeof data === 'object') {
      Object.keys(data).forEach(key => {
        data[key] = replaceComma(data[key]);
      });
    }

    return data;
  }
  /**
   * qs 6.9.3 changed the way comma separted parsing worked. It now treats %2C as part
   * of an array value rather than a delimiter. This breaks things for us because ember
   * router always encodes our commas when they go in the url. We therefore have to
   * convert any %2C commas into `,` so that qs will separate array items when we need it
   * to
   * @param {string} data
   * @returns string
   */


  function replaceHTMLEncodedCommma(data) {
    if (typeof data === 'string') {
      return data.replace(new RegExp(HTML_COMMA, 'g'), ',');
    }

    return data;
  }

  function replaceUTF8EncodedComma(data) {
    if (typeof data === 'string') {
      return data.replace(new RegExp(UTF8_COMMA, 'g'), ',');
    }

    if (Ember.isArray(data)) {
      return data.map(item => replaceUTF8EncodedComma(item));
    }

    if (typeof data === 'object') {
      Object.keys(data).forEach(key => {
        data[key] = replaceUTF8EncodedComma(data[key]);
      });
    }

    return data;
  }
});