define("balance-ember/components/percentage-bar/bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XgPlgO8b",
    "block": "{\"symbols\":[\"@bar\"],\"statements\":[[10,\"div\"],[15,5,[32,1,[\"inlineStyle\"]]],[15,0,[31,[\"\\n    \",[32,1,[\"color\"]],\"\\n    \",[32,1,[\"borderRadiusClasses\"]],\"\\n    \",[32,1,[\"borderClasses\"]],\"\\n    \",[32,1,[\"borderColorClass\"]]]]],[12],[2,\"\\n\\n\"],[6,[37,1],[[32,1,[\"innerRadius\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"\\n      relative\\n      left-3\\n      \",[30,[36,1],[[32,0,[\"hasNoWrapperBorder\"]],\"-mt-px h-full-plus-2 \",\"h-full \"],null],\"\\n      \",[30,[36,1],[[32,1,[\"border\"]],[30,[36,0],[\"border-r \",[32,1,[\"borderColorClass\"]]],null]],null],\"\\n      \",[32,1,[\"color\"]],\"\\n      rounded-r\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\"]}",
    "moduleName": "balance-ember/components/percentage-bar/bar/template.hbs"
  });

  _exports.default = _default;
});