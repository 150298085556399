define("balance-ember/templates/budgeting/fiscal-year/purchase-orders/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8vXMEZw0",
    "block": "{\"symbols\":[],\"statements\":[[8,\"container\",[],[[\"@style\"],[\"tabbed-budget\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"container\",[],[[\"@style\"],[\"horizontal centered page\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"budget/page-header\",[],[[\"@title\",\"@showBudgetTotals\"],[[30,[36,0],[\"budget.fiscal_year.tabs.purchase_orders\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"budget/page-header/fiscal-year-tabs\",[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"container\",[],[[\"@style\"],[\"horizontal centered page padding-all\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"budget/purchase-orders/table\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "balance-ember/templates/budgeting/fiscal-year/purchase-orders/index.hbs"
  });

  _exports.default = _default;
});