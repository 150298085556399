define("balance-ember/components/benefits-package-form/component", ["exports", "balance-ember/utils/refresh-route", "ember-concurrency"], function (_exports, _refreshRoute, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    availableFiscalYears: Ember.inject.service(),
    modelChanges: Ember.inject.service(),
    fiscalYear: Ember.computed.readOnly('availableFiscalYears.current'),
    benefitsPackage: null,
    buttonText: '',
    disabledText: '',
    formTitle: '',
    // We're not saving benefits directly to the package because
    // benefits are polymorphic and we therefore don't know which
    // benefit model we'll need until the user saves the benefits package.
    benefits: null,
    segmentTypes: null,
    benefitSegmentType: Ember.computed('segmentTypes.[]', function () {
      return this.segmentTypes.findBy('benefitSegment', true);
    }),
    isCreating: false,
    autofocusNewBenefits: false,
    benefitsPackageSnapshot: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'segmentTypes', []); // If we're have 'save and add another' button we don't want it to disappear in between when
      // the model is successfully saved and we clear out all the fields and start over. That's why
      // we're not doing this in a computed property

      if (this.benefitsPackage.isNew) {
        Ember.set(this, 'isCreating', true);
      }

      let benefitsPackage = this.benefitsPackage;
      Ember.set(this, 'benefitsPackageSnapshot', benefitsPackage.takeSnapshot({
        flatRateBenefits: true,
        percentageBenefits: true
      }));
      let segmentTypes = this.fiscalYear.segmentTypes;
      let benefits = this.benefitsPackage.benefits || []; // We need to call `toArray` on the benefits in case they are a `hasMany` EmberData relationship
      // type, because when we go to add new benefits as POJOs it won't work.

      Ember.set(this, 'benefits', benefits.toArray());
      Ember.set(this, 'segmentTypes', segmentTypes); // We want a new benefits package to start with one possible benefit

      if (benefitsPackage.isNew) {
        this.createNewBenefit();
      }
    },

    createNewBenefit() {
      let benefits = this.benefits;
      let newBenefit = this.store.createRecord('percentage-benefit', {
        isBenefitTypeEditable: true,
        segment: null
      });
      benefits.addObject(newBenefit);
    },

    goToBenefitsPackagesIndex() {
      this.router.transitionTo('budgeting.benefits-packages');
    },

    saveBenefitsPackage: (0, _emberConcurrency.task)(function* (afterSave) {
      let benefitsPackage = this.benefitsPackage;
      let benefits = this.benefits;
      let flatRateBenefits = benefits.filterBy('benefitType', 'flat-rate-benefit');
      let percentageBenefits = benefits.filterBy('benefitType', 'percentage-benefit');
      benefitsPackage.mergeNestedChanges({
        flatRateBenefits,
        percentageBenefits
      });
      Ember.set(benefitsPackage, 'fiscalYear', this.fiscalYear);
      Ember.set(benefitsPackage, 'shouldValidate', true);

      if (benefitsPackage.validations.isValid) {
        yield benefitsPackage.batchSave().then(() => {
          this.modelChanges.addChange({
            id: benefitsPackage.id,
            modelType: 'benefitsPackage',
            changeType: this.isCreating ? 'create' : 'update'
          });

          if (afterSave) {
            afterSave();
          } else {
            this.goToBenefitsPackagesIndex();
          }
        });
      }
    }).drop(),
    actions: {
      addBenefit() {
        Ember.set(this, 'autofocusNewBenefits', true);
        this.createNewBenefit();
      },

      addAnother() {
        // Reset autofocus boolean, we don't want the createNewBenefit call to override form focus
        Ember.set(this, 'autofocusNewBenefits', false); // Reset the benefits, so we don't have the previous package's benefits lingering

        Ember.set(this, 'benefits', []); // We don't want errors showing as soon as the form opens

        Ember.set(this, 'benefitsPackage.shouldValidate', false); // Have the `new` route create a new model for us

        (0, _refreshRoute.default)('budgeting.benefits-packages.new', this);
        this.createNewBenefit();
      },

      cancel() {
        // We need to make sure any changes that happen to child benefits are undone if the user
        // presses cancel
        this.benefitsPackage.restoreSnapshot(this.benefitsPackageSnapshot);
        this.goToBenefitsPackagesIndex();
      },

      chooseSegment(benefit, segment) {
        Ember.set(benefit, 'segment', segment);
      },

      changeBenefitType(benefit, benefitType) {
        Ember.set(benefit, 'amount', null);
        let segment = benefit.segment; // create a new benefit so that validations will be picked up correctly

        let newBenefit = this.store.createRecord(benefitType, {
          segment,
          isBenefitTypeEditable: true
        }); // swap out the old benefit for the new ones

        let existingIndex = this.benefits.indexOf(benefit);
        this.benefits.removeAt(existingIndex);
        this.benefits.insertAt(existingIndex, newBenefit);
      },

      removeBenefit(benefit) {
        this.benefits.removeObject(benefit);
      }

    }
  });

  _exports.default = _default;
});