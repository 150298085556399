define("balance-ember/components/blank-template/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is simply yields an empty template. Nothing else. No html. Nothing. Can be helpful for
   * yielding out different blocks from a component (named block RFC is not part of Ember yet, so
   * this will have to do for now). This technique was found at
   * https://dockyard.com/blog/2018/11/26/how-to-yield-an-ember-component-in-multiple-places
   */
  var _default = _component.default.extend({});

  _exports.default = _default;
});