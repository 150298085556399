define("balance-ember/components/budget/reports/overassigned-employees/employees-positions-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KBIkl+XX",
    "block": "{\"symbols\":[\"position\",\"positionId\",\"@row\"],\"statements\":[[10,\"div\"],[14,0,\"font-bold whitespace-nowrap\"],[12],[2,\"\\n  \"],[8,\"overflowing-text\",[],[[\"@text\"],[[30,[36,0],[[32,3,[\"content\",\"firstName\"]],\" \",[32,3,[\"content\",\"lastName\"]]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,3,[\"content\",\"overassignedPositions\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"text-15 text-material-gray650 truncate\"],[12],[2,\"\\n      \"],[1,[32,1,[\"jobTitle\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"-each-in\",\"each\"]}",
    "moduleName": "balance-ember/components/budget/reports/overassigned-employees/employees-positions-cell/template.hbs"
  });

  _exports.default = _default;
});