define("balance-ember/mixins/expense-allocation-subtotal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    allocationRemainingAmount: Ember.computed('expenseSubtotal', 'model.allocation.remainingAmount', function () {
      var _this$model$allocatio;

      let allocationRemaining = this.model ? (_this$model$allocatio = this.model.allocation) === null || _this$model$allocatio === void 0 ? void 0 : _this$model$allocatio.remainingAmount : 0.0;
      let expenseUsed = this.expenseSubtotal || 0.0;
      return allocationRemaining - expenseUsed;
    })
  });

  _exports.default = _default;
});