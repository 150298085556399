define("balance-ember/components/promote-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "65tVdKrk",
    "block": "{\"symbols\":[\"@disabled\",\"@buttonText\",\"@tooltipText\",\"@showButtonText\",\"@districtwide\",\"@displayText\",\"@click\"],\"statements\":[[8,\"icon-button\",[],[[\"@style\",\"@disabled\",\"@tooltipTitle\",\"@showButtonText\",\"@icon\",\"@buttonText\",\"@displayText\",\"@onclick\"],[\"btn-subtle-link\",[32,1],[30,[36,1],[[32,3],[30,[36,0],[\"Promote \",[32,2]],null]],null],[32,4],[30,[36,2],[[32,5],\"district-cancel1\",\"district\"],null],[31,[\"Promote \",[32,2]]],[32,6],[32,7]]],null],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"or\",\"if\"]}",
    "moduleName": "balance-ember/components/promote-button/template.hbs"
  });

  _exports.default = _default;
});