define("balance-ember/utils/filters/formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Formatter {
    constructor(filter, categoryConfig) {
      _defineProperty(this, "filter", void 0);

      _defineProperty(this, "categoryConfig", void 0);

      this.filter = filter;
      this.categoryConfig = categoryConfig;
    }

    format() {
      if (this.numFilters > 1) {
        return `${this.categoryTitle} has ${this.numFilters} filters`;
      }

      return `${this.categoryTitle} ${this.operatorDescriptor} ${this.formatValue(this.firstValue)}`;
    }

    get numFilters() {
      return this.filter.totalFilterValues;
    }

    get categoryTitle() {
      return this.categoryConfig.title;
    }

    get operatorDescriptor() {
      return this.operatorDescriptors[this.operatorValue];
    }

    get operatorDescriptors() {
      return {};
    }

    get operatorValue() {
      return this.filter.firstOperator.value.state;
    }

    formatValue(value) {
      return value;
    }

    get firstValue() {
      return this.filter.firstOperator.firstFilterValue;
    }

  }

  _exports.default = Formatter;
});