define("balance-ember/components/budget/expense-table/edit-strategies/errors/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BudgetExpensesTableEditStrategiesErrorsComponent = (_dec = Ember.computed('args.{errors.all,expenseStrategy.budgetStrategy}'), _dec2 = Ember.computed('args.{errors.all,expenseStrategy.percentage}'), _dec3 = Ember.computed('args.errors.all'), (_class = class BudgetExpensesTableEditStrategiesErrorsComponent extends _component.default {
    get errors() {
      return [this.budgetStrategyError, this.percentageError, this.sumError].compact();
    }

    get budgetStrategyError() {
      if (!this.args.expenseStrategy.budgetStrategy) {
        var _this$args$errors, _this$args$errors$all;

        return (_this$args$errors = this.args.errors) === null || _this$args$errors === void 0 ? void 0 : (_this$args$errors$all = _this$args$errors.all.findBy('attribute', 'expenseStrategies.budgetStrategy')) === null || _this$args$errors$all === void 0 ? void 0 : _this$args$errors$all.message;
      }

      return undefined;
    }

    get percentageError() {
      if (!this.args.expenseStrategy.percentage) {
        var _this$args$errors2, _this$args$errors2$al;

        return (_this$args$errors2 = this.args.errors) === null || _this$args$errors2 === void 0 ? void 0 : (_this$args$errors2$al = _this$args$errors2.all.findBy('attribute', 'expenseStrategies.percentage')) === null || _this$args$errors2$al === void 0 ? void 0 : _this$args$errors2$al.message;
      }

      return undefined;
    }

    get sumError() {
      var _this$args$errors3, _this$args$errors3$al;

      return (_this$args$errors3 = this.args.errors) === null || _this$args$errors3 === void 0 ? void 0 : (_this$args$errors3$al = _this$args$errors3.all.findBy('attribute', 'expenseStrategies')) === null || _this$args$errors3$al === void 0 ? void 0 : _this$args$errors3$al.message;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "budgetStrategyError", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "budgetStrategyError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "percentageError", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "percentageError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sumError", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "sumError"), _class.prototype)), _class));
  _exports.default = BudgetExpensesTableEditStrategiesErrorsComponent;
});