define("balance-ember/components/table/actions-popover/delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CVYdyqFW",
    "block": "{\"symbols\":[\"@tableAction\"],\"statements\":[[10,\"div\"],[14,0,\"leading-none\"],[12],[2,\"\\n  \"],[8,\"button\",[],[[\"@style\",\"@testId\",\"@disabled\",\"@onclick\"],[\"btn-primary-link\",\"delete\",[32,1,[\"disabled\"]],[30,[36,1],[[32,0,[\"onClick\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"global.buttons.delete\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,2],[[32,1,[\"disabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tooltip\",[],[[\"@text\"],[[30,[36,0],[[32,1,[\"disabledMessage\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\",\"if\"]}",
    "moduleName": "balance-ember/components/table/actions-popover/delete/template.hbs"
  });

  _exports.default = _default;
});