define("balance-ember/components/filter-form/multi/completed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tOpP2Bhr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[8,\"selected-box\",[],[[\"@onDeselectClick\",\"@selected\"],[[32,0,[\"removeFilter\"]],[30,[36,0],[[32,0,[\"operatorDescriptor\"]],\": \",[32,0,[\"displayText\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"max-w-320\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"font-bold\"],[12],[1,[32,0,[\"operatorDescriptor\"]]],[2,\":\"],[13],[2,\"\\n      \"],[1,[32,0,[\"displayText\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "moduleName": "balance-ember/components/filter-form/multi/completed/template.hbs"
  });

  _exports.default = _default;
});