define("balance-ember/components/manage/account-details/transaction-amount-cell/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    amount: Ember.computed.readOnly('value'),
    actualStyle: 'text-material-indigo600',
    encumberedStyle: 'text-material-cyan600',
    budgetStyle: 'text-material-amber900',
    status: Ember.computed.readOnly('row.content.status'),
    statusStyle: Ember.computed('actualStyle', 'budgetStyle', 'encumberedStyle', 'status', function () {
      let statusStyle;

      switch (this.status) {
        case 'actual':
          statusStyle = this.actualStyle;
          break;

        case 'budget':
          statusStyle = this.budgetStyle;
          break;

        case 'encumbrance':
          statusStyle = this.encumberedStyle;
          break;
      }

      return statusStyle;
    })
  });

  _exports.default = _default;
});