define("balance-ember/initializers/validate", ["exports", "validate.js", "balance-ember/utils/validatejs/validators/array", "balance-ember/utils/validatejs/validators/sum", "balance-ember/utils/validatejs/validators/vendor", "moment"], function (_exports, _validate, _array, _sum, _vendor, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _validate.default.extend(_validate.default.validators.datetime, {
      // The value is guaranteed not to be null or undefined but otherwise it
      // could be anything.
      parse(value) {
        return Number(_moment.default.utc(value, 'YYYY-MM-DD', true)); // boolean makes Moment use strict parsing
      },

      // Input is a unix timestamp
      format(value, options) {
        let format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
        return _moment.default.utc(value).format(format);
      }

    });

    _validate.default.validators.sum = _sum.default;
    _validate.default.validators.array = _array.default;
    _validate.default.validators.vendor = _vendor.default; // Set default message as i18n paths so we can translate the error messages

    _validate.default.validators.presence.message = 'global.validation.rules.presence.enabled';
    _validate.default.validators.email.message = 'global.validation.rules.email.enabled';
    _validate.default.validators.length.wrongLength = 'global.validation.rules.length.is';
    _validate.default.validators.length.tooLong = 'global.validation.rules.length.maximum';
    _validate.default.validators.length.tooShort = 'global.validation.rules.length.minimum';
    _validate.default.validators.numericality.notValid = 'global.validation.rules.numericality.strict';
    _validate.default.validators.numericality.notInteger = 'global.validation.rules.numericality.only_integer';
    _validate.default.validators.numericality.notGreaterThan = 'global.validation.rules.numericality.greater_than';
    _validate.default.validators.numericality.notGreaterThanOrEqualTo = 'global.validation.rules.numericality.greater_than_or_equal_to';
    _validate.default.validators.numericality.notEqualTo = 'global.validation.rules.numericality.equal_to';
    _validate.default.validators.numericality.notLessThan = 'global.validation.rules.numericality.less_than';
    _validate.default.validators.numericality.notLessThanOrEqualTo = 'global.validation.rules.numericality.less_than_or_equal_to';
    _validate.default.validators.numericality.notDivisibleBy = 'global.validation.rules.numericality.divisible_by';
    _validate.default.validators.numericality.notOdd = 'global.validation.rules.numericality.odd';
    _validate.default.validators.numericality.notEven = 'global.validation.rules.numericality.even';
    _validate.default.validators.url.message = 'global.validation.rules.url.enabled';
    _validate.default.validators.equality.message = 'global.validation.rules.equality.enabled';
    _validate.default.validators.datetime.notValid = 'global.validation.rules.datetime.valid';
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});