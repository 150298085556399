define("balance-ember/utils/validators/supplemental-pay-expense", ["exports", "balance-ember/utils/validator"], function (_exports, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class _default extends _validator.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "constraints", {
        supplementalPayType: {
          presence: {
            allowEmpty: false
          }
        },
        quantity: {
          presence: {
            allowEmpty: false
          },
          numericality: {
            greaterThan: 0
          }
        },
        segments: {
          length: {
            is: this.options.numSegments,
            message: 'budget.supplemental_pay_expenses.validation.segments'
          }
        },
        expenseStrategies: {
          sum: {
            key: 'percentage',
            lessThanOrEqualTo: 100
          },
          array: {
            percentage: {
              numericality: {
                greaterThan: 0
              },
              presence: true
            },
            budgetStrategy: {
              presence: true
            }
          }
        }
      });

      _defineProperty(this, "constraintPropertyLookups", {
        supplementalPayType: 'budget.supplemental_pay_expenses.data.supplemental_pay_type',
        quantity: 'budget.supplemental_pay_expenses.data.quantity',
        segments: 'budget.supplemental_pay_expenses.data.segments'
      });
    }

  }

  _exports.default = _default;
});