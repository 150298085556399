define("balance-ember/helpers/segment-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatSegmentName = formatSegmentName;
  _exports.segmentName = segmentName;
  _exports.default = void 0;

  function formatSegmentName(code, name) {
    return `${code} - ${name}`;
  }

  function segmentName(params
  /*, hash*/
  ) {
    let [code, name] = params;

    if (Ember.isBlank(code) || Ember.isBlank(name)) {
      return '';
    }

    return formatSegmentName(code, name);
  }

  var _default = Ember.Helper.helper(segmentName);

  _exports.default = _default;
});