define("balance-ember/templates/budgeting/budget-priorities/destroy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CIbR7Xfa",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"modal/destroy\",[],[[\"@closeModal\",\"@destroy\",\"@model\",\"@text\"],[[30,[36,0],[[32,0],\"cancelBudgetPriority\"],null],[30,[36,0],[[32,0],\"deleteBudgetPriority\"],null],[32,1],\"Deleting this will permanently remove it\"]],null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "balance-ember/templates/budgeting/budget-priorities/destroy.hbs"
  });

  _exports.default = _default;
});