define("balance-ember/components/ui-modal/modal-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l1GfM07i",
    "block": "{\"symbols\":[\"&default\",\"@errors\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,0],[[32,0,[\"isSticky\"]],\"sticky top-0 z-10\"],null]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"\\n    py-12\\n    px-20\\n    text-xl\\n    flex\\n    items-center\\n    \",[32,0,[\"styleClasses\"]]]]],[12],[2,\"\\n\\n    \"],[18,1,null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"ml-auto leading-none\"],[12],[2,\"\\n      \"],[8,\"icon-button\",[],[[\"@style\",\"@size\",\"@icon\",\"@buttonText\",\"@onclick\"],[\"btn-white-link\",\"xlg\",\"cancel-x\",\"Close\",[30,[36,1],[[32,0],[32,0,[\"closeModal\"]]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"ui-modal/errors\",[],[[\"@errors\"],[[32,2]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\"]}",
    "moduleName": "balance-ember/components/ui-modal/modal-header/template.hbs"
  });

  _exports.default = _default;
});