define("balance-ember/components/allocation-form/segments/confirm-changes/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    segmentsDiffs: Ember.computed('allocation.{model.segments,segments.[]}', function () {
      let diffs = [];
      let newSegments = this.allocation.segments;
      let oldSegments = this.allocation.model.segments;
      newSegments.forEach(newSegment => {
        let oldSegment = oldSegments.findBy('segmentType.id', newSegment.segmentType.id);

        if (!oldSegment) {
          diffs.push({
            new: newSegment.name,
            attribute: newSegment.segmentType.name
          });
        } else if (oldSegment.code !== newSegment.code) {
          diffs.push({
            new: newSegment.name,
            old: oldSegment.name,
            attribute: newSegment.segmentType.name
          });
        }
      });
      oldSegments.forEach(oldSegment => {
        let newSegment = newSegments.findBy('segmentType.id', oldSegment.segmentType.id);

        if (!newSegment) {
          diffs.push({
            unlockedSegment: oldSegment.name,
            attribute: oldSegment.segmentType.name
          });
        }
      });
      return diffs;
    })
  });

  _exports.default = _default;
});