define("balance-ember/mixins/positions-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    staffTypes: null,
    store: Ember.inject.service(),
    categories: Ember.computed('allocations', 'staffTypes.[]', function () {
      return [{
        title: 'Allocation ID',
        value: 'allocation_id',
        type: 'select',
        removable: false,
        filterOptions: {
          options: this.allocations,
          valuePath: 'id',
          labelPath: 'name'
        }
      }, {
        title: 'Position ID',
        value: 'code',
        type: 'text'
      }, {
        title: 'Job Title',
        value: 'job_type_title',
        type: 'text'
      }, {
        title: 'Employee',
        value: 'employee_name',
        type: 'text'
      }, {
        title: 'Budget',
        value: 'budget_name',
        type: 'text'
      }, {
        title: 'Position Type',
        value: 'staff_type_id',
        type: 'select',
        filterOptions: {
          options: this.staffTypes,
          valuePath: 'id',
          labelPath: 'name'
        }
      }, {
        title: 'FTE',
        value: 'fte',
        type: 'decimal'
      }, {
        title: 'Funded',
        value: 'percent_funded',
        type: 'percent'
      }, {
        title: 'Notes',
        value: 'notes',
        type: 'text'
      }, {
        title: 'Created At',
        value: 'created_at',
        type: 'date'
      }];
    })
  });

  _exports.default = _default;
});