define("balance-ember/utils/urls", ["exports", "balance-ember/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.URLForAPIv3 = URLForAPIv3;
  _exports.URLForAccountBalances = URLForAccountBalances;
  _exports.URLForAmazonBusinessLogin = URLForAmazonBusinessLogin;
  _exports.URLForTransactions = URLForTransactions;
  _exports.URLForAllocationSummariesGrandTotals = URLForAllocationSummariesGrandTotals;
  _exports.URLForBudgetNamespace = URLForBudgetNamespace;
  _exports.URLForExports = URLForExports;
  _exports.URLForFeatureFlags = URLForFeatureFlags;
  _exports.URLForUser = URLForUser;
  _exports.URLForAccountUnlock = URLForAccountUnlock;
  _exports.UrlForMagicLink = UrlForMagicLink;
  _exports.URLForPasswordReset = URLForPasswordReset;
  _exports.URLForResendConfirmation = URLForResendConfirmation;
  _exports.URLForPasswordUpdate = URLForPasswordUpdate;
  _exports.URLForResendUnlock = URLForResendUnlock;
  _exports.URLForConfirm = URLForConfirm;
  _exports.URLForCheckConfirm = URLForCheckConfirm;
  _exports.URLForReportsSummary = URLForReportsSummary;
  _exports.URLForResourceVersions = URLForResourceVersions;
  _exports.URLForSharing = URLForSharing;
  _exports.URLForFeedbackForm = URLForFeedbackForm;
  _exports.URLForFeedbackFormPersonas = URLForFeedbackFormPersonas;
  _exports.URLForTranslations = URLForTranslations;
  _exports.URLForBulkManagePermissions = URLForBulkManagePermissions;
  _exports.URLForBulkBulkPrivileges = URLForBulkBulkPrivileges;

  function URLForAPIv3() {
    return `${_environment.default.APIRoot}/api/v3`;
  }

  function URLForAccountBalances() {
    return `${URLForAPIv3()}/account_balances`;
  }

  function URLForAmazonBusinessLogin() {
    return `${_environment.default.APIRoot}/oauth_redirect/to`;
  }

  function URLForTransactions() {
    return `${URLForAPIv3()}/transactions`;
  }

  function URLForAllocationSummariesGrandTotals() {
    return `${_environment.default.APIRoot}/api/v3/allocation_summaries`;
  }

  function URLForBudgetNamespace() {
    return `${_environment.default.APIRoot}/budgeting/api/v1`;
  }

  function URLForExports() {
    return `${URLForAPIv3()}/exports`;
  }

  function URLForFeatureFlags() {
    return `${_environment.default.APIRoot}/features`;
  }

  function URLForUser(userId) {
    return `${URLForAPIv3()}/users/${userId}`;
  }

  function URLForAccountUnlock() {
    return `${_environment.default.APIRoot}/users/unlock`;
  }

  function UrlForMagicLink() {
    return `${_environment.default.APIRoot}/users/login_link`;
  }

  function URLForPasswordReset() {
    return `${_environment.default.APIRoot}/users/password`;
  }

  function URLForResendConfirmation() {
    return `${_environment.default.APIRoot}/users/confirmation`;
  }

  function URLForPasswordUpdate() {
    return `${_environment.default.APIRoot}/users/password`;
  }

  function URLForResendUnlock() {
    return `${_environment.default.APIRoot}/users/unlock`;
  }

  function URLForConfirm() {
    return `${_environment.default.APIRoot}/users/confirmation`;
  }

  function URLForCheckConfirm() {
    return `${_environment.default.APIRoot}/users/confirmation/check_confirmation`;
  }

  function URLForReportsSummary({
    fiscalYearId,
    reportName
  } = {}) {
    return `${URLForBudgetNamespace()}/fiscal_years/${fiscalYearId}/reports/${reportName}/summary`;
  }

  function URLForResourceVersions() {
    return `${_environment.default.APIRoot}/resource_versions`;
  }

  function URLForSharing() {
    return `${_environment.default.APIRoot}/share`;
  }

  function URLForFeedbackForm() {
    return `${_environment.default.APIRoot}/feedback`;
  }

  function URLForFeedbackFormPersonas() {
    return `${_environment.default.APIRoot}/feedback/personas`;
  }

  function URLForTranslations(locale) {
    return `${_environment.default.APIRoot}/translations/${locale}.json`;
  }

  function URLForBulkManagePermissions() {
    return `${URLForAPIv3()}/manage_permissions/bulk_upload`;
  }

  function URLForBulkBulkPrivileges(fiscalYearId) {
    return `${URLForAPIv3()}/fiscal_years/${fiscalYearId}/budget_privileges/bulk_upload`;
  }
});