define("balance-ember/components/budget/non-personnel-expenses-table/edit-vendor/no-vendor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LLD6jzaW",
    "block": "{\"symbols\":[\"group\",\"InlineItem\",\"@expense\"],\"statements\":[[2,\" \"],[8,\"form/label-group\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"layout/inline\",[],[[\"@space\",\"@align\"],[\"xs\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"one-way-radio\",[],[[\"@id\",\"@value\",\"@option\",\"@name\",\"@update\",\"@disabled\"],[[32,1,[\"id\"]],[32,3,[\"vendorSelection\"]],null,\"vendor-choices\",[32,0,[\"update\"]],[30,[36,0],[[32,3,[\"isEditable\"]]],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"Label\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"budget.non_personnel_expense_form.vendor.none\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"not\",\"t\"]}",
    "moduleName": "balance-ember/components/budget/non-personnel-expenses-table/edit-vendor/no-vendor/template.hbs"
  });

  _exports.default = _default;
});