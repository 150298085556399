define("balance-ember/components/light-table/cell-components/table-actions/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Lv6e6Z6",
    "block": "{\"symbols\":[\"@class\",\"@row\",\"@tableActions\",\"@column\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"flex items-center \",[32,1]]]],[12],[2,\"\\n  \"],[8,\"edit-button\",[],[[\"@click\",\"@buttonText\",\"@disabled\",\"@icon\",\"@testSelectorValue\",\"@tooltipText\"],[[30,[36,1],[[30,[36,0],[[32,3,[\"edit\"]],[32,2,[\"content\"]],[32,2]],null]],null],[32,0,[\"buttonText\"]],[32,0,[\"isEditDisabled\"]],[30,[36,2],[[32,4,[\"editIcon\"]],\"edit\"],null],[32,2,[\"content\",\"id\"]],[32,0,[\"editTooltipText\"]]]],null],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"isEditDisabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tooltip\",[],[[\"@text\"],[[32,0,[\"editTooltipText\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"stop-propagation\",\"or\",\"if\"]}",
    "moduleName": "balance-ember/components/light-table/cell-components/table-actions/edit/template.hbs"
  });

  _exports.default = _default;
});