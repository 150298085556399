define("balance-ember/components/positions-table/form/position-details/fte-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VD864OUf",
    "block": "{\"symbols\":[\"@position\"],\"statements\":[[8,\"one-way-number-mask\",[[16,1,[32,0,[\"group\",\"id\"]]],[24,0,\"css-input-field\"]],[[\"@decimal\",\"@options\",\"@value\",\"@update\"],[true,[30,[36,0],null,[[\"allowMinus\",\"digits\"],[true,8]]],[32,1,[\"fte\"]],[30,[36,1],[[32,1],\"fte\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"set\"]}",
    "moduleName": "balance-ember/components/positions-table/form/position-details/fte-input/template.hbs"
  });

  _exports.default = _default;
});