define("balance-ember/components/positions-table/form/nav/expense-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZYywqNw9",
    "block": "{\"symbols\":[\"@expense\",\"@stateMachine\"],\"statements\":[[8,\"button\",[[16,0,[31,[\"\\n  py-8\\n  pl-28\\n  pr-8\\n  rounded-sm\\n  font-bold\\n  w-full\\n  flex\\n  items-center\\n  justify-between\\n  text-sm\\n  \",[30,[36,1],[[30,[36,3],[[30,[36,0],[[32,2,[\"status\"]],[32,1]],null],[30,[36,2],[[32,2,[\"errors\"]],[32,1,[\"allocation\",\"id\"]]],null]],null],\"bg-material-red100\",[30,[36,1],[[30,[36,0],[[32,2,[\"status\"]],[32,1]],null],\"bg-material-light-blue100\"],null]],null],\"\\n  \"]]]],[[\"@size\",\"@style\",\"@testId\",\"@active\",\"@onclick\"],[\"none\",\"none\",[31,[\"allocation-link-\",[32,1,[\"allocation\",\"name\"]]]],[30,[36,0],[[32,2,[\"status\"]],[32,1]],null],[30,[36,4],[[32,2,[\"editExpense\"]],[32,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[\"\\n    whitespace-normal\\n    text-left\\n    \",[30,[36,1],[[30,[36,2],[[32,2,[\"errors\"]],[32,1,[\"allocation\",\"id\"]]],null],\"btn-danger-link\",\"btn-primary-link\"],null],\"\\n    \"]]],[12],[2,\"\\n\\n    \"],[1,[32,1,[\"allocation\",\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,2],[[32,2,[\"errors\"]],[32,1,[\"allocation\",\"id\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"pl-4\"],[12],[2,\"\\n      \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[\"notification-error\",\"text-material-red500\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"get\",\"and\",\"fn\"]}",
    "moduleName": "balance-ember/components/positions-table/form/nav/expense-link/template.hbs"
  });

  _exports.default = _default;
});