define("balance-ember/templates/budgeting/budget/supplemental-pay-expenses/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FlO4n32n",
    "block": "{\"symbols\":[],\"statements\":[[8,\"budget/supplemental-pay-expense-form\",[],[[\"@supplementalPayExpenseId\",\"@budgetId\",\"@formTitle\",\"@actionButtonText\",\"@disabledButtonText\"],[[32,0,[\"model\",\"expenseId\"]],[32,0,[\"model\",\"budgetId\"]],\"Edit Supplemental Pay\",\"Save Changes\",\"Editing\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/budgeting/budget/supplemental-pay-expenses/edit.hbs"
  });

  _exports.default = _default;
});