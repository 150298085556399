define("balance-ember/components/ui-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wIIitRtx",
    "block": "{\"symbols\":[\"@tetherTarget\",\"@attachment\",\"@offset\",\"@targetOffset\",\"@constraints\",\"@errors\",\"&default\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@hasOverlay\",\"@clickOutsideToClose\",\"@translucentOverlay\",\"@containerClass\",\"@overlayClass\",\"@wrapperClass\",\"@overlayPosition\",\"@tetherTarget\",\"@targetAttachment\",\"@renderInPlace\",\"@attachment\",\"@offset\",\"@targetOffset\",\"@constraints\"],[[30,[36,0],[[32,0],\"closeModal\"],null],[32,0,[\"shouldShowOverlay\"]],true,true,[30,[36,1],[\"bg-white \",[32,0,[\"containerClassNames\"]]],null],[32,0,[\"overlayClassNames\"]],[32,0,[\"wrapperClassNames\"]],[32,0,[\"overlayPosition\"]],[32,1],[32,0,[\"targetAttachment\"]],[32,0,[\"renderInPlace\"]],[32,2],[32,3],[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"div\"],[24,\"role\",\"dialog\"],[24,\"aria-modal\",\"true\"],[16,\"data-modal-id\",[32,0,[\"element\",\"id\"]]],[16,0,[30,[36,2],[[32,0,[\"isSlideout\"]],\"h-screen overflow-y-scroll\"],null]],[4,[38,4],null,[[\"focusTrapOptions\"],[[30,[36,3],null,[[\"clickOutsideDeactivates\"],[true]]]]]],[12],[2,\"\\n    \"],[18,7,[[30,[36,3],null,[[\"header\",\"body\",\"section\",\"loader\",\"footer\",\"actions\"],[[30,[36,5],[\"ui-modal/modal-header\"],[[\"closeModal\",\"style\",\"errors\"],[[30,[36,0],[[32,0],\"closeModal\"],null],[32,0,[\"headerStyles\"]],[32,6]]]],[30,[36,5],[\"ui-modal/modal-body\"],[[\"style\"],[[32,0,[\"bodyStyles\"]]]]],[30,[36,5],[\"ui-modal/modal-section\"],null],[30,[36,5],[\"ui-modal/loader\"],null],[30,[36,5],[\"ui-modal/modal-footer\"],[[\"style\"],[[32,0,[\"footerStyles\"]]]]],[30,[36,3],null,[[\"close\"],[[30,[36,0],[[32,0],\"closeModal\"],null]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"concat\",\"if\",\"hash\",\"focus-trap\",\"component\"]}",
    "moduleName": "balance-ember/components/ui-modal/template.hbs"
  });

  _exports.default = _default;
});