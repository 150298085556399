define("balance-ember/controllers/budgeting/budget/supplemental-pay-expenses/destroy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modelChanges: Ember.inject.service(),
    actions: {
      cancelPayExpense() {
        this.set('isConfirming', false);
        this.transitionToRoute('budgeting.budget.supplemental-pay-expenses');
      },

      deletePayExpense() {
        return this.model.destroyRecord().then(() => {
          this.modelChanges.addChange({
            id: this.model.id,
            modelType: 'supplementalPayExpense',
            changeType: 'delete'
          });
          this.set('isConfirming', false);
          this.transitionToRoute('budgeting.budget.supplemental-pay-expenses');
        });
      },

      confirmDeletePayExpense() {
        this.set('isConfirming', true);
      }

    }
  });

  _exports.default = _default;
});