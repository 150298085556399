define("balance-ember/components/manage/transactions-table/summarize/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e4f7w6w6",
    "block": "{\"symbols\":[\"Header\",\"@firstColumnPadding\",\"@columns\",\"@extra\",\"@table\",\"@sortIcons\",\"@middleColumnPadding\",\"@lastColumnPadding\"],\"statements\":[[8,\"manage/summarize/header\",[],[[\"@rowLabel\",\"@firstColumnPadding\",\"@columns\",\"@extra\",\"@table\",\"@sortIcons\"],[\"Transactions\",[32,2],[32,3],[32,4],[32,5],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"cell\"]],[],[[\"@testId\",\"@amount\",\"@paddingClass\"],[\"summarize-header-actual\",[32,0,[\"grandTotals\",\"actual\"]],[32,7]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"cell\"]],[],[[\"@testId\",\"@amount\",\"@paddingClass\"],[\"summarize-header-encumbrance\",[32,0,[\"grandTotals\",\"encumbrance\"]],[32,8]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/manage/transactions-table/summarize/header/template.hbs"
  });

  _exports.default = _default;
});