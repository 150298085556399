define("balance-ember/components/manage/manage-overview-scope-selector/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/classes/bookmark-query-param-builder", "balance-ember/utils/manage/current-bookmark-finder"], function (_exports, _component, _bookmarkQueryParamBuilder, _currentBookmarkFinder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: Ember.inject.service(),
    preferences: Ember.inject.service(),
    availableBudgetLeas: Ember.inject.service(),
    eventTracking: Ember.inject.service(),
    currentBudgetLea: Ember.computed.readOnly('availableBudgetLeas.current'),
    defaultBookmarkMenuText: 'All Accounts',
    currentBookmark: Ember.computed('bookmarks.[]', 'serializedTableState', function () {
      return new _currentBookmarkFinder.default(this.bookmarks, this.serializedTableState).find() || null;
    }),
    actions: {
      applyBookmark(bookmark, closeAction) {
        let currentRoute = this.router.currentRouteName;
        let queryParamBuilder = new _bookmarkQueryParamBuilder.default(bookmark, this.currentBudgetLea.id);
        this.preferences.update('manageOverviewDefaultBookmark', bookmark.id);
        this.preferences.persist();
        this.trackClick();
        const query = queryParamBuilder.build();
        this.router.transitionTo(currentRoute, {
          queryParams: {
            query
          }
        });
        closeAction();
      },

      clearBookmarks(closeAction) {
        this.preferences.update('manageOverviewDefaultBookmark', null);
        this.preferences.persist();
        let currentRoute = this.router.currentRouteName;
        this.router.transitionTo(currentRoute, {
          queryParams: {
            query: undefined
          }
        });
        closeAction();
      }

    },

    trackClick() {
      let bookmarkName = this.currentBookmark ? this.currentBookmark.name : this.defaultBookmarkMenuText;
      this.eventTracking.trackEvent('Select Bookmark - Donut', {
        bookmarkName
      });
    }

  });

  _exports.default = _default;
});