define("balance-ember/controllers/users/confirm-account", ["exports", "balance-ember/utils/urls", "ember-cp-validations", "ember-concurrency"], function (_exports, _urls, _emberCpValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    tosAgreement: (0, _emberCpValidations.validator)('inclusion', {
      message: 'Must be accepted',
      in: [true]
    }),
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 4,
      max: 120
    })],
    password_confirmation: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Password Confirmation doesn\'t match Password'
    })
  }, {
    disabled: Ember.computed.not('model.shouldValidate')
  });
  const UsersConfirmAccountController = Ember.Controller.extend(validations, {
    ajax: Ember.inject.service(),
    eventTracking: Ember.inject.service(),
    notifications: Ember.inject.service(),
    queryParams: ['confirmation_token'],
    password: '',
    password_confirmation: '',
    userData: Ember.computed('confirmation_token', 'password', 'password_confirmation', 'tosAgreement', function () {
      return {
        confirmation_token: this.confirmation_token,
        user: {
          password: this.password,
          password_confirmation: this.password_confirmation,
          tos_agreement: this.tosAgreement
        }
      };
    }),
    // Your account has been setup successfully.
    confirmAccount: (0, _emberConcurrency.task)(function* () {
      this.set('shouldValidate', true);

      if (!this.validations.isValid) {
        return;
      }

      return yield this.ajax.patch((0, _urls.URLForConfirm)(), {
        data: this.userData
      }).then(() => {
        this.eventTracking.trackEvent('Confirmed Account', {
          email: this.email
        });
        this.notifications.success('Your account has been setup successfully.');
        return this.transitionToRoute('login');
      }).catch(({
        payload
      }) => {
        this.set('errors', payload.errors);
      });
    })
  });
  var _default = UsersConfirmAccountController;
  _exports.default = _default;
});