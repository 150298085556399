define("balance-ember/components/dashboard-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dashboard-table'],

    /**
     * tableColumns - Add css properties to the passed in columns to get correct look and feel
     */
    tableColumns: Ember.computed('columns.[]', function () {
      return this.columns.map((column, index) => {
        let cellClasses = 'align-top overflow-x-hidden'; // First column

        if (index === 0) {
          cellClasses = `${cellClasses} pl-16 pr-10 py-12`; // Last column
        } else if (index === this.columns.length - 1) {
          cellClasses = `${cellClasses} pr-16 pl-10 py-12`;
        } else {
          cellClasses = `${cellClasses} px-10 py-12`;
        }

        let classNames = `${cellClasses} font-bold text-material-gray700 text-sm`;
        let cellClassNames = `${cellClasses} text-material-gray700 text-sm lg:text-base`; // Put our class names first so they can be easily overridden

        cellClassNames = `${cellClassNames} ${column.cellClassNames}`;
        classNames = `${classNames} ${column.classNames}`;
        let newColumn = Object.assign({}, column, {
          cellClassNames,
          classNames
        }); // If a column type has not been assigned we'll add allovue-column so column headers
        // can easily have test selectors

        if (!column.type) {
          newColumn = Object.assign(newColumn, {
            type: 'allovue-column'
          });
        } // If a cell type has not been assigned we'll add allovue-cell so all cells
        // can easily have test selectors


        if (!column.cellType) {
          newColumn = Object.assign(newColumn, {
            cellType: 'allovue-cell'
          });
        }

        return newColumn;
      });
    }),
    isLoading: Ember.computed.readOnly('loadTask.isRunning'),
    shouldShowEndData: Ember.computed('height', 'isLoading', 'tableState.{hasFullPage,pagination}', function () {
      let {
        isLastPage
      } = this.tableState.pagination;
      return !this.isLoading && isLastPage && !this.tableState.hasFullPage && this.height;
    })
  });

  _exports.default = _default;
});