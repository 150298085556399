define("balance-ember/models/expense-strategy", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  const schema = {
    budgetStrategy: (0, _model.belongsTo)('budgetStrategy', {
      async: false
    }),
    expense: (0, _model.belongsTo)({
      polymorphic: true,
      async: false
    }),
    percentage: (0, _model.attr)('number')
  };
  _exports.schema = schema;

  var _default = _model.default.extend(schema);

  _exports.default = _default;
});