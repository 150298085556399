define("balance-ember/utils/filters/percent-formatter", ["exports", "balance-ember/utils/filters/integer-formatter", "numeral"], function (_exports, _integerFormatter, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PercentFormatter extends _integerFormatter.default {
    formatValue(value) {
      let formatted = (0, _numeral.default)(value).format('0,0[.]00');
      return `${formatted}%`;
    }

  }

  _exports.default = PercentFormatter;
});