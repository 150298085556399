define("balance-ember/components/budget-totals/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v/w9KQQV",
    "block": "{\"symbols\":[\"InlineItem\"],\"statements\":[[8,\"horizontal-summary-stats\",[[24,0,\"w-auto\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"layout/inline\",[],[[\"@space\"],[\"s\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"budget-totals/total\",[],[[\"@isFiscalYear\",\"@testId\",\"@borderWidthClass\",\"@amount\",\"@label\"],[[32,0,[\"isFiscalYear\"]],\"total-allocated\",[32,0,[\"borderWidthClass\"]],[32,0,[\"totalAllocated\"]],\"Allocated\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"budget-totals/total\",[],[[\"@isFiscalYear\",\"@testId\",\"@borderWidthClass\",\"@amount\",\"@label\"],[[32,0,[\"isFiscalYear\"]],\"total-planned\",[32,0,[\"borderWidthClass\"]],[32,0,[\"totalPlanned\"]],\"Planned\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"budget-totals/total\",[],[[\"@isFiscalYear\",\"@testId\",\"@borderWidthClass\",\"@amount\",\"@label\"],[[32,0,[\"isFiscalYear\"]],\"total-remaining\",[32,0,[\"borderWidthClass\"]],[32,0,[\"totalRemaining\"]],\"Remaining\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/budget-totals/template.hbs"
  });

  _exports.default = _default;
});