define("balance-ember/components/dashboard-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ffLJNMN+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"container\",[],[[\"@style\",\"@height\"],[\"dashboard\",[32,0,[\"height\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"header\",\"title\"],[[30,[36,0],[\"dashboard-widget/header\"],null],[30,[36,0],[\"dashboard-widget/title\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/dashboard-widget/template.hbs"
  });

  _exports.default = _default;
});