define("balance-ember/components/budget/reports/export-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yomKevIM",
    "block": "{\"symbols\":[\"StackItem\",\"@reportName\",\"@export\"],\"statements\":[[2,\" \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@active\"],[\"btn-default\",\"sm\",\"export-button\",[32,0,[\"isShowing\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Export Report\\n\\n    \"],[8,\"popover\",[],[[\"@placement\",\"@onChange\"],[\"bottom-end\",[30,[36,0],[[32,0],\"isShowing\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"w-192 p-10\"],[12],[2,\"\\n        \"],[8,\"layout/stack\",[],[[\"@space\"],[\"xxs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[8,\"csv-download-button\",[],[[\"@onclick\"],[[30,[36,1],[[32,3],\"csv\",[32,2]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n          \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[8,\"excel-download-button\",[],[[\"@onclick\"],[[30,[36,1],[[32,3],\"xlsx\",[32,2]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"set\",\"fn\"]}",
    "moduleName": "balance-ember/components/budget/reports/export-button/template.hbs"
  });

  _exports.default = _default;
});