define("balance-ember/routes/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class LoginRoute extends Ember.Route.extend(_unauthenticatedRouteMixin.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        token: {
          replace: true
        },
        confirmed: {
          refreshModel: true
        }
      });
    }

    activate() {
      return document.title = 'Log In - Allovue Balance';
    }

    setupController(controller) {
      controller.set('sentMagic', false);
      controller.set('isAuthenticating', false);
      controller.set('errorMessage', null);

      if (controller.token) {
        controller.send('authenticateWithToken', controller.token);
      }

      return controller;
    }

  }

  _exports.default = LoginRoute;
});