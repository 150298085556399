define("balance-ember/components/ui-form/ui-label/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    hasErrors: Ember.computed.notEmpty('errors'),
    errorClasses: Ember.computed('hasErrors', function () {
      return this.hasErrors ? 'error css-show-exclamation' : '';
    }),
    style: 'side row',
    labelClasses: Ember.computed('style', function () {
      let style = '';

      if (this.style.includes('side')) {
        style = `${style} flex items-center`;
      }

      if (this.style.includes('row')) {
        style = `${style} not-first:mt-8 px-32`;
      }

      if (this.style.includes('bar')) {
        style = `${style} mb-4 px-40 py-14`;
      }

      if (this.style.includes('gray')) {
        style = `${style} bg-material-gray100`;
      }

      if (this.style.includes('hidden')) {
        style = `${style} sr-only`;
      }

      if (this.style.includes('w-full')) {
        style = `${style} w-full`;
      }

      return style;
    })
  });

  _exports.default = _default;
});