define("balance-ember/components/budget/budget-files/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z9JQXIlM",
    "block": "{\"symbols\":[\"queue\",\"uploadFileTask\",\"@budget\",\"@for\",\"@uploadFile\",\"&default\"],\"statements\":[[8,\"budget/file-upload/input\",[],[[\"@name\",\"@for\",\"@url\",\"@uploadData\",\"@uploadFile\",\"@budget\",\"@afterAllUploads\"],[[30,[36,0],[\"budget-\",[32,3,[\"id\"]],\"-upload-queue\"],null],[32,4],[32,0,[\"url\"]],[30,[36,1],null,[[\"budget_id\"],[[32,3,[\"id\"]]]]],[32,5],[32,3],[32,0,[\"afterAllUploads\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,6,[[32,1],[32,2]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"hash\"]}",
    "moduleName": "balance-ember/components/budget/budget-files/input/template.hbs"
  });

  _exports.default = _default;
});