define("balance-ember/components/budget/expense-table/edit-strategies/delete-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Dtucp3p",
    "block": "{\"symbols\":[\"@index\",\"@expenseStrategy\",\"@expense\"],\"statements\":[[6,[37,1],[[32,3,[\"isEditable\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"delete-button\",[],[[\"@testSelectorValue\",\"@buttonText\",\"@click\"],[[32,1],[30,[36,0],[[32,2,[\"budgetStrategy\",\"name\"]],[32,1]],null],[32,0,[\"deleteStrategy\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"if\"]}",
    "moduleName": "balance-ember/components/budget/expense-table/edit-strategies/delete-button/template.hbs"
  });

  _exports.default = _default;
});