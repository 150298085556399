define("balance-ember/components/percentage-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uTgzbSLh",
    "block": "{\"symbols\":[\"bar\",\"bar\"],\"statements\":[[10,\"div\"],[14,0,\"w-full\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex w-full\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"barsWithStyle\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"percentage-bar/label\",[],[[\"@bar\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[\"\\n    flex\\n    w-full\\n    overflow-hidden\\n    \",[32,0,[\"sizeClasses\"]],\"\\n    \",[32,0,[\"borderRadiusClasses\"]],\"\\n    \",[32,0,[\"backgroundColor\"]]]]],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"barsWithStyle\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"percentage-bar/bar\",[],[[\"@bar\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/percentage-bar/template.hbs"
  });

  _exports.default = _default;
});