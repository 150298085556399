define("balance-ember/components/manage/chart/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/classes/table-serializer", "ember-concurrency"], function (_exports, _component, _tableSerializer, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let colors = {
    'budgeted': '#C2C2C2',
    'encumbrance': '#22ADCE',
    'spent': '#D2409B',
    'available': '#01CD9F'
  };

  var _default = _component.default.extend({
    availableBudgetLeas: Ember.inject.service(),
    store: Ember.inject.service(),
    segment: Ember.inject.service(),
    trackingPrefix: 'Chart',
    perPupil: false,
    metricTypes: Object.freeze([]),
    selectedMetricType: null,
    isPercentageMetric: Ember.computed.equal('selectedMetricType.measurementType', 'percentage'),
    highchartsData: Ember.computed('chartSeriesSettings', 'measurementData', 'perPupil', 'selectedMetricType.name', 'tooltipFormat', function () {
      if (!this.measurementData) {
        return [];
      }

      return Object.keys(this.chartSeriesSettings).map(amountType => {
        let categoryDetails = this.chartSeriesSettings[amountType];
        return Object.assign({}, {
          name: amountType,
          tooltip: {
            headerFormat: '',
            pointFormat: this.tooltipFormat
          },
          data: this.measurementData.map(measurement => {
            let name = measurement.segment.name;
            let rawValue = Number(measurement.totalAccountBalances[amountType]).toFixed(2);
            let perPupilValue = (rawValue / measurement.numberEnrolled).toFixed(2);
            return {
              name,
              amountType,
              x: measurement.value,
              y: Number(this.perPupil ? perPupilValue : rawValue),
              metricTitle: this.selectedMetricType.name,
              numEnrolled: measurement.numberEnrolled,
              className: `scatter-point-for-${Ember.String.dasherize(name)}`
            };
          })
        }, categoryDetails);
      });
    }),
    actions: {
      togglePupil() {
        this.set('perPupil', !this.perPupil);
      },

      updateMetricType({
        id
      }) {
        this.changeMetric(id);
      }

    },

    changeMetric(id) {
      this.set('selectedMetricType', this.metricTypes.findBy('id', String(id)));
      this.loadMeasurements.perform(this.selectedMetricType.id);
    },

    didReceiveAttrs() {
      this._super();

      this.loadMetrics.perform();
    },

    loadMetrics: (0, _emberConcurrency.task)(function* () {
      let serializedQuery = new _tableSerializer.default(this.tableState).apiQuery;
      return yield this.store.query('metric', {
        filter: {
          budget_lea_id: serializedQuery.filter.budget_lea_id
        }
      }).then(results => {
        this.set('metricTypes', results);
        this.changeMetric(this.metricTypes.firstObject.id);
      });
    }).drop(),
    loadMeasurements: (0, _emberConcurrency.task)(function* (measurementId) {
      this.set('measurementData', null);

      if (!measurementId) {
        return;
      }

      let serializedQuery = new _tableSerializer.default(this.tableState).apiQuery;
      return yield this.store.query('measurement', {
        include: 'segment.segment_type',
        extra_fields: {
          'measurements': 'total_account_balances,number_enrolled'
        },
        filter: Object.assign({}, serializedQuery.filter, {
          metric_id: {
            eq: measurementId
          }
        })
      }).then(results => {
        this.set('measurementData', results);
      });
    }).drop(),
    tooltipFormat: Ember.computed('perPupil', 'isPercentageMetric', function () {
      // Percentage metrics should have a percent sign after the percentage value
      let metricTitleSuffix = this.isPercentageMetric ? '%' : ''; // let value = this.isPercentageMetric ? point.x : numeral(point.x).format('$0,0.00'); ;

      if (this.perPupil) {
        // Highcharts strips out test selectors it seem
        return `\
        <b>{point.name}</b> \
        <br> <span class='metric-title'>{point.metricTitle}: {point.x:,.f}${metricTitleSuffix}</span> \
        <br> <span class='num-enrolled'>{point.numEnrolled} students enrolled</span> \
        <br> <span class='amount-per-pupil'>\${point.y:,.2f} {point.amountType} per pupil</span> \
      `;
      }

      return `\
      <b>{point.name}</b> \
      <br> <span class='metric-title'>{point.metricTitle}: {point.x:,.f}${metricTitleSuffix}</span>\
      <br> <span class='amount-without-pupil'>\${point.y:,.2f} {point.amountType} </span>\
    `;
    }),
    chartSeriesSettings: Ember.computed(function () {
      return {
        budgeted: {
          visible: false,
          color: colors.budgeted,
          marker: {
            symbol: 'square'
          }
        },
        encumbered: {
          visible: false,
          color: colors.encumbrance,
          marker: {
            symbol: 'triangle'
          }
        },
        // 'spent' should be visible by default
        spent: {
          color: colors.spent,
          marker: {
            symbol: 'circle'
          }
        },
        available: {
          visible: false,
          color: colors.available,
          marker: {
            symbol: 'diamond'
          }
        }
      };
    })
  });

  _exports.default = _default;
});