define("balance-ember/components/number-cell/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    numberFormat: Ember.computed('column.format', function () {
      return this.column.format ? this.column.format : '$0,0.00';
    })
  });

  _exports.default = _default;
});