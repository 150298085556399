define("balance-ember/initializers/titleize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    String.prototype.titleize = function () {
      return Ember.String.capitalize(this.humanize()).split(' ').reduce(function (prev, current) {
        return Ember.String.capitalize(prev) + ' ' + Ember.String.capitalize(current);
      });
    };
  }

  var _default = {
    name: 'titleize',
    initialize
  };
  _exports.default = _default;
});