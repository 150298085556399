define("balance-ember/components/budget/budgets/buttons/upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MnQRPz1G",
    "block": "{\"symbols\":[\"@tableState\",\"@categories\"],\"statements\":[[8,\"budget/csv-upload-dropdown\",[[24,\",\",\"\"]],[[\"@url\",\"@resourceName\",\"@afterUploadSuccess\",\"@resource\",\"@reportName\",\"@tableState\",\"@categories\"],[[32,0,[\"budgetBulkUrl\"]],\"Budget\",[32,0,[\"afterUploadSuccess\"]],\"budget\",\"Budget Template\",[32,1],[32,2]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/budget/budgets/buttons/upload/template.hbs"
  });

  _exports.default = _default;
});