define("balance-ember/helpers/titleize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Override of one from ember-cli-string-helpers. The one in the addon does not remove dashes/underscores/etc.
   * @override
   */
  var _default = Ember.Helper.helper(function titleize(params
  /*, hash*/
  ) {
    let value = params[0] ?? '';
    return value.titleize();
  });

  _exports.default = _default;
});