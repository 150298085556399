define("balance-ember/serializers/bookmark", ["exports", "balance-ember/serializers/sideposting"], function (_exports, _sideposting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sideposting.default.extend({});

  _exports.default = _default;
});