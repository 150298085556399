define("balance-ember/components/manage/summarize/data-loader/parent-filters-builder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ParentFiltersBuilder {
    constructor(summarizePath) {
      this.summarizePath = summarizePath;
    }

    build() {
      return this.summarizePath.reduce((acc, node) => {
        if (node.value === null) {
          return acc;
        }

        if (!this.nodeChangesCategory(node)) {
          return acc;
        }

        acc = this.addFilter(acc, node);
        return acc;
      }, []);
    }

    addFilter(filters, node) {
      let filter = new FilterBuilderFactory(node).build();
      return filters.concat(filter);
    }

    nodeChangesCategory(node) {
      return Boolean(node.summary.category.keys.length);
    }

  }

  _exports.default = ParentFiltersBuilder;

  class FilterBuilderFactory {
    constructor(node) {
      _defineProperty(this, "TYPE_CLASSES", {
        'segment': SegmentFilterBuilder,
        'supplement': SupplementFilterBuilder,
        'month': DateFilterBuilder,
        'quarter': DateFilterBuilder
      });

      this.node = node;
      return this.builderClass;
    }

    get builderClass() {
      return new this.TYPE_CLASSES[this.type](this.node);
    }

    get type() {
      return this.node.category.type;
    }

  }

  class FilterBuilder {
    constructor(node) {
      this.node = node;
    }

    build() {
      return {
        category: this.categoryValue,
        type: this.type,
        operators: [this.operator]
      };
    }

    get categoryValue() {
      return this.category.value;
    }

    get category() {
      return this.node.category;
    }

    get operator() {
      return '';
    }

    get type() {
      return this.category.type;
    }

    get value() {
      return this.node.value;
    }

  }

  class SegmentFilterBuilder extends FilterBuilder {
    get operator() {
      return {
        value: 'eq_group',
        filterValues: [{
          eq: [this.value]
        }]
      };
    }

  }

  class SupplementFilterBuilder extends FilterBuilder {
    get operator() {
      return {
        value: 'eq',
        filterValues: [this.value]
      };
    }

  }

  class DateFilterBuilder extends FilterBuilder {
    get operator() {
      return {
        value: 'between',
        filterValues: [this.value]
      };
    }

    get categoryValue() {
      return 'transaction_date';
    }

  }
});