define("balance-ember/models/job-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.computedProperties = _exports.schema = void 0;
  const schema = {
    benefitsPackage: (0, _model.belongsTo)('benefitsPackage', {
      async: false
    }),
    staffType: (0, _model.belongsTo)('staffType', {
      async: false
    }),
    fiscalYear: (0, _model.belongsTo)('fiscalYear', {
      async: false
    }),
    salarySegment: (0, _model.belongsTo)('segment', {
      async: false
    }),
    positions: (0, _model.hasMany)('position', {
      async: false
    }),
    title: (0, _model.attr)('string'),
    code: (0, _model.attr)('string'),
    averageBaseSalary: (0, _model.attr)('number'),
    averageBenefitsAmount: (0, _model.attr)('number', {
      readOnly: true
    }),
    averageTotal: (0, _model.attr)('number', {
      readOnly: true
    }),
    deletable: (0, _model.attr)('boolean', {
      readOnly: true
    }),
    budgetCategory: (0, _model.attr)('string', {
      defaultValue: 'any'
    })
  };
  _exports.schema = schema;
  const computedProperties = {
    segments: Ember.computed('salarySegment', function () {
      return [this.salarySegment];
    })
  };
  _exports.computedProperties = computedProperties;

  var _default = _model.default.extend(schema, computedProperties);

  _exports.default = _default;
});