define("balance-ember/components/budget-notes/edit-active/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RiQhBXv4",
    "block": "{\"symbols\":[\"@notesPlaceholderText\",\"@budget\",\"@cancelEditNotes\",\"@saveNotes\"],\"statements\":[[10,\"section\"],[14,0,\"px-24 py-16\"],[12],[2,\"\\n  \"],[8,\"textarea\",[[16,\"placeholder\",[32,1]]],[[\"@value\",\"@autoresize\",\"@rows\",\"@max-rows\"],[[30,[36,0],[[32,2,[\"notes\"]]],null],true,2,15]],null],[2,\"\"],[13],[2,\"\\n\"],[10,\"section\"],[14,0,\"edit-notes-active notes-actions\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"self-center\"],[12],[2,\"Editing\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"end-lg items-center leading-none\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mr-8 inline-block\"],[12],[2,\"\\n      \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-default\",\"sm\",\"cancel-edit-note\",[30,[36,1],[[32,0],[32,3]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Cancel\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-success\",\"sm\",\"save-note\",[30,[36,1],[[32,0],[32,4]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Save\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\"]}",
    "moduleName": "balance-ember/components/budget-notes/edit-active/template.hbs"
  });

  _exports.default = _default;
});