define("balance-ember/components/tooltip/component", ["exports", "@glimmer/component", "balance-ember/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultAnimation = 'shift';
  const testAnimation = 'none';

  class PopoverComponent extends _component.default {
    get animation() {
      return _environment.default.environment === 'test' ? testAnimation : defaultAnimation;
    }

  }

  _exports.default = PopoverComponent;
});