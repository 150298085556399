define("balance-ember/helpers/coded-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function codedName(params, {
    namePath = 'name',
    codePath = 'code'
  } = {}) {
    let obj = params[0];

    if (!obj) {
      return obj;
    }

    let code = obj[codePath];
    let name = obj[namePath];

    if (Ember.isPresent(obj[codePath])) {
      return `${code} - ${name}`;
    }

    return name;
  });

  _exports.default = _default;
});