define("balance-ember/controllers/budgeting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    needsFiscalYearReminder: Ember.computed.not('session.isBudgetArchitect'),
    viewingNonCurrentFiscalYear: Ember.computed.not('model.isCurrent'),
    shouldShowBanner: Ember.computed.and('needsFiscalYearReminder', 'viewingNonCurrentFiscalYear'),
    actions: {
      showNonCurrentFiscalYearBanner() {
        this.notifications.warning('You are viewing an older fiscal year, click here to choose a different fiscal year <div data-test-id="non-current-fy-warning" class="invisible"></div>', {
          autoClear: false,
          htmlContent: true,
          onClick: () => this.router.transitionTo('fiscal-years')
        });
      },

      hideNonCurrentFiscalYearBanner() {
        this.notifications.clearAll();
      }

    }
  });

  _exports.default = _default;
});