define("balance-ember/components/filter-form/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kt71GBNB",
    "block": "{\"symbols\":[\"InlineItem\",\"@cancel\",\"@apply\",\"@applyButtonType\"],\"statements\":[[10,\"div\"],[14,0,\"flex items-center justify-end bg-material-gray100\\n  px-14 py-10 rounded-t\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ml-12\"],[12],[2,\"\\n    \"],[8,\"layout/inline\",[],[[\"@space\",\"@align\"],[\"m\",\"center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"button\",[],[[\"@style\",\"@onclick\",\"@testId\"],[\"btn-subtle\",[30,[36,0],[[32,0],[32,2]],null],\"cancel-filter\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          Cancel\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"        \"],[8,\"button\",[],[[\"@style\",\"@testId\",\"@onclick\",\"@type\"],[\"btn-primary\",\"apply-filter\",[30,[36,2],[[30,[36,1],[[32,4],\"button\"],null],[32,3]],null],[30,[36,3],[[32,4],\"submit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          Apply\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"eq\",\"if\",\"or\"]}",
    "moduleName": "balance-ember/components/filter-form/footer/template.hbs"
  });

  _exports.default = _default;
});