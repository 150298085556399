define("balance-ember/routes/users/forgot-password", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UsersForgotPasswordRoute = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('errors', []);
        return controller.set('sentRecovery', false);
      }
    },

    activate() {
      return document.title = 'Forgot Password - Allovue Balance';
    }

  });
  var _default = UsersForgotPasswordRoute;
  _exports.default = _default;
});