define("balance-ember/routes/manage-v3/accounts/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ManageV3AccountsDetailsRoute extends Ember.Route {
    redirect(_, transition) {
      let {
        budget_lea_id: budgetLeaId,
        account_code: accountCode
      } = transition.to.params;
      this.replaceWith('manage.accounts.details', budgetLeaId, accountCode);
    }

  }

  _exports.default = ManageV3AccountsDetailsRoute;
});