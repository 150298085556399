define("balance-ember/components/report-export-single/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i7VUR4Iq",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"isBudgetArchitect\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"export-button\",[],[[\"@budget\",\"@reportType\",\"@exportAction\",\"@buttonText\"],[[32,0,[\"budget\"]],[32,0,[\"reportType\"]],[30,[36,0],[[32,0],\"fetchExportReport\"],null],\"Export Report\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "moduleName": "balance-ember/components/report-export-single/template.hbs"
  });

  _exports.default = _default;
});