define("balance-ember/controllers/budgeting/budget/expenses/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    currentAllocationID: null,

    init() {
      this._super(...arguments);

      this.router.on('routeWillChange', transition => {
        this.resetCurrentAllocationIfLeavingBudget(transition);
      });
    },

    resetCurrentAllocationIfLeavingBudget(transition) {
      // We want to hold on to the state of the last viewed allocation,
      // unless we're navigating away from the individual budget's route
      // then we reset it so that when we visit a new budget's expenses
      // we're taken to the first available allocation in the list.
      // See Also: routes/budgeting/budget/expenses/index.js
      if (this.currentAllocationID && (this.isLeavingBudgetRoute(transition) || this.isSwitchingBudgets(transition))) {
        Ember.set(this, 'currentAllocationID', null);
      }
    },

    isLeavingBudgetRoute(transition) {
      return !transition.to || !transition.to.name.includes('budgeting.budget');
    },

    isSwitchingBudgets(transition) {
      if (!transition.from) {
        return true;
      }

      let isBudgetRoute = routeInfo => routeInfo.name === 'budgeting.budget';

      let fromBudgetId = transition.from.find(isBudgetRoute).params.budget_id;
      let toBudgetId = transition.to.find(isBudgetRoute).params.budget_id;
      return fromBudgetId !== toBudgetId;
    }

  });

  _exports.default = _default;
});