define("balance-ember/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "balance-ember/utils/urls"], function (_exports, _applicationRouteMixin, _urls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //
  // This code is to handle button focus design.
  // We want buttons to show a visual focus outline only when tabbed to,
  // if we click on a button don't show the outline as it's jarring.
  // We do it via an attribute because Ember will often manipulate
  // the classlist on button actions, negating our work.
  const toggleFocusOutline = function (ev, showOrHide) {
    const attributeState = Boolean(showOrHide === 'show');
    const hidingAttributeName = 'data-focus-outline-hidden';
    const targetIsButton = Boolean(ev.target.tagName === 'BUTTON');
    const targetIsLink = Boolean(ev.target.tagName === 'A');

    if (targetIsButton || targetIsLink) {
      ev.target.setAttribute(hidingAttributeName, attributeState);
    }
  };

  const clickCallback = function (ev) {
    toggleFocusOutline(ev, 'show');
  };

  const blurCallback = function (ev) {
    toggleFocusOutline(ev, 'hide');
  };

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    liveChat: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    fetchFeatureFlags() {
      return this.ajax.request((0, _urls.URLForFeatureFlags)()).then(data => {
        let featureData = data.reduce((acc, curr) => {
          let {
            feature_key: featureKey,
            value
          } = curr;
          acc[featureKey] = value;
          return acc;
        }, {});
        this.features.setup(featureData);
      });
    },

    // load translations
    async beforeModel() {
      this.intl.setLocale(['en']); // add a query param using the current time stamp to bust the cache on the
      // translations file

      let translationsUrl = `${(0, _urls.URLForTranslations)(this.intl.primaryLocale)}?v=${Date.now()}`;
      let translations = await this.ajax.request(translationsUrl);
      this.intl.addTranslations(this.intl.primaryLocale, translations);
    },

    model() {
      return this.fetchFeatureFlags();
    },

    // This code is to handle button focus design.
    // We want buttons to show a visual focus outline only when tabbed to,
    // if we click on a button don't show the outline as it's jarring.
    activate() {
      document.querySelector('body').addEventListener('mousedown', clickCallback, {
        capture: true
      });
      document.querySelector('body').addEventListener('blur', blurCallback, {
        capture: true
      });
    },

    deactivate() {
      document.querySelector('body').removeEventListener('mousedown', clickCallback);
      document.querySelector('body').removeEventListener('blur', blurCallback);
    },

    // need to call super so the page refreses and empties the store
    // await transition to login so accpetance tests will behave
    async sessionInvalidated() {
      this.liveChat.clearMessages();
      this.segment.reset();

      this._super();

      return await this.router.transitionTo('login');
    }

  });

  _exports.default = _default;
});