define("balance-ember/services/live-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LiveChatService extends Ember.Service {
    open() {
      if (this.messenger) {
        this.messenger('show');
      }
    }

    clearMessages() {
      if (this.messenger) {
        this.messenger('shutdown');
      }
    }
    /**
     * Is loaded async and may not exist
     */


    get messenger() {
      return window.Intercom;
    }

  }

  _exports.default = LiveChatService;
});