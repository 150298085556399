define("balance-ember/components/filter-form/text/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/filters/text-formatter"], function (_exports, _component, _textFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    filter: null,
    operatorDescriptors: Ember.computed.readOnly('textFormatter.operatorDescriptors'),
    textFormatter: Ember.computed('filter', 'categoryConfig', function () {
      return new _textFormatter.default(this.filter, this.categoryConfig);
    }),
    actions: {
      formatCompletedValue(filterInput) {
        return `"${filterInput.value}"`;
      }

    }
  });

  _exports.default = _default;
});