define("balance-ember/serializers/report", ["exports", "balance-ember/serializers/application", "balance-ember/utils/morph-keys"], function (_exports, _application, _morphKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeQueryRecordResponse() {
      const result = this._super(...arguments); // We want to camelcase all the nested keys in the reportData property


      let {
        reportData
      } = result.data.attributes;

      if (reportData) {
        result.data.attributes.reportData = (0, _morphKeys.camelizeKeys)(reportData);
      } // Ember Data doesn't attach `meta` to results from queryRecord


      result.data.attributes.meta = result.meta;
      return result;
    }

  });

  _exports.default = _default;
});