define("balance-ember/components/ui-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cWjJNwIE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"LinkTab\",\"ButtonTab\"],[[30,[36,0],[\"ui-tabs/link-tab\"],null],[30,[36,0],[\"ui-tabs/button-tab\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "balance-ember/components/ui-tabs/template.hbs"
  });

  _exports.default = _default;
});