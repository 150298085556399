define("balance-ember/components/budget/reports/budget-fill-rates/component", ["exports", "balance-ember/mixins/budgeting-reports"], function (_exports, _budgetingReports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_budgetingReports.default, {
    availableFiscalYears: Ember.inject.service(),
    reportName: 'budget-fill-rates',
    defaultSortColumn: 'name',
    columns: Ember.computed(function () {
      let availableFiscalYears = this.availableFiscalYears;
      return [{
        label: 'Budget',
        valuePath: 'name',
        sortName: 'name',
        testSelector: 'name',
        cellClassNames: 'align-top',
        width: '250px',
        cellComponent: 'light-table/cell-components/link-cell',
        route: ['budgeting.budget.expenses', 'budgetId'],
        trackingEvent: 'View Budget From Report',
        trackingParams: {
          budget: 'name',
          fiscalYear: availableFiscalYears.current.name,
          reportName: 'Budget Vacancy and Fill Rate'
        }
      }, {
        label: 'Filled (FTE)',
        valuePath: 'filled',
        sortName: 'filled',
        testSelector: 'filled',
        cellClassNames: 'tabular-amount text-sm',
        cellType: 'allovue-cell',
        type: 'allovue-column'
      }, {
        label: 'Vacant (FTE)',
        valuePath: 'vacant',
        sortName: 'vacant',
        testSelector: 'vacant',
        cellClassNames: 'tabular-amount text-sm',
        cellType: 'allovue-cell',
        type: 'allovue-column'
      }, {
        label: 'Total (FTE)',
        valuePath: 'total',
        sortName: 'total',
        testSelector: 'total',
        cellClassNames: 'tabular-amount text-sm',
        cellType: 'allovue-cell',
        type: 'allovue-column'
      }];
    }),
    categories: Ember.computed(function () {
      return [{
        title: 'Budget',
        value: 'name',
        type: 'text'
      }, {
        title: 'Filled (FTE)',
        value: 'filled',
        type: 'decimal'
      }, {
        title: 'Vacant (FTE)',
        value: 'vacant',
        type: 'decimal'
      }, {
        title: 'Total (FTE)',
        value: 'total',
        type: 'decimal'
      }];
    })
  });

  _exports.default = _default;
});