define("balance-ember/helpers/numeral-formatter", ["exports", "balance-ember/utils/money-math", "numeral"], function (_exports, _moneyMath, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.numeralFormatter = numeralFormatter;
  _exports.default = void 0;

  function decidePattern(number, format, abbreviate) {
    if (!abbreviate) {
      return format;
    }

    if (number < 1000) {
      return '$0a'; // '$999', ''
    }

    if (number >= 1000) {
      return '$0.0a'; // $1.0K
    }
  } // Using some explicit destructuring here. You can pass any named parameter
  //   here, but we've specified default values for them to be explicit.
  // See https://guides.emberjs.com/v2.12.0/templates/writing-helpers/
  //   Ember helpers always get two parameters.
  //     The first is an array of unnamed parameters (in the order they are given).
  //     The second is an object consisting of the named parameters.


  function numeralFormatter([stringToFormat = ''], {
    format = '$0,0.00',
    abbreviate = false
  }) {
    if (format.includes('$')) {
      stringToFormat = (0, _moneyMath.roundMoneyAmount)(stringToFormat);
      format = decidePattern(stringToFormat, format, abbreviate);
    }

    let numeralObject = (0, _numeral.default)(stringToFormat);
    return numeralObject.format(format).toUpperCase();
  }

  var _default = Ember.Helper.helper(numeralFormatter);

  _exports.default = _default;
});