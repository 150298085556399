define("balance-ember/components/light-table/columns/allovue-column/component", ["exports", "ember-light-table/components/columns/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    init() {
      this._super(...arguments);

      let attributeBindings = this.attributeBindings;
      let testSelector = this.column.testSelector;
      Ember.set(this, 'attributeBindings', attributeBindings.concat([`testSelectorValue:data-test-${testSelector}-header`, 'testId:data-test-id']));
    },

    testSelectorValue: Ember.computed('column.testSelectorValue', function () {
      let value = this.column.testSelectorValue;
      return value ? value : true;
    }),
    testId: Ember.computed('column.testSelector', function () {
      return `column-header-${this.column.testSelector}`;
    })
  });

  _exports.default = _default;
});