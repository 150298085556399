define("balance-ember/components/icon-button/default-layout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u7J2r/tR",
    "block": "{\"symbols\":[\"@displayText\",\"@svgSizeClasses\",\"@icon\",\"@iconClasses\",\"@showButtonText\",\"@buttonText\"],\"statements\":[[10,\"span\"],[15,0,[31,[\"\\n  leading-none\\n  \",[32,2]]]],[12],[2,\"\\n  \"],[8,\"icon-svg\",[],[[\"@icon\",\"@class\"],[[32,3],[32,4]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"span\"],[15,0,[30,[36,0],[[32,5],\"ml-4\",\"sr-only\"],null]],[12],[2,\"\\n  \"],[1,[32,6]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,\"aria-hidden\",true],[14,0,\"leading-none pointer-events-none ml-4\"],[12],[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/icon-button/default-layout/template.hbs"
  });

  _exports.default = _default;
});