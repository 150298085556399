define("balance-ember/components/text-view-with-links/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "71GLoEzn",
    "block": "{\"symbols\":[\"@placeholder\"],\"statements\":[[10,\"section\"],[14,0,\"text-view-with-links px-24 py-16\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,0,[\"text\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,0,[\"text\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"linkify\",\"is-empty\",\"if\"]}",
    "moduleName": "balance-ember/components/text-view-with-links/template.hbs"
  });

  _exports.default = _default;
});