define("balance-ember/components/fiscal-year-form/chart-of-accounts/reuse-existing/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    existingFiscalYears: Ember.computed(function () {
      return this.store.loadRecords('fiscalYear');
    }),
    selectedFiscalYear: Ember.computed('fiscalYear.segmentsSource', function () {
      let id = this.fiscalYear.segmentsSource;
      return id ? this.store.peekRecord('fiscalYear', id) : null;
    }),
    actions: {
      updateSegmentsSource(fiscalYear) {
        Ember.set(this, 'fiscalYear.segmentsSource', fiscalYear.id);
      }

    }
  });

  _exports.default = _default;
});