define("balance-ember/templates/budgeting/budget/details/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1jxZmD2p",
    "block": "{\"symbols\":[\"data\",\"@model\"],\"statements\":[[8,\"budget/budgets/form-data\",[],[[\"@budget\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"budget/budgets/form\",[],[[\"@allBudgetUsers\",\"@originalBudget\",\"@budget\",\"@amazonVendor\",\"@loadTask\",\"@style\",\"@actionButtonText\",\"@disabledButtonText\",\"@formTitle\"],[[32,1,[\"budgetUsers\"]],[32,2],[32,1,[\"budget\"]],[32,1,[\"amazonVendor\"]],[32,1,[\"loadTask\"]],\"slideout wide padded\",\"Save Changes\",\"Saving\",[31,[\"Update \",[32,0,[\"budget\",\"name\"]]]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/templates/budgeting/budget/details/edit.hbs"
  });

  _exports.default = _default;
});