define("balance-ember/components/user/profile-tabs/account-info/edit/roles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IkiPNRrv",
    "block": "{\"symbols\":[\"suggestion\"],\"statements\":[[10,\"div\"],[14,0,\"w-full\"],[12],[2,\"\\n  \"],[8,\"autocomplete/multiple\",[],[[\"@selected\",\"@options\",\"@searchField\",\"@onChange\",\"@dropdownClass\"],[[32,0,[\"userDistrictRoles\"]],[32,0,[\"districtRoles\"]],\"label\",[32,0,[\"updateDistrictRoles\"]],\"w-full\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1,[\"label\"]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/user/profile-tabs/account-info/edit/roles/template.hbs"
  });

  _exports.default = _default;
});