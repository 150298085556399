define("balance-ember/components/account-amounts-donut/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Eswm5Td6",
    "block": "{\"symbols\":[\"@series\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[8,\"highcharts-chart\",[],[[\"@options\",\"@series\",\"@destinationId\"],[[32,0,[\"options\"]],[32,1],\"accounts-details-donut-destination\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/account-amounts-donut/template.hbs"
  });

  _exports.default = _default;
});