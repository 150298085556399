define("balance-ember/components/multi-step-dropdown/dropdown-step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fICSKjWa",
    "block": "{\"symbols\":[\"@renderInPlace\",\"@closeModal\",\"@uniqueId\",\"&default\"],\"statements\":[[6,[37,2],[[32,0,[\"shouldShow\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui-modal\",[],[[\"@style\",\"@renderInPlace\",\"@closeModal\",\"@stackable\",\"@tetherTarget\",\"@attachment\",\"@targetAttachment\",\"@offset\",\"@tetherClasses\"],[\"no-overlay rounded\",[32,1],[32,2],[32,0,[\"stackable\"]],[30,[36,0],[\".dropdown-trigger-\",[32,3]],null],[32,0,[\"attachment\"]],[32,0,[\"targetAttachment\"]],[32,0,[\"offset\"]],\"mt-8\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"button\"],[24,0,\"sr-only\"],[24,4,\"button\"],[4,[38,1],[[32,0],[32,2]],null],[12],[2,\"\\n      Close Dropdown\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,\"data-dropdown-step\",[32,3]],[12],[2,\"\\n      \"],[18,4,null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"action\",\"if\"]}",
    "moduleName": "balance-ember/components/multi-step-dropdown/dropdown-step/template.hbs"
  });

  _exports.default = _default;
});