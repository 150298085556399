define("balance-ember/components/employees-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sbYbjPQT",
    "block": "{\"symbols\":[\"T\",\"Controls\",\"Body\",\"Controls\",\"@tableState\",\"@categories\",\"@employeeChanges\"],\"statements\":[[8,\"table\",[],[[\"@tableState\",\"@columns\",\"@categories\",\"@loadTask\",\"@modelChanges\",\"@trackingPrefix\",\"@tableActions\"],[[32,5],[32,0,[\"columns\"]],[32,6],[32,0,[\"loadEmployees\"]],[32,7],\"Employees\",[30,[36,0],null,[[\"edit\",\"delete\"],[[32,0,[\"edit\"]],[32,0,[\"delete\"]]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,4,[\"filter-button\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,4,[\"pagination\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[4]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"filter-bar\"]],[],[[],[]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"body\"]],[],[[\"@rowTestSelector\",\"@id\",\"@noDataIcon\"],[\"employee-row\",\"employees-table\",\"na-employees\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"loader\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Loading Employees....\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,3,[\"noData\"]],[],[[\"@noDataText\",\"@linkToPath\",\"@buttonText\"],[\"No employees yet.\",\"budgeting.employees.new\",\"Add Employee\"]],null],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"controls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"pagination\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "balance-ember/components/employees-table/template.hbs"
  });

  _exports.default = _default;
});