define("balance-ember/components/downloads-dropdown/download-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FFZj+h1o",
    "block": "{\"symbols\":[\"file\",\"index\",\"&default\",\"@title\",\"@files\"],\"statements\":[[10,\"div\"],[14,0,\"download-category-group in-progress-downloads\"],[12],[2,\"\\n  \"],[10,\"h5\"],[14,0,\"text-13 text-material-gray700 font-normal mt-16 mb-8 mx-16\"],[12],[2,\"\\n    \"],[1,[32,4]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[18,3,[[32,1],[32,2]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/downloads-dropdown/download-group/template.hbs"
  });

  _exports.default = _default;
});