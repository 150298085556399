define("balance-ember/components/radio-button-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param {object} row
   * @param {object} column
   * @param {string} column.namePath Property on your object to use for the `name` attribute
   * @param {string} column.actionName The name of your action on the `tableActions` property. The
   * action will be passed the object and the chosen option
   * @param {string} column.testSelectorName The name of the test selector attribute you want to use
   * @param {string} column.testSelectorValuePath The path to the property on the object to use as the
   * value for the test selector
   * @param {string} column.controlLabel A function to generate a label message for each radio button.
   * The function will be passed the current rows object
   * @param {any} value The value that comes from your object.valuePath
   * @param {any} option The value that should be used for the radio button
   */
  var _default = Ember.Component.extend({
    attributeBindings: [],
    controlId: null,

    init() {
      this._super(...arguments); // We want a test selector, but we want the name of the test selector to be dynamic which
      // you can't do in the template


      let attributeBindings = this.attributeBindings;
      let testSelector = this.column.testSelectorName;
      Ember.set(this, 'attributeBindings', attributeBindings.concat([`testSelectorValue:data-test-${testSelector}`]));
      Ember.set(this, 'controlId', `${testSelector}-${this.row.content.id}`);
    },

    testSelectorValue: Ember.computed('column.testSelectorValuePath', 'row.content', function () {
      let path = this.column.testSelectorValuePath;
      return this.row.content[path];
    }),
    controlLabel: Ember.computed('column.controlLabel', 'row.content', function () {
      return this.column.controlLabel(this.row.content);
    })
  });

  _exports.default = _default;
});