define("balance-ember/components/light-table/cell-components/actions-dropdown-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ekucao9S",
    "block": "{\"symbols\":[\"b\",\"popover\",\"@value\",\"@row\",\"@column\"],\"statements\":[[10,\"div\"],[14,0,\"mr-16\"],[12],[2,\"\\n  \"],[8,\"icon-button\",[[24,0,\"w-40 h-40\"]],[[\"@style\",\"@size\",\"@icon\",\"@testId\",\"@buttonText\",\"@onclick\"],[\"btn-subtle-link\",\"xlg\",\"admin\",[31,[\"open-actions-dropdown-for-\",[32,3]]],[31,[\"Open Actions for \",[32,0,[\"buttonText\"]]]],[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,[32,1,[\"icon\"]],[],[[\"@class\"],[[30,[36,1],[[32,4,[\"expanded\"]],\"w-20 h-20 text-material-light-blue500\",[32,1,[\"iconClasses\"]]],null]]],null],[2,\"\\n\"],[2,\"    \"],[8,\"popover\",[],[[\"@arrow\",\"@placement\"],[false,\"bottom\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,3],[[30,[36,2],[[32,5,[\"dropdownComponent\"]],\"table/actions-popover\"],null]],[[\"value\",\"row\",\"column\",\"popover\"],[[32,3],[32,4],[32,5],[32,2]]]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"stop-propagation\",\"if\",\"or\",\"component\"]}",
    "moduleName": "balance-ember/components/light-table/cell-components/actions-dropdown-cell/template.hbs"
  });

  _exports.default = _default;
});