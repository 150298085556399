define("balance-ember/components/add-filter-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y838kxYY",
    "block": "{\"symbols\":[\"d\",\"@categories\",\"&default\"],\"statements\":[[8,\"multi-step-dropdown\",[],[[\"@onClose\",\"@renderInPlace\"],[[30,[36,0],[[32,0],\"onClickOutside\"],null],[32,0,[\"renderInPlace\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,3,[[30,[36,1],null,[[\"trigger\",\"step\",\"filter\",\"isOpen\",\"category-list\",\"filter-form\",\"actions\"],[[32,1,[\"trigger\"]],[32,1,[\"step\"]],[32,0,[\"forkedFilter\"]],[32,1,[\"state\",\"isOpen\"]],[30,[36,2],[\"add-filter-button/category-list\"],[[\"categories\",\"afterUpdate\",\"filter\"],[[32,2],[32,1,[\"actions\",\"nextStep\"]],[32,0,[\"forkedFilter\"]]]]],[30,[36,2],[\"filter-form\"],[[\"categoryConfig\",\"filter\",\"cancel\",\"done\"],[[32,0,[\"categoryConfig\"]],[32,0,[\"forkedFilter\"]],[30,[36,0],[[32,0],\"cancel\",[32,1,[\"actions\",\"close\"]]],null],[30,[36,0],[[32,0],\"apply\",[32,1,[\"actions\",\"close\"]]],null]]]],[30,[36,1],null,[[\"cancel\",\"done\",\"nextStep\",\"close\"],[[30,[36,0],[[32,0],\"cancel\",[32,1,[\"actions\",\"close\"]]],null],[30,[36,0],[[32,0],\"apply\",[32,1,[\"actions\",\"close\"]]],null],[32,1,[\"actions\",\"nextStep\"]],[32,1,[\"actions\",\"close\"]]]]]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\",\"component\"]}",
    "moduleName": "balance-ember/components/add-filter-button/template.hbs"
  });

  _exports.default = _default;
});