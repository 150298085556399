define("balance-ember/components/report-downloader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3na880r/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"downloadReport\",\"downloadResourceReport\"],[[30,[36,0],[[32,0],\"downloadReport\"],null],[30,[36,0],[[32,0],\"downloadResourceReport\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\"]}",
    "moduleName": "balance-ember/components/report-downloader/template.hbs"
  });

  _exports.default = _default;
});