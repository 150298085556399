define("balance-ember/components/table/bulk-action-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JI3ad61b",
    "block": "{\"symbols\":[\"@tableState\",\"@selectedRows\",\"&default\"],\"statements\":[[6,[37,5],[[32,0,[\"numSelected\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"bg-material-blue700 px-16 py-12\"],[4,[38,0],[[32,0,[\"forkTableState\"]]],null],[4,[38,1],[[32,0,[\"checkForFilterChange\"]],[32,1]],null],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex justify-between items-center\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"flex items-center\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-white font-bold\"],[12],[2,\"\\n          \"],[1,[30,[36,2],[\"global.table.bulk_actions.count_label\"],[[\"count\"],[[32,0,[\"numSelected\"]]]]]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"ml-12\"],[12],[2,\"\\n          \"],[18,3,[[30,[36,4],null,[[\"tag\"],[[30,[36,3],[\"table/bulk-action-bar/tag-button\"],[[\"selectedRows\",\"deselect\"],[[32,2],[32,0,[\"deselect\"]]]]]]]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[8,\"button\",[],[[\"@style\",\"@size\",\"@testId\",\"@onclick\"],[\"btn-white-link\",\"sm\",\"deselect-button\",[32,0,[\"deselect\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[\"global.buttons.deselect\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"t\",\"component\",\"hash\",\"if\"]}",
    "moduleName": "balance-ember/components/table/bulk-action-bar/template.hbs"
  });

  _exports.default = _default;
});