define("balance-ember/components/budget/expense-table/edit-segments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ysFnHlCm",
    "block": "{\"symbols\":[\"StackItem\",\"segmentType\",\"@expense\",\"@errors\"],\"statements\":[[11,\"div\"],[24,0,\"flex flex-col items-center\"],[4,[38,0],[[32,0],\"wrapper\"],null],[12],[2,\"\\n  \"],[8,\"layout/stack\",[],[[\"@space\"],[\"xs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"segmentTypes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"budget/expense-table/edit-segments/segment-type\",[],[[\"@expense\",\"@segmentType\",\"@errors\",\"@changeSegment\"],[[32,3],[32,2],[32,4],[32,0,[\"changeSegment\"]]]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"ref\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/budget/expense-table/edit-segments/template.hbs"
  });

  _exports.default = _default;
});