define("balance-ember/templates/budgeting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T70Ryv8H",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"shouldShowBanner\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui-hooks\",[],[[\"@did-insert\",\"@will-destroy\"],[[30,[36,0],[[32,0],\"showNonCurrentFiscalYearBanner\"],null],[30,[36,0],[[32,0],\"hideNonCurrentFiscalYearBanner\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "balance-ember/templates/budgeting.hbs"
  });

  _exports.default = _default;
});