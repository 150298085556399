define("balance-ember/components/light-table/cell-components/table-actions/delete/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LightTableCellComponentsTableActionsDeleteComponent extends _component.default {
    get buttonText() {
      return this.args.column.buttonText(this.args.row.content);
    }

    get isDeleteDisabled() {
      if (this.args.row.content.isDeleted) return true;

      if (this.args.column.isDeleteDisabled) {
        return this.args.column.isDeleteDisabled(this.args.row.content);
      }

      return false;
    }

    get deleteTooltipText() {
      if (this.args.column.deleteTooltipText && !this.args.row.content.isDeleted) {
        return this.args.column.deleteTooltipText(this.args.row.content);
      }

      return '';
    }

  }

  _exports.default = LightTableCellComponentsTableActionsDeleteComponent;
});