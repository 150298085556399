define("balance-ember/components/power-select-single/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['power-select-single'],
    style: 'blue-selected',
    boxStyles: Ember.computed('style', function () {
      let style = '';

      if (this.style.includes('blue-selected')) {
        style = 'blue';
      }

      if (this.style.includes('white-selected')) {
        style = 'white';
      }

      return style;
    }),
    actions: {
      deselect() {
        this.onchange(null);
      }

    }
  });

  _exports.default = _default;
});