define("balance-ember/routes/budgeting/budget/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel() {
      // There is no content on the budget index page, so we want the user to be redirected to
      // expenses. We're using replaceWith, because we don't want the index page included in the history
      // in case the user presses back
      this.replaceWith('budgeting.budget.expenses');
    }

  });

  _exports.default = _default;
});