define("balance-ember/routes/budgeting/segments/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BudgetinSegmentsNewRoute extends Ember.Route {
    model() {
      return this.store.createRecord('segment');
    }

  }

  _exports.default = BudgetinSegmentsNewRoute;
});