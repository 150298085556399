define("balance-ember/components/allocation-form-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AKfQU8/v",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"loadingData\",\"isFinished\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,0],null,[[\"allocation\",\"budgets\",\"segmentTypes\"],[[32,0,[\"allocation\"]],[32,0,[\"budgets\"]],[32,0,[\"segmentTypes\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"if\"]}",
    "moduleName": "balance-ember/components/allocation-form-data/template.hbs"
  });

  _exports.default = _default;
});