define("balance-ember/types/autocomplete-substring-operator", ["exports", "balance-ember/types/filter-operator", "balance-ember/types/autocomplete-substring-filter-value", "@microstates/ember"], function (_exports, _filterOperator, _autocompleteSubstringFilterValue, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function getFilterValue(operator, index) {
    let firstValue = [...operator.filterValues][index];
    return firstValue ? (0, _ember.valueOf)(firstValue) : {};
  }

  class AutocompleteSubstringOperator extends _filterOperator.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "filterValues", [_autocompleteSubstringFilterValue.default]);
    }

    get firstFilterValue() {
      return getFilterValue(this, 0);
    }

    get secondFilterValue() {
      return getFilterValue(this, 1);
    }

  }

  _exports.default = AutocompleteSubstringOperator;
});