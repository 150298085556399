define("balance-ember/components/budgeting-segments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eluQUglG",
    "block": "{\"symbols\":[\"segment\"],\"statements\":[[10,\"div\"],[14,0,\"allocation-segments-wrapper\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"allocationName\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"allocation-name\"],[12],[1,[32,0,[\"allocationName\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"allocation-segments\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"segments\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"allocation-segment\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"cell-title\"],[12],[1,[32,1,[\"segmentTypeName\"]]],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"cell-code code\"],[12],[1,[32,1,[\"code\"]]],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"cell-content\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "balance-ember/components/budgeting-segments/template.hbs"
  });

  _exports.default = _default;
});