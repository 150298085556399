define("balance-ember/components/budget/expense-table/edit-segments/component", ["exports", "@glimmer/component", "balance-ember/utils/expense-totals"], function (_exports, _component, _expenseTotals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetExpenseTableEditSegmentsComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed('allocationSegments', 'allowedSegmentTypes', 'args.expense.segments.[]'), _dec3 = Ember._action, (_class = class BudgetExpenseTableEditSegmentsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor, this);
    }

    get segmentTypes() {
      return this.allowedSegmentTypes.sortBy('displayOrder').map(segmentType => {
        let lockedSegment = this.allocationSegments.findBy('segmentType.id', segmentType.id);
        let unlockedSegment = this.args.expense.segments.findBy('segmentType.id', segmentType.id);
        return {
          segmentType,
          segment: unlockedSegment ?? lockedSegment,
          isLocked: Boolean(lockedSegment)
        };
      });
    }

    get allowedSegmentTypes() {
      return this.allSegmentTypes.reject(segmentType => {
        if (!this.args.expense.canHaveSalarySegment && segmentType.salarySegment) {
          return true;
        }
      });
    }

    get allocationSegments() {
      return new _expenseTotals.default({
        expense: this.args.expense
      }).allocationSegments;
    }

    get allSegmentTypes() {
      return this.availableFiscalYears.current.segmentTypes;
    }

    changeSegment(segment, segmentTypeId) {
      if (!segment) {
        return this.removeSegment(segmentTypeId);
      }

      this.args.expense.addSegment(segment); // Wait till after the next render cycle so that we don't lose the inputs from the DOM
      // while we are trying to apply focus

      Ember.run.next(this, 'focusNextSegmentInput', segmentTypeId);
    }

    removeSegment(segmentTypeId) {
      let segment = this.args.expense.segments.findBy('segmentType.id', segmentTypeId);
      this.args.expense.disassociateSegment(segment);
    }

    focusNextSegmentInput(currentSegmentTypeId) {
      let unlockedSegmentTypes = this.segmentTypes.filterBy('isLocked', false);
      let currentIndex = unlockedSegmentTypes.mapBy('segmentType.id').indexOf(currentSegmentTypeId);
      let nextSegmentType = unlockedSegmentTypes[currentIndex + 1];

      if (nextSegmentType && !nextSegmentType.segment) {
        var _this$wrapper$querySe;

        let selector = `[data-segment-finder="${nextSegmentType.segmentType.name}"] input`;
        (_this$wrapper$querySe = this.wrapper.querySelector(selector)) === null || _this$wrapper$querySe === void 0 ? void 0 : _this$wrapper$querySe.focus();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "segmentTypes", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "segmentTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSegment", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeSegment"), _class.prototype)), _class));
  _exports.default = BudgetExpenseTableEditSegmentsComponent;
});