define("balance-ember/mixins/route-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Use this mixin on routes that you wish to track the history of
   */
  var _default = Ember.Mixin.create({
    routeHistory: Ember.inject.service(),

    activate() {
      this._super(...arguments);

      let routeName = this.routeName; // We want to add to the history on every route change, which is why we are doing this in
      // `activate`. `beforeModel` and other model hooks won't be fired on every transition so
      // they don't help us here.

      this.routeHistory.addToHistory(routeName, this.paramsFor(routeName));
    }

  });

  _exports.default = _default;
});