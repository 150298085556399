define("balance-ember/components/budget/allocations/table/table-header/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    grandTotals: Ember.computed.readOnly('extra.grandTotals')
  });

  _exports.default = _default;
});