define("balance-ember/utils/validators/purchase-order", ["exports", "balance-ember/utils/validator", "moment"], function (_exports, _validator, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class _default extends _validator.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "constraints", {
        number: {
          presence: {
            allowEmpty: false
          }
        },
        notes: {
          length: {
            minimum: 1
          },
          array: {
            message: {
              presence: {
                allowEmpty: false
              }
            }
          }
        },
        startDate: {
          presence: {
            allowEmpty: false
          },
          datetime: true
        },
        endDate: {
          presence: {
            allowEmpty: false
          },
          datetime: true,
          equality: {
            attribute: 'startDate',
            comparator: (end, start) => (0, _moment.default)(end).isAfter((0, _moment.default)(start)),
            message: 'budget.po.validation.no_time_travel'
          }
        },
        active: {
          presence: {
            allowEmpty: false
          }
        }
      });

      _defineProperty(this, "constraintPropertyLookups", {
        number: 'budget.po.data.number',
        notes: 'budget.po.data.bpo_description',
        'notes.message': 'budget.po.data.bpo_description',
        startDate: 'budget.po.data.start',
        endDate: 'budget.po.data.end',
        active: 'budget.po.data.active'
      });
    }

  }

  _exports.default = _default;
});