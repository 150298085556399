define("balance-ember/components/positions-page/component", ["exports", "@glimmer/component", "balance-ember/utils/urls", "ember-concurrency", "balance-ember/classes/table-serializer"], function (_exports, _component, _urls, _emberConcurrency, _tableSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * This component represents the full positions section of the app, including all positions in a fiscal year
   */
  let PositionsPage = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed('fiscalYearId'), _dec7 = Ember.computed('modelChanges.changes.[]'), _dec8 = (0, _emberConcurrency.task)(function* () {
    let positions = yield this.store.query('position', { ...this.serializedTableState,
      filter: { ...this.serializedTableState.filter,
        fiscal_year_id: {
          eq: this.fiscalYearId
        }
      },
      include: 'budget,employee,job_type.staff_type',
      extra_fields: {
        positions: 'editable,budgeted_cost,percent_funded'
      },
      fields: {
        budgets: 'name',
        employees: 'name,code',
        job_types: 'title,code'
      },
      stats: {
        total: 'count'
      },
      paginate: false
    });
    Ember.set(this, 'positions', positions);
    return positions;
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    let positions = yield this.fetchPositions.perform();
    return positions;
  }), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class PositionsPage extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "availableFiscalYears", _descriptor, this);

      _initializerDefineProperty(this, "modelChanges", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "eventTracking", _descriptor5, this);

      _defineProperty(this, "fundedWidgetRoutePath", 'budgeting.fiscal-year.positions.fund-position');

      _initializerDefineProperty(this, "fetchPositions", _descriptor6, this);

      _initializerDefineProperty(this, "loadTask", _descriptor7, this);
    }

    get fiscalYearId() {
      return this.availableFiscalYears.current.id;
    }

    get positionBulkUrl() {
      return `${(0, _urls.URLForBudgetNamespace)()}/fiscal_years/${this.fiscalYearId}/positions/bulk_upload`;
    }

    get positionChanges() {
      return this.modelChanges.changesForModelType('position');
    }

    get serializedTableState() {
      return new _tableSerializer.default(this.args.tableState).apiQuery;
    }

    afterUploadSuccess() {
      this.modelChanges.addChange({
        modelType: 'position'
      });
    }

    onEditClick(position) {
      this.router.transitionTo('budgeting.fiscal-year.positions.edit', position.id);
    }

    onRowClick(position) {
      this.eventTracking.trackEvent('Position Details Opened', {
        fiscalYear: this.availableFiscalYears.current.name,
        budget: position.get('budget.name')
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "availableFiscalYears", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modelChanges", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "eventTracking", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "positionBulkUrl", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "positionBulkUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "positionChanges", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "positionChanges"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fetchPositions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loadTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterUploadSuccess", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "afterUploadSuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEditClick", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onEditClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRowClick", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onRowClick"), _class.prototype)), _class));
  _exports.default = PositionsPage;
});