define("balance-ember/components/container/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    style: '',
    height: '',
    styleClasses: Ember.computed('style', function () {
      let style = '';

      if (this.style.includes('horizontal') && this.style.includes('centered') && this.style.includes('page')) {
        style = `${style} min-w-768 max-w-1280 mx-auto`;
      }

      if (this.style.includes('padding-x') && this.style.includes('page')) {
        style = `${style} px-24`;
      }

      if (this.style.includes('padding-all') && this.style.includes('page')) {
        style = `${style} p-24`;
      }

      if (this.style.includes('dashboard')) {
        style = `${style} bg-white rounded shadow-container`;
      }

      if (this.style.includes('tabbed-budget')) {
        style = `${style} border-b border-budgeting-border pt-8 px-16 bg-budgeting-bg-color`;
      }

      return style;
    })
  });

  _exports.default = _default;
});