define("balance-ember/routes/budgeting/budget/strategies/destroy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BudgetingBudgetStrategiesDeleteRoute extends Ember.Route {
    model({
      strategy_id: id
    }) {
      return this.store.findRecord('budget-strategy', id);
    }

  }

  _exports.default = BudgetingBudgetStrategiesDeleteRoute;
});