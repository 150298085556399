define("balance-ember/components/budget/purchase-orders/existing-view/amazon-purchases-table/expanded-row/table/cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b625AnWG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"td\"],[14,0,\"\\n  first:bg-material-blue-gray50\\n  py-4\\n  px-8\\n  text-material-blue-gray700\\n  border\\n  border-material-blue-gray200\\n  last:text-right\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/budget/purchase-orders/existing-view/amazon-purchases-table/expanded-row/table/cell/template.hbs"
  });

  _exports.default = _default;
});