define("balance-ember/components/budget/purchase-orders/potential-view/component", ["exports", "@glimmer/component", "balance-ember/utils/refresh-route"], function (_exports, _component, _refreshRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BudgetPurchaseOrdersPotentialViewComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class BudgetPurchaseOrdersPotentialViewComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "amazonBusiness", _descriptor, this);
    }

    get needsAmazonAccess() {
      return this.isAmazonVendor && !this.hasAmazonAccess;
    }

    get needsGroupId() {
      return this.isAmazonVendor && !this.hasGroupId;
    }

    get hasGroupId() {
      var _this$args$allocation, _this$args$allocation2;

      return (_this$args$allocation = this.args.allocation) === null || _this$args$allocation === void 0 ? void 0 : (_this$args$allocation2 = _this$args$allocation.budget) === null || _this$args$allocation2 === void 0 ? void 0 : _this$args$allocation2.amazonGroupId;
    }

    get hasAmazonAccess() {
      return this.amazonBusiness.isConnected;
    }

    get isAmazonVendor() {
      var _this$args$vendor;

      return (_this$args$vendor = this.args.vendor) === null || _this$args$vendor === void 0 ? void 0 : _this$args$vendor.amazonBusiness;
    }

    afterNonPersonnelExpenseSave() {
      (0, _refreshRoute.default)('budgeting.fiscal-year.purchase-orders.view-report', this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "amazonBusiness", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterNonPersonnelExpenseSave", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "afterNonPersonnelExpenseSave"), _class.prototype)), _class));
  _exports.default = BudgetPurchaseOrdersPotentialViewComponent;
});