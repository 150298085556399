define("balance-ember/components/budget/budgets/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jEFGWlQM",
    "block": "{\"symbols\":[\"StackItem\",\"session\",\"InlineItem\",\"@tableState\",\"@categories\"],\"statements\":[[8,\"layout/stack\",[],[[\"@space\"],[\"s\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"auth-provider\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"layout/inline\",[],[[\"@space\"],[\"s\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,2,[\"isBudgetArchitect\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"budget/budgets/buttons/create\",[],[[],[]],null],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\\n          \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"budget/budgets/buttons/upload\",[],[[\"@tableState\",\"@categories\"],[[32,4],[32,5]]],null],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\\n          \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"budget/budgets/buttons/export\",[],[[],[]],null],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[3]}]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"w-full\"],[12],[2,\"\\n      \"],[8,\"budget/budgets/table\",[],[[\"@tableState\",\"@categories\"],[[32,4],[32,5]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "balance-ember/components/budget/budgets/template.hbs"
  });

  _exports.default = _default;
});