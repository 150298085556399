define("balance-ember/components/budget-quick-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZrQxuaVK",
    "block": "{\"symbols\":[\"suggestion\"],\"statements\":[[8,\"power-select-single\",[[24,0,\"w-256\"]],[[\"@noMatchesMessage\",\"@placeholder\",\"@search\",\"@selected\",\"@triggerId\",\"@triggerClass\",\"@onchange\"],[\"No Matching Budgets Found\",\"Jump to budget...\",[30,[36,0],[[32,0,[\"fetchBudgetsTask\"]]],null],[32,0,[\"budget\",\"id\"]],[32,0,[\"context\",\"id\"]],\"assigned-budget-control\",[30,[36,1],[[32,0],\"goToBudgetExpensesPage\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"action\"]}",
    "moduleName": "balance-ember/components/budget-quick-picker/template.hbs"
  });

  _exports.default = _default;
});