define("balance-ember/components/manage/spending-by-segment/cell-utilized/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/money-math"], function (_exports, _component, _moneyMath) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    encumbered: Ember.computed.readOnly('row.encumbered'),
    spent: Ember.computed.readOnly('row.spent'),
    budgeted: Ember.computed.readOnly('row.budgeted'),
    available: Ember.computed.readOnly('row.available'),
    isInfinite: Ember.computed.equal('percentUtilized', Infinity),
    utilized: Ember.computed('spent', 'encumbered', function () {
      return Number(this.spent) + Number(this.encumbered);
    }),
    percentUtilized: Ember.computed('utilized', 'budgeted', function () {
      // Guard against divide by zero error
      return this.budgeted ? Math.round(this.utilized / Number(this.budgeted) * 100) : 0;
    }),
    isDeficit: Ember.computed('available', function () {
      return (0, _moneyMath.roundMoneyAmount)(Number(this.available)) < 0;
    })
  });

  _exports.default = _default;
});