define("balance-ember/components/filter-form/text/input/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/utils/key-codes"], function (_exports, _component, _keyCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    radioOperators: Object.freeze([{
      value: 'match',
      label: 'Contains',
      testSelector: 'contains-match'
    }, {
      value: 'not_match',
      label: 'Doesn\'t Contain',
      testSelector: 'not-contains-match'
    }]),
    filterInput: null,

    didInsertElement() {
      this._super(...arguments);

      this.moveFocusToInput();
    },

    moveFocusToInput() {
      let input = document.getElementById(this.id);

      if (input) {
        input.focus();
      }
    },

    preventEnterFromSubmittingForm(evt) {
      if (evt.keyCode === _keyCodes.ENTER && evt.target.value) {
        evt.preventDefault();
      }
    },

    actions: {
      onkeydown(evt) {
        this.preventEnterFromSubmittingForm(evt);
      }

    }
  });

  _exports.default = _default;
});