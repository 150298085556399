define("balance-ember/adapters/displaced-employee", ["exports", "balance-ember/adapters/report"], function (_exports, _report) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _report.default.extend({});

  _exports.default = _default;
});