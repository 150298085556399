define("balance-ember/components/budget/supplemental-pay-expense-form/totals-table/component", ["exports", "balance-ember/components/tagless-component/component", "balance-ember/mixins/expense-allocation-subtotal"], function (_exports, _component, _expenseAllocationSubtotal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_expenseAllocationSubtotal.default, {
    supplementalPayExpense: null,
    // @param
    model: Ember.computed.readOnly('supplementalPayExpense'),

    init() {
      this._super(...arguments);

      let initialExpenseSubtotal = this.model.totalAmount;
      Ember.set(this, 'initialExpenseSubtotal', initialExpenseSubtotal);
    },

    expenseSubtotal: Ember.computed('initialExpenseSubtotal', 'model.totalAmount', function () {
      let newValue = this.model.totalAmount;
      let oldValue = this.initialExpenseSubtotal;
      return newValue - oldValue;
    })
  });

  _exports.default = _default;
});