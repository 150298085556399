define("balance-ember/components/manage/download-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ryGminSE",
    "block": "{\"symbols\":[\"b\",\"popover\",\"StackItem\"],\"statements\":[[10,\"div\"],[14,0,\"flex h-32 px-10\"],[12],[2,\"\\n  \"],[8,\"icon-button\",[[24,1,\"file-download\"]],[[\"@style\",\"@size\",\"@icon\",\"@buttonText\",\"@showButtonText\",\"@testId\"],[\"btn-secondary-link\",\"sm\",\"download\",\"Download\",[30,[36,0],[\"isXxl\"],null],\"download-trigger\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,[32,1,[\"defaultLayout\"]],[],[[],[]],null],[2,\"\\n\\n    \"],[8,\"popover\",[],[[\"@placement\"],[\"bottom-end\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"w-256 p-10\"],[12],[2,\"\\n        \"],[8,\"layout/stack\",[],[[\"@space\"],[\"xxs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[8,\"csv-download-button\",[],[[\"@onclick\"],[[30,[36,1],[[32,0,[\"download\"]],\"csv\",[32,2,[\"hide\"]]],null]]],null]],\"parameters\":[]}]]],[2,\"\\n          \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[8,\"excel-download-button\",[],[[\"@onclick\"],[[30,[36,1],[[32,0,[\"download\"]],\"xlsx\",[32,2,[\"hide\"]]],null]]],null]],\"parameters\":[]}]]],[2,\"\\n          \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"p\"],[14,0,\"text-material-gray700 text-xs leading-16\"],[12],[2,\"\\n              Excel Downloads are limited to the first 100k rows.\\n            \"],[13],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[3]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"media\",\"fn\"]}",
    "moduleName": "balance-ember/components/manage/download-dropdown/template.hbs"
  });

  _exports.default = _default;
});