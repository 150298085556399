define("balance-ember/components/checkbox/tristate/component", ["exports", "@glimmer/component", "@microstates/ember"], function (_exports, _component, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let CheckboxTristateComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class CheckboxTristateComponent extends _component.default {
    constructor() {
      super(...arguments); // We care what the state is at construction time. If it starts as 'partial' it
      // can have 3 states, otherwise it will be traditional true/false

      _initializerDefineProperty(this, "checkboxMachine", _descriptor, this);

      let isPartial = this.args.checked === 'partial';
      let machineClass = isPartial ? TernaryCheckboxMachine : BinaryCheckboxMachine;
      this.checkboxMachine = (0, _ember.create)(machineClass, {
        status: Ember.isBlank(this.args.checked) ? 'false' : String(this.args.checked)
      });
    }

    get checked() {
      if (this.status) {
        return true;
      }

      return false;
    }

    get status() {
      let status = this.checkboxMachine.status.state;

      if (status === 'true') {
        return true;
      }

      if (status === 'false') {
        return false;
      }

      return status;
    }

    onupdate() {
      this.checkboxMachine = this.checkboxMachine.toggle();

      if (this.args.onupdate) {
        this.args.onupdate(this.status);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "checkboxMachine", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onupdate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onupdate"), _class.prototype)), _class));
  _exports.default = CheckboxTristateComponent;

  class TernaryCheckboxMachine {
    constructor() {
      _defineProperty(this, "status", String);
    }

    toggle() {
      switch (this.status.state) {
        case 'false':
          return this.status.set('partial');

        case 'partial':
          return this.status.set('true');

        case 'true':
        default:
          return this.status.set('false');
      }
    }

  }

  class BinaryCheckboxMachine {
    constructor() {
      _defineProperty(this, "status", String);
    }

    toggle() {
      switch (this.status.state) {
        case 'false':
          return this.status.set('true');

        case 'true':
        default:
          return this.status.set('false');
      }
    }

  }
});