define("balance-ember/components/filter-form/basic/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    didRender() {
      this._super(...arguments);

      if (!this.filter.firstOperator) {
        this.setOperatorWithDefaults(this.defaultOperator);
      }
    },

    setOperatorWithDefaults(newOperator) {
      let {
        firstOperator
      } = this.filter.updateOperatorValue(0, newOperator);

      if (Ember.isPresent(this.defaultFilterValue)) {
        firstOperator.updateFilterValue(0, this.defaultFilterValue);
      }

      return firstOperator;
    }

  });

  _exports.default = _default;
});