define("balance-ember/components/a11y-announcer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ID3gey8u",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"a11y-announcer sr-only\"],[14,\"aria-live\",\"assertive\"],[12],[2,\"\\n  \"],[1,[32,0,[\"a11yAnnouncer\",\"message\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/a11y-announcer/template.hbs"
  });

  _exports.default = _default;
});