define("balance-ember/components/user/profile-tabs/manage-permissions/delete/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    actions: {
      cancel() {
        this.goBack();
      },

      afterSave() {
        this.goBack();
      },

      delete(budgetLea, data, saveTask) {
        let budgetLeaData = data.findBy('budgetLea.id', budgetLea.id);
        Ember.set(budgetLeaData, 'hasAccess', false);
        let writeableLea = budgetLeaData.user.budgetLeas.find(lea => lea.model === budgetLea);
        budgetLeaData.user.disassociateBudgetLea(writeableLea);
        saveTask.perform();
      }

    }
  });

  _exports.default = _default;
});