define("balance-ember/utils/money-math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roundMoneyAmount = roundMoneyAmount;
  _exports.isZero = isZero;
  _exports.isNegative = isNegative;
  const MONEY_DECIMALS = 2;
  const ROUND_FACTOR = Math.pow(10, MONEY_DECIMALS);

  function roundMoneyAmount(value) {
    // Floats are bad.
    return Math.round(value * ROUND_FACTOR) / ROUND_FACTOR;
  }

  function isZero(value) {
    // Math.abs is needed because floats are REALLY bad and you can get -0 as a result
    return Math.abs(roundMoneyAmount(value)) === 0;
  }

  function isNegative(value) {
    return roundMoneyAmount(value) < 0;
  }
});