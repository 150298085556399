define("balance-ember/models/flat-rate-benefit", ["exports", "balance-ember/models/benefit", "ember-cp-validations"], function (_exports, _benefit, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    amount: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true
    })]
  });

  var _default = _benefit.default.extend(validations, {
    flatRate: Ember.computed.readOnly('amount')
  });

  _exports.default = _default;
});