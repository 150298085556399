define("balance-ember/components/light-table/cell-components/tag-cell/count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Mb2IqmA",
    "block": "{\"symbols\":[\"@tags\"],\"statements\":[[10,\"div\"],[14,0,\"\\n    rounded\\n    text-material-blue-gray500\\n    text-sm\\n    border-2\\n    border-material-blue-gray100\\n    font-bold\\n    py-4\\n    px-2\\n    leading-none\"],[12],[2,\"\\n  +\"],[1,[32,1,[\"length\"]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/light-table/cell-components/tag-cell/count/template.hbs"
  });

  _exports.default = _default;
});