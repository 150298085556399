define("balance-ember/components/budget/district-wide-files/dropzone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PcCs5saG",
    "block": "{\"symbols\":[\"dropzone\",\"queue\",\"@uploadFile\"],\"statements\":[[8,\"file-upload-dropzone\",[],[[\"@name\"],[\"district-wide-files-queue\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"budget/file-upload/dropzone\",[],[[\"@queue\",\"@dropzone\"],[[32,2],[32,1]]],null],[2,\"\\n\\n  \"],[8,\"budget/district-wide-files/input\",[],[[\"@for\",\"@uploadFile\"],[\"district-wide-upload-drag-drop\",[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "balance-ember/components/budget/district-wide-files/dropzone/template.hbs"
  });

  _exports.default = _default;
});