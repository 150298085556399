define("balance-ember/controllers/budgeting/fiscal-year/positions", ["exports", "balance-ember/mixins/table-controller", "balance-ember/mixins/positions-categories"], function (_exports, _tableController, _positionsCategories) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_tableController.default, _positionsCategories.default, {
    init() {
      this._super(...arguments);

      this.tableState.defaultSort.set({
        column: 'job_type_title',
        dir: 'asc'
      });
    }

  });

  _exports.default = _default;
});