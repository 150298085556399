define("balance-ember/components/positions-summary/component", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sumOfAllFTEs: Ember.computed.alias('summary.totalFte'),
    sumOfFundedFTEs: Ember.computed.alias('summary.fundedFte'),
    sumOfStaffedFtes: Ember.computed.alias('summary.staffedFte'),
    sumOfUnstaffedFTEs: Ember.computed('sumOfAllFTEs', 'sumOfStaffedFtes', function () {
      let unstaffed = this.sumOfAllFTEs - this.sumOfStaffedFtes;
      return (0, _numeral.default)(unstaffed).format('0[.][00]');
    }),
    percentageofFilledFTEs: Ember.computed('sumOfAllFTEs', 'sumOfStaffedFtes', 'sumOfUnstaffedFTEs', function () {
      let perc = this.sumOfStaffedFtes / this.sumOfAllFTEs;
      return (0, _numeral.default)(perc).format('%0[.][0]');
    })
  });

  _exports.default = _default;
});