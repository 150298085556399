define("balance-ember/components/ui-modal/modal-header/component", ["exports", "balance-ember/components/tagless-component/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    closeModal() {},

    isSticky: Ember.computed('style', function () {
      return this.style.includes('sticky');
    }),
    style: 'blue sticky',
    styleClasses: Ember.computed('style', function () {
      let style = '';

      if (this.style.includes('blue')) {
        style = `${style} bg-blue350 text-white`;
      }

      if (this.style.includes('white')) {
        style = `${style} bg-white text-material-gray700`;
      }

      if (this.style.includes('rounded')) {
        style = `${style} rounded-t`;
      }

      if (this.style.includes('bordered')) {
        style = `${style} border-b border-material-gray200`;
      }

      return style;
    })
  });

  _exports.default = _default;
});