define("balance-ember/components/manage/account-details/transaction-amount-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ht86lXnA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[32,0,[\"statusStyle\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"amount\"]]],[[\"format\",\"currency\"],[\"$0,0.00\",\"dollars\"]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    (\"],[1,[30,[36,1],[[32,0,[\"status\"]]],null]],[2,\")\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"numeral-formatter\",\"titleize\"]}",
    "moduleName": "balance-ember/components/manage/account-details/transaction-amount-cell/template.hbs"
  });

  _exports.default = _default;
});